<template>
  <b-container fluid>
    <b-row class="pt-1 pb-3">
      <button  v-if="role === 2 || role === 1" class="save_btn_common" @click="editClientAnalit()">Добавить аналиты</button>
      <button  v-if="(role === 2 && (permission.showButtonLoadExamBlank || loadExaminationReportToProcess)) || (role === 1 && loadExaminationReportToProcess)" class="save_btn_common" @click="openUploadFileModal()">Загрузить файл исследований</button>
      <button  v-if="role === 2 || role === 1" class="save_btn_common" @click="editClientPresribedBlankAnalit()">Добавить исследования из бланка назначений</button>
      <button  v-if="role === 2" class="save_btn_common" @click="deleteAllClientsAnalit()">Очистить список исследований</button>
<!--      <button  v-if="role === 2 || role === 1" class="save_btn_common" @click="editExaminationResultsSettings()">Настройка интерпретации результатов исследований</button>-->
    </b-row>

    <b-row><br />
      <b-table v-if="role === 2 || role === 1" striped hover :items="reportParams" :fields="fields" :thead-class="'table-header'" :tbody-tr-class="rowClass" class="h-100">
        <!-- A custom formatted heads -->
        <template v-slot:head(examinationDate)="data">
          <div class="column-head vertical-center">Дата ввода исследования</div>
        </template>
        <template v-slot:head(requestCode)="data">
          <div class="column-head vertical-center">Код заявки</div>
        </template>
        <template v-slot:head(paramFullName)="data">
          <div class="column-head vertical-center">Наименование аналита</div>
        </template>
        <template v-slot:head(val)="data">
          <div class="column-head vertical-center">Результат</div>
        </template>
        <template v-slot:head(paramReport)="data">
          <div class="column-head vertical-center">Интерпретация</div>
        </template>

        <!-- A custom formatted column -->
        <template v-slot:cell(examinationDate)="data">
          <div class="vertical-center-container"><span class="vertical-center" >{{ new Date(data.item.examinationDate).toLocaleDateString() }}</span></div>
        </template>
        <template v-slot:cell(requestCode)="data">
          <div class="vertical-center-container"><span class="vertical-center" >{{ data.item.requestCode }}</span></div>
        </template>
        <template v-slot:cell(paramFullName)="data" >
          <div class="vertical-center">
            <b-icon :id="'param_def_' + data.item.paramId" icon="book"></b-icon>
            <b-tooltip  :target="'param_def_' + data.item.paramId" triggers="hover">
              <span v-html="data.item.paramDefinition"></span>
            </b-tooltip> &nbsp;
            {{data.item.paramName}}{{data.item.biomaterialTypeName ? ', ' + data.item.biomaterialTypeName : '' }}
          </div>
        </template>
        <template v-slot:cell(val)="data">
          <div class="vertical-center">
          <span v-if="data.item.paramType === 2 || (data.item.paramType === 7 && data.item.propertyName)">
            <b :class="'div-' + rowClass(data.item, 'row')">{{ data.item.propertyName }}, {{ data.item.propertyValueName }}</b>
          </span>
          <span v-else>
            <b :class="'div-' + rowClass(data.item, 'row')">{{ data.item.paramValue }} {{ data.item.measurementUnitName }}</b>
          </span>
            &nbsp;
            <b-link v-if="role === 2 && (data.item.paramType === 1 || data.item.paramType === 3 || data.item.paramType === 10)" @click="selectDynamicParam(data.item.paramName + (data.item.biomaterialTypeName ? ', ' + data.item.biomaterialTypeName : ''), data.item.paramId)">
              <b-icon icon="graph-up"></b-icon>
            </b-link>
            <b-link v-else @click="selectDynamicTableParam(data.item.paramName + (data.item.biomaterialTypeName ? ', ' + data.item.biomaterialTypeName : ''), data.item.paramId)">
              <b-icon icon="graph-up"></b-icon>
            </b-link>
          </div>
        </template>
        <template v-slot:cell(paramReport)="data">
          <div class="vertical-center"><span v-html="data.item.paramReport"></span></div>
        </template>
        <template v-slot:cell(checkboxes)="data">
          <div v-if="data.item.excludeSeldomDisease">Исключены из отчета редкие нозологические формы</div>
          <div v-if="data.item.excludePriorityDisease">Исключены из отчета нозологические формы, имеющие неспецифическую связь с отклонениями аналитов</div>
        </template>
        <template v-slot:cell(buttons)="data">
          <div class="vertical-center">
            <nobr>

              <b-link v-if="data.item.examinationFileId" class="ml-1"  target="_blank" :to="'/rest/recognition/' + patientId + '/pdf/' + data.item.examinationFileId"><b-icon variant="success" icon="box-arrow-in-down" size="lg" ></b-icon></b-link>
              <b-link v-if="role === 2  && !permission.reportPreBlock" @click="openAnalitPdfReport(data.item)" class="ml-1"  title="Просмотр предварительных отчетов для одного аналита" ><img src="@/assets/images/patients/icon_pdf.png"></b-link>
              <b-link @click="editClientAnalit(data.item)" class="ml-1"  title="Редактировать"><img src="@/assets/images/patients/icon_edit.png"></b-link>
              <b-link @click="deleteClientAnalit(data.item.paramClientId)" class="ml-1"  title="Удалить"><img src="@/assets/images/patients/icon_delete.png"></b-link>
            </nobr>
          </div>
        </template>
      </b-table>
      <b-modal id="createClientReportParamModal" no-close-on-backdrop no-close-on-esc hide-header-close hide-footer size="xl" :title="currentAnalit ? 'Редактировать значение аналита' : 'Выбрать аналит из списка и ввести его значение'">
          <ClientReportParamForm @save-success="afterSave"  @save-cancel="cancelSave" v-bind:error="error" v-bind:patient-id="patientId" v-bind:current-analit="currentAnalit"></ClientReportParamForm>
      </b-modal>
      <b-modal id="createClientReportPrescribedParamModal" no-close-on-backdrop hide-header-close no-close-on-esc hide-footer size="xl" title="Выбрать бланк назначений и ввести значения аналитов">
        <ClientReportPrescribedParamForm @save-success="afterSavePrescribed"  @save-cancel="cancelSavePrescribed" v-bind:error="error" v-bind:patient-id="patientId" v-bind:current-analit="currentAnalit"></ClientReportPrescribedParamForm>
      </b-modal>


      <b-modal id="dynamicParamInfo" no-close-on-backdrop no-close-on-esc hide-footer size="xl" :title="'Динамика аналита ' + this.currentParamName">
        <DynamicInfoChart v-bind:patient-id="patientId" v-bind:param-id="currentParamId"></DynamicInfoChart>
      </b-modal>
      <b-modal id="dynamicParamTableReport" no-close-on-backdrop no-close-on-esc hide-footer size="xl" :title="'Динамика аналита ' + this.currentParamName">
        <DynamicInfoReport v-bind:patient-id="patientId" v-bind:param-id="currentParamId"></DynamicInfoReport>
      </b-modal>

      <b-modal id="uploadFileModal"  no-close-on-backdrop no-close-on-esc hide-footer size="xl" title="Загрузка файла">
        <b-container fluid>
          <b-row>
            <b-col cols="3">Выберите файл с исследованием в формате PDF</b-col>
            <b-col cols="6">
              <b-form-file
                  :disabled="fileDisable"
                  v-model="fileToRecognize"
                  :state="Boolean(fileToRecognize)"
                  placeholder="Выберите файл на диске или перетащите..."
                  drop-placeholder="Перетащите файлы..."
                  browse-text="Обзор"
              ></b-form-file>
            </b-col>
<!--            <b-col cols="3">-->
<!--              <button class="save_btn_common" :disabled="!Boolean(fileToRecognize)" @click="sendFileToRecognize()">Загрузить исследование<b-spinner v-if="saving" variant="light" small></b-spinner></button>-->
<!--            </b-col>-->
          </b-row>
          <b-row v-if="saving" >
            <b-col cols="12" class="ml-auto py-2 text-center w-100 d-flex justify-content-center" >
              <div class="lds-component">
                <div class="lds-dual-ring"></div>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="fileUploadFinished && ((modelResult && modelResult.probes.length == 0) || !modelResult)" >
            <b-col cols="12" class="ml-auto py-2 text-danger" >
              Данные загружены, при распознавании не найдены исследования.
            </b-col>
          </b-row>
          <b-row v-if="modelResult && modelResult.probes.length > 0">
            <b-col cols="12">
              <ClientReportComplex2ParamForm @params-saved="onParamsSaved()" v-bind:child-params="modelResult.probes" v-bind:patient-id="patientId" v-bind:entity-id="modelResult.entityId"></ClientReportComplex2ParamForm>
            </b-col>
          </b-row>
        </b-container>
      </b-modal>
      <b-modal id="examinationResultsSettings" hide-footer size="xl" title="Настройка интерпретации результатов исследований">
        <div class="container-fluid">
          <div class="row mx-0">
            <div class="background_E9F5EA_page_7 ">
              <form>
                <div class="row mx-0 pb-4 pt-5">
                  <div class="col-lg-4 col-6 pl-4">
              <span class="span_page_8 d-flex">

              </span>
                    <div class="d-flex pt-3">
                      <div>
                        <div class="radio-item">
                          <b-checkbox switch id="excludeSeldomDiseaseCheckbox" name="excludeSeldomDiseaseCheckbox" v-model="clientReport.excludeSeldomDisease"></b-checkbox>
                        </div>
                      </div>
                      <div>
                        <div>
                          <label for="excludeSeldomDiseaseCheckbox" class="pl-2">
                      <span class="name_atribute_label" >Исключить из отчета редкие нозологические формы
                      </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex pt-4">
                      <div>
                        <div class="radio-item">
                          <b-checkbox switch id="excludePriorityDiseaseCheckbox" name="excludePriorityDiseaseCheckbox" v-model="clientReport.excludePriorityDisease"></b-checkbox>
                        </div>
                      </div>
                      <div>
                        <div>
                          <label for="excludePriorityDiseaseCheckbox" class="pl-2">
                      <span class="name_atribute_label" >Исключить из отчета нозологические формы, имеющие неспецифическую связь с отклонениями аналитов
                      </span>
                          </label>
                        </div>
                      </div>
                    </div>
<!--                    <div class="d-flex pt-4">-->
<!--                      <div>-->
<!--                        <div class="radio-item">-->
<!--                          <input type="radio" value="3" id="reportTypeRadio3" name="reportTypeRadio3" v-model="clientReport.reportType" />-->
<!--                          <label for="reportTypeRadio3"></label>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div>-->
<!--                        <div>-->
<!--                          <label for="reportTypeRadio3" class="pl-2">-->
<!--                      <span class="name_atribute_label" >Сокращенный-->
<!--                      </span>-->
<!--                          </label>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->

                  </div>
                  <div class="col-lg-4 col-6 d-flex justify-content-center">
                    <div>
                <span class="span_page_8 d-flex">
                  Уровень отчета
                </span>
                      <div class="d-flex pt-3">
                        <div>
                          <div class="radio-item">
                            <input type="radio" value="1" id="reportLevelRadio1" name="reportLevelRadio1" v-model="clientReport.reportLevel" />
                            <label for="reportLevelRadio1"></label>
                          </div>
                        </div>
                        <div>
                          <div>
                            <label for="reportLevelRadio1" class="pl-2">
                              <span class="name_atribute_label" >С детализацией нозологических форм</span>
                            </label>
                          </div>
                          <div class="pl-2">
                      <span class="name_atribute_span">
                        Дифференциальная диагностика нозологических форм; детализированная диагностика особых состояний
                        и влияния факторов внешней среды; исключение влияния конкретных лекарственных препаратов*;
                        детальное исключение лабороторных ошибок и интерференций.
                      </span>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex pt-1">
                        <div>
                          <div class="radio-item">
                            <input type="radio" value="2" id="reportLevelRadio2" name="reportLevelRadio2" v-model="clientReport.reportLevel" />
                            <label for="reportLevelRadio2"></label>
                          </div>
                        </div>
                        <div>
                          <div>
                            <label for="reportLevelRadio2" class="pl-2">
                        <span class="name_atribute_label" >Без детализации нозологических форм
                        </span>
                            </label>
                          </div>
                          <div class="pl-2">
                      <span class="name_atribute_span">
                        Дифференциальная диагностика патологических состояний; детализированная диагностика особых состояний
                        и влияния факторов внешней среды; исключение влияния лекарственных препаратов на уровне фармакологических групп**;
                        обобщенное исключение лабороторных ошибок и интерференций.
                      </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4  col-6 pl-5">
              <span class="span_page_8 d-flex">
                Вид части отчета по влиянию лекарственных препаратов на аналит
              </span>
                    <div class="d-flex pt-3">
                      <div>
                        <div class="radio-item">
                          <input type="radio" value="1" id="reportViewRadio1" name="reportViewRadio1" v-model="clientReport.reportView" />
                          <label for="reportViewRadio1"></label>
                        </div>
                      </div>
                      <div>
                        <label for="reportViewRadio1" class="pl-2">
                    <span class="name_atribute_label" >Включить в отчет все лекарственные препараты
                    </span>
                        </label>
                      </div>
                    </div>
                    <div class="d-flex pt-1">
                      <div>
                        <div class="radio-item">
                          <input type="radio" value="2" id="reportViewRadio2" name="reportViewRadio2" v-model="clientReport.reportView" />
                          <label for="reportViewRadio2"></label>
                        </div>
                      </div>
                      <div>
                        <label for="reportViewRadio2" class="pl-2">
                    <span class="name_atribute_label" >Включить в отчет только принимаемые пациентом лекарственные препараты
                    </span>
                        </label>
                      </div>
                    </div>
                    <div class="d-flex pt-1">
                      <div>
                        <div class="radio-item">
                          <input type="radio" value="3" id="reportViewRadio3" name="reportViewRadio3" v-model="clientReport.reportView" />
                          <label for="reportViewRadio3"></label>
                        </div>
                      </div>
                      <div>
                        <label for="reportViewRadio3" class="pl-2">
                    <span class="name_atribute_label" >Включить в отчет все фармакологические группы
                    </span>
                        </label>
                      </div>
                    </div>
                    <div class="d-flex pt-1">
                      <div>
                        <div class="radio-item">
                          <input type="radio" value="4" id="reportViewRadio4" name="reportViewRadio4" v-model="clientReport.reportView" />
                          <label for="reportViewRadio4"></label>
                        </div>
                      </div>
                      <div>
                        <label for="reportViewRadio4" class="pl-2">
                    <span class="name_atribute_label" >Включить в отчет только те фармакологические группы,
                      в которые входит принимаемый пациентом лекарственный препарат
                    </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

        </div>
      </b-modal>
    <Loader v-bind:showLoader="loading" />
    </b-row>
  </b-container>
</template>

<script>
  import ClientReportParamForm from '@/components/patients/ClientReportParamForm'
  import Loader from '@/components/Loader'
  import {host} from '@/constants'
  import ExaminationFiles from "@/components/patients/ExaminationFiles"
  import ClientReportPrescribedParamForm from "@/components/patients/ClientReportPrescribedParamForm";
  import ClientReportComplex2ParamForm from "@/components/patients/ClientReportComplex2ParamForm";
  import DynamicInfoChart from "@/components/patients/DynamicInfoChart";
  import DynamicInfoReport from "@/components/patients/DynamicInfoReport";

  export default {
    props: ["patientId"],
    name: "PatientReportParams",
    computed: {
      role: {
        get() {
          return this.$store.getters.role
        }
      },
      permission: {
        get() {
          return this.$store.getters.permission
        }
      }
    },
    watch: {
      fileToRecognize: function (newFileToRecognize, oldFileToRecognize) {
        if (newFileToRecognize)
          this.sendFileToRecognize()
      },
      'clientReport.reportType': function(newReportType)
      {
        this.saveClientReport()
      },
      'clientReport.reportLevel': function(newReportLevel)
      {
        this.saveClientReport()
      },
      'clientReport.reportView': function(newReportView)
      {
        this.saveClientReport()
      },
      'clientReport.excludeSeldomDisease': function(newExcludeSeldomDisease)
      {
        this.saveClientReport()
      },
      'clientReport.excludePriorityDisease': function(newExcludePriorityDisease)
      {
        this.saveClientReport()
      }

    },
    data() {
      return {
        fileToRecognize: null,
        selectedBlank: null,
        blankOptions: [],
        modelResult: null,
        saving: false,
        fileDisable: false,
        fileUploadFinished: false,
        fields: [
          {
            key: 'examinationDate',
            label: 'Дата ввода исследования',
            thClass: 'table-header h-100',
            tdClass: 'h-100'
          },
          {
            key: 'requestCode',
            label: 'Код заявки',
            thClass: 'table-header h-100',
            tdClass: 'h-100'
          },
          {
            key: 'paramFullName',
            label: 'Наименование аналита',
            thClass: 'table-header h-100',
            tdClass: 'h-100'
          },
          {
            key: 'val',
            label: 'Результат',
            thClass: 'table-header h-100',
            tdClass: 'h-100'
          },
          {
            key: 'paramReport',
            label: 'Интерпретация',
            thClass: 'table-header h-100',
            tdClass: 'h-100'
          },
          {
            key: 'buttons',
            label: '',
            thClass: 'table-header h-100',
            tdClass: 'h-100'
          }

        ],
        reportParams: [],
        error: "",
        currentAnalit: null,
        loading: false,
        recommendedParams: [],
        currentParamId: null,
        currentParamName: '',
        clientReport: {
          reportType: null,
          reportLevel: null,
          reportView: null,
          excludeSeldomDisease: null,
          excludePriorityDisease: null
        },
        loadExaminationReportToProcess : true
      }
    },
    mounted: function () {
      this.fetchData()
      this.fetchPrescribedBlanks()
      this.fetchLoadExaminationReportToProcess()
    },
    components: {
      DynamicInfoChart, DynamicInfoReport,
      ClientReportComplex2ParamForm,
      ClientReportPrescribedParamForm,
      ExaminationFiles,
      ClientReportParamForm, Loader
    },
    methods: {
      rowClass(item, type = 'row') {
        if (!item || type !== 'row') return
        if (item.zoneId === 6 || item.zoneId === 16 || item.zoneId === 19 || item.zoneId === 25) return 'table-row-good'
        else return 'table-row'
      },
      onParamsSaved() {
        this.$bvModal.hide('uploadFileModal')
        this.loading = true
        setTimeout(() => {
          this.fetchData()
        }, 2000)

      },
      saveClientReport() {
        fetch(host + '/rest/patients/' + this.patientId + '/clientreport', {
          method: 'PUT',
          credentials: 'include',
          body: JSON.stringify(this.clientReport),
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {

                //this.$messageToast('Настройки отчетов')

              } else {

                this.$errorToast(json.resultCode + ' ' + json.message)
              }
              this.loading = false
            }).catch(error => {
          this.$errorToast(error)
        })
      },
      sendFileToRecognize() {
        this.fileUploadFinished = false
        this.fileDisable = true
        this.saving = true
        //console.log((this.fileToRecognize))

        if (this.fileToRecognize && this.fileToRecognize.type === 'application/pdf') {

          var reader  = new FileReader();
          var curThis = this

          reader.onloadend = function () {

            console.log(reader.result)
            let rq = {blankId: curThis.selectedBlank, name: curThis.fileToRecognize.name, type:curThis.fileToRecognize.type, content: reader.result.substring(reader.result.indexOf('base64,') + 7 )}
            console.log(JSON.stringify(rq))

            fetch(host + '/rest/recognition/' + curThis.patientId, {
              method: 'POST',
              credentials: 'include',
              body: JSON.stringify(rq),
              headers: {'Content-Type': 'application/json; charset=utf-8'}
            }).then(response => response.json())
                .then(json => {
                  console.log('Response ' + JSON.stringify(json))
                  curThis.modelResult = json.modelResult
                  curThis.saving = false
                  curThis.fetchData()
                  //curThis.$errorToast(json.resultCode + ' ' + json.message)
                  //curThis.$messageToast('Личные данные')
                  curThis.fileUploadFinished = true
                  //curThis.$bvModal.hide('uploadFileModal')
                }).catch(error => {

              console.log(error)
              curThis.saving = false
              curThis.fileUploadFinished = true
              //curThis.$bvModal.hide('uploadFileModal')
              //curThis.$errorToast(error)


            })

          }

          reader.readAsDataURL(this.fileToRecognize);

        }
      },

      openAnalitPdfReport(item = null) {
        let reportType = 'AnalitDoctor'
        if (item.paramType === 2) {
          reportType = 'QualAnalitDoctor'
        } else if (item.paramType === 7) {
          reportType = "SemiQuanAnalitDoctor"
        }
        window.open(host + "/rest/reports/" + reportType + "/patient/" + this.patientId + "/analit/" + item.paramClientId , "_blank");

      },
      editClientAnalit(item = null) {
        this.currentAnalit = item
        this.$bvModal.show('createClientReportParamModal')
      },
      openUploadFileModal() {
        this.modelResult = null
        this.fileDisable = false
        this.fileUploadFinished = false
        this.$bvModal.show('uploadFileModal')
      },
      editClientPresribedBlankAnalit() {
        this.$bvModal.show('createClientReportPrescribedParamModal')
      },
      editExaminationResultsSettings() {
        this.$bvModal.show('examinationResultsSettings')
      },
      deleteClientAnalit(paramClientId) {

        this.error = null;
        if (confirm('Вы уверены, что хотите удалить аналит?')) {
          this.loading = true;
          fetch(host + '/rest/params/clientreport/' + paramClientId, {
            method: 'DELETE',
            credentials: 'include',
            headers: {'Content-Type': 'application/json; charset=utf-8'}
          }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {
                this.reportParams = this.reportParams.filter(item => item.paramClientId !== paramClientId)

                console.log('Delete Success ' + json)

              } else {

                console.log('Delete Failed ' + json)
                this.error = json.message;
              }
              this.loading = false
            }).catch(error => {
              this.error = error
              console.log(JSON.stringify(error))
              this.loading = false
            })
        }
      },
      deleteAllClientsAnalit () {
        this.error = null;
        if (confirm('Вы уверены, что хотите удалить все аналиты?')) {
          this.loading = true;
          fetch(host + '/rest/params/clientreport/forclient/' + this.patientId, {
            method: 'DELETE',
            credentials: 'include',
            headers: {'Content-Type': 'application/json; charset=utf-8'}
          }).then(response => response.json())
              .then(json => {
                if (json.resultCode == 0) {
                  this.fetchData()

                  console.log('Delete Success ' + json)

                } else {

                  console.log('Delete Failed ' + json)
                  this.error = json.message;
                }
                this.loading = false
              }).catch(error => {
            this.error = error
            console.log(JSON.stringify(error))
            this.loading = false
          })
        }
      },
      selectDynamicParam(paramName, paramId) {
        this.currentParamId = paramId
        this.currentParamName = paramName
        this.$bvModal.show('dynamicParamInfo')
      },
      selectDynamicTableParam(paramName, paramId) {
        this.currentParamId = paramId
        this.currentParamName = paramName
        this.$bvModal.show('dynamicParamTableReport')
      },
      afterSave() {
        this.$bvModal.hide('createClientReportParamModal')
        this.loading = true
        setTimeout(() => {
          this.fetchData()
        }, 2000)

      },
      cancelSave() {
        this.$bvModal.hide('createClientReportParamModal')
      },
      cancelSavePrescribed() {
        this.$bvModal.hide('createClientReportPrescribedParamModal')
      },
      afterSavePrescribed() {
        this.$bvModal.hide('createClientReportPrescribedParamModal')
        this.loading = true
        setTimeout(() => {
          this.fetchData()
        }, 2000)
      },
      fetchData() {
        fetch(host + '/rest/params/clientreport/' + this.patientId + '', {
          credentials: "include"
        })
            .then(response => response.json())
            .then(json => {
              this.reportParams = json.reportParams
              this.loading = false
              console.log(JSON.stringify(json))
            }).catch(error => {
          console.log(error)
          this.loading = false
        })

        fetch(host + '/rest/patients/' + this.patientId + '/clientreport' , {
          credentials: "include"
        })
            .then(response => response.json())
            .then(json => {
              this.clientReport = json.clientReport

            }).catch(error => {
          console.log(error)
        })

      },
      fetchLoadExaminationReportToProcess() {
        fetch(host + '/rest/settings', {
          credentials: 'include',
          method: 'GET'
        })
            .then(response => response.json())
            .then(json => {
              if (json.resultCode === 0) {
                this.loadExaminationReportToProcess = json.settings.loadExaminationReportToProcess
              }
            })

      },
      fetchPrescribedBlanks() {
        fetch(host + '/rest/patients/' + this.patientId + '/prescribes', {
          credentials: "include",
          method: 'GET'
        })
            .then(response => response.json())
            .then(json => {


              if (json.resultCode === 0) {
                //this.prescribedBlanks = json.blanks

                this.blankOptions = [{prescribedBlankId: null, prescribedBlankName: ''}]
                this.blankOptions = this.blankOptions.concat(json.blanks)
                if (json.blanks.length > 0) {
                  this.selectedBlank = json.blanks[0].prescribedBlankId
                }

              } else {
                this.$errorToast(json.resultCode + ' ' + json.message)
              }

            }).catch(error => {
          console.log(error)
          this.saving = false
        })
      }
    }
  }
</script>

<style scoped>

</style>