export default {
  install(Vue, options) {
    Vue.prototype.$messageToast = function(title = '', autoHideDelay = 5000) {
      this.$bvToast.toast("Сохранены успешно", {
        title: title,
        toaster: 'b-toaster-top-center',
        variant: "info",
        autoHideDelay: autoHideDelay,
        appendToast: true
      })
    }

    Vue.prototype.$customInfoMessageToast = function(title = '', message = '') {
      this.$bvToast.toast(message, {
        title: title,
        toaster: 'b-toaster-top-center',
        variant: "info",
        noAutoHide: true,
        appendToast: true
      })
    }

    Vue.prototype.$customInfoMessageToastAutohide = function(title = '', message = '', autoHideDelay = 5000) {
      this.$bvToast.toast(message, {
        title: title,
        toaster: 'b-toaster-top-center',
        variant: "info",
        noAutoHide: false,
        autoHideDelay: autoHideDelay,
        appendToast: true
      })
    }

    Vue.prototype.$errorToast = function(title = '') {
      this.$bvToast.toast(title, {
        title: `Ошибка`,
        toaster: 'b-toaster-top-center',
        solid: true,
        variant: 'danger',
        noAutoHide: true,
        appendToast: true
      })
    }

    Vue.prototype.$reportToast = function(id = '', title = '', url = '') {
      const h = this.$createElement
      const vNodesMsg = h(
        'p',
        { class: ['text-left', 'mb-0'] },
        [
          'Успешно создан. ',
          h('a', { attrs: { target: '_blank', href: url } }, 'Открыть')
        ]
      )
      this.$root.$bvToast.toast(vNodesMsg, {
        title: title,
        toaster: 'b-toaster-bottom-right',
        solid: true,
        noAutoHide: true,
        variant: 'success',
        id: id
      })
    }

    Vue.prototype.$reportCreationToast = function(id = '', title = '') {
      const h = this.$createElement
      const vNodesMsg = h(
        'p',
        { class: ['text-center', 'mb-0'] },
        [
          h('b-spinner', { props: { variant: 'success', small: true } })
        ]
      )
      this.$root.$bvToast.toast(vNodesMsg, {
        title: title,
        toaster: 'b-toaster-bottom-right',
        solid: true,
        noAutoHide: true,
        variant: 'info',
        id: id
      })
    }
    Vue.prototype.$reportCreationHideToast = function(id = '') {
      this.$root.$bvToast.hide(id)
    }


    Vue.prototype.$printDate= function(date) {

      var mm = date.getMonth() + 1; // getMonth() is zero-based
      var dd = date.getDate();

      // return [date.getFullYear(),
      //   (mm>9 ? '' : '0') + mm,
      //   (dd>9 ? '' : '0') + dd
      // ].join('-');

      return [(dd>9 ? '' : '0') + dd,
        (mm>9 ? '' : '0') + mm,
        date.getFullYear()
      ].join('.');

    }

    Vue.prototype.$printDate2 = function(date) {

      var mm = date.getMonth() + 1; // getMonth() is zero-based
      var dd = date.getDate();

      return [date.getFullYear(),
        (mm>9 ? '' : '0') + mm,
        (dd>9 ? '' : '0') + dd
      ].join('-');

      // return [(dd>9 ? '' : '0') + dd,
      //   (mm>9 ? '' : '0') + mm,
      //   date.getFullYear()
      // ].join('.');

    }

  }
}