<template>
  <b-container fluid  class="pb-2">
    <b-row>
      <b-col cols="12">&nbsp;</b-col>
    </b-row>
    <b-form-group id="ig1" label-for="param" label="Наименование аналита">
      <b-form-input v-model="searchRequest" autocomplete="off" type="text" @update="filterParams"></b-form-input>
    </b-form-group>

    <hr width="100%" color="gray">
    <b-row>
      <b-col>
        <button class="save_btn_common " @click="addAllParams()">Назначить все исследования</button>
      </b-col>
      <b-col class="text-right">
        <button class="save_btn_common_orange " @click="clearRecommendedAnalits()">Очистить список исследований</button>
      </b-col>
    </b-row>
    <b-row v-for="(item, index) in recommendedParams" :key="item.paramId">
      <b-col >
        <img src="@/assets/images/Icon_OMG_20x20.png" v-if="item.omsCode" >
<!--        <b-icon v-if="item.omsCode" variant="info" icon="award-fill" size="lg" ></b-icon>-->
      </b-col>
      <b-col cols="8">
        <b-icon :id="'param_srch_def_' + item.paramId" icon="book"></b-icon>
        <b-tooltip  :target="'param_srch_def_' + item.paramId" triggers="hover">
          <span v-html="item.paramDefinition"></span>
        </b-tooltip> &nbsp;
        {{ item.paramName }}, {{ item.biomaterialTypeName }}
      </b-col>
      <b-col cols="2">
        <b-link @click="editClientAnalit(item)">Назначить</b-link>
      </b-col>
      <b-col><b-link @click="deleteRecommendedAnalit(index)" title="Удалить"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link></b-col>
<!--      <div>-->
<!--        {{ item.paramName }}, {{ item.biomaterialTypeName }}-->
<!--        <b-link @click="editClientAnalit(item)">Добавить в лист назначений</b-link>-->
<!--      </div>-->
    </b-row>
  </b-container>
</template>

<script>
import {host} from "@/constants";

export default {
  name: "PatientSearchRecommendations",
  data() {
    return {
      searchRequest: null,
      recommendedParams: [],
      params: []
    }
  },
  mounted() {
    fetch(host + '/rest/params/fordoctor', {
      credentials: "include"
    })
        .then(response => response.json())
        .then(json => {
          this.params = json.params

        })
  },
  methods: {
    deleteRecommendedAnalit(index) {
      this.recommendedParams.splice(index, 1)
    },
    clearRecommendedAnalits() {
      this.recommendedParams = []
    },
    editClientAnalit(item = null) {
      this.$emit('selectParam', item)
    },
    addAllParams() {
      this.$emit('selectParams', this.recommendedParams)
    },
    filterParams() {
      if (!this.searchRequest) {
        this.recommendedParams = []
        return
      }

      this.recommendedParams = this.params.filter((el, index, []) => {
        return (el.paramName + el.biomaterialTypeName + ';_;_; ' + el.paramSynonym + ' ' + el.searchString).toLowerCase().includes(this.searchRequest.toLowerCase())
      })

    }
  }
}
</script>

<style scoped>

</style>