import { render, staticRenderFns } from "./ScaleRangeValueEdit.vue?vue&type=template&id=2124af46&scoped=true"
import script from "./ScaleRangeValueEdit.vue?vue&type=script&lang=js"
export * from "./ScaleRangeValueEdit.vue?vue&type=script&lang=js"
import style0 from "./ScaleRangeValueEdit.vue?vue&type=style&index=0&id=2124af46&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2124af46",
  null
  
)

export default component.exports