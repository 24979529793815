import { render, staticRenderFns } from "./RubricatorValueEdit.vue?vue&type=template&id=2a8df331&scoped=true"
import script from "./RubricatorValueEdit.vue?vue&type=script&lang=js"
export * from "./RubricatorValueEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a8df331",
  null
  
)

export default component.exports