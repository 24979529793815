<template>
  <div>
    <Header @toogle-sidebar="toogleSidebar"/>

    <div class="container-fluid">
      <div class="row h-100">
        <div v-if="isSidebarOpen" :class="{'col-2' : isSidebarOpen, 'col-0': !isSidebarOpen}" class="p-0 border-right">
          <SidebarMenu  v-model="isSidebarOpen"></SidebarMenu>
        </div>
        <div :class="{'col-10' : isSidebarOpen, 'col-12': !isSidebarOpen}" >
          <div class="mainContent">
            <router-view/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import SidebarMenu from "@/components/SidebarMenu";
export default {
  name: "BootVueLayout",
  components: {Header, SidebarMenu},
  data() {
    return {
      isSidebarOpen : false
    }
  },
  methods: {
    toogleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen
      console.log(this.isSidebarOpen)
    }
  }
}
</script>

<style scoped>

</style>