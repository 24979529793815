<template>
  <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 1756 1000" style="enable-background:new 0 0 1756 1000;" xml:space="preserve">
    <g>

		<rect x="875.3" y="45.8" class="st100" width="809.6" height="39"/>
      <path class="st101" d="M1685.5,541.7H874.6V84.1h810.9V541.7z M875.9,540.4h808.3V85.4H875.9V540.4z"/>
      <path class="st101" d="M1685.5,684.8H874.6V540.4h810.9V684.8z M875.9,683.5h808.3V541.7H875.9V683.5z"/>
      <path class="st101" d="M1685.5,758.9H874.6v-75.4h810.9V758.9z M875.9,757.6h808.3v-72.8H875.9V757.6z"/>
      <path class="st101" d="M1685.5,948.1H874.6V757.6h810.9V948.1z M875.9,946.8h808.3v-188H875.9V946.8z"/>
      <a id="ast102" v-on:click="toogleSvgClass($event,'ast102')" :class="this.questionary.painHead ? 'svglinkActive' : 'svglink'">
        <rect x="1000.8" y="101.5" class="st102" width="45.3" height="45.3"/>
        <path class="st102" d="M677.3,110.4c-1.3-1.5-3.2-2.3-5.6-2.3c3.1-12.4,2.2-27.6-2.5-38.8c-5-12.2-21.4-14.5-30.7-14.7
          c-1.2,0-4.1-0.1-6.9-0.1c-2.6,0-8.8,0-11.5,0.2c-10.2,0.7-23.2,3.8-27.6,14.6c-4.6,11.3-5.6,26.4-2.5,38.8
          c-2.5,0-4.3,0.8-5.6,2.3c-1.6,1.9-2.1,5-1.5,8.9l0.2,1.2c1.1,7.1,2.1,13.2,9.3,15.8c0.9,9.4,1.9,19.9,10.1,27.7
          c0.1,0.1,0.1,0.1,0.2,0.1c0,0.1-0.1,0.3-0.1,0.4c0,0.3,0,0.6,0,0.9c5.4,4.1,10.8,6.8,17.4,8.4c2.4,0.7,6.1,1.3,10.8,1.5
          c5.7,0.2,6.4-0.4,10.2-1.2c5.7-1.4,11.9-4.3,17.9-10c0,0,0,0,0,0c0.1-0.1,0.1-0.1,0.2-0.1c8.3-7.7,9.3-18.3,10.1-27.7
          c7.2-2.6,8.2-8.8,9.3-15.8l0.2-1.2C679.5,115.4,678.9,112.3,677.3,110.4z"/>
        <path class="st102" d="M174.6,120.6c1.1,7.1,2.1,13.2,9.3,15.8c0.9,9.4,1.9,19.9,10.1,27.7c0.1,0.1,0.1,0.1,0.2,0.1
          c0,0.1-0.1,0.3-0.1,0.4c0,0,0,0,0,0c17.8,15.7,38,15.8,56.3,0.3c0-0.1,0-0.2,0-0.3c0-0.2,0-0.3-0.1-0.4c0.1-0.1,0.1-0.1,0.2-0.1
          c8.3-7.7,9.3-18.3,10.1-27.7c7.2-2.6,8.2-8.8,9.3-15.8l0.2-1.2c0.7-3.9,0.1-7-1.5-8.9c-1.3-1.5-3.2-2.3-5.6-2.3
          c3.1-12.4,2.2-27.6-2.5-38.8c-5-12.2-21.4-14.5-30.7-14.7c-1.2,0-4.1-0.1-6.9-0.1c-2.6,0-8.8,0-11.5,0.2
          c-10.2,0.7-23.2,3.8-27.6,14.6c-4.6,11.3-5.6,26.4-2.5,38.8c-2.5,0-4.3,0.8-5.6,2.3c-1.6,1.9-2.1,5-1.5,8.9L174.6,120.6z"/>
      </a>
      <path class="st103" d="M1177.5,118.4v2.4h-5.7v2.2c0.4-0.1,1.1-0.1,1.6-0.1c1.3,0,2.7,0.3,3.6,1.1c0.9,0.7,1.5,1.7,1.5,3.1
					c0,1.3-0.5,2.2-1.2,2.9c-1.2,1.1-3.3,1.5-5.2,1.5c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1177.5z M1171.8,129.2c0.3,0.1,0.7,0.1,1.1,0.1
					c0.7,0,1.4-0.2,1.9-0.7c0.4-0.3,0.6-0.9,0.6-1.5c0-0.7-0.3-1.1-0.7-1.5c-0.5-0.4-1.2-0.6-1.9-0.6c-0.3,0-0.7,0-1,0.1V129.2z"/>
      <path class="st103" d="M1192.2,124.7c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1190,118.2,1192.2,121.2,1192.2,124.7z M1182.8,124.9c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1184,120.5,1182.8,122.4,1182.8,124.9z"/>
      <path class="st103" d="M1203.6,118.4v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
					c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1203.6z"/>
      <path class="st103" d="M1209,118.4v4.5c0.4,0,1-0.1,1.6-0.1c2.8,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8c-1.2,1.3-3.4,1.6-5.4,1.6
					c-1.4,0-2.5-0.1-3.2-0.2v-12.8H1209z M1209,129.2c0.3,0.1,0.7,0.1,1.1,0.1c1.4,0,2.6-0.7,2.6-2.1c0-1.5-1.1-2.1-2.5-2.1
					c-0.4,0-0.8,0-1.1,0.1V129.2z"/>
      <path class="st103" d="M1221.5,118.6c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
					c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
					V118.6z M1224.4,123.5h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V123.5z M1224.4,129.3c0.3,0,0.6,0,1.1,0
					c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V129.3z"/>
      <path class="st103" d="M1244.8,118.4v2.4h-4.9v10.5h-2.9v-12.9H1244.8z"/>
      <path class="st103" d="M1257.5,124.7c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1255.3,118.2,1257.5,121.2,1257.5,124.7z M1248.2,124.9c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1249.3,120.5,1248.2,122.4,1248.2,124.9z"/>
      <path class="st103" d="M1268.9,118.4v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
					c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1268.9z"/>
      <path class="st103" d="M1283.3,124.7c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1281.1,118.2,1283.3,121.2,1283.3,124.7z M1273.9,124.9c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1275.1,120.5,1273.9,122.4,1273.9,124.9z"/>
      <path class="st103" d="M1285.2,118.6c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
					c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
					V118.6z M1288.1,123.5h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V123.5z M1288.1,129.3c0.3,0,0.6,0,1.1,0
					c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V129.3z"/>
      <path class="st103" d="M1304.4,125.9h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V125.9z"/>
      <a id="ast104" v-on:click="toogleSvgClass($event,'ast104')" :class="this.questionary.painFrontHalfChest ? 'svglinkActive' : 'svglink'">
        <path class="st104" d="M307.6,213.5c1-1.5,2-2.7,2.9-3.8c-2.3-0.6-4.6-1.1-7-1.5c-2.5-0.4-5-0.5-7.5-0.7c-3.8-0.2-7.7-0.5-11.4-1.5
          c-3.2-0.9-6.3-2.4-9.3-3.8c-1.4-0.7-2.9-1.4-4.4-2c-8.3-3.5-13.4-8-16.7-14.4c-3.1-6.2-3.6-13.6-3.6-20.6
          c-8.6,7.2-17.8,11.2-26.9,11.6c-0.5,0-0.9,0-1.4,0c-9.7,0-19.1-4-28-11.9c-0.1,7.1-0.5,14.6-3.6,20.9
          c-3.2,6.4-8.4,10.8-16.7,14.4c-1.5,0.6-3,1.3-4.4,2c-3,1.4-6.1,2.9-9.3,3.8c-3.7,1.1-7.6,1.3-11.4,1.5c-2.5,0.1-5,0.3-7.5,0.7
          c-2.5,0.4-4.8,0.9-7.2,1.5c0.9,1.1,1.9,2.3,2.9,3.8c3.8,5.7,8.3,15.9,8.7,32.4l0,0.1l0,0c-7.8,5.9-8.5,20.1-8.6,20.2l0,0l0.2,0
          c0.2,6,0.4,13.1,0.5,20.7c0.3,14.4,0.7,30.2,1.4,39.9c1.3,4.3,2.9,8.6,4.5,12.8c3.3,8.9,6.8,18.1,7.6,27.7c0.1,1,0.2,2,0.2,3
          c24.4,1,41.9-8.2,52.5-16.4c4.9-3.8,8.9-7.8,11.8-11.2c-0.4-2.9-4.2-28.2-5.4-55.1c-0.7-16.7-0.3-30.7,1.2-41.7
          c1.9-13.7,5.6-22.7,11-26.7l0.1,0l0.1,0c5.4,4,9.1,12.9,11,26.7c0.9,6.4,1.4,13.7,1.5,22.1l72.3-1.9c-0.1-1.3-1.1-14.5-8.5-20.1
          l0,0l0-0.1C299.2,229.4,303.8,219.2,307.6,213.5z"/>
        <path class="st104" d="M305.3,327.1c-5-0.1-70.6-2.6-73.5-2.7c-1.1,9.9-2.2,16.9-2.4,18.4c2.9,3.4,6.9,7.4,11.8,11.2
				  c10.5,8.1,27.7,17.2,51.8,16.4c0.1-1,0.1-2,0.2-3.1c0.9-9.7,4.3-18.9,7.6-27.7C302.4,335.5,304,331.4,305.3,327.1z"/>
        <rect x="999.5" y="168.1" class="st104" width="45.7" height="45.7"/>
      </a>
      <path class="st103" d="M1177.5,185.2v2.4h-5.7v2.2c0.4-0.1,1.1-0.1,1.6-0.1c1.3,0,2.7,0.3,3.6,1.1c0.9,0.7,1.5,1.7,1.5,3.1
					c0,1.3-0.5,2.2-1.2,2.9c-1.2,1.1-3.3,1.5-5.2,1.5c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1177.5z M1171.8,196c0.3,0.1,0.7,0.1,1.1,0.1
					c0.7,0,1.4-0.2,1.9-0.7c0.4-0.3,0.6-0.9,0.6-1.5c0-0.7-0.3-1.1-0.7-1.5c-0.5-0.4-1.2-0.6-1.9-0.6c-0.3,0-0.7,0-1,0.1V196z"/>
      <path class="st103" d="M1192.2,191.5c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1190,185,1192.2,187.9,1192.2,191.5z M1182.8,191.7c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1184,187.3,1182.8,189.2,1182.8,191.7z"/>
      <path class="st103" d="M1203.6,185.2v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
					c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1203.6z"/>
      <path class="st103" d="M1209,185.2v4.5c0.4,0,1-0.1,1.6-0.1c2.8,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8c-1.2,1.3-3.4,1.6-5.4,1.6
					c-1.4,0-2.5-0.1-3.2-0.2v-12.8H1209z M1209,196c0.3,0.1,0.7,0.1,1.1,0.1c1.4,0,2.6-0.7,2.6-2.1c0-1.5-1.1-2.1-2.5-2.1
					c-0.4,0-0.8,0-1.1,0.1V196z"/>
      <path class="st103" d="M1232,185.2v12.9h-2.9v-10.5h-4.6v10.5h-2.9v-12.9H1232z"/>
      <path class="st103" d="M1242.2,192.6h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V192.6z"/>
      <path class="st103" d="M1244.7,185.4c0.9-0.2,2.2-0.3,3.9-0.3c1.8,0,3.1,0.3,3.9,1c0.8,0.7,1.4,1.7,1.4,3s-0.4,2.3-1.2,3.1
					c-1,0.9-2.5,1.4-4.2,1.4c-0.4,0-0.7,0-1-0.1v4.6h-2.9V185.4z M1247.6,191.2c0.2,0.1,0.6,0.1,1,0.1c1.5,0,2.5-0.8,2.5-2.1
					c0-1.2-0.8-1.9-2.3-1.9c-0.6,0-1,0.1-1.2,0.1V191.2z"/>
      <path class="st103" d="M1263.5,192.6h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V192.6z"/>
      <path class="st103" d="M1276.5,185.2v10.6l1.3,0.1l-0.1,5.5h-2.2l-0.2-3.3h-7.5l-0.2,3.3h-2.2l-0.1-5.5l1.1-0.1
					c0.5-0.9,0.9-1.9,1.2-2.9c0.4-1.3,0.5-2.7,0.5-4.3v-3.4H1276.5z M1270.7,189.3c0,1.5-0.2,2.9-0.6,4.2c-0.2,0.8-0.6,1.6-0.9,2.2
					h4.3v-8.2h-2.8V189.3z"/>
      <path class="st103" d="M1282.3,185.2v5h4.8v-5h2.9v12.9h-2.9v-5.4h-4.8v5.4h-2.9v-12.9H1282.3z"/>
      <path class="st103" d="M1300.2,192.6h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V192.6z"/>
      <path class="st103" d="M1305.5,185.2v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7v-3.8
					c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1305.5z M1307.3,182.2c0,0.7,0.2,1.2,0.8,1.2
					c0.6,0,0.8-0.4,0.9-1.2h2c-0.1,1.6-1.2,2.4-2.9,2.4c-1.7,0-2.6-0.7-2.8-2.4H1307.3z"/>
      <path class="st103" d="M1330.3,185.2v12.9h-2.9v-10.5h-4.6v10.5h-2.9v-12.9H1330.3z"/>
      <path class="st103" d="M1344.6,191.5c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1342.4,185,1344.6,187.9,1344.6,191.5z M1335.3,191.7c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1336.4,187.3,1335.3,189.2,1335.3,191.7z"/>
      <path class="st103" d="M1356,185.2v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
					c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1356z"/>
      <path class="st103" d="M1370.4,191.5c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1368.2,185,1370.4,187.9,1370.4,191.5z M1361.1,191.7c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1362.2,187.3,1361.1,189.2,1361.1,191.7z"/>
      <path class="st103" d="M1372.3,185.4c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
					c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
					V185.4z M1375.2,190.3h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V190.3z M1375.2,196c0.3,0,0.6,0,1.1,0
					c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V196z"/>
      <path class="st103" d="M1386.5,185.2v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7v-3.8
					c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1386.5z"/>
      <path class="st103" d="M1400,185.2v5h4.8v-5h2.9v12.9h-2.9v-5.4h-4.8v5.4h-2.9v-12.9H1400z"/>
      <path class="st103" d="M1413.1,185.2v4.5c0.4-0.1,1-0.1,1.4-0.1c2.7,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8
					c-1.2,1.3-3.4,1.6-5.3,1.6c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1413.1z M1413.1,196c0.2,0.1,0.6,0.1,1,0.1c1.3,0,2.5-0.7,2.5-2.2
					c0-1.5-1.4-2.1-2.5-2.1c-0.4,0-0.7,0-1,0.1V196z M1423.8,185.2v12.9h-2.9v-12.9H1423.8z"/>
      <path class="st103" d="M1438.2,185.2v2.4h-4.9v10.5h-2.9v-12.9H1438.2z"/>
      <path class="st103" d="M1439.7,185.4c0.9-0.2,2.2-0.3,3.9-0.3c1.8,0,3.1,0.3,3.9,1c0.8,0.7,1.4,1.7,1.4,3s-0.4,2.3-1.2,3.1
					c-1,0.9-2.5,1.4-4.2,1.4c-0.4,0-0.7,0-1-0.1v4.6h-2.9V185.4z M1442.6,191.2c0.2,0.1,0.6,0.1,1,0.1c1.5,0,2.5-0.8,2.5-2.1
					c0-1.2-0.8-1.9-2.3-1.9c-0.6,0-1,0.1-1.2,0.1V191.2z"/>
      <path class="st103" d="M1452.8,185.2l1.9,4.5c0.3,0.9,0.6,1.7,0.9,2.5h0.1c0.2-0.6,0.4-1.4,0.7-2.5l1.4-4.5h3.1l-2.4,6.3
					c-0.8,2-1.6,4-2.5,5.1c-0.8,1.1-1.9,1.7-3.4,1.7c-0.7,0-1.1-0.1-1.4-0.2l0.2-2.3c0.2,0.1,0.5,0.1,1,0.1c0.9,0,1.5-0.6,1.7-1.2
					c0.1-0.2,0.1-0.4,0-0.6l-4.3-8.9H1452.8z"/>
      <path class="st103" d="M1471.1,185.2v10.6l1.3,0.1l-0.1,5.5h-2.2l-0.2-3.3h-7.5l-0.2,3.3h-2.2l-0.1-5.5l1.1-0.1
					c0.5-0.9,0.9-1.9,1.2-2.9c0.4-1.3,0.5-2.7,0.5-4.3v-3.4H1471.1z M1465.4,189.3c0,1.5-0.2,2.9-0.6,4.2c-0.2,0.8-0.6,1.6-0.9,2.2
					h4.3v-8.2h-2.8V189.3z"/>
      <path class="st103" d="M1477,185.2v5h4.8v-5h2.9v12.9h-2.9v-5.4h-4.8v5.4h-2.9v-12.9H1477z"/>
      <path class="st103" d="M1499.1,191.5c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1496.9,185,1499.1,187.9,1499.1,191.5z M1489.7,191.7c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1490.9,187.3,1489.7,189.2,1489.7,191.7z"/>
      <path class="st103" d="M1503.6,185.2v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7v-3.8
					c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1503.6z M1505.4,182.2c0,0.7,0.2,1.2,0.8,1.2
					c0.6,0,0.8-0.4,0.9-1.2h2c-0.1,1.6-1.2,2.4-2.9,2.4c-1.7,0-2.6-0.7-2.8-2.4H1505.4z"/>
      <path class="st103" d="M1520.9,185.2v5.4h0.2l3.6-5.4h3.5l-4.5,5.7c1.7,0.4,2.4,1.6,2.9,3.1c0.5,1.4,0.9,2.8,1.5,4.1h-3.1
					c-0.4-0.9-0.7-1.9-1-2.8c-0.4-1.5-1-2.6-2.6-2.6h-0.6v5.4h-2.9v-12.9H1520.9z"/>
      <path class="st103" d="M1539.9,185.2v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
					c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1539.9z"/>
      <path class="st103" d="M1550.1,192.6h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V192.6z"/>
      <path class="st103" d="M1555.1,187.6h-3.5v-2.4h9.9v2.4h-3.5v10.4h-2.9V187.6z"/>
      <path class="st103" d="M1566,185.2v5.4h0.2l3.6-5.4h3.5l-4.5,5.7c1.7,0.4,2.4,1.6,2.9,3.1c0.5,1.4,0.9,2.8,1.5,4.1h-3.1
					c-0.4-0.9-0.7-1.9-1-2.8c-0.4-1.5-1-2.6-2.6-2.6h-0.6v5.4h-2.9v-12.9H1566z"/>
      <path class="st103" d="M1577.4,185.2v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7v-3.8
					c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1577.4z"/>
      <a id="ast105" v-on:click="toogleSvgClass($event,'ast105')" :class="this.questionary.painHeart ? 'svglinkActive' : 'svglink'">
        <path class="st105" d="M576.9,301.1c-15.2,0.5-28.6-0.9-30-1.1c0,1.4,0.1,13.7,0.7,26.8c1.3,4.3,2.9,8.6,4.5,12.7
          c2.9,7.7,5.8,15.5,7.1,23.8c24.9,1.2,42.5-8,53.3-16.3c3.5-2.7,5-4,7.4-6.5c0,0,0-30,0-50.1c-4.6,3.5-10.2,6.1-18.6,8
          C594.6,299.9,586.3,300.9,576.9,301.1z"/>
        <path class="st105" d="M231.8,324.2c3.1,0.1,69.1,2.6,73.5,2.7c0.1-0.2,0.1-0.4,0.2-0.6c1.3-12.5,1.9-57.4,1.9-59.9l-72.3,1.9
          c0.1,6,0,12.5-0.3,19.4C234.2,301.1,233,314.2,231.8,324.2z"/>
        <polygon class="st105" points="999.5,234.5 999.5,285.6 999.5,324.9 999.5,331.2 1045.1,331.2 1045.1,324.9 1045.1,285.6
          1045.1,234.5 		"/>
      </a>
      <a id="ast107" v-on:click="toogleSvgClass($event,'ast107')" :class="this.questionary.painCenterSpine ? 'svglinkActive' : 'svglink'">
        <path class="st107" d="M620,244.9c0.1,15.2,0.1,95.5,0.1,95.5c6.7-6.2,11-13.6,11-13.7l0.1-0.2l0.1,0.2c0,0.1,3.2,5.7,9.9,12.6
            c0-5.8,0-149.2,0-164.9c-3.1,0.8-6.8,1.3-10,1.3c-0.4,0-0.8,0-1.3,0c-2.9-0.1-6.9-0.7-9.8-1.4C620.1,188.6,619.8,212.3,620,244.9
            z"/>
        <rect x="999.5" y="412.5" class="st107" width="45.7" height="45.7"/>
      </a>
      <path class="st103" d="M1177.5,252.1v2.4h-5.7v2.2c0.4-0.1,1.1-0.1,1.6-0.1c1.3,0,2.7,0.3,3.6,1.1c0.9,0.7,1.5,1.7,1.5,3.1
					c0,1.3-0.5,2.2-1.2,2.9c-1.2,1.1-3.3,1.5-5.2,1.5c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1177.5z M1171.8,262.9c0.3,0.1,0.7,0.1,1.1,0.1
					c0.7,0,1.4-0.2,1.9-0.7c0.4-0.3,0.6-0.9,0.6-1.5c0-0.7-0.3-1.1-0.7-1.5c-0.5-0.4-1.2-0.6-1.9-0.6c-0.3,0-0.7,0-1,0.1V262.9z"/>
      <path class="st103" d="M1192.2,258.4c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1190,251.9,1192.2,254.9,1192.2,258.4z M1182.8,258.6c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1184,254.2,1182.8,256.1,1182.8,258.6z"/>
      <path class="st103" d="M1203.6,252.1V265h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
					c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1203.6z"/>
      <path class="st103" d="M1209,252.1v4.5c0.4,0,1-0.1,1.6-0.1c2.8,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8c-1.2,1.3-3.4,1.6-5.4,1.6
					c-1.4,0-2.5-0.1-3.2-0.2v-12.8H1209z M1209,262.9c0.3,0.1,0.7,0.1,1.1,0.1c1.4,0,2.6-0.7,2.6-2.1c0-1.5-1.1-2.1-2.5-2.1
					c-0.4,0-0.8,0-1.1,0.1V262.9z"/>
      <path class="st103" d="M1221.5,252.3c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
					c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
					V252.3z M1224.4,257.2h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V257.2z M1224.4,262.9c0.3,0,0.6,0,1.1,0
					c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V262.9z"/>
      <path class="st103" d="M1246.4,264.6c-0.5,0.3-1.7,0.6-3.3,0.6c-4.5,0-6.8-2.8-6.8-6.4c0-4.4,3.1-6.9,7-6.9c1.5,0,2.7,0.3,3.2,0.6
					l-0.6,2.3c-0.6-0.2-1.4-0.5-2.4-0.5c-2.3,0-4.1,1.4-4.1,4.3c0,2.6,1.5,4.2,4.1,4.2c0.9,0,1.9-0.2,2.4-0.4L1246.4,264.6z"/>
      <path class="st103" d="M1256,259.6h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V259.6z"/>
      <path class="st103" d="M1258.5,252.3c0.9-0.2,2.2-0.3,3.9-0.3c1.8,0,3.1,0.3,3.9,1c0.8,0.7,1.4,1.7,1.4,3s-0.4,2.3-1.2,3.1
					c-1,0.9-2.5,1.4-4.2,1.4c-0.4,0-0.7,0-1-0.1v4.6h-2.9V252.3z M1261.4,258.1c0.2,0.1,0.6,0.1,1,0.1c1.5,0,2.5-0.8,2.5-2.1
					c0-1.2-0.8-1.9-2.3-1.9c-0.6,0-1,0.1-1.2,0.1V258.1z"/>
      <path class="st103" d="M1278.7,252.1v10.6l1.3,0.1l-0.1,5.5h-2.2l-0.2-3.3h-7.5l-0.2,3.3h-2.2l-0.1-5.5l1.1-0.1
					c0.5-0.9,0.9-1.9,1.2-2.9c0.4-1.3,0.5-2.7,0.5-4.3v-3.4H1278.7z M1272.9,256.2c0,1.5-0.2,2.9-0.6,4.2c-0.2,0.8-0.6,1.6-0.9,2.2
					h4.3v-8.2h-2.8V256.2z"/>
      <path class="st103" d="M1284.5,252.1v10.5h4.5v-10.5h2.9v10.6l1.3,0.1l-0.1,5.5h-2.2l-0.2-3.3h-9.1v-12.9H1284.5z"/>
      <path class="st103" d="M1302.5,259.6h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V259.6z"/>
      <path class="st103" d="M1177.5,297.9v2.4h-5.7v2.2c0.4-0.1,1.1-0.1,1.6-0.1c1.3,0,2.7,0.3,3.6,1.1c0.9,0.7,1.5,1.7,1.5,3.1
					c0,1.3-0.5,2.2-1.2,2.9c-1.2,1.1-3.3,1.5-5.2,1.5c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1177.5z M1171.8,308.7c0.3,0.1,0.7,0.1,1.1,0.1
					c0.7,0,1.4-0.2,1.9-0.7c0.4-0.3,0.6-0.9,0.6-1.5c0-0.7-0.3-1.1-0.7-1.5c-0.5-0.4-1.2-0.6-1.9-0.6c-0.3,0-0.7,0-1,0.1V308.7z"/>
      <path class="st103" d="M1192.2,304.2c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1190,297.7,1192.2,300.7,1192.2,304.2z M1182.8,304.4c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1184,300,1182.8,301.9,1182.8,304.4z"/>
      <path class="st103" d="M1203.6,297.9v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
					c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1203.6z"/>
      <path class="st103" d="M1209,297.9v4.5c0.4,0,1-0.1,1.6-0.1c2.8,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8c-1.2,1.3-3.4,1.6-5.4,1.6
					c-1.4,0-2.5-0.1-3.2-0.2v-12.8H1209z M1209,308.7c0.3,0.1,0.7,0.1,1.1,0.1c1.4,0,2.6-0.7,2.6-2.1c0-1.5-1.1-2.1-2.5-2.1
					c-0.4,0-0.8,0-1.1,0.1V308.7z"/>
      <path class="st103" d="M1232,297.9v12.9h-2.9v-10.5h-4.6v10.5h-2.9v-12.9H1232z"/>
      <path class="st103" d="M1246.3,304.2c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1244.1,297.7,1246.3,300.7,1246.3,304.2z M1237,304.4c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1238.1,300,1237,301.9,1237,304.4z"/>
      <path class="st103" d="M1258,297.9v10.6l1.3,0.1l-0.1,5.5h-2.2l-0.2-3.3h-7.5l-0.2,3.3h-2.2l-0.1-5.5l1.1-0.1
					c0.5-0.9,0.9-1.9,1.2-2.9c0.4-1.3,0.5-2.7,0.5-4.3v-3.4H1258z M1252.2,302c0,1.5-0.2,2.9-0.6,4.2c-0.2,0.8-0.6,1.6-0.9,2.2h4.3
					v-8.2h-2.8V302z"/>
      <path class="st103" d="M1274.5,297.9v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
					c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1274.5z"/>
      <path class="st103" d="M1288.9,304.2c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1286.7,297.7,1288.9,300.7,1288.9,304.2z M1279.6,304.4c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1280.7,300,1279.6,301.9,1279.6,304.4z"/>
      <path class="st103" d="M1301.2,297.9v12.9h-2.9v-10.5h-4.6v10.5h-2.9v-12.9H1301.2z"/>
      <path class="st103" d="M1306.8,307.5l-0.9,3.3h-3l3.9-12.9h3.8l4,12.9h-3.1l-1-3.3H1306.8z M1310,305.3l-0.8-2.7
					c-0.2-0.8-0.5-1.7-0.7-2.5h0c-0.2,0.8-0.4,1.7-0.6,2.5l-0.8,2.7H1310z"/>
      <path class="st103" d="M1317.5,300.3h-3.5v-2.4h9.9v2.4h-3.5v10.4h-2.9V300.3z"/>
      <path class="st103" d="M1328.4,297.9v5.4h0.2l3.6-5.4h3.5l-4.5,5.7c1.7,0.4,2.4,1.6,2.9,3.1c0.5,1.4,0.9,2.8,1.5,4.1h-3.1
					c-0.4-0.9-0.7-1.9-1-2.8c-0.4-1.5-1-2.6-2.6-2.6h-0.6v5.4h-2.9v-12.9H1328.4z"/>
      <path class="st103" d="M1348.6,304.2c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1346.4,297.7,1348.6,300.7,1348.6,304.2z M1339.3,304.4c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1340.4,300,1339.3,301.9,1339.3,304.4z"/>
      <path class="st103" d="M1353.2,297.9v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7V307
					c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1353.2z M1355,294.9c0,0.7,0.2,1.2,0.8,1.2
					c0.6,0,0.8-0.4,0.9-1.2h2c-0.1,1.6-1.2,2.4-2.9,2.4c-1.7,0-2.6-0.7-2.8-2.4H1355z"/>
      <path class="st103" d="M1377,310.4c-0.5,0.3-1.7,0.6-3.3,0.6c-4.5,0-6.8-2.8-6.8-6.4c0-4.4,3.1-6.9,7-6.9c1.5,0,2.7,0.3,3.2,0.6
					l-0.6,2.3c-0.6-0.2-1.4-0.5-2.4-0.5c-2.3,0-4.1,1.4-4.1,4.3c0,2.6,1.5,4.2,4.1,4.2c0.9,0,1.9-0.2,2.4-0.4L1377,310.4z"/>
      <path class="st103" d="M1389.1,297.9v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
					c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1389.1z"/>
      <path class="st103" d="M1399.3,305.3h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V305.3z"/>
      <path class="st103" d="M1401.9,298.1c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
					c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
					V298.1z M1404.8,303h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V303z M1404.8,308.7c0.3,0,0.6,0,1.1,0
					c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V308.7z"/>
      <path class="st103" d="M1416.4,307.5l-0.9,3.3h-3l3.9-12.9h3.8l4,12.9h-3.1l-1-3.3H1416.4z M1419.7,305.3l-0.8-2.7
					c-0.2-0.8-0.5-1.7-0.7-2.5h0c-0.2,0.8-0.4,1.7-0.6,2.5l-0.8,2.7H1419.7z"/>
      <a id="ast106" v-on:click="toogleSvgClass($event,'ast106')" :class="this.questionary.painChest ? 'svglinkActive' : 'svglink'">
        <path class="st106" d="M222.6,219.6c-5.3,3.9-8.9,12.9-10.8,26.5c-1.5,11-1.9,25-1.2,41.7c1.2,26.4,4.8,51.2,5.4,54.9
          c4.3-5,6.4-8.8,6.5-8.8l0.1-0.2l0.1,0.2c0,0.1,2.2,3.8,6.5,8.8c0.6-3.7,4.2-28.5,5.4-54.9c0.7-16.7,0.3-30.7-1.2-41.7
          C231.5,232.4,227.9,223.5,222.6,219.6z"/>
        <rect x="999.5" y="345.6" class="st106" width="45.7" height="45.7"/>
      </a>
      <path class="st103" d="M1177.5,363.3v2.4h-5.7v2.2c0.4-0.1,1.1-0.1,1.6-0.1c1.3,0,2.7,0.3,3.6,1.1c0.9,0.7,1.5,1.7,1.5,3.1
					c0,1.3-0.5,2.2-1.2,2.9c-1.2,1.1-3.3,1.5-5.2,1.5c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1177.5z M1171.8,374.1c0.3,0.1,0.7,0.1,1.1,0.1
					c0.7,0,1.4-0.2,1.9-0.7c0.4-0.3,0.6-0.9,0.6-1.5c0-0.7-0.3-1.1-0.7-1.5c-0.5-0.4-1.2-0.6-1.9-0.6c-0.3,0-0.7,0-1,0.1V374.1z"/>
      <path class="st103" d="M1192.2,369.6c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1190,363.1,1192.2,366.1,1192.2,369.6z M1182.8,369.8c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1184,365.4,1182.8,367.3,1182.8,369.8z"/>
      <path class="st103" d="M1203.6,363.3v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
					c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1203.6z"/>
      <path class="st103" d="M1209,363.3v4.5c0.4,0,1-0.1,1.6-0.1c2.8,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8c-1.2,1.3-3.4,1.6-5.4,1.6
					c-1.4,0-2.5-0.1-3.2-0.2v-12.8H1209z M1209,374.1c0.3,0.1,0.7,0.1,1.1,0.1c1.4,0,2.6-0.7,2.6-2.1c0-1.5-1.1-2.1-2.5-2.1
					c-0.4,0-0.8,0-1.1,0.1V374.1z"/>
      <path class="st103" d="M1221.1,364.1c0.9-0.5,2.2-1,4-1c2.8,0,4.5,1.4,4.5,3.3c0,1.6-1.1,2.7-2.6,3v0c1.6,0.2,3,1.3,3,3.1
					c0,2.9-2.9,3.9-5.3,3.9c-1.3,0-2.8-0.3-3.9-0.9l0.6-2.2c0.6,0.3,1.9,0.8,3.1,0.8c1.5,0,2.4-0.6,2.4-1.7c0-1.4-1.5-1.7-2.7-1.7
					h-1.2v-2.1h1.2c1.3,0,2.3-0.6,2.3-1.6c0-0.8-0.7-1.4-2-1.4c-1,0-2.1,0.4-2.8,0.8L1221.1,364.1z"/>
      <path class="st103" d="M1234.9,372.9l-0.9,3.3h-3l3.9-12.9h3.8l4,12.9h-3.1l-1-3.3H1234.9z M1238.2,370.7l-0.8-2.7
					c-0.2-0.8-0.5-1.7-0.7-2.5h0c-0.2,0.8-0.4,1.7-0.6,2.5l-0.8,2.7H1238.2z"/>
      <path class="st103" d="M1256.1,363.3v2.4h-4.9v10.5h-2.9v-12.9H1256.1z"/>
      <path class="st103" d="M1257.7,363.5c0.9-0.2,2.2-0.3,3.9-0.3c1.8,0,3.1,0.3,3.9,1c0.8,0.7,1.4,1.7,1.4,3s-0.4,2.3-1.2,3.1
					c-1,0.9-2.5,1.4-4.2,1.4c-0.4,0-0.7,0-1-0.1v4.6h-2.9V363.5z M1260.5,369.4c0.2,0.1,0.6,0.1,1,0.1c1.5,0,2.5-0.8,2.5-2.1
					c0-1.2-0.8-1.9-2.3-1.9c-0.6,0-1,0.1-1.2,0.1V369.4z"/>
      <path class="st103" d="M1270.7,363.3l1.9,4.5c0.3,0.9,0.6,1.7,0.9,2.5h0.1c0.2-0.6,0.4-1.4,0.7-2.5l1.4-4.5h3.1l-2.4,6.3
					c-0.8,2-1.6,4-2.5,5.1c-0.8,1.1-1.9,1.7-3.4,1.7c-0.7,0-1.1-0.1-1.4-0.2l0.2-2.3c0.2,0.1,0.5,0.1,1,0.1c0.9,0,1.5-0.6,1.7-1.2
					c0.1-0.2,0.1-0.4,0-0.6l-4.3-8.9H1270.7z"/>
      <path class="st103" d="M1289.1,363.3v10.6l1.3,0.1l-0.1,5.5h-2.2l-0.2-3.3h-7.5l-0.2,3.3h-2.2l-0.1-5.5l1.1-0.1
					c0.5-0.9,0.9-1.9,1.2-2.9c0.4-1.3,0.5-2.7,0.5-4.3v-3.4H1289.1z M1283.3,367.4c0,1.5-0.2,2.9-0.6,4.2c-0.2,0.8-0.6,1.6-0.9,2.2
					h4.3v-8.2h-2.8V367.4z"/>
      <path class="st103" d="M1294.7,363.3v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7v-3.8
					c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1294.7z"/>
      <path class="st103" d="M1308.1,363.3v5h4.8v-5h2.9v12.9h-2.9v-5.4h-4.8v5.4h-2.9v-12.9H1308.1z"/>
      <path class="st103" d="M1330.2,369.6c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1328,363.1,1330.2,366.1,1330.2,369.6z M1320.9,369.8c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1322,365.4,1320.9,367.3,1320.9,369.8z"/>
      <path class="st103" d="M1334.8,363.3v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7v-3.8
					c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1334.8z M1336.6,360.4c0,0.7,0.2,1.2,0.8,1.2
					c0.6,0,0.8-0.4,0.9-1.2h2c-0.1,1.6-1.2,2.4-2.9,2.4c-1.7,0-2.6-0.7-2.8-2.4H1336.6z"/>

      <path class="st103" d="M1177.5,429.7v2.4h-5.7v2.2c0.4-0.1,1.1-0.1,1.6-0.1c1.3,0,2.7,0.3,3.6,1.1c0.9,0.7,1.5,1.7,1.5,3.1
					c0,1.3-0.5,2.2-1.2,2.9c-1.2,1.1-3.3,1.5-5.2,1.5c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1177.5z M1171.8,440.5c0.3,0.1,0.7,0.1,1.1,0.1
					c0.7,0,1.4-0.2,1.9-0.7c0.4-0.3,0.6-0.9,0.6-1.5c0-0.7-0.3-1.1-0.7-1.5c-0.5-0.4-1.2-0.6-1.9-0.6c-0.3,0-0.7,0-1,0.1V440.5z"/>
      <path class="st103" d="M1192.2,436c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1190,429.5,1192.2,432.5,1192.2,436z M1182.8,436.2c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1184,431.8,1182.8,433.7,1182.8,436.2z"/>
      <path class="st103" d="M1203.6,429.7v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
					c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1203.6z"/>
      <path class="st103" d="M1209,429.7v4.5c0.4,0,1-0.1,1.6-0.1c2.8,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8c-1.2,1.3-3.4,1.6-5.4,1.6
					c-1.4,0-2.5-0.1-3.2-0.2v-12.8H1209z M1209,440.5c0.3,0.1,0.7,0.1,1.1,0.1c1.4,0,2.6-0.7,2.6-2.1c0-1.5-1.1-2.1-2.5-2.1
					c-0.4,0-0.8,0-1.1,0.1V440.5z"/>
      <path class="st103" d="M1221.5,429.9c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
					c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
					V429.9z M1224.4,434.8h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V434.8z M1224.4,440.5c0.3,0,0.6,0,1.1,0
					c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V440.5z"/>
      <path class="st103" d="M1239.8,429.7v10.5h3.2v-10.5h2.9v10.5h3.2v-10.5h2.9v12.9H1237v-12.9H1239.8z"/>
      <path class="st103" d="M1262.1,437.1h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V437.1z"/>
      <path class="st103" d="M1267.3,429.7v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7v-3.8
					c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1267.3z M1269.1,426.8c0,0.7,0.2,1.2,0.8,1.2
					c0.6,0,0.8-0.4,0.9-1.2h2c-0.1,1.6-1.2,2.4-2.9,2.4c-1.7,0-2.6-0.7-2.8-2.4H1269.1z"/>
      <path class="st103" d="M1280.8,429.7v5h4.8v-5h2.9v12.9h-2.9v-5.4h-4.8v5.4h-2.9v-12.9H1280.8z"/>
      <path class="st103" d="M1302.8,436c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1300.7,429.5,1302.8,432.5,1302.8,436z M1293.5,436.2c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1294.6,431.8,1293.5,433.7,1293.5,436.2z"/>
      <path class="st103" d="M1315.5,437.7c-0.1-1.6-0.1-3.4-0.1-5.3h-0.1c-0.4,1.6-0.9,3.5-1.4,5l-1.6,5h-2.3l-1.4-5
					c-0.4-1.5-0.9-3.3-1.2-5h0c-0.1,1.7-0.1,3.7-0.2,5.3l-0.2,4.9h-2.7l0.8-12.9h3.9l1.3,4.3c0.4,1.5,0.8,3.1,1.1,4.6h0.1
					c0.4-1.5,0.8-3.2,1.2-4.6l1.4-4.3h3.8l0.7,12.9h-2.9L1315.5,437.7z"/>
      <path class="st103" d="M1327.4,429.7v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7v-3.8
					c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1327.4z"/>
      <path class="st103" d="M1349.5,429.7v2.4h-4.9v10.5h-2.9v-12.9H1349.5z"/>
      <path class="st103" d="M1351.1,429.9c0.9-0.2,2.2-0.3,3.9-0.3c1.8,0,3.1,0.3,3.9,1c0.8,0.7,1.4,1.7,1.4,3s-0.4,2.3-1.2,3.1
					c-1,0.9-2.5,1.4-4.2,1.4c-0.4,0-0.7,0-1-0.1v4.6h-2.9V429.9z M1354,435.7c0.2,0.1,0.6,0.1,1,0.1c1.5,0,2.5-0.8,2.5-2.1
					c0-1.2-0.8-1.9-2.3-1.9c-0.6,0-1,0.1-1.2,0.1V435.7z"/>
      <path class="st103" d="M1364.1,429.7l1.9,4.5c0.3,0.9,0.6,1.7,0.9,2.5h0.1c0.2-0.6,0.4-1.4,0.7-2.5l1.4-4.5h3.1l-2.4,6.3
					c-0.8,2-1.6,4-2.5,5.1c-0.8,1.1-1.9,1.7-3.4,1.7c-0.7,0-1.1-0.1-1.4-0.2l0.2-2.3c0.2,0.1,0.5,0.1,1,0.1c0.9,0,1.5-0.6,1.7-1.2
					c0.1-0.2,0.1-0.4,0-0.6l-4.3-8.9H1364.1z"/>
      <path class="st103" d="M1382.5,429.7v10.6l1.3,0.1l-0.1,5.5h-2.2l-0.2-3.3h-7.5l-0.2,3.3h-2.2l-0.1-5.5l1.1-0.1
					c0.5-0.9,0.9-1.9,1.2-2.9c0.4-1.3,0.5-2.7,0.5-4.3v-3.4H1382.5z M1376.8,433.8c0,1.5-0.2,2.9-0.6,4.2c-0.2,0.8-0.6,1.6-0.9,2.2
					h4.3v-8.2h-2.8V433.8z"/>
      <path class="st103" d="M1388.4,429.7v5h4.8v-5h2.9v12.9h-2.9v-5.4h-4.8v5.4h-2.9v-12.9H1388.4z"/>
      <path class="st103" d="M1410.4,436c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1408.3,429.5,1410.4,432.5,1410.4,436z M1401.1,436.2c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1402.2,431.8,1401.1,433.7,1401.1,436.2z"/>
      <path class="st103" d="M1423.1,437.7c-0.1-1.6-0.1-3.4-0.1-5.3h-0.1c-0.4,1.6-0.9,3.5-1.4,5l-1.6,5h-2.3l-1.4-5
					c-0.4-1.5-0.9-3.3-1.2-5h0c-0.1,1.7-0.1,3.7-0.2,5.3l-0.2,4.9h-2.7l0.8-12.9h3.9l1.3,4.3c0.4,1.5,0.8,3.1,1.1,4.6h0.1
					c0.4-1.5,0.8-3.2,1.2-4.6l1.4-4.3h3.8l0.7,12.9h-2.9L1423.1,437.7z"/>
      <path class="st103" d="M1444.1,436c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1441.9,429.5,1444.1,432.5,1444.1,436z M1434.8,436.2c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1435.9,431.8,1434.8,433.7,1434.8,436.2z"/>
      <path class="st103" d="M1448.3,432.1h-3.5v-2.4h9.9v2.4h-3.5v10.4h-2.9V432.1z"/>
      <path class="st103" d="M1465.4,429.7v10.6l1.3,0.1l-0.1,5.5h-2.2l-0.2-3.3h-7.5l-0.2,3.3h-2.2l-0.1-5.5l1.1-0.1
					c0.5-0.9,0.9-1.9,1.2-2.9c0.4-1.3,0.5-2.7,0.5-4.3v-3.4H1465.4z M1459.7,433.8c0,1.5-0.2,2.9-0.6,4.2c-0.2,0.8-0.6,1.6-0.9,2.2
					h4.3v-8.2h-2.8V433.8z"/>
      <path class="st103" d="M1476,437.1h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V437.1z"/>
      <path class="st103" d="M1488.6,429.7v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
					c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1488.6z"/>
      <path class="st103" d="M1498.8,437.1h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V437.1z"/>
      <path class="st103" d="M1515.7,429.7v12.9h-2.9v-10.5h-4.6v10.5h-2.9v-12.9H1515.7z"/>
      <path class="st103" d="M1530,436c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1527.8,429.5,1530,432.5,1530,436z M1520.7,436.2c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1521.8,431.8,1520.7,433.7,1520.7,436.2z"/>
      <path class="st103" d="M1531.2,430.5c0.9-0.5,2.2-1,4-1c2.8,0,4.5,1.4,4.5,3.3c0,1.6-1.1,2.7-2.6,3v0c1.6,0.2,3,1.3,3,3.1
					c0,2.9-2.9,3.9-5.3,3.9c-1.3,0-2.8-0.3-3.9-0.9l0.6-2.2c0.6,0.3,1.9,0.8,3.1,0.8c1.5,0,2.4-0.6,2.4-1.7c0-1.4-1.5-1.7-2.7-1.7
					h-1.2v-2.1h1.2c1.3,0,2.3-0.6,2.3-1.6c0-0.8-0.7-1.4-2-1.4c-1,0-2.1,0.4-2.8,0.8L1531.2,430.5z"/>
      <path class="st103" d="M1542.1,429.9c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
					c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
					V429.9z M1545,434.8h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V434.8z M1545,440.5c0.3,0,0.6,0,1.1,0
					c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V440.5z"/>
      <path class="st103" d="M1565.4,436c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1563.2,429.5,1565.4,432.5,1565.4,436z M1556,436.2c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1557.2,431.8,1556,433.7,1556,436.2z"/>
      <path class="st103" d="M1570.2,429.7v5h4.8v-5h2.9v12.9h-2.9v-5.4h-4.8v5.4h-2.9v-12.9H1570.2z"/>
      <path class="st103" d="M1592.2,436c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1590.1,429.5,1592.2,432.5,1592.2,436z M1582.9,436.2c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1584.1,431.8,1582.9,433.7,1582.9,436.2z"/>
      <path class="st103" d="M1596.8,429.7v3.7c0,1.5,0.8,2.2,2.2,2.2c0.7,0,1.3-0.2,1.9-0.5v-5.4h2.9v12.9h-2.9v-5.3h-0.1
					c-0.9,0.5-1.8,0.7-2.9,0.7c-2.3,0-4-1.2-4-4v-4.2H1596.8z"/>
      <path class="st103" d="M1609.3,429.7v5h4.8v-5h2.9v12.9h-2.9v-5.4h-4.8v5.4h-2.9v-12.9H1609.3z"/>
      <path class="st103" d="M1622.2,429.7v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7v-3.8
					c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1622.2z"/>
      <path class="st103" d="M1635.6,429.7v5.4h0.2l3.6-5.4h3.5l-4.5,5.7c1.7,0.4,2.4,1.6,2.9,3.1c0.5,1.4,0.9,2.8,1.5,4.1h-3.1
					c-0.4-0.9-0.7-1.9-1-2.8c-0.4-1.5-1-2.6-2.6-2.6h-0.6v5.4h-2.9v-12.9H1635.6z"/>
      <path class="st103" d="M1647.5,439.3l-0.9,3.3h-3l3.9-12.9h3.8l4,12.9h-3.1l-1-3.3H1647.5z M1650.8,437.1l-0.8-2.7
					c-0.2-0.8-0.5-1.7-0.7-2.5h0c-0.2,0.8-0.4,1.7-0.6,2.5l-0.8,2.7H1650.8z"/>
      <a id="ast108" v-on:click="toogleSvgClass($event,'ast108')" :class="this.questionary.painBack ? 'svglinkActive' : 'svglink'">
        <rect x="999.5" y="479.4" class="st108" width="45.7" height="45.7"/>
        <path class="st108" d="M715.3,265.6L715.3,265.6L715.3,265.6L715.3,265.6c0-0.2-0.8-14.4-8.6-20.3l0,0l0,0
          c-1.8-18.2-0.5-30.3,1-37.4c-1.1-0.1-2.2-0.2-3.3-0.2c-3.8-0.2-7.7-0.5-11.4-1.5c-3.2-0.9-6.3-2.4-9.3-3.8
          c-1.4-0.7-2.9-1.4-4.4-2c-8.3-3.5-13.4-8-16.7-14.4c-3.2-6.4-3.6-14-3.6-21.2c0-0.1,0-0.1,0-0.2c-1.7,1.6-3.6,3.1-5.5,4.4
          c-3.8,2.6-7.9,4.4-12.3,5.5c0,20.7,0,159.9,0,164.9c2.3,2.4,5.4,5.2,8.6,7.7c10.6,8.2,28,17.4,52.5,16.4
          c1.3-8.2,4.3-16.1,7.1-23.8c1.6-4.2,3.2-8.5,4.5-12.8C715.5,312.2,717.9,283.2,715.3,265.6z"/>
        <path class="st108" d="M620.1,174.2c-6.2-1.7-11.3-4-16.7-7.9c-0.2-0.2-0.5-0.4-0.7-0.5c-0.1,6.9-0.6,14-3.6,20.1
          c-3.2,6.4-8.4,10.8-16.7,14.4c-1.5,0.6-3,1.3-4.4,2c-3,1.4-6.1,2.9-9.3,3.8c-3.7,1.1-7.6,1.3-11.4,1.5c-1.3,0.1-2.5,0.2-3.8,0.3
          c1.5,7.1,2.8,19.2,1,37.4l0,0l0,0c-7.8,5.9-8.5,20.1-8.6,20.2l0,0h0c0.2,21.9,1,32.4,1.2,34.3l-0.1,0c0.1,0,14.1,1.6,30,1.1
          c9.4-0.3,17.6-1.2,24.5-2.7c8.5-1.9,14.6-4.5,18.7-8.2C620.1,258.1,620.1,199.1,620.1,174.2z"/>
      </a>
      <path class="st103" d="M1177.5,495.5v2.4h-5.7v2.2c0.4-0.1,1.1-0.1,1.6-0.1c1.3,0,2.7,0.3,3.6,1.1c0.9,0.7,1.5,1.7,1.5,3.1
					c0,1.3-0.5,2.2-1.2,2.9c-1.2,1.1-3.3,1.5-5.2,1.5c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1177.5z M1171.8,506.3c0.3,0.1,0.7,0.1,1.1,0.1
					c0.7,0,1.4-0.2,1.9-0.7c0.4-0.3,0.6-0.9,0.6-1.5c0-0.7-0.3-1.1-0.7-1.5c-0.5-0.4-1.2-0.6-1.9-0.6c-0.3,0-0.7,0-1,0.1V506.3z"/>
      <path class="st103" d="M1192.2,501.8c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1190,495.3,1192.2,498.3,1192.2,501.8z M1182.8,502c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1184,497.6,1182.8,499.5,1182.8,502z"/>
      <path class="st103" d="M1203.6,495.5v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
					c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1203.6z"/>
      <path class="st103" d="M1209,495.5v4.5c0.4,0,1-0.1,1.6-0.1c2.8,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8c-1.2,1.3-3.4,1.6-5.4,1.6
					c-1.4,0-2.5-0.1-3.2-0.2v-12.8H1209z M1209,506.3c0.3,0.1,0.7,0.1,1.1,0.1c1.4,0,2.6-0.7,2.6-2.1c0-1.5-1.1-2.1-2.5-2.1
					c-0.4,0-0.8,0-1.1,0.1V506.3z"/>
      <path class="st103" d="M1231,508c-0.5,0.3-1.7,0.6-3.3,0.6c-4.5,0-6.8-2.8-6.8-6.4c0-4.4,3.1-6.9,7-6.9c1.5,0,2.7,0.3,3.2,0.6
					l-0.6,2.3c-0.6-0.2-1.4-0.5-2.4-0.5c-2.3,0-4.1,1.4-4.1,4.3c0,2.6,1.5,4.2,4.1,4.2c0.9,0,1.9-0.2,2.4-0.4L1231,508z"/>
      <path class="st103" d="M1244.2,501.8c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1242.1,495.3,1244.2,498.3,1244.2,501.8z M1234.9,502c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1236.1,497.6,1234.9,499.5,1234.9,502z"/>
      <path class="st103" d="M1259.5,508c-0.5,0.3-1.7,0.6-3.3,0.6c-4.5,0-6.8-2.8-6.8-6.4c0-4.4,3.1-6.9,7-6.9c1.5,0,2.7,0.3,3.2,0.6
					l-0.6,2.3c-0.6-0.2-1.4-0.5-2.4-0.5c-2.3,0-4.1,1.4-4.1,4.3c0,2.6,1.5,4.2,4.1,4.2c0.9,0,1.9-0.2,2.4-0.4L1259.5,508z"/>
      <path class="st103" d="M1264.2,498h-3.5v-2.4h9.9v2.4h-3.5v10.4h-2.9V498z"/>
      <path class="st103" d="M1283.4,501.8c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1281.2,495.3,1283.4,498.3,1283.4,501.8z M1274.1,502c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1275.2,497.6,1274.1,499.5,1274.1,502z"/>
      <path class="st103" d="M1285.3,495.7c0.9-0.2,2.2-0.3,3.9-0.3c1.8,0,3.1,0.3,3.9,1c0.8,0.7,1.4,1.7,1.4,3c0,1.3-0.4,2.3-1.2,3.1
					c-1,0.9-2.5,1.4-4.2,1.4c-0.4,0-0.7,0-1-0.1v4.6h-2.9V495.7z M1288.2,501.5c0.2,0.1,0.6,0.1,1,0.1c1.5,0,2.5-0.8,2.5-2.1
					c0-1.2-0.8-1.9-2.3-1.9c-0.6,0-1,0.1-1.2,0.1V501.5z"/>
      <path class="st103" d="M1308.2,501.8c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1306,495.3,1308.2,498.3,1308.2,501.8z M1298.9,502c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1300,497.6,1298.9,499.5,1298.9,502z"/>
      <path class="st103" d="M1313,495.5v5h4.8v-5h2.9v12.9h-2.9V503h-4.8v5.4h-2.9v-12.9H1313z"/>
      <path class="st103" d="M1326.2,495.5v4.5c0.4-0.1,1-0.1,1.4-0.1c2.7,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8
					c-1.2,1.3-3.4,1.6-5.3,1.6c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1326.2z M1326.2,506.3c0.2,0.1,0.6,0.1,1,0.1c1.3,0,2.5-0.7,2.5-2.2
					c0-1.5-1.4-2.1-2.5-2.1c-0.4,0-0.7,0-1,0.1V506.3z M1336.8,495.5v12.9h-2.9v-12.9H1336.8z"/>
      <path class="st103" d="M1352.9,508c-0.5,0.3-1.7,0.6-3.3,0.6c-4.5,0-6.8-2.8-6.8-6.4c0-4.4,3.1-6.9,7-6.9c1.5,0,2.7,0.3,3.2,0.6
					l-0.6,2.3c-0.6-0.2-1.4-0.5-2.4-0.5c-2.3,0-4.1,1.4-4.1,4.3c0,2.6,1.5,4.2,4.1,4.2c0.9,0,1.9-0.2,2.4-0.4L1352.9,508z"/>
      <path class="st103" d="M1365.2,495.5v12.9h-2.9v-10.5h-4.6v10.5h-2.9v-12.9H1365.2z"/>
      <path class="st103" d="M1370.4,495.5v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7v-3.8
					c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1370.4z"/>
      <path class="st103" d="M1383.9,495.5v5h4.8v-5h2.9v12.9h-2.9V503h-4.8v5.4h-2.9v-12.9H1383.9z"/>
      <path class="st103" d="M1397,495.5v4.5c0.4-0.1,1-0.1,1.4-0.1c2.7,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8
					c-1.2,1.3-3.4,1.6-5.3,1.6c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1397z M1397,506.3c0.2,0.1,0.6,0.1,1,0.1c1.3,0,2.5-0.7,2.5-2.2
					c0-1.5-1.4-2.1-2.5-2.1c-0.4,0-0.7,0-1,0.1V506.3z M1407.7,495.5v12.9h-2.9v-12.9H1407.7z"/>
      <a id="ast109" v-on:click="toogleSvgClass($event,'ast109')" :class="this.questionary.painUpAbdominal ? 'svglinkActive' : 'svglink'">
        <rect x="1000.6" y="555.3" class="st109" width="45.7" height="45.7"/>
        <path class="st109" d="M290,370.6c-8.7,0-17-1.3-24.9-3.8c-8.7-2.8-16.8-7.1-24.1-12.7c-4.9-3.8-8.8-7.7-11.7-11.1c0,0,0,0,0,0
          l-0.2,0c0,0,0-0.1,0-0.2c-3.9-4.6-6.1-8.1-6.5-8.8c-0.5,0.8-2.6,4.3-6.5,8.8c0,0.1,0,0.2,0,0.2l-0.2,0c0,0,0,0,0,0
          c-2.9,3.4-6.8,7.3-11.7,11.1c-7.3,5.7-15.4,10-24.1,12.7c-8.9,2.9-18.5,4.1-28.5,3.7c0.5,7.9,0.3,16,0.2,23.8l0,1.4
          c-0.1,2.7,0,5.5,0,8.1c0,1.1,0,2.3,0,3.4h141c0-1.1,0-2.3,0-3.4c0-2.7,0.1-5.4,0-8.1l0-1.4c-0.2-7.8-0.3-15.8,0.2-23.7
          C292,370.6,291,370.6,290,370.6z"/>
      </a>
      <path class="st103" d="M1177.5,575.9v2.4h-5.7v2.2c0.4-0.1,1.1-0.1,1.6-0.1c1.3,0,2.7,0.3,3.6,1.1c0.9,0.7,1.5,1.7,1.5,3.1
					c0,1.3-0.5,2.2-1.2,2.9c-1.2,1.1-3.3,1.5-5.2,1.5c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1177.5z M1171.8,586.7c0.3,0.1,0.7,0.1,1.1,0.1
					c0.7,0,1.4-0.2,1.9-0.7c0.4-0.3,0.6-0.9,0.6-1.5c0-0.7-0.3-1.1-0.7-1.5c-0.5-0.4-1.2-0.6-1.9-0.6c-0.3,0-0.7,0-1,0.1V586.7z"/>
      <path class="st103" d="M1192.2,582.3c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1190,575.7,1192.2,578.7,1192.2,582.3z M1182.8,582.4c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1184,578,1182.8,579.9,1182.8,582.4z"/>
      <path class="st103" d="M1203.6,575.9v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
					c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1203.6z"/>
      <path class="st103" d="M1209,575.9v4.5c0.4,0,1-0.1,1.6-0.1c2.8,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8c-1.2,1.3-3.4,1.6-5.4,1.6
					c-1.4,0-2.5-0.1-3.2-0.2v-12.8H1209z M1209,586.7c0.3,0.1,0.7,0.1,1.1,0.1c1.4,0,2.6-0.7,2.6-2.1c0-1.5-1.1-2.1-2.5-2.1
					c-0.4,0-0.8,0-1.1,0.1V586.7z"/>
      <path class="st103" d="M1221.5,576.1c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
					c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
					V576.1z M1224.4,581h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V581z M1224.4,586.8c0.3,0,0.6,0,1.1,0
					c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V586.8z"/>
      <path class="st103" d="M1237,576.1c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5c0,1.1-0.7,2.2-2.1,2.8
					v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2V576.1z M1239.8,581h1
					c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V581z M1239.8,586.8c0.3,0,0.6,0,1.1,0c1.3,0,2.5-0.5,2.5-1.9
					c0-1.3-1.2-1.8-2.6-1.8h-1V586.8z"/>
      <path class="st103" d="M1256.2,583.4h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V583.4z"/>
      <path class="st103" d="M1258.7,576.1c0.9-0.2,2.2-0.3,3.9-0.3c1.8,0,3.1,0.3,3.9,1c0.8,0.7,1.4,1.7,1.4,3c0,1.3-0.4,2.3-1.2,3.1
					c-1,0.9-2.5,1.4-4.2,1.4c-0.4,0-0.7,0-1-0.1v4.6h-2.9V576.1z M1261.6,582c0.2,0.1,0.6,0.1,1,0.1c1.5,0,2.5-0.8,2.5-2.1
					c0-1.2-0.8-1.9-2.3-1.9c-0.6,0-1,0.1-1.2,0.1V582z"/>
      <path class="st103" d="M1276.1,588.8l-1.2-2.3c-0.5-0.9-0.8-1.6-1.1-2.3h0c-0.3,0.7-0.6,1.4-1,2.3l-1.1,2.3h-3.3l3.7-6.5l-3.6-6.4
					h3.3l1.1,2.4c0.4,0.8,0.7,1.4,1,2.1h0c0.3-0.8,0.6-1.4,0.9-2.1l1.1-2.4h3.3l-3.6,6.3l3.8,6.6H1276.1z"/>
      <path class="st103" d="M1284,575.9v5h4.8v-5h2.9v12.9h-2.9v-5.4h-4.8v5.4h-2.9v-12.9H1284z"/>
      <path class="st103" d="M1301.9,583.4h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V583.4z"/>
      <path class="st103" d="M1315.3,583.9c-0.1-1.5-0.1-3.4-0.1-5.3h-0.1c-0.4,1.6-0.9,3.5-1.4,5l-1.6,5h-2.3l-1.4-5
					c-0.4-1.5-0.9-3.3-1.2-5h0c-0.1,1.7-0.1,3.7-0.2,5.3l-0.2,4.9h-2.7l0.8-12.9h3.9l1.3,4.3c0.4,1.5,0.8,3.1,1.1,4.6h0.1
					c0.4-1.5,0.8-3.2,1.2-4.6l1.4-4.3h3.8l0.7,12.9h-2.9L1315.3,583.9z"/>
      <path class="st103" d="M1336.3,582.3c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1334.1,575.7,1336.3,578.7,1336.3,582.3z M1327,582.4c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1328.1,578,1327,579.9,1327,582.4z"/>
      <path class="st103" d="M1340.5,578.4h-3.5v-2.4h9.9v2.4h-3.5v10.4h-2.9V578.4z"/>
      <path class="st103" d="M1357.6,575.9v10.6l1.3,0.1l-0.1,5.5h-2.2l-0.2-3.3h-7.5l-0.2,3.3h-2.2l-0.1-5.5l1.1-0.1
					c0.5-0.9,0.9-1.9,1.2-2.9c0.4-1.3,0.5-2.7,0.5-4.3v-3.4H1357.6z M1351.9,580c0,1.5-0.2,2.9-0.6,4.2c-0.2,0.8-0.6,1.6-0.9,2.2
					h4.3v-8.2h-2.8V580z"/>
      <path class="st103" d="M1368.2,583.4h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V583.4z"/>
      <path class="st103" d="M1380.8,575.9v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
					c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1380.8z"/>
      <path class="st103" d="M1391,583.4h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V583.4z"/>
      <path class="st103" d="M1399.7,575.9l3.2,5.4h0.3v-5.4h2.8v5.4h0.3l3.2-5.4h3.4l-4.4,5.8c1.7,0.2,2.6,1.5,3.1,3.1
					c0.4,1.3,0.8,2.7,1.3,4h-2.9c-0.4-0.9-0.7-1.9-0.9-2.9c-0.4-1.4-1-2.5-2.6-2.5h-0.5v5.4h-2.8v-5.4h-0.5c-1.6,0-2.2,1.2-2.6,2.5
					c-0.3,0.9-0.5,2-0.9,2.9h-2.9c0.6-1.3,0.9-2.7,1.4-3.9c0.5-1.7,1.4-2.9,3.1-3.2l-4.4-5.8H1399.7z"/>
      <path class="st103" d="M1416.8,575.9v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7V585
					c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1416.8z"/>
      <path class="st103" d="M1427.4,576.1c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
					c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
					V576.1z M1430.2,581h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V581z M1430.2,586.8c0.3,0,0.6,0,1.1,0
					c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V586.8z"/>
      <path class="st103" d="M1450.6,582.3c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1448.5,575.7,1450.6,578.7,1450.6,582.3z M1441.3,582.4c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1442.5,578,1441.3,579.9,1441.3,582.4z"/>
      <path class="st103" d="M1454.9,578.4h-3.5v-2.4h9.9v2.4h-3.5v10.4h-2.9V578.4z"/>
      <path class="st103" d="M1464.4,585.5l-0.9,3.3h-3l3.9-12.9h3.8l4,12.9h-3.1l-1-3.3H1464.4z M1467.7,583.3l-0.8-2.7
					c-0.2-0.8-0.5-1.7-0.7-2.5h0c-0.2,0.8-0.4,1.7-0.6,2.5l-0.8,2.7H1467.7z"/>
      <a id="ast110" v-on:click="toogleSvgClass($event,'ast110')" :class="this.questionary.painLowAbdominal ? 'svglinkActive' : 'svglink'">
        <path class="st110" d="M234,532.7c8.9-11.2,5.6-35.6,5.6-35.8l0-0.1l0,0l18.9-17.7c-0.3-0.9-0.7-1.8-0.9-2.8
          c-1.7-6.1-2.1-15.1,5.7-23.8c7.8-8.6,19.5-10.8,28-11.1c3.7-0.1,7.1,0.1,9.9,0.3c-1.7-7.5-4.1-14.8-7.5-21.8
          c-0.1-0.2-0.4-1.5-0.5-2c-0.3-3.4-0.4-6.9-0.3-10.4h-141c0,3.5,0,7-0.3,10.4c0,0.5-0.4,1.8-0.5,2c-3.4,7-5.8,14.3-7.5,21.8
          c2.7-0.3,6.1-0.5,9.8-0.3c8.5,0.3,20.2,2.5,28,11.1c7.8,8.6,7.4,17.7,5.7,23.8c-0.3,1-0.6,1.9-0.9,2.8l18.9,17.8l0,0.1
          c0,0.2-3.4,24.6,5.6,35.8c2.5,3.1,5.6,4.9,9.5,5.5c0.5-0.1,1.1-0.2,1.8-0.2c0.2,0,0.4,0,0.5-0.1c0.2,0.1,0.4,0.1,0.5,0.1
          c0.6,0,1.2,0.1,1.7,0.2C228.4,537.6,231.5,535.7,234,532.7z"/>
        <rect x="1000.6" y="621.9" class="st110" width="45.7" height="45.7"/>
      </a>
      <path class="st103" d="M1177.5,639.2v2.4h-5.7v2.2c0.4-0.1,1.1-0.1,1.6-0.1c1.3,0,2.7,0.3,3.6,1.1c0.9,0.7,1.5,1.7,1.5,3.1
					c0,1.3-0.5,2.2-1.2,2.9c-1.2,1.1-3.3,1.5-5.2,1.5c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1177.5z M1171.8,649.9c0.3,0.1,0.7,0.1,1.1,0.1
					c0.7,0,1.4-0.2,1.9-0.7c0.4-0.3,0.6-0.9,0.6-1.5c0-0.7-0.3-1.1-0.7-1.5c-0.5-0.4-1.2-0.6-1.9-0.6c-0.3,0-0.7,0-1,0.1V649.9z"/>
      <path class="st103" d="M1192.2,645.5c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1190,639,1192.2,641.9,1192.2,645.5z M1182.8,645.7c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1184,641.3,1182.8,643.1,1182.8,645.7z"/>
      <path class="st103" d="M1203.6,639.2v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
					c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1203.6z"/>
      <path class="st103" d="M1209,639.2v4.5c0.4,0,1-0.1,1.6-0.1c2.8,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8c-1.2,1.3-3.4,1.6-5.4,1.6
					c-1.4,0-2.5-0.1-3.2-0.2v-12.8H1209z M1209,649.9c0.3,0.1,0.7,0.1,1.1,0.1c1.4,0,2.6-0.7,2.6-2.1c0-1.5-1.1-2.1-2.5-2.1
					c-0.4,0-0.8,0-1.1,0.1V649.9z"/>
      <path class="st103" d="M1221.5,639.3c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
					c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
					V639.3z M1224.4,644.3h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V644.3z M1224.4,650c0.3,0,0.6,0,1.1,0
					c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V650z"/>
      <path class="st103" d="M1239.9,639.2v5h4.8v-5h2.9v12.9h-2.9v-5.4h-4.8v5.4h-2.9v-12.9H1239.9z"/>
      <path class="st103" d="M1252.8,639.2v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7v-3.8
					c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1252.8z"/>
      <path class="st103" d="M1265.6,639.2l3.2,5.4h0.3v-5.4h2.8v5.4h0.3l3.2-5.4h3.4l-4.4,5.8c1.7,0.2,2.6,1.5,3.1,3.1
					c0.4,1.3,0.8,2.7,1.3,4h-2.9c-0.4-0.9-0.7-1.9-0.9-2.9c-0.4-1.4-1-2.5-2.6-2.5h-0.5v5.4h-2.8v-5.4h-0.5c-1.6,0-2.2,1.2-2.6,2.5
					c-0.3,0.9-0.5,2-0.9,2.9h-2.9c0.6-1.3,0.9-2.7,1.4-3.9c0.5-1.7,1.4-2.9,3.1-3.2l-4.4-5.8H1265.6z"/>
      <path class="st103" d="M1283.1,639.2v5h4.8v-5h2.9v12.9h-2.9v-5.4h-4.8v5.4h-2.9v-12.9H1283.1z"/>
      <path class="st103" d="M1301,646.6h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V646.6z"/>
      <path class="st103" d="M1314.4,647.1c-0.1-1.5-0.1-3.4-0.1-5.3h-0.1c-0.4,1.6-0.9,3.5-1.4,5l-1.6,5h-2.3l-1.4-5
					c-0.4-1.5-0.9-3.3-1.2-5h0c-0.1,1.7-0.1,3.7-0.2,5.3l-0.2,4.9h-2.7l0.8-12.9h3.9l1.3,4.3c0.4,1.5,0.8,3.1,1.1,4.6h0.1
					c0.4-1.5,0.8-3.2,1.2-4.6l1.4-4.3h3.8l0.7,12.9h-2.9L1314.4,647.1z"/>
      <path class="st103" d="M1335.4,645.5c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1333.2,639,1335.4,641.9,1335.4,645.5z M1326.1,645.7c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1327.2,641.3,1326.1,643.1,1326.1,645.7z"/>
      <path class="st103" d="M1339.6,641.6h-3.5v-2.4h9.9v2.4h-3.5v10.4h-2.9V641.6z"/>
      <path class="st103" d="M1356.7,639.2v10.6l1.3,0.1l-0.1,5.5h-2.2l-0.2-3.3h-7.5l-0.2,3.3h-2.2l-0.1-5.5l1.1-0.1
					c0.5-0.9,0.9-1.9,1.2-2.9c0.4-1.3,0.5-2.7,0.5-4.3v-3.4H1356.7z M1351,643.3c0,1.5-0.2,2.9-0.6,4.2c-0.2,0.8-0.6,1.6-0.9,2.2
					h4.3v-8.2h-2.8V643.3z"/>
      <path class="st103" d="M1367.3,646.6h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V646.6z"/>
      <path class="st103" d="M1379.9,639.2v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
					c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1379.9z"/>
      <path class="st103" d="M1390.1,646.6h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V646.6z"/>
      <path class="st103" d="M1398.8,639.2l3.2,5.4h0.3v-5.4h2.8v5.4h0.3l3.2-5.4h3.4l-4.4,5.8c1.7,0.2,2.6,1.5,3.1,3.1
					c0.4,1.3,0.8,2.7,1.3,4h-2.9c-0.4-0.9-0.7-1.9-0.9-2.9c-0.4-1.4-1-2.5-2.6-2.5h-0.5v5.4h-2.8v-5.4h-0.5c-1.6,0-2.2,1.2-2.6,2.5
					c-0.3,0.9-0.5,2-0.9,2.9h-2.9c0.6-1.3,0.9-2.7,1.4-3.9c0.5-1.7,1.4-2.9,3.1-3.2l-4.4-5.8H1398.8z"/>
      <path class="st103" d="M1416,639.2v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7v-3.8
					c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1416z"/>
      <path class="st103" d="M1426.5,639.3c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
					c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
					V639.3z M1429.4,644.3h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V644.3z M1429.4,650c0.3,0,0.6,0,1.1,0
					c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V650z"/>
      <path class="st103" d="M1449.8,645.5c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1447.6,639,1449.8,641.9,1449.8,645.5z M1440.4,645.7c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1441.6,641.3,1440.4,643.1,1440.4,645.7z"/>
      <path class="st103" d="M1454,641.6h-3.5v-2.4h9.9v2.4h-3.5v10.4h-2.9V641.6z"/>
      <path class="st103" d="M1463.5,648.8l-0.9,3.3h-3l3.9-12.9h3.8l4,12.9h-3.1l-1-3.3H1463.5z M1466.8,646.6l-0.8-2.7
					c-0.2-0.8-0.5-1.7-0.7-2.5h0c-0.2,0.8-0.4,1.7-0.6,2.5l-0.8,2.7H1466.8z"/>
      <a id="ast111" v-on:click="toogleSvgClass($event,'ast111')" :class="this.questionary.painLowBack ? 'svglinkActive' : 'svglink'">
        <path class="st111" d="M559.8,418.7c3.2,0.3,8.5,1.4,15.6,5.3c7.4,4,15.2,10.1,23.4,18.1c10.2,10,20.8,22.9,31.7,38.6
          c10.8-15.6,21.6-28.6,31.8-38.6c8.2-8,16.2-14.1,23.7-18.1c7.1-3.8,12.4-5,15.7-5.3c-0.1-0.3-0.1-0.6-0.1-0.7
          c-0.4-4.6-0.4-9.4-0.3-14c0-2.7,0.1-5.4,0-8.1l0-1.4c-0.2-8.9-0.4-18,0.4-27c0.1-1.3,0.3-2.5,0.5-3.7c-1.2,0-2.4,0.1-3.6,0.1
          c-8.7,0-17-1.3-24.9-3.8c-8.7-2.8-16.8-7.1-24.1-12.7c-11.6-9-17.6-18.8-18.4-20.1c-0.8,1.4-6.8,11.1-18.4,20.1
          c-7.3,5.7-15.4,10-24.1,12.7c-9.2,2.9-19,4.2-29.3,3.7c0.2,1.2,0.4,2.5,0.5,3.8c0.8,9,0.6,18.1,0.4,27l0,1.4
          c-0.1,2.7,0,5.5,0,8.1c0.1,4.6,0.1,9.4-0.3,14C560,418.1,559.9,418.4,559.8,418.7z"/>
        <rect x="1000.6" y="699" class="st111" width="45.7" height="45.7"/>
      </a>
      <path class="st103" d="M1177.5,714.6v2.4h-5.7v2.2c0.4-0.1,1.1-0.1,1.6-0.1c1.3,0,2.7,0.3,3.6,1.1c0.9,0.7,1.5,1.7,1.5,3.1
					c0,1.3-0.5,2.2-1.2,2.9c-1.2,1.1-3.3,1.5-5.2,1.5c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1177.5z M1171.8,725.3c0.3,0.1,0.7,0.1,1.1,0.1
					c0.7,0,1.4-0.2,1.9-0.7c0.4-0.3,0.6-0.9,0.6-1.5c0-0.7-0.3-1.1-0.7-1.5c-0.5-0.4-1.2-0.6-1.9-0.6c-0.3,0-0.7,0-1,0.1V725.3z"/>
      <path class="st103" d="M1192.2,720.9c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1190,714.3,1192.2,717.3,1192.2,720.9z M1182.8,721c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1184,716.7,1182.8,718.5,1182.8,721z"/>
      <path class="st103" d="M1203.6,714.6v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
					c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1203.6z"/>
      <path class="st103" d="M1209,714.6v4.5c0.4,0,1-0.1,1.6-0.1c2.8,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8c-1.2,1.3-3.4,1.6-5.4,1.6
					c-1.4,0-2.5-0.1-3.2-0.2v-12.8H1209z M1209,725.3c0.3,0.1,0.7,0.1,1.1,0.1c1.4,0,2.6-0.7,2.6-2.1c0-1.5-1.1-2.1-2.5-2.1
					c-0.4,0-0.8,0-1.1,0.1V725.3z"/>
      <path class="st103" d="M1221.5,714.7c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
					c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
					V714.7z M1224.4,719.7h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V719.7z M1224.4,725.4c0.3,0,0.6,0,1.1,0
					c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V725.4z"/>
      <path class="st103" d="M1247.4,714.6v12.9h-2.9v-10.5h-4.6v10.5h-2.9v-12.9H1247.4z"/>
      <path class="st103" d="M1261.7,720.9c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1259.5,714.3,1261.7,717.3,1261.7,720.9z M1252.4,721c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1253.5,716.7,1252.4,718.5,1252.4,721z"/>
      <path class="st103" d="M1262.4,727.5c0.3-0.4,0.5-0.9,0.7-1.5c0.5-1.5,0.8-3,1.8-3.9c0.3-0.3,0.7-0.5,1.1-0.7v-0.1
					c-1.4-0.3-2.7-1.3-2.7-3.1c0-1.2,0.5-2.1,1.3-2.7c1-0.8,2.6-1,4.2-1c1.4,0,2.8,0.1,3.7,0.3v12.7h-2.9v-5.1h-0.7
					c-0.6,0-1,0.2-1.4,0.5c-0.8,0.8-1.1,2.4-1.5,3.5c-0.1,0.4-0.2,0.7-0.5,1.1H1262.4z M1269.6,716.7c-0.2,0-0.6-0.1-1.2-0.1
					c-1.3,0-2.3,0.5-2.3,1.9c0,1.2,1.1,1.8,2.4,1.8c0.4,0,0.8,0,1.1-0.1V716.7z"/>
      <path class="st103" d="M1284.5,727.1c-0.5,0.3-1.7,0.6-3.3,0.6c-4.5,0-6.8-2.8-6.8-6.4c0-4.4,3.1-6.9,7-6.9c1.5,0,2.7,0.3,3.2,0.6
					l-0.6,2.3c-0.6-0.2-1.4-0.5-2.4-0.5c-2.3,0-4.1,1.4-4.1,4.3c0,2.6,1.5,4.2,4.1,4.2c0.9,0,1.9-0.2,2.4-0.4L1284.5,727.1z"/>
      <path class="st103" d="M1289.3,714.6v5h4.8v-5h2.9v12.9h-2.9v-5.4h-4.8v5.4h-2.9v-12.9H1289.3z"/>
      <path class="st103" d="M1302.2,714.6v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7v-3.8
					c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1302.2z"/>
      <path class="st103" d="M1315.7,714.6v10.5h4.5v-10.5h2.9v10.6l1.3,0.1l-0.1,5.5h-2.2l-0.2-3.3h-9.1v-12.9H1315.7z"/>
      <path class="st103" d="M1333.7,722h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V722z"/>

      <path class="st103" d="M1177.5,838v2.4h-5.7v2.2c0.4-0.1,1.1-0.1,1.6-0.1c1.3,0,2.7,0.3,3.6,1.1c0.9,0.7,1.5,1.7,1.5,3.1
					c0,1.3-0.5,2.2-1.2,2.9c-1.2,1.1-3.3,1.5-5.2,1.5c-1.4,0-2.5-0.1-3.1-0.2V838H1177.5z M1171.8,848.8c0.3,0.1,0.7,0.1,1.1,0.1
					c0.7,0,1.4-0.2,1.9-0.7c0.4-0.3,0.6-0.9,0.6-1.5c0-0.7-0.3-1.1-0.7-1.5c-0.5-0.4-1.2-0.6-1.9-0.6c-0.3,0-0.7,0-1,0.1V848.8z"/>
      <path class="st103" d="M1192.2,844.4c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1190,837.8,1192.2,840.8,1192.2,844.4z M1182.8,844.5c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1184,840.1,1182.8,842,1182.8,844.5z"/>
      <path class="st103" d="M1203.6,838v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
					c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4V838H1203.6z"/>
      <path class="st103" d="M1209,838v4.5c0.4,0,1-0.1,1.6-0.1c2.8,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8c-1.2,1.3-3.4,1.6-5.4,1.6
					c-1.4,0-2.5-0.1-3.2-0.2V838H1209z M1209,848.8c0.3,0.1,0.7,0.1,1.1,0.1c1.4,0,2.6-0.7,2.6-2.1c0-1.5-1.1-2.1-2.5-2.1
					c-0.4,0-0.8,0-1.1,0.1V848.8z"/>
      <path class="st103" d="M1221.5,838.2c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
					c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
					V838.2z M1224.4,843.2h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V843.2z M1224.4,848.9c0.3,0,0.6,0,1.1,0
					c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V848.9z"/>
      <path class="st103" d="M1239.8,838v5.4h0.2l3.6-5.4h3.5l-4.5,5.7c1.7,0.4,2.4,1.6,2.9,3.1c0.5,1.4,0.9,2.8,1.5,4.1h-3.1
					c-0.4-0.9-0.7-1.9-1-2.8c-0.4-1.5-1-2.6-2.6-2.6h-0.6v5.4h-2.9V838H1239.8z"/>
      <path class="st103" d="M1260,844.4c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1257.8,837.8,1260,840.8,1260,844.4z M1250.7,844.5c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1251.8,840.1,1250.7,842,1250.7,844.5z"/>
      <path class="st103" d="M1271.4,850.6c-0.5,0.3-1.7,0.6-3.3,0.6c-4.5,0-6.8-2.8-6.8-6.4c0-4.4,3.1-6.9,7-6.9c1.5,0,2.7,0.3,3.2,0.6
					l-0.6,2.3c-0.6-0.2-1.4-0.5-2.4-0.5c-2.3,0-4.1,1.4-4.1,4.3c0,2.6,1.5,4.2,4.1,4.2c0.9,0,1.9-0.2,2.4-0.4L1271.4,850.6z"/>
      <path class="st103" d="M1276.1,840.5h-3.5V838h9.9v2.4h-3.5v10.4h-2.9V840.5z"/>
      <path class="st103" d="M1282.8,850.9c0.3-0.4,0.5-0.9,0.7-1.5c0.5-1.5,0.8-3,1.8-3.9c0.3-0.3,0.7-0.5,1.1-0.7v-0.1
					c-1.4-0.3-2.7-1.3-2.7-3.1c0-1.2,0.5-2.1,1.3-2.7c1-0.8,2.6-1,4.2-1c1.4,0,2.8,0.1,3.7,0.3v12.7h-2.9v-5.1h-0.7
					c-0.6,0-1,0.2-1.4,0.5c-0.8,0.8-1.1,2.4-1.5,3.5c-0.1,0.4-0.2,0.7-0.5,1.1H1282.8z M1290,840.2c-0.2,0-0.6-0.1-1.2-0.1
					c-1.3,0-2.3,0.5-2.3,1.9c0,1.2,1.1,1.8,2.4,1.8c0.4,0,0.8,0,1.1-0.1V840.2z"/>
      <path class="st103" d="M1302.2,850.9l-1.2-2.3c-0.5-0.9-0.8-1.6-1.1-2.3h0c-0.3,0.7-0.6,1.4-1,2.3l-1.1,2.3h-3.3l3.7-6.5l-3.6-6.4
					h3.3l1.1,2.4c0.4,0.8,0.7,1.4,1,2.1h0c0.3-0.8,0.6-1.4,0.9-2.1l1.1-2.4h3.3l-3.6,6.3l3.8,6.6H1302.2z"/>
      <path class="st103" d="M1177.5,792.5v2.4h-5.7v2.2c0.4-0.1,1.1-0.1,1.6-0.1c1.3,0,2.7,0.3,3.6,1.1c0.9,0.7,1.5,1.7,1.5,3.1
					c0,1.3-0.5,2.2-1.2,2.9c-1.2,1.1-3.3,1.5-5.2,1.5c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1177.5z M1171.8,803.3c0.3,0.1,0.7,0.1,1.1,0.1
					c0.7,0,1.4-0.2,1.9-0.7c0.4-0.3,0.6-0.9,0.6-1.5c0-0.7-0.3-1.1-0.7-1.5c-0.5-0.4-1.2-0.6-1.9-0.6c-0.3,0-0.7,0-1,0.1V803.3z"/>
      <path class="st103" d="M1192.2,798.8c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1190,792.3,1192.2,795.2,1192.2,798.8z M1182.8,799c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1184,794.6,1182.8,796.5,1182.8,799z"/>
      <path class="st103" d="M1203.6,792.5v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
					c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1203.6z"/>
      <path class="st103" d="M1209,792.5v4.5c0.4,0,1-0.1,1.6-0.1c2.8,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8c-1.2,1.3-3.4,1.6-5.4,1.6
					c-1.4,0-2.5-0.1-3.2-0.2v-12.8H1209z M1209,803.3c0.3,0.1,0.7,0.1,1.1,0.1c1.4,0,2.6-0.7,2.6-2.1c0-1.5-1.1-2.1-2.5-2.1
					c-0.4,0-0.8,0-1.1,0.1V803.3z"/>
      <path class="st103" d="M1221.5,792.7c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
					c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
					V792.7z M1224.4,797.6h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V797.6z M1224.4,803.3c0.3,0,0.6,0,1.1,0
					c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V803.3z"/>
      <path class="st103" d="M1247.9,800.5c-0.1-1.5-0.1-3.4-0.1-5.3h-0.1c-0.4,1.6-0.9,3.5-1.4,5l-1.6,5h-2.3l-1.4-5
					c-0.4-1.5-0.9-3.3-1.2-5h0c-0.1,1.7-0.1,3.7-0.2,5.3l-0.2,4.9h-2.7l0.8-12.9h3.9l1.3,4.3c0.4,1.5,0.8,3.1,1.1,4.6h0.1
					c0.4-1.5,0.8-3.2,1.2-4.6l1.4-4.3h3.8l0.7,12.9h-2.9L1247.9,800.5z"/>
      <path class="st103" d="M1256,792.5v4.5c0.4-0.1,1-0.1,1.4-0.1c2.7,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8
					c-1.2,1.3-3.4,1.6-5.3,1.6c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1256z M1256,803.3c0.2,0.1,0.6,0.1,1,0.1c1.3,0,2.5-0.7,2.5-2.2
					c0-1.5-1.4-2.1-2.5-2.1c-0.4,0-0.7,0-1,0.1V803.3z M1266.7,792.5v12.9h-2.9v-12.9H1266.7z"/>
      <path class="st103" d="M1272.3,792.5V803h3.2v-10.5h2.9V803h3.2v-10.5h2.9v12.9h-14.9v-12.9H1272.3z"/>
      <path class="st103" d="M1289.8,792.5V803h4.5v-10.5h2.9v10.6l1.3,0.1l-0.1,5.5h-2.2l-0.2-3.3h-9.1v-12.9H1289.8z"/>
      <path class="st103" d="M1303.3,802.1l-0.9,3.3h-3l3.9-12.9h3.8l4,12.9h-3.1l-1-3.3H1303.3z M1306.6,799.9l-0.8-2.7
					c-0.2-0.8-0.5-1.7-0.7-2.5h0c-0.2,0.8-0.4,1.7-0.6,2.5l-0.8,2.7H1306.6z"/>
      <path class="st103" d="M1319.7,805.4l-1.2-2.3c-0.5-0.9-0.8-1.6-1.1-2.3h0c-0.3,0.7-0.6,1.4-1,2.3l-1.1,2.3h-3.3l3.7-6.5l-3.6-6.4
					h3.3l1.1,2.4c0.4,0.8,0.7,1.4,1,2.1h0c0.3-0.8,0.6-1.4,0.9-2.1l1.1-2.4h3.3l-3.6,6.3l3.8,6.6H1319.7z"/>

      <path class="st103" d="M1177.5,906.7v2.4h-5.7v2.2c0.4-0.1,1.1-0.1,1.6-0.1c1.3,0,2.7,0.3,3.6,1.1c0.9,0.7,1.5,1.7,1.5,3.1
					c0,1.3-0.5,2.2-1.2,2.9c-1.2,1.1-3.3,1.5-5.2,1.5c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1177.5z M1171.8,917.5c0.3,0.1,0.7,0.1,1.1,0.1
					c0.7,0,1.4-0.2,1.9-0.7c0.4-0.3,0.6-0.9,0.6-1.5c0-0.7-0.3-1.1-0.7-1.5c-0.5-0.4-1.2-0.6-1.9-0.6c-0.3,0-0.7,0-1,0.1V917.5z"/>
      <path class="st103" d="M1192.2,913.1c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
					C1190,906.5,1192.2,909.5,1192.2,913.1z M1182.8,913.2c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
					C1184,908.9,1182.8,910.7,1182.8,913.2z"/>
      <path class="st103" d="M1203.6,906.7v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
					c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1203.6z"/>
      <path class="st103" d="M1209,906.7v4.5c0.4,0,1-0.1,1.6-0.1c2.8,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8c-1.2,1.3-3.4,1.6-5.4,1.6
					c-1.4,0-2.5-0.1-3.2-0.2v-12.8H1209z M1209,917.5c0.3,0.1,0.7,0.1,1.1,0.1c1.4,0,2.6-0.7,2.6-2.1c0-1.5-1.1-2.1-2.5-2.1
					c-0.4,0-0.8,0-1.1,0.1V917.5z"/>
      <path class="st103" d="M1221.5,906.9c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
					c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
					V906.9z M1224.4,911.9h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V911.9z M1224.4,917.6c0.3,0,0.6,0,1.1,0
					c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V917.6z"/>
      <path class="st103" d="M1246.4,919.3c-0.5,0.3-1.7,0.6-3.3,0.6c-4.5,0-6.8-2.8-6.8-6.4c0-4.4,3.1-6.9,7-6.9c1.5,0,2.7,0.3,3.2,0.6
					l-0.6,2.3c-0.6-0.2-1.4-0.5-2.4-0.5c-2.3,0-4.1,1.4-4.1,4.3c0,2.6,1.5,4.2,4.1,4.2c0.9,0,1.9-0.2,2.4-0.4L1246.4,919.3z"/>
      <path class="st103" d="M1250.7,906.7l1.9,4.5c0.3,0.9,0.6,1.7,0.9,2.5h0.1c0.2-0.6,0.4-1.4,0.7-2.5l1.4-4.5h3.1l-2.4,6.3
					c-0.8,2-1.6,4-2.5,5.1c-0.8,1.1-1.9,1.7-3.4,1.7c-0.7,0-1.1-0.1-1.4-0.2l0.2-2.3c0.2,0.1,0.5,0.1,1,0.1c0.9,0,1.5-0.6,1.7-1.2
					c0.1-0.2,0.1-0.4,0-0.6l-4.3-8.9H1250.7z"/>
      <path class="st103" d="M1269,919.3c-0.5,0.3-1.7,0.6-3.3,0.6c-4.5,0-6.8-2.8-6.8-6.4c0-4.4,3.1-6.9,7-6.9c1.5,0,2.7,0.3,3.2,0.6
					l-0.6,2.3c-0.6-0.2-1.4-0.5-2.4-0.5c-2.3,0-4.1,1.4-4.1,4.3c0,2.6,1.5,4.2,4.1,4.2c0.9,0,1.9-0.2,2.4-0.4L1269,919.3z"/>
      <path class="st103" d="M1273.8,909.2h-3.5v-2.4h9.9v2.4h-3.5v10.4h-2.9V909.2z"/>
      <path class="st103" d="M1283.3,916.3l-0.9,3.3h-3l3.9-12.9h3.8l4,12.9h-3.1l-1-3.3H1283.3z M1286.6,914.2l-0.8-2.7
					c-0.2-0.8-0.5-1.7-0.7-2.5h0c-0.2,0.8-0.4,1.7-0.6,2.5l-0.8,2.7H1286.6z"/>
      <path class="st103" d="M1292.9,906.9c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
					c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
					V906.9z M1295.8,911.9h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V911.9z M1295.8,917.6c0.3,0,0.6,0,1.1,0
					c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V917.6z"/>
      <path class="st103" d="M1307.4,916.3l-0.9,3.3h-3l3.9-12.9h3.8l4,12.9h-3.1l-1-3.3H1307.4z M1310.7,914.2l-0.8-2.7
					c-0.2-0.8-0.5-1.7-0.7-2.5h0c-0.2,0.8-0.4,1.7-0.6,2.5l-0.8,2.7H1310.7z"/>
      <path class="st103" d="M1323.7,919.6l-1.2-2.3c-0.5-0.9-0.8-1.6-1.1-2.3h0c-0.3,0.7-0.6,1.4-1,2.3l-1.1,2.3h-3.3l3.7-6.5l-3.6-6.4
					h3.3l1.1,2.4c0.4,0.8,0.7,1.4,1,2.1h0c0.3-0.8,0.6-1.4,0.9-2.1l1.1-2.4h3.3l-3.6,6.3l3.8,6.6H1323.7z"/>
<!--      <path class="st114" d="M892.6,68.1l-0.7,2.6h-2.4l3.1-10h3l3.1,10h-2.4l-0.8-2.6H892.6z M895.1,66.4l-0.6-2.1-->
<!--					c-0.2-0.6-0.4-1.3-0.5-1.9h0c-0.1,0.6-0.3,1.4-0.5,1.9l-0.6,2.1H895.1z"/>-->
<!--      <path class="st114" d="M902.3,60.6v3.9h3.7v-3.9h2.3v10H906v-4.2h-3.7v4.2H900v-10H902.3z"/>-->
<!--      <path class="st114" d="M912.5,60.6v4.2h0.2l2.8-4.2h2.8l-3.5,4.4c1.3,0.3,1.9,1.2,2.3,2.4c0.4,1.1,0.7,2.2,1.2,3.2h-2.4-->
<!--					c-0.3-0.7-0.5-1.5-0.8-2.2c-0.3-1.1-0.8-2-2-2h-0.4v4.2h-2.2v-10H912.5z"/>-->
<!--      <path class="st114" d="M925.2,66.4h-3.7v2.4h4.1v1.9h-6.4v-10h6.2v1.9h-3.9v2.1h3.7V66.4z"/>-->
<!--      <path class="st114" d="M929.1,62.5h-2.7v-1.9h7.7v1.9h-2.7v8.1h-2.3V62.5z"/>-->
<!--      <path class="st114" d="M937.6,60.6v3.5c0.3,0,0.7-0.1,1.1-0.1c2.1,0,4,1,4,3.3c0,0.9-0.3,1.6-0.8,2.2c-1,1-2.6,1.3-4.1,1.3-->
<!--					c-1.1,0-1.9-0.1-2.4-0.1v-10H937.6z M937.6,69c0.2,0,0.5,0.1,0.8,0.1c1,0,1.9-0.6,1.9-1.7c0-1.1-1.1-1.6-1.9-1.6-->
<!--					c-0.3,0-0.6,0-0.8,0.1V69z M945.9,60.6v10h-2.3v-10H945.9z"/>-->
<!--      <path class="st114" d="M954,70.7V61h2.5l1.6,3.6c0.4,1,0.9,2.2,1.3,3.2l0,0c-0.1-1.2-0.2-2.6-0.2-4.1V61h1.9v9.7h-2.3l-1.7-3.7-->
<!--					c-0.4-1-0.9-2.2-1.3-3.3h0c0.1,1.2,0.1,2.6,0.1,4.3v2.8H954z M967.1,63.8c0,1.9-1.2,2.7-2.6,2.7c-1.5,0-2.6-0.9-2.6-2.6-->
<!--					c0-1.6,1.1-2.7,2.6-2.7C966.1,61.2,967.1,62.3,967.1,63.8z M962.3,68.2V67h4.4v1.1H962.3z M963.6,63.9c0,0.8,0.4,1.5,0.9,1.5-->
<!--					c0.6,0,0.9-0.6,0.9-1.5c0-0.7-0.3-1.5-0.9-1.5S963.6,63.2,963.6,63.9z"/>-->
<!--      <path class="st114" d="M1091,60.6v4.2h0.2l2.8-4.2h2.8l-3.5,4.4c1.3,0.3,1.9,1.2,2.3,2.4c0.4,1.1,0.7,2.2,1.2,3.2h-2.4-->
<!--					c-0.3-0.7-0.5-1.5-0.8-2.2c-0.3-1.1-0.8-2-2-2h-0.4v4.2h-2.2v-10H1091z"/>-->
<!--      <path class="st114" d="M1106.7,65.5c0,3.3-2,5.3-4.9,5.3c-3,0-4.7-2.2-4.7-5.1c0-3,1.9-5.3,4.9-5.3-->
<!--					C1105,60.5,1106.7,62.8,1106.7,65.5z M1099.4,65.7c0,2,0.9,3.3,2.4,3.3c1.5,0,2.4-1.5,2.4-3.4c0-1.8-0.9-3.3-2.4-3.3-->
<!--					C1100.3,62.3,1099.4,63.7,1099.4,65.7z"/>-->
<!--      <path class="st114" d="M1115.8,60.6v8.2l1,0.1l-0.1,4.3h-1.7l-0.1-2.5h-5.9l-0.1,2.5h-1.7l-0.1-4.3l0.8-0.1-->
<!--					c0.4-0.7,0.7-1.4,1-2.2c0.3-1,0.4-2.1,0.4-3.3v-2.7H1115.8z M1111.3,63.8c0,1.2-0.1,2.3-0.4,3.3c-0.2,0.6-0.4,1.2-0.7,1.7h3.3-->
<!--					v-6.3h-2.2V63.8z"/>-->
      <path class="st114" d="M1170.9,60.6v3.9h3.7v-3.9h2.3v10h-2.3v-4.2h-3.7v4.2h-2.3v-10H1170.9z"/>
      <path class="st114" d="M1181.2,68.1l-0.7,2.6h-2.4l3.1-10h3l3.1,10h-2.4l-0.8-2.6H1181.2z M1183.8,66.4l-0.6-2.1
					c-0.2-0.6-0.4-1.3-0.5-1.9h0c-0.1,0.6-0.3,1.4-0.5,1.9l-0.6,2.1H1183.8z"/>
      <path class="st114" d="M1190.7,60.6v3c0,1.6,0,3.2-0.1,4.5h0.1c0.5-1.2,1.2-2.5,1.8-3.6l2.2-3.9h2.3v10h-2.1v-3
					c0-1.5,0-2.9,0.2-4.2h0c-0.5,1.2-1.1,2.5-1.7,3.6l-2,3.6h-2.6v-10H1190.7z"/>
      <path class="st114" d="M1207.4,66.8c0-1.2-0.1-2.7-0.1-4.1h0c-0.3,1.3-0.7,2.7-1.1,3.9l-1.2,3.9h-1.8l-1.1-3.9
					c-0.3-1.2-0.7-2.6-0.9-3.9h0c-0.1,1.4-0.1,2.9-0.2,4.2l-0.2,3.8h-2.1l0.6-10h3l1,3.3c0.3,1.2,0.6,2.4,0.8,3.6h0
					c0.3-1.2,0.6-2.5,1-3.6l1.1-3.3h3l0.6,10h-2.2L1207.4,66.8z"/>
      <path class="st114" d="M1217.5,66.4h-3.7v2.4h4.1v1.9h-6.4v-10h6.2v1.9h-3.9v2.1h3.7V66.4z"/>
      <path class="st114" d="M1221.7,60.6v3.9h3.7v-3.9h2.3v10h-2.3v-4.2h-3.7v4.2h-2.3v-10H1221.7z"/>
      <path class="st114" d="M1238.9,65.5c0,3.3-2,5.3-4.9,5.3c-3,0-4.7-2.2-4.7-5.1c0-3,1.9-5.3,4.9-5.3
					C1237.2,60.5,1238.9,62.8,1238.9,65.5z M1231.6,65.7c0,2,0.9,3.3,2.4,3.3c1.5,0,2.4-1.5,2.4-3.4c0-1.8-0.9-3.3-2.4-3.3
					C1232.5,62.3,1231.6,63.7,1231.6,65.7z"/>
      <path class="st114" d="M1240.4,60.8c0.6-0.1,1.8-0.2,2.9-0.2c1.4,0,2.2,0.1,3,0.6c0.7,0.4,1.2,1.1,1.2,2c0,0.9-0.5,1.7-1.7,2.1v0
					c1.1,0.3,2,1.2,2,2.5c0,0.9-0.4,1.6-1,2.1c-0.7,0.6-1.9,0.9-3.9,0.9c-1.1,0-1.9-0.1-2.5-0.1V60.8z M1242.6,64.6h0.7
					c1.2,0,1.8-0.5,1.8-1.2c0-0.8-0.6-1.2-1.6-1.2c-0.5,0-0.8,0-1,0.1V64.6z M1242.6,69.1c0.2,0,0.5,0,0.9,0c1,0,1.9-0.4,1.9-1.4
					c0-1-0.9-1.4-2-1.4h-0.8V69.1z"/>
      <path class="st114" d="M1251.7,68.1l-0.7,2.6h-2.4l3.1-10h3l3.1,10h-2.4l-0.8-2.6H1251.7z M1254.2,66.4l-0.6-2.1
					c-0.2-0.6-0.4-1.3-0.5-1.9h0c-0.1,0.6-0.3,1.4-0.5,1.9l-0.6,2.1H1254.2z"/>
      <path class="st114" d="M1261.4,60.6v3.9h3.7v-3.9h2.3v10h-2.3v-4.2h-3.7v4.2h-2.3v-10H1261.4z"/>
      <path class="st114" d="M1271.4,60.6v3c0,1.6,0,3.2-0.1,4.5h0.1c0.5-1.2,1.2-2.5,1.8-3.6l2.2-3.9h2.3v10h-2.1v-3
					c0-1.5,0-2.9,0.2-4.2h0c-0.5,1.2-1.1,2.5-1.7,3.6l-2,3.6h-2.6v-10H1271.4z"/>
      <path class="st114" d="M1285.6,66.4h-3.7v2.4h4.1v1.9h-6.4v-10h6.2v1.9h-3.9v2.1h3.7V66.4z"/>
      <path class="st114" d="M1290.2,61.3c0.7-0.4,1.7-0.8,3.1-0.8c2.2,0,3.5,1.1,3.5,2.6c0,1.2-0.8,2.1-2,2.4v0c1.2,0.2,2.4,1,2.4,2.4
					c0,2.2-2.3,3-4.1,3c-1,0-2.2-0.2-3-0.7l0.5-1.7c0.4,0.2,1.5,0.6,2.4,0.6c1.1,0,1.8-0.5,1.8-1.3c0-1.1-1.2-1.3-2.1-1.3h-1v-1.7
					h0.9c1,0,1.8-0.5,1.8-1.3c0-0.7-0.6-1.1-1.5-1.1c-0.8,0-1.6,0.3-2.2,0.6L1290.2,61.3z"/>
      <path class="st114" d="M1307.8,65.5c0,3.3-2,5.3-4.9,5.3c-3,0-4.7-2.2-4.7-5.1c0-3,1.9-5.3,4.9-5.3
					C1306.1,60.5,1307.8,62.8,1307.8,65.5z M1300.5,65.7c0,2,0.9,3.3,2.4,3.3c1.5,0,2.4-1.5,2.4-3.4c0-1.8-0.9-3.3-2.4-3.3
					C1301.4,62.3,1300.5,63.7,1300.5,65.7z"/>
      <path class="st114" d="M1311.5,60.6v3.9h3.7v-3.9h2.3v10h-2.3v-4.2h-3.7v4.2h-2.3v-10H1311.5z"/>
      <path class="st114" d="M1321.8,60.6v3.5c0.3,0,0.7-0.1,1.1-0.1c2.1,0,4,1,4,3.3c0,0.9-0.3,1.6-0.8,2.2c-1,1-2.6,1.3-4.1,1.3
					c-1.1,0-1.9-0.1-2.4-0.1v-10H1321.8z M1321.7,69c0.2,0,0.5,0.1,0.8,0.1c1,0,1.9-0.6,1.9-1.7c0-1.1-1.1-1.6-1.9-1.6
					c-0.3,0-0.6,0-0.8,0.1V69z M1330,60.6v10h-2.3v-10H1330z"/>
      <path class="st114" d="M1009,60.6v8.2h3.5v-8.2h2.3v8.2l1,0.1l-0.1,4.3h-1.7l-0.1-2.5h-7.1v-10H1009z"/>
      <path class="st114" d="M1017,60.8c0.6-0.1,1.8-0.2,2.9-0.2c1.4,0,2.2,0.1,3,0.6c0.7,0.4,1.2,1.1,1.2,2c0,0.9-0.5,1.7-1.7,2.1v0
					c1.1,0.3,2,1.2,2,2.5c0,0.9-0.4,1.6-1,2.1c-0.7,0.6-1.9,0.9-3.9,0.9c-1.1,0-1.9-0.1-2.5-0.1V60.8z M1019.3,64.6h0.7
					c1.2,0,1.8-0.5,1.8-1.2c0-0.8-0.6-1.2-1.6-1.2c-0.5,0-0.8,0-1,0.1V64.6z M1019.3,69.1c0.2,0,0.5,0,0.9,0c1,0,1.9-0.4,1.9-1.4
					c0-1-0.9-1.4-2-1.4h-0.8V69.1z"/>
      <path class="st114" d="M1032,66.4h-3.7v2.4h4.1v1.9h-6.4v-10h6.2v1.9h-3.9v2.1h3.7V66.4z"/>
      <path class="st114" d="M1035.9,62.5h-2.7v-1.9h7.7v1.9h-2.7v8.1h-2.3V62.5z"/>
<!--      <path class="st114" d="M927.9,306.9L927.9,306.9l-4,1.9l-0.8-3.6l5.5-2.6h4v20.7h-4.7V306.9z"/>-->
<!--      <path class="st114" d="M1107.9,117.1v2.7l-7.4,15.3h-4.4l7.4-14.5v-0.1h-8.2v-3.5H1107.9z"/>-->
<!--      <path class="st114" d="M1095.3,186.9c-0.5,0-1,0-1.6,0.1c-3.7,0.3-5.4,2.2-5.9,4.3h0.1c0.9-0.9,2.1-1.4,3.8-1.4-->
<!--				c3,0,5.6,2.1,5.6,5.9c0,3.6-2.7,6.5-6.6,6.5c-4.8,0-7.1-3.6-7.1-7.8c0-3.4,1.2-6.2,3.2-8c1.8-1.6,4.1-2.5,6.9-2.7-->
<!--				c0.8-0.1,1.3-0.1,1.7,0V186.9z M1093,195.9c0-1.7-0.9-3.1-2.7-3.1c-1.1,0-2.1,0.7-2.5,1.6c-0.1,0.2-0.2,0.6-0.2,1-->
<!--				c0.1,1.9,1,3.6,2.9,3.6C1092,199,1093,197.7,1093,195.9z"/>-->
<!--      <path class="st114" d="M1099.4,199.6c0-1.5,1-2.6,2.5-2.6c1.5,0,2.5,1,2.5,2.6c0,1.5-1,2.5-2.5,2.5-->
<!--				C1100.4,202.1,1099.4,201.1,1099.4,199.6z"/>-->
<!--      <path class="st114" d="M1107.1,197.7c0.7,0.4,2.5,1.1,4.2,1.1c2.2,0,3.3-1,3.3-2.4c0-1.8-1.8-2.6-3.6-2.6h-1.7v-3h1.6-->
<!--				c1.4,0,3.2-0.6,3.2-2.1c0-1.1-0.9-1.9-2.7-1.9c-1.5,0-3,0.6-3.8,1.1l-0.9-3c1.1-0.7,3.2-1.4,5.6-1.4c3.8,0,6,2,6,4.5-->
<!--				c0,1.9-1.1,3.4-3.3,4.2v0.1c2.2,0.4,3.9,2,3.9,4.4c0,3.2-2.8,5.5-7.4,5.5c-2.3,0-4.3-0.6-5.3-1.3L1107.1,197.7z"/>-->
<!--      <path class="st114" d="M1095.3,253.8c-0.5,0-1,0-1.6,0.1c-3.7,0.3-5.4,2.2-5.9,4.3h0.1c0.9-0.9,2.1-1.4,3.8-1.4-->
<!--				c3,0,5.6,2.1,5.6,5.9c0,3.6-2.7,6.5-6.6,6.5c-4.8,0-7.1-3.6-7.1-7.8c0-3.4,1.2-6.2,3.2-8c1.8-1.6,4.1-2.5,6.9-2.7-->
<!--				c0.8-0.1,1.3-0.1,1.7,0V253.8z M1093,262.8c0-1.7-0.9-3.1-2.7-3.1c-1.1,0-2.1,0.7-2.5,1.6c-0.1,0.2-0.2,0.6-0.2,1-->
<!--				c0.1,1.9,1,3.6,2.9,3.6C1092,266,1093,264.6,1093,262.8z"/>-->
<!--      <path class="st114" d="M1099.4,266.5c0-1.5,1-2.6,2.5-2.6c1.5,0,2.5,1,2.5,2.6c0,1.5-1,2.5-2.5,2.5-->
<!--				C1100.4,269.1,1099.4,268,1099.4,266.5z"/>-->
<!--      <path class="st114" d="M1118.3,254.2h-7l-0.4,2.8c0.4-0.1,0.7-0.1,1.2-0.1c1.7,0,3.5,0.4,4.7,1.3c1.4,0.9,2.2,2.4,2.2,4.5-->
<!--				c0,3.3-2.9,6.3-7.7,6.3c-2.2,0-4-0.5-5-1l0.7-3.2c0.8,0.4,2.4,0.9,4.1,0.9c1.7,0,3.6-0.8,3.6-2.7c0-1.9-1.5-3-5.1-3-->
<!--				c-1,0-1.7,0.1-2.4,0.2l1.2-9.4h9.9V254.2z"/>-->
<!--      <path class="st114" d="M1095.3,299.6c-0.5,0-1,0-1.6,0.1c-3.7,0.3-5.4,2.2-5.9,4.3h0.1c0.9-0.9,2.1-1.4,3.8-1.4-->
<!--				c3,0,5.6,2.1,5.6,5.9c0,3.6-2.7,6.5-6.6,6.5c-4.8,0-7.1-3.6-7.1-7.8c0-3.4,1.2-6.2,3.2-8c1.8-1.6,4.1-2.5,6.9-2.7-->
<!--				c0.8-0.1,1.3-0.1,1.7,0V299.6z M1093,308.6c0-1.7-0.9-3.1-2.7-3.1c-1.1,0-2.1,0.7-2.5,1.6c-0.1,0.2-0.2,0.6-0.2,1-->
<!--				c0.1,1.9,1,3.6,2.9,3.6C1092,311.7,1093,310.4,1093,308.6z"/>-->
<!--      <path class="st114" d="M1099.4,312.3c0-1.5,1-2.6,2.5-2.6c1.5,0,2.5,1,2.5,2.6c0,1.5-1,2.5-2.5,2.5-->
<!--				C1100.4,314.8,1099.4,313.8,1099.4,312.3z"/>-->
<!--      <path class="st114" d="M1117.8,299.6c-0.5,0-1,0-1.6,0.1c-3.7,0.3-5.4,2.2-5.9,4.3h0.1c0.9-0.9,2.1-1.4,3.8-1.4-->
<!--				c3,0,5.6,2.1,5.6,5.9c0,3.6-2.7,6.5-6.6,6.5c-4.8,0-7.1-3.6-7.1-7.8c0-3.4,1.2-6.2,3.2-8c1.8-1.6,4.1-2.5,6.9-2.7-->
<!--				c0.8-0.1,1.3-0.1,1.7,0V299.6z M1115.5,308.6c0-1.7-0.9-3.1-2.7-3.1c-1.1,0-2.1,0.7-2.5,1.6c-0.1,0.2-0.2,0.6-0.2,1-->
<!--				c0.1,1.9,1,3.6,2.9,3.6C1114.6,311.7,1115.5,310.4,1115.5,308.6z"/>-->
<!--      <path class="st114" d="M1095.3,365c-0.5,0-1,0-1.6,0.1c-3.7,0.3-5.4,2.2-5.9,4.3h0.1c0.9-0.9,2.1-1.4,3.8-1.4c3,0,5.6,2.1,5.6,5.9-->
<!--				c0,3.6-2.7,6.5-6.6,6.5c-4.8,0-7.1-3.6-7.1-7.8c0-3.4,1.2-6.2,3.2-8c1.8-1.6,4.1-2.5,6.9-2.7c0.8-0.1,1.3-0.1,1.7,0V365z-->
<!--				 M1093,374c0-1.7-0.9-3.1-2.7-3.1c-1.1,0-2.1,0.7-2.5,1.6c-0.1,0.2-0.2,0.6-0.2,1c0.1,1.9,1,3.6,2.9,3.6-->
<!--				C1092,377.2,1093,375.8,1093,374z"/>-->
<!--      <path class="st114" d="M1099.4,377.7c0-1.5,1-2.6,2.5-2.6c1.5,0,2.5,1,2.5,2.6c0,1.5-1,2.5-2.5,2.5-->
<!--				C1100.4,380.3,1099.4,379.2,1099.4,377.7z"/>-->
<!--      <path class="st114" d="M1106.3,380v-2.5l2.3-2.1c3.9-3.5,5.8-5.5,5.9-7.6c0-1.5-0.9-2.6-3-2.6c-1.5,0-2.9,0.8-3.8,1.5l-1.2-3-->
<!--				c1.4-1,3.5-1.9,5.9-1.9c4.1,0,6.3,2.4,6.3,5.6c0,3-2.2,5.4-4.8,7.7l-1.7,1.4v0.1h6.8v3.5H1106.3z"/>-->
<!--      <path class="st114" d="M1095.3,431.4c-0.5,0-1,0-1.6,0.1c-3.7,0.3-5.4,2.2-5.9,4.3h0.1c0.9-0.9,2.1-1.4,3.8-1.4-->
<!--				c3,0,5.6,2.1,5.6,5.9c0,3.6-2.7,6.5-6.6,6.5c-4.8,0-7.1-3.6-7.1-7.8c0-3.4,1.2-6.2,3.2-8c1.8-1.6,4.1-2.5,6.9-2.7-->
<!--				c0.8-0.1,1.3-0.1,1.7,0V431.4z M1093,440.4c0-1.7-0.9-3.1-2.7-3.1c-1.1,0-2.1,0.7-2.5,1.6c-0.1,0.2-0.2,0.6-0.2,1-->
<!--				c0.1,1.9,1,3.6,2.9,3.6C1092,443.5,1093,442.2,1093,440.4z"/>-->
<!--      <path class="st114" d="M1099.4,444.1c0-1.5,1-2.6,2.5-2.6c1.5,0,2.5,1,2.5,2.6c0,1.5-1,2.5-2.5,2.5-->
<!--				C1100.4,446.6,1099.4,445.6,1099.4,444.1z"/>-->
<!--      <path class="st114" d="M1113.8,446.3v-4.3h-8v-2.7l6.8-10.9h5.1v10.5h2.2v3.2h-2.2v4.3H1113.8z M1113.8,438.9v-4-->
<!--				c0-1.1,0.1-2.2,0.1-3.3h-0.1c-0.6,1.2-1,2.2-1.7,3.3l-2.4,3.9v0.1H1113.8z"/>-->
<!--      <path class="st114" d="M1095.3,497.2c-0.5,0-1,0-1.6,0.1c-3.7,0.3-5.4,2.2-5.9,4.3h0.1c0.9-0.9,2.1-1.4,3.8-1.4-->
<!--				c3,0,5.6,2.1,5.6,5.9c0,3.6-2.7,6.5-6.6,6.5c-4.8,0-7.1-3.6-7.1-7.8c0-3.4,1.2-6.2,3.2-8c1.8-1.6,4.1-2.5,6.9-2.7-->
<!--				c0.8-0.1,1.3-0.1,1.7,0V497.2z M1093,506.2c0-1.7-0.9-3.1-2.7-3.1c-1.1,0-2.1,0.7-2.5,1.6c-0.1,0.2-0.2,0.6-0.2,1-->
<!--				c0.1,1.9,1,3.6,2.9,3.6C1092,509.4,1093,508,1093,506.2z"/>-->
<!--      <path class="st114" d="M1099.4,509.9c0-1.5,1-2.6,2.5-2.6c1.5,0,2.5,1.1,2.5,2.6c0,1.5-1,2.5-2.5,2.5-->
<!--				C1100.4,512.5,1099.4,511.4,1099.4,509.9z"/>-->
<!--      <path class="st114" d="M1111.7,497.9L1111.7,497.9l-3.5,1.6l-0.7-3.1l4.8-2.2h3.5v18h-4.1V497.9z"/>-->
<!--      <path class="st114" d="M1083.5,579.4c0-2.2,1.2-3.6,3.2-4.5v-0.1c-1.7-0.9-2.6-2.3-2.6-3.9c0-3,2.7-5.1,6.3-5.1-->
<!--				c4.2,0,5.9,2.4,5.9,4.6c0,1.5-0.8,3.1-2.6,4v0.1c1.7,0.7,3.3,2.2,3.3,4.5c0,3.3-2.7,5.4-6.9,5.4-->
<!--				C1085.6,584.4,1083.5,581.8,1083.5,579.4z M1092.8,579.2c0-1.6-1.2-2.5-2.8-3c-1.4,0.4-2.2,1.4-2.2,2.7c0,1.3,1,2.5,2.5,2.5-->
<!--				C1091.8,581.5,1092.8,580.5,1092.8,579.2z M1088.1,570.7c0,1.2,1.1,2,2.5,2.4c1-0.3,1.8-1.2,1.8-2.3c0-1.1-0.6-2.2-2.2-2.2-->
<!--				C1088.8,568.7,1088.1,569.6,1088.1,570.7z"/>-->
<!--      <path class="st114" d="M1099.4,581.8c0-1.5,1-2.6,2.5-2.6c1.5,0,2.5,1.1,2.5,2.6c0,1.5-1,2.5-2.5,2.5-->
<!--				C1100.4,584.4,1099.4,583.3,1099.4,581.8z"/>-->
<!--      <path class="st114" d="M1111.7,569.8L1111.7,569.8l-3.5,1.6l-0.7-3.2l4.8-2.2h3.5v18h-4.1V569.8z"/>-->
<!--      <path class="st114" d="M1083.5,651.1c0-2.2,1.2-3.6,3.2-4.5v-0.1c-1.7-0.9-2.6-2.3-2.6-3.9c0-3,2.7-5.1,6.3-5.1-->
<!--				c4.2,0,5.9,2.4,5.9,4.6c0,1.5-0.8,3.1-2.6,4v0.1c1.7,0.7,3.3,2.2,3.3,4.5c0,3.3-2.7,5.4-6.9,5.4-->
<!--				C1085.6,656.1,1083.5,653.5,1083.5,651.1z M1092.8,651c0-1.6-1.2-2.5-2.8-3c-1.4,0.4-2.2,1.4-2.2,2.7c0,1.3,1,2.5,2.5,2.5-->
<!--				C1091.8,653.2,1092.8,652.2,1092.8,651z M1088.1,642.5c0,1.2,1.1,2,2.5,2.4c1-0.3,1.8-1.2,1.8-2.3c0-1.1-0.6-2.2-2.2-2.2-->
<!--				C1088.8,640.4,1088.1,641.3,1088.1,642.5z"/>-->
<!--      <path class="st114" d="M1099.4,653.6c0-1.5,1-2.6,2.5-2.6c1.5,0,2.5,1.1,2.5,2.6c0,1.5-1,2.5-2.5,2.5-->
<!--				C1100.4,656.1,1099.4,655,1099.4,653.6z"/>-->
<!--      <path class="st114" d="M1106.3,655.8v-2.5l2.3-2.1c3.9-3.5,5.8-5.5,5.9-7.6c0-1.5-0.9-2.6-3-2.6c-1.5,0-2.9,0.8-3.8,1.5l-1.2-3-->
<!--				c1.4-1,3.5-1.9,5.9-1.9c4.1,0,6.3,2.4,6.3,5.6c0,3-2.2,5.4-4.8,7.7l-1.7,1.4v0.1h6.8v3.5H1106.3z"/>-->
<!--      <path class="st114" d="M1096.4,728.2c0.5,0.1,1,0.1,1.8,0c1.3-0.1,2.6-0.4,3.5-1.1c1.2-0.8,1.9-2,2.3-3.3l-0.1,0-->
<!--				c-0.8,0.8-2,1.3-3.6,1.3c-3,0-5.6-2.1-5.6-5.6c0-3.5,2.8-6.5,6.8-6.5c4.6,0,6.8,3.5,6.8,7.7c0,3.7-1.2,6.5-3.1,8.3-->
<!--				c-1.7,1.5-4.1,2.4-6.9,2.5c-0.7,0.1-1.4,0-1.9,0V728.2z M1098.9,719.1c0,1.5,0.8,2.9,2.5,2.9c1.1,0,1.9-0.6,2.3-1.2-->
<!--				c0.1-0.2,0.2-0.5,0.2-1.1c0-1.9-0.7-3.8-2.6-3.8C1100,716,1098.9,717.3,1098.9,719.1z"/>-->
<!--      <path class="st114" d="M1081.5,794.9L1081.5,794.9l-3.5,1.6l-0.7-3.2l4.8-2.2h3.5v18h-4.1V794.9z"/>-->
<!--      <path class="st114" d="M1104.7,800.1c0,5.6-2.2,9.4-6.8,9.4c-4.6,0-6.7-4.2-6.7-9.3c0-5.2,2.2-9.3,6.9-9.3-->
<!--				C1102.9,790.9,1104.7,795.1,1104.7,800.1z M1095.4,800.2c0,4.1,1,6.1,2.6,6.1s2.5-2,2.5-6.1c0-4-0.9-6.1-2.5-6.1-->
<!--				C1096.4,794,1095.4,796,1095.4,800.2z"/>-->
<!--      <path class="st114" d="M1107,806.9c0-1.5,1-2.6,2.5-2.6c1.5,0,2.5,1.1,2.5,2.6c0,1.5-1,2.5-2.5,2.5-->
<!--				C1108.1,809.4,1107,808.4,1107,806.9z"/>-->
<!--      <path class="st114" d="M1114,809.1v-2.5l2.3-2.1c3.9-3.5,5.8-5.5,5.9-7.6c0-1.5-0.9-2.6-3-2.6c-1.5,0-2.9,0.8-3.8,1.5l-1.2-3-->
<!--				c1.4-1,3.5-1.9,5.9-1.9c4.1,0,6.3,2.4,6.3,5.6c0,3-2.2,5.4-4.8,7.7l-1.7,1.4v0.1h6.8v3.5H1114z"/>-->
<!--      <path class="st114" d="M1081.5,840.5L1081.5,840.5l-3.5,1.6l-0.7-3.2l4.8-2.2h3.5v18h-4.1V840.5z"/>-->
<!--      <path class="st114" d="M1104.7,845.6c0,5.6-2.2,9.4-6.8,9.4c-4.6,0-6.7-4.2-6.7-9.3c0-5.2,2.2-9.3,6.9-9.3-->
<!--				C1102.9,836.4,1104.7,840.7,1104.7,845.6z M1095.4,845.7c0,4.1,1,6.1,2.6,6.1s2.5-2,2.5-6.1c0-4-0.9-6.1-2.5-6.1-->
<!--				C1096.4,839.6,1095.4,841.6,1095.4,845.7z"/>-->
<!--      <path class="st114" d="M1107,852.4c0-1.5,1-2.6,2.5-2.6c1.5,0,2.5,1.1,2.5,2.6c0,1.5-1,2.5-2.5,2.5-->
<!--				C1108.1,855,1107,853.9,1107,852.4z"/>-->
<!--      <path class="st114" d="M1114.7,850.6c0.7,0.4,2.5,1.1,4.2,1.1c2.2,0,3.3-1.1,3.3-2.4c0-1.8-1.8-2.6-3.6-2.6h-1.7v-3h1.6-->
<!--				c1.4,0,3.2-0.6,3.2-2.1c0-1.1-0.9-1.9-2.7-1.9c-1.5,0-3,0.6-3.8,1.1l-0.9-3c1.1-0.7,3.2-1.4,5.6-1.4c3.8,0,6,2,6,4.5-->
<!--				c0,1.9-1.1,3.4-3.3,4.2v0.1c2.2,0.4,3.9,2,3.9,4.4c0,3.2-2.8,5.5-7.4,5.5c-2.3,0-4.3-0.6-5.3-1.3L1114.7,850.6z"/>-->
<!--      <path class="st114" d="M1081.5,909.2L1081.5,909.2l-3.5,1.6l-0.7-3.2l4.8-2.2h3.5v18h-4.1V909.2z"/>-->
<!--      <path class="st114" d="M1104.7,914.3c0,5.6-2.2,9.4-6.8,9.4c-4.6,0-6.7-4.2-6.7-9.3c0-5.2,2.2-9.3,6.9-9.3-->
<!--				C1102.9,905.1,1104.7,909.4,1104.7,914.3z M1095.4,914.4c0,4.1,1,6.1,2.6,6.1s2.5-2,2.5-6.1c0-4-0.9-6.1-2.5-6.1-->
<!--				C1096.4,908.3,1095.4,910.3,1095.4,914.4z"/>-->
<!--      <path class="st114" d="M1107,921.1c0-1.5,1-2.6,2.5-2.6c1.5,0,2.5,1.1,2.5,2.6c0,1.5-1,2.5-2.5,2.5-->
<!--				C1108.1,923.7,1107,922.6,1107,921.1z"/>-->
<!--      <path class="st114" d="M1119.4,909.2L1119.4,909.2l-3.5,1.6l-0.7-3.2l4.8-2.2h3.5v18h-4.1V909.2z"/>-->
<!--      <path class="st114" d="M921.7,618.9V616l2.7-2.4c4.5-4,6.7-6.4,6.8-8.8c0-1.7-1-3-3.4-3c-1.8,0-3.3,0.9-4.4,1.7L922,600-->
<!--					c1.6-1.2,4-2.1,6.8-2.1c4.7,0,7.3,2.7,7.3,6.5c0,3.5-2.5,6.2-5.5,8.9l-1.9,1.6v0.1h7.8v4H921.7z"/>-->
<!--      <path class="st114" d="M922.6,718.2c0.9,0.4,2.8,1.3,4.8,1.3c2.5,0,3.8-1.2,3.8-2.8c0-2-2-3-4.2-3h-2v-3.5h1.9-->
<!--					c1.6,0,3.7-0.6,3.7-2.4c0-1.2-1-2.2-3.1-2.2c-1.7,0-3.5,0.7-4.3,1.2l-1-3.5c1.2-0.8,3.7-1.6,6.4-1.6c4.4,0,6.9,2.3,6.9,5.2-->
<!--					c0,2.2-1.2,3.9-3.8,4.8v0.1c2.5,0.4,4.5,2.3,4.5,5c0,3.7-3.2,6.3-8.5,6.3c-2.7,0-4.9-0.7-6.2-1.5L922.6,718.2z"/>-->
<!--      <path class="st114" d="M930.3,852.6v-4.9h-9.2v-3.2l7.8-12.6h5.9V844h2.5v3.6h-2.5v4.9H930.3z M930.3,844v-4.6-->
<!--					c0-1.2,0.1-2.5,0.2-3.9h-0.1c-0.7,1.3-1.2,2.5-1.9,3.9l-2.8,4.5v0.1H930.3z"/>-->
      <rect x="1064" y="45.8" class="st115" width="1.3" height="38.5"/>
      <rect x="1142" y="45.8" class="st115" width="1.3" height="38.5"/>
      <rect x="1064" y="84.7" class="st101" width="1.3" height="862.8"/>
      <rect x="980.7" y="84.7" class="st101" width="1.3" height="862.8"/>
      <rect x="1142" y="84.7" class="st101" width="1.3" height="862.8"/>
      <rect x="980.7" y="45.8" class="st115" width="1.3" height="38.5"/>
      <path d="M674.1,113c1.6,1.9,0.9,6,0.3,9.1l-0.2,0.9c-0.9,5.6-1.7,8.8-7.3,10.2c-0.1,0-0.1,0-0.2,0.1c1.1-3.6,1.7-7.2,2.1-10.2
				c0.6-4.4,0.7-8.3,0.7-11.1C671.8,111.6,673.3,111.9,674.1,113z"/>
<!--      <path d="M592.4,88.4c0.4-5.9,0.9-13.3,3.9-18.6c3.8-6.8,11.9-10.6,24.2-11.4c2.5-0.2,8.6-0.2,11.2-0.2c2.8,0,5.5,0,6.6,0.1-->
<!--				c9.5,0.3,21.9,2.5,27.1,11.6c3,5.4,3.5,12.7,3.9,18.6c0.5,7.4,0.1,13.4-1.1,18.5c-0.4,0.1-0.7,0.5-0.6,1c0,0,0,0.4,0.1,1.1-->
<!--				c-0.1,0.2-0.1,0.3-0.2,0.5l0.2,0.5c0.1,2.6,0.1,7.3-0.6,12.9c-1,7.6-3.6,18.3-10.6,26c-6.2,6.8-14.8,10.2-25.6,10.2-->
<!--				c-10.8,0-19.4-3.4-25.6-10.2c-7-7.7-9.6-18.4-10.6-26c-0.7-5.6-0.7-10.4-0.6-13l0.2-0.4c-0.1-0.2-0.1-0.3-0.1-0.5-->
<!--				c0-0.7,0.1-1.1,0.1-1.1c0-0.5-0.2-0.9-0.6-1C592.3,101.8,591.9,95.8,592.4,88.4z"/>-->
      <path d="M587.3,123l-0.2-0.9c-0.5-3-1.2-7.2,0.3-9.1c0.6-0.8,1.6-1.1,3-1.1c0.5,0,1,0,1.6,0.1c0,2.8,0.1,6.6,0.7,11.1
				c0.4,3,1,6.6,2.1,10.2c-0.1,0-0.1,0-0.2-0.1C589,131.8,588.2,128.5,587.3,123z"/>
      <path d="M631.3,534.2c-0.2,0-0.4,0-0.5,0.1c-0.2,0-0.3-0.1-0.5-0.1c0.1,0,0.1,0,0.2-0.1c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0
				c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0,0,0,0,0,0h0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0
				C631.2,534.2,631.3,534.2,631.3,534.2C631.4,534.2,631.4,534.2,631.3,534.2z"/>

      <a id="ast113" v-on:click="toogleSvgClass($event,'ast113')" :class="this.questionary.painJoint ? 'svglinkActive' : 'svglink'">
        <rect x="1000.6" y="886.1" class="st113" width="45.7" height="45.7"/>
        <path class="st113" d="M658.8,471.8c-0.9,6.4,0.7,12.2,4.7,17.1c11.7,14.3,26.7,18.9,37.1,20.2c7.1,0.9,13.2,0.5,16.8,0
          c-0.2-1.9-0.4-3.7-0.6-5.6c-0.5-4.4-1-8.8-1.3-13.3c-0.3-3-0.5-6-0.7-9c-1.6-20.8-3.3-42.4-12.6-61.4c0-0.1-0.1-0.4-0.2-0.8
          c-3.3,2.4-11.3,8.4-19.4,16.2C672.7,444.7,660.6,458.6,658.8,471.8z"/>
        <path class="st113" d="M707.9,207.8c-1.5,7.1-2.8,19.2-1,37.4c7.6,5.8,8.5,19.4,8.6,20.3c0.4,0.4,4.2,3.8,10,7.1
          c5.6,3.2,14.2,6.9,23.4,6.3c4.8-0.3,9.3-1.7,13.6-4.3c0.1-7.7-0.4-15.4-2.3-22.8c-3.6-14.3-13-26.9-26.6-35.6
          c-6.4-4.1-13.7-6.7-21.7-7.9C710.6,208,709.3,207.9,707.9,207.8z"/>
        <path class="st113" d="M769.8,351.6c-1.6-0.8-3.7-1.6-6.1-2.4c-5.2-1.7-13.3-3.2-21.9-0.9c-8.3,2.1-15.6,7.2-21.8,15.1
          c0.6,3.7,1.2,7.5,1.6,11.3l0.4,3.9c0.3,2.7,0.6,5.3,0.9,8c12.6,8.1,24.4,10.9,35.1,8.3c11.1-2.7,18.1-10.7,21.1-14.9
          c-0.5-3.3-1.1-6.7-2.3-9.7c-1.1-2.7-2.5-4.9-3.9-6.9C770.3,359.6,770,355.7,769.8,351.6z"/>
        <path class="st113" d="M512.2,278.8c9.2,0.6,17.8-3.1,23.4-6.3c5.8-3.3,9.6-6.8,10-7.1c0.1-0.9,1-14.5,8.6-20.3
          c1.8-18.1,0.5-30.2-1-37.3c-1.2,0.1-2.3,0.2-3.5,0.4c-8,1.2-15.3,3.9-21.7,7.9c-13.6,8.6-23,21.3-26.6,35.6
          c-1.9,7.6-2.3,15.4-2.3,23.2C503.2,277.2,507.6,278.5,512.2,278.8z"/>
        <path class="st113" d="M560.5,509.1c10.5-1.3,25.4-5.9,37.1-20.2c4-4.9,5.6-10.6,4.7-17.1c-1.9-13.2-13.9-27.1-23.7-36.4
          c-7.8-7.5-15.4-13.3-19-15.9c-0.1,0.2-0.1,0.4-0.1,0.4c-9.3,19-11,40.6-12.6,61.4c-0.2,3-0.5,6-0.7,9c-0.4,4.4-0.9,8.9-1.3,13.3
          c-0.2,1.9-0.4,3.7-0.6,5.6C547.9,509.6,553.7,509.9,560.5,509.1z"/>
        <path class="st113" d="M697.2,921.3c-6.4-6.3-11.7-14.6-14.2-22.3c-0.7-2-1.1-4.2-1.6-6.3c-0.5-2.3-1.1-4.8-1.9-7.1
          c-1.5-4.3-2.8-8.9-2.8-14.2c-3.8,0.8-10.1,1.8-17.1,1.8c-4.2,0-8.6-0.4-13-1.3c-0.5,13.7-1.6,27.6-4,41l-0.2,0.9
          c-2,10.6-4.4,23.7,10.1,26.5c1.8,0.4,3.8,0.5,6,0.5c3.1,0,6.3-0.3,9.3-0.7c3.7-0.4,7.4-0.8,11.1-0.6c1.6,0.1,3.2,0.2,4.8,0.3
          c2.6,0.2,5.3,0.4,8,0.4c2.3,0,4.4-0.2,6.3-0.5c3.6-0.7,5.8-2.1,6.5-4.3C706,930.7,699.9,924,697.2,921.3z"/>
        <path class="st113" d="M502.7,395c10.9,2.6,23-0.4,36.1-8.9c0.3-2.5,0.6-5,0.9-7.6l0.4-3.9c0.4-3.6,0.9-7.2,1.5-10.7
          c-6.5-8.4-14-13.8-22.5-15.9c-11.9-3-22.7,1.3-27.3,3.6c-0.1,4.1-0.4,8.1-3,11.8c-1.4,2-2.9,4.2-3.9,6.9c-1.3,3.4-2,7.3-2.5,11.1
          C485.5,385.4,492.3,392.5,502.7,395z"/>
        <path class="st113" d="M829.9,513.2c-7.2-5.5-13-12.6-18.6-19.4c-1.5-1.8-2.9-3.6-4.4-5.3c-4.9-5.8-10.8-9.5-16.7-10.5
          c-1.9,1-4.7,2.3-8.2,3.6c-4.9,1.8-11.9,3.7-20.4,4.2c1.2,8.3,1.4,16.7,1.6,24.9c0.2,9.6,0.5,19.6,2.4,29.4
          c0.9,4.7,3.8,19.2,10.6,19.2c0.8,0,1.6-0.2,2.4-0.6c0.6,2.3,1.4,4.8,3.2,6.7c1.8,2,4.7,2.6,8.6,1.7c1.3,1.4,3.1,2.2,5.1,2.2
          c3.5,0,7.2-2.5,7.6-6.5c2.3-0.4,4.2-1.6,5.7-3.5c1.7-2.3,2.6-5.4,3-9.1c1-8.7-1-20.4-2.6-29.6c-0.5-3.1-1-5.9-1.3-8.1
          c1.2,0.6,2.4,1.3,3.5,1.9c3.7,2.1,7.3,4,11.4,4c2.2,0,4.4-0.5,6.7-1.7c0.6-0.3,0.9-0.8,0.9-1.4c0-0.1,0-0.2,0-0.3
          c0-0.1,0-0.2,0-0.3C830.7,514.1,830.4,513.6,829.9,513.2z"/>
        <path class="st113" d="M693.7,675c-3.3-3.7-9.8-9.5-18.6-10.2c-8.7-0.7-17.6,3.8-26.5,13.3c0.1,1.3,0.2,2.5,0.2,3.8
          c0,0.1,0,0.2,0,0.3c-0.5,5.8,0.3,11.3,1,17.2c0.2,1.5,0.4,3.1,0.6,4.6c0.8,6.9,0,14.1-1.4,20.9c9.4,9.7,18.8,14.1,27.8,13.1
          c7.6-0.9,13.4-5.5,17.1-9.2c1.6-1.6,2.8-3.2,3.8-4.5c-0.4-1.2-0.8-2.3-1.2-3.4c-1.9-5.3-3.9-10.7-4.9-16.2c0-2-0.1-4-0.2-5.9
          c-0.2-2.9-0.3-5.9-0.1-8.8C691.6,684.5,692.6,679.3,693.7,675z"/>
        <path class="st113" d="M479.1,481.5c-3.4-1.2-6.1-2.5-8-3.5c-5.7,1.1-11.5,4.8-16.3,10.5c-1.5,1.8-2.9,3.5-4.4,5.3
          c-5.6,6.8-11.4,13.9-18.6,19.4c-0.5,0.4-0.8,1-0.7,1.6c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0.6,0.4,1.1,0.9,1.4
          c2.3,1.1,4.5,1.7,6.7,1.7c4.1,0,7.7-1.9,11.4-4c1.1-0.6,2.3-1.3,3.5-1.9c-0.3,2.2-0.7,5-1.3,8.1c-0.9,5.2-1.9,11.1-2.4,16.8
          c-0.8,8.8-0.6,17.3,2.9,21.9c1.4,1.9,3.3,3.1,5.7,3.5c0.3,4,4.1,6.5,7.6,6.5c2,0,3.8-0.8,5.1-2.2c3.9,0.9,6.8,0.3,8.6-1.7
          c1.8-2,2.6-4.5,3.2-6.7c0.8,0.4,1.6,0.6,2.4,0.6c5.4,0,8.3-8.9,9.7-15c0.4-1.7,0.7-3.1,0.9-4.2c0.6-3,1-6,1.3-9
          c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.7-6.7,0.8-13.4,1-20c0.2-8.2,0.4-16.6,1.6-24.9C491.3,485.2,484.1,483.3,479.1,481.5z"/>
        <path class="st113" d="M619,912.9c-2.5-13.5-3.6-27.4-4-41.1c-4.5,1-9,1.4-13.3,1.4c-6.8,0-12.9-0.9-16.8-1.7
          c0.1,5.2-1.3,9.8-2.8,14.1c-0.8,2.3-1.4,4.8-1.9,7.1c-0.5,2.1-0.9,4.2-1.6,6.3c-2.5,7.7-7.8,16-14.2,22.3
          c-2.7,2.7-8.9,9.4-7.4,14.2c0.7,2.2,2.9,3.7,6.5,4.3c1.9,0.4,4,0.5,6.3,0.5c2.7,0,5.4-0.2,8-0.4c1.6-0.1,3.2-0.3,4.8-0.3
          c3.6-0.2,7.4,0.2,11.1,0.6c3.1,0.3,6.2,0.7,9.3,0.7c2.2,0,4.2-0.2,6-0.5c14.5-2.8,12.1-15.9,10.1-26.5L619,912.9z"/>
        <path class="st113" d="M567.4,728.7c3.6,3.7,9.5,8.3,17.1,9.2c9.1,1,18.5-3.4,28-13.3c-1.4-6.7-2.2-13.8-1.3-20.6
          c0.2-1.6,0.4-3.1,0.6-4.6c0.8-5.8,1.5-11.4,1-17.2c0-0.1,0-0.2,0-0.3c0-1.2,0.1-2.4,0.2-3.5c-9-9.7-18-14.2-26.8-13.5
          c-8.5,0.7-14.9,6.2-18.3,9.9c1,4.4,2.1,9.7,2.4,15.1c0.2,3,0,5.9-0.1,8.8c-0.1,1.9-0.2,3.9-0.2,5.8c-1,5.6-3,11-4.9,16.3
          c-0.4,1.2-0.9,2.5-1.3,3.7C564.7,725.7,565.9,727.2,567.4,728.7z"/>
        <path class="st113" d="M307.7,213.6c-3.7,5.7-8.3,15.8-8.7,32.2c7.5,5.7,8.5,18.9,8.6,20.2l0,0l0,0.1c0.7,0.6,12.6,10.5,27.3,9.3
          c6.7-0.5,13.1-3.3,18.9-8.1c-0.2-5.3-0.9-10.5-2.1-15.6c-3.6-14.3-13-26.9-26.6-35.6c-4.4-2.8-9.2-4.9-14.4-6.4
          C309.8,210.8,308.7,212.1,307.7,213.6z"/>
        <path class="st113" d="M136.4,503.5c-0.1,0.8-0.2,1.7-0.3,2.5c11,2.4,21.4-1.6,28.8-5.8c9.7-5.6,16.2-12.9,16.3-12.9
          c0,0,3.8-4.6,5.6-11.2c1.7-6,2.1-15-5.6-23.6c-7.8-8.6-19.4-10.7-27.8-11.1c-3.7-0.1-7.1,0-9.8,0.3c-3,12.9-4,26.3-5,39.4
          c-0.2,3-0.5,6-0.7,9C137.4,494.7,136.9,499.2,136.4,503.5z"/>
        <path class="st113" d="M78.6,374.4c3.9,4.5,10.4,10.4,19.2,12.4c10.3,2.4,21.6-0.8,33.5-9.4l0.3-2.9c0.3-2.3,0.6-4.6,0.9-7
          c-7.7-13.1-16.9-20.9-27.3-23.3c-9.2-2.1-17.3,0.7-21.7,2.9c0,0,0,0,0,0c-0.1,1.1-0.1,2.3-0.1,3.4c-0.1,4.5-0.3,8.7-3.1,12.7
          c-1.4,2-2.9,4.2-3.9,6.9c-0.1,0.3-0.2,0.6-0.3,0.9C76.7,372.1,77.6,373.2,78.6,374.4z"/>
        <path class="st113" d="M66.2,476.6c-0.1,0.3-0.1,0.7-0.2,1c-6.8,0-14,4-19.8,10.8c-1.5,1.8-2.9,3.5-4.4,5.3
          c-5.6,6.8-11.4,13.9-18.6,19.4c-0.6,0.5-0.8,1.1-0.7,1.8c-0.1,0.7,0.3,1.3,0.9,1.7c2.3,1.1,4.5,1.7,6.7,1.7c4.1,0,7.7-1.9,11.4-4
          c1.1-0.6,2.3-1.3,3.5-1.9c-0.3,2.2-0.7,5-1.3,8.1c-2.2,13.1-5.3,31.1,0.5,38.7c1.4,1.9,3.3,3.1,5.7,3.5c0.3,4,4.1,6.5,7.6,6.5
          c2,0,3.8-0.8,5.1-2.2c3.9,0.9,6.8,0.3,8.6-1.7c1.8-2,2.6-4.5,3.2-6.7c0.8,0.4,1.6,0.6,2.4,0.6c6.9,0,9.7-14.4,10.6-19.2
          c1.9-9.7,2.1-19.7,2.4-29.4c0.2-7.9,0.4-16.1,1.5-24.1C82.2,479.8,72,477.5,66.2,476.6z"/>
        <path class="st113" d="M109.5,275.5c14.8,1.2,26.6-8.7,27.3-9.3c0.1-0.9,1-14.5,8.6-20.3c-0.4-16.4-4.9-26.6-8.7-32.3
          c-1-1.5-2-2.8-2.9-3.8c-5.1,1.5-9.9,3.6-14.3,6.3c-13.6,8.6-23,21.3-26.6,35.6c-1.3,5.2-1.9,10.4-2.2,15.7
          C96.6,272.2,102.9,274.9,109.5,275.5z"/>
        <path class="st113" d="M291.2,441.5c-8.4,0.3-20.1,2.5-27.8,11.1c-7.7,8.6-7.3,17.5-5.6,23.6c1.9,6.5,5.6,11.1,5.6,11.2
          c0.1,0.1,6.6,7.3,16.3,12.9c7.3,4.2,17.8,8.2,28.9,5.8c-0.1-0.8-0.2-1.7-0.3-2.5c-0.5-4.4-1-8.8-1.3-13.3c-0.3-3-0.5-6-0.7-9
          c-1-13.1-2.1-26.5-5-39.4C298.4,441.6,294.9,441.4,291.2,441.5z"/>
        <path class="st113" d="M210.5,912.9c-2.9-15.5-3.9-31.7-4.2-47.3c-7.4,2.7-14.1,3.5-19.2,3.5c-1.1,0-2.1,0-3-0.1
          c-3.4-0.2-6.2-0.7-7.9-1.1c0.1,0.8,0.1,1.6,0.2,2.4c0.3,5.7-1.1,10.7-2.7,15.3c-0.8,2.3-1.4,4.8-1.9,7.1
          c-0.5,2.1-0.9,4.2-1.6,6.3c-2.5,7.7-7.8,16-14.2,22.3c-2.7,2.7-8.9,9.4-7.4,14.2c0.7,2.2,2.9,3.7,6.5,4.3c1.9,0.4,4,0.5,6.3,0.5
          c2.7,0,5.4-0.2,8-0.4c1.6-0.1,3.2-0.3,4.8-0.3c3.6-0.2,7.4,0.2,11.1,0.6c3.1,0.3,6.2,0.7,9.3,0.7c2.2,0,4.2-0.2,6-0.5
          c14.5-2.8,12.1-15.9,10.1-26.5L210.5,912.9z"/>
        <path class="st113" d="M421.4,513.2c-7.2-5.5-13-12.6-18.6-19.4c-1.5-1.8-2.9-3.6-4.4-5.3c-5.8-6.9-13-10.8-19.8-10.8
          c-0.1-0.5-0.2-0.9-0.2-1.4c-3.2,0.2-14.5,1.4-25.5,7.9c1.4,8.7,1.7,17.7,1.9,26.4c0.2,9.6,0.5,19.6,2.4,29.4
          c0.9,4.7,3.8,19.2,10.6,19.2c0.8,0,1.6-0.2,2.4-0.6c0.6,2.3,1.4,4.8,3.2,6.7c1.8,2,4.7,2.6,8.6,1.7c1.3,1.4,3.1,2.2,5.1,2.2
          c3.5,0,7.2-2.5,7.6-6.5c2.3-0.4,4.2-1.6,5.7-3.5c5.7-7.6,2.7-25.6,0.5-38.7c-0.5-3.1-1-5.9-1.3-8.1c1.2,0.6,2.4,1.3,3.5,1.9
          c3.7,2.1,7.3,4,11.4,4c2.2,0,4.4-0.5,6.7-1.7c0.6-0.3,1-0.9,0.9-1.7C422.2,514.3,422,513.6,421.4,513.2z"/>
        <path class="st113" d="M267.3,660.1c-11.2-2.8-21.7-0.1-29.4,7.2c1.4,4.8,2.4,9.7,2.4,14.6c0,0.1,0,0.2,0,0.3
          c-0.5,5.8,0.3,11.3,1,17.2c0.2,1.5,0.4,3.1,0.6,4.6c0.4,3.5,0.4,7,0.1,10.5c9.1,8.9,19.9,8.1,25.7,6.7
          c6.9-1.6,13.4-5.7,16.9-10.1c-0.6-2.2-1.2-4.3-1.6-6.5c0-2-0.1-4-0.2-5.9c-0.2-2.9-0.3-5.9-0.1-8.8c0.4-6.5,1.9-12.9,3.1-17.8
          c0,0,0,0,0,0C282.8,667,275.5,662.2,267.3,660.1z"/>
        <path class="st113" d="M177.8,660c-4.7,1.1-9.3,3.3-12.9,6.1c-2.7,2.1-4.8,4.5-5.8,6.8c1.2,4.8,2.5,10.8,2.9,16.9
          c0.2,3,0,5.9-0.1,8.8c-0.1,1.9-0.2,3.9-0.2,5.8c-0.4,1.9-0.8,3.8-1.4,5.7c1.1,1.7,2.7,3.3,4.7,4.8c3.6,2.8,8.2,5,12.9,6.1
          c5.7,1.4,15.9,2.2,24.9-5.2c-0.4-4-0.5-8,0-11.9c0.2-1.6,0.4-3.1,0.6-4.6c0.8-5.8,1.5-11.4,1-17.2c0-0.1,0-0.2,0-0.3
          c0-4.4,0.8-8.7,2-13C196.5,657.5,184.2,658.4,177.8,660z"/>
        <path class="st113" d="M288.7,921.3c-6.4-6.3-11.7-14.6-14.2-22.3c-0.7-2-1.1-4.2-1.6-6.3c-0.5-2.3-1.1-4.8-1.9-7.1
          c-1.6-4.6-3-9.6-2.7-15.3c0-0.8,0.1-1.6,0.2-2.4c-1.8,0.4-4.5,0.9-7.9,1.1c-0.9,0.1-1.9,0.1-3,0.1c-5.1,0-11.8-0.7-19.2-3.5
          c-0.4,15.6-1.4,31.8-4.2,47.3l-0.2,0.9c-2,10.6-4.4,23.7,10.1,26.5c1.8,0.4,3.8,0.5,6,0.5c3.1,0,6.3-0.3,9.3-0.7
          c3.7-0.4,7.4-0.8,11.1-0.6c1.6,0.1,3.2,0.2,4.8,0.3c2.6,0.2,5.3,0.4,8,0.4c2.3,0,4.4-0.2,6.3-0.5c3.6-0.7,5.8-2.1,6.5-4.3
          C297.5,930.7,291.4,924,288.7,921.3z"/>
        <path class="st113" d="M364.4,363.3c-2.8-4-2.9-8.2-3.1-12.7c0-0.3,0-0.6,0-1l-0.4-0.4c-0.1-0.1-11.4-8.1-25.3-4.9
          c-9,2.1-17.2,8.2-24.2,18.4c0.7,3.9,1.3,7.9,1.7,11.8l0.4,3.9c0.1,0.6,0.1,1.2,0.2,1.8c10.3,6.5,20.2,8.7,29.3,6.6
          c8.8-2.1,15.3-7.9,19.2-12.5c2.2-2.6,3.9-5.1,4.9-6.9C366.2,366,365.3,364.6,364.4,363.3z"/>
      </a>
      <a id="ast112" v-on:click="toogleSvgClass($event,'ast112')" :class="this.questionary.painMuscle ? 'svglinkActive' : 'svglink'">
        <polygon class="st112" points="1000.6,773.5 1000.6,815.1 1000.6,860.8 1000.6,864.8 1046.3,864.8 1046.3,860.8 1046.3,815.1
          1046.3,773.5 		"/>
        <path class="st112" d="M642.7,795.5c0.3,2.9,0.6,5.8,0.9,8.6c1.8,17.1,3.6,33.2,3.4,50.1c-0.1,5.7-0.2,11.5-0.4,17.4
            c11.7,2.6,24,0.8,30.1-0.5c0-0.3,0-0.6,0-0.9c0.6-10.7,3.2-21.2,5.8-31.3c0.9-3.6,1.9-7.4,2.7-11.1c1.1-5,2.2-10,3.3-14.9
            c2.3-10.4,4.6-21.2,7.5-31.7c3.4-12.5,7-25.4,6.1-38.8c-0.4-6.3-2.3-12.3-4.3-18.1c-1,1.3-2.2,2.9-3.8,4.4
            c-3.6,3.8-9.6,8.4-17.2,9.3c-0.9,0.1-1.7,0.2-2.6,0.2c-8.2,0-16.7-4.4-25.2-13.2c-1,5-2.4,9.7-3.6,14.1
            C639.9,758.9,641,778.7,642.7,795.5z"/>
        <path class="st112" d="M717.4,509.3c-2,0.3-4.9,0.5-8.3,0.5c-2.6,0-5.4-0.1-8.5-0.5c-6.7-0.9-13-2.7-18.7-5.5
            c-7.1-3.5-13.4-8.5-18.6-14.8c-4.1-5-5.7-10.7-4.7-17.2c0.8-5.8,3.7-12.3,8.5-19.2c3.9-5.5,9-11.4,15.2-17.3
            c8.2-7.9,16.2-13.9,19.5-16.3c0,0,0,0,0-0.1c-3.3,0.3-8.6,1.5-15.7,5.3c-11.8,6.3-30.9,21.3-55.5,56.8l-0.1,0.1l-0.1-0.1
            c-24.6-35.5-43.5-50.4-55.1-56.7c-7.1-3.9-12.3-5-15.5-5.3c0,0.1,0,0.2-0.1,0.3c3.5,2.6,11.2,8.4,19.1,15.9
            c6.2,6,11.4,11.8,15.2,17.3c4.8,6.9,7.7,13.4,8.5,19.2c0.9,6.5-0.7,12.3-4.7,17.2c-5.2,6.3-11.5,11.3-18.6,14.8
            c-5.7,2.8-12,4.6-18.7,5.5c-3.1,0.4-5.9,0.5-8.5,0.5c-3.1,0-5.8-0.2-7.8-0.4c-0.6,5.8-1.1,11.6-1.3,17.5
            c-0.4,12.5-0.2,25.4,0.7,38.3c1.7,24,4.1,49.5,12.4,73.3c1,2.8,2,5.6,3,8.4c3,8.2,6,16.6,8.2,25.1c0.2,0.8,0.4,1.6,0.6,2.4
            c3.5-3.8,9.9-9.2,18.4-9.9c8.8-0.7,17.8,3.9,26.8,13.5c0.6-5.7,2.3-11.4,4.1-16.8c1.1-3.7,2.3-7.5,3.2-11.3
            c2.4-10.9,3.6-23,3.9-37c0.2-11.6-0.1-23.3-0.5-34.6c-0.1-3-0.2-5.9-0.3-8.9c-0.2-6.5-0.1-13.3,0.2-21.5
            c0.3-6.2,0.7-9.7,6.7-10.1c0.2,0,0.4,0,0.5-0.1c0.2,0.1,0.4,0.1,0.5,0.1c6,0.3,6.4,3.8,6.7,10.1c0.3,8.2,0.4,15,0.2,21.5
            c-0.1,3-0.2,5.9-0.3,8.9c-0.4,11.3-0.7,23.1-0.5,34.6c0.3,14,1.6,26.1,3.9,37c0.8,3.8,2,7.6,3.2,11.3c1.7,5.4,3.4,10.9,4,16.6
            c8.9-9.4,17.8-13.9,26.6-13.2c8.7,0.7,15.2,6.4,18.6,10.1c0.2-0.9,0.4-1.8,0.7-2.7c2.1-8.5,5.2-17,8.2-25.1c1-2.8,2-5.6,3-8.4
            c8.3-23.9,10.7-49.3,12.4-73.3c0.9-13,1.2-25.9,0.7-38.3C718.5,521,718,515.1,717.4,509.3z"/>
        <path class="st112" d="M757.2,473.6c1.1,3.3,2.3,6.6,4,9.8l0,0.1l0,0.1c0,0,0,0,0,0h0c0.1,0.6,0.2,1.3,0.3,1.9
            c8.5-0.5,15.5-2.4,20.4-4.1c3.3-1.2,6-2.5,7.9-3.4c-0.9-0.1-1.9-0.2-2.8-0.2c-2.9-17-3.7-34.5-4.4-51.4
            c-0.6-12.4-1.1-25.3-2.6-37.9c-0.1-0.9-0.2-1.7-0.3-2.6c-0.2-1.8-0.4-3.7-0.6-5.6c-3.1,4.3-10.1,12.1-21.1,14.8
            c-2.6,0.6-5.2,0.9-7.9,0.9c-8.5,0-17.6-3.1-27.2-9.2c1,8.3,2.4,16.4,5.1,24.7c3.5,10.5,8.7,20.3,13.8,29.8
            c1.1,2,2.2,4.1,3.3,6.1c3.2,6.1,6.3,12.5,9.3,19.1C755.5,468.7,756.4,471.2,757.2,473.6z"/>
        <path class="st112" d="M565.5,781.2c2.9,10.5,5.2,21.3,7.5,31.7c1.1,4.9,2.2,9.9,3.3,14.9c0.9,3.7,1.8,7.5,2.7,11.1
            c2.6,10.1,5.3,20.6,5.8,31.3c0,0.3,0,0.6,0,1c6.2,1.3,18.5,3,30.1,0.3c-0.2-5.8-0.3-11.6-0.4-17.3c-0.2-16.9,1.6-33,3.4-50.1
            c0.3-2.9,0.6-5.7,0.9-8.6c1.7-16.8,2.8-36.6-2.7-56.4c-1.2-4.4-2.6-9.3-3.6-14.3c-8.6,8.9-17.2,13.4-25.5,13.4
            c-0.9,0-1.8-0.1-2.6-0.2c-7.6-0.9-13.5-5.5-17.2-9.3c-1.4-1.5-2.6-2.9-3.6-4.2c-2,5.7-3.8,11.6-4.2,17.8
            C558.6,755.8,562.1,768.7,565.5,781.2z"/>
        <path class="st112" d="M510.5,396.1c-2.7,0-5.3-0.3-7.8-0.9c-10.3-2.4-17.1-9.4-20.4-13.6c-0.2,1.4-0.3,2.8-0.5,4.1
            c-0.1,0.9-0.2,1.8-0.3,2.6c-1.4,12.6-2,25.5-2.6,37.9c-0.8,16.9-1.6,34.4-4.4,51.4c-1.1,0-2.1,0.1-3.2,0.3
            c1.9,1,4.5,2.2,7.8,3.4c5,1.8,12.2,3.7,20.9,4.2c0.1-0.6,0.2-1.3,0.3-1.9h0c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2
            c1.7-3.1,2.9-6.5,4-9.8c0.8-2.4,1.7-4.9,2.8-7.2c3-6.6,6.1-13,9.3-19.1c1.1-2.1,2.2-4.1,3.3-6.1c5.1-9.5,10.3-19.3,13.8-29.8
            c2.8-8.4,4.1-16.6,5.2-25C528.7,392.8,519.3,396.1,510.5,396.1z"/>
        <path class="st112" d="M769.8,350.6c0-1.1-0.1-2.2-0.1-3.4c-0.4-6.8-2.3-13.5-4.1-19.9c-2.1-7.6-4.3-15.5-4.1-23.8
            c0,0,0-0.1,0-0.1c0-0.1,0.1-0.3,0.1-0.4c0-4.9,0.2-9.9,0.5-14.7c0.2-4.5,0.4-9,0.5-13.5c-4.2,2.5-8.8,3.9-13.6,4.2
            c-0.7,0-1.4,0.1-2,0.1c-8.5,0-16.3-3.4-21.5-6.4c-5.3-3-8.9-6.2-9.8-7c2.5,17.5,0.2,46.1-1.4,60.7c0.2,1.8,0.4,3.5,0.7,5.1l0,0
            l0,0.1c0.8,7.4,2.1,14.8,3.4,21.9c0.6,3.1,1.1,6.3,1.6,9.4c6.3-7.9,13.6-12.9,21.8-15c8.7-2.2,16.8-0.7,22,1
            c2.4,0.8,4.5,1.6,6.1,2.4C769.8,351.1,769.8,350.8,769.8,350.6z"/>
        <path class="st112" d="M546.8,299.8L546.8,299.8c0-0.5-0.9-8.8-1.2-34.1c-0.8,0.7-4.5,4-9.9,7c-5.2,3-13,6.4-21.5,6.4
            c-0.7,0-1.4,0-2-0.1c-4.6-0.3-8.9-1.6-13-3.9c0,4.4,0.3,8.8,0.5,13.2c0.2,4.9,0.5,9.9,0.5,14.7c0,0.2,0,0.3,0.1,0.4l0,0l0,0.1
            c0.2,8.2-2,16.1-4.1,23.8c-1.8,6.4-3.6,13-4.1,19.9c-0.1,1.1-0.1,2.3-0.1,3.4c0,0.2,0,0.4,0,0.7c1.5-0.8,3.7-1.7,6.3-2.6
            c5-1.6,12.7-3.1,21-1c8.5,2.1,16,7.4,22.5,15.8c0.5-3.4,1.1-6.7,1.7-10c1.3-7.2,2.6-14.6,3.4-21.9l0,0c0.3-1.6,0.5-3.4,0.7-5.3
            c-0.6-13.5-0.7-26.1-0.7-26.3L546.8,299.8z"/>
        <path class="st112" d="M308.5,506.3c-2.3,0.5-4.6,0.8-7,0.8c-1.7,0-3.4-0.1-5.1-0.4c-5.5-0.8-11.1-2.9-16.9-6.2
          c-9.7-5.6-16.3-12.9-16.3-13c0,0-2.7-3.3-4.7-8.3l-18.8,17.7c0.1,0.6,0.8,6.6,0.6,13.7c-0.2,6.9-1.5,16.4-6.2,22.3
          c-2.4,3-5.4,4.8-9,5.4c3.8,1.1,4.2,4.4,4.4,9.7c0.3,8.2,0.4,15,0.2,21.5c-0.1,3-0.2,5.9-0.3,8.9c-0.4,11.3-0.7,23.1-0.5,34.6
          c0.3,14,1.6,26.1,3.9,37c0.8,3.8,2,7.6,3.2,11.3c0.6,1.9,1.2,3.9,1.8,5.9c3.9-3.7,8.4-6.1,13.4-7.4c5-1.2,10.6-1.1,16.1,0.2
          c8.2,2,15.5,6.8,18.6,11.8c2.1-8.4,5.2-16.7,8.1-24.8c1-2.8,2-5.6,3-8.4c8.3-23.9,10.7-49.3,12.4-73.3c0.9-13,1.2-25.9,0.7-38.3
          C309.9,520,309.2,513,308.5,506.3z"/>
        <path class="st112" d="M131.2,377.7c-9.3,6.7-18.2,10.1-26.6,10.1c-2.3,0-4.6-0.3-6.9-0.8c-8.9-2.1-15.4-7.9-19.3-12.5
          c-1-1.1-1.8-2.2-2.5-3.2c-1.5,4.5-2.1,9.8-2.6,14.4c-0.1,0.9-0.2,1.8-0.3,2.6c-1.4,12.6-2,25.5-2.6,37.9
          c-0.7,16.5-1.5,33.6-4.2,50.2c5.8,0.8,16,3.2,25.2,9.8c0.1-0.9,0.3-1.8,0.4-2.7c0-0.1,0-0.1,0-0.2c1.7-3.1,2.9-6.5,4-9.8
          c0.8-2.4,1.7-4.9,2.8-7.2c3-6.6,6.1-13,9.3-19.1c1.1-2.1,2.2-4.1,3.3-6.1c5.1-9.5,10.3-19.3,13.8-29.8c3.6-11,4.8-21.6,6.1-32.9
          L131.2,377.7z"/>
        <path class="st112" d="M234.2,795.5c0.3,2.9,0.6,5.8,0.9,8.6c1.8,17.1,3.6,33.2,3.4,50.1c0,3.7-0.1,7.4-0.2,11.1
          c13.7,5.1,25.4,3.4,30.1,2.3c0.8-9.8,3.3-19.4,5.6-28.7c0.9-3.6,1.9-7.4,2.7-11.1c1.1-5,2.2-10,3.3-14.9
          c2.3-10.4,4.6-21.2,7.5-31.7c3.4-12.5,7-25.4,6.1-38.8c-0.5-7.6-3.1-14.8-5.6-21.8c-1.1-3.1-2.3-6.2-3.2-9.4
          c-1.1,1.3-2.4,2.7-4,3.9c-3.6,2.9-8.2,5.1-12.9,6.2c-2,0.5-4.5,0.9-7.3,0.9c-5.6,0-12.4-1.6-18.5-7.5c-0.7,8.6-3.1,17.1-5.1,24.4
          C231.4,758.9,232.5,778.7,234.2,795.5z"/>
        <path class="st112" d="M352.7,483.4L352.7,483.4l0,0.2c0,0.1,0,0.2,0.1,0.4c10.9-6.5,22.3-7.7,25.5-7.9
          c-2.7-16.5-3.4-33.4-4.2-49.8c-0.6-12.4-1.1-25.3-2.6-37.9c-0.1-0.9-0.2-1.7-0.3-2.6c-0.5-5.1-1.1-10.8-3-15.5
          c-0.3-0.9-0.7-1.7-1.1-2.4c-1.1,1.8-2.7,4.2-4.9,6.8c-3.9,4.6-10.4,10.5-19.3,12.5c-2.2,0.5-4.5,0.8-6.9,0.8
          c-7.1,0-14.6-2.4-22.4-7.3c1.2,10.5,2.5,20.5,5.9,30.8c3.5,10.5,8.7,20.3,13.8,29.8c1.1,2,2.2,4.1,3.3,6.1
          c3.2,6.1,6.3,12.5,9.3,19.1c1.1,2.3,1.9,4.8,2.8,7.2C349.9,476.9,351,480.3,352.7,483.4z"/>
        <path class="st112" d="M151,742.5c-0.9,13.4,2.7,26.3,6.1,38.8c2.9,10.5,5.2,21.3,7.5,31.7c1.1,4.9,2.2,9.9,3.3,14.9
          c0.9,3.7,1.8,7.5,2.7,11.1c2.4,9.3,4.8,18.9,5.6,28.7c1.8,0.4,4.5,0.9,8,1.1c5.3,0.3,13.2-0.1,22.1-3.4
          c-0.1-3.7-0.1-7.5-0.2-11.1c-0.2-16.9,1.6-33,3.4-50.1c0.3-2.9,0.6-5.7,0.9-8.6c1.7-16.8,2.8-36.6-2.7-56.4
          c-1.9-6.9-4.2-14.9-5-23.1c-3,2.4-6.4,4.2-10,5.2c-2.3,0.6-4.8,0.9-7.4,0.9c-2.5,0-5-0.3-7.5-0.9c-4.7-1.1-9.4-3.3-13-6.2
          c-1.9-1.5-3.5-3.1-4.6-4.7c-1,3.5-2.2,6.9-3.5,10.3C154.1,727.6,151.5,734.8,151,742.5z"/>
        <path class="st112" d="M219.5,538.3c-3.7-0.6-6.7-2.5-9.1-5.5c-4.7-5.9-6-15.4-6.2-22.3c-0.2-7.1,0.5-13,0.6-13.7L186,479.2
          c-2,4.9-4.6,8.2-4.6,8.2c-0.1,0.1-6.6,7.4-16.3,13c-5.7,3.3-11.4,5.4-16.9,6.2c-1.7,0.3-3.4,0.4-5.1,0.4c-2.3,0-4.6-0.2-6.9-0.7
          c-0.7,6.8-1.4,13.7-1.7,20.6c-0.4,12.5-0.2,25.4,0.7,38.3c1.7,24,4.1,49.5,12.4,73.3c1,2.8,2,5.6,3,8.4c3,8.2,6,16.6,8.2,25.1
          c0,0.2,0.1,0.4,0.1,0.6c1.1-2.3,3.1-4.6,5.8-6.7c3.7-2.8,8.3-5,13-6.2c5.1-1.2,10.3-1.2,14.9,0c5.3,1.4,9.9,4.4,13.8,8.8
          c0.7-2.5,1.4-5,2.2-7.4c1.1-3.7,2.3-7.5,3.2-11.3c2.4-10.9,3.6-23,3.9-37c0.2-11.6-0.1-23.3-0.5-34.6c-0.1-3-0.2-5.9-0.3-8.9
          c-0.2-6.5-0.1-13.3,0.2-21.5C215.3,542.7,215.7,539.3,219.5,538.3z"/>
        <path class="st112" d="M335.5,344.1c14-3.2,25.4,4.9,25.5,5l0.3,0.2c0-0.7-0.1-1.3-0.1-2c-0.4-6.8-2.3-13.5-4.1-19.9
          c-2.1-7.6-4.3-15.5-4.1-23.8c0,0,0-0.1,0-0.1c0-0.1,0.1-0.3,0.1-0.4c0-4.9,0.2-9.9,0.5-14.7c0.3-6.8,0.7-13.8,0.4-20.7
          c-5.8,4.8-12.2,7.5-18.9,8.1c-0.9,0.1-1.7,0.1-2.5,0.1c-13.1,0-23.3-8.1-24.8-9.3c0,3.6-0.6,48.9-1.9,60.4
          c0.2,1.7,0.4,3.2,0.6,4.7l0,0l0,0.1c0.8,7.4,2.1,14.8,3.4,21.9c0.5,2.9,1,5.9,1.5,8.9C318.4,352.3,326.5,346.2,335.5,344.1z"/>
        <path class="st112" d="M137.6,286.9c-0.2-7.5-0.3-14.6-0.5-20.6l0,0c-0.1,0.1-10.9,9.5-25,9.5c-0.8,0-1.7,0-2.5-0.1
          c-6.7-0.5-13-3.2-18.8-8c-0.3,6.9,0,13.8,0.4,20.6c0.2,4.9,0.5,9.9,0.5,14.7c0,0.2,0,0.3,0.1,0.4l0,0l0,0.1
          c0.2,8.2-2,16.1-4.1,23.8c-1.8,6.3-3.6,12.8-4.1,19.6c4.5-2.2,12.5-4.9,21.7-2.8c10.4,2.4,19.6,10.1,27.3,23.1
          c0.7-4.6,1.5-9.2,2.3-13.7c1.3-7.2,2.6-14.6,3.4-21.9l0,0c0.3-1.6,0.5-3.3,0.7-5.2C138.2,316.6,137.9,301.1,137.6,286.9z"/>
      </a>
      <polygon points="396.6,508.6 396.6,508.6 396.6,508.6 			"/>
      <polygon points="272.5,312.1 272.5,312.1 272.5,312.1 			"/>
      <polygon points="172.2,312.1 172.2,312.1 172.2,312.1 			"/>
      <polygon points="94.7,303.4 94.7,303.4 94.7,303.4 			"/>
      <polygon points="209,219.8 209,219.8 209,219.8 			"/>
      <polygon points="235.6,219.8 235.6,219.8 235.6,219.8 			"/>






      <path d="M170.5,312.5c-5.4,0-10.3-1.3-14.8-3.8c-3.9-2.2-7.4-5.3-10.5-9.2c-5.2-6.7-7.4-13.6-7.4-13.7l0.2-0.1
				c0,0.1,2.2,6.9,7.4,13.6c4.8,6.1,13.3,13.4,26.4,12.9l0,0.2C171.4,312.5,170.9,312.5,170.5,312.5z"/>
      <path d="M117.8,372.4l-0.2-0.2c0.4-0.4,10.7-10.8,15.6-12.3l0.1,0.2C128.5,361.7,117.9,372.3,117.8,372.4z"/>
      <path d="M91.5,366.6c-7.2-5.5-7.5-10.2-7.5-10.2l0.2,0c0,0,0.4,4.6,7.5,10.1L91.5,366.6z"/>
      <path d="M99.3,316c-0.7-11.6-7.7-16.8-7.7-16.8l0.1-0.2c0.1,0.1,7.1,5.2,7.8,17L99.3,316z"/>
      <path d="M180.7,452.5c-1.8-11.9-11.3-19.1-18.9-24.8c-5.7-4.3-10.6-8-10.6-12.4h0.2c0,4.3,4.9,8,10.5,12.3c7.7,5.8,17.2,13,19,25
				L180.7,452.5z"/>
      <path d="M209.5,219.3c-4.3-0.3-6.9-1.3-9.3-2.3c-2.3-0.9-4.5-1.8-7.8-1.9c-3.2-0.1-6.7,0.8-10,1.7c-3,0.8-5.9,1.6-8.3,1.5
				c-1.9,0-5.1-1.3-9.2-2.9c-7-2.8-15.7-6.2-23.1-5.6l0-0.2c7.5-0.6,16.2,2.8,23.2,5.6c3.9,1.5,7.3,2.9,9.1,2.9
				c2.4,0,5.2-0.7,8.2-1.5c3.4-0.9,6.9-1.8,10.1-1.7c3.4,0.1,5.6,1,7.9,1.9c2.5,1,5,2,9.3,2.2L209.5,219.3z"/>
      <path d="M164.8,909.6l-0.1-0.2c9.7-8.7,18.1-10.2,18.2-10.2l0,0.2C182.8,899.5,174.5,900.9,164.8,909.6z"/>
      <path d="M276.6,304.1c-0.4,0-0.7,0-1,0l-0.1,0l0,0c-0.2,0-0.5,0-0.7,0.1l-0.1,0c-0.1,0-0.2,0-0.2,0l-0.1,0l0,0
				c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0-0.5,0.1-0.8,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2,0.1-0.4,0.3-0.5,0.6c0,0.1-0.1,0.1-0.1,0.1
				c-0.3,0.5-0.4,1-0.1,1.6c0.6,1.5,2.6,1.5,3.2,1.5c0.5,0,1,0,1.4-0.1l0.2,0c0.5,0,2.2-0.2,2.9-1.3c0.3-0.5,0.4-1,0.2-1.7
				C279.2,304.3,277.7,304.1,276.6,304.1z"/>
      <path d="M172.3,305.5c-0.1-0.3-0.3-0.5-0.5-0.6c-0.1-0.1-0.1-0.2-0.3-0.2c-0.3-0.1-0.6-0.2-0.7-0.2c-0.3-0.1-0.6-0.2-0.8-0.2
				l-0.2,0c-0.1,0-0.1,0-0.2,0l-0.1,0c-0.2,0-0.5,0-0.8,0c-0.3,0-0.7,0-1.1,0c-1.1,0-2.6,0.2-3,1.6c-0.2,0.6-0.2,1.2,0.2,1.7
				c0.7,1,2.4,1.2,2.9,1.3l0.2,0c0.4,0,0.9,0.1,1.4,0.1c0.7,0,2.6,0,3.2-1.5c0.2-0.6,0.2-1.1-0.1-1.6
				C172.4,305.6,172.3,305.6,172.3,305.5z"/>
      <path d="M222.5,403.1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0
				c-0.1,0-0.1,0-0.1,0c-1.4,0.2-2.8,1.2-2.8,2.8c0,0.2,0.1,0.4,0.2,0.6c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.6-0.1,0.7-0.3
				c0.2-0.2,0.2-0.4,0.2-0.7c0-0.1,0-0.2,0.1-0.4c0.3-0.3,1-0.5,1.6-0.6c0.6,0.1,1.3,0.3,1.5,0.6c0.1,0.1,0.1,0.2,0.1,0.4
				c0,0.2,0,0.5,0.2,0.6c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.2-0.4,0.2-0.6
				C225.3,404.3,223.9,403.3,222.5,403.1z"/>
      <path d="M231.4,150.4c-2.4,0.5-4.8,0.6-7.2,0.7c-0.7,0-1.5,0.1-2.2,0.1c-0.7,0-1.5-0.1-2.2-0.1c-2.4-0.1-4.9-0.2-7.2-0.7
				c-0.6-0.1-1,0.3-1.1,0.8c0,0.5,0.3,0.9,0.7,1c3.2,0.6,6.6,0.7,9.7,0.7c0,0,0,0,0,0h0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0h0
				c0,0,0,0,0,0c3-0.1,6.4-0.1,9.7-0.7c0.5-0.1,0.8-0.5,0.7-1C232.4,150.7,231.9,150.3,231.4,150.4z"/>
      <path d="M245.8,116.8c-2.3-1-4.6-1.5-7.2-1.5c-2.2,0-4.6,0.4-7.5,1.2c-0.4,0.1-0.7,0.5-0.6,1c0.1,0.5,0.5,0.8,1,0.7
				c1.5-0.4,2.8-0.6,4-0.8c0.2,1.7,1.6,3,3.3,3c0.1,0,0.2,0,0.3,0c1.2-0.1,2.3-1.1,2.4-2.3c0.1-0.2,0.1-0.4,0.2-0.6
				c1.1,0.2,2.2,0.6,3.2,1c0.1,0.1,0.3,0.1,0.4,0.1c0.4,0,0.8-0.3,0.9-0.8C246.4,117.3,246.2,116.9,245.8,116.8z"/>
      <path d="M229.6,111.7c0.2,0,0.3-0.1,0.5-0.2c3-2,6.8-3,11.6-3c1.4,0,3,0.1,4.8,0.3l0.1,0c0.5,0,0.8-0.4,0.9-0.8
				c0-0.4-0.2-0.9-0.8-0.9c-7.4-0.9-13.3,0.1-17.5,3c-0.3,0.2-0.4,0.6-0.3,1C228.9,111.4,229.2,111.7,229.6,111.7z"/>
      <path d="M421,513.1c-7.2-5.5-13-12.6-18.6-19.4c-1.5-1.8-2.9-3.6-4.4-5.3c-5.8-6.9-13-10.8-19.8-10.8c-2.9-17-3.7-34.5-4.4-51.4
				c-0.6-12.4-1.1-25.3-2.6-37.9c-0.1-0.9-0.2-1.7-0.3-2.6c-0.5-5.1-1.1-10.8-3-15.5c-1.1-2.7-2.5-4.9-3.9-6.9
				c-2.8-4-2.9-8.2-3.1-12.7c0-1.1-0.1-2.2-0.1-3.4c-0.4-6.8-2.3-13.5-4.1-19.9c-2.1-7.6-4.3-15.5-4.1-23.8c0,0,0-0.1,0-0.1
				c0-0.1,0.1-0.3,0.1-0.4c0-4.9,0.2-9.9,0.5-14.7c0.6-12.1,1.2-24.7-1.8-36.6c-3.6-14.3-13-26.9-26.6-35.6
				c-6.4-4.1-13.7-6.7-21.7-7.9c-2.5-0.4-5-0.5-7.5-0.7c-3.8-0.2-7.7-0.5-11.4-1.5c-3.2-0.9-6.3-2.4-9.3-3.8c-1.4-0.7-2.9-1.4-4.4-2
				c-8.3-3.5-13.4-8-16.7-14.4c-3.2-6.4-3.6-14-3.6-21.2c0-0.2,0-0.3-0.1-0.4c0.1-0.1,0.1-0.1,0.2-0.1c8.3-7.7,9.3-18.3,10.1-27.7
				c7.2-2.6,8.2-8.8,9.3-15.8l0.2-1.2c0.7-3.9,0.1-7-1.5-8.9c-1.3-1.5-3.2-2.3-5.6-2.3c3.1-12.4,2.2-27.6-2.5-38.8
				c-5-12.2-21.4-14.5-30.7-14.7c-1.2,0-4.1-0.1-6.9-0.1c-2.6,0-8.8,0-11.5,0.2c-10.2,0.7-23.2,3.8-27.6,14.6
				c-4.6,11.3-5.6,26.4-2.5,38.8c-2.5,0-4.3,0.8-5.6,2.3c-1.6,1.9-2.1,5-1.5,8.9l0.2,1.2c1.1,7.1,2.1,13.2,9.3,15.8
				c0.9,9.4,1.9,19.9,10.1,27.7c0.1,0.1,0.1,0.1,0.2,0.1c0,0.1-0.1,0.3-0.1,0.4c-0.1,7.2-0.4,14.8-3.6,21.2
				c-3.2,6.4-8.4,10.8-16.7,14.4c-1.5,0.6-3,1.3-4.4,2c-3,1.4-6.1,2.9-9.3,3.8c-3.7,1.1-7.6,1.3-11.4,1.5c-2.5,0.1-5,0.3-7.5,0.7
				c-8,1.2-15.3,3.9-21.7,7.9c-13.6,8.6-23,21.3-26.6,35.6c-3,11.9-2.4,24.5-1.8,36.6c0.2,4.9,0.5,9.9,0.5,14.7c0,0.2,0,0.3,0.1,0.4
				l0,0l0,0.1c0.2,8.2-2,16.1-4.1,23.8c-1.8,6.4-3.6,13-4.1,19.9c-0.1,1.1-0.1,2.3-0.1,3.4c-0.1,4.5-0.3,8.7-3.1,12.7
				c-1.4,2-2.9,4.2-3.9,6.9c-1.9,4.8-2.5,10.5-3,15.5c-0.1,0.9-0.2,1.8-0.3,2.6c-1.4,12.6-2,25.5-2.6,37.9
				c-0.8,16.9-1.6,34.4-4.4,51.4c-6.8,0-14,4-19.8,10.8c-1.5,1.8-2.9,3.5-4.4,5.3c-5.6,6.8-11.4,13.9-18.6,19.4
				c-0.6,0.5-0.8,1.1-0.7,1.8c-0.1,0.7,0.3,1.3,0.9,1.7c2.3,1.1,4.5,1.7,6.7,1.7c4.1,0,7.7-1.9,11.4-4c1.1-0.6,2.3-1.3,3.5-1.9
				c-0.3,2.2-0.7,5-1.3,8.1c-2.2,13.1-5.3,31.1,0.5,38.7c1.4,1.9,3.3,3.1,5.7,3.5c0.3,4,4.1,6.5,7.6,6.5c2,0,3.8-0.8,5.1-2.2
				c3.9,0.9,6.8,0.3,8.6-1.7c1.8-2,2.6-4.5,3.2-6.7c0.8,0.4,1.6,0.6,2.4,0.6c6.9,0,9.7-14.4,10.6-19.2c1.9-9.7,2.1-19.7,2.4-29.4
				c0.2-8.9,0.4-18.1,2-27c0-0.1,0-0.1,0-0.2c1.7-3.1,2.9-6.5,4-9.8c0.8-2.4,1.7-4.9,2.8-7.2c3-6.6,6.1-13,9.3-19.1
				c1.1-2.1,2.2-4.1,3.3-6.1c5.1-9.5,10.3-19.3,13.8-29.8c3.6-11,4.8-21.6,6.1-32.9l0.4-3.9c0.8-7,2.1-14.1,3.3-21
				c1.3-7.2,2.6-14.6,3.4-21.9l0,0c0.3-1.7,0.5-3.5,0.7-5.5c1.4,4.6,3.1,9.1,4.7,13.5c3.3,8.9,6.8,18.1,7.6,27.7
				c0.8,9,0.6,18.1,0.4,27l0,1.4c-0.1,2.7,0,5.5,0,8.1c0.1,4.6,0.1,9.4-0.3,14c0,0.5-0.4,1.8-0.5,2c-9.3,19-11,40.6-12.6,61.4
				c-0.2,3-0.5,6-0.7,9c-0.4,4.4-0.9,8.9-1.3,13.3c-0.8,7.6-1.7,15.5-2,23.3c-0.4,12.5-0.2,25.4,0.7,38.3c1.7,24,4.1,49.5,12.4,73.3
				c1,2.8,2,5.6,3,8.4c3,8.2,6,16.6,8.2,25.1c1.2,4.9,2.7,11.3,3.1,17.8c0.2,3,0,5.9-0.1,8.8c-0.1,1.9-0.2,3.9-0.2,5.8
				c-1,5.6-3,11-4.9,16.3c-2.5,6.9-5.1,14.1-5.6,21.8c-0.9,13.4,2.7,26.3,6.1,38.8c2.9,10.5,5.2,21.3,7.5,31.7
				c1.1,4.9,2.2,9.9,3.3,14.9c0.9,3.7,1.8,7.5,2.7,11.1c2.6,10.1,5.3,20.6,5.8,31.3c0.3,5.7-1.1,10.7-2.7,15.3
				c-0.8,2.3-1.4,4.8-1.9,7.1c-0.5,2.1-0.9,4.2-1.6,6.3c-2.5,7.7-7.8,16-14.2,22.3c-2.7,2.7-8.9,9.4-7.4,14.2
				c0.7,2.2,2.9,3.7,6.5,4.3c1.9,0.4,4,0.5,6.3,0.5c2.7,0,5.4-0.2,8-0.4c1.6-0.1,3.2-0.3,4.8-0.3c3.6-0.2,7.4,0.2,11.1,0.6
				c3.1,0.3,6.2,0.7,9.3,0.7c2.2,0,4.2-0.2,6-0.5c14.5-2.8,12.1-15.9,10.1-26.5l-0.2-0.9c-3.6-19.3-4.2-39.6-4.4-58.7
				c-0.2-16.9,1.6-33,3.4-50.1c0.3-2.9,0.6-5.7,0.9-8.6c1.7-16.8,2.8-36.6-2.7-56.4c-2.9-10.3-6.5-23.1-5-35.2
				c0.2-1.6,0.4-3.1,0.6-4.6c0.8-5.8,1.5-11.4,1-17.2c0-0.1,0-0.2,0-0.3c0-7,2.2-13.9,4.3-20.6c1.1-3.7,2.3-7.5,3.2-11.3
				c2.4-10.9,3.6-23,3.9-37c0.2-11.6-0.1-23.3-0.5-34.6c-0.1-3-0.2-5.9-0.3-8.9c-0.2-6.5-0.1-13.3,0.2-21.5
				c0.3-6.2,0.7-9.7,6.7-10.1c0.2,0,0.4,0,0.5-0.1c0.2,0.1,0.4,0.1,0.5,0.1c6,0.3,6.4,3.8,6.7,10.1c0.3,8.2,0.4,15,0.2,21.5
				c-0.1,3-0.2,5.9-0.3,8.9c-0.4,11.3-0.7,23.1-0.5,34.6c0.3,14,1.6,26.1,3.9,37c0.8,3.8,2,7.6,3.2,11.3c2.1,6.7,4.3,13.6,4.3,20.6
				c0,0.1,0,0.2,0,0.3c-0.5,5.8,0.3,11.3,1,17.2c0.2,1.5,0.4,3.1,0.6,4.6c1.4,12.1-2.1,24.9-5,35.2c-5.5,19.7-4.5,39.6-2.7,56.4
				c0.3,2.9,0.6,5.8,0.9,8.6c1.8,17.1,3.6,33.2,3.4,50.1c-0.2,19.1-0.8,39.4-4.4,58.7l-0.2,0.9c-2,10.6-4.4,23.7,10.1,26.5
				c1.8,0.4,3.8,0.5,6,0.5c3.1,0,6.3-0.3,9.3-0.7c3.7-0.4,7.4-0.8,11.1-0.6c1.6,0.1,3.2,0.2,4.8,0.3c2.6,0.2,5.3,0.4,8,0.4
				c2.3,0,4.4-0.2,6.3-0.5c3.6-0.7,5.8-2.1,6.5-4.3c1.5-4.7-4.7-11.5-7.4-14.2c-6.4-6.3-11.7-14.6-14.2-22.3c-0.7-2-1.1-4.2-1.6-6.3
				c-0.5-2.3-1.1-4.8-1.9-7.1c-1.6-4.6-3-9.6-2.7-15.3c0.6-10.7,3.2-21.2,5.8-31.3c0.9-3.6,1.9-7.4,2.7-11.1c1.1-5,2.2-10,3.3-14.9
				c2.3-10.4,4.6-21.2,7.5-31.7c3.4-12.5,7-25.4,6.1-38.8c-0.5-7.6-3.1-14.8-5.6-21.8c-1.9-5.3-3.9-10.7-4.9-16.2c0-2-0.1-4-0.2-5.9
				c-0.2-2.9-0.3-5.9-0.1-8.8c0.4-6.5,1.9-12.9,3.1-17.8c2.1-8.5,5.2-17,8.2-25.1c1-2.8,2-5.6,3-8.4c8.3-23.9,10.7-49.3,12.4-73.3
				c0.9-13,1.2-25.9,0.7-38.3c-0.3-7.8-1.1-15.7-2-23.3c-0.5-4.4-1-8.8-1.3-13.3c-0.3-3-0.5-6-0.7-9c-1.6-20.8-3.3-42.4-12.6-61.4
				c-0.1-0.2-0.4-1.5-0.5-2c-0.4-4.6-0.4-9.4-0.3-14c0-2.7,0.1-5.4,0-8.1l0-1.4c-0.2-8.9-0.4-18,0.4-27c0.9-9.7,4.3-18.9,7.6-27.7
				c1.6-4.4,3.3-8.9,4.7-13.5c0.2,2,0.5,3.8,0.7,5.4l0,0l0,0.1c0.8,7.4,2.1,14.8,3.4,21.9c1.2,6.9,2.5,14,3.3,21l0.4,3.9
				c1.3,11.3,2.5,21.9,6.1,32.9c3.5,10.5,8.7,20.3,13.8,29.8c1.1,2,2.2,4.1,3.3,6.1c3.2,6.1,6.3,12.5,9.3,19.1
				c1.1,2.3,1.9,4.8,2.8,7.2c1.1,3.3,2.3,6.6,4,9.8l0,0.1l0,0.1c1.5,8.9,1.8,18.1,2,27c0.2,9.6,0.5,19.6,2.4,29.4
				c0.9,4.7,3.8,19.2,10.6,19.2c0.8,0,1.6-0.2,2.4-0.6c0.6,2.3,1.4,4.8,3.2,6.7c1.8,2,4.7,2.6,8.6,1.7c1.3,1.4,3.1,2.2,5.1,2.2
				c3.5,0,7.2-2.5,7.6-6.5c2.3-0.4,4.2-1.6,5.7-3.5c5.7-7.6,2.7-25.6,0.5-38.7c-0.5-3.1-1-5.9-1.3-8.1c1.2,0.6,2.4,1.3,3.5,1.9
				c3.7,2.1,7.3,4,11.4,4c2.2,0,4.4-0.5,6.7-1.7c0.6-0.3,1-0.9,0.9-1.7C421.8,514.3,421.6,513.6,421,513.1z M396.2,508.6
				L396.2,508.6L396.2,508.6L396.2,508.6z M397.6,507.7c-0.7-0.2-1.3-0.1-1.7,0.3l-0.2,0.2c-0.3,0.3-0.4,0.6-0.4,1
				c-0.1,2.3,0.6,6.6,1.5,12.1c1.9,11.9,4.9,29.9-0.2,35.8c-1.1,1.3-2.5,1.9-4.3,2c0.1-3.5-0.3-7.1-0.7-10.4
				c-0.3-2.2-0.6-4.3-0.9-6.5c-0.7-4.7-1.4-9.6-1.5-14.4c0.7-0.1,1.2-0.2,1.7-0.3c0.5-0.1,0.7-0.5,0.7-1c-0.1-0.6-0.6-0.9-1.1-0.8
				c-3.3,0.7-6.7,0.8-10,0.9c-0.8,0-1.5,0-2.3,0.1c-1,0-2,0.1-3,0.1c-1.9,0.1-3.9,0.2-5.9,0.2c-1.9,0-3.6-0.1-5.2-0.3l-0.1,0
				c-0.5,0-0.8,0.4-0.9,0.9c0,0.5,0.3,0.8,0.7,0.9c0.7,0.1,1.6,0.2,2.5,0.2c-0.1,4.2,0.8,8.3,1.7,12.2l0.2,0.8
				c0.6,2.5,0.9,5.2,1.2,7.7c0.2,1.4,0.4,2.9,0.6,4.4c-0.3,0.2-0.6,0.5-0.7,0.8c-0.5,0.3-1,0.5-1.5,0.5c-1.6,0-5.8-2.4-8.8-24.9
				l-0.1-0.4c-1-7.2-1.1-14.5-1.1-21.6c-0.1-8.2-0.2-16.7-1.6-25c0.1-0.4,0-0.8-0.2-1.2c-1.7-3.2-2.9-6.7-4.1-10
				c-0.6-1.7-1.2-3.5-1.9-5.2c-2-5.1-4.5-10.1-7-14.9l-1-2c-1.8-3.5-3.6-7-5.4-10.4c-4.3-8.1-8.8-16.5-12.1-25.3
				c-5.2-13.5-6.6-27.1-7.9-40.2c-0.4-3.7-0.9-7.4-1.5-11.1c0.7,0.8,1.4,1.5,2.1,2.1c0.7,0.6,1.6,1.2,2.4,1.9c2,1.5,4.2,3,5,5.1
				c0.1,0.4,0.5,0.6,0.8,0.6c0.3,0,0.6-0.1,0.7-0.4c0.2-0.2,0.2-0.5,0.1-0.7c-0.8-2.4-3-4.1-5-5.5l-0.7-0.5
				c-2.5-1.9-4.4-4.1-6.2-6.5c-0.4-2.5-0.9-4.9-1.3-7.3c-1.2-6.7-2.5-13.6-3.3-20.5l0-0.2c-2.8-17.6-2.3-36.1-1.4-55.1
				c0.5-10.3,1.1-24.3-9.3-31.9c-1-0.7-2.3-0.2-2.7,0.9c-0.3,0.8,0,1.6,0.7,2.1c9,6.7,8.3,19.3,7.6,30.3c-0.1,1.6-0.2,3.1-0.2,4.5
				c0,1.3-0.1,2.6-0.1,3.9c-0.2,0.5-0.5,0.9-0.7,1.4c-2.6,5.3-5.2,10.9-9.5,15.2c-6.6,6.6-15.6,8.1-22,8.1c-0.5,0-0.8,0.4-0.8,0.8
				c0,0.4,0.3,0.9,0.9,0.9h0c8.9-0.1,16.2-2.7,22.4-7.9c4.4-3.7,7.1-8.8,9.6-14c-0.2,7.7-0.2,15.8,0.2,23.7
				c-1.4,8.6-4.4,16.8-7.3,24.7c-3.3,8.9-6.7,18-7.8,27.8c-0.9,8.2-0.7,16.7-0.5,24.8l0.1,2.3c0.1,3,0,6,0,9
				c-0.1,4.9-0.1,10.1,0.3,15c0,0.4,0.1,0.9,0.3,1.5c-1.9,4.4-5.9,7.5-9.7,10.4c-1.5,1.2-3.1,2.4-4.5,3.6
				c-4.8,4.3-10.6,10.3-12.1,18.5c-0.1,0.3,0,0.5,0.2,0.7c0.2,0.2,0.4,0.3,0.7,0.3c0.4,0,0.7-0.3,0.8-0.7
				c1.4-6.9,6.1-13.3,14.4-19.6l1-0.7c3.7-2.8,7.7-5.8,10.1-9.9c0.2,0.4,0.4,0.9,0.7,1.5c0.3,0.7,0.7,1.4,0.9,1.8
				c7.5,17.9,9,37.9,10.5,57.2c0.2,3.3,0.5,6.7,0.8,9.9c0.4,4.4,0.8,8.8,1.3,13.1c0.8,7.8,1.7,15.8,1.9,23.8
				c0.4,11.6,0,24.1-1.2,40.4c-0.9,12.3-2.4,25.1-4.3,39.4c-1.6,11.7-5.6,22.9-9.5,33.8l-1.1,3c-3.6,10-7.3,20.4-9.6,31
				c-0.4,2-0.7,3.9-1,5.8c-1.2,2.6-1.8,5.6-2.3,8.6c-0.3,1.5-0.5,3-0.9,4.3c-2.3,9.2-6.6,14.8-12.6,16.9c-0.9,0.3-1.8,0.5-2.7,0.5h0
				c-5.7,0-9.1-6.8-11-12.5c-0.7-2.1-1.2-4.3-1.7-6.5c-0.3-1.2-0.6-2.5-0.9-3.8c-1-3.7-2.2-7.5-3.7-11.4c-0.1-1.6-0.3-3.1-0.3-3.5
				c-0.4-2.6-1.1-5.4-2.2-9c-4-12.9-6.5-25.1-7.7-37.1c-1.3-14-1.1-28.2-0.8-40.4c0.1-2.8,0.2-5.6,0.3-8.4c0.3-10.1,0.7-20.6,0.1-31
				l0-0.6c-0.3-5.8-0.6-11.4-5.7-13.6c4.2-1.9,7.3-4.7,9.3-8.6c3.1-6,3.1-12.8,3.2-19.3c0-3.2,0-6.5,0.4-9.6c0-0.2,0-0.5-0.2-0.7
				c-0.2-0.2-0.4-0.3-0.7-0.3c-0.5,0-0.8,0.3-0.9,0.8c-0.4,3.2-0.4,6.4-0.5,9.5c-0.1,5.2-0.2,10.5-1.8,15.6
				c-1.8,5.8-5.7,9.6-11.8,11.7c-0.6-0.1-1.2-0.1-1.8-0.2c-0.2,0-0.4,0-0.5,0.1c-0.2-0.1-0.4-0.1-0.6-0.1c0,0,0,0,0,0
				c-0.6,0-1.2,0.1-1.8,0.2c-6.1-2.1-10-5.9-11.8-11.7c-1.6-5.1-1.7-10.5-1.8-15.6c-0.1-3.1-0.1-6.3-0.5-9.5
				c-0.1-0.4-0.4-0.8-0.9-0.8c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.2,0.4-0.2,0.7c0.4,3.1,0.4,6.4,0.4,9.6c0,6.5,0.1,13.2,3.2,19.3
				c2,3.8,5,6.7,9.2,8.6c-5,2.2-5.3,7.8-5.6,13.6l0,0.6c-0.5,10.3-0.2,20.8,0.1,31c0.1,2.8,0.2,5.6,0.3,8.4
				c0.3,12.2,0.6,26.4-0.8,40.4c-1.1,12-3.6,24.2-7.7,37.1c-1.1,3.6-1.8,6.4-2.2,9c-0.1,0.5-0.2,2-0.3,3.5
				c-1.5,3.9-2.8,7.7-3.7,11.4c-0.3,1.3-0.6,2.5-0.9,3.8c-0.5,2.2-1,4.4-1.7,6.5c-1.9,5.7-5.3,12.5-11,12.5c-0.9,0-1.8-0.2-2.7-0.5
				c-6-2-10.2-7.7-12.6-16.9c-0.3-1.3-0.6-2.8-0.9-4.3c-0.5-3-1.1-6-2.3-8.6c-0.2-1.9-0.5-3.8-1-5.8c-2.3-10.6-6-21-9.6-31l-1.1-3
				c-3.9-10.9-7.9-22.2-9.5-33.8c-2-14.3-3.4-27.1-4.3-39.4c-1.2-16.3-1.6-28.7-1.2-40.4c0.3-8,1.1-16,1.9-23.8
				c0.5-4.3,0.9-8.7,1.3-13.1c0.3-3.3,0.5-6.6,0.8-9.9c1.4-19.3,2.9-39.3,10.5-57.2c0.2-0.4,0.5-1.1,0.9-1.8
				c0.3-0.6,0.5-1.1,0.7-1.5c2.4,4.1,6.5,7.1,10.1,9.9l1,0.7c8.3,6.3,13,12.7,14.4,19.6c0.1,0.4,0.4,0.7,0.8,0.7
				c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.2-0.4,0.2-0.7c-1.5-8.1-7.3-14.1-12.1-18.5c-1.4-1.3-3-2.5-4.5-3.6c-3.9-2.9-7.8-6-9.7-10.4
				c0.1-0.6,0.2-1.1,0.3-1.5c0.4-5,0.4-10.1,0.3-15c0-3-0.1-6,0-9l0.1-2.3c0.2-8.2,0.4-16.6-0.5-24.8c-1.1-9.7-4.5-18.9-7.8-27.8
				c-2.9-8-6-16.2-7.3-24.7c0.4-8,0.4-16,0.2-23.7c2.5,5.2,5.2,10.3,9.6,14c6.2,5.2,13.5,7.8,22.4,7.9h0c0.6,0,0.9-0.5,0.9-0.9
				c0-0.5-0.3-0.8-0.8-0.8c-6.4-0.1-15.4-1.5-22-8.1c-4.3-4.3-7-9.8-9.5-15.2c-0.2-0.5-0.5-0.9-0.7-1.4c0-1.3-0.1-2.6-0.1-3.9
				c-0.1-1.5-0.1-3-0.2-4.5c-0.6-11.1-1.4-23.6,7.6-30.3c0.7-0.5,0.9-1.3,0.7-2.1c-0.4-1.1-1.7-1.7-2.7-0.9
				c-10.4,7.6-9.8,21.6-9.3,31.9c0.8,19,1.4,37.4-1.4,55.1l0,0.1c-0.8,6.9-2,13.8-3.3,20.5c-0.4,2.4-0.9,4.9-1.3,7.3
				c-1.8,2.4-3.7,4.6-6.2,6.5l-0.7,0.5c-1.9,1.5-4.1,3.1-5,5.5c-0.1,0.3-0.1,0.5,0.1,0.7c0.2,0.2,0.4,0.4,0.7,0.4
				c0.4,0,0.7-0.2,0.8-0.6c0.8-2.2,2.9-3.7,5-5.1c0.9-0.6,1.7-1.2,2.4-1.9c0.7-0.6,1.4-1.3,2.1-2.1c-0.6,3.7-1.1,7.4-1.5,11.1
				c-1.3,13.1-2.6,26.7-7.9,40.2c-3.4,8.7-7.8,17.1-12.1,25.3c-1.8,3.4-3.6,6.9-5.4,10.4l-1,2c-2.4,4.8-5,9.8-7,14.9
				c-0.7,1.7-1.3,3.5-1.9,5.2c-1.2,3.4-2.4,6.9-4.1,10c-0.2,0.4-0.3,0.8-0.2,1.2c-1.4,8.3-1.5,16.8-1.6,25
				c-0.1,7.1-0.2,14.5-1.1,21.6l-0.1,0.4c-3,22.5-7.2,24.9-8.8,24.9c-0.5,0-1-0.2-1.5-0.5c-0.1-0.4-0.4-0.6-0.7-0.8
				c0.2-1.5,0.4-2.9,0.6-4.4c0.3-2.6,0.6-5.2,1.2-7.7l0.2-0.8c0.9-3.9,1.8-8,1.7-12.2c0.9-0.1,1.7-0.1,2.5-0.2
				c0.5-0.1,0.8-0.4,0.7-0.9c0-0.4-0.3-0.9-0.9-0.9l-0.1,0c-1.6,0.2-3.3,0.3-5.2,0.3c-2,0-4-0.1-5.9-0.2c-1,0-2-0.1-3-0.1
				c-0.8,0-1.5,0-2.3-0.1c-3.3-0.1-6.7-0.2-10-0.9c-0.5-0.1-1,0.3-1.1,0.8c-0.1,0.5,0.2,0.9,0.7,1c0.5,0.1,1.1,0.2,1.7,0.3
				c-0.1,4.8-0.8,9.7-1.5,14.4c-0.3,2.1-0.6,4.3-0.9,6.5c-0.4,3.3-0.8,6.9-0.8,10.4c-1.8,0-3.2-0.7-4.3-2c-5-5.9-2.1-23.9-0.1-35.8
				c0.9-5.5,1.6-9.8,1.5-12.1c0-0.4-0.2-0.7-0.4-0.9c-0.1-0.1-0.1-0.2-0.2-0.3c-0.5-0.5-1.1-0.6-1.8-0.4c-2.5,0.9-4.6,2.1-6.7,3.3
				c-3.2,1.8-6.2,3.5-9.7,3.5c-0.8,0-1.6-0.1-2.4-0.3c6.2-5.2,11.2-11.1,15.7-16.7l0.9-1.2c5.7-7.1,12.2-15.2,21.7-15.2
				c0.2,0,0.3,0,0.6,0c0.2,0,0.4,0,0.6-0.1c1.3,0.1,2.5,0.3,3.9,0.5c0.5,0.1,1-0.3,1-0.8c0-0.4-0.2-0.8-0.7-0.9
				c-0.7-0.1-1.4-0.2-2.1-0.3c0-0.1,0.1-0.2,0.1-0.2c2.9-16.7,3.6-33.9,4.4-50.6c0.6-14.6,1.3-29.7,3.4-44.3c0.5-3.6,1.1-7.4,2-11
				c0.8-3,2.3-5.3,3.9-7.9c0.7-1.1,1.5-2.3,2.2-3.5c0.3-0.5,0.5-1.1,0.7-1.6c0.8,1.5,1.8,3,2.8,4.5c0.5,0.8,1.1,1.6,1.6,2.4
				c0.2,0.3,0.4,0.4,0.8,0.4c0.3,0,0.7-0.2,0.8-0.4c0.1-0.2,0.1-0.5,0-0.8c-0.5-0.9-1.1-1.8-1.7-2.6c-1.3-2-2.6-4-3.5-6.1
				c0.2-1.7,0.2-3.4,0.2-5c0-1.1,0-2.2,0.1-3.3c0.4-7,2.3-13.8,4.1-20.4c2.2-8.2,4.5-16.6,4.2-25.4c0,0,0,0,0,0
				c0.1,0.2,0.2,0.4,0.3,0.6c1.9,3.5,4,7.1,3.9,11.2c0,0.2,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3c0.4,0,0.8-0.3,0.8-0.8
				c0.2-4.5-1.9-8.3-4-12.1c-0.7-1.2-1.3-2.5-1.9-3.7c-0.1-3.8-0.3-7.7-0.5-11.4c-0.7-12.6-1.4-25.7,2.3-37.6
				c5.6-17.7,19.8-32.2,36.3-37c4.2-1.2,8.6-1.5,12.9-1.8c0.8-0.1,1.6-0.1,2.4-0.2c3,0.5,5.9,1.5,8.8,2.5c1.4,0.5,2.8,1,4.2,1.4
				c1.2,0.4,2.5,0.8,3.8,1.3c3.3,1.2,6.7,2.4,10.1,2.4c0.7,0,1.3,0,1.9-0.1c1.8-0.3,3.6-0.6,5.3-1c3.1-0.7,6.3-1.4,9.6-1.4
				c0.9,0,1.7,0,2.5,0.1c2.1,0.2,4.1,0.9,6.1,1.5c2.6,0.8,5.2,1.6,8,1.6h0c0.6,0,0.9-0.5,0.9-0.9c0-0.4-0.3-0.8-0.9-0.8
				c-2.8,0-5.4-0.8-8-1.6c-2.6-0.8-5.4-1.7-8.3-1.7l-0.2,0c-3.6,0-6.8,0.4-9.6,1.1c-0.4,0.1-0.7,0.2-1.1,0.3c-2.2,0.6-4.4,1.1-6.6,1
				c-3.6-0.2-7.5-1.6-11.1-2.9c-1.8-0.6-3.5-1.3-5.2-1.8c-1.2-0.3-2.4-0.7-3.5-1.1c-0.5-0.2-1.1-0.4-1.6-0.5
				c1.9-0.3,3.7-0.6,5.5-1.1c5.7-1.7,16.5-5.2,24.2-10.7c9.8-7,13-17.2,14-26.9c0.4,1.7,0.7,3.5,1,5.2c1.7,8.9,3.4,18.1,8.4,25.7
				c3.5,5.2,6,10.8,8.6,16.4c1.3,2.8,2.9,5.9,5.9,6.6c0.1,0,0.5,0.1,0.6,0.1c0.1,0,0.2,0,0.3,0l0.1,0c0.2,0,0.7-0.1,0.9-0.1
				c3-0.7,4.6-3.7,5.8-6.6c2.5-5.7,5.1-11.2,8.6-16.4c5-7.6,6.8-16.8,8.4-25.7c0.3-1.7,0.7-3.5,1-5.2c1,9.6,4.2,19.9,14,26.9
				c7.7,5.5,18.5,9.1,24.2,10.7c1.8,0.5,3.7,0.9,5.5,1.1c-0.5,0.2-1.1,0.4-1.6,0.5c-1.2,0.4-2.3,0.8-3.5,1.1
				c-1.7,0.5-3.4,1.1-5.2,1.8c-3.7,1.3-7.5,2.7-11.1,2.9c-2.2,0.1-4.4-0.5-6.6-1c-0.4-0.1-0.7-0.2-1.1-0.3c-2.8-0.7-6-1.1-9.6-1.1
				l-0.2,0c-2.9,0-5.6,0.8-8.3,1.7c-2.6,0.8-5.2,1.6-8,1.6c-0.5,0-0.8,0.4-0.8,0.8c0,0.4,0.3,0.9,0.9,0.9h0c2.8,0,5.5-0.8,8-1.6
				c2-0.6,4-1.2,6.1-1.5c0.8-0.1,1.7-0.1,2.5-0.1c3.2,0,6.5,0.7,9.6,1.4c1.7,0.4,3.5,0.8,5.3,1c0.6,0.1,1.3,0.1,1.9,0.1
				c3.4,0,6.8-1.2,10.1-2.4c1.3-0.5,2.6-0.9,3.8-1.3c1.4-0.4,2.8-0.9,4.2-1.4c2.9-1,5.8-2,8.8-2.5c0.8,0.1,1.6,0.1,2.4,0.2
				c4.3,0.3,8.7,0.6,12.9,1.8c16.5,4.7,30.7,19.2,36.3,37c3.8,11.9,3,25,2.3,37.6c-0.2,3.8-0.4,7.6-0.5,11.4
				c-0.6,1.3-1.3,2.5-1.9,3.7c-2.1,3.7-4.2,7.6-4,12.1c0,0.5,0.3,0.8,0.8,0.8c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7
				c-0.1-4.1,1.9-7.7,3.9-11.2c0.1-0.2,0.2-0.4,0.3-0.6c0,0,0,0,0,0c-0.3,8.8,2,17.2,4.2,25.4c1.8,6.6,3.6,13.5,4.1,20.4
				c0.1,1.1,0.1,2.2,0.1,3.3c0,1.7,0,3.4,0.2,5c-0.9,2.2-2.2,4.2-3.5,6.1c-0.6,0.9-1.2,1.8-1.7,2.6c-0.2,0.3-0.2,0.6,0,0.8
				c0.2,0.3,0.5,0.4,0.8,0.4c0.3,0,0.6-0.2,0.8-0.4c0.5-0.8,1-1.6,1.6-2.4c1-1.4,1.9-2.9,2.8-4.5c0.2,0.5,0.4,1.1,0.7,1.6
				c0.7,1.2,1.4,2.4,2.2,3.5c1.6,2.5,3.1,4.9,3.9,7.9c1,3.7,1.5,7.6,2,11c2.1,14.7,2.8,29.8,3.4,44.3c0.7,16.6,1.5,33.8,4.4,50.6
				c0,0.1,0.1,0.2,0.1,0.2c-0.7,0.1-1.4,0.2-2.1,0.3c-0.4,0.1-0.7,0.4-0.7,0.9c0,0.5,0.5,0.9,1,0.8c1.4-0.2,2.7-0.4,3.9-0.5
				c0.2,0.1,0.4,0.1,0.6,0.1c0,0,0,0,0,0c9.8-0.3,16.4,7.9,22.2,15.1l0.9,1.2c4.5,5.6,9.6,11.5,15.7,16.7c-0.8,0.2-1.6,0.3-2.4,0.3
				c-3.5,0-6.5-1.7-9.7-3.5C402.2,509.8,400,508.5,397.6,507.7z M371,549.2c-0.3-2.2-0.5-4.5-0.9-6.7c-0.2-1.3-0.5-2.6-0.8-3.9
				c-0.7-3.4-1.4-6.5-1.3-9.9c2.6,0.1,5.2-0.1,7.8-0.2l1.2-0.1l0.1,1.1c0.3,6.4,0.6,13,1.3,19.5c0.5,4.6,1,9.7,2.5,14.1
				c-0.8,0.2-1.7,0.3-2.5,0.3c-1.4,0-2.4-0.4-3.2-1.4c-1.3-1.6-1.8-3.9-2.3-6c-0.1-0.4-0.2-0.8-0.3-1.2c-0.2-0.6-0.6-1.1-1.1-1.3
				C371.4,552.2,371.2,550.7,371,549.2z M382.8,563c-1.7-4.7-2.2-10.2-2.7-15.1l-0.1-1.3c-0.3-3.3-0.5-6.6-0.7-9.9
				c-0.1-2.7-0.3-5.5-0.5-8.3c0.6,0,1.2,0,1.8,0c2.2,0,4.5-0.1,6.8-0.3c0.1,5.2,0.8,10.4,1.6,15.4l0.3,2c0.5,3.4,0.8,6.3,1,9
				l0.1,0.9c0.1,1.3,0.2,2.7,0.2,4.1c-0.2,0.2-0.3,0.3-0.4,0.6c-0.2,0.6-0.1,1.3,0.3,1.9c0,0.3-0.1,0.5-0.1,0.8
				c-0.5,1.6-2.3,2.8-3.8,2.8c-0.7,0-1.6-0.2-2.3-1.1C384.1,563.8,383.5,563.2,382.8,563z M272,312L272,312L272,312L272,312z
				 M264.3,877.3c0.8,4.2,2.1,8.5,3.3,12.6c0.8,2.7,1.6,5.4,2.3,8.1c0.5,2.2,1.3,4.2,2.2,6.1c-3.1-2.4-6.4-4.2-10.1-5.5
				c-0.5-0.2-1,0.1-1.1,0.6c-0.1,0.5,0.2,0.9,0.6,1c5.1,1.9,9.4,4.7,13.1,8.5c1.7,2.8,3.5,5.5,5.5,8.3l0.1,0.2
				c1.7,2.5,3.8,4.7,5.9,6.8c1.7,1.8,3.5,3.6,5,5.7c1.2,1.6,1.5,2.8,1.1,3.7c0,0.1-0.1,0.1-0.1,0.2c-0.4-2.2-1.6-4.3-4-4.8
				c-1.6-0.3-3.7-0.3-4.7,1.2c-0.1,0.2-0.3,0.4-0.4,0.6c-0.5-1.7-1.6-3.1-4-3.3c-2.3-0.2-3.5,1.7-4.2,3.9c0-0.1-0.1-0.3-0.1-0.4
				c-0.7-2.2-2.1-3.4-4.4-3.7c-2.4-0.3-3.7,1.1-4.3,3c-0.4-1.4-1.2-2.6-2.7-3.3c-2.2-1.1-5.1-0.7-6.8,1.1c-1.1-2.8-3.7-4.7-7.1-4.6
				c-4.4,0.1-8.1,3.1-8.8,7.6c-0.3,2,0.2,3.5,1.2,4.8c-1.7-0.6-3-1.5-3.9-2.7c-3.6-4.5-1.9-12.7-0.5-19.3l0.1-0.4
				c7.8-38.6,4.6-78,0.2-118.4c-1.2-11.5-1.7-21.1-1.4-30.2c0.3-10.5,2.9-20.2,5.6-30.4l0.2-0.9c1.2-4.6,2.1-9.2,2.7-13.6
				c0.5,0.8,1.1,1.5,1.8,2.2c0.2,0.2,0.4,0.3,0.6,0.3c0.4,0,0.8-0.3,0.9-0.6c0.1-0.3,0-0.7-0.2-1c-1.3-1.1-2.2-2.6-2.7-4.2
				c0.2-2,0.4-4,0.4-6c0.2-4.6-0.4-9.2-0.9-13.6c-0.5-4.1-1-8.3-0.9-12.4c0.8,2.4,1.5,4.8,2.1,7.2c0.3,1.1,0.6,2.2,0.8,3.4
				c0.6,2.7,1.3,5.4,2.3,8.1c2.6,7.1,6.4,11,11.3,11.6c0.4,0,0.8,0.1,1.2,0.1c6.5,0,11.7-6.4,14.4-12.4c1.5-3.4,2.4-7,3.1-10.1
				c0,2.3,0,4.7,0,7.1l0,2.4c0,0.9,0.5,4.4,0.6,4.8c1.2,5.6,3.1,11,5,16.3c0.8,2.3,1.7,4.7,2.5,7.1c3,9.2,3.3,19.4,0.9,32.3
				c-1.1,6.1-2.8,12.3-4.5,18.2c-0.9,3.3-1.8,6.7-2.7,10c-1.6,6.5-3,13.1-4.4,19.6c-0.7,3.3-1.4,6.5-2.1,9.8
				c-1.3,5.8-2.8,11.7-4.3,17.4c-1,3.7-1.9,7.5-2.9,11.3C264.9,858,262.6,868.6,264.3,877.3z M272,935.8c-1.2-0.1-2.4-0.1-3.8-0.1
				c-0.6,0-1.2,0-1.8,0c0-0.8,0-1.6,0-2.4c0.2-2.4,1-4.9,3.7-4.8c3.7,0.1,4,4.3,3.9,7.5c-0.5,0-0.9-0.1-1.3-0.1L272,935.8z
				 M258.2,936.3c-0.7,0.1-1.4,0.1-2.1,0.2c0.6-1.5,0.8-3.3,0.9-5c0-0.7-0.1-1.4-0.2-2c0,0,0.1,0,0.1-0.1c1.5-2.1,4.3-2.2,6.4-0.9
				c1.8,1.1,2,3.5,2,5.7c0,0.6,0,1.1-0.1,1.6C262.9,935.9,260.5,936.1,258.2,936.3z M246.4,936.8c-0.8,0-1.6-0.1-2.3-0.2
				c-1.6-1.3-2.6-3.1-2.2-5.8c0.3-2.2,1.8-3.9,3.6-5.1c3.2-2,8.2-0.9,9.8,2.6c0.9,2,0.9,5.9-0.5,8.2
				C252,936.8,249.2,936.9,246.4,936.8z M275.3,934.3c0.3-2.5,1.2-5.9,3.9-5.6c3.6,0.3,3.2,4.6,3,7.6c-0.4,0-0.8,0-1.2,0
				c-2.1,0-4.1-0.1-5.7-0.2C275.3,935.5,275.3,934.9,275.3,934.3z M290.9,934.7c-1.2,0.7-3.4,1.3-7.6,1.5c0-0.3,0-0.7,0-1.1
				c0,0,0-0.1,0-0.1c0.1-2.5,0.3-5.3,3.5-5.1C289.7,930.2,290.7,932.4,290.9,934.7z M206.4,913.6l0.1,0.4c1.3,6.6,3,14.8-0.5,19.3
				c-1,1.2-2.3,2.1-3.9,2.7c1-1.3,1.5-2.9,1.2-4.8c-0.7-4.4-4.3-7.5-8.8-7.6c-3.4-0.1-6,1.8-7.1,4.6c-1.7-1.8-4.5-2.3-6.8-1.1
				c-1.5,0.8-2.3,1.9-2.7,3.3c-0.6-1.9-1.9-3.2-4.3-3c-2.3,0.2-3.7,1.4-4.4,3.7c0,0.1-0.1,0.3-0.1,0.4c-0.7-2.2-1.9-4.1-4.2-3.9
				c-2.4,0.2-3.5,1.5-4,3.3c-0.1-0.2-0.2-0.4-0.4-0.6c-1.1-1.5-3.1-1.5-4.7-1.2c-2.4,0.5-3.6,2.5-4,4.8c-0.1-0.1-0.1-0.1-0.1-0.2
				c-0.5-0.9-0.1-2.2,1.1-3.7c1.5-2,3.3-3.9,5-5.7c2-2.1,4.1-4.3,5.9-6.8l0.1-0.2c1.9-2.8,3.8-5.5,5.5-8.3c3.7-3.9,7.9-6.6,13.1-8.5
				c0.4-0.2,0.7-0.6,0.6-1c-0.1-0.5-0.6-0.8-1.1-0.6c-3.7,1.2-7.1,3-10.1,5.5c0.9-1.9,1.6-4,2.2-6.1c0.7-2.7,1.5-5.5,2.3-8.1
				c1.2-4.1,2.5-8.4,3.3-12.6c1.7-8.8-0.6-19.4-2.8-28.6c-0.9-3.8-1.9-7.6-2.9-11.3c-1.5-5.7-3-11.6-4.3-17.4
				c-0.7-3.3-1.4-6.5-2.1-9.8c-1.4-6.4-2.7-13.1-4.4-19.6c-0.8-3.4-1.8-6.7-2.7-10c-1.6-5.9-3.3-12.1-4.5-18.2
				c-2.4-12.9-2.1-23.2,0.9-32.3c0.8-2.4,1.6-4.8,2.5-7.1c1.9-5.3,3.9-10.7,5-16.3c0.1-0.5,0.6-3.9,0.6-4.8l0-2.4c0-2.4,0-4.8,0-7.1
				c0.7,3.1,1.5,6.7,3,10.1c2.7,6,7.8,12.4,14.4,12.4c0.4,0,0.8,0,1.2-0.1c4.9-0.6,8.7-4.5,11.3-11.6c1-2.7,1.6-5.4,2.3-8.1
				c0.3-1.1,0.5-2.2,0.8-3.4c0.6-2.4,1.3-4.8,2.1-7.2c0.1,4.2-0.4,8.4-0.9,12.4c-0.6,4.4-1.1,9-0.9,13.6c0.1,2,0.2,4,0.4,6
				c-0.5,1.6-1.4,3.1-2.7,4.2c-0.3,0.3-0.4,0.6-0.2,1c0.1,0.4,0.5,0.6,0.9,0.6c0.2,0,0.5-0.1,0.6-0.3c0.7-0.7,1.3-1.4,1.8-2.2
				c0.6,4.5,1.5,9,2.7,13.6l0.2,0.8c2.7,10.2,5.2,19.9,5.6,30.4c0.3,9.1-0.1,18.6-1.4,30.2C201.8,835.5,198.6,875,206.4,913.6z
				 M189,936.6c-1.5-2.3-1.4-6.2-0.5-8.2c1.6-3.6,6.5-4.7,9.8-2.6c1.8,1.1,3.3,2.9,3.6,5.1c0.4,2.7-0.5,4.4-2.2,5.8
				c-0.7,0.1-1.5,0.2-2.3,0.2C194.6,936.9,191.8,936.8,189,936.6z M178.5,935.8c0-0.5,0-1.1-0.1-1.6c0-2.3,0.2-4.6,2-5.7
				c2.1-1.3,4.9-1.1,6.4,0.9c0,0,0.1,0.1,0.1,0.1c-0.1,0.6-0.2,1.3-0.2,2c0,1.7,0.2,3.5,0.9,5c-0.7-0.1-1.4-0.1-2.1-0.2
				C183.3,936.1,180.9,935.9,178.5,935.8z M171.1,935.9c-0.4,0-0.8,0.1-1.3,0.1c-0.1-3.1,0.2-7.3,3.9-7.5c2.7-0.1,3.5,2.4,3.7,4.8
				c0,0.8,0,1.6,0,2.4c-0.6,0-1.2,0-1.8,0c-1.3,0-2.6,0-3.8,0.1L171.1,935.9z M164.7,928.7c2.6-0.2,3.6,3.2,3.9,5.6
				c0,0.6-0.1,1.2,0,1.8c-1.7,0.1-3.7,0.2-5.7,0.2c-0.4,0-0.8,0-1.2,0C161.4,933.3,161.1,929,164.7,928.7z M160.5,936.2
				c-4.2-0.2-6.4-0.8-7.6-1.5c0.2-2.3,1.2-4.5,4-4.7c3.3-0.2,3.5,2.5,3.5,5.1c0,0,0,0.1,0,0.1C160.5,935.5,160.5,935.9,160.5,936.2z
				 M53.3,562c0.4-0.5,0.5-1.2,0.3-1.9c-0.1-0.2-0.2-0.4-0.4-0.6c0-1.4,0.1-2.8,0.2-4.1l0.1-0.9c0.2-2.7,0.5-5.6,1-9l0.3-2
				c0.7-5,1.5-10.2,1.6-15.4c2.3,0.2,4.6,0.3,6.8,0.3c0.6,0,1.2,0,1.8,0c-0.2,2.8-0.4,5.6-0.5,8.3c-0.2,3.2-0.4,6.6-0.7,9.9
				l-0.1,1.3c-0.5,4.9-1,10.4-2.7,15.1c-0.7,0.2-1.3,0.8-1.5,1.5c-0.7,0.9-1.7,1.1-2.3,1.1c-1.6,0-3.3-1.1-3.8-2.8
				C53.3,562.6,53.3,562.3,53.3,562z M65.4,549.1c0.7-6.5,1-13.1,1.3-19.5l0.1-1.1l1.2,0.1c2.5,0.1,5.2,0.2,7.8,0.2
				c0.1,3.4-0.6,6.6-1.3,9.9c-0.3,1.3-0.5,2.6-0.8,3.9c-0.4,2.2-0.7,4.5-0.9,6.7c-0.2,1.5-0.4,3-0.6,4.5c-0.6,0.2-1,0.7-1.1,1.3
				c-0.1,0.4-0.2,0.8-0.3,1.2c-0.5,2.1-1,4.4-2.3,6c-0.7,0.9-1.8,1.4-3.2,1.4c-0.8,0-1.7-0.2-2.5-0.3
				C64.4,558.8,64.9,553.7,65.4,549.1z M94.3,303.3L94.3,303.3C94.3,303.3,94.3,303.3,94.3,303.3L94.3,303.3z M208.6,219.8
				L208.6,219.8L208.6,219.8L208.6,219.8z M258.5,109.4c0,0.1,0,0.2,0,0.2c-0.3,0.3-0.5,0.5-0.6,0.6c-0.8,0.4-0.9,0.2-0.9,0.1
				c0,0-0.1-0.2-0.1-0.7l0-0.7c0-0.4,0-0.9,0-1.3c0-1.3,0-2.6-0.1-4c-0.2-2.7-0.7-5.3-1.5-7.9c-1.2-4-2.8-8.2-5.7-11
				c-2.4-2.4-5.2-3.6-8.6-3.6c-2.9,0-5.9,0.8-8.8,1.6c-2.3,0.6-4.7,1.3-7.1,1.6l-0.4,0c-0.7,0.1-1.9,0.2-3,0.2
				c-1.2,0-2.4-0.2-3.1-0.3c-0.2,0-0.3,0-0.4-0.1c-2.2-0.3-4.5-0.9-6.7-1.5c-2.9-0.8-5.9-1.6-8.8-1.6c-3.4,0-6.2,1.2-8.6,3.6
				c-2.9,2.9-4.5,7-5.7,11c-0.8,2.6-1.3,5.2-1.5,7.9c-0.1,1.4-0.1,2.7-0.1,4c0,0.4,0,0.9,0,1.3l0,0.8c0,0.4-0.1,0.6-0.1,0.6
				c0,0-0.1,0.2-0.8-0.1c-0.1,0-0.2-0.1-0.3-0.2c0-0.2,0-0.4-0.1-0.7c-1.8-5.7-2.4-12.4-1.8-21.1c0.4-5.9,0.9-13.3,3.9-18.6
				c3.8-6.8,11.9-10.6,24.2-11.4c2.5-0.2,8.6-0.2,11.2-0.2c2.8,0,5.5,0,6.6,0.1c9.5,0.3,21.9,2.5,27.1,11.6c3,5.4,3.5,12.7,3.9,18.6
				C260.9,97.1,260.3,103.8,258.5,109.4z M184.1,111.9c0.6,0.7,1.3,1.1,2,1.1c0.5,0,1.7-0.2,2.2-2.3c0.3-1.6,0.3-3.2,0.3-4.9
				c0-1,0-2.1,0-3.1c0.3-3.4,1.3-6.6,2.5-9.6c1.6-4.4,4.8-9.7,10.9-10.3c2.7-0.2,5.4,0.7,8.1,1.5c0.8,0.3,1.7,0.6,2.6,0.8
				c1.9,0.6,3.7,0.9,5.3,1.1c0.1,0,0.3,0,0.5,0.1c0.8,0.1,1.9,0.3,3.2,0.3c1.2,0,2.4-0.1,3.2-0.2c0.2,0,0.4,0,0.5-0.1
				c1.7-0.2,3.5-0.5,5.6-1.1c0.8-0.2,1.7-0.5,2.6-0.8c2.6-0.9,5.4-1.8,8.1-1.5c6.1,0.5,9.3,5.9,10.9,10.3c1.1,3,2.2,6.2,2.5,9.6
				c0.1,1,0.1,2.1,0,3.1c0,1.6-0.1,3.3,0.3,4.9c0.5,2.1,1.7,2.3,2.2,2.3c0.7,0,1.5-0.4,2.1-1.2c0.3,0.2,0.7,0.2,1.1,0.2
				c2.3-0.4,3.8-0.1,4.7,1c1.6,1.9,0.9,6,0.3,9.1l-0.2,0.9c-0.9,5.6-1.7,8.8-7.3,10.2c-0.7,0.2-1.2,0.7-1.3,1.3c0,0.2-0.1,0.7-0.2,2
				c-0.1,1.4-0.3,3.4-0.5,4.4c-1.3,7.7-2.4,14.3-8.4,20.3c-5.2,5.2-11.6,8.7-20.4,11c-1.1,0.3-2.6,0.7-5,0.7c-1.7,0-3.5-0.2-5.4-0.6
				c-8.1-1.7-15.1-5.5-20.7-11.1c-6-6.1-7.1-12.7-8.4-20.3c-0.2-1-0.3-2.9-0.5-4.4c-0.1-1.3-0.2-1.8-0.2-2c-0.2-0.7-0.6-1.1-1.3-1.3
				c-5.6-1.4-6.4-4.7-7.3-10.2l-0.2-0.9c-0.5-3-1.2-7.2,0.3-9.1c0.6-0.8,1.6-1.1,3-1.1c0.5,0,1.1,0.1,1.7,0.2
				C183.6,112,183.9,112,184.1,111.9z M222.3,176.6L222.3,176.6c3.3,0,5.7-0.6,6.4-0.8c6.3-1.7,12.1-4.5,17.3-8.3
				c-0.9,3.4-1.5,6.9-2.2,10.2c-1.2,6-2.4,12.2-4.6,18c-1.4,3.8-3.6,7.5-5.8,11.1c-1.5,2.6-3.1,5.2-4.4,7.9c-0.3,0.7-0.7,1.6-1,2.5
				c-1.3,3.3-2.8,7.4-6,7.6c-3.3-0.2-4.8-4.3-6.1-7.6c-0.4-0.9-0.7-1.8-1-2.5c-1.3-2.7-2.9-5.3-4.4-7.9c-2.1-3.6-4.3-7.2-5.8-11.1
				c-2.2-5.8-3.4-12-4.6-18c-0.7-3.4-1.3-6.8-2.2-10.2c5.3,4,10.9,6.8,16.8,8.2C217.3,176.3,219.9,176.6,222.3,176.6z M235.2,219.8
				L235.2,219.8L235.2,219.8L235.2,219.8z"/>
      <path d="M213.3,117.4c0.1-0.5-0.2-0.9-0.6-1c-2.9-0.8-5.2-1.2-7.5-1.2c-2.6,0-4.9,0.5-7.2,1.5c-0.4,0.2-0.5,0.5-0.5,0.9
				c0.1,0.6,0.8,0.9,1.3,0.7c1-0.5,2-0.8,3.2-1c0,0.2,0.1,0.4,0.2,0.6c0.1,1.2,1.1,2.2,2.4,2.3c0.1,0,0.2,0,0.3,0
				c1.7,0,3.1-1.3,3.3-3c1.2,0.2,2.5,0.4,4,0.8C212.7,118.2,213.2,117.9,213.3,117.4z"/>
      <path d="M197.3,108.8L197.3,108.8c1.9-0.2,3.4-0.3,4.9-0.3c4.8,0,8.5,1,11.5,3c0.2,0.1,0.3,0.2,0.5,0.2c0.4,0,0.7-0.3,0.8-0.6
				c0.1-0.4,0-0.8-0.3-1c-4.1-3-10-4-17.5-3c-0.5,0.1-0.8,0.5-0.8,0.9C196.5,108.4,196.8,108.8,197.3,108.8z"/>
      <path d="M213.8,138.4c0,0.4,0.2,0.9,0.8,1c1.1,0.1,2.1,0.6,3.2,1.2c1.2,0.6,2.4,1.2,3.9,1.2c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
				c1.5,0,2.7-0.6,3.9-1.2c1-0.5,2-1,3.2-1.2c0.6-0.1,0.9-0.5,0.8-1c0-0.6-0.5-1-1.1-0.8c-1,0.2-2,0.7-2.9,1.2
				c-1.3,0.6-2.6,1.3-4,1.3c0,0,0,0,0,0c0,0,0,0,0,0c-1.5,0.1-2.8-0.6-4.2-1.3c-0.9-0.5-1.9-0.9-2.9-1.2
				C214.3,137.4,213.8,137.8,213.8,138.4z"/>
      <path d="M830.1,515c0-0.1,0-0.2,0-0.3c0.1-0.6-0.2-1.2-0.7-1.6c-7.2-5.5-13-12.6-18.6-19.4c-1.5-1.8-2.9-3.6-4.4-5.3
				c-5.8-6.9-13-10.8-19.8-10.8c-2.9-17-3.7-34.5-4.4-51.4c-0.6-12.4-1.1-25.3-2.6-37.9c-0.1-0.9-0.2-1.7-0.3-2.6
				c-0.5-5.1-1.1-10.8-3-15.5c-1.1-2.7-2.5-4.9-3.9-6.9c-2.8-4-2.9-8.2-3.1-12.7c0-1.1-0.1-2.2-0.1-3.4c-0.4-6.8-2.3-13.5-4.1-19.9
				c-2.1-7.6-4.3-15.5-4.1-23.8c0,0,0-0.1,0-0.1c0-0.1,0.1-0.3,0.1-0.4c0-4.9,0.2-9.9,0.5-14.7c0.6-12.1,1.2-24.7-1.8-36.6
				c-3.6-14.3-13-26.9-26.6-35.6c-6.4-4.1-13.7-6.7-21.7-7.9c-2.5-0.4-5-0.5-7.5-0.7c-3.8-0.2-7.7-0.5-11.4-1.5
				c-3.2-0.9-6.3-2.4-9.3-3.8c-1.4-0.7-2.9-1.4-4.4-2c-8.3-3.5-13.4-8-16.7-14.4c-3.2-6.4-3.6-14-3.6-21.2c0-0.2,0-0.3-0.1-0.4
				c0.1-0.1,0.1-0.1,0.2-0.1c8.3-7.7,9.3-18.3,10.1-27.7c7.2-2.6,8.2-8.8,9.3-15.8l0.2-1.2c0.7-3.9,0.1-7-1.5-8.9
				c-1.3-1.5-3.2-2.3-5.6-2.3c3.1-12.4,2.2-27.6-2.5-38.8c-5-12.2-21.4-14.5-30.7-14.7c-1.2,0-4.1-0.1-6.9-0.1
				c-2.6,0-8.8,0-11.5,0.2c-10.2,0.7-23.2,3.8-27.6,14.6c-4.6,11.3-5.6,26.4-2.5,38.8c-2.5,0-4.3,0.8-5.6,2.3
				c-1.6,1.9-2.1,5-1.5,8.9l0.2,1.2c1.1,7.1,2.1,13.2,9.3,15.8c0.9,9.4,1.9,19.9,10.1,27.7c0.1,0.1,0.1,0.1,0.2,0.1
				c0,0.1-0.1,0.3-0.1,0.4c-0.1,7.2-0.4,14.8-3.6,21.2c-3.2,6.4-8.4,10.8-16.7,14.4c-1.5,0.6-3,1.3-4.4,2c-3,1.4-6.1,2.9-9.3,3.8
				c-3.7,1.1-7.6,1.3-11.4,1.5c-2.5,0.1-5,0.3-7.5,0.7c-8,1.2-15.3,3.9-21.7,7.9c-13.6,8.6-23,21.3-26.6,35.6
				c-3,11.9-2.4,24.5-1.8,36.6c0.2,4.9,0.5,9.9,0.5,14.7c0,0.2,0,0.3,0.1,0.4l0,0l0,0.1c0.2,8.2-2,16.1-4.1,23.8
				c-1.8,6.4-3.6,13-4.1,19.9c-0.1,1.1-0.1,2.3-0.1,3.4c-0.1,4.5-0.3,8.7-3.1,12.7c-1.4,2-2.9,4.2-3.9,6.9c-1.9,4.8-2.5,10.5-3,15.5
				c-0.1,0.9-0.2,1.8-0.3,2.6c-1.4,12.6-2,25.5-2.6,37.9c-0.8,16.9-1.6,34.4-4.4,51.4c-6.8,0-14,4-19.8,10.8
				c-1.5,1.8-2.9,3.5-4.4,5.3c-5.6,6.8-11.4,13.9-18.6,19.4c-0.5,0.4-0.8,1-0.7,1.6c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3
				c0,0.6,0.4,1.1,0.9,1.4c2.3,1.1,4.5,1.7,6.7,1.7c4.1,0,7.7-1.9,11.4-4c1.1-0.6,2.3-1.3,3.5-1.9c-0.3,2.2-0.7,5-1.3,8.1
				c-0.9,5.2-1.9,11.1-2.4,16.8c-0.8,8.8-0.6,17.3,2.9,21.9c1.4,1.9,3.3,3.1,5.7,3.5c0.3,4,4.1,6.5,7.6,6.5c2,0,3.8-0.8,5.1-2.2
				c3.9,0.9,6.8,0.3,8.6-1.7c1.8-2,2.6-4.5,3.2-6.7c0.8,0.4,1.6,0.6,2.4,0.6c5.4,0,8.3-8.9,9.7-15c0.4-1.7,0.7-3.1,0.9-4.2
				c0.6-3,1-6,1.3-9c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.7-6.7,0.8-13.4,1-20c0.2-8.9,0.4-18.1,2-27h0c0,0,0,0,0,0
				c0-0.1,0-0.1,0-0.2c1.7-3.1,2.9-6.5,4-9.8c0.8-2.4,1.7-4.9,2.8-7.2c3-6.6,6.1-13,9.3-19.1c1.1-2.1,2.2-4.1,3.3-6.1
				c5.1-9.5,10.3-19.3,13.8-29.8c3.6-11,4.8-21.6,6.1-32.9l0.4-3.9c0.8-7,2.1-14.1,3.3-21c1.3-7.2,2.6-14.6,3.4-21.9l0,0
				c0.3-1.7,0.5-3.5,0.7-5.5c1.4,4.6,3.1,9.1,4.7,13.5c3.3,8.9,6.8,18.1,7.6,27.7c0.8,9,0.6,18.1,0.4,27l0,1.4c-0.1,2.7,0,5.5,0,8.1
				c0.1,4.6,0.1,9.4-0.3,14c0,0.5-0.4,1.8-0.5,2c-9.3,19-11,40.6-12.6,61.4c-0.2,3-0.5,6-0.7,9c-0.4,4.4-0.9,8.9-1.3,13.3
				c-0.8,7.6-1.7,15.5-2,23.3c-0.4,12.5-0.2,25.4,0.7,38.3c1.7,24,4.1,49.5,12.4,73.3c1,2.8,2,5.6,3,8.4c3,8.2,6,16.6,8.2,25.1
				c1.2,4.9,2.7,11.3,3.1,17.8c0.2,3,0,5.9-0.1,8.8c-0.1,1.9-0.2,3.9-0.2,5.8c-1,5.6-3,11-4.9,16.3c-2.5,6.9-5.1,14.1-5.6,21.8
				c-0.9,13.4,2.7,26.3,6.1,38.8c2.9,10.5,5.2,21.3,7.5,31.7c1.1,4.9,2.2,9.9,3.3,14.9c0.9,3.7,1.8,7.5,2.7,11.1
				c2.6,10.1,5.3,20.6,5.8,31.3c0.3,5.7-1.1,10.7-2.7,15.3c-0.8,2.3-1.4,4.8-1.9,7.1c-0.5,2.1-0.9,4.2-1.6,6.3
				c-2.5,7.7-7.8,16-14.2,22.3c-2.7,2.7-8.9,9.4-7.4,14.2c0.7,2.2,2.9,3.7,6.5,4.3c1.9,0.4,4,0.5,6.3,0.5c2.7,0,5.4-0.2,8-0.4
				c1.6-0.1,3.2-0.3,4.8-0.3c3.6-0.2,7.4,0.2,11.1,0.6c3.1,0.3,6.2,0.7,9.3,0.7c2.2,0,4.2-0.2,6-0.5c14.5-2.8,12.1-15.9,10.1-26.5
				l-0.2-0.9c-3.6-19.3-4.2-39.6-4.4-58.7c-0.2-16.9,1.6-33,3.4-50.1c0.3-2.9,0.6-5.7,0.9-8.6c1.7-16.8,2.8-36.6-2.7-56.4
				c-2.9-10.3-6.5-23.1-5-35.2c0.2-1.6,0.4-3.1,0.6-4.6c0.8-5.8,1.5-11.4,1-17.2c0-0.1,0-0.2,0-0.3c0-7,2.2-13.9,4.3-20.6
				c1.1-3.7,2.3-7.5,3.2-11.3c2.4-10.9,3.6-23,3.9-37c0.2-11.6-0.1-23.3-0.5-34.6c-0.1-3-0.2-5.9-0.3-8.9
				c-0.2-6.5-0.1-13.3,0.2-21.5c0.3-6.2,0.7-9.7,6.7-10.1c0.2,0,0.4,0,0.5-0.1c0.2,0.1,0.4,0.1,0.5,0.1c6,0.3,6.4,3.8,6.7,10.1
				c0.3,8.2,0.4,15,0.2,21.5c-0.1,3-0.2,5.9-0.3,8.9c-0.4,11.3-0.7,23.1-0.5,34.6c0.3,14,1.6,26.1,3.9,37c0.8,3.8,2,7.6,3.2,11.3
				c2.1,6.7,4.3,13.6,4.3,20.6c0,0.1,0,0.2,0,0.3c-0.5,5.8,0.3,11.3,1,17.2c0.2,1.5,0.4,3.1,0.6,4.6c1.4,12.1-2.1,24.9-5,35.2
				c-5.5,19.7-4.5,39.6-2.7,56.4c0.3,2.9,0.6,5.8,0.9,8.6c1.8,17.1,3.6,33.2,3.4,50.1c-0.2,19.1-0.8,39.4-4.4,58.7l-0.2,0.9
				c-2,10.6-4.4,23.7,10.1,26.5c1.8,0.4,3.8,0.5,6,0.5c3.1,0,6.3-0.3,9.3-0.7c3.7-0.4,7.4-0.8,11.1-0.6c1.6,0.1,3.2,0.2,4.8,0.3
				c2.6,0.2,5.3,0.4,8,0.4c2.3,0,4.4-0.2,6.3-0.5c3.6-0.7,5.8-2.1,6.5-4.3c1.5-4.7-4.7-11.5-7.4-14.2c-6.4-6.3-11.7-14.6-14.2-22.3
				c-0.7-2-1.1-4.2-1.6-6.3c-0.5-2.3-1.1-4.8-1.9-7.1c-1.6-4.6-3-9.6-2.7-15.3c0.6-10.7,3.2-21.2,5.8-31.3c0.9-3.6,1.9-7.4,2.7-11.1
				c1.1-5,2.2-10,3.3-14.9c2.3-10.4,4.6-21.2,7.5-31.7c3.4-12.5,7-25.4,6.1-38.8c-0.5-7.6-3.1-14.8-5.6-21.8
				c-1.9-5.3-3.9-10.7-4.9-16.2c0-2-0.1-4-0.2-5.9c-0.2-2.9-0.3-5.9-0.1-8.8c0.4-6.5,1.9-12.9,3.1-17.8c2.1-8.5,5.2-17,8.2-25.1
				c1-2.8,2-5.6,3-8.4c8.3-23.9,10.7-49.3,12.4-73.3c0.9-13,1.2-25.9,0.7-38.3c-0.3-7.8-1.1-15.7-2-23.3c-0.5-4.4-1-8.8-1.3-13.3
				c-0.3-3-0.5-6-0.7-9c-1.6-20.8-3.3-42.4-12.6-61.4c-0.1-0.2-0.4-1.5-0.5-2c-0.4-4.6-0.4-9.4-0.3-14c0-2.7,0.1-5.4,0-8.1l0-1.4
				c-0.2-8.9-0.4-18,0.4-27c0.9-9.7,4.3-18.9,7.6-27.7c1.6-4.4,3.3-8.9,4.7-13.5c0.2,2,0.5,3.8,0.7,5.4l0,0l0,0.1
				c0.8,7.4,2.1,14.8,3.4,21.9c1.2,6.9,2.5,14,3.3,21l0.4,3.9c1.3,11.3,2.5,21.9,6.1,32.9c3.5,10.5,8.7,20.3,13.8,29.8
				c1.1,2,2.2,4.1,3.3,6.1c3.2,6.1,6.3,12.5,9.3,19.1c1.1,2.3,1.9,4.8,2.8,7.2c1.1,3.3,2.3,6.6,4,9.8l0,0.1l0,0.1c0,0,0,0,0,0h0
				c1.5,8.9,1.8,18.1,2,27c0.2,9.6,0.5,19.6,2.4,29.4c0.9,4.7,3.8,19.2,10.6,19.2c0.8,0,1.6-0.2,2.4-0.6c0.6,2.3,1.4,4.8,3.2,6.7
				c1.8,2,4.7,2.6,8.6,1.7c1.3,1.4,3.1,2.2,5.1,2.2c3.5,0,7.2-2.5,7.6-6.5c2.3-0.4,4.2-1.6,5.7-3.5c1.7-2.3,2.6-5.4,3-9.1
				c1-8.7-1-20.4-2.6-29.6c-0.5-3.1-1-5.9-1.3-8.1c1.2,0.6,2.4,1.3,3.5,1.9c3.7,2.1,7.3,4,11.4,4c2.2,0,4.4-0.5,6.7-1.7
				c0.6-0.3,0.9-0.8,0.9-1.4C830.2,515.1,830.2,515,830.1,515z M673.7,112.9c1.6,1.9,0.9,6,0.3,9.1l-0.2,0.9
				c-0.9,5.6-1.7,8.8-7.3,10.2c-0.1,0-0.1,0-0.2,0.1c1.1-3.6,1.7-7.2,2.1-10.2c0.6-4.4,0.7-8.3,0.7-11.1
				C671.4,111.6,672.9,111.9,673.7,112.9z M592,88.4c0.4-5.9,0.9-13.3,3.9-18.6c3.8-6.8,11.9-10.6,24.2-11.4
				c2.5-0.2,8.6-0.2,11.2-0.2c2.8,0,5.5,0,6.6,0.1c9.5,0.3,21.9,2.5,27.1,11.6c3,5.4,3.5,12.7,3.9,18.6c0.5,7.4,0.1,13.4-1.1,18.5
				c-0.4,0.1-0.7,0.5-0.6,1c0,0,0,0.4,0.1,1.1c-0.1,0.2-0.1,0.3-0.2,0.5l0.2,0.5c0.1,2.6,0.1,7.3-0.6,12.9c-1,7.6-3.6,18.3-10.6,26
				c-6.2,6.8-14.8,10.2-25.6,10.2c-10.8,0-19.4-3.4-25.6-10.2c-7-7.7-9.6-18.4-10.6-26c-0.7-5.6-0.7-10.4-0.6-13l0.2-0.4
				c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.7,0.1-1.1,0.1-1.1c0-0.5-0.2-0.9-0.6-1C591.9,101.7,591.5,95.8,592,88.4z M586.9,122.9l-0.2-0.9
				c-0.5-3-1.2-7.2,0.3-9.1c0.6-0.8,1.6-1.1,3-1.1c0.5,0,1,0,1.6,0.1c0,2.8,0.1,6.6,0.7,11.1c0.4,3,1,6.6,2.1,10.2
				c-0.1,0-0.1,0-0.2-0.1C588.6,131.7,587.8,128.5,586.9,122.9z M630.9,534.2c-0.2,0-0.4,0-0.5,0.1c-0.2,0-0.3-0.1-0.5-0.1
				c0.1,0,0.1,0,0.2-0.1c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0,0,0,0,0,0h0l0,0c0,0,0,0,0,0c0,0,0,0,0,0
				c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0C630.8,534.2,630.9,534.2,630.9,534.2C631,534.2,630.9,534.2,630.9,534.2z
				 M760.2,472c-0.6-1.7-1.2-3.5-1.9-5.2c-2-5.1-4.5-10.1-7-14.9l-1-2c-1.8-3.5-3.6-7-5.4-10.4c-4.3-8.1-8.8-16.5-12.1-25.3
				c-5.2-13.5-6.6-27.1-7.9-40.2c-0.4-3.7-0.9-7.4-1.5-11.1l0,0c2.4,3.5,6.4,8.6,10.7,10.4c0.1,0,0.2,0.1,0.4,0.1
				c0.4,0,0.7-0.2,0.9-0.6c0.2-0.5,0-1.1-0.5-1.2c-6-2.4-11.9-12.9-11.9-13c-0.1-0.2-0.2-0.3-0.3-0.4c-0.4-2.2-0.8-4.4-1.2-6.5
				c-1.2-6.7-2.5-13.6-3.3-20.5l0-0.2c-2.8-17.6-2.3-36.1-1.4-55.1c0.5-10.3,1.1-24.3-9.3-31.9c-1-0.7-2.3-0.2-2.7,0.9
				c-0.3,0.8,0,1.6,0.7,2.1c9,6.7,8.3,19.3,7.6,30.3c-0.1,1.6-0.2,3.1-0.2,4.5c0,1.3-0.1,2.6-0.1,3.9l-0.1,4.6
				c-0.2,7.7-0.2,15.8,0.2,23.7c-1.4,8.6-4.4,16.8-7.3,24.7c-3.3,8.9-6.7,18-7.8,27.8c-0.9,8.2-0.7,16.7-0.5,24.8l0.1,2.3
				c0.1,3,0,6,0,9c-0.1,4.3-0.1,8.7,0.1,13c-4.3-5.9-10-9.7-10.3-9.9c-0.4-0.3-1-0.2-1.3,0.3c-0.3,0.4-0.2,1,0.3,1.3
				c0.1,0.1,8.6,5.7,12.3,13.7c0,0.1,0.1,0.2,0.1,0.2l0.1,0.4c0.2,0.4,0.4,0.9,0.7,1.5c0.3,0.7,0.7,1.4,0.9,1.8
				c7.5,17.9,9,37.9,10.5,57.2c0.2,3.3,0.5,6.7,0.8,9.9c0.4,4.4,0.8,8.8,1.3,13.1c0.8,7.8,1.7,15.8,1.9,23.8
				c0.4,11.6,0,24.1-1.2,40.4c-0.9,12.3-2.4,25.1-4.3,39.4c-1.6,11.7-5.6,22.9-9.5,33.8l-1.1,3c-3.6,10-7.3,20.4-9.6,31
				c-0.4,2-0.7,3.9-1,5.8l-0.6,9.7c0,2.3,0,4.7,0,7.1l0,2.4c0,0.9,0.5,4.4,0.6,4.8c0.6,2.8,1.4,5.6,2.3,8.4
				c-3.4-6.7-6.2-13.4-6.2-13.5c-0.2-0.5-0.8-0.7-1.3-0.5c-0.5,0.2-0.7,0.8-0.5,1.3c0,0.1,3.1,7.4,6.7,14.5c0.6,1.2,1.1,2.2,1.6,3.2
				c1.1,2.3,2.2,4.5,4.3,8.3c0.2,0.6,0.4,1.2,0.6,1.7c3,9.2,3.3,19.4,0.9,32.3c-1.1,6.1-2.8,12.3-4.5,18.2c-0.9,3.3-1.8,6.7-2.7,10
				c-1.6,6.5-3,13.1-4.4,19.6c-0.7,3.3-1.4,6.5-2.1,9.8c-1.3,5.8-2.8,11.7-4.3,17.4c-1,3.7-1.9,7.5-2.9,11.3
				c-2.2,9.2-4.5,19.8-2.8,28.6c0.8,4.2,2.1,8.5,3.3,12.6c0.7,2.4,1.5,4.8,2.1,7.2c-0.6,6.3-2.4,14.8-2.5,14.9
				c-0.1,0.5,0.2,1,0.7,1.1c0.1,0,0.1,0,0.2,0c0.4,0,0.8-0.3,0.9-0.8c0.1-0.3,1.2-5.5,1.9-10.8c0.3,0.8,0.7,1.7,1,2.5l2.5,4.7
				c1.7,2.8,3.5,5.5,5.5,8.3l0.1,0.2c1.7,2.5,3.8,4.7,5.9,6.8c1.7,1.8,3.5,3.6,5,5.7c1.2,1.6,1.5,2.8,1.1,3.7c0,0.1-0.1,0.1-0.1,0.2
				l-1.1,0.9c-0.5,0.3-1.3,0.6-2.3,0.9c0.7-1.4,0.5-2.2,0.4-2.5c-0.2-0.5-0.8-0.7-1.3-0.5c-0.4,0.2-0.7,0.7-0.5,1.2
				c0,0.1,0,0.8-1,2.2c0,0,0,0.1-0.1,0.1c-0.8,0.1-1.7,0.2-2.8,0.2l-1.2,0c-0.4,0-0.8,0-1.2,0c-2.1,0-4.1-0.1-5.7-0.2l-1.3-0.1
				c-0.5,0-0.9-0.1-1.3-0.1l-0.7-0.1c-1.2-0.1-2.4-0.1-3.8-0.1c-0.6,0-1.2,0-1.8,0l-1.2,0c-0.6,0-1.1,0.1-1.7,0.1
				c3.8-5.9,2-11.5,0.4-14.6c-0.7-1.3-2.1-2.9-3.3-4.4c-0.6-0.8-1.3-1.5-1.5-1.9c-4.8-7.2-5.2-19.3-5.2-19.4c0-0.5-0.4-0.9-1-0.9
				c0,0,0,0,0,0c-0.5,0-1,0.5-0.9,1c0,0.5,0.4,12.8,5.5,20.4c0.3,0.5,0.9,1.2,1.7,2.1c1.1,1.3,2.5,2.9,3.1,4c1.8,3.5,3,8.6-1.2,13.8
				c-0.9,0.1-1.9,0.2-2.8,0.2c-0.7,0.1-1.4,0.1-2.1,0.2l-1.3,0.1c-2.8,0.2-5.6,0.4-8.4,0.3c-0.8,0-1.6-0.1-2.3-0.2l-2.3-0.6
				c-1.7-0.6-3-1.5-3.9-2.7c-1.5-1.9-2.1-4.5-2.1-7.4c1.3-5.9,2.5-8.6,3.6-10.6c5.4-10.2,6.9-19.2,6.9-19.5c0.1-0.5-0.3-1-0.8-1.1
				c-0.5-0.1-1,0.3-1.1,0.8c0,0.1-1.5,9.1-6.7,18.9c-0.2,0.4-0.4,0.8-0.6,1.2c0.1-0.5,0.2-1.1,0.3-1.6l0.1-0.4
				c7.8-38.6,4.6-78,0.2-118.4c-1.2-11.5-1.7-21.1-1.4-30.2c0.3-10.5,2.9-20.2,5.6-30.4l0.2-0.9c0.6-2.5,1.2-4.9,1.7-7.4
				c6.1-15.1,7.8-22.9,7.8-23.2c0.1-0.5-0.2-1-0.7-1.1c-0.5-0.1-1,0.2-1.1,0.7c0,0.1-1.1,5.1-4.6,14.7l0.1-0.7c0.2-2,0.4-4,0.4-6
				c0.2-4.6-0.4-9.2-0.9-13.6c-0.5-4.1-1-8.3-0.9-12.4l-0.1-5.8c-0.1-1.1-0.2-2.2-0.3-2.9c3.3,5.2,6.3,8.3,6.6,8.5
				c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4c-0.1-0.1-5.2-5.3-9.3-13.6c-0.3-1-0.6-2.1-0.9-3.2
				c-4-12.9-6.5-25.1-7.7-37.1c-1.3-14-1.1-28.2-0.8-40.4c0.1-2.8,0.2-5.6,0.3-8.4c0.3-10.1,0.7-20.6,0.1-31l0-0.6
				c-0.2-4.7-0.5-9.2-3.2-12c5.2,1.8,12.4,4,20.7,4.8c2.3,0.2,4.5,0.3,6.7,0.3c10.8,0,20.5-2.8,28.8-8.3c0.4-0.3,0.6-0.9,0.3-1.3
				c-0.3-0.4-0.9-0.6-1.3-0.3c-22.6,15-49,5.1-58.9,1.4c-1.4-0.5-2.4-0.9-3-1.1v-17.4c0-0.5-0.4-1-1-1l0,0h0c-0.5,0-1,0.4-1,1v17.4
				c-0.6,0.2-1.6,0.5-3,1.1c-9.9,3.7-36.3,13.6-58.9-1.4c-0.4-0.3-1-0.2-1.3,0.3c-0.3,0.4-0.2,1,0.3,1.3c8.3,5.5,18,8.3,28.8,8.3
				c2.2,0,4.4-0.1,6.7-0.3c8.2-0.8,15.4-3,20.6-4.8c-2.7,2.7-3,7.3-3.2,12l0,0.6c-0.5,10.3-0.2,20.8,0.1,31c0.1,2.8,0.2,5.6,0.3,8.4
				c0.3,12.2,0.6,26.4-0.8,40.4c-1.1,12-3.6,24.2-7.7,37.1c-0.4,1.1-0.7,2.2-0.9,3.2c-4.1,8.3-9.2,13.6-9.3,13.6
				c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.5-0.1,0.7-0.3c0.2-0.2,3.3-3.3,6.6-8.5c-0.1,0.7-0.2,1.8-0.3,2.9
				l-0.1,5.8c0.1,4.2-0.4,8.4-0.9,12.4c-0.6,4.4-1.1,9-0.9,13.6c0.1,2,0.2,4,0.4,6l0.1,0.7c-3.5-9.6-4.6-14.6-4.6-14.7
				c-0.1-0.5-0.6-0.9-1.1-0.7c-0.5,0.1-0.9,0.6-0.7,1.1c0.1,0.3,1.7,8.1,7.8,23.2c0.5,2.4,1,4.9,1.7,7.4l0.2,0.8
				c2.7,10.2,5.2,19.9,5.6,30.4c0.3,9.1-0.1,18.6-1.4,30.2c-4.4,40.4-7.6,79.9,0.2,118.4l0.1,0.4c0.1,0.5,0.2,1.1,0.3,1.6
				c-0.2-0.4-0.4-0.8-0.6-1.2c-5.2-9.9-6.7-18.9-6.7-18.9c-0.1-0.5-0.6-0.9-1.1-0.8c-0.5,0.1-0.9,0.6-0.8,1.1
				c0.1,0.4,1.5,9.4,6.9,19.5c1.1,2,2.3,4.7,3.6,10.6c0,2.8-0.6,5.4-2.1,7.4c-1,1.2-2.3,2.1-3.9,2.7l-2.3,0.6
				c-0.7,0.1-1.5,0.2-2.3,0.2c-2.8,0.1-5.6,0-8.4-0.3l-1.3-0.1c-0.7-0.1-1.4-0.1-2.1-0.2c-0.9-0.1-1.9-0.2-2.8-0.2
				c-4.2-5.2-3-10.4-1.2-13.8c0.6-1.1,2-2.7,3.1-4c0.7-0.8,1.3-1.6,1.7-2.1c5.1-7.7,5.5-19.9,5.5-20.4c0-0.5-0.4-1-0.9-1
				c-0.5,0-1,0.4-1,0.9c0,0.1-0.4,12.2-5.2,19.4c-0.3,0.4-0.9,1.1-1.5,1.9c-1.2,1.5-2.7,3.1-3.3,4.4c-1.6,3-3.4,8.7,0.4,14.6
				c-0.6,0-1.1-0.1-1.7-0.1l-1.2,0c-0.6,0-1.2,0-1.8,0c-1.3,0-2.6,0-3.8,0.1l-0.7,0.1c-0.4,0-0.8,0.1-1.3,0.1l-1.3,0.1
				c-1.7,0.1-3.7,0.2-5.7,0.2c-0.4,0-0.8,0-1.2,0l-1.2,0c-1,0-2-0.1-2.8-0.2c0,0,0-0.1-0.1-0.1c-1-1.4-1-2-1-2.2
				c0.1-0.5-0.1-1-0.5-1.2c-0.5-0.2-1.1,0-1.3,0.5c-0.1,0.3-0.3,1.1,0.4,2.5c-1-0.3-1.8-0.6-2.3-0.9l-1.1-0.9
				c-0.1-0.1-0.1-0.1-0.1-0.2c-0.5-0.9-0.1-2.2,1.1-3.7c1.5-2,3.3-3.9,5-5.7c2-2.1,4.1-4.3,5.9-6.8l0.1-0.2c1.9-2.8,3.8-5.5,5.5-8.3
				l2.5-4.7c0.4-0.8,0.7-1.6,1-2.5c0.8,5.3,1.9,10.5,1.9,10.8c0.1,0.5,0.5,0.8,0.9,0.8c0.1,0,0.1,0,0.2,0c0.5-0.1,0.9-0.6,0.7-1.1
				c0-0.1-1.8-8.7-2.5-14.9c0.6-2.4,1.4-4.9,2.1-7.2c1.2-4.1,2.5-8.4,3.3-12.6c1.7-8.8-0.6-19.4-2.8-28.6c-0.9-3.8-1.9-7.6-2.9-11.3
				c-1.5-5.7-3-11.6-4.3-17.4c-0.7-3.3-1.4-6.5-2.1-9.8c-1.4-6.4-2.7-13.1-4.4-19.6c-0.8-3.4-1.8-6.7-2.7-10
				c-1.6-5.9-3.3-12.1-4.5-18.2c-2.4-12.9-2.1-23.2,0.9-32.3c0.2-0.6,0.4-1.2,0.6-1.7c2.1-3.7,3.2-5.9,4.3-8.3c0.5-1,1-2.1,1.6-3.2
				c3.6-7.1,6.7-14.4,6.7-14.5c0.2-0.5,0-1.1-0.5-1.3c-0.5-0.2-1.1,0-1.3,0.5c0,0.1-2.8,6.8-6.2,13.5c0.9-2.7,1.7-5.5,2.3-8.4
				c0.1-0.5,0.6-3.9,0.6-4.8l0-2.4c0-2.4,0-4.8,0-7.1l-0.6-9.7c-0.2-1.9-0.5-3.8-1-5.8c-2.3-10.6-6-21-9.6-31l-1.1-3
				c-3.9-10.9-7.9-22.2-9.5-33.8c-2-14.3-3.4-27.1-4.3-39.4c-1.2-16.3-1.6-28.7-1.2-40.4c0.3-8,1.1-16,1.9-23.8
				c0.5-4.3,0.9-8.7,1.3-13.1c0.3-3.3,0.5-6.6,0.8-9.9c1.4-19.3,2.9-39.3,10.5-57.2c0.2-0.4,0.5-1.1,0.9-1.8
				c0.3-0.6,0.5-1.1,0.7-1.5l0.1-0.4c0.1-0.1,0.1-0.1,0.1-0.2c3.7-8,12.2-13.6,12.3-13.7c0.4-0.3,0.6-0.9,0.3-1.3
				c-0.3-0.4-0.9-0.6-1.3-0.3c-0.3,0.2-6,4-10.3,9.9c0.3-4.3,0.2-8.7,0.1-13c0-3-0.1-6,0-9l0.1-2.3c0.2-8.2,0.4-16.6-0.5-24.8
				c-1.1-9.7-4.5-18.9-7.8-27.8c-2.9-8-6-16.2-7.3-24.7c0.4-8,0.4-16,0.2-23.7l-0.1-4.6c0-1.3-0.1-2.6-0.1-3.9
				c-0.1-1.5-0.1-3-0.2-4.5c-0.6-11.1-1.4-23.6,7.6-30.3c0.7-0.5,0.9-1.3,0.7-2.1c-0.4-1.1-1.7-1.7-2.7-0.9
				c-10.4,7.6-9.8,21.6-9.3,31.9c0.8,19,1.4,37.4-1.4,55.1l0,0.1c-0.8,6.9-2,13.8-3.3,20.5c-0.4,2.2-0.8,4.3-1.2,6.5
				c-0.1,0.1-0.3,0.2-0.3,0.4c-0.1,0.1-5.9,10.6-11.9,13c-0.5,0.2-0.7,0.8-0.5,1.2c0.2,0.4,0.5,0.6,0.9,0.6c0.1,0,0.2,0,0.4-0.1
				c4.3-1.7,8.4-6.9,10.7-10.4l0,0c-0.6,3.7-1.1,7.4-1.5,11.1c-1.3,13.1-2.6,26.7-7.9,40.2c-3.4,8.7-7.8,17.1-12.1,25.3
				c-1.8,3.4-3.6,6.9-5.4,10.4l-1,2c-2.4,4.8-5,9.8-7,14.9c-0.7,1.7-1.3,3.5-1.9,5.2c-1.2,3.4-2.4,6.9-4.1,10
				c-0.2,0.4-0.3,0.8-0.2,1.2c-1.4,8.3-1.5,16.8-1.6,25c-0.1,7.1-0.2,14.5-1.1,21.6l-0.1,0.4c-3,22.5-7.2,24.9-8.8,24.9
				c-0.5,0-1-0.2-1.5-0.5c-0.1-0.4-0.4-0.6-0.7-0.8c0.2-1.5,0.4-2.9,0.6-4.4c0.3-2.6,0.6-5.2,1.2-7.7l0.2-0.8
				c0.9-3.8,1.8-7.8,1.7-11.9c0-0.1,0-0.6-0.1-0.7c-0.1-0.2-0.4-0.4-0.8-0.3c-0.4,0-0.6,0.2-0.7,0.4s-0.1,0.8-0.1,1
				c0,3.2-0.6,6.2-1.3,9.3c-0.3,1.3-0.5,2.6-0.8,3.9c-0.4,2.2-0.7,4.5-0.9,6.7c-0.2,1.5-0.4,3-0.6,4.5h0c-0.6,0.2-1,0.7-1.1,1.3
				c-0.1,0.4-0.2,0.8-0.3,1.2c-0.5,2.1-1,4.4-2.3,6c-0.7,0.9-1.8,1.4-3.2,1.4c-0.8,0-1.7-0.2-2.5-0.3c1.5-4.5,2.1-9.6,2.5-14.1
				c0.7-6.5,1-13.1,1.3-19.5l0.1-0.9c0,0,0-0.8-0.8-0.9c-0.8-0.1-0.9,0.8-0.9,0.8c-0.2,2.8-0.4,5.3-0.5,8.1
				c-0.2,3.2-0.4,6.6-0.7,9.9l-0.1,1.3c-0.5,4.9-1,10.4-2.7,15.1h0c-0.7,0.2-1.3,0.8-1.5,1.5c-0.7,0.9-1.7,1.1-2.3,1.1
				c-1.6,0-3.3-1.1-3.8-2.8c0-0.2,0-0.3-0.1-0.5c0-0.1,0-0.2,0-0.3c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0
				c0.2-0.3,0.3-0.8,0.3-1.2c0-0.2,0-0.3-0.1-0.5c0-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.1-0.1-0.2-0.2c0-1.4,0.1-2.8,0.2-4.1l0.1-0.9
				c0.2-2.7,0.5-5.6,1-9l0.3-2c0.7-5,1.5-10,1.6-15.2c0,0-0.1-1-0.9-1c-0.7,0-0.8,0.7-0.8,0.7c-0.1,4.8-0.8,9.5-1.5,14.2
				c-0.3,2.1-0.6,4.3-0.9,6.5c-0.4,3.3-0.8,6.9-0.7,10.4h0c-1.2,0-2.2-0.3-3.1-0.9c-0.4-0.3-0.8-0.6-1.2-1c-5-5.9-2.1-23.9-0.2-35.8
				c0.7-4.1,1.2-7.5,1.4-10c0,0,0-0.1,0-0.1c0-0.2,0-0.3,0-0.5c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2c0-0.4,0-0.7,0-1
				c0-0.4-0.2-0.7-0.3-0.9c0,0,0,0,0,0c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.2
				c-0.4-0.2-0.8-0.2-1.2,0c-2.5,0.9-4.6,2.1-6.7,3.3c-3.2,1.8-6.2,3.5-9.7,3.5c-0.8,0-1.6-0.1-2.4-0.3c6.2-5.2,11.2-11.1,15.7-16.7
				l0.9-1.2c5.4-6.8,11.5-14.4,20.4-15.1c0,0,0,0,0,0c0.2,0,0.5,0,0.7,0c0.1,0,0.2,0,0.3,0c0.3,0,0.5,0,0.8,0l0,0c0,0,0,0,0.1,0
				c0.2,0,0.4,0,0.6-0.1l2.2-1.6c0-0.1,0.1-0.2,0.1-0.2c2.9-16.7,3.6-33.9,4.4-50.6c0.6-14.6,1.3-29.7,3.4-44.3
				c0.5-3.6,1.1-7.4,2-11c0.8-3,2.3-5.3,3.9-7.9c0.7-1.1,1.5-2.3,2.2-3.5l1.4-4.3c0.2-1.7,0.2-3.4,0.2-5c0-1.1,0-2.2,0.1-3.3
				c0.4-7,2.3-13.8,4.1-20.4c2.2-8.2,4.5-16.6,4.2-25.4c0,0,0,0,0,0l0-3.9c-0.1-3.8-0.3-7.7-0.5-11.4c-0.7-12.6-1.4-25.7,2.3-37.6
				c5.6-17.7,19.8-32.2,36.3-37c4.2-1.2,8.6-1.5,12.9-1.8c0.1,0,0.6-0.1,1.2-0.1c12-0.4,23.4,0.4,23.5,0.4c0.5,0,1-0.4,1-0.9
				c0-0.5-0.4-1-0.9-1c-0.1,0-4.6-0.3-10.9-0.5c5.9-1.8,15.9-5.3,23.2-10.4c9.8-7,13-17.2,14-26.9c0,0,0.4-5.8,0.4-7
				c0.1-3.4-1.3-4.3-1.3-4.3c-6-6.1-7.1-12.7-8.4-20.3c-0.1-0.8-0.3-2.2-0.4-3.4c1.7,4.4,4.1,8.9,7.5,12.6
				c6.6,7.2,15.6,10.9,27,10.9c11.4,0,20.4-3.7,27-10.9c3.4-3.8,5.8-8.2,7.5-12.6c-0.1,1.3-0.2,2.6-0.4,3.4
				c-1.3,7.7-2.4,14.3-8.4,20.3c0,0-1,0.6-1.2,4.5c-0.1,1.7,0.3,6.8,0.3,6.8c1,9.6,4.2,19.9,14,26.9c7.3,5.2,17.3,8.7,23.2,10.4
				c-6.5,0.1-11.1,0.5-11.2,0.5c-0.5,0-0.9,0.5-0.9,1c0,0.5,0.5,0.9,1,0.9c0.1,0,11.1-0.8,22.9-0.5c0.7,0,1.4,0.1,2.1,0.1
				c4.3,0.3,8.7,0.6,12.9,1.8c16.5,4.7,30.7,19.2,36.3,37c3.8,11.9,3,25,2.3,37.6c-0.2,3.8-0.4,7.6-0.5,11.4l0,3.9c0,0,0,0,0,0
				c-0.3,8.8,2,17.2,4.2,25.4c1.8,6.6,3.6,13.5,4.1,20.4c0.1,1.1,0.1,2.2,0.1,3.3c0,1.7,0,3.4,0.2,5l0.7,2.7
				c0.2,0.5,0.4,1.1,0.7,1.6c0.7,1.2,1.4,2.4,2.2,3.5c1.6,2.5,3.1,4.9,3.9,7.9c1,3.7,1.5,7.6,2,11c2.1,14.7,2.8,29.8,3.4,44.3
				c0.7,16.6,1.5,33.8,4.4,50.6c0,0.1,0.6,1.8,2.3,1.8c0.2,0,0.4,0.1,0.6,0.1c0,0,0,0,0,0s0,0,0,0l0,0c9.8-0.3,16.4,7.9,22.2,15.1
				l0.9,1.2c4.5,5.6,9.6,11.5,15.7,16.7c-0.8,0.2-1.6,0.3-2.4,0.3c-3.5,0-6.5-1.7-9.7-3.5c-2.1-1.2-4.3-2.4-6.7-3.3
				c-0.7-0.2-1.3-0.1-1.7,0.3l-0.2,0.2c-0.3,0.3-0.4,0.6-0.4,1v0c0,0,0,0,0,0c-0.1,2.3,0.6,6.6,1.5,12.1c1.4,8.5,3.3,20.2,2.4,28.3
				c-0.4,3.2-1.1,5.8-2.5,7.4c-1.1,1.3-2.5,1.9-4.3,2h0c0.1-3.5-0.3-7.1-0.7-10.4c-0.3-2.2-0.6-4.3-0.9-6.5
				c-0.7-4.7-1.4-9.3-1.5-14.2c0,0-0.1-0.7-0.8-0.7c-0.9,0-0.9,1-0.9,1c0.1,5.2,0.8,10.2,1.6,15.2l0.3,2c0.5,3.4,0.8,6.3,1,9
				l0.1,0.9c0.1,1.3,0.2,2.7,0.2,4.1c-0.2,0.2-0.3,0.3-0.4,0.6c-0.1,0.2-0.1,0.3-0.1,0.5c0,0.5,0.1,1,0.4,1.4c0,0.1,0,0.3,0,0.4
				c0,0.1,0,0.3-0.1,0.4c-0.5,1.6-2.3,2.8-3.8,2.8c-0.7,0-1.6-0.2-2.3-1.1c-0.2-0.7-0.7-1.3-1.5-1.5h0c-1.7-4.7-2.2-10.2-2.7-15.1
				l-0.1-1.3c-0.3-3.3-0.5-6.6-0.7-9.9c-0.1-2.7-0.3-5.3-0.5-8.1c0,0-0.1-0.9-0.9-0.8c-0.8,0.1-0.8,0.9-0.8,0.9l0.1,0.9
				c0.3,6.4,0.6,13,1.3,19.5c0.5,4.6,1,9.7,2.5,14.1c-0.8,0.2-1.7,0.3-2.5,0.3c-1.4,0-2.4-0.4-3.2-1.4c-1.3-1.6-1.8-3.9-2.3-6
				c-0.1-0.4-0.2-0.8-0.3-1.2c-0.2-0.6-0.6-1.1-1.1-1.3h0c-0.2-1.5-0.4-3-0.6-4.5c-0.3-2.2-0.5-4.5-0.9-6.7
				c-0.2-1.3-0.5-2.6-0.8-3.9c-0.7-3.1-1.3-6.1-1.3-9.3c0-0.2,0-0.8-0.1-1c-0.1-0.2-0.3-0.4-0.7-0.4c-0.4,0-0.7,0.1-0.8,0.3
				c-0.1,0.2-0.1,0.6-0.1,0.7c-0.1,4.1,0.8,8,1.7,11.9l0.2,0.8c0.6,2.5,0.9,5.2,1.2,7.7c0.2,1.4,0.4,2.9,0.6,4.4
				c-0.3,0.2-0.6,0.5-0.7,0.8c-0.5,0.3-1,0.5-1.5,0.5c-1.6,0-5.8-2.4-8.8-24.9l-0.1-0.4c-1-7.2-1.1-14.5-1.1-21.6
				c-0.1-8.2-0.2-16.7-1.6-25c0.1-0.4,0-0.8-0.2-1.2C762.6,478.9,761.4,475.4,760.2,472z"/>
      <path d="M641.9,282.8c-2.5-5.5-3.1-33.3-3.1-33.6c0-0.5-0.4-0.9-1-0.9c-0.5,0-1,0.5-0.9,1c0,1.2,0.7,28.5,3.3,34.3
				c0.2,0.4,4,9.2,8.6,13.2c0.2,0.2,0.4,0.2,0.6,0.2c0.3,0,0.5-0.1,0.7-0.3c0.3-0.4,0.3-1-0.1-1.4
				C645.7,291.6,642,282.9,641.9,282.8z"/>
      <path d="M624.8,249.2c0-0.5-0.4-1-0.9-1c-0.5,0-1,0.4-1,0.9c0,0.3-0.7,28.1-3.1,33.6c0,0.1-3.8,8.8-8.1,12.5
				c-0.4,0.3-0.4,1-0.1,1.4c0.2,0.2,0.5,0.3,0.7,0.3c0.2,0,0.4-0.1,0.6-0.2c4.6-3.9,8.4-12.8,8.6-13.2
				C624.1,277.7,624.7,250.4,624.8,249.2z"/>
      <path d="M755.1,377.4c-4.7,4.1-13.3,3-13.5,3c-0.5-0.1-1,0.3-1.1,0.8c-0.1,0.5,0.3,1,0.8,1.1c0.1,0,1.4,0.2,3.1,0.2
				c3.3,0,8.4-0.6,11.9-3.6c0.4-0.3,0.4-1,0.1-1.4C756.1,377.1,755.5,377.1,755.1,377.4z"/>
      <path d="M640.2,428.2c0,0-2.4,2-6.7,5.5c-1.8,1.5-2.7,2.9-3.1,4.2c-0.5-1.3-1.3-2.7-3.1-4.2c-4.3-3.6-6.7-5.5-6.7-5.5
				c-0.4-0.3-1-0.3-1.4,0.1c-0.3,0.4-0.3,1,0.1,1.4c0,0,2.4,2,6.7,5.5c2.6,2.2,2.8,4,3,5.7c0,0.4,0.1,0.7,0.1,1.1
				c0.2,1,0.2,2.8,0.3,4.7c0,0.2,0,0.5,0,0.7c0,4-0.3,8.4-0.3,8.5c0,0.5,0.4,1,0.9,1c0,0,0,0,0.1,0c0.1,0,0.2,0,0.3,0
				c0.1,0,0.2,0,0.3,0c0,0,0,0,0.1,0c0.5,0,0.9-0.5,0.9-1c0-0.1-0.2-4.5-0.3-8.5c0-0.3,0-0.5,0-0.7c0-1.9,0.1-3.6,0.3-4.7
				c0.1-0.4,0.1-0.7,0.1-1.1c0.2-1.7,0.4-3.5,3-5.7c4.3-3.5,6.6-5.5,6.7-5.5c0.4-0.3,0.5-0.9,0.1-1.4
				C641.2,427.9,640.6,427.8,640.2,428.2z"/>
      <path d="M519.2,380.4c-0.1,0-8.7,1.2-13.5-3c-0.4-0.3-1-0.3-1.4,0.1c-0.3,0.4-0.3,1,0.1,1.4c3.5,3.1,8.6,3.6,11.9,3.6
				c1.8,0,3-0.2,3.1-0.2c0.5-0.1,0.9-0.6,0.8-1.1C520.2,380.7,519.8,380.3,519.2,380.4z"/>
    </g>
  </svg>

</template>

<script>
export default {
  props: {questionary: Object},
  name: "PatientComplaintsWoman",
  data() {
    return {
      sts: [],
      groups: []
    }
  },
  methods: {
    toogleSvgClass(event, name) {
      console.log('toogleSvgClass ' + name)
      if (name && name === 'ast102') {
        this.questionary.painHead = !this.questionary.painHead
      } else if (name && name === 'ast104') {
        this.questionary.painFrontHalfChest = !this.questionary.painFrontHalfChest
      } else if (name && name === 'ast105') {
        this.questionary.painHeart = !this.questionary.painHeart
      } else if (name && name === 'ast106') {
        this.questionary.painChest = !this.questionary.painChest
      } else if (name && name === 'ast107') {
        this.questionary.painCenterSpine = !this.questionary.painCenterSpine
      } else if (name && name === 'ast108') {
        this.questionary.painBack = !this.questionary.painBack
      } else if (name && name === 'ast109') {
        this.questionary.painUpAbdominal = !this.questionary.painUpAbdominal
      } else if (name && name === 'ast110') {
        this.questionary.painLowAbdominal = !this.questionary.painLowAbdominal
      } else if (name && name === 'ast111') {
        this.questionary.painLowBack = !this.questionary.painLowBack
      } else if (name && name === 'ast112') {
        this.questionary.painMuscle = !this.questionary.painMuscle
      } else if (name && name === 'ast113') {
        this.questionary.painJoint = !this.questionary.painJoint
      }
      this.selectGroups(this.questionary)
    },
    selectGroups(questionary) {

      if (questionary.painMuscle || questionary.painJoint) {
        if (!this.groups.includes('10'))
          this.groups.push('10')
      } else {
        if (this.groups.includes('10'))
          this.groups.splice(this.groups.indexOf('10'), 1)
      }
      if (questionary.painLowBack) {
        if (!this.groups.includes('9'))
          this.groups.push('9')
      } else {
        if (this.groups.includes('9'))
          this.groups.splice(this.groups.indexOf('9'), 1)
      }
      if (questionary.painLowAbdominal || questionary.painUpAbdominal) {
        if (!this.groups.includes('8'))
          this.groups.push('8')
      } else {
        if (this.groups.includes('8'))
          this.groups.splice(this.groups.indexOf('8'), 1)
      }
      if (questionary.painHead
          || questionary.painFrontHalfChest
          || questionary.painHeart
          || questionary.painChest
          || questionary.painCenterSpine
          || questionary.painBack) {
        if (!this.groups.includes('6'))
          this.groups.push('6')
      } else {
        if (this.groups.includes('6'))
          this.groups.splice(this.groups.indexOf('6'), 1)
      }

      console.log('select-group ' + this.groups)

      this.$emit('select-group', this.groups, false)
    }
  }
}
</script>

<style scoped>
.st100{fill:#D1D3D4;}
.st101{fill:#F1F2F2;}
.st102{fill:#59C0DB;} /* голова */
.st103{fill:#6D6E71;}
.st104{fill:#CCCCCB;} /* передняя половина грудной клетки */
.st105{fill:#D62D2C;} /* под лопаткой справа сердце */
.st106{fill:#6D331F;} /* грудина*/
.st107{fill:#147A70;} /* шейный и грудной отдел */
.st108{fill:#72469A;} /* боль со спины */
.st109{fill:#F7D443;} /* верхний отдел живота 2 groupId = 8 */
.st110{fill:#2B608A;} /* нижний отдел живота 2 groupId = 8 */
.st111{fill:#F37951;} /* поясница 3 groupId = 9 */
.st112{fill:#3E9344;} /* мышцы 4 groupId = 10 */
.st113{fill:#EEC0B1;} /* суставы 4 groupId = 10  */
.st114{fill:#58595B;}
.st115{fill:#FFFFFF;}


.svglink{ opacity:0.1; transition: .5s opacity; cursor: pointer; }
.svglinkActive{ opacity:1; transition: .5s opacity; cursor: pointer;}
.svglink:hover {opacity: 1;}

</style>