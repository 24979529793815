<template>
    <div>Проект Лаб</div>
</template>

<script>
  export default {
    name: "Home"
  }
</script>

<style scoped>

</style>