<template>
  <div class="row mx-0 h-100">
    <img src="@/assets/images/BAnner2.png" class="w-100">
<!--    <CabinetSidebar></CabinetSidebar>-->

    <div class="col-12">
      <div class="row mx-0">
        <div class="col-12">
          <div class="row border-bottom pb-2">
            <div class="col-12 col-lg-7 col-md-6 pt-4 text-center text-md-left">
              <img src="@/assets/images/Group 141.png" class="pb-2">
              <span class="pl-2 patients">Личные данные</span>
            </div>
            <div class="col-12 col-lg-5 col-md-6 pt-4 ">
              <div class="d-flex justify-content-end">
                <div class="col-7 col-md-8 pt-2 text-md-right px-0 account_expiration_date"><span> Срок действия аккаунта: </span></div>
                <div class="col-5 col-md-4 text-right date_days" ><span v-if="doctor && doctor.activationDaysCount"> {{ doctor.activationDaysCount }} дней </span></div>
              </div>
            </div>

          </div>
        </div>
        <div class="col-12">
          <form @submit.prevent="onSubmit()">

            <div class="col-12 ">
              <div class="row  ">
                <div class="d-flex border-bottom col-8 py-3">
                  <div class="col-3 "><span class="personal_date_page_3_left">Фамилия / Имя / Отчество</span></div>
                  <div class="col-8 ">
                    <div class="password edit-container ">
                      <input   class="edit-container__input-text doctor-info bg-white" v-model="fullname" autocomplete="off">
                    </div>
                  </div>
                  <div class="col-1 ">
                    &nbsp;
                  </div>
                </div>
                <div class="d-flex border-bottom col-4  py-3">
                  <div class="col-3 "><span class="personal_date_page_3_left" >Регион</span></div>
                  <div class="col-9">
                    <div class="password edit-container">
                      <!--            <b-form-select id="regionCode" v-model="patient.regionCode" :options="regionOptions | mainRussiaRegions" :select-size="1"></b-form-select>-->
                      <select  class="edit-container__input-text edit-container__select doctor-info " v-model="doctor.regionCode">
                        <option v-for="item of mainRussiaRegionFunction(regionOptions)" :key="item.value" :value="item.value">{{item.text}}</option>
                      </select>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row ">
                <div class="d-flex border-bottom col-8 py-3">
                  <div class="col-3 "><span  class="personal_date_page_3_left">Контактный телефон</span></div>
                  <div class="col-8">
                    <div class="password edit-container">
                      <input v-model="doctor.phoneNumber" autocomplete="off" v-mask="'+ # (###) ###-##-##'"  placeholder="+ 7 (999) 999-99-99"  class=" bg-white edit-container__input-text  doctor-info">
                    </div>
                  </div>
                  <div class="col-1 ">
                    &nbsp;
                  </div>
                </div>
                <div class="d-flex border-bottom col-4 py-3">
                  <div class="col-3 "><span  class="personal_date_page_3_left">E-mail</span></div>
                  <div class="col-9 ">
                    <div class="password edit-container">
                      <input v-model="doctor.email" autocomplete="off" class="edit-container__input-text doctor-info bg-white">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row ">
                <div class="d-flex border-bottom col-12 py-3">
                  <div class="col-2 "><span class="personal_date_page_3_left" >Специализация</span></div>
                  <div class="col-10   py-3">
                    <span class="perosnal_date_page_3_center">
                      <treeselect name="specializations" id="specializations" placeholder=""

                                multiple clearable searchable
                                open-on-click disableFuzzyMatching
                                :noResultsText="'Не найдено...'"
                                :options="specializationOptions"
                                :max-height="200"
                                :normalizer="normalizeSpecializations"
                                v-model="specializations" />
                    </span>


                  </div>
                </div>
              </div>
              <div class="row ">
                <div class="d-flex border-bottom col-12  py-3">
                  <div class="col-2 ">
                  <span class="personal_date_page_3_left">
                    Медицинское учреждение <br>
                    (Выберите медицинское учреждение или создайте новое)
                  </span>
                  </div>
                  <div class="col-7 py-3 ">
                    <span class="perosnal_date_page_3_center">
                      <treeselect name="clinics" id="clinics" placeholder=""
                                  multiple clearable searchable
                                  open-on-click disableFuzzyMatching
                                  :noResultsText="'Не найдено...'"
                                  :options="clinicOptions"
                                  :max-height="200"
                                  :normalizer="normalizeClinics"
                                  v-model="clinics" />
                    </span>
                  </div>
                  <div class="col-3 py-3 text-right">
                    <span class="perosnal_date_page_3_center">
                      <button class="save_btn_common w-100" @click="addClinic()">Создать медицинское учреждение</button>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center pt-4">
              <button type="submit" class="border-0 save_btn_page_5">
							<span class="text_save_page_5">Сохранить
							</span>
                <b-spinner v-if="saving" variant="light" small></b-spinner>
              </button>
            </div>

          </form>



          <div class="border-bottom d-flex border-top mt-5">
            <div class="col-6 border_right_page_5">
              <div class="d-lg-flex  py-3 px-3 justify-content-lg-between ">
                <div><span class="personal_date_page_3_left">Дата активации аккаунта</span></div>
                <div class="px-lg-5"><span class="perosnal_date_page_3_center">{{ doctor.activationDate }}</span></div>
              </div>
            </div>
            <div class="col-6">
              <div class="d-lg-flex  py-3 px-3 justify-content-lg-between ">
                <div class="px-lg-5"><span class="personal_date_page_3_left">Дата окончания активации</span></div>
                <div><span class="perosnal_date_page_3_center">{{ doctor.activationEndDate }}</span></div>
              </div>
            </div>
          </div>

          <div class="my-5 py-4">
          </div>

        </div>
      </div>
    </div>

    <b-modal id="specializationModal" hide-footer size="lg" title="Специализация">
      <SpecializationEdit @save-success="afterSpecializationSave" v-bind:error="error" ></SpecializationEdit>
    </b-modal>

    <b-modal id="clinicModal" hide-footer size="lg" title="Клиника">
      <ClinicEdit @save-success="afterClinicSave" v-bind:error="error" ></ClinicEdit>
    </b-modal>
  </div>
</template>

<script>
import CabinetSidebar from "@/components/CabinetSidebar";
import {host} from "@/constants";
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import SpecializationEdit from "@/components/admin/doctors/SpecializationEdit";
import ClinicEdit from "@/components/admin/doctors/ClinicEdit";
export default {
  name: "DoctorInfo",
  components: {CabinetSidebar, Treeselect, SpecializationEdit, ClinicEdit},
  data() {
    return {
      error: null,
      saving: false,
      loading: false,
      regionOptions: [],
      fullname: '',
      doctor: {},
      clinics: [],
      specializations: [],
      clinicOptions: [],
      specializationOptions: [],
      normalizeClinics(node) {
        return {
          id: node.value,
          label: node.text
        }
      },
      normalizeSpecializations(node) {
        return {
          id: node.value,
          label: node.text
        }
      }
    }
  },
  methods: {
    addClinic() {
      this.$bvModal.show('clinicModal')
    },
    afterClinicSave() {
      this.$bvModal.hide('clinicModal')
      this.loading = true
      this.fetchDictsData()
    },
    addSpecialization() {
      this.$bvModal.show('specializationModal')
    },
    afterSpecializationSave() {
      this.$bvModal.hide('specializationModal')
      this.loading = true
      this.fetchDictsData()
    },
    async onSubmit() {
      this.saving = true
      let method = 'PUT'

      let firstIndex = this.fullname.indexOf(' ')
      console.log('firstIndex = ' + firstIndex)
      if (firstIndex < 0) {
        this.doctor.lastname = this.fullname
      } else {
        this.doctor.lastname = this.fullname.substring(0, firstIndex)
        let secondIndex = this.fullname.indexOf(' ', firstIndex + 1)
        console.log('secondIndex = ' + secondIndex)
        if (secondIndex < 0) {
          this.doctor.firstname = this.fullname.substring(firstIndex + 1)
        } else {
          this.doctor.firstname = this.fullname.substring(firstIndex + 1, secondIndex)
          this.doctor.middlename = this.fullname.substring(secondIndex + 1)
        }
      }


      let rs = await fetch(host + '/rest/doctors', {
        method: method,
        credentials: 'include',
        body: JSON.stringify(this.doctor),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      })
      let json = await rs.json()

      let localDocId = this.doctor.doctorId
      // if (!this.doctor.doctorId) {
      //   localDocId = json.entityId
      // }



      if (json.resultCode != 0) {
        this.$errorToast(json.resultCode + ' ' + json.message)
      } else {
        json = await this.updateDoctorClinics(localDocId)
        if(json) {
          this.$errorToast(json.resultCode + ' ' + json.message)
        }
        json = await this.updateDoctorSpecializations(localDocId)
        if(json) {
          this.$errorToast(json.resultCode + ' ' + json.message)
        }
        this.$messageToast('Личные данные')
      }

      this.saving = false

    },
    async updateDoctorClinics(localDocId) {
      let rs = await fetch(host + '/rest/doctors/' + localDocId + '/clinics', {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify({doctorClinics:this.clinics}),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      })
      let json = await rs.json()

      if (json.resultCode != 0) {
        //this.raiseError(json)
        return json
      }

      return null
    },
    async updateDoctorSpecializations(localDocId) {
      let rs = await fetch(host + '/rest/doctors/' + localDocId + '/specializations', {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify({doctorSpecializations:this.specializations}),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      })
      let json = await rs.json()

      if (json.resultCode != 0) {
        //this.raiseError(json)
        return json
      }

      return null
    },
    mainRussiaRegionFunction(options) {
      let result = []
      for (let o of options) {
        if (o.parentRegionId === 1286 && o.isMain === true) {
          result.push(o)
        }
      }

      return result
    },
    fetchDictsData() {
      fetch(host + '/rest/dicts/region')
          .then(response => response.json())
          .then(json => {
            this.regionOptions = json.options
            console.log(json)
          })

      fetch( host + '/rest/dicts/clinic', {
        credentials: 'include'
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode === 0) {
              this.clinicOptions = json.options
            }
            this.loading = false
          })
      fetch( host + '/rest/dicts/doctorspecialization', {
        credentials: 'include'
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode === 0) {
              this.specializationOptions = json.options
            }
            this.loading = false
          })
    }
  },
  mounted() {
    this.fetchDictsData()


    fetch(host + '/rest/doctors/self', {
      credentials: "include"
    })
        .then(response => response.json())
        .then(json => {

          this.doctor = json.doctor
          this.fullname = this.doctor.lastname + ' ' + this.doctor.firstname + ' ' + this.doctor.middlename
          this.specializations = json.doctorSpecializations
          this.clinics = json.doctorClinics

          console.log(JSON.stringify(this.doctor))
        })
  }
}
</script>

<style scoped>

@import '../assets/css/patient.css';

</style>