import Vue from 'vue'
import Vuex from 'vuex'
import analitList from "./module/analitList";
import doctors from "@/store/module/doctors";
import auth from "@/store/module/auth";
import checklist from "@/store/module/checklist";
import diseaseValueList from "@/store/module/diseaseValueList";
import patientList from "@/store/module/patientList";

Vue.use(Vuex)

export default new Vuex.Store({


  modules: {
    analitList, doctors, auth, checklist, diseaseValueList, patientList
  }
})
