<template>
  <b-container fluid>
    <b-row>
      <h3>Элементы интерпретации</h3>
    </b-row>
    <b-tabs v-model="tabIndex" content-class="mt-3" nav-class="font-weight-bold">
      <b-tab title="Текстовые отчеты">
        <ZoneValueList/>
      </b-tab>
      <b-tab title="Подробный отчет" >
        <ZoneReportPhrases v-bind:report-type="1" />
      </b-tab>
      <b-tab title="Обобщенный отчет" >
        <ZoneReportPhrases v-bind:report-type="2" />
      </b-tab>
      <b-tab title="Сокращенный отчет" >
        <ZoneReportPhrases v-bind:report-type="3" />
      </b-tab>

      <b-tab title="Графический отчет пациента" >
        <ZoneReportPhrases v-bind:report-type="4" />
      </b-tab>
      <b-tab title="Графический отчет врача" >
        <ZoneReportPhrases v-bind:report-type="5" />
      </b-tab>
<!--      <b-tab title="Патологии графических отчетов" >-->
<!--        <ZoneDiseaseReportPhrases />-->
<!--      </b-tab>-->
    </b-tabs>
  </b-container>
</template>

<script>
import ZoneValueList from "./ZoneValueList";
import ZoneReportPhrases from "./ZoneReportPhrases";
import ZoneDiseaseReportPhrases from "./ZoneDiseaseReportPhrases"
export default {
  name: "ZoneTabs",
  data() {
    return {
      tabIndex: 0
    }
  },
  components: {ZoneReportPhrases, ZoneValueList, ZoneDiseaseReportPhrases},
}
</script>

<style scoped>

</style>