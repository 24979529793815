<template>
  <b-container>
    <b-row v-if="chartData && chartData.datasets && chartData.datasets[0] && chartData.datasets[0].data && chartData.datasets[0].data.length > 1">
      <LineChartGenerator
          :chart-options="chartOptions"
          :chart-data="chartData"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
      />
    </b-row>
    <b-row v-if="chartData && chartData.datasets && chartData.datasets[0] && chartData.datasets[0].data && chartData.datasets[0].data.length === 1">
      <BarChartGenerator

          :chart-data="chartData"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
      />
    </b-row>
    <b-row>
      <img width="100%" src="@/assets/images/Shkala.png">
    </b-row>
  </b-container>
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import { Bar as BarChartGenerator} from 'vue-chartjs/legacy'
import {host} from '@/constants'
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement
} from 'chart.js'

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    ChartDataLabels
)




export default {
  name: "DynamicInfoChart",
  components: {
    LineChartGenerator, BarChartGenerator
  },
  props: {
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    patientId: {
      type: String,
      default: '0'
    },
    paramId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      width: 400,
      height: 400,
      reportParams:[],
      chartData: {
        labels: [
        ],
        datasets: [
          {
            label: '',
            backgroundColor: '#f87979',
            pointRadius: 9,
            // pointBackgroundColor: function(context) {
            //   var index = context.dataIndex;
            //   var result = '#000000';
            //
            //   return result  // draw negative values in red
            //
            // },
            data: [],
            rawData:[]
          }
        ]
      },
      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          // Change options for ALL labels of THIS CHART
          datalabels: {
            color: '#505050',
            font: {
              weight: 'bold'
            },
            anchor: 'start'
          }
        },
        scales: {
          y: {
            min: 0
          }
        }
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          // Change options for ALL labels of THIS CHART
          datalabels: {
            color: '#505050',
            font: {
              weight: 'bold'
            },
            offset: 12,
            anchor: 'start',
            align: 'top'
          }
        },
        scales: {
          y: {
            min: 10,
            max: 50,
          }
          // ,
          // x: {
          //   min: new Date(),
          //   max: new Date()
          // }
        }
      }
    }
  },
  methods: {
    fetchData() {
      fetch(host + '/rest/patients/' + this.patientId + '/params/' + this.paramId + '/exams', {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            let localChartData = {
              labels: [
              ],
                datasets: [
                  {
                    label: '',
                    backgroundColor: function(context) {
                      var index = context.dataIndex;
                      console.log('Print dataset'  + JSON.stringify(context.dataset))
                      var result = '#AAAAAA';

                      var value = context.dataset.rawData[index];
                      if (value.zoneId == 6 || value.zoneId == 16 || value.zoneId == 19  || value.zoneId == 25) {
                        //Оптимум
                        result = '#2eb671'
                      } else if (value.zoneId == 5 || value.zoneId == 7 || value.zoneId == 17 || value.zoneId == 26) {
                        //Тенденция к повышению/понижению
                        result = '#76c695'
                      } else if (value.zoneId == 4 || value.zoneId == 8 || value.zoneId == 23) {
                        //Незначительное повышение/понижение
                        result = '#f5b535'
                      } else if (value.zoneId == 3 || value.zoneId == 9 || value.zoneId == 22) {
                        //Заметное
                        result = '#ed7b23'
                      } else if (value.zoneId == 2 || value.zoneId == 10 || value.zoneId == 18 || value.zoneId == 20 || value.zoneId == 21) {
                        //Значительное
                        result = '#e62d25'
                      } else if (value.zoneId == 1 || value.zoneId == 11) {
                        //Критическое
                        result = '#9a1b1f'
                      } else {
                        result = '#AAAAAA'
                      }
                      return result  // draw negative values in red

                    },
                    pointRadius: 9,
                    pointBackgroundColor: function(context) {
                      var index = context.dataIndex;
                      console.log('Print dataset'  + JSON.stringify(context.dataset))
                      var result = '#000000';

                      var value = context.dataset.rawData[index];
                      if (value.zoneId == 6 || value.zoneId == 16 || value.zoneId == 19  || value.zoneId == 25) {
                        //Оптимум
                        result = '#2eb671'
                      } else if (value.zoneId == 5 || value.zoneId == 7 || value.zoneId == 17 || value.zoneId == 26) {
                        //Тенденция к повышению/понижению
                        result = '#76c695'
                      } else if (value.zoneId == 4 || value.zoneId == 8 || value.zoneId == 23) {
                        //Незначительное повышение/понижение
                        result = '#f5b535'
                      } else if (value.zoneId == 3 || value.zoneId == 9 || value.zoneId == 22) {
                        //Заметное
                        result = '#ed7b23'
                      } else if (value.zoneId == 2 || value.zoneId == 10 || value.zoneId == 18 || value.zoneId == 20 || value.zoneId == 21) {
                        //Значительное
                        result = '#e62d25'
                      } else if (value.zoneId == 1 || value.zoneId == 11) {
                        //Критическое
                        result = '#9a1b1f'
                      } else {
                        result = '#000000'
                      }


                      return result  // draw negative values in red

                    },
                    data: [],
                    rawData:[]
                  }
                ]
            }
            let minVal = 999999, maxVal = 0//, index = 0, rpLength = json.reportParams.length
            //let minX = new Date(), maxX = new Date()

            for (let p of json.reportParams) {
              localChartData.labels.push(p.examinationDate)
              localChartData.datasets[0].data.push(p.paramValue)
              localChartData.datasets[0].label = p.measurementUnitName
              if (p.paramValue < minVal) {
                minVal = p.paramValue
              }
              if (p.paramValue > maxVal) {
                maxVal = p.paramValue
              }
              // if (index == 0) {
              //   minX = this.addDays(p.examinationDate, -1)
              // }
              // if (index == rpLength -1) {
              //   maxX = this.addDays(p.examinationDate, 1)
              // }
              // index++
            }
            if (localChartData.datasets[0].data.length > 1) {
              localChartData.datasets[0].backgroundColor = null
            }
            localChartData.datasets[0].rawData = json.reportParams
            if (json.reportParams && json.reportParams.length > 10) {

              this.width = 200 + json.reportParams.length * 20
              console.log('width ' + this.width)
            }
            this.chartData = localChartData
            if (minVal >= 0 && (minVal - ((maxVal - minVal) * 0.1)) < 0 ) {
              this.chartOptions.scales.y.min = 0
            } else {
              this.chartOptions.scales.y.min = minVal - ((maxVal - minVal) * 0.1)
            }

            this.chartOptions.scales.y.max = maxVal + ((maxVal - minVal) * 0.1)
            // this.chartOptions.scales.x.min = minX
            // this.chartOptions.scales.x.max = maxX
            this.loading = false
            console.log(JSON.stringify(json))
          }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    addDays(date, days) {
      //var date = new Date(this.valueOf());
      console.log(date)
      let curDate = new Date()
      curDate.setTime(Date.parse(date))
      console.log(curDate)
      curDate.setDate(curDate.getDate() + days);
      return this.$printDate(curDate);
    }

  },
  mounted() {
    this.fetchData()
  }
}
</script>

<style scoped>

</style>