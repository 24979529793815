<template>
  <b-container fluid>
    <b-button variant="info" @click="editRubricatorValue()"><b-icon icon="plus" aria-hidden="true" ></b-icon> Добавить</b-button>
    <div class="accordion" role="tablist">
      <b-card no-body class="mb-1" v-for="prop in properties" :key="prop.propId">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-container fluid>
            <b-row>
              <b-col cols="10"><b-button block @click="toogleAccordeon(prop)" variant="info">{{ prop.propName }} {{ prop.propCode ? ('(' + prop.propCode + ')') : ''}}</b-button></b-col>
              <b-col><b-button variant="outline-info" @click="editRubricatorValue(prop)" title="Редактировать"><b-icon icon="pencil" aria-hidden="true" ></b-icon></b-button></b-col>
              <b-col><b-button variant="outline-info" @click="deleteRubricatorValue(prop.propId, prop.propName)" title="Удалить"><b-icon icon="x-circle" aria-hidden="true" ></b-icon></b-button></b-col>
            </b-row>
          </b-container>
        </b-card-header>
        <b-collapse :id="'accordion-' + prop.propId" accordion="propsAccordion" role="tabpanel">
          <b-card-body>
            <AnalitPropertyValues v-bind:analit-id="analitId" v-bind:rubr="prop" v-bind:param-type="paramType"></AnalitPropertyValues>
          </b-card-body>
        </b-collapse>
      </b-card>


    </div>

    <b-modal id="propEditModal" hide-footer size="lg" :title="'Атрибут'">
      <AnalitPropEdit @save-success="saveCommand" v-bind:error="error" v-bind:analit-id="analitId" v-bind:property="currentProp"></AnalitPropEdit>
    </b-modal>

  </b-container>
</template>

<script>
import AnalitPropEdit from "@/components/admin/analits/AnalitPropEdit";
import {host} from "@/constants";
import AnalitPropertyValues from "@/components/admin/analits/AnalitPropertyValues";

export default {
  components: {AnalitPropertyValues, AnalitPropEdit},
  props: ["analitId", "paramType"],
  name: "AnalitProperties",
  data() {
    return  {
      currentProp: null,
      error: null,
      properties: []
    }
  },
  methods: {
    deleteRubricatorValue(paramId, paramName) {
      this.error = null;
      if (confirm('Вы уверены, что хотите удалить атрибут "' + paramName + '"')) {
        this.loading = true;
        fetch(host + '/rest/params/' + this.analitId + '/properties/' + paramId, {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {
                this.fetchData()

                console.log('Delete Success ' + json)

              } else {

                console.log('Delete Failed ' + json)
                this.error = json.message;
              }
              this.loading = false

            }).catch(error => {
          this.error = error
          console.log(JSON.stringify(error))
          this.loading = false
        })
      }
    },

    toogleAccordeon(prop) {
      if (prop == null) return
      this.$root.$emit('bv::toggle::collapse', 'accordion-' + prop.propId)
    },
    saveCommand( ) {

      this.$bvModal.hide('propEditModal')
      this.fetchData()
    },
    editRubricatorValue(item = null) {
      this.currentProp = item
      this.$bvModal.show('propEditModal')
    },
    fetchData() {
      fetch(host + '/rest/params/' + this.analitId + '/properties', {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.properties = json.properties
          })
    },
  },
  mounted() {
    this.fetchData()
  }
}
</script>

<style scoped>

</style>