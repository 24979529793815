<template>
  <div class="col-12 wrapper">
    <b-button @click="saveCurrentGroupData()" variant="info" :disabled="saving">
      Сохранить изменения
      <b-spinner v-if="saving" variant="light" small></b-spinner>
    </b-button>
    <drop-list
        :items="localParams"
        class="list"
        @reorder="$event.apply(localParams)"
    >
      <template v-slot:item="{item}">
        <drag class="item" :key="item.paramId">{{item.paramName}}, {{item.biomaterialTypeName}}</drag>
      </template>
      <template v-slot:feedback="{data}">
        <div class="item feedback" :key="data">{{data}}</div>
      </template>
    </drop-list>
  </div>
</template>

<script>
import {Drag, DropList} from "vue-easy-dnd";
import {host} from "@/constants";

export default {
  props: {
    params: Array
  },
  name: "AnalitSortOrder",
  data () {
    return {
      saving: false,
      localParams: []

    }
  },
  components : {
    Drag, DropList
  },
  filters: {
    // filterForDoctor: function (params) {
    //   console.log('filterForDoctor')
    //   return params.filter(el => ((el.hiddenForSearch != true && el.emptyDummy != true && el.workInPractice==true) || (el.hiddenForSearch === true && el.emptyDummy === true)))
    // }
  },
  methods : {
    async saveCurrentGroupData() {

      this.saving = true
      //const groupName = this.currentGroup.text
      let rqData = []
      for (const [i, v] of this.localParams.entries()) {
        rqData.push({ paramId: v.paramId, priorityReport: i})
      }
      // console.log(JSON.stringify(rqData))
      fetch(host + '/rest/params/priority', {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify({params: rqData}),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {
              this.$messageToast('приоритеты аналитов')
            } else {
              this.$errorToast(json.resultCode + ' ' + json.message)
            }
            this.saving=false
          })
          .catch(error => {
            this.$errorToast(json.resultCode + ' ' + json.message)
            this.saving=false
          })
    },
    compare( a, b ) {
      //console.log('a ' + a.priorityReport + ', b ' + b.priorityReport)
      if ( a.priorityReport < b.priorityReport ){
        return -1;
      }
      if ( a.priorityReport > b.priorityReport ){
        return 1;
      }
      return 0;
    }


  },
  watch: {
    params: function(newVal, oldVal) { // watch it
      console.log('watchParams' + oldVal)
      this.localParams = newVal.filter(el => ((el.hiddenForSearch != true && el.emptyDummy != true && el.workInPractice==true) || (el.hiddenForSearch === true && el.emptyDummy === true)))
      this.localParams.sort(this.compare)
    }
  }
}
</script>

<style >
.v-application--wrap,
.v-content,
.v-content__wrap {
  height: 100%;
}

.drop-in {
  box-shadow: 0 0 10px rgba(0, 0, 255, 0.3);
}
</style>

<style scoped >

.wrapper .list {
  border: none;
  margin: 2rem auto;
  width: 100%;

}
.wrapper .list .item {
  padding: 0.3rem;
  margin: 1rem;
  color: rgb(23, 162, 184);
  border: 1px solid rgb(23, 162, 184);
  border-radius: 3px;
  font-weight: bold;
  background-color: rgb(255,255,255);
  display: flex;
  align-items: center;
  justify-content: left;
}
.wrapper .list .item .feedback {
  background-color: rgb(255, 220, 220);
  border: 2px dashed black;
}

.wrapper .list .item .drag-image {
  background-color: rgb(220, 255, 220);
  transform: translate(-50%, -50%);
}
</style>