<template>
    <b-modal v-model="showLoader" hide-footer hide-header visible no-close-on-backdrop centered size="sm" content-class="loaderModal">
      <div class="lds-component">
        <div class="lds-dual-ring"></div>
      </div>
    </b-modal>
</template>

<script>
export default {
  props: ['showLoader'],
  name: "Loader"

}
</script>

<style >
  .loaderModal{
    background-color: transparent!important;
    border: 0px!important;

  }
  .lds-component {
    background-color: rgba(255,255,255, 0.8) ;
    border: 0px solid;
    border-radius: 0.3rem;
    width: 90px;
    height: 90px;
    padding: 5px;
  }
  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    align-content: center;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #17a2b8;
    border-color: #17a2b8 transparent #17a2b8 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>