<template>
  <form @submit.prevent="updateSettings">
    <b-container fluid>

      <b-row>
        <b-col cols="4">
          <b-form-group label="А для уровня связи 1">
            <b-form-input required type="number" v-model="reportConfig.coefA1"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="А для уровня связи 2">
            <b-form-input required type="number" v-model="reportConfig.coefA2"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="А для уровня связи 3">
            <b-form-input required type="number" v-model="reportConfig.coefA3"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="reportType == '5'">
        <b-col cols="4">
          <b-form-group label="Максимальное число родительских состояний">
            <b-form-input required type="number" v-model="reportConfig.coefU"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="8">
          &nbsp;
        </b-col>
      </b-row>
      <b-row v-if="reportType !== '5'">
        <b-col cols="4">
          <b-form-group label="К - минимальный балл для списка ПС">
            <b-form-input required type="number" v-model="reportConfig.coefK"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="N - число рекомендованных аналитов">
            <b-form-input required type="number" v-model="reportConfig.coefN"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="T - минимальный балл для списка рекомендаций">
            <b-form-input required type="number" v-model="reportConfig.coefT"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b-form-group label="Коэффициент корректировки Cmax">
            <b-form-input required type="number" v-model="reportConfig.cMaxModification"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group v-if="reportType !== '5'" label="J2 - число частых патологий в списке">
            <b-form-input required type="number" v-model="reportConfig.coefJ2"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group  v-if="reportType !== '5'" label="J3 - число редких патологий в списке">
            <b-form-input required type="number" v-model="reportConfig.coefJ3"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b-form-group label="M для количественных аналитов - макс. значение коэффициента β">
            <b-form-input required type="number" v-model="reportConfig.coefM1"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="М для качественных аналитов - макс. значение коэффициента β">
            <b-form-input required type="number" v-model="reportConfig.coefM2"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="М для полуколичественных аналитов - макс. значение коэффициента β">
            <b-form-input required type="number" v-model="reportConfig.coefM3"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="reportType !== '5'">
        <b-col cols="4">
          <b-form-group label="Число лекарств в списке">
            <b-form-input required type="number" v-model="reportConfig.coefDrugCount"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Число лабораторных ошибок в списке">
            <b-form-input required type="number" v-model="reportConfig.coefLaboratoryErrorCount"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Число факторов риска в списке">
            <b-form-input required type="number" v-model="reportConfig.coefRiskFactorCount"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-button type="submit" variant="info">Сохранить<b-spinner v-if="saving" variant="light" small></b-spinner></b-button>
      </b-row>
    </b-container>
  </form>
</template>

<script>
import {host} from "@/constants";

export default {
  name: "ReportSettings",
  props: ['reportType'],
  data() {
    return {
      saving: false,
      reportConfig : {
        coefA1: 0,
        coefA2: 0,
        coefA3: 0,
        coefK: 0,
        coefN: 0,
        coefT: 0,
        coefJ2: 0,
        coefM1: 0,
        coefM2: 0,
        coefM3: 0,
        coefDrugCount: 0,
        coefLaboratoryErrorCount: 0,
        coefRiskFactorCount: 0,
        cMaxModification: 0,
        coefJ3: 0,
        coefU: 0
      }
    }
  },
  methods: {
    fetchData() {
      fetch(host + '/rest/reports/settings/' + this.reportType, {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.reportConfig = json.reportConfig
          }).catch(error => {
        console.log(error)
      })

    },
    async updateSettings() {
      this.saving = true
      try {
        let response = await fetch(host + '/rest/reports/settings/' + this.reportType, {
          credentials: "include",
          method: 'PUT',
          body: JSON.stringify(this.reportConfig),
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        })
        let json = await response.json()
        if (json.resultCode === 0) {
          this.$messageToast('Настройки отчётов')

        } else {
          this.$errorToast(json.resultCode + ' ' + json.message)
        }
        this.saving = false
      } catch (e) {
        this.saving = false
      }
    }
  },
  mounted () {
    this.fetchData()
  }
}
</script>

<style scoped>

</style>