import {host} from "@/constants";

export default {
  actions: {
    async fetchDoctors(ctx) {
      const response = await fetch(host + '/rest/doctors', {
        credentials: "include"
      })
      const json = await response.json()

      ctx.commit('updateDoctors', json.doctors)
    }
  },
  mutations: {
    updateSearchDoctorRequest(state, searchDoctorRequest) {
      state.searchDoctorRequest = searchDoctorRequest
    },
    updateDoctors(state, doctors) {
      state.doctors = doctors
    }
  },
  state: {
    searchDoctorRequest: '',
    doctors: []
  },
  getters: {
    searchDoctorRequest(state) {
      return state.searchDoctorRequest
    },
    doctors(state) {
      return state.doctors
    },
    doctor: (state) => (doctorId) =>  {
      let result = state.doctors.find(item => item.doctorId == doctorId)
      if (!result ) {
        result = {
          firstname: '',
          middlename: '',
          lastname: '',
          genderCode: 1,
          regionCode: 6611,
          password: '',
          phoneNumber: '',
          email: '',
          activationDate: new Intl.DateTimeFormat('ru-RU').format(new Date()),
          activationDaysCount: null,
          doctorComment: '',
          reportDetailedBlock: true,
          reportGeneralBlock: false,
          reportConciseBlock: false,
          reportPatientBlock: false,
          reportGraphicBlock: false,
          questionaryBlock: false,
          paramPrescribedBlock: false,
          reportConfigBlock: true,
          showCoefDReportGeneral: true,
          showCoefDReportConcise: true,

          questionaryTabGeneralEnable: true,
          questionaryTabReproductEnable: true,
          questionaryTabComplaintsEnable: true,
          questionaryTabChronicEnable: true,
          questionaryTabDrugEnable: true,
          questionaryTabHeredityEnable: true,
          reportPreBlock: false,
          alternativeScaleBlock: true,
          onlyOMSParams: false,

          reportDetailedSubType: 10,
          reportGeneralSubType: 20,
          reportConciseSubType: 30,
          reportPatientSubType: 40,
          reportGraphicSubType: 50,
          debugDoctor: false,
          reportParamRecommendBlock: false,
          reportParamRecommendMetabolomicBlock: false

        }
      }
      return result
    }
  }
}