<template>
  <div>
    <div v-if="error" class="alert alert-danger">
      {{error}}
    </div>
    <b-form @submit.prevent="onSubmit">

      <b-form-group id="ig2" label-for="paramValue" label="Название шкалы">
        <b-textarea id="paramValue" required rows="3" v-model="mu.text" autocomplete="off"></b-textarea>
      </b-form-group>

<!--      <b-row class="newAttr">-->
<!--        <b-col>Фраза для отчета врача:</b-col>-->
<!--      </b-row>-->
<!--      <b-row>-->
<!--        <b-col>-->
<!--          <ckeditor v-model="mu.reportText"-->
<!--                    :config="editorConfig"></ckeditor>-->
<!--        </b-col>-->

<!--      </b-row>-->

      <b-button type="submit" class="btn-info" variant="primary">Сохранить</b-button>

    </b-form>
  </div>
</template>

<script>
import {host} from "@/constants";

export default {
  name: "ScaleRangeValueEdit",
  props: {
    currentMeasurementUnit: Object
  },
  data() {
    return {
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        // The configuration of the editor.
      },
      error: null,
      mu: {
        value: this.currentMeasurementUnit !== null ? this.currentMeasurementUnit.value : null,
        text: this.currentMeasurementUnit !== null ? this.currentMeasurementUnit.text : null
        //reportText: this.currentMeasurementUnit !== null ? this.currentMeasurementUnit.reportText : null
      }
    }
  },
  methods: {
    onSubmit() {
      let method = 'POST'
      if (this.currentMeasurementUnit !== null) {
        method = 'PUT'
      }


      fetch(host + '/rest/scaleranges', {
        method: method,
        credentials: 'include',
        body: JSON.stringify(this.mu),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {

              this.$emit('save-success')
            } else {

              this.error = json.resultCode + ' ' + json.message;
            }
          })
          .catch(error => {
            this.error = error

          })

    }
  }
}
</script>

<style scoped>
.ck-content {
  height: 200px;
}

.newAttr {
  padding-top: 1rem;
}
</style>
