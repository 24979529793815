<template>
  <div>
    <div v-if="error" class="alert alert-danger">
      {{error}}
    </div>
    <b-form @submit.prevent="onSubmit">

      <b-form-group id="ig2" label-for="paramValue" label="Название специализации">
        <b-form-input id="paramValue" required v-model="mu.doctorSpecializationName" type="text" autocomplete="off"></b-form-input>
      </b-form-group>



      <b-button type="submit" class="btn-info" variant="primary">Сохранить</b-button>

    </b-form>
  </div>
</template>

<script>
import {host} from "@/constants";

export default {
  name: "SpecializationEdit",
  props: {
    currentMeasurementUnit: Object
  },
  data() {
    return {
      error: null,
      mu: {
        doctorSpecializationId: this.currentMeasurementUnit  ? this.currentMeasurementUnit.doctorSpecializationId : null,
        doctorSpecializationName: this.currentMeasurementUnit  ? this.currentMeasurementUnit.doctorSpecializationName : null
      }
    }
  },
  methods: {
    onSubmit() {
      let method = 'POST'
      if (this.currentMeasurementUnit) {
        method = 'PUT'
      }


      fetch(host + '/rest/specializations', {
        method: method,
        credentials: 'include',
        body: JSON.stringify(this.mu),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {

              this.$emit('save-success')
            } else {

              this.error = json.resultCode + ' ' + json.message;
            }
          })
          .catch(error => {
            this.error = error

          })

    }
  }
}
</script>

<style scoped>
.ck-content {
  height: 200px;
}

.newAttr {
  padding-top: 1rem;
}
</style>

