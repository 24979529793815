export default {
  actions: {},
  mutations: {
    updateCheckListSearchRequest(state, searchRequest) {
      state.checkListSearchRequest = searchRequest
    }
  },
  state: {
    checkListSearchRequest: ''
  },
  getters: {
    checkListSearchRequest(state) {
      return state.checkListSearchRequest
    }
  }
}