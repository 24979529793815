export default {
  actions: {},
  mutations: {
    updateSearchRequest(state, searchRequest) {
      state.searchRequest = searchRequest
    },
    updateParamTypeFilter(state, paramTypeFilter) {
      state.paramTypeFilter = paramTypeFilter
    }

  },
  state: {
    searchRequest: '',
    paramTypeFilter: ''
  },
  getters: {
    searchRequest(state) {
      return state.searchRequest
    },
    paramTypeFilter(state) {
      return state.paramTypeFilter
    }
  }
}