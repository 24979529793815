<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <b-button variant="info" @click="editRule()">Создать правило</b-button>
        <br /><br />
        <div v-if="error" class="alert alert-danger">
          {{error}}
        </div>
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon variant="info" icon="search" size="lg"></b-icon>
          </b-input-group-prepend>
          <b-form-input type="text" v-model="searchRequest" autocomplete="off"></b-form-input>
        </b-input-group>
        <b-table striped hover :items="items | filterItems(searchRequest)" :fields="fields" ref="measurementUnitTable">
          <!-- A custom formatted column -->
          <template v-slot:cell(value)="data">
            <b>{{ data.item.value }}</b>
          </template>
          <template v-slot:cell(text)="data">
            <span v-html="data.item.text"></span>
          </template>
          <template v-slot:cell(buttons)="data">
            <h3>
              <nobr>
                <b-link @click="editRule(data.item)" ><b-icon variant="info" icon="pencil" size="lg" ></b-icon></b-link>
                &nbsp;
                <b-link @click="deleteRule(data.item.ruleId, data.item.ruleName)"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link>
              </nobr>
            </h3>
          </template>
        </b-table>
        <b-modal id="ruleModal" hide-footer size="lg" title="Правило">
          <RuleEdit @save-success="afterSave" v-bind:error="error" v-bind:current-measurement-unit="currentMeasurementUnit"></RuleEdit>
        </b-modal>
        <Loader v-bind:showLoader="loading" />
      </div>
    </div>
  </div>
</template>

<script>
import RuleEdit from '@/components/admin/rules/RuleEdit'
import Loader from '@/components/Loader'
import {host} from '@/constants'

export default {
  name: "RuleList",
  components: {
    Loader, RuleEdit
  },
  filters: {
    filterItems: function (items, searchRequest) {
      if (searchRequest !== null && searchRequest !== "" && searchRequest.length > 2 ) {
        return items.filter((el) => ( ( el.ruleName && (el.ruleName.toLowerCase()).includes(searchRequest.toLowerCase()) )
        ) )
      }
      return items
    }
  },
  data() {
    return {
      fields: [
        {
          key: 'ruleId',
          label: 'Id',
          sortable: true
        },
        {
          key: 'ruleName',
          label: 'Наименование',
          sortable: true
        },
        {
          key: 'createDate',
          label: 'Дата создания',
          sortable: true
        },
        {
          key: 'modifyDate',
          label: 'Дата изменения',
          sortable: true
        },
        {
          key: 'buttons',
          label: ' ',
          sortable: false
        }
      ],
      items: [],
      loading: true,
      error: null,
      currentMeasurementUnit: null,
      searchRequest: null
    }
  },
  methods: {
    deleteRule(paramId, paramName) {
      this.error = null;
      if (confirm('Вы уверены, что хотите удалить единицу правило "' + paramName + '"')) {
        this.loading = true;
        fetch(host + '/rest/warehouse/rules/' + paramId, {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {
                this.items = this.items.filter(item => item.ruleId !== paramId)

                console.log('Delete Success ' + json)

              } else {

                console.log('Delete Failed ' + json)
                this.error = json.message;
              }
              this.loading = false

            }).catch(error => {
          this.error = error
          console.log(JSON.stringify(error))
          this.loading = false
        })
      }
    },
    editRule(item = null) {
      this.currentMeasurementUnit = item
      this.$bvModal.show('ruleModal')
    },
    fetchData() {
      fetch(host + '/rest/warehouse/rules', {
        credentials: 'include'
      })
          .then(response => response.json())
          .then(json => {
            this.items = json.ruleOptions
            this.loading = false
            console.log(json)
          })
    },
    afterSave() {
      this.$bvModal.hide('ruleModal')
      this.loading = true
      this.fetchData()
    }
  },
  mounted() {
    this.fetchData()
  }
}
</script>

<style scoped>
h3 {
  text-align: -webkit-right!important;
  text-align: -moz-right!important;
  text-align: right!important;
}

</style>