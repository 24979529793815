import {host} from "@/constants";

export default {
  actions: {
    async checkAuth(context) {
      let isAuth = false
      try {
        console.log(host)
        let response = await fetch(host + '/rest/session', {
          method: 'GET',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        })
        let json = await response.json()
        console.log('get json ' + JSON.stringify(json))
        if (json.resultCode == 0) {
          isAuth = true
        } else {
          isAuth = false
        }
      } catch (e) {
        isAuth = false
      }

      context.commit('updateIsAuthorized', isAuth)
    }
  },
  mutations: {
    updateIsAuthorized(state, isAuthorized) {
      console.log('updateIsAuthorized called')
      state.isAuthorized = isAuthorized
    },
    updateRole(state, role) {
      state.role = role
    },
    updateUsername(state, username) {
      state.username = username
    },
    updateRolename(state, rolename) {
      state.rolename = rolename
    },
    updateUserId(state, userId) {
      state.userId = userId
    },
    updateActivationDaysLeftCount(state, activationDaysLeftCount) {
      state.activationDaysLeftCount = activationDaysLeftCount
    },
    updateReportDetailedBlock(state, reportDetailedBlock) {
      state.reportDetailedBlock = reportDetailedBlock
    },
    updateReportGeneralBlock(state, reportGeneralBlock) {
      state.reportGeneralBlock = reportGeneralBlock
    },
    updateReportConciseBlock(state, reportConciseBlock) {
      state.reportConciseBlock = reportConciseBlock
    },
    updateReportPatientBlock(state, reportPatientBlock) {
      state.reportPatientBlock = reportPatientBlock
    },
    updateReportGraphicBlock(state, reportGraphicBlock) {
      state.reportGraphicBlock = reportGraphicBlock
    },
    updatePermission(state, permission) {
      state.permission = permission
    }
  },
  state: {
    isAuthorized: false,
    role: 0,
    username: '',
    rolename: '',
    userId: '',
    activationDaysLeftCount: null,
    reportDetailedBlock: false,
    reportGeneralBlock: false,
    reportConciseBlock: false,
    reportPatientBlock: false,
    reportGraphicBlock: false,
    permission: {
      reportDetailedBlock: false,
      reportGeneralBlock: false,
      reportConciseBlock: false,
      reportPatientBlock: false,
      reportGraphicBlock: false,
      questionaryBlock: true,
      paramPrescribedBlock: true,
      reportConfigBlock: false,

      questionaryTabGeneralEnable: true,
      questionaryTabReproductEnable: true,
      questionaryTabComplaintsEnable: true,
      questionaryTabChronicEnable: true,
      questionaryTabDrugEnable: true,
      questionaryTabHeredityEnable: true,
      reportPreBlock: false,
      alternativeScaleBlock: true,
      onlyOMSParams: false,

      reportDetailedSubType: 10,
      reportGeneralSubType: 20,
      reportConciseSubType: 30,
      reportPatientSubType: 40,
      reportGraphicSubType: 50,

      debugDoctor: null,
      showButtonLoadExamBlank: false
    }
  },
  getters: {
    isAuthorized(state) {
      return state.isAuthorized
    },
    role(state) {
      return state.role
    },
    username(state) {
      return state.username
    },
    rolename(state) {
      return state.rolename
    },
    userId(state) {
      return state.userId
    },
    activationDaysLeftCount(state) {
      return state.activationDaysLeftCount
    },
    reportDetailedBlock(state) {
      return state.reportDetailedBlock
    },
    reportGeneralBlock(state) {
      return state.reportGeneralBlock
    },
    reportConciseBlock(state) {
      return state.reportConciseBlock
    },
    reportPatientBlock(state) {
      return state.reportPatientBlock
    },
    reportGraphicBlock(state) {
      return state.reportGraphicBlock
    },
    permission(state) {
      return state.permission
    }
  }
}