export default {
  actions: {},
  mutations: {
    updatePatientListSearchRequest(state, searchRequest) {
      state.patientListSearchRequest = searchRequest
    }
  },
  state: {
    patientListSearchRequest: ''
  },
  getters: {
    patientListSearchRequest(state) {
      return state.patientListSearchRequest
    }
  }
}