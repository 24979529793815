<template>
  <b-container fluid>
    <b-row>
      <h3>Элементы интерпретации</h3>
    </b-row>
    <b-row>
      <b-button variant="info" @click="editRubricatorValue()"><b-icon icon="plus" aria-hidden="true" ></b-icon> Добавить</b-button>
    </b-row>
    <b-row>
      <b-table striped hover :items="items" :fields="fields" ref="measurementUnitTable">
        <!-- A custom formatted column -->
        <template v-slot:cell(zoneId)="data">
          <b>{{ data.item.zoneId }}</b>
        </template>
        <template v-slot:cell(reportText)="data">
          <span v-if="data.item.reportTextId">Код фразы: {{data.item.reportTextId}}, </span>
          <span v-html="data.item.reportText"></span>
        </template>
        <template v-slot:cell(buttons)="data">
          <h3>
            <nobr>
              <b-link @click="data.toggleDetails" title="Показать детальные данные" ><b-icon variant="info" icon="file-text" size="lg" ></b-icon></b-link>

              <b-link @click="editRubricatorValue(data.item)" title="Редактировать" ><b-icon variant="info" icon="pencil" size="lg" ></b-icon></b-link>
              &nbsp;
              <b-link @click="deleteRubricatorValue(data.item.zoneId, data.item.zoneName)" title="Удалить"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link>
            </nobr>
          </h3>
        </template>
        <template #row-details="data">
          <b-card>
            <b-container fluid>
              <b-row v-if="data.item.reportTextDesease" class="newAttr">
                <strong>Фраза для блока отчета &quot;Патологические состояния и причины отклонений&quot; </strong>
              </b-row>
              <b-row v-if="data.item.reportTextDesease">
                <span v-if="data.item.reportTextDeseaseId">Код фразы: {{data.item.reportTextDeseaseId}},</span>
                <span v-html="data.item.reportTextDesease"></span>
              </b-row>

              <b-row v-if="data.item.reportTextDrug" class="newAttr">
                <strong>Фраза для блока отчета &quot;Лекарственные препараты&quot;</strong>
              </b-row>
              <b-row v-if="data.item.reportTextDrug">
                <span v-if="data.item.reportTextDrugId">Код фразы: {{data.item.reportTextDrugId}},</span>
                <span v-html="data.item.reportTextDrug"></span>
              </b-row>

              <b-row v-if="data.item.reportTextRiskFactor" class="newAttr">
                <strong>Фраза для блока отчета &quot;Факторы внешней среды и особые состояния организма&quot;</strong>
              </b-row>
              <b-row v-if="data.item.reportTextRiskFactor">
                <span v-if="data.item.reportTextRiskFactorId">Код фразы: {{data.item.reportTextRiskFactorId}},</span>
                <span v-html="data.item.reportTextRiskFactor"></span>
              </b-row>

              <b-row v-if="data.item.reportTextLaboratoryError" class="newAttr">
                <strong>Фраза для блока отчета &quot;Лабораторные ошибки&quot;</strong>
              </b-row>
              <b-row v-if="data.item.reportTextLaboratoryError">
                <span v-if="data.item.reportTextLaboratoryErrorId">Код фразы: {{data.item.reportTextLaboratoryErrorId}},</span>
                <span v-html="data.item.reportTextLaboratoryError"></span>
              </b-row>

              <b-row v-if="data.item.reportTextParamRecommended" class="newAttr">
                <strong>Фраза для блока отчета &quot;Рекомендуемые лабораторные исследования&quot;</strong>
              </b-row>
              <b-row v-if="data.item.reportTextParamRecommended">
                <span v-if="data.item.reportTextParamRecommendedId">Код фразы: {{data.item.reportTextParamRecommendedId}},</span>
                <span v-html="data.item.reportTextParamRecommended"></span>
              </b-row>

              <b-row v-if="data.item.reportTextRecommendExamination" class="newAttr">
                <strong>Фраза для блока отчета &quot;Рекомендуемые инструментальные исследования&quot;  </strong>
              </b-row>
              <b-row v-if="data.item.reportTextRecommendExamination">
                <span v-if="data.item.reportTextRecommendExaminationId">Код фразы: {{data.item.reportTextRecommendExaminationId}},</span>
                <span v-html="data.item.reportTextRecommendExamination"></span>
              </b-row>
            </b-container>
          </b-card>
        </template>
      </b-table>
    </b-row>
    <b-row>
      <div v-if="error" class="alert alert-danger">
        {{error}}
      </div>

      <b-modal id="zoneModal" hide-footer size="lg" title="Элемент интерпретации">
        <ZoneValueEdit @save-success="afterSave" v-bind:error="error" v-bind:current-zone="currentZone"></ZoneValueEdit>
      </b-modal>
      <Loader v-bind:showLoader="loading" />
    </b-row>
  </b-container>
</template>

<script>
import {host} from "@/constants"
import ZoneValueEdit from "@/components/admin/zones/ZoneValueEdit";
import Loader from "@/components/Loader";

export default {
  name: "ZoneValueList",
  components: {ZoneValueEdit, Loader},
  data() {
    return {
      fields: [
        {
          key: 'zoneId',
          label: 'Id',
          sortable: true
        },
        {
          key: 'paramTypeName',
          label: 'Тип',
          sortable: true
        },
        {
          key: 'zoneName',
          label: 'Наименование',
          sortable: true
        },
        {
          key: 'zoneShowPriority',
          label: 'Приоритет',
          sortable: true
        },
        {
          key: 'zoneGroupName',
          label: 'Группа',
          sortable: true
        },
        {
          key: 'reportText',
          label: 'Текст заключения',
          sortable: true
        },
        {
          key: 'coefB',
          label: 'Коэффициент значимости отклонений',
          sortable: true
        },
        {
          key: 'coefBGraphic',
          label: 'Коэффициент значимости отклонений для графических отчётов',
          sortable: true
        },
        {
          key: 'buttons',
          label: ' ',
          sortable: false
        }
      ],
      currentZone: null,
      error: null,
      loading: true,
      items:[],
    }
  },
  methods : {
    deleteRubricatorValue(paramId, paramName) {
      this.error = null;
      if (confirm('Вы уверены, что хотите удалить значение рубрикатора "' + paramName + '"')) {
        this.loading = true;
        fetch(host + '/rest/zones/' + paramId, {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {
                this.items = this.items.filter(item => item.zoneId !== paramId)

                console.log('Delete Success ' + json)

              } else {

                console.log('Delete Failed ' + json)
                this.error = json.message;
              }
              this.loading = false

            }).catch(error => {
          this.error = error
          console.log(JSON.stringify(error))
          this.loading = false
        })
      }
    },
    editRubricatorValue(item = null) {
      this.currentZone = item
      this.$bvModal.show('zoneModal')
    },
    fetchData() {
      fetch(host + '/rest/zones')
          .then(response => response.json())
          .then(json => {
            this.items = json.options
            this.loading = false
            console.log(json)
          })
    },
    afterSave() {
      this.$bvModal.hide('zoneModal')
      this.loading = true
      this.fetchData()
    }
  },
  mounted() {
    this.fetchData()
  }
}
</script>

<style scoped>

.newAttr {
  padding-top: 1rem;
}

</style>