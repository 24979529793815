<template>
  <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 1756 1000" style="enable-background:new 0 0 1756 1000;" xml:space="preserve">
    <g>
      <a id="ast0" v-on:click="toogleSvgClass($event,'ast0')" class="svglink" :class="this.questionary.painMuscle ? 'svglinkActive' : 'svglink'">
        <path class="st0" d="M178,286.7c-2,1.6-11.9,9.1-24.5,9.1c-0.8,0-1.7,0-2.5-0.1c-6.1-0.5-11.9-2.8-17.3-6.9c0,0.8,0,1.6,0.1,2.4
          c0,0.5-0.1,3.3-0.4,6.9c-0.3,4.6-0.8,10.9-1.4,14.7c0,0.1-0.6,3.3-1.1,11.2c-0.3,6.1-0.1,15,0.1,27.3c0.1,4.4,0.2,9.4,0.3,14.9
          c4.6-2.1,12.1-4.3,20.6-2.4c6,1.4,11.6,4.5,16.7,9.5c0.4-7.3,1.7-11.9,1.7-11.9c0-0.1,0-0.1,0-0.2c1.3-7.7,2.4-16.2,3.3-24.8
          C173.3,325.9,172.6,298.1,178,286.7z"/>
        <path class="st0" d="M336.7,295.7c-0.8,0.1-1.7,0.1-2.5,0.1c-12.1,0-21.8-7-24.2-8.9c0.6,1,1.7,3.3,2.6,8
          c1.2,6.2,2.3,18,0.7,38.8c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c0.9,9.4,2.1,18.8,3.5,27.2c0,0.1,0,0.1,0,0.2c0,0.1,1.1,4,1.6,10.2
          c4.6-4,9.6-6.6,14.9-7.8c9.9-2.2,18.4,1.1,22.6,3.3c0.1-5.9,0.2-11.2,0.3-15.8c0.3-12.3,0.5-21.2,0.1-27.3
          c-0.4-7.9-1-11.1-1-11.1c-0.7-3.8-1.1-10.1-1.4-14.7c-0.2-3.6-0.3-6.4-0.4-6.9c0-0.7,0-1.3,0.1-2
          C348.3,293,342.7,295.2,336.7,295.7z"/>
        <path class="st0" d="M167.7,434.3c2.4-10.3,3.6-20.1,3.7-29c0-0.7,0-1.4,0-2.1c-5.6,2.6-11,3.9-16.2,3.9c-2.3,0-4.6-0.3-6.8-0.8
          c-8.5-2-14.9-7.5-18.8-12c-0.1,0.4-0.2,0.8-0.4,1.2c-1,3.3-2.1,7-2.8,12.2c-0.5,3.9-0.8,11.1-0.7,18.4c0,0.3,0.5,27.9,0.4,48.3
          c0,8.7-0.5,24.2-1,29.9c-0.2,2.2-0.6,5.5-1,8.1c10.3,4.9,20.6,5.5,25.9,5.4c0-0.1,0-0.1,0-0.1c0-0.3,2.6-28.8,10.5-56.6
          C162.3,454.4,166.6,439,167.7,434.3z"/>
        <path class="st0" d="M363.2,512.5c-0.4-2.7-0.8-5.9-1-8.1c-0.5-5.7-1-21.2-1-29.9c-0.1-20.5,0.4-48,0.4-48.3
          c0.1-7.3-0.2-14.5-0.7-18.4c-0.7-5.2-1.8-8.9-2.8-12.2c-0.1-0.4-0.3-0.9-0.4-1.3c-3.9,4.5-10.3,10-18.8,12
          c-2.2,0.5-4.5,0.8-6.8,0.8c-5.2,0-10.6-1.3-16.1-3.9c0,0.7,0,1.4,0,2.1c0,8.9,1.3,18.7,3.7,29c1.1,4.6,5.4,20.1,7.3,26.9
          c7.9,27.8,10.4,56.3,10.5,56.6c0,0,0,0.1,0,0.1C342.6,518,352.9,517.4,363.2,512.5z"/>
        <path class="st0" d="M196.9,683.7c4.1-3.2,9.2-5.6,14.5-6.9c5.7-1.4,11.4-1.3,16.5,0c6,1.6,11.2,5,15.5,10l0.1-152.4h0
        c-15-23.3-32.1-40.7-43.8-51.2c-9.7-8.7-17.4-14.3-20.5-16.4c3.6,3.1,7.6,7.3,11.7,13.1c5.8,8.3,6.3,16.7,5.7,22.4
        c-0.7,6.1-2.7,10.4-2.8,10.4c-0.1,0.1-5,7-12.3,12.4c-4.3,3.1-8.6,5.1-12.7,5.9c-1.3,0.2-2.6,0.4-3.9,0.4c-2.4,0-4.7-0.4-7-1.3
        c2.1,27.8,8.1,83.4,24,117.8c0.1,0.2,7.4,16.4,10.2,33.8c0.2,1.3,0.4,3.3,0.6,5.9C193.9,686.3,195.3,685,196.9,683.7z"/>
        <path class="st0" d="M322.3,531.4c-1.3,0-2.6-0.1-3.9-0.4c-4.1-0.8-8.4-2.8-12.7-5.9c-7.3-5.4-12.3-12.3-12.3-12.4
        c0-0.1-2.1-4.3-2.8-10.4c-0.6-5.7-0.1-14.1,5.7-22.4c4.1-5.8,8.1-10,11.7-13.1c-3.1,2.1-10.8,7.6-20.5,16.4
        c-11.7,10.5-28.8,27.9-43.8,51.2h0l-0.1,152.4c4.7-5.2,10.3-8.6,16.8-10.1c5.6-1.3,11.8-1.3,17.8,0.3c6.4,1.6,12.3,4.7,16.5,8.3
        c0.1-1.4,0.3-2.6,0.4-3.5c2.8-17.5,10.2-33.7,10.2-33.8c15.9-34.4,21.9-90,24-117.8C327.1,531,324.8,531.4,322.3,531.4z"/>
        <path class="st0" d="M243.1,774.9c-4.1-15.6-7.4-26.1-6.5-31.6c0.2-1.5,0.8-4.7,1.5-8.5c-3,2.3-6.4,4-10.1,4.9
        c-2.6,0.7-5.4,1.1-8.2,1.1c-2.8,0-5.6-0.3-8.4-1c-5.3-1.3-10.5-3.7-14.6-6.9c-1.5-1.1-2.8-2.4-3.8-3.6c-0.1,0.7-0.2,1.3-0.3,1.9
        c-0.1,0.4-0.5,1.6-0.9,3c-1.1,3.5-3,9.3-4.1,15.4c-2.4,13.3-3.5,34.6,4.9,60.3c0,0.1,4,13.4,9.1,32.1c2.7,9.8,7.2,29.2,9.8,42.5
        c0.4,1.9,1,6.8,1.1,7.3c0,0.3,0,0.6,0,0.9c1.6,0.3,3.6,0.6,5.9,0.7c4.8,0.3,11.8,0,19.7-2.6c0.2-13.2,1.6-28.5,4-54.7l0.5-5.1
        c0.4-4.7,0.6-9.1,0.9-13.2l0-43.7C243.3,774.2,243.2,774.6,243.1,774.9z"/>
        <path class="st0" d="M299.7,749.5c-1.1-6.1-3-11.9-4.1-15.4c-0.4-1.4-0.8-2.5-0.9-3c-0.2-0.9-0.3-1.9-0.5-2.9
          c-1.2,1.6-2.8,3.1-4.6,4.6c-4.1,3.2-9.2,5.7-14.5,6.9c-2.7,0.6-5.4,1-8.1,1c-2.8,0-5.6-0.4-8.2-1.1c-3.9-1.1-7.6-3-10.8-5.6
          c0.6,3.2,1.1,6,1.3,7.8c0.8,7.2-1.8,14.2-5.8,31.1l0,44.9c0.4,4,0.9,8.4,1.3,12.9l0.5,5.1c2.4,26.2,3.8,41.6,4,54.7
          c7.9,2.6,15,2.9,19.8,2.6c2.3-0.1,4.3-0.4,5.9-0.7c0-0.3,0-0.6,0-0.9c0-0.5,0.7-5.4,1.1-7.3c2.5-13.3,7.1-32.7,9.8-42.5
          c5.1-18.7,9.1-32,9.1-32.1C303.2,784,302.1,762.8,299.7,749.5z"/>
        <path class="st0" d="M699.5,331.8c0.9,9.9,2.1,19.9,3.7,28.9c0,0.1,0,0.1,0,0.2c0,0,0.7,2.4,1.2,6.5c4.6-4,9.6-6.6,14.9-7.8
          c10.2-2.3,18.9,1.3,23,3.5c0.1-4.4,0.2-8.5,0.2-12.2c0.3-12.3,0.5-21.2,0.1-27.3c-0.4-7.9-1-11.1-1-11.1
          c-0.7-3.8-1.1-10.1-1.4-14.7c-0.2-3.6-0.3-6.4-0.4-6.9c0-0.4,0-0.8,0-1.3c-4.4,2.4-8.9,3.8-13.3,4.4c-1.4,0.2-2.8,0.3-4.1,0.3
          c-4,0-8-0.8-11.8-2.3c-3.2-1.3-6.1-3-8.7-5c0.1,7.9-1.6,30.4-2.9,46.6C699.3,332.9,699.4,332.3,699.5,331.8z"/>
        <path class="st0" d="M554.4,434c2.4-10.3,3.6-20.1,3.7-29c0-0.7,0-1.5-0.1-2.4c-5.8,2.8-11.4,4.2-16.8,4.2
          c-2.3,0-4.6-0.3-6.8-0.8c-8.2-1.9-14.4-7.1-18.4-11.5c-0.1,0.3-0.1,0.5-0.2,0.8c-1,3.3-2.1,7-2.8,12.2
          c-0.5,3.9-0.8,11.1-0.7,18.4c0,0.3,0.5,27.9,0.4,48.3c0,8.7-0.5,24.2-1,29.9c-0.2,2.3-0.7,5.9-1.1,8.7c10.8,4.8,21.4,5,26,4.8
          c0,0,0-0.1,0-0.1c0-0.3,2.6-28.8,10.5-56.6C549,454.1,553.3,438.6,554.4,434z"/>
        <path class="st0" d="M749.5,511.7c-0.4-2.6-0.8-5.6-0.9-7.6c-0.5-5.7-1-21.2-1-29.9c-0.1-20.5,0.4-48,0.4-48.3
          c0.1-7.3-0.2-14.5-0.7-18.4c-0.7-5.2-1.8-8.9-2.8-12.2c-0.2-0.5-0.3-1-0.5-1.6c-3.9,4.5-10.3,10.3-19.1,12.3
          c-2.2,0.5-4.5,0.8-6.8,0.8c-5.1,0-10.4-1.3-15.8-3.8c0,0.7,0,1.3,0,1.9c0,8.9,1.3,18.7,3.7,29c1.1,4.6,5.4,20.1,7.3,26.9
          c7.9,27.8,10.4,56.3,10.5,56.6c0,0,0,0.1,0,0.1C729.4,517.6,739.6,516.7,749.5,511.7z"/>
        <path class="st0" d="M560.2,306.2c0.1-0.7,1.2-13,2.6-21.5c-3.2,2.9-7,5.5-11.3,7.2c-3.8,1.5-7.8,2.3-11.8,2.3
          c-1.4,0-2.7-0.1-4.1-0.3c-5-0.6-10.1-2.5-15.1-5.5c0,0.8,0,1.5,0.1,2.3c0,0.5-0.1,3.3-0.4,6.9c-0.3,4.6-0.8,10.9-1.4,14.7
          c0,0.1-0.6,3.3-1.1,11.2c-0.3,6.1-0.1,15,0.1,27.3c0.1,3.3,0.1,6.9,0.2,10.8c4.6-2,11.9-4,20.1-2.1c6.3,1.4,12.2,4.9,17.5,10.3
          c0.5-5.5,1.5-8.8,1.5-8.9c0-0.1,0-0.1,0-0.2c1.5-8.8,2.8-18.7,3.6-28.5C559.6,319.7,559.3,312.9,560.2,306.2z"/>
        <path class="st0" d="M583.5,683.3c4.1-3.2,9.2-5.6,14.5-6.9c5.7-1.4,11.4-1.3,16.5,0c6,1.6,11.3,5,15.6,10v-196c0,0,0,0,0,0
          c-15-31.4-35.9-39.7-50.8-41.1c-10.3-1-19.2,1.1-24.3,2.7c-0.1,0.4-0.2,0.7-0.3,1.1c1,0.5,2.1,1.2,3.4,2.1
          c4.5,3.1,11.3,8.9,18.2,19.6c5.8,9,6.3,18.3,5.7,24.5c-0.7,6.7-2.7,11.3-2.8,11.4c-0.1,0.2-11.2,17.1-25,20
          c-1.3,0.3-2.6,0.4-3.9,0.4c-1.9,0-3.9-0.3-5.7-0.9c2.1,28,8.2,83.2,24,117.5c0.1,0.2,7.4,16.4,10.2,33.8c0.2,1.3,0.4,3.3,0.6,5.8
          C580.5,685.9,581.9,684.6,583.5,683.3z"/>
        <path class="st0" d="M707.8,531.1c-1.3,0-2.6-0.1-3.9-0.4c-13.8-2.8-24.9-19.8-25-20c0-0.1-2.1-4.7-2.8-11.4
          c-0.6-6.2-0.1-15.4,5.7-24.5c6.9-10.6,13.7-16.5,18.2-19.6c2.2-1.5,4-2.5,5.4-3.1c0-0.2-0.1-0.3-0.1-0.5
          c-5.3-1.6-14-3.4-23.9-2.4c-15,1.5-36.1,9.8-51.1,41.2c0,0,0,0,0,0v196c4.7-5.2,10.2-8.6,16.7-10.1c5.6-1.3,11.8-1.3,17.8,0.3
          c6.3,1.6,12.2,4.6,16.3,8.2c0.1-1.4,0.3-2.5,0.4-3.4c2.8-17.5,10.2-33.7,10.2-33.8c16-34.6,22-90.8,24.1-118.4
          C713.3,530.4,710.6,531.1,707.8,531.1z"/>
        <path class="st0" d="M623.8,743.5c0.3-1.6,0.9-5.3,1.7-9.7c-3.3,2.6-6.9,4.5-10.9,5.6c-2.6,0.7-5.4,1.1-8.2,1.1
          c-2.8,0-5.6-0.3-8.4-1c-5.3-1.3-10.5-3.7-14.6-6.9c-1.4-1.1-2.7-2.3-3.8-3.5c-0.1,0.6-0.2,1.2-0.3,1.8c-0.1,0.4-0.5,1.6-0.9,3
          c-1.1,3.5-3,9.3-4.1,15.4c-2.4,13.3-3.5,34.6,4.9,60.3c0,0.1,4,13.4,9.1,32.1c2.6,9.4,7,28.1,9.6,41.8c0.3,1.6,0.6,3,0.8,4.1
          c0.4,1.8,0.7,3.2,0.9,4.9c1.5,0.2,3.3,0.5,5.3,0.6c4.8,0.3,11.9,0,19.8-2.6c0.2-13.4,1.6-29,4-54.8l0.5-5c0.4-4,0.7-7.8,1-11.5
          v-44.9C626.1,759.1,623,748.9,623.8,743.5z"/>
        <path class="st0" d="M686.1,749.1c-1.1-6.1-3-11.9-4.1-15.4c-0.4-1.4-0.8-2.5-0.9-3c-0.2-0.8-0.3-1.7-0.5-2.7
          c-1.2,1.5-2.7,3-4.5,4.4c-4.1,3.2-9.2,5.7-14.5,6.9c-2.7,0.6-5.4,1-8.1,1c-2.8,0-5.6-0.4-8.2-1.1c-3.7-1-7.1-2.8-10.2-5.2
          c0.6,3.3,1.1,6.1,1.3,8c0.9,7.5-2,14.7-6.2,33c0-0.1,0-0.1-0.1-0.2V819c0.3,3.6,0.6,7.4,1,11.5l0.5,5c2.3,25.7,3.8,41.4,4,54.8
          c10.6,3.5,19.8,3,25.1,2.1c0.3-1.7,0.6-3.1,0.9-4.9c0.2-1.2,0.5-2.5,0.8-4.1c2.6-13.7,7-32.3,9.6-41.8c5.1-18.7,9.1-32,9.1-32.1
          C689.7,783.7,688.6,762.4,686.1,749.1z"/>
        <polygon class="st0" points="1000.6,773.5 1000.6,815.1 1000.6,860.8 1000.6,864.8 1046.3,864.8 1046.3,860.8 1046.3,815.1
          1046.3,773.5 		"/>
      </a>
      <a id="ast1" v-on:click="toogleSvgClass($event,'ast1')" class="svglink" :class="this.questionary.painFrontHalfChest ? 'svglinkActive' : 'svglink'">
      <path class="st1" d="M305,246.3c2.1-4.7,5-8.9,8.6-12.5c0.9-0.9,1.9-1.8,2.8-2.6c-0.3-0.1-0.5-0.1-0.7-0.2
        c-6.3-2.1-11-5.1-11-5.1c-0.1,0-0.1-0.1-0.2-0.1c-0.5-0.3-1.1-0.5-1.6-0.8l0,0l0,0c-1.3-0.7-2.7-1.4-4.1-2.2c0,0,0,0,0-0.1
        c0,0,0,0,0,0l-3.4-1.9c0,0,0,0,0,0c-0.5-0.3-1-0.6-1.5-0.9l0,0c-3.1-1.8-5.9-3.6-7.9-4.8c-1.9-1.2-5.5-3-7.4-3.9l-0.8-0.4
        c-3.3-1.6-5.6-3.4-7-5.4c-0.3-0.4-0.5-0.8-0.7-1.4c-0.7-2.1-0.9-10.6-0.4-22l-2.6-1.1l-1,1.8c-8.3,5.9-16,8.8-23.3,8.8
        c-0.3,0-0.7,0-1,0c-7-0.2-13.7-3.1-20.7-8.8l0,0l-1.1-1.7c-0.5,0.2-1.4,0.7-2.5,1.2c0.4,11.4,0.3,19.8-0.4,21.9
        c-0.2,0.6-0.4,1-0.7,1.4c-1.4,1.9-3.7,3.7-7,5.4l-0.8,0.4c-1.9,0.9-5.5,2.7-7.4,3.9c-2.2,1.4-5.6,3.5-9.2,5.6c0,0,0,0,0,0
        L188,223c0,0,0,0,0,0c-0.4,0.2-0.8,0.5-1.2,0.7l0,0l0,0c-1.4,0.8-2.8,1.5-4.1,2.1c-0.1,0-0.1,0.1-0.2,0.1c0,0-4.8,3.1-11,5.1
        c-0.1,0-0.2,0.1-0.3,0.1c1,0.8,1.9,1.7,2.9,2.7c3.6,3.6,6.5,7.8,8.6,12.5c2.6,5.9,4.1,12.5,4.2,19.8l0,0l0,0
        c-9,14.1-8.5,20.1-8.5,20.1l0,0.1l0,0c0,0,0,0,0,0l0,0c-5.3,10.6-4.9,36.8-4.5,48.3c0.1-0.9,0.2-1.7,0.2-2.6
        c2.2,10.7,5.6,24.5,10.4,36.5c0,0.1,2.3,6.4,3,13.7c18.4-0.6,31.8-7.8,40.2-14.3c3.9-3.1,7.1-6.2,9.6-9l-0.1,0
        c0-0.3-4.2-26.8-5.5-55c-0.7-16.6-0.3-30.6,1.2-41.5c1.9-13.7,5.6-22.6,10.9-26.5l0.1,0l0.1,0c5.3,3.9,9,12.9,10.9,26.5
        c1,7.5,1.5,16.4,1.5,26.7l53-2.9c0-0.8-0.2-6.9-8.5-20l0,0l0,0C301,258.9,302.4,252.2,305,246.3z"/>
      <path class="st1" d="M312.7,333.8c-5.8,0-51.3-7.2-58.2-8.3c-1.7,18.9-3.9,33.3-4,33.5l-0.1,0c2.5,2.8,5.7,5.9,9.6,9
        c8.3,6.5,21.5,13.6,39.6,14.3c0.6-7.3,2.9-13.6,2.9-13.6C307.3,357.3,310.6,344.3,312.7,333.8
        C312.7,333.8,312.7,333.8,312.7,333.8z"/>
        <rect x="999.5" y="168.1" class="st1" width="45.7" height="45.7"/>
      </a>

      <a id="ast2" v-on:click="toogleSvgClass($event,'ast2')" class="svglink" :class="this.questionary.painChest ? 'svglinkActive' : 'svglink'">
        <path class="st2" d="M243.9,236.2c-5.3,3.9-8.9,12.8-10.8,26.3c-1.5,10.9-1.9,24.8-1.2,41.4c1.2,27.5,5.2,53.2,5.4,54.9
          c4.3-4.8,6.4-8.5,6.4-8.5l0.1-0.2l0.1,0.2c0,0.1,2.1,3.7,6.4,8.5c0.2-1.2,2.3-15.2,4-33.4c0,0-0.1,0-0.1,0l0-0.2c0,0,0.1,0,0.1,0
          c0.6-6.7,1.1-14,1.5-21.4c0.7-16.6,0.3-30.5-1.2-41.4C252.8,248.9,249.1,240.1,243.9,236.2z"/>
        <rect x="999.5" y="345.6" class="st2" width="45.7" height="45.7"/>
      </a>
      <a id="ast3" v-on:click="toogleSvgClass($event,'ast3')" class="svglink" :class="this.questionary.painHeart ? 'svglinkActive' : 'svglink'">
      <path class="st3" d="M256.4,289.3c0,4.6-0.1,9.5-0.4,14.6c-0.3,7.4-0.9,14.7-1.5,21.4c15.5,2.5,53.9,8.4,58.2,8.3
        c0.1-0.5,0.2-1,0.3-1.6c0,0.3,0.1,0.6,0.1,0.9c2.8-37.7-3.3-46-3.8-46.6L256.4,289.3z"/>
      <path class="st3" d="M619.9,363.5c0.1-1.2,0.9-25,1.9-55.6c-13.6,4.8-26.9,6.4-39.8,4.7c-10.4-1.4-17.6-4.5-20.7-5.9
        c-0.4-0.2-0.7-0.3-1-0.4c-0.9,6.9-0.6,13.8,0.7,26.8c2.2,10.5,5.5,23.7,10.1,35.2c0,0.1,2.5,7,3,14.7
        c18.1-0.7,31.3-7.8,39.6-14.3C616.1,367,618.2,365.1,619.9,363.5z"/>
      <polygon class="st3" points="999.5,234.5 999.5,285.6 999.5,324.9 999.5,331.2 1045.1,331.2 1045.1,324.9 1045.1,285.6
      1045.1,234.5 		"/>
      </a>
      <a id="ast4" v-on:click="toogleSvgClass($event,'ast4')" class="svglink" :class="this.questionary.painUpAbdominal ? 'svglinkActive' : 'svglink'">
      <path class="st4" d="M227.8,368.1c-6.4,5-13.5,8.7-21,11.1c-6.1,2-12.6,3-19.3,3.3c0.3,4,0.2,8.2-1.1,11.9l-0.1,0.3
        c-0.6,1.7-1.9,5.6-4.2,9.3c-2.4,3.8-7.2,13.5-8.6,23.2c-0.3,1.9-0.6,3.8-1,5.7c4.2,1.9,12.7,3.6,25,4.9c13.2,1.4,30,2.2,47.1,2.3
        c17.5,0.1,34-0.5,46.5-1.8c12.1-1.3,20.2-3.1,23.6-5.3c-0.4-1.9-0.7-3.8-1-5.7c-1.4-9.7-6.3-19.4-8.6-23.2
        c-2.3-3.7-3.6-7.6-4.2-9.3l-0.1-0.3c-1.3-3.7-1.4-8-1.1-11.9c-6.5-0.3-12.8-1.3-18.7-3.2c-7.6-2.4-14.6-6.2-21-11.1
        c-10.1-7.8-15.3-16.3-16.1-17.6C243.1,351.8,237.9,360.3,227.8,368.1z"/>
      <rect x="1000.6" y="555.3" class="st4" width="45.7" height="45.7"/>
      </a>
      <a id="ast6" v-on:click="toogleSvgClass($event,'ast6')" class="svglink" :class="this.questionary.painLowAbdominal ? 'svglinkActive' : 'svglink'">
      <path class="st6" d="M243.6,534.3c15-23.3,32.1-40.7,43.8-51.2c12.7-11.4,21.9-17.3,22-17.4l0,0c1.9-1.6,3.7-2.8,5.2-3.7
        c2.6-1.6,4.7-2.6,6.1-3.1c-0.6-1.9-1.2-4.1-1.8-6.9c-0.6-2.8-1.2-5.3-1.7-7.6c-1-4.6-1.8-8.1-2.4-11.3
        c-8.2,5.3-39.5,7.1-66.5,7.1c-1.2,0-2.5,0-3.7,0c-28.6-0.2-62-2.5-72.1-7.2c-0.6,3.2-1.4,6.7-2.4,11.4c-0.5,2.2-1,4.7-1.7,7.6
        c-0.6,2.9-1.2,5.1-1.8,6.9c1.4,0.6,3.5,1.5,6.1,3.1c1.5,0.9,3.2,2.1,5.2,3.7l0,0c0.1,0.1,9.3,6,22,17.4
        C211.5,493.6,228.6,511,243.6,534.3z"/>
      <rect x="1000.6" y="621.9" class="st6" width="45.7" height="45.7"/>
      </a>

      <a id="ast5" v-on:click="toogleSvgClass($event,'ast5')" class="svglink" :class="this.questionary.painJoint ? 'svglinkActive' : 'svglink'">
      <path class="st5" d="M151.9,363.9c-8.5-1.9-16,0.3-20.6,2.4c0.1,4.7,0.2,9.7,0.2,15.1c0.1,5.5-0.8,9-1.9,12.8
        c3.9,4.5,10.2,10,18.8,12c7.2,1.7,14.9,0.6,23-3.2c-0.2-4.9-1.1-10.9-1.8-15.5c-0.4-2.6-0.7-4.9-0.8-6c-0.2-2.9-0.2-5.6-0.1-8.1
        C163.5,368.4,157.9,365.2,151.9,363.9z"/>
      <path class="st5" d="M181.3,525c7.3-5.3,12.2-12.3,12.3-12.3c0,0,2.1-4.2,2.7-10.3c0.6-5.6,0.1-14.1-5.7-22.2
        c-6.9-9.7-13.6-15.1-18.1-17.9c-2.5-1.6-4.6-2.5-6-3.1c-0.6,2-1.3,3.5-1.9,5.1c-0.4,1.1-0.9,2.2-1.3,3.3
        c-3.1,8.6-6.7,23.6-6.5,45.6c0,0.2,0.2,6.6,1,16.8c2.4,0.9,4.7,1.3,7,1.3C171.2,531.2,177,528.2,181.3,525z"/>
      <path class="st5" d="M338.8,406.2c8.6-2,14.9-7.6,18.8-12.1c-1.1-3.7-2-7.3-1.9-12.7c0-5,0.1-9.8,0.2-14.2
        c-4.1-2.2-12.7-5.6-22.5-3.3c-5.3,1.2-10.3,3.8-14.9,7.8c0.2,2.8,0.3,6.1,0,9.8c-0.1,1.2-0.4,3.4-0.8,6
        c-0.7,4.6-1.6,10.6-1.8,15.5C323.9,406.8,331.6,407.8,338.8,406.2z"/>

      <path class="st5" d="M290.5,924c0,0-3.6-4.2-6.6-9.1c-0.1-0.2-0.7-1.5-1.2-2.5c-0.9-1.8-1.9-3.8-2.5-4.8l-0.6-1
        c-1.8-3-2.4-4-3.3-6.6l-0.1-0.2c-0.5-1.3-1.3-3.7-1.4-7.1c-1.6,0.3-3.6,0.6-5.8,0.7c-0.9,0.1-1.9,0.1-3,0.1
        c-4.5,0-10.4-0.6-16.8-2.7c0,2.6,0,5,0,7.5l-0.4,5.3c-0.1,1.4-0.3,2.8-0.5,4.2c-0.2,1.7-0.9,4.6-1.5,7.3
        c-0.6,2.7-1.2,5.3-1.4,6.9c-0.7,5.3-0.8,12.5,2.8,16.4c1.8,1.9,4.2,2.9,7.3,2.9c0.3,0,0.5,0,0.8,0c0.2,0,19.4-1.9,25.6-0.7
        c0,0,0.1,0,0.1,0c1.1,0.1,10.7,1.3,15-1.4c0.7-0.4,1.5-1.5,1.8-2.9c0.2-1.2,0.1-3-1.7-5.2C295.9,929.9,294.5,928.2,290.5,924z"/>
      <path class="st5" d="M240.5,915.1c-0.6-2.7-1.3-5.6-1.5-7.3c-0.2-1.4-0.4-2.8-0.5-4.2l-0.4-5.2c-0.1-2.5-0.1-5,0-7.6
        c-6.4,2.1-12.3,2.7-16.8,2.7c-1.1,0-2,0-3-0.1c-2.3-0.1-4.3-0.4-5.9-0.7c-0.1,3.4-0.9,5.8-1.3,7.1l-0.1,0.2
        c-0.9,2.5-1.5,3.6-3.3,6.6l-0.6,1c-0.6,1-1.6,3-2.5,4.8c-0.5,1-1.1,2.2-1.2,2.4c-3,4.9-6.5,9-6.5,9c0,0,0,0,0,0
        c-4.1,4.3-5.5,6-6.6,7.3c-1.8,2.2-1.9,4.1-1.7,5.2c0.2,1.4,1.1,2.4,1.8,2.9c4.3,2.6,13.9,1.5,15,1.4c0,0,0.1,0,0.1,0
        c6.2-1.3,25.4,0.6,25.7,0.7c0.3,0,0.5,0,0.8,0c3,0,5.4-1,7.2-2.9c3.7-4,3.6-11.1,2.8-16.4C241.7,920.4,241.1,917.8,240.5,915.1z"
      />
      <path class="st5" d="M296.5,480.1c-5.8,8.2-6.3,16.6-5.7,22.3c0.7,6.1,2.7,10.3,2.7,10.3c0,0.1,5,7,12.3,12.3
        c5.9,4.3,14.5,8.3,23.6,4.9c0.8-10.2,1-16.6,1-16.8c0.2-22-3.4-36.9-6.5-45.5c-0.4-1.1-0.8-2.2-1.3-3.3c-0.6-1.6-1.3-3.1-1.9-5.1
        c-1.4,0.6-3.6,1.5-6.1,3.1C310.2,465,303.4,470.4,296.5,480.1z"/>
      <path class="st5" d="M151,520.5c-0.4-0.8-0.9-1.8-1-2.4c-0.3,0-0.6,0-1,0c-5.5,0-15.3-0.8-25-5.4c-0.1,0.4-0.1,0.7-0.2,1
        l-0.2,0.5c-2,4-4.4,6.3-4.8,6.6c-1.3,1-2.5,2-3.5,3.3c-4.7,5.3-5.9,13.6-6.2,16c-1,9.2-8.8,12.4-9.1,12.5c-0.1,0-0.2,0.1-0.3,0.2
        c-2.1,1.4-3,2.8-2.6,4.3c0.6,2.5,4.2,3.3,5.8,3.5c0.1,0,0.2,0,0.2,0c4.4,0,8.7-3.4,11.6-6.4c0,12.8,1.9,32.7,2,33.7
        c0,0.1,0,0.1,0,0.2c0.5,2.5,1.9,3.8,2.9,4.4c1.4,0.8,2.9,0.9,4.1,0.8c1.2,4.2,4.2,5.3,5.9,5.3c0.1,0,0.2,0,0.3,0
        c2.9,0,4.7-2.4,5.5-4.5c3-0.3,5.1-3.3,6.1-5.7c8.2-3.1,11.2-22.8,11.6-25.1c3.3-20.1,1.9-31.4,0.3-37.2
        C152.7,523.9,151.8,522,151,520.5z"/>
      <path class="st5" d="M387.6,552.8c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-8.1-3.3-9.1-12.5c-0.3-2.4-1.5-10.8-6.2-16
        c-1.1-1.2-2.3-2.3-3.5-3.3c-0.3-0.3-2.8-2.6-4.7-6.6l-0.3-0.5c-0.1-0.3-0.1-0.7-0.2-1.1c-9.7,4.7-19.5,5.4-25,5.4
        c-0.3,0-0.6,0-0.9,0c-0.2,0.6-0.7,1.6-1,2.4c-0.7,1.5-1.7,3.4-2.3,5.7c-1.7,5.9-3,17.1,0.2,37.2c0.3,2.3,3.4,22,11.6,25.1
        c1,2.4,3.1,5.3,6.1,5.7c0.8,2.1,2.6,4.5,5.5,4.5c0.1,0,0.2,0,0.3,0c1.7,0.1,4.7-1,5.9-5.3c1.2,0.1,2.7,0,4.1-0.8
        c1-0.6,2.4-1.9,2.9-4.4c0-0.1,0-0.1,0-0.2c0.1-1,2-20.9,2-33.7c2.9,3,7.1,6.4,11.6,6.4c0.1,0,0.2,0,0.2,0c1.5-0.2,5.2-1,5.8-3.5
        C390.5,555.7,389.6,554.2,387.6,552.8z"/>
      <path class="st5" d="M316.7,231.3c-1,0.8-1.9,1.7-2.9,2.7c-5.6,5.6-12.4,15.8-12.8,32.1c8.5,13.4,8.6,19.5,8.6,20.2
        c0.8,0.7,12.5,10.4,27.2,9.2c6-0.5,11.6-2.7,16.8-6.6c0.5-24.4-2.6-36.1-5.3-41.6c-3.3-6.7-10.2-10.4-15.5-12.4
        c-2.2-0.8-6.2-1.6-10.1-2.3C320.4,232.1,318.2,231.7,316.7,231.3z"/>
      <path class="st5" d="M151,295.5c14.6,1.2,26.4-8.6,27.2-9.2c0-0.6,0-6.8,8.6-20.2c-0.4-16.3-7.2-26.5-12.8-32.1c-1-1-2-1.9-3-2.8
        c-1.4,0.4-3.8,0.8-6.3,1.3c-3.9,0.7-7.9,1.5-10.1,2.3c-5.2,2-12.2,5.7-15.5,12.4c-2.7,5.5-5.8,17.2-5.3,41.3
        C139.1,292.7,144.9,295,151,295.5z"/>
      <path class="st5" d="M242.7,709c0,0,0.3,1.2,0.7,3l0-24.9c-10.9-12.8-24.7-11.9-32-10.2c-5.2,1.3-10.3,3.7-14.4,6.8
        c-1.7,1.3-3.2,2.7-4.3,4.2c0.6,8.6,1.1,22.5,1,30.2c-0.1,4.1-0.4,7.7-0.8,10.8c1.1,1.3,2.4,2.5,3.9,3.7c4.1,3.2,9.2,5.6,14.5,6.9
        c6.2,1.5,16.9,2.4,26.8-5.1C239.9,724,242.7,709,242.7,709z"/>
      <path class="st5" d="M294.7,685.5c-4.1-3.6-10.1-6.8-16.5-8.3c-13.3-3.3-26,0.4-34.6,10l0,25.8c1.1,5,3.1,13.9,4.4,20.8
        c9.8,8.1,20.9,7.3,27.1,5.8c7.9-1.9,15.3-6.5,19.1-11.6c-0.4-2.8-0.6-6.1-0.7-9.8C293.4,709.7,294,693.8,294.7,685.5z"/>


      <path class="st5" d="M724.9,405.8c8.8-2,15.2-7.8,19.1-12.3c-1.1-3.7-1.9-7.2-1.8-12.5c0.1-6.4,0.2-12.4,0.2-17.8
        c-4-2.1-12.7-5.9-22.9-3.5c-5.3,1.2-10.3,3.9-15,7.9c0.4,3.5,0.7,8.2,0.4,13.6c-0.1,1.2-0.4,3.4-0.8,6
        c-0.7,4.6-1.6,10.7-1.8,15.6C710.2,406.4,717.8,407.4,724.9,405.8z"/>
      <path class="st5" d="M682,475c-5.8,9-6.3,18.2-5.7,24.3c0.7,6.7,2.7,11.3,2.7,11.3c0.1,0.2,11.1,17,24.9,19.9
        c4.2,0.9,8.2,0.3,12-1.5c0.7-9.8,1-16,1-16.2c0.2-22-3.4-36.9-6.5-45.5c-0.4-1.1-0.8-2.2-1.3-3.3c-1.2-2.9-2.4-6-3.6-11.5
        c-1.4,0.6-3.2,1.6-5.4,3.1C695.6,458.5,688.8,464.4,682,475z"/>
      <path class="st5" d="M554.2,530.5c13.7-2.8,24.8-19.7,24.9-19.9c0,0,2.1-4.6,2.7-11.3c0.6-6.1,0.1-15.4-5.7-24.3
        c-6.9-10.6-13.6-16.5-18.1-19.5c-1.2-0.8-2.3-1.5-3.3-2c-1.2,4.9-2.3,7.7-3.4,10.5c-0.4,1.1-0.9,2.2-1.3,3.3
        c-3.1,8.6-6.7,23.6-6.5,45.6c0,0.2,0.3,6.8,1,17.2C547.6,531,550.9,531.1,554.2,530.5z"/>
      <path class="st5" d="M699,229.5c-1.3,1.1-2.6,2.4-3.9,4c-4.7,5.8-9.5,16.7-5.8,35.3c1.3,6.5,9.5,18.1,21.5,22.9
        c6.7,2.7,16.9,4.1,29.1-2.4c0.6-24.9-2.5-36.8-5.3-42.4c-3.3-6.7-10.2-10.4-15.5-12.4c-2.2-0.8-6.2-1.6-10.1-2.3
        c-2.9-0.6-5.6-1.1-6.9-1.5C701.1,230.3,700,229.9,699,229.5z"/>

      <path class="st5" d="M538,359.7c-8.2-1.9-15.5,0.2-20.1,2.2c0.1,5.8,0.2,12.2,0.3,19.2c0.1,5.7-0.9,9.3-2.1,13.3
        c3.9,4.4,10.1,9.6,18.3,11.5c7.4,1.7,15.3,0.6,23.5-3.4c-0.2-4.9-1.1-10.7-1.8-15.2c-0.4-2.6-0.7-4.9-0.8-6
        c-0.3-4.2-0.1-8,0.1-11.1C550.2,364.6,544.3,361.1,538,359.7z"/>
      <path class="st5" d="M774,552.5c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3-0.1-8.1-3.4-9.2-12.5c-0.3-2.4-1.5-10.8-6.2-16
        c-1.1-1.2-2.3-2.3-3.5-3.3c-0.3-0.3-2.8-2.6-4.7-6.6l-0.3-0.5c-0.1-0.5-0.2-1-0.2-1.5c-10,5-20.2,5.9-25.9,5.9
        c-0.2,0.6-0.7,1.6-1,2.4c-0.7,1.5-1.7,3.4-2.3,5.7c-1.7,5.9-3,17.1,0.2,37.2c0.3,2.3,3.4,22,11.6,25.1c1,2.4,3.1,5.3,6.1,5.7
        c0.8,2.1,2.6,4.5,5.5,4.5c0.1,0,0.2,0,0.3,0c1.7,0.1,4.7-1,5.9-5.3c1.2,0.1,2.7,0,4.1-0.8c1-0.6,2.4-1.9,2.9-4.4
        c0-0.1,0-0.1,0-0.2c0.1-1,2-20.9,2-33.7c2.9,3,7.1,6.4,11.6,6.4c0.1,0,0.2,0,0.2,0c1.5-0.2,5.2-1,5.8-3.5
        C777,555.3,776.1,553.8,774,552.5z"/>
      <path class="st5" d="M624.9,902.9c0-0.2,0-1-0.1-1.6l0-0.4c-0.1-3.4-0.2-6.8-0.1-10.4c-6.5,2.1-12.3,2.7-16.9,2.7
        c-1.1,0-2,0-3-0.1c-2-0.1-3.8-0.3-5.2-0.6c0.1,0.5,0.2,1.1,0.2,1.8c0.1,1.2-0.2,2.9-0.3,3.3c-0.3,0.9-0.5,1.7-0.8,2.5
        c-0.2,0.7-0.4,1.3-0.6,1.9c-0.3,1.1-0.6,3-0.8,4.2l-0.1,0.5c-0.5,3.3-1.8,6.1-2.7,7.9c-1.1,2.1-3.8,6.6-5,8.2
        c-3.3,4.5-7.8,8.4-7.8,8.4c-0.1,0.1-0.1,0.1-0.2,0.2c-3.2,3.8-2.1,7-1,8.2c1.7,2.3,5.5,2.8,8.5,2.8c1.8,0,3.3-0.2,3.9-0.3
        c6.9-1.4,12.1-0.6,12.2-0.6c5.7,0.8,12.4,0.6,12.6,0.6c3.5,0,6.1-0.9,8-2.9c2-2,2.5-4.6,2.6-6.1c0,0,0,0,0,0
        c0.6-5.7-2.1-19.1-2.2-19.6C625.7,910.2,625.2,906.6,624.9,902.9z"/>
      <path class="st5" d="M678.5,931.1c0,0-4.5-3.9-7.8-8.4c-1.1-1.5-3.9-6.1-5-8.2c-0.9-1.8-2.1-4.6-2.7-7.9l-0.1-0.5
        c-0.2-1.2-0.5-3.1-0.8-4.2c-0.2-0.6-0.4-1.2-0.6-1.9c-0.2-0.8-0.5-1.6-0.8-2.5c-0.1-0.4-0.4-2.1-0.3-3.3c0.1-0.6,0.2-1.2,0.2-1.8
        c-1.4,0.2-3.2,0.4-5.1,0.6c-0.9,0.1-1.9,0.1-3,0.1c-4.6,0-10.5-0.6-17-2.7c0,3.6,0,7-0.1,10.4l0,0.4c0,0.5-0.1,1.4-0.1,1.6
        c-0.3,3.7-0.8,7.3-1.4,10.6c-0.1,0.6-2.9,14-2.3,19.7c0,0,0,0,0,0c0.1,1.5,0.7,4.1,2.6,6.1c1.9,2,4.6,2.9,8,2.9
        c0.3,0,7,0.2,12.7-0.6c0.1,0,5.3-0.8,12.3,0.6c0.6,0.1,2,0.2,3.8,0.2c3,0,6.8-0.5,8.5-2.8c1.1-1.3,2.2-4.5-1-8.2
        C678.7,931.2,678.6,931.2,678.5,931.1z"/>
      <path class="st5" d="M539.4,527.6l0.1-0.2c0.4,0.2,0.7,0.5,1.1,0.7c-0.2-0.8-0.4-1.6-0.6-2.3c-0.6-2.2-1.6-4.1-2.3-5.7
        c-0.4-0.8-0.9-1.8-1.1-2.4c-0.6,0-1.4,0-2.2,0c-5.3,0-14.5-0.7-23.8-4.9c0,0.2-0.1,0.3-0.1,0.5l-0.2,0.5c-2,4-4.4,6.3-4.8,6.6
        c-1.3,1-2.5,2-3.5,3.3c-4.7,5.3-5.9,13.6-6.2,16c-1,9.2-8.8,12.4-9.1,12.5c-0.1,0-0.2,0.1-0.3,0.2c-2.1,1.4-3,2.8-2.6,4.3
        c0.6,2.5,4.2,3.3,5.8,3.5c0.1,0,0.2,0,0.2,0c4.4,0,8.7-3.4,11.6-6.4c0,12.8,1.9,32.7,2,33.7c0,0.1,0,0.1,0,0.2
        c0.5,2.5,1.9,3.8,2.9,4.4c1.4,0.8,2.9,0.9,4.1,0.8c1.2,4.2,4.2,5.4,5.9,5.3c0.1,0,0.2,0,0.3,0c2.9,0,4.7-2.4,5.5-4.5
        c3-0.3,5.1-3.3,6.1-5.7c8.2-3.1,11.2-22.8,11.6-25.1c2.8-17.5,2.2-28.3,0.9-34.7C540.3,528.1,539.8,527.9,539.4,527.6z"/>
      <path class="st5" d="M539.7,294c4.6,0,8.5-1,11.7-2.3c12-4.8,20.2-16.4,21.5-22.9c3.7-18.6-1.1-29.5-5.8-35.3
        c-1.5-1.9-3.1-3.3-4.5-4.5c-1.3,0.6-2.8,1.2-4.4,1.7c-1.3,0.4-4,0.9-6.9,1.5c-3.9,0.7-7.9,1.5-10.1,2.3
        c-5.2,2-12.2,5.7-15.5,12.4c-2.7,5.5-5.8,17.2-5.3,41.3C527.6,292.5,534.1,294,539.7,294z"/>
      <path class="st5" d="M629.9,709.2c0,0,0.1,0.2,0.2,0.7l0-23.1l-0.1-0.1c-10.9-12.8-24.8-11.8-32-10.1c-5.2,1.3-10.3,3.7-14.4,6.8
        c-1.7,1.3-3.1,2.7-4.2,4.1c0.6,8.6,1.2,22.6,1,30.3c-0.1,4.1-0.4,7.8-0.8,10.9c1.1,1.2,2.4,2.4,3.8,3.6c4.1,3.2,9.2,5.6,14.5,6.9
        c6.3,1.5,17.6,2.5,27.6-5.7C627.4,723.1,629.9,709.2,629.9,709.2z"/>
      <path class="st5" d="M681.2,685.1c-4.1-3.6-10-6.6-16.4-8.2c-13.3-3.3-25.8,0.3-34.4,9.9l-0.1,0.1l0,24.1c0.9,4,3.3,14.8,4.9,23
        c9.7,7.7,20.4,6.8,26.5,5.4c7.8-1.9,15.1-6.4,19-11.4c-0.4-2.9-0.6-6.2-0.7-10C679.8,709.3,680.4,693.2,681.2,685.1z"/>
      <rect x="1000.6" y="886.1" class="st5" width="45.7" height="45.7"/>
      </a>
      <a id="ast8" v-on:click="toogleSvgClass($event,'ast8')" class="svglink" :class="this.questionary.painLowBack ? 'svglinkActive' : 'svglink'">
      <path class="st8" d="M579.3,449.2c9.5,0.9,18.3,4.2,26.2,9.8c9.8,6.9,18.1,17.4,24.7,31.2c6.6-13.8,15-24.3,24.8-31.3
        c7.9-5.6,16.8-8.9,26.4-9.9c9.9-1,18.5,0.8,23.8,2.4c-0.6-2.8-1.1-5.2-1.6-7.4c-1.7-7.5-2.7-12-3.4-17.2
        c-1.4-9.7-6.3-19.4-8.6-23.2c-2.3-3.7-3.6-7.6-4.2-9.3l-0.1-0.3c-1.2-3.4-1.4-7.2-1.2-10.8c-6.6-0.2-12.9-1.3-18.9-3.2
        c-7.6-2.4-14.6-6.2-21-11.1c-10.1-7.8-15.3-16.3-16.1-17.6c-0.7,1.3-6,9.7-16.1,17.6c-6.4,5-13.5,8.7-21,11.1
        c-6,1.9-12.2,3-18.7,3.2c0.2,3.6,0,7.5-1.2,10.8l-0.1,0.3c-0.6,1.7-1.9,5.6-4.2,9.3c-2.4,3.8-7.2,13.5-8.6,23.2
        c-0.8,5.3-1.8,9.8-3.4,17.2c-0.5,2.2-1,4.7-1.7,7.6c0,0.1,0,0.2-0.1,0.2C560.2,450.2,569.1,448.2,579.3,449.2z"/>
        <rect x="1000.6" y="699" class="st8" width="45.7" height="45.7"/>
      </a>
      <a id="ast7" v-on:click="toogleSvgClass($event,'ast7')" class="svglink" :class="this.questionary.painHead ? 'svglinkActive' : 'svglink'">
      <path class="st7" d="M208.6,156.7c0.1,0,0.3,0,0.4,0c2.3,8.3,5,14.3,5.1,14.5c0.9,2.3,2,4.4,3.2,6.3c0.1,1.5,0.1,3,0.2,4.5
        c1-0.5,1.9-0.9,2.5-1.2l0.1,0l1.2,1.8c14.4,11.7,28.2,11.7,44.7,0l1.1-1.9l2.7,1.1c0.1-1.4,0.1-2.9,0.2-4.3
        c1.7-2.8,6.1-10.4,9.5-20.8c1.5-0.1,3.4-0.9,5.4-4c1.4-2.2,5.5-8.9,5.4-19c0-3.6-2.2-6.8-5.1-7.5c-1.4-0.3-2.7-0.1-3.9,0.7
        c-3.8-11.6-24.6-17.4-26.6-18c-20.1-8.1-20.7-22-20.7-22.6c0-0.9-0.7-1.7-1.7-1.8c-0.9-0.1-1.8,0.5-2,1.4c0,0.1-1.9,8.9-7.4,13.5
        c-0.5,0.3-11.1,8.7-15,21.3c-0.3,1.1-0.8,3.3-1.3,6.1c-1.2-0.8-2.5-1-3.9-0.7c-2.9,0.7-5.1,3.9-5.1,7.5
        c-0.1,10.1,4.1,16.8,5.4,19C205.1,156,207.1,156.7,208.6,156.7z"/>
      <path class="st7" d="M665.8,89.3C657.7,78.7,645,72.8,629.1,72v0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0v0
        c-16,0.8-28.6,6.7-36.7,17.4c-9.2,12.1-12.3,30.1-8.3,48.2c6.9,30.9,21.4,47.3,43.2,48.7h-2.1c1.1,0.1,2.1,0.1,3.3,0.1
        c0.4,0,0.7,0,1,0c0.3,0,0.7,0,1,0c1.1,0,2.2,0,3.3-0.1h-2.1c21.8-1.5,36.3-17.9,43.2-48.7C678.2,119.5,675,101.4,665.8,89.3z"/>
      <rect x="1000.8" y="101.5" class="st7" width="45.3" height="45.3"/>
      </a>

      <a id="ast9" v-on:click="toogleSvgClass($event,'ast9')" class="svglink" :class="this.questionary.painBack ? 'svglinkActive' : 'svglink'">
        <path class="st9" d="M701.9,286.7c-7.2-5.6-11.8-13.1-12.8-17.9c-1.6-8.3-1.7-15.7-0.3-22.1c1.1-5.1,3.2-9.6,6.2-13.4
          c1.3-1.6,2.6-2.9,3.9-3.9c-4.5-1.9-7.6-3.9-7.6-3.9c-0.1,0-0.1-0.1-0.2-0.1c-1.2-0.6-2.6-1.3-4.1-2.1l0,0l-5.1-2.6
          c-2.3-1.4-9.9-5.2-11.4-6.2c-1.9-1.2-5.5-3-7.4-3.9l-0.8-0.4c-3.3-1.6-5.6-3.4-7-5.4c-0.3-0.4-0.5-0.8-0.7-1.4
          c-0.7-2.1-0.9-11.2-0.4-22.9c-3.7-0.4-10.6-1.2-16.8-2c-2.6,46.2,2.9,182,3.1,184.9c1.7,1.7,3.9,3.6,6,5.2
          c8.4,6.5,21.6,13.6,39.8,14.3c0.5-7.7,3-14.6,3-14.7c4.4-10.8,7.6-23.1,9.7-33.2C700.2,318.7,702,294.7,701.9,286.7z"/>
        <path class="st9" d="M602,180.5c0.5,11.7,0.1,20.8-0.6,23c-0.2,0.6-0.4,1-0.7,1.4c-1.4,1.9-3.7,3.7-7,5.4l-0.8,0.4
          c-1.9,0.9-5.5,2.7-7.4,3.9c-1.7,1.1-8.1,5.6-12.5,9c-1.3,0.7-2.5,1.3-3.6,1.9c-0.1,0-0.1,0.1-0.2,0.1c0,0-2.6,1.7-6.4,3.4
          c1.5,1.2,3,2.6,4.5,4.5c3,3.7,5.1,8.2,6.2,13.4c1.4,6.4,1.3,13.9-0.3,22.1c-0.8,4.2-4.4,10.4-10.1,15.6
          c-1.4,8.1-2.5,19.8-2.6,21.6c0.3,0.1,0.6,0.3,1.1,0.5c18.9,8.2,38.2,8.9,59.4,1.5c0.6-40.7,1.6-102.6,2-129.8
          C616,179,605.8,180.1,602,180.5z"/>
        <rect x="999.5" y="479.4" class="st9" width="45.7" height="45.7"/>
      </a>

      <a id="ast10" v-on:click="toogleSvgClass($event,'ast10')" class="svglink" :class="this.questionary.painCenterSpine ? 'svglinkActive' : 'svglink'">
        <path class="st10" d="M637.2,178.6c-6.5-3-14.3-0.4-14.3-0.4s-2.7,178.2-3,185.3c6.8-6.6,10.1-12.4,10.1-12.4l0.1-0.2l0.1,0.2
          c0,0.1,3.3,5.8,10.1,12.4C640,356.4,637.2,176.5,637.2,178.6z"/>
        <rect x="999.5" y="412.5" class="st10" width="45.7" height="45.7"/>
      </a>
      <path d="M663.7,710.5c-5.8,1.2-11.8,0-11.8,0c-0.4-0.1-0.8,0.2-0.9,0.6c-0.1,0.4,0.2,0.8,0.6,0.9c0.2,0,2.9,0.6,6.4,0.6
        c1.8,0,3.9-0.1,6-0.6c0.4-0.1,0.7-0.5,0.6-0.9C664.6,710.7,664.1,710.4,663.7,710.5z"/>
      <path d="M651.7,437.3c-0.4-0.1-0.8,0.3-0.9,0.7c0,0-0.4,3.2-1,5.6c-0.1,0.4,0.2,0.9,0.6,1c0.1,0,0.1,0,0.2,0
        c0.4,0,0.7-0.2,0.8-0.6c0.6-2.4,1-5.6,1-5.8C652.5,437.7,652.1,437.4,651.7,437.3z"/>
      <path d="M717.9,381.9c-0.4,0.2-0.6,0.6-0.5,1c0,0,1.3,3.6,0,7.5c-0.1,0.4,0.1,0.9,0.5,1c0.1,0,0.2,0,0.2,0c0.3,0,0.6-0.2,0.7-0.5
        c1.5-4.4,0.1-8.4,0-8.5C718.8,382,718.3,381.8,717.9,381.9z"/>
      <path d="M644,288.7c0-0.4-0.4-0.8-0.9-0.7c-0.4,0-0.8,0.4-0.7,0.9c0.1,0.9,2.3,22.3,13.9,30.8c0.1,0.1,0.3,0.2,0.5,0.2
        c0.2,0,0.5-0.1,0.6-0.3c0.3-0.4,0.2-0.8-0.2-1.1C646.2,310.3,644.1,288.9,644,288.7z"/>
      <path d="M596.3,712c2.1,0.4,4.2,0.6,6,0.6c3.6,0,6.3-0.5,6.4-0.6c0.4-0.1,0.7-0.5,0.6-0.9c-0.1-0.4-0.5-0.7-0.9-0.6
        c-0.1,0-6,1.2-11.8,0c-0.4-0.1-0.8,0.2-0.9,0.6C595.6,711.5,595.9,711.9,596.3,712z"/>
      <path d="M635.3,474l-5.1,7l-5.1-7c-0.3-0.3-0.7-0.4-1.1-0.2c-0.4,0.3-0.4,0.8-0.2,1.1l5.8,7.9c0.1,0.2,0.4,0.3,0.6,0.3
        c0.3,0,0.5-0.1,0.6-0.3l5.8-7.9c0.3-0.4,0.2-0.8-0.2-1.1C636.1,473.6,635.6,473.7,635.3,474z"/>
      <path d="M609.5,438c-0.1-0.4-0.5-0.7-0.9-0.7c-0.4,0.1-0.7,0.4-0.7,0.9c0,0.1,0.4,3.3,1,5.8c0.1,0.4,0.4,0.6,0.8,0.6
        c0.1,0,0.1,0,0.2,0c0.4-0.1,0.7-0.5,0.6-1C609.9,441.2,609.5,438,609.5,438z"/>
      <path d="M542.5,381.9c-0.4-0.2-0.9,0.1-1,0.5c-0.1,0.2-1.5,4.1,0,8.5c0.1,0.3,0.4,0.5,0.7,0.5c0.1,0,0.2,0,0.2,0
        c0.4-0.1,0.6-0.6,0.5-1c-1.3-3.9,0-7.4,0-7.5C543.1,382.5,542.9,382.1,542.5,381.9z"/>
      <path d="M617.2,288c-0.4,0-0.8,0.3-0.9,0.7c0,0.2-2.2,21.6-13.2,29.6c-0.4,0.3-0.4,0.7-0.2,1.1c0.2,0.2,0.4,0.3,0.6,0.3
        c0.2,0,0.3,0,0.5-0.2c11.6-8.4,13.8-29.9,13.9-30.8C617.9,288.4,617.6,288,617.2,288z"/>
      <path d="M555.9,180.5"/>
      <path d="M605.6,184.2c0-0.7,0-1.4-0.1-2c0,0,0,0.1,0.1,0.1v-5.5h-4.1c0.1,0.1,0.1,0.2,0.2,0.3c0.7,13.8,0.6,24.2-0.2,26.6
          c-0.2,0.6-0.4,1-0.7,1.4c-1.4,1.9-3.7,3.7-7,5.4l-0.8,0.4c-1.9,0.9-5.5,2.7-7.4,3.9c-1.7,1.1-8.1,5.6-12.5,9l5.4,0.3
          c3.7-2.4,6.9-4.9,9-6.2c1.8-1.1,5.4-2.9,7.1-3.7l0.9-0.4c3.8-1.9,6.6-4.1,8.4-6.5c0.5-0.6,0.8-1.4,1.2-2.3
          c0.4-1,0.6-2.9,0.7-5.1V184.2z"/>
      <path d="M681.8,220.8c-2.3-1.4-9.9-5.2-11.4-6.2c-1.9-1.2-5.5-3-7.4-3.9l-0.8-0.4c-3.3-1.6-5.6-3.4-7-5.4
          c-0.3-0.4-0.5-0.8-0.7-1.4c-0.8-2.4-0.9-12.8-0.2-26.6c0-0.1,0.1-0.1,0.1-0.2h-4.1c0,0,0,5.5,0.1,5.4
          c-0.3,8.6-0.5,19.4,0.7,22.6c0.3,0.9,0.7,1.7,1.2,2.3c1.7,2.4,4.5,4.6,8.4,6.5l0.8,0.4c1.7,0.8,5.3,2.6,7.1,3.7
          c2,1.2,13.3,7.2,17.7,9.4L681.8,220.8z"/>
      <path d="M555.9,153.4"/>
      <path d="M688.6,147.2c-0.4-1.2,0.8-1.9,1.2-2.4c8.1-9.2,9.3-13.1,5.2-17.6c-8.8-9.4-8.7-14.6-8.5-21.1c0.1-2.3,0.1-4.7-0.2-7.4
        C683.6,76,663.4,58.4,638.4,57c-5.7-0.3-11.2,1.3-15.5,2.6c-2.4,0.7-4.5,1.3-5.8,1.3c-17.5,0-29.3,7.7-35,22.8
        c-0.7,1.8-2.6,5-3.1,5.5c-2.1,1.9-4.2,2-7.2,2.3c-0.7,0.1-1.4,0.1-2.1,0.2c-2.8,0.3-5.1,1.6-6.9,4c-6.6,8.5-3.8,28-3.7,28.9
        c2.8,15.4,0.2,18.7-3.1,23.5c-0.2,0.1-0.3,0.3-0.4,0.5c-2.7,4.9-3.2,9.6-1.5,13.9c3.4,8.2,14.1,12,16.3,12.7
        c1.7,1.2,9.3,5.8,27.1,5.8c6.5,0,14.3-0.6,23.7-2.2c7.3-1.2,12.7-0.5,19,0.5c6.2,0.9,13.3,1.9,23.6,1.3
        c10.1-0.7,18.1-2.2,23.6-4.6c5-2.2,8.2-5.1,9.4-8.8C700.1,157.7,689.4,147.9,688.6,147.2z M693.1,165.7
        c-1.4,4.1-7.7,9.3-29.5,10.7c-9.9,0.6-16.7-0.4-22.8-1.2c-6.6-1-12.4-1.8-20.3-0.4c-18.9,3.2-30.9,2.2-37.7,0.8
        c-7.3-1.5-10.2-3.8-10.2-3.8l-0.3-0.3l-0.4-0.1c-0.1,0-11.1-3.3-14.1-10.5c-1.3-3.1-0.9-6.5,1.3-10.4c0-0.1,0.1-0.1,0.1-0.2l0,0
        c3.5-4.3,6.8-11.6,3.4-26.3c-0.8-5.2-1.7-20,3-26.1c1.2-1.5,2.6-2.4,4.4-2.6c0.7-0.1,1.4-0.1,2-0.2c3.3-0.3,6.4-0.5,9.3-3.2
        c1.1-1,3.4-5.1,4.1-7c5.2-13.7,15.5-20.4,31.6-20.4c1.8,0,4-0.7,6.9-1.5c4-1.2,9.1-2.7,14.2-2.4c23.3,1.4,42,17.6,44.5,38.6
        c0.3,2.4,0.2,4.7,0.2,6.9c-0.2,6.8-0.3,13.2,9.5,23.7c1.8,1.9,3.1,3.3-5.3,12.7c-1.1,1.2-3.4,4-1.6,7.3c0,0,0,0,0,0
        c0.1,0.2,0.2,0.3,0.4,0.4C688.6,152.5,695.1,160,693.1,165.7z"/>
      <path d="M774,552.5c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3-0.1-8.1-3.4-9.2-12.5c-0.3-2.4-1.5-10.8-6.2-16c-1.1-1.2-2.3-2.3-3.5-3.3
        c-0.3-0.3-2.8-2.6-4.7-6.6l-0.3-0.5c-0.4-2.8-1-6.9-1.2-9.4c-0.5-5.7-1-21.2-1-29.9c-0.1-20.5,0.4-48,0.4-48.3
        c0.1-7.3-0.2-14.5-0.7-18.4c-0.7-5.2-1.8-8.9-2.8-12.2c-1.3-4.4-2.4-8.1-2.4-14.3c0.1-11.9,0.3-22,0.5-30.2
        c0.3-12.3,0.5-21.2,0.1-27.3c-0.4-7.9-1-11.1-1-11.1c-0.7-3.8-1.1-10.1-1.4-14.7c-0.2-3.6-0.3-6.4-0.4-6.9
        c0.8-25.9-2.4-38.2-5.3-43.9c-3.3-6.7-10.2-10.4-15.5-12.4c-2.2-0.8-6.2-1.6-10.1-2.3c-2.9-0.6-5.6-1.1-6.9-1.5
        c-6.3-2.1-11-5.1-11-5.1c-0.1,0-0.1-0.1-0.2-0.1c-1.2-0.6-2.6-1.3-4.1-2.1l0,0l-5.3-2.7c0,0.6,0,1.2,0,1.8
        c0.1,0.8,0.1,1.6,0.2,2.4l3.6,1.9c0,0,0,0,0,0c0,0,0,0,0,0c1.3,0.7,2.6,1.4,3.8,2c0.7,0.5,5.5,3.4,11.8,5.5
        c1.5,0.5,4.2,1,7.3,1.6c3.6,0.7,7.6,1.5,9.5,2.2c4.6,1.7,10.7,5,13.5,10.6c2.2,4.4,5.7,15.7,4.9,42.2c0,0,0,0.1,0,0.1
        c0,0,0.1,3,0.4,7c0.3,4.7,0.8,11.1,1.5,15.1c0,0,0.6,3.1,1,10.7c0.3,5.9,0.1,14.8-0.1,27c-0.2,8.2-0.4,18.4-0.5,30.2
        c-0.1,6.7,1.2,10.9,2.5,15.4c1,3.3,2,6.7,2.7,11.6c0.5,3.7,0.8,10.7,0.7,17.8c0,0.3-0.5,27.9-0.4,48.4c0,8.8,0.5,24.3,1,30.2
        c0.3,2.8,0.8,7.1,1.3,9.9c0,0.2,0.1,0.3,0.2,0.5l0.4,0.8c2.5,5,5.5,7.6,5.8,7.8c1.1,0.8,2.1,1.8,3,2.8c3.1,3.5,4.8,9.5,5.3,14
        c1.2,10.6,9.8,14.8,11.2,15.4c0.3,0.2,0.4,0.3,0.6,0.4c-0.5,0.2-1.4,0.5-2.2,0.6c-4.6-0.1-10.2-6.5-11.9-9
        c-0.5-0.7-1.3-0.9-2.1-0.7c-0.8,0.3-1.3,1-1.2,1.8c0.8,10.8-1.7,36.5-1.8,38.3c-0.2,0.9-0.6,1.5-1.1,1.9
        c-0.9,0.5-2.2,0.4-2.8,0.2c0.2-4.8,0.6-18.5-0.1-26.2c0-0.4-0.4-0.8-0.9-0.7c-0.4,0-0.8,0.4-0.7,0.9c0.6,7.7,0.2,21.4,0.1,26.1
        c-0.1,0-0.2,0.1-0.2,0.1c-0.5,0.3-0.8,0.8-0.8,1.3c-0.5,3.9-2.4,4-2.6,4c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0
        c-1.7,0-2.5-3.1-2.5-3.1c-0.1-0.6-0.5-1-1-1.2v-27.3c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8v27.1
        c-2.1-0.1-3.6-4.1-3.6-4.1c-0.2-0.6-0.7-1-1.3-1.2c-0.7-3.9-2.5-14.6-2.1-23.9c0-0.4-0.3-0.8-0.8-0.8c-0.4,0-0.8,0.3-0.8,0.8
        c-0.4,8.7,1.1,18.5,1.9,23.1c-4-3.2-7-14.3-8-21.5c-3.1-19.5-1.9-30.1-0.3-35.7c0.6-1.9,1.4-3.6,2.1-5.1c0.9-1.8,1.6-3.3,1.5-4.6
        c0-0.3-2.6-29.2-10.6-57.3c-2.4-8.3-6.3-22.6-7.3-26.7c-2.3-10-3.5-19.5-3.6-28.2c0-5,1-12.1,1.8-17.3c0.4-2.8,0.7-5,0.8-6.3
        c0.8-12-1.5-20.3-1.8-21.4c-2.8-16.2-4.7-36.1-4.9-52c0-1-0.8-1.8-1.8-1.8c0,0,0,0,0,0c-1,0-1.8,0.8-1.8,1.8c0,2.9,0.1,6,0.3,9.1
        c0,0.1-0.1,0.2-0.1,0.3c-1.4,8.8-5.4,31.3-12.7,49.6c-0.2,0.7-6,16.4-1.9,28.3l0.1,0.3c0.6,1.8,2,6,4.6,10
        c2.2,3.5,6.8,12.6,8.1,21.8c0.8,5.4,1.8,10,3.5,17.5c0.5,2.2,1,4.7,1.7,7.6c1.4,6.3,2.8,9.8,4,12.8c0.4,1.1,0.8,2.1,1.2,3.2
        c3,8.4,6.5,22.8,6.3,44.2c0,0.9-3.3,87-24.7,133.5c-0.3,0.7-7.6,16.8-10.5,34.8c-1,6.3-1.9,27.4-1.7,37c0.1,5.3,0.5,9.9,1.3,13.6
        c0.1,0.6,0.5,1.7,1,3.4c1.1,3.4,2.9,9.1,4,14.9c2.3,12.9,3.4,33.5-4.8,58.6c0,0.1-4,13.4-9.1,32.2c-2.6,9.5-7,28.3-9.7,42
        c-0.3,1.6-0.6,2.9-0.8,4.1c-0.5,2.4-0.9,4.2-1.3,7.2c-0.2,1.9,0.3,4.2,0.4,4.7c0.3,1,0.5,1.8,0.8,2.6c0.2,0.6,0.4,1.2,0.5,1.7
        c0.2,0.9,0.5,2.8,0.7,3.8l0.1,0.5c0.2,1.2,0.5,2.4,0.8,3.5c-0.2,2.2-0.7,5.5-1.8,10.2c-0.1,0.4,0.2,0.8,0.6,0.9
        c0.1,0,0.1,0,0.2,0c0.4,0,0.7-0.2,0.8-0.6c0.7-2.9,1.1-5.3,1.4-7.3c0.3,0.8,0.7,1.5,1.1,2.2c1.1,2.2,4,6.9,5.3,8.6
        c3.3,4.5,7.5,8.2,8.3,8.9c1.8,2.2,1,3.3,0.9,3.4c-0.1,0.1-0.1,0.2-0.2,0.2c-0.3,0.4-0.8,0.7-1.6,0.9c0.4-1.2,0.5-2.3,0.5-2.4
        c0-0.4-0.3-0.8-0.7-0.9c-0.4,0-0.8,0.3-0.9,0.7c0,0-0.1,1.5-0.8,2.8c-1.6,0.2-3.6,0.1-5.3-0.1c-5.1-1-9.3-1-11.6-0.8
        c1.4-2.7,1.2-4.8,1.2-4.8c0-2.1-0.7-4.4-2.1-6.6c-0.4-0.6-1-1.5-1.7-2.4c-0.9-1.2-1.9-2.6-2.2-3.3c-3.1-6.2-3-13.6-3-13.6
        c0-0.4-0.3-0.8-0.8-0.8c0,0,0,0,0,0c-0.4,0-0.8,0.3-0.8,0.8c0,0.3-0.1,7.7,3.2,14.3c0.4,0.7,1.3,2.1,2.4,3.5
        c0.6,0.9,1.3,1.7,1.6,2.3c0.9,1.3,1.9,3.4,1.9,5.8c0,0.1,0.2,2.2-1.5,4.9c-5.4,0.7-11.9,0.6-12,0.6c-2.3,0.1-4.2-0.5-5.3-1.8
        c-1.8-1.8-1.7-4.6-1.7-4.7c0-0.2,0-0.3,0-0.5c0-1.5,0.1-3.4,0.3-5.4c1.1-2.6,2.4-4.8,2.5-4.9c3-5.1,4.6-13.8,4.6-14.2
        c0.1-0.4-0.2-0.8-0.6-0.9c-0.4-0.1-0.8,0.2-0.9,0.6c0,0.1-1.6,8.8-4.4,13.6c0,0.1-0.2,0.3-0.5,0.8c0.6-3.8,1.3-7,1.3-7.1
        c0.6-3.4,1.1-7.1,1.4-10.9c0-0.2,0.1-1.1,0.1-1.8l0-0.4c0.7-16.4-0.8-32.9-3.8-65.9l-0.5-5c-1.7-18.5-2.6-33.4-2.9-38.8
        c0.1-1.3,0.1-2.1,0.1-2.2c0-1.3,0-2.6-0.1-4c0.6-11.4,3.5-24.6,5-31.4c0.5-2.3,0.8-3.8,0.9-4.5c0.4-3.4,0.3-7.1-0.5-10.9
        c-0.3-1.8-0.9-4.1-1.5-6.4c-0.6-2.4-1.2-5-1.4-6.3c-2.3-17.4-2.3-53.1-2.2-72.8c0.1-9.6,0.6-21.1,0.7-22.8
        c0.3-5.9,0.4-11.1,0.3-15.5c-0.1-3.7-1.1-52-1.5-70.2c2.9,1.9,8.8,4.3,19.6,4.3c1.1,0,27.6-0.8,35.1-13.1
        c0.2-0.4,0.1-0.9-0.3-1.1c-0.4-0.2-0.9-0.1-1.1,0.3c-7,11.6-33.5,12.4-33.7,12.4c-13.1,0-18.6-3.7-20.2-5.2
        c-0.1-0.4-0.3-0.8-0.4-1.1v-30.5c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8v30.5c-0.2,0.3-0.3,0.7-0.4,1.1
        c-1.6,1.5-7.1,5.2-20.2,5.2c-0.3,0-26.8-0.7-33.8-12.4c-0.2-0.4-0.7-0.5-1.1-0.3c-0.4,0.2-0.5,0.7-0.3,1.1
        c7.4,12.4,33.9,13.1,35.1,13.1c10.7,0,16.6-2.4,19.6-4.3c-0.4,18.2-1.4,66.5-1.5,70.2c-0.1,4.4,0,9.7,0.3,15.5
        c0.1,1.7,0.6,13.2,0.7,22.8c0.2,19.7,0.2,55.4-2.2,72.8c-0.2,1.3-0.8,3.8-1.4,6.3c-0.6,2.3-1.2,4.6-1.5,6.4
        c-0.7,3.9-0.9,7.5-0.5,10.9c0.1,0.7,0.4,2.2,0.9,4.5c1.5,6.8,4.4,20,5,31.4c-0.1,1.4-0.1,2.7-0.1,4.1c0,0,0,0.8,0.1,2.1
        c-0.3,5.5-1.2,20.3-2.9,38.8l-0.5,5c-3,33-4.5,49.5-3.8,65.9l0,0.4c0,0.6,0.1,1.6,0.1,1.7c0.3,3.8,0.8,7.5,1.4,11
        c0,0.1,0.7,3.2,1.3,7.1c-0.2-0.4-0.4-0.7-0.4-0.8c-2.9-4.8-4.4-13.6-4.4-13.6c-0.1-0.4-0.5-0.7-0.9-0.6c-0.4,0.1-0.7,0.5-0.6,0.9
        c0.1,0.4,1.6,9.1,4.6,14.2c0,0,1.4,2.2,2.5,4.9c0.2,2,0.4,3.9,0.3,5.4c0,0.2-0.1,0.3,0,0.5c0,0,0.1,2.8-1.7,4.6
        c-1.2,1.2-3,1.8-5.4,1.8c-0.1,0-6.6,0.1-12-0.6c-1.7-2.7-1.6-4.8-1.5-5c0-2.3,1-4.4,1.9-5.7c0.4-0.6,1-1.5,1.6-2.3
        c1-1.4,2-2.8,2.4-3.5c3.2-6.6,3.2-14,3.2-14.3c0-0.4-0.4-0.8-0.8-0.8c0,0,0,0,0,0c-0.4,0-0.8,0.4-0.8,0.8c0,0.1,0,7.4-3,13.6
        c-0.3,0.6-1.3,2-2.2,3.3c-0.7,0.9-1.3,1.8-1.7,2.4c-1.4,2.2-2.2,4.5-2.1,6.5c0,0.1-0.2,2.2,1.2,4.9c-2.3-0.1-6.5-0.2-11.5,0.8
        c-1.8,0.2-3.7,0.3-5.4,0.1c-0.6-1.3-0.7-2.8-0.8-2.8c0-0.4-0.4-0.8-0.9-0.7c-0.4,0-0.8,0.4-0.7,0.9c0,0.1,0.1,1.1,0.5,2.3
        c-0.8-0.2-1.4-0.5-1.6-0.9c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.9-1.2,0.9-3.4c0.8-0.7,4.9-4.4,8.3-8.9c1.3-1.7,4.1-6.4,5.3-8.6
        c0.4-0.7,0.7-1.5,1.1-2.2c0.3,1.9,0.8,4.3,1.4,7.3c0.1,0.4,0.4,0.6,0.8,0.6c0.1,0,0.1,0,0.2,0c0.4-0.1,0.7-0.5,0.6-0.9
        c-1.1-4.7-1.6-8-1.8-10.2c0.3-1.2,0.6-2.3,0.8-3.5l0.1-0.5c0.2-1,0.4-2.9,0.7-3.8c0.2-0.5,0.3-1.1,0.5-1.7
        c0.3-0.8,0.5-1.7,0.8-2.6c0.1-0.5,0.6-2.8,0.4-4.7c-0.4-2.9-0.8-4.8-1.3-7.2c-0.2-1.2-0.5-2.4-0.8-4.1c-2.6-13.8-7.1-32.5-9.7-42
        c-5.1-18.8-9.1-32.1-9.1-32.2c-8.2-25.1-7.1-45.7-4.8-58.6c1.1-5.8,2.9-11.5,4-14.9c0.5-1.6,0.9-2.7,1-3.4
        c0.7-3.7,1.2-8.3,1.3-13.6c0.2-9.6-0.7-30.7-1.7-37c-2.9-18-10.2-34.1-10.5-34.8c-21.4-46.5-24.7-132.6-24.7-133.4
        c-0.2-21.4,3.3-35.9,6.3-44.3c0.4-1.1,0.8-2.1,1.2-3.2c1.2-3,2.6-6.5,4-12.8c0.6-2.8,1.2-5.3,1.7-7.6c1.7-7.5,2.7-12.1,3.5-17.5
        c1.3-9.1,5.9-18.2,8.1-21.8c2.5-4.1,4-8.3,4.6-10l0.1-0.3c4.1-11.8-1.7-27.6-1.9-28.3c-7.3-18.2-11.3-40.7-12.7-49.5
        c0-0.1,0-0.2-0.1-0.3c0.1-3.1,0.3-6.2,0.3-9.1c0-1-0.8-1.8-1.8-1.8c0,0,0,0,0,0c-1,0-1.8,0.8-1.8,1.8c-0.2,15.9-2.1,35.8-4.9,52
        c-0.3,1.1-2.5,9.5-1.8,21.4c0.1,1.3,0.4,3.5,0.8,6.3c0.8,5.2,1.8,12.3,1.8,17.3c0,8.7-1.2,18.2-3.6,28.2
        c-1,4.1-4.9,18.4-7.3,26.7c-8,28.1-10.6,57-10.6,57.3c-0.1,1.4,0.6,2.8,1.5,4.6c0.7,1.5,1.5,3.1,2.1,5.1
        c1.6,5.5,2.8,16.2-0.3,35.7c-1,7.2-4,18.3-8,21.5c0.8-4.7,2.3-14.4,1.9-23.1c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8
        c0.4,9.4-1.3,20-2.1,23.9c-0.6,0.2-1.1,0.6-1.3,1.2c-0.3,0.9-1.8,4-3.6,4.1v-27.1c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8
        v27.3c-0.5,0.2-0.9,0.7-1,1.2c0,0-0.8,3.2-2.6,3.1c-0.1,0-0.1,0-0.2,0h0c-0.2,0-2.1-0.1-2.6-4c-0.1-0.5-0.4-1-0.8-1.3
        c-0.1,0-0.2-0.1-0.2-0.1c-0.2-4.7-0.6-18.4,0.1-26.1c0-0.4-0.3-0.8-0.7-0.9c-0.4,0-0.8,0.3-0.9,0.7c-0.6,7.8-0.2,21.5-0.1,26.2
        c-0.7,0.2-2,0.3-2.8-0.2c-0.6-0.3-0.9-0.9-1.1-1.9c-0.2-1.8-2.6-27.5-1.8-38.3c0.1-0.8-0.4-1.6-1.2-1.8c-0.8-0.3-1.6,0-2.1,0.7
        c-1.7,2.5-7.3,8.9-11.9,9c-0.8-0.1-1.7-0.4-2.2-0.6c0.1-0.1,0.3-0.3,0.6-0.4c1.4-0.6,10-4.8,11.2-15.4c0.5-4.4,2.2-10.5,5.3-14
        c0.9-1,2-2,3.1-2.9c0.1-0.1,3.2-2.8,5.7-7.8l0.4-0.8c0.1-0.2,0.1-0.3,0.2-0.5c0.4-2.8,1-7.1,1.3-9.9c0.5-6,1-21.4,1-30.2
        c0.1-20.5-0.4-48.1-0.4-48.4c-0.1-7.1,0.2-14.1,0.7-17.8c0.7-4.9,1.7-8.3,2.7-11.6c1.3-4.4,2.6-8.6,2.5-15.4
        c-0.1-11.9-0.3-22.1-0.5-30.2c-0.3-12.2-0.4-21.1-0.1-27c0.4-7.6,1-10.7,1-10.7c0.7-4,1.2-10.4,1.5-15.1c0.2-4,0.4-7,0.4-7
        c0,0,0-0.1,0-0.1c-0.8-26.5,2.7-37.8,4.9-42.2c2.8-5.6,8.9-8.8,13.5-10.6c1.9-0.7,6-1.5,9.5-2.2c3.1-0.6,5.8-1.1,7.3-1.6
        c6.3-2.1,11.1-5,11.8-5.5c0.8-0.4,1.7-0.8,2.5-1.3l5.3-3.9c-0.7-0.9-1.4-1.8-2.3-2.7l-2.8,2.3c-1.6,0.9-3.1,1.7-4.5,2.4
        c-0.1,0-0.1,0.1-0.2,0.1c0,0-4.8,3.1-11,5.1c-1.3,0.4-4,0.9-6.9,1.5c-3.9,0.7-7.9,1.5-10.1,2.3c-5.2,2-12.2,5.7-15.5,12.4
        c-2.8,5.7-6,18-5.3,43.9c0,0.5-0.1,3.3-0.4,6.9c-0.3,4.6-0.8,10.9-1.4,14.7c0,0.1-0.6,3.3-1.1,11.2c-0.3,6.1-0.1,15,0.1,27.3
        c0.2,8.2,0.4,18.3,0.5,30.2c0.1,6.2-1.1,9.9-2.4,14.3c-1,3.3-2.1,7-2.8,12.2c-0.5,3.9-0.8,11.1-0.7,18.4c0,0.3,0.5,27.9,0.4,48.3
        c0,8.7-0.5,24.2-1,29.9c-0.2,2.5-0.8,6.6-1.2,9.4l-0.2,0.5c-2,4-4.4,6.3-4.8,6.6c-1.3,1-2.5,2-3.5,3.3c-4.7,5.3-5.9,13.6-6.2,16
        c-1,9.2-8.8,12.4-9.1,12.5c-0.1,0-0.2,0.1-0.3,0.2c-2.1,1.4-3,2.8-2.6,4.3c0.6,2.5,4.2,3.3,5.8,3.5c0.1,0,0.2,0,0.2,0
        c4.4,0,8.7-3.4,11.6-6.4c0,12.8,1.9,32.7,2,33.7c0,0.1,0,0.1,0,0.2c0.5,2.5,1.9,3.8,2.9,4.4c1.4,0.8,2.9,0.9,4.1,0.8
        c1.2,4.2,4.2,5.4,5.9,5.3c0.1,0,0.2,0,0.3,0c2.9,0,4.7-2.4,5.5-4.5c3-0.3,5.1-3.3,6.1-5.7c8.2-3.1,11.2-22.8,11.6-25.1
        c3.3-20.1,1.9-31.4,0.3-37.2c-0.6-2.2-1.6-4.1-2.3-5.7c-0.5-1-1.1-2.3-1.1-2.7c0-0.3,2.6-28.8,10.5-56.6
        c1.9-6.8,6.2-22.2,7.3-26.9c2.4-10.3,3.6-20.1,3.7-29c0-5.2-1.1-12.5-1.8-17.8c-0.4-2.6-0.7-4.9-0.8-6
        c-0.8-11.9,1.6-20.2,1.7-20.3c0-0.1,0-0.1,0-0.2c1.5-8.9,2.8-19,3.7-28.9c2.2,10.7,5.6,24.5,10.4,36.5c0.1,0.2,5.5,15.2,1.9,25.8
        l-0.1,0.3c-0.6,1.7-1.9,5.6-4.2,9.3c-2.4,3.8-7.2,13.5-8.6,23.2c-0.8,5.3-1.8,9.8-3.4,17.2c-0.5,2.2-1,4.7-1.7,7.6
        c-1.3,6-2.6,9.2-3.8,12.2c-0.4,1.1-0.9,2.2-1.3,3.3c-3.1,8.6-6.7,23.6-6.5,45.6c0,0.9,3.3,87.7,25.1,134.8
        c0.1,0.2,7.4,16.4,10.2,33.8c0.9,5.6,1.8,26.3,1.6,36.4c-0.1,5.1-0.5,9.5-1.2,13c-0.1,0.4-0.5,1.6-0.9,3c-1.1,3.5-3,9.3-4.1,15.4
        c-2.4,13.3-3.5,34.6,4.9,60.3c0,0.1,4,13.4,9.1,32.1c2.6,9.4,7,28.1,9.6,41.8c0.3,1.6,0.6,3,0.8,4.1c0.5,2.4,0.9,4.1,1.2,6.9
        c0.1,1.2-0.2,2.9-0.3,3.3c-0.3,0.9-0.5,1.7-0.8,2.5c-0.2,0.7-0.4,1.3-0.6,1.9c-0.3,1.1-0.6,3-0.8,4.2l-0.1,0.5
        c-0.5,3.3-1.8,6.1-2.7,7.9c-1.1,2.1-3.8,6.6-5,8.2c-3.3,4.5-7.8,8.4-7.8,8.4c-0.1,0.1-0.1,0.1-0.2,0.2c-3.2,3.8-2.1,7-1,8.2
        c1.7,2.3,5.5,2.8,8.5,2.8c1.8,0,3.3-0.2,3.9-0.3c6.9-1.4,12.1-0.6,12.2-0.6c5.7,0.8,12.4,0.6,12.6,0.6c3.5,0,6.1-0.9,8-2.9
        c2-2,2.5-4.6,2.6-6.1c0,0,0,0,0,0c0.6-5.7-2.1-19.1-2.2-19.6c-0.6-3.3-1.1-6.9-1.4-10.6c0-0.2,0-1-0.1-1.6l0-0.4
        c-0.7-16.1,0.8-32.6,3.8-65.4l0.5-5c0.4-4.5,0.8-8.8,1.1-12.8c0.3,4,0.7,8.3,1.1,12.8l0.5,5c3,32.8,4.5,49.3,3.8,65.4l0,0.4
        c0,0.5-0.1,1.4-0.1,1.6c-0.3,3.7-0.8,7.3-1.4,10.6c-0.1,0.6-2.9,14-2.3,19.7c0,0,0,0,0,0c0.1,1.5,0.7,4.1,2.6,6.1
        c1.9,2,4.6,2.9,8,2.9c0.3,0,7,0.2,12.7-0.6c0.1,0,5.3-0.8,12.3,0.6c0.6,0.1,2,0.2,3.8,0.2c3,0,6.8-0.5,8.5-2.8
        c1.1-1.3,2.2-4.5-1-8.2c-0.1-0.1-0.1-0.1-0.2-0.2c0,0-4.5-3.9-7.8-8.4c-1.1-1.5-3.9-6.1-5-8.2c-0.9-1.8-2.1-4.6-2.7-7.9l-0.1-0.5
        c-0.2-1.2-0.5-3.1-0.8-4.2c-0.2-0.6-0.4-1.2-0.6-1.9c-0.2-0.8-0.5-1.6-0.8-2.5c-0.1-0.4-0.4-2.1-0.3-3.3c0.3-2.8,0.7-4.5,1.2-6.9
        c0.2-1.2,0.5-2.5,0.8-4.1c2.6-13.7,7-32.3,9.6-41.8c5.1-18.7,9.1-32,9.1-32.1c8.4-25.8,7.3-47.1,4.9-60.4
        c-1.1-6.1-3-11.9-4.1-15.4c-0.4-1.4-0.8-2.5-0.9-3c-0.7-3.5-1.1-7.9-1.2-13c-0.2-10,0.7-30.8,1.6-36.4
        c2.8-17.5,10.2-33.7,10.2-33.8c21.8-47.1,25-134,25.1-134.9c0.2-22-3.4-36.9-6.5-45.5c-0.4-1.1-0.8-2.2-1.3-3.3
        c-1.2-3.1-2.5-6.2-3.8-12.2c-0.6-2.8-1.2-5.3-1.7-7.6c-1.7-7.5-2.7-12-3.4-17.2c-1.4-9.7-6.3-19.4-8.6-23.2
        c-2.3-3.7-3.6-7.6-4.2-9.3l-0.1-0.3c-3.6-10.6,1.8-25.7,1.9-25.8c4.8-12,8.3-25.8,10.4-36.5c0.9,9.9,2.1,19.9,3.7,28.9
        c0,0.1,0,0.1,0,0.2c0,0.1,2.4,8.4,1.7,20.3c-0.1,1.2-0.4,3.4-0.8,6c-0.8,5.3-1.9,12.6-1.8,17.8c0,8.9,1.3,18.7,3.7,29
        c1.1,4.6,5.4,20.1,7.3,26.9c7.9,27.8,10.4,56.3,10.5,56.6c0,0.4-0.6,1.7-1.1,2.7c-0.7,1.5-1.7,3.4-2.3,5.7
        c-1.7,5.9-3,17.1,0.2,37.2c0.3,2.3,3.4,22,11.6,25.1c1,2.4,3.1,5.3,6.1,5.7c0.8,2.1,2.6,4.5,5.5,4.5c0.1,0,0.2,0,0.3,0
        c1.7,0.1,4.7-1,5.9-5.3c1.2,0.1,2.7,0,4.1-0.8c1-0.6,2.4-1.9,2.9-4.4c0-0.1,0-0.1,0-0.2c0.1-1,2-20.9,2-33.7
        c2.9,3,7.1,6.4,11.6,6.4c0.1,0,0.2,0,0.2,0c1.5-0.2,5.2-1,5.8-3.5C777,555.4,776.1,553.9,774,552.5z M630.2,769.7
        c-1.1-6.6-2.4-12.7-3.3-16.6c-0.5-2-0.8-3.6-0.9-4.2c-0.4-3-0.2-6.3,0.4-9.8c0.3-1.7,0.9-4,1.4-6.2c0.7-2.7,1.3-5.2,1.5-6.7
        c0.3-2.4,0.6-5.1,0.8-8c0.2,2.9,0.5,5.7,0.8,8c0.2,1.5,0.8,4,1.5,6.7c0.6,2.2,1.1,4.5,1.4,6.2c0.7,3.5,0.8,6.8,0.4,9.8
        c-0.1,0.6-0.4,2.2-0.9,4.2C632.6,757,631.3,763,630.2,769.7z"/>
      <path d="M288,711.1c-2.7,0.8-4.6,3.1-5.6,4.9c-0.3,0.5-0.6,1.3-1,2.2c-1.9,4.5-5,12.1-14.9,9.3c-8-2.2-8.1-17-8.1-17.1
        c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8c0,0.7,0.1,16.1,9.2,18.7c1.6,0.5,3.1,0.7,4.5,0.7c7.9,0,10.9-7.2,12.4-10.9
        c0.3-0.8,0.6-1.6,0.9-2c0.9-1.6,2.5-3.5,4.7-4.2c0.4-0.1,0.7-0.6,0.5-1C288.9,711.2,288.5,711,288,711.1z"/>
      <path d="M282.4,695.4c0.1,0.4,0.4,0.7,0.8,0.7c0,0,0.1,0,0.1,0c0.4-0.1,0.7-0.5,0.7-0.9c-0.6-4.2-2.9-7.3-3-7.4
        c-0.3-0.3-0.7-0.4-1.1-0.2c-0.4,0.3-0.4,0.8-0.2,1.1C279.8,688.7,281.9,691.6,282.4,695.4z"/>
      <path d="M271.1,402c0,0.1,1.5,6.9,0,19.3c-0.1,0.4,0.3,0.8,0.7,0.9c0,0,0.1,0,0.1,0c0.4,0,0.7-0.3,0.8-0.7
        c1.5-12.7,0-19.6,0-19.8c-0.1-0.4-0.5-0.7-0.9-0.6C271.3,401.1,271,401.6,271.1,402z"/>
      <path d="M257,351.7c-0.2,1-0.3,5.7,3.6,10c0.5,0.5,1.5,1.3,2.7,2.2c1.1,0.8,2.2,1.7,2.8,2.2c1.3,1.3,3,3.5,3.8,6.7
        c0.1,0.4,0.4,0.6,0.8,0.6c0.1,0,0.1,0,0.2,0c0.4-0.1,0.7-0.5,0.6-0.9c-0.8-3.6-2.7-6.1-4.2-7.5c-0.7-0.6-1.8-1.5-3-2.4
        c-1.1-0.8-2.1-1.6-2.5-2c-3.4-3.7-3.4-7.9-3.3-8.6c0.1-0.4-0.2-0.9-0.6-1C257.5,351,257.1,351.3,257,351.7z"/>
      <path d="M260.8,280.3c-5.3,5.5-7.8,11.5-7.9,11.8c-0.2,0.4,0,0.9,0.4,1c0.1,0,0.2,0.1,0.3,0.1c0.3,0,0.6-0.2,0.7-0.5
        c0-0.1,2.4-6,7.5-11.3c0.3-0.3,0.3-0.8,0-1.1C261.6,280,261.1,280,260.8,280.3z"/>
      <path d="M305.2,236.2c-11.2,1.5-24.8,2.2-32.2,1.6c-2.2-0.2-4.4-0.3-6.4-0.4c-0.4,0-0.8,0.3-0.8,0.7c0,0.4,0.3,0.8,0.7,0.8
        c2,0.1,4.2,0.3,6.4,0.4c1.9,0.1,4.1,0.2,6.4,0.2c8.3,0,18.6-0.8,26.2-1.8c0.4-0.1,0.7-0.5,0.7-0.9
        C306,236.4,305.6,236.1,305.2,236.2z"/>
      <path d="M253.5,232.3c0.1,0,0.1,0,0.2,0c0.4,0,0.7-0.2,0.8-0.6c0-0.1,1.6-7,4.7-11.7c0,0,0-0.1,0.1-0.1c0.1-0.2,2.7-6.1,2.9-12
        c0-0.4-0.3-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8c-0.2,5.3-2.5,10.8-2.7,11.4c-3.3,4.9-4.9,11.8-4.9,12.1
        C252.8,231.7,253.1,232.2,253.5,232.3z"/>
      <path d="M247.9,423.3c-0.4,0-0.8,0.4-0.8,0.8c0,3.9-2.5,4.1-2.8,4.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.3,0-2.9-0.1-2.8-4.1
        c0-0.4-0.4-0.8-0.8-0.8c0,0,0,0,0,0c-0.4,0-0.8,0.4-0.8,0.8c0,1.9,0.5,3.4,1.5,4.4c1.2,1.2,2.8,1.3,2.9,1.3c0,0,0,0,0,0
        c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,1.7,0,2.9-1.3c1-1,1.5-2.5,1.5-4.4C248.7,423.7,248.3,423.3,247.9,423.3z"/>
      <path d="M295.2,304.4c-2.9,0-5.2,2.3-5.2,5.2c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2C300.4,306.7,298.1,304.4,295.2,304.4z
         M295.2,313.2c-2,0-3.6-1.6-3.6-3.6c0-2,1.6-3.6,3.6-3.6c2,0,3.6,1.6,3.6,3.6C298.8,311.5,297.2,313.2,295.2,313.2z"/>
      <path d="M295.2,307.9c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6C296.9,308.6,296.1,307.9,295.2,307.9z
        "/>
      <path d="M193.3,307.9c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6C194.9,308.6,194.2,307.9,193.3,307.9z
        "/>
      <path d="M360.4,539.5c-3.7-1-7.3-5-7.3-5.1c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0.1-0.5,0.3-0.6,0.5c-0.3,0.8-3.8,3.1-6.9,4.9
        c-0.4,0.2-0.5,0.7-0.3,1.1c0.2,0.4,0.7,0.5,1.1,0.3c1.4-0.8,5.3-3,6.9-4.8c1.3,1.3,4.2,4,7.4,4.8c0.1,0,0.1,0,0.2,0
        c0.3,0,0.7-0.2,0.8-0.6C361,540.1,360.8,539.6,360.4,539.5z"/>
      <path d="M280.6,145.7c0.1,0,0.1,0,0.2,0c0.3-0.1,2.7-0.8,4.1-6c0.2-0.8,0.6-2.8-0.3-4c-0.3-0.4-0.9-0.9-2-0.9c0,0,0,0,0,0
        c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8c0,0,0,0,0,0h0c0.5,0,0.6,0.2,0.7,0.3c0.4,0.5,0.3,1.7,0.1,2.7
        c-1.1,4.2-2.9,4.9-3,4.9c-0.4,0.1-0.7,0.5-0.6,0.9C279.9,145.4,280.2,145.7,280.6,145.7z"/>
      <path d="M248.8,241.1c-0.4,0.5-0.6,0.9-0.8,1.3c-0.1,0.1-0.2,0.3-0.2,0.4c-0.6,0.8-1.2,1.4-1.8,1.8c-0.9,0.6-2.2,0.6-3.2,0.1
        c-0.6-0.3-1.3-1-1.9-1.9c-0.1-0.1-0.1-0.2-0.2-0.4c-0.2-0.3-0.5-0.8-0.8-1.3c-0.3-0.3-0.8-0.4-1.1-0.1c-0.3,0.3-0.4,0.8-0.1,1.1
        c0.3,0.4,0.5,0.8,0.7,1.1c0.1,0.2,0.2,0.4,0.3,0.5c0.8,1.1,1.6,1.9,2.5,2.3c0.7,0.4,1.5,0.6,2.3,0.6c0.9,0,1.8-0.2,2.5-0.7
        c0.7-0.5,1.5-1.2,2.2-2.2c0.1-0.1,0.2-0.3,0.3-0.5c0.2-0.3,0.4-0.7,0.7-1.1c0.3-0.3,0.2-0.8-0.1-1.1
        C249.5,240.7,249,240.7,248.8,241.1z"/>
      <path d="M208.7,144.1c0,0-1.8-0.6-3-4.9c-0.3-1-0.3-2.2,0.1-2.7c0.1-0.1,0.3-0.3,0.7-0.3h0c0,0,0,0,0,0c0.4,0,0.8-0.4,0.8-0.8
        c0-0.4-0.4-0.8-0.8-0.8c0,0,0,0,0,0c-1.1,0-1.7,0.5-2,0.9c-0.9,1.2-0.5,3.2-0.3,4c1.4,5.3,3.8,6,4.1,6c0.1,0,0.1,0,0.2,0
        c0.3,0,0.7-0.2,0.8-0.6C209.3,144.7,209.1,144.2,208.7,144.1z"/>
      <path d="M249.5,158.9c-0.4,0-0.8,0.4-0.7,0.8c0,0.4,0.4,0.7,0.8,0.7c0,0,0,0,0,0c1.3-0.1,3.9-0.9,4.1-3.3v-2.4
        c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8l0,2.3C252,158.7,249.6,158.9,249.5,158.9z"/>
      <path d="M247.9,145C248,145,248,145,247.9,145c0.5,0,0.8-0.4,0.8-0.9c0-0.1-0.5-6.2,2.1-8.9c0.3-0.3,0.3-0.8,0-1.1
        c-0.3-0.3-0.8-0.3-1.1,0c-3,3.3-2.5,9.8-2.5,10.1C247.2,144.7,247.5,145,247.9,145z"/>
      <path d="M263.7,134.2C263.7,134.2,263.7,134.2,263.7,134.2c-1.4-0.2-2.7-0.2-4,0c-4.2,0.8-6.4,4-6.5,4.1
        c-0.2,0.4-0.1,0.8,0.2,1.1c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.4-0.1,0.6-0.3c1.1,0.8,3.6,2.2,6.6,2.2c0.3,0,0.6,0,0.9,0
        c2.5-0.2,4.7-1.5,6.7-3.6c0.4,0.2,0.7,0.4,1.1,0.7c0.4,0.2,0.9,0.2,1.1-0.2c0.2-0.4,0.2-0.9-0.2-1.1
        C268.2,135.6,265.9,134.6,263.7,134.2z M263.2,135.7c0.8,1.3,0.2,2.6,0.2,2.6c-1.1,1.7-3.3,0.9-3.4,0.9c-1.4-0.7-1.3-2.4-1.1-3.1
        C260.2,135.6,261.7,135.5,263.2,135.7z M257.8,139.3c-1-0.4-1.8-0.8-2.3-1.2c0.4-0.4,1-0.9,1.7-1.3
        C257.2,137.6,257.4,138.5,257.8,139.3z M264.8,138.9c0.2-0.5,0.5-1.5,0.2-2.7c0.7,0.2,1.4,0.5,2.1,0.9
        C266.4,137.8,265.6,138.4,264.8,138.9z"/>
      <path d="M260.9,138.9c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C259.5,138.2,260.1,138.9,260.9,138.9z
        "/>
      <path d="M251.2,131.3c0.1,0,0.2,0,0.3,0c0.1,0,11.8-4,15.7-4.2c0.5,0.1,2.9,0.9,5.3,3.9c0.3,0.3,0.8,0.4,1.1,0.1
        c0.3-0.3,0.4-0.8,0.1-1.1c-2.9-3.8-6.1-4.5-6.3-4.5c-0.1,0-0.1,0-0.2,0c-4.1,0.1-15.8,4.1-16.3,4.3c-0.4,0.1-0.6,0.6-0.5,1
        C250.6,131.1,250.9,131.3,251.2,131.3z"/>
      <path d="M237.9,156.4c-0.4,0.1-0.6,0.6-0.5,1c0.1,0.4,0.6,0.6,1,0.5c0.3-0.1,1.1,0.6,1.6,1.4c1.1,1.4,3.6,1.7,3.8,1.8
        c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.3,0,2.7-0.3,3.9-1.8c0.5-0.7,1.3-1.4,1.6-1.3c0.4,0.1,0.9-0.1,1-0.5c0.1-0.4-0.1-0.9-0.5-1
        c-1.6-0.6-3.1,1.5-3.4,1.9c-0.6,0.7-2.1,1.1-2.7,1.2c-0.6-0.1-2.1-0.4-2.7-1.1C241,157.9,239.5,155.8,237.9,156.4z"/>
      <path d="M235,154c-0.4,0-0.8,0.4-0.8,0.8l0,2.4c0.1,2.4,2.7,3.2,4.1,3.3c0,0,0,0,0,0c0.4,0,0.8-0.3,0.8-0.7
        c0-0.4-0.3-0.8-0.7-0.8c0,0-2.5-0.2-2.6-1.7v-2.4C235.7,154.3,235.4,154,235,154z"/>
      <path d="M237.1,135.2c2.5,2.7,2.1,8.9,2.1,8.9c0,0.4,0.3,0.8,0.7,0.9c0,0,0,0,0.1,0c0.4,0,0.8-0.3,0.8-0.7
        c0-0.3,0.5-6.9-2.5-10.1c-0.3-0.3-0.8-0.3-1.1,0C236.8,134.4,236.8,134.9,237.1,135.2z"/>
      <path d="M234,139.5c0.2,0,0.3,0,0.4-0.1c0.4-0.2,0.5-0.7,0.2-1.1c-0.1-0.1-2.2-3.3-6.5-4.1c-1.3-0.3-2.6-0.3-4,0c0,0,0,0,0,0
        c-2.2,0.4-4.5,1.4-6.9,3c-0.4,0.2-0.5,0.7-0.2,1.1c0.2,0.4,0.7,0.5,1.1,0.2c0.4-0.3,0.7-0.5,1.1-0.7c1.9,2.2,4.2,3.4,6.7,3.6
        c0.3,0,0.6,0,0.9,0c3,0,5.5-1.4,6.6-2.2C233.6,139.4,233.8,139.5,234,139.5z M230.6,136.8c0.7,0.4,1.3,0.9,1.7,1.3
        c-0.5,0.3-1.3,0.8-2.3,1.2C230.5,138.5,230.7,137.6,230.6,136.8z M229,136.1c0.2,0.8,0.3,2.5-1.1,3.1c-0.1,0-2.3,0.8-3.4-0.8
        c0-0.1-0.7-1.4,0.1-2.7C226.2,135.5,227.6,135.6,229,136.1z M220.7,137c0.7-0.4,1.4-0.7,2.1-0.9c-0.3,1.2,0,2.3,0.2,2.8
        C222.3,138.4,221.5,137.8,220.7,137z"/>
      <path d="M226.9,138.9c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C225.5,138.2,226.1,138.9,226.9,138.9z
        "/>
      <path d="M256.6,170.2c-0.1-0.3-0.5-0.5-0.8-0.4c0,0,0,0-0.1,0c-1.4,0-5.2-1-6.4-1.4c-0.6-0.3-1.3-0.4-2-0.4
        c-1.5,0-2.8,0.5-3.4,0.9c-0.6-0.3-2-0.9-3.4-0.9c-0.7,0-1.4,0.1-1.9,0.4c-1.3,0.4-5.1,1.4-6.6,1.4c0,0,0,0,0,0c0,0,0,0,0,0
        c-0.1,0-0.2,0-0.4,0.1c0,0,0,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0-0.1,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.2
        c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0.1,0.3c0,0,0,0,0,0c0.1,0.3,3.4,7.5,12.7,7.5c9.4,0,12.6-7.2,12.7-7.5c0,0,0,0,0,0
        c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1C256.7,170.5,256.7,170.3,256.6,170.2z M239.1,169.8c0.4-0.2,0.9-0.3,1.4-0.3c1.6,0,3,0.8,3,0.9
        c0.2,0.1,0.6,0.1,0.8,0c0,0,1.5-0.9,3-0.9c0.5,0,1,0.1,1.5,0.3c0.1,0,2.2,0.7,4.1,1.1c-2.9,1-5.8,1.5-9,1.5c-3.2,0-6.1-0.5-9-1.5
        C236.9,170.4,238.9,169.8,239.1,169.8z M243.9,176.7c-5.4,0-8.5-2.6-10-4.6c3.2,1.2,6.4,1.7,10,1.7c3.6,0,6.8-0.6,10-1.7
        C252.3,174.1,249.2,176.7,243.9,176.7z"/>
      <path d="M215.4,131c2.3-3,4.8-3.8,5.3-3.9c4,0.2,15.6,4.2,15.7,4.2c0.1,0,0.2,0,0.3,0c0.3,0,0.6-0.2,0.7-0.5
        c0.1-0.4-0.1-0.9-0.5-1c-0.5-0.2-12.2-4.2-16.3-4.3c-0.1,0-0.1,0-0.2,0c-0.1,0-3.3,0.7-6.3,4.5c-0.3,0.3-0.2,0.8,0.1,1.1
        C214.6,131.4,215.1,131.4,215.4,131z"/>
      <path d="M200,712.6c2.2,0.7,3.8,2.6,4.7,4.2c0.3,0.4,0.6,1.2,0.9,2c1.5,3.7,4.5,10.9,12.4,10.9c1.3,0,2.8-0.2,4.5-0.7
        c9.1-2.6,9.2-18,9.2-18.7c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8c0,0.1-0.1,14.9-8.1,17.1c-10,2.8-13.1-4.8-14.9-9.3
        c-0.4-0.9-0.7-1.7-1-2.2c-1.1-1.8-2.9-4.1-5.6-4.9c-0.4-0.1-0.9,0.1-1,0.5C199.4,712.1,199.6,712.5,200,712.6z"/>
      <path d="M205.2,696c0,0,0.1,0,0.1,0c0.4,0,0.7-0.3,0.8-0.7c0.6-3.8,2.7-6.7,2.7-6.7c0.3-0.4,0.2-0.8-0.2-1.1
        c-0.4-0.3-0.8-0.2-1.1,0.2c-0.1,0.1-2.3,3.2-3,7.4C204.5,695.6,204.8,696,205.2,696z"/>
      <path d="M217.4,402c0.1-0.4-0.2-0.8-0.6-0.9c-0.4-0.1-0.8,0.2-0.9,0.6c-0.1,0.3-1.5,7.1,0,19.8c0,0.4,0.4,0.7,0.8,0.7
        c0,0,0.1,0,0.1,0c0.4-0.1,0.7-0.4,0.7-0.9C215.9,408.9,217.4,402.1,217.4,402z"/>
      <path d="M230.6,351.1c-0.4,0.1-0.7,0.5-0.6,1c0.2,0.6,0.2,4.8-3.3,8.6c-0.4,0.4-1.5,1.2-2.5,2c-1.1,0.9-2.3,1.7-3,2.4
        c-1.5,1.4-3.4,3.9-4.2,7.5c-0.1,0.4,0.2,0.8,0.6,0.9c0.1,0,0.1,0,0.2,0c0.4,0,0.7-0.2,0.8-0.6c0.7-3.2,2.5-5.5,3.8-6.7
        c0.6-0.6,1.7-1.4,2.8-2.2c1.2-0.9,2.2-1.7,2.7-2.2c4-4.3,3.9-9.1,3.6-10C231.5,351.3,231,351,230.6,351.1z"/>
      <path d="M227.7,280.3c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1c5.1,5.3,7.5,11.2,7.5,11.3c0.1,0.3,0.4,0.5,0.7,0.5
        c0.1,0,0.2,0,0.3-0.1c0.4-0.2,0.6-0.6,0.4-1C235.5,291.9,233,285.8,227.7,280.3z"/>
      <path d="M222,237.4c-2.1,0.1-4.2,0.3-6.4,0.4c-7.5,0.6-21-0.1-32.2-1.6c-0.4-0.1-0.8,0.2-0.9,0.7c-0.1,0.4,0.2,0.8,0.7,0.9
        c7.6,1,17.9,1.8,26.2,1.8c2.3,0,4.5-0.1,6.4-0.2c2.2-0.2,4.4-0.3,6.4-0.4c0.4,0,0.8-0.4,0.7-0.8
        C222.8,237.7,222.5,237.3,222,237.4z"/>
      <path d="M229.3,220c3.1,4.7,4.7,11.6,4.7,11.7c0.1,0.4,0.4,0.6,0.8,0.6c0.1,0,0.1,0,0.2,0c0.4-0.1,0.7-0.5,0.6-0.9
        c-0.1-0.3-1.7-7.2-4.9-12.1c-0.3-0.6-2.6-6-2.7-11.4c0-0.4-0.3-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8c0.2,6,2.8,11.8,2.9,12
        C229.3,219.9,229.3,220,229.3,220z"/>
      <path d="M193.3,304.4c-2.9,0-5.2,2.3-5.2,5.2c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2C198.5,306.7,196.2,304.4,193.3,304.4z
         M193.3,313.2c-2,0-3.6-1.6-3.6-3.6c0-2,1.6-3.6,3.6-3.6c2,0,3.6,1.6,3.6,3.6C196.9,311.5,195.3,313.2,193.3,313.2z"/>
      <path d="M143.6,539.6c-3.1-1.7-6.6-4.1-6.9-4.9c-0.1-0.3-0.3-0.5-0.6-0.5c-0.3-0.1-0.6,0-0.7,0.3c0,0-3.6,4.1-7.3,5.1
        c-0.4,0.1-0.7,0.5-0.6,1c0.1,0.4,0.4,0.6,0.8,0.6c0.1,0,0.1,0,0.2,0c3.2-0.8,6.1-3.5,7.4-4.8c1.6,1.7,5.6,4,6.9,4.8
        c0.4,0.2,0.9,0.1,1.1-0.3C144.2,540.3,144,539.8,143.6,539.6z"/>
      <path d="M387.4,552.5c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-8.1-3.3-9.1-12.5c-0.3-2.4-1.5-10.8-6.2-16c-1.1-1.2-2.3-2.3-3.5-3.3
        c-0.3-0.3-2.8-2.6-4.7-6.6l-0.3-0.5c-0.4-2.8-1-6.9-1.2-9.4c-0.5-5.7-1-21.2-1-29.9c-0.1-20.5,0.4-48,0.4-48.3
        c0.1-7.3-0.2-14.5-0.7-18.4c-0.7-5.2-1.8-8.9-2.8-12.2c-1.3-4.4-2.4-8.1-2.4-14.3c0.1-11.9,0.3-22,0.5-30.2
        c0.3-12.3,0.5-21.2,0.1-27.3c-0.4-7.9-1-11.1-1-11.1c-0.7-3.8-1.1-10.1-1.4-14.7c-0.2-3.6-0.3-6.4-0.4-6.9
        c0.8-25.9-2.4-38.2-5.3-43.9c-3.3-6.7-10.2-10.4-15.5-12.4c-2.2-0.8-6.2-1.6-10.1-2.3c-2.9-0.6-5.6-1.1-6.9-1.5
        c-6.3-2.1-11-5.1-11-5.1c-0.1,0-0.1-0.1-0.2-0.1c-0.5-0.3-1.1-0.5-1.6-0.8l0,0l0,0c-1.3-0.7-2.7-1.4-4.1-2.2c0,0,0,0,0-0.1
        c0,0,0,0,0,0l-3.4-1.9c0,0,0,0,0,0c-0.5-0.3-1-0.6-1.5-0.9l0,0v0c-3.1-1.8-5.9-3.6-7.9-4.8c-1.9-1.2-5.5-3-7.4-3.9l-0.8-0.4
        c-3.3-1.6-5.6-3.4-7-5.4c-0.3-0.4-0.5-0.8-0.7-1.4c-0.8-2.4-0.9-12.8-0.2-26.6c1.7-2.8,6.1-10.4,9.5-20.8c1.5-0.1,3.4-0.9,5.4-4
        c1.4-2.2,5.5-8.9,5.4-19c0-3.6-2.2-6.8-5.1-7.5c-1.4-0.3-2.7-0.1-3.9,0.7c-3.8-11.6-24.6-17.4-26.6-18
        c-20.1-8.1-20.7-22-20.7-22.6c0-0.9-0.7-1.7-1.7-1.8c-0.9-0.1-1.8,0.5-2,1.4c0,0.1-1.9,8.9-7.4,13.5c-0.5,0.3-11.1,8.7-15,21.3
        c-0.3,1.1-0.8,3.3-1.3,6.1c-1.2-0.8-2.5-1-3.9-0.7c-2.9,0.7-5.1,3.9-5.1,7.5c-0.1,10.1,4.1,16.8,5.4,19c2,3.3,4.1,4,5.6,4
        c0.1,0,0.3,0,0.4,0c2.3,8.3,5,14.3,5.1,14.5c0.9,2.3,2,4.4,3.2,6.3c0.7,13.8,0.6,24.2-0.2,26.6c-0.2,0.6-0.4,1-0.7,1.4
        c-1.4,1.9-3.7,3.7-7,5.4l-0.8,0.4c-1.9,0.9-5.5,2.7-7.4,3.9c-2.2,1.4-5.6,3.5-9.2,5.6c0,0,0,0,0,0l-3.9,2.2c0,0,0,0,0,0
        c-0.4,0.2-0.8,0.5-1.2,0.7l0,0l0,0c-1.4,0.8-2.8,1.5-4.1,2.1c-0.1,0-0.1,0.1-0.2,0.1c0,0-4.8,3.1-11,5.1c-1.3,0.4-4,0.9-6.9,1.5
        c-3.9,0.7-7.9,1.5-10.1,2.3c-5.2,2-12.2,5.7-15.5,12.4c-2.8,5.7-6,18-5.3,43.9c0,0.5-0.1,3.3-0.4,6.9c-0.3,4.6-0.8,10.9-1.4,14.7
        c0,0.1-0.6,3.3-1.1,11.2c-0.3,6.1-0.1,15,0.1,27.3c0.2,8.2,0.4,18.3,0.5,30.2c0.1,6.2-1.1,9.9-2.4,14.3c-1,3.3-2.1,7-2.8,12.2
        c-0.5,3.9-0.8,11.1-0.7,18.4c0,0.3,0.5,27.9,0.4,48.3c0,8.7-0.5,24.2-1,29.9c-0.2,2.5-0.8,6.6-1.2,9.4l-0.2,0.5
        c-2,4-4.4,6.3-4.8,6.6c-1.3,1-2.5,2-3.5,3.3c-4.7,5.3-5.9,13.6-6.2,16c-1,9.2-8.8,12.4-9.1,12.5c-0.1,0-0.2,0.1-0.3,0.2
        c-2.1,1.4-3,2.8-2.6,4.3c0.6,2.5,4.2,3.3,5.8,3.5c0.1,0,0.2,0,0.2,0c4.4,0,8.7-3.4,11.6-6.4c0,12.8,1.9,32.7,2,33.7
        c0,0.1,0,0.1,0,0.2c0.5,2.5,1.9,3.8,2.9,4.4c1.4,0.8,2.9,0.9,4.1,0.8c1.2,4.2,4.2,5.3,5.9,5.3c0.1,0,0.2,0,0.3,0
        c2.9,0,4.7-2.4,5.5-4.5c3-0.3,5.1-3.3,6.1-5.7c8.2-3.1,11.2-22.8,11.6-25.1c3.3-20.1,1.9-31.4,0.3-37.2c-0.6-2.2-1.6-4.1-2.3-5.7
        c-0.5-1-1.1-2.3-1.1-2.7c0-0.3,2.6-28.8,10.5-56.6c1.9-6.8,6.2-22.2,7.3-26.9c2.4-10.3,3.6-20.1,3.7-29c0-5.2-1.1-12.5-1.8-17.8
        c-0.4-2.6-0.7-4.9-0.8-6c-0.8-11.9,1.6-20.2,1.7-20.3c0-0.1,0-0.1,0-0.2c1.5-8.9,2.8-19,3.7-28.9c2.2,10.7,5.6,24.5,10.4,36.5
        c0.1,0.2,5.5,15.2,1.9,25.8l-0.1,0.3c-0.6,1.7-1.9,5.6-4.2,9.3c-2.4,3.8-7.2,13.5-8.6,23.2c-0.8,5.3-1.8,9.8-3.4,17.2
        c-0.5,2.2-1,4.7-1.7,7.6c-1.3,6-2.6,9.2-3.8,12.2c-0.4,1.1-0.9,2.2-1.3,3.3c-3.1,8.6-6.7,23.6-6.5,45.6
        c0,0.9,3.3,87.7,25.1,134.8c0.1,0.2,7.4,16.4,10.2,33.8c0.9,5.6,1.8,26.3,1.6,36.4c-0.1,5.1-0.5,9.5-1.2,13
        c-0.1,0.4-0.5,1.6-0.9,3c-1.1,3.5-3,9.3-4.1,15.4c-2.4,13.3-3.5,34.6,4.9,60.3c0,0.1,4,13.4,9.1,32.1c2.7,9.8,7.2,29.2,9.8,42.5
        c0.4,1.9,1,6.8,1.1,7.3c0.1,4-0.8,6.8-1.3,8.2l-0.1,0.2c-0.9,2.5-1.5,3.6-3.3,6.6l-0.6,1c-0.6,1-1.6,3-2.5,4.8
        c-0.5,1-1.1,2.2-1.2,2.4c-3,4.9-6.5,9-6.5,9c0,0,0,0,0,0c-4.1,4.3-5.5,6-6.6,7.3c-1.8,2.2-1.9,4.1-1.7,5.2
        c0.2,1.4,1.1,2.4,1.8,2.9c4.3,2.6,13.9,1.5,15,1.4c0,0,0.1,0,0.1,0c6.2-1.3,25.4,0.6,25.7,0.7c0.3,0,0.5,0,0.8,0
        c3,0,5.4-1,7.2-2.9c3.7-4,3.6-11.1,2.8-16.4c-0.2-1.6-0.8-4.2-1.4-6.9c-0.6-2.7-1.3-5.6-1.5-7.3c-0.2-1.4-0.4-2.8-0.5-4.2
        l-0.4-5.2c-0.4-15.3,1-31.1,3.9-62.5l0.5-5.1c0.5-5.1,0.9-9.9,1.2-14.4c0.4,4.4,0.8,9.3,1.2,14.4l0.5,5.1
        c2.9,31.5,4.3,47.3,3.9,62.4l-0.4,5.3c-0.1,1.4-0.3,2.8-0.5,4.2c-0.2,1.7-0.9,4.6-1.5,7.3c-0.6,2.7-1.2,5.3-1.4,6.9
        c-0.7,5.3-0.8,12.5,2.8,16.4c1.8,1.9,4.2,2.9,7.3,2.9c0.3,0,0.5,0,0.8,0c0.2,0,19.4-1.9,25.6-0.7c0,0,0.1,0,0.1,0
        c1.1,0.1,10.7,1.3,15-1.4c0.7-0.4,1.5-1.5,1.8-2.9c0.2-1.2,0.1-3-1.7-5.2c-1.1-1.4-2.5-3-6.5-7.2c0,0-3.6-4.2-6.6-9.1
        c-0.1-0.2-0.7-1.5-1.2-2.5c-0.9-1.8-1.9-3.8-2.5-4.8l-0.6-1c-1.8-3-2.4-4-3.3-6.6l-0.1-0.2c-0.5-1.5-1.5-4.2-1.3-8.2
        c0-0.5,0.7-5.4,1.1-7.3c2.5-13.3,7.1-32.7,9.8-42.5c5.1-18.7,9.1-32,9.1-32.1c8.4-25.8,7.3-47.1,4.9-60.4
        c-1.1-6.1-3-11.9-4.1-15.4c-0.4-1.4-0.8-2.5-0.9-3c-0.7-3.5-1.1-7.9-1.2-13c-0.2-10,0.7-30.8,1.6-36.4
        c2.8-17.5,10.2-33.7,10.2-33.8c21.8-47.1,25-134,25.1-134.9c0.2-22-3.4-36.9-6.5-45.5c-0.4-1.1-0.8-2.2-1.3-3.3
        c-1.2-3.1-2.5-6.2-3.8-12.2c-0.6-2.8-1.2-5.3-1.7-7.6c-1.7-7.5-2.7-12-3.4-17.2c-1.4-9.7-6.3-19.4-8.6-23.2
        c-2.3-3.7-3.6-7.6-4.2-9.3l-0.1-0.3c-3.6-10.6,1.8-25.7,1.9-25.8c4.8-12,8.3-25.9,10.4-36.5c0.9,9.9,2.1,20,3.7,28.9
        c0,0.1,0,0.1,0,0.2c0,0.1,2.4,8.4,1.7,20.3c-0.1,1.2-0.4,3.4-0.8,6c-0.8,5.3-1.9,12.6-1.8,17.8c0,8.9,1.3,18.7,3.7,29
        c1.1,4.6,5.4,20.1,7.3,26.9c7.9,27.8,10.4,56.3,10.5,56.6c0,0.4-0.6,1.7-1.1,2.7c-0.7,1.5-1.7,3.4-2.3,5.7
        c-1.7,5.9-3,17.1,0.2,37.2c0.3,2.3,3.4,22,11.6,25.1c1,2.4,3.1,5.3,6.1,5.7c0.8,2.1,2.6,4.5,5.5,4.5c0.1,0,0.2,0,0.3,0
        c1.7,0.1,4.7-1,5.9-5.3c1.2,0.1,2.7,0,4.1-0.8c1-0.6,2.4-1.9,2.9-4.4c0-0.1,0-0.1,0-0.2c0.1-1,2-20.9,2-33.7
        c2.9,3,7.1,6.4,11.6,6.4c0.1,0,0.2,0,0.2,0c1.5-0.2,5.2-1,5.8-3.5C390.3,555.4,389.4,553.9,387.4,552.5z M278.9,152.9
        C278.8,152.8,278.8,152.8,278.9,152.9C278.8,152.8,278.9,152.8,278.9,152.9L278.9,152.9z M217.2,169.4c0-0.1-3.1-6.8-5.4-15.7
        c-0.3-1-1.2-1.6-2.2-1.3c-0.4,0.1-0.8,0.4-1,0.7l-0.2-0.3c0,0,0,0,0.1-0.1c-0.3,0.1-1.3-0.1-2.7-2.3c-1.2-2-5-8-4.9-17.1
        c0-2.1,1.2-3.7,2.3-4c1.3-0.3,2.3,1.4,2.7,2.2c0.2,0.4,0.6,0.7,1.1,0.9c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0
        c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0c0.7-0.2,1.3-0.8,1.4-1.6c0.4-4.3,1.2-7.9,1.6-9.4
        c3.5-11.5,13.6-19.4,13.8-19.5c3.2-2.6,5.3-6.3,6.7-9.4c2.3,5.7,7.8,13.9,21.6,19.4c0.1,0,0.1,0.1,0.2,0.1
        c6.8,1.8,24.3,8.4,24.5,17.9l0,0.7c0,0.8,0.6,1.6,1.4,1.8c0.8,0.2,1.7-0.2,2.1-0.9c0.4-0.8,1.4-2.5,2.7-2.2
        c1.1,0.3,2.3,1.9,2.3,4c0.1,9-3.7,15.1-4.9,17.1c-1.4,2.2-2.4,2.4-2.7,2.3c0,0,0,0,0,0c-0.1-0.1-0.2-0.1-0.3-0.1
        c-1-0.3-2,0.2-2.3,1.2c-4.1,13.3-10,22.1-10.3,22.5c-6.7,9.9-15.8,12.4-18.5,13c-1.9,0.4-6.2,0.4-8.1,0
        C236.8,188.8,223.7,185.4,217.2,169.4z M196.8,937c-1.9-0.1-3.7-0.4-4.7-1c-0.3-0.4-0.4-1.3,0.7-2.6c0.6-0.3,1.4-0.5,2.4-0.5
        c0.1,0,1.3,0,1.9,2.1C196.9,935.7,196.8,936.4,196.8,937z M203.9,936.9c-1.6,0.2-3.6,0.3-5.5,0.2c0-0.7,0.1-1.6,0.3-2.5
        c0.1-0.5,0.5-1.9,1.4-2.5c0.6-0.3,2.1-1,3.3,0.2c0.4,0.4,0.6,1,0.8,1.9l0,0.1C204,935.3,203.9,936.1,203.9,936.9z M212.2,935.4
        c0,0.3,0,0.6,0,0.9c-2.6,0-4.9,0.1-6.7,0.3c0-1.5,0.5-3.6,2.2-4.6c0.8-0.5,2-0.9,3.3,0c0.8,0.6,1.3,1.1,1.5,1.6
        C212.3,934.3,212.3,934.9,212.2,935.4z M220.8,936.6c-2.3-0.1-4.7-0.2-7-0.3c0-0.2,0-0.5,0-0.8c0.1-1.1,0.3-2.3,1.3-3.3
        c2.2-2.1,4.8-1.1,6-0.5c-0.2,0.6-0.5,1.9-0.5,2.5C220.6,935,220.7,935.8,220.8,936.6z M235,936.6c-1,0.6-2.3,0.9-3.9,0.8
        c-0.3,0-4.1-0.4-8.7-0.7c-0.2-0.7-0.2-1.5-0.2-2.5c0-0.5,0.3-1.8,0.4-2.1c1.6-4.2,5.5-4.1,5.7-4.1c2.2,0,3.9,0.7,5.3,2.1
        c1.8,1.7,1.9,3.4,1.9,4.3c0,0,0,0.1,0,0.1C235.5,935.3,235.3,936,235,936.6z M243.4,770.5c-1.1-6.9-2.5-13.3-3.4-17.3
        c-0.5-2-0.8-3.6-0.9-4.2c-0.4-3-0.2-6.3,0.4-9.8c0.3-1.7,0.9-4,1.4-6.2c0.7-2.7,1.3-5.2,1.5-6.7c0.4-2.8,0.7-6.1,0.9-9.7
        c0.3,3.6,0.6,6.9,0.9,9.7c0.2,1.5,0.8,4,1.5,6.7c0.6,2.2,1.1,4.5,1.4,6.2c0.7,3.5,0.8,6.8,0.4,9.8c-0.1,0.6-0.4,2.2-0.9,4.2
        C246,757.2,244.5,763.5,243.4,770.5z M264.4,936.7c-4.6,0.3-8.3,0.7-8.6,0.7c-1.6,0.1-2.9-0.2-4-0.8c-0.3-0.6-0.5-1.3-0.5-2.1
        c0,0,0-0.1,0-0.1c0-0.8,0.1-2.5,1.9-4.3c1.4-1.3,3.1-2,5.4-2.1c0.2,0,4-0.2,5.6,4.1c0.1,0.3,0.4,1.6,0.4,2.1
        C264.6,935.2,264.5,936.1,264.4,936.7z M273,936.3c-2.3,0-4.8,0.1-7,0.3c0.1-0.8,0.2-1.6,0.2-2.4c0-0.6-0.3-1.9-0.5-2.5
        c1.1-0.6,3.8-1.6,6,0.5c1,1,1.2,2.2,1.3,3.3C273,935.8,273,936.1,273,936.3z M274.6,936.3c0-0.3,0-0.6,0-0.9
        c0-0.6-0.1-1.1-0.3-1.7c0.2-0.5,0.7-1.1,1.5-1.6c1.3-0.9,2.5-0.4,3.3,0c1.7,1,2.1,3,2.2,4.6C279.5,936.4,277.2,936.3,274.6,936.3
        z M282.9,936.9c0-0.7-0.1-1.6-0.3-2.5l0-0.1c0.3-0.9,0.5-1.6,0.8-1.9c1.1-1.2,2.7-0.5,3.3-0.2c1,0.6,1.3,1.9,1.4,2.5
        c0.2,0.9,0.3,1.8,0.3,2.5C286.5,937.2,284.5,937,282.9,936.9z M294.8,936c-1.1,0.6-2.8,0.9-4.7,1c0-0.6-0.1-1.3-0.2-2.1
        c0.5-2,1.7-2.1,2-2.1c1-0.1,1.7,0.1,2.3,0.4C295.2,934.7,295,935.6,294.8,936z M385.5,556.3c-0.5,0.2-1.1,0.3-1.7,0.4
        c-4.6-0.1-10.2-6.5-11.9-9c-0.5-0.7-1.3-0.9-2.1-0.7c-0.8,0.3-1.3,1-1.2,1.8c0.8,10.8-1.7,36.5-1.8,38.3
        c-0.2,0.9-0.6,1.5-1.1,1.9c-0.9,0.5-2.2,0.4-2.8,0.2c0.2-4.8,0.6-18.5-0.1-26.2c0-0.4-0.4-0.8-0.9-0.7c-0.4,0-0.8,0.4-0.7,0.9
        c0.6,7.7,0.2,21.4,0.1,26.1c-0.1,0.3-0.1,5.5-3.7,5.4c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c-3.1,0.1-3.5-4.3-3.5-4.3v-27.3
        c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8v26.9c-2.3,1-4.2-4.1-4.8-4.9c-0.7-3.9-2.5-14.6-2.1-24c0-0.4-0.3-0.8-0.8-0.8
        c-0.4,0-0.8,0.3-0.8,0.8c-0.4,8.7,1,18.4,1.9,23.1c-4-3.3-7-14.3-8-21.5c-3.1-19.5-1.9-30.1-0.3-35.7c0.6-1.9,1.4-3.6,2.1-5.1
        c0.9-1.8,1.6-3.3,1.5-4.6c0-0.3-2.6-29.2-10.6-57.3c-2.4-8.3-6.3-22.6-7.3-26.7c-2.3-10-3.5-19.5-3.6-28.2c0-5,1-12.1,1.8-17.3
        c0.4-2.8,0.7-5,0.8-6.3c0.8-12-1.5-20.3-1.8-21.4c-1.7-10.1-3.1-21.7-4-32.9c-0.2-3.1-1.4-15.9-2.1-24.6v0
        c-0.4-5.3-1.5-11.2-4.1-16.8c-0.2-0.4-0.7-0.6-1-0.4c-0.4,0.2-0.6,0.7-0.4,1c6.8,14.8,3,32.7,3,32.8
        c-5.2,17.7-25.9,17.9-26.1,17.9c-18.7,0-27.1-12.6-27.1-12.7c-0.2-0.4-0.7-0.5-1.1-0.2c-0.4,0.2-0.5,0.7-0.2,1.1
        c0.1,0.1,8.8,13.4,28.5,13.4c0.2,0,17-0.2,24.9-13c-2,10.9-5.7,27.4-11.3,41.4c-0.2,0.7-6,16.4-1.9,28.3l0.1,0.3
        c0.6,1.8,2,6,4.6,10c2.2,3.5,6.8,12.6,8.1,21.8c0.8,5.4,1.8,10,3.5,17.5c0.5,2.2,1,4.7,1.7,7.6c1.4,6.3,2.8,9.8,4,12.8
        c0.4,1.1,0.8,2.1,1.2,3.2c3,8.4,6.5,22.8,6.3,44.2c0,0.9-3.3,87-24.7,133.5c-0.3,0.7-7.6,16.8-10.5,34.8c-1,6.3-1.9,27.4-1.7,37
        c0.1,5.3,0.5,9.9,1.3,13.6c0.1,0.6,0.5,1.7,1,3.4c1.1,3.4,2.9,9.1,4,14.9c2.3,12.9,3.4,33.5-4.8,58.6c0,0.1-4,13.4-9.1,32.2
        c-2.7,9.8-7.3,29.4-9.8,42.8c-0.4,1.9-1.1,7.1-1.1,7.8c-0.2,4.7,0.9,7.9,1.5,9.6l0.1,0.2c0.9,2.7,1.7,4,3.2,6.6
        c-3.3-1.8-6.5-3-6.7-3c-0.4-0.1-0.9,0.1-1,0.5c-0.1,0.4,0.1,0.9,0.5,1c0.1,0,5,1.8,8.8,4.4c0.5,1,1.1,2.2,1.6,3.3
        c0.8,1.6,1.2,2.4,1.4,2.8c3.2,5.2,6.8,9.4,7,9.6c2.4,2.5,3.8,4,4.8,5.2c-0.2,0-0.4,0-0.6,0c-0.6-0.1-1.8,0.2-2.6,1.4
        c-0.3-0.7-0.9-1.4-1.6-1.9c-1.5-0.9-3.6-1.2-5.2,0.5c-0.3,0.3-0.5,0.6-0.6,1c-0.4-0.6-1-1.2-1.8-1.6c-1.8-1-3.5-1-5,0.1
        c-0.5,0.4-1,0.8-1.3,1.2c-0.2-0.3-0.4-0.6-0.7-0.9c-2.6-2.5-5.8-1.8-7.7-0.8c-2.3-4-6.5-3.8-6.6-3.8c-2.6,0-4.7,0.9-6.4,2.5
        c-2.2,2.2-2.3,4.3-2.4,5.4c-1.4-2.6-1.8-6.8-1-12.2c0.2-1.5,0.8-4.1,1.4-6.6c0.7-2.8,1.3-5.7,1.6-7.6c0.2-1.4,0.4-2.9,0.5-4.4
        l0.4-5.4c0.4-15.5-1.1-32.3-3.9-63l-0.5-5.1c-2.1-23.3-3-40.8-3-40.9c0-12.1,3.5-27.8,5.2-35.3c0.5-2.3,0.8-3.8,0.9-4.5
        c0.4-3.4,0.3-7.1-0.5-10.9c-0.3-1.8-0.9-4.1-1.5-6.4c-0.6-2.4-1.2-5-1.4-6.3c-2.3-17.4-2.3-53.1-2.2-72.8
        c0.1-9.6,0.6-21.1,0.7-22.8c0.3-5.9,0.4-11.1,0.3-15.5c-0.1-3.7-1.1-51.3-1.5-70.1c0-1.4-0.2-9.5-0.2-10.6
        c6.8-16,42.4-49.7,42.7-50c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.8-0.3-1.1,0c-1.6,1.5-37.4,35.4-43.5,51.5
        c-6.1-16.1-41.9-50-43.5-51.5c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1c0.4,0.4,36.1,34.1,42.8,50.1
        c0.1,2.6-0.1,4.2-0.1,5.7c-0.3,13.2-1.5,70.9-1.6,75c-0.1,4.4,0,9.7,0.3,15.5c0.1,1.7,0.6,13.2,0.7,22.8
        c0.2,19.7,0.2,55.4-2.2,72.8c-0.2,1.3-0.8,3.8-1.4,6.3c-0.6,2.3-1.2,4.6-1.5,6.4c-0.7,3.9-0.9,7.5-0.5,10.9
        c0.1,0.7,0.4,2.2,0.9,4.5c1.7,7.5,5.2,23.3,5.2,35.3c0,0.2-0.9,17.8-3,41l-0.5,5.1c-2.8,30.6-4.3,47.5-3.9,63l0.4,5.4
        c0.2,1.5,0.3,2.9,0.5,4.3c0.3,1.9,0.9,4.8,1.6,7.6c0.6,2.5,1.2,5.1,1.4,6.6c0.7,5.4,0.4,9.6-1,12.2c0,0,0,0,0-0.1
        c0-1-0.1-3.2-2.4-5.4c-1.7-1.6-3.8-2.5-6.4-2.5c0,0-4.3-0.2-6.6,3.8c-1.9-1-5.2-1.7-7.7,0.8c-0.3,0.3-0.5,0.6-0.7,0.9
        c-0.3-0.4-0.8-0.8-1.3-1.2c-1.5-1.1-3.2-1.1-5-0.1c-0.8,0.4-1.3,1-1.7,1.6c-0.2-0.4-0.4-0.7-0.6-1c-1.6-1.7-3.7-1.3-5.2-0.5
        c-0.8,0.5-1.3,1.2-1.6,1.9c-0.9-1.1-2-1.4-2.5-1.4c-0.2,0-0.5,0-0.7,0c1-1.1,2.5-2.7,4.9-5.2c0.2-0.2,3.7-4.4,6.9-9.5
        c0.2-0.3,0.6-1.2,1.4-2.8c0.5-1.1,1.1-2.3,1.6-3.3c3.8-2.6,8.8-4.4,8.8-4.4c0.4-0.1,0.6-0.6,0.5-1c-0.1-0.4-0.6-0.6-1-0.5
        c-0.2,0.1-3.4,1.2-6.7,3c1.6-2.6,2.3-3.9,3.2-6.6l0.1-0.2c0.6-1.7,1.7-4.9,1.5-9.6c0-0.7-0.8-5.9-1.1-7.8
        c-2.5-13.4-7.1-33-9.8-42.8c-5.1-18.8-9.1-32.1-9.1-32.2c-8.2-25.1-7.1-45.7-4.8-58.6c1.1-5.8,2.9-11.5,4-14.9
        c0.5-1.6,0.9-2.7,1-3.4c0.7-3.7,1.2-8.3,1.3-13.6c0.2-9.6-0.7-30.7-1.7-37c-2.9-18-10.2-34.1-10.5-34.8
        c-21.4-46.5-24.7-132.6-24.7-133.4c-0.2-21.4,3.3-35.9,6.3-44.3c0.4-1.1,0.8-2.1,1.2-3.2c1.2-3,2.6-6.5,4-12.8
        c0.6-2.8,1.2-5.3,1.7-7.6c1.7-7.5,2.7-12.1,3.5-17.5c1.3-9.1,5.9-18.2,8.1-21.8c2.5-4.1,4-8.3,4.6-10l0.1-0.3
        c4.1-11.8-1.7-27.6-1.9-28.3c-5.6-13.9-9.3-30.4-11.3-41.3c7.9,12.8,24.7,13,24.9,13c19.6,0,28.4-13.3,28.5-13.4
        c0.2-0.4,0.1-0.9-0.2-1.1c-0.4-0.2-0.9-0.1-1.1,0.2c-0.1,0.1-8.4,12.7-27.1,12.7c-0.2,0-20.8-0.2-26-17.9c0-0.2-3.8-18.1,3-32.9
        c0.2-0.4,0-0.9-0.4-1c-0.4-0.2-0.9,0-1,0.4c-2.1,4.5-3.2,9.2-3.8,13.6c0,0,0,0,0,0c-0.5,2.5-1.7,16.9-2,21.3
        c-0.8,13-2.3,27.2-4.4,39.3c-0.3,1.1-2.5,9.5-1.8,21.4c0.1,1.3,0.4,3.5,0.8,6.3c0.8,5.2,1.8,12.3,1.8,17.3
        c0,8.7-1.2,18.2-3.6,28.2c-1,4.1-4.9,18.4-7.3,26.7c-8,28.1-10.6,57-10.6,57.3c-0.1,1.4,0.6,2.8,1.5,4.6c0.7,1.5,1.5,3.1,2.1,5.1
        c1.6,5.5,2.8,16.2-0.3,35.7c-1,7.2-4,18.3-8,21.5c0.8-4.7,2.3-14.4,1.9-23.1c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8
        c0.4,9.6-1.4,20.5-2.1,24.2c-0.6,1.1-2.6,4.7-4.8,5.3v-27.4c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8v28.3
        c-0.4,1.2-1.2,3.2-3.6,3.3c-0.1,0-0.1,0-0.2,0h0c-2.9,0-3.6-5-3.7-5.4c-0.2-4.7-0.6-18.4,0.1-26.1c0-0.4-0.3-0.8-0.7-0.9
        c-0.4,0-0.8,0.3-0.9,0.7c-0.6,7.8-0.2,21.5-0.1,26.2c-0.7,0.2-2,0.3-2.8-0.2c-0.6-0.3-0.9-0.9-1.1-1.9
        c-0.2-1.8-2.6-27.5-1.8-38.3c0.1-0.8-0.4-1.6-1.2-1.8c-0.8-0.3-1.6,0-2.1,0.7c-1.8,2.5-7.3,8.9-11.9,9c-0.5-0.1-1.1-0.2-1.6-0.4
        c-0.2-0.1-0.7-0.4-0.1-0.7c1.4-0.6,10-4.8,11.2-15.4c0.5-4.4,2.2-10.5,5.3-14c0.9-1,2-2,3.1-2.9c0.1-0.1,3.2-2.8,5.7-7.8l0.4-0.8
        c0.1-0.2,0.1-0.3,0.2-0.5c0.4-2.8,1-7.1,1.3-9.9c0.5-6,1-21.4,1-30.2c0.1-20.5-0.4-48.1-0.4-48.4c-0.1-7.1,0.2-14.1,0.7-17.8
        c0.7-4.9,1.7-8.3,2.7-11.6c1.3-4.4,2.6-8.6,2.5-15.4c-0.1-11.9-0.3-22.1-0.5-30.2c-0.3-12.2-0.4-21.1-0.1-27
        c0.4-7.6,1-10.7,1-10.7c0.7-4,1.2-10.4,1.5-15.1c0.2-4,0.4-7,0.4-7c0,0,0-0.1,0-0.1c-0.8-26.5,2.7-37.8,4.9-42.2
        c2.8-5.6,8.9-8.8,13.5-10.6c1.9-0.7,6-1.5,9.5-2.2c3.1-0.6,5.8-1.1,7.3-1.6c6.3-2.1,11.1-5,11.8-5.5c6.6-3.3,14.5-8.2,18.7-10.8
        c1.8-1.1,5.4-2.9,7.1-3.7l0.9-0.4c3.8-1.9,6.6-4.1,8.4-6.5c0.5-0.6,0.8-1.4,1.2-2.3c1.1-3.3,1-14,0.7-22.6
        c5.5,6.1,12.1,9.5,17.6,10.6c1.2,0.2,3,0.4,4.8,0.4c1.8,0,3.6-0.1,4.8-0.4c2.6-0.5,10.7-2.7,17.6-10.5
        c-0.3,8.6-0.5,19.3,0.7,22.6c0.3,0.9,0.7,1.7,1.2,2.3c1.7,2.4,4.5,4.6,8.4,6.5l0.9,0.4c1.7,0.8,5.3,2.6,7.1,3.7
        c4.1,2.6,12,7.5,18.7,10.8c0.7,0.5,5.5,3.4,11.8,5.5c1.5,0.5,4.2,1,7.3,1.6c3.6,0.7,7.6,1.5,9.5,2.2c4.6,1.7,10.7,5,13.5,10.6
        c2.2,4.4,5.7,15.7,4.9,42.2c0,0,0,0.1,0,0.1c0,0,0.1,3,0.4,7c0.3,4.7,0.8,11.1,1.5,15.1c0,0,0.6,3.1,1,10.7
        c0.3,5.9,0.1,14.8-0.1,27c-0.2,8.2-0.4,18.4-0.5,30.2c-0.1,6.7,1.2,10.9,2.5,15.4c1,3.3,2,6.7,2.7,11.6
        c0.5,3.7,0.8,10.7,0.7,17.8c0,0.3-0.5,27.9-0.4,48.4c0,8.8,0.5,24.3,1,30.2c0.3,2.8,0.8,7.1,1.3,9.9c0,0.2,0.1,0.3,0.2,0.5
        l0.4,0.8c2.5,5,5.5,7.6,5.8,7.8c1.1,0.8,2.1,1.8,3,2.8c3.1,3.5,4.8,9.5,5.3,14c1.2,10.6,9.8,14.8,11.2,15.4
        C385.8,555.8,385.7,556.3,385.5,556.3z"/>
      <path d="M303.1,147.2c-0.4-1.2,0.8-1.9,1.2-2.4c8.1-9.2,9.3-13.1,5.2-17.6c-8.8-9.4-8.7-14.6-8.5-21.1c0.1-2.3,0.1-4.7-0.2-7.4
        C298.1,76,278,58.4,252.9,57c-5.7-0.3-11.2,1.3-15.5,2.6c-2.4,0.7-4.5,1.3-5.8,1.3c-17.5,0-29.3,7.7-35,22.8
        c-0.7,1.8-2.6,5-3.1,5.5c-2.1,1.9-4.2,2-7.2,2.3c-0.7,0.1-1.4,0.1-2.1,0.2c-2.8,0.3-5.1,1.6-6.9,4c-6.6,8.5-3.8,28-3.7,28.9
        c2.8,15.4,0.2,18.7-3.1,23.5c-0.2,0.1-0.3,0.3-0.4,0.5c-2.7,4.9-3.2,9.6-1.5,13.9c3.4,8.2,14.1,12,16.3,12.7
        c1.7,1.2,9.3,5.8,27.1,5.8c2.7,0,5.5-0.1,8.7-0.3c-1-1.2-2-2.5-2.9-3.9c-9.3,0.5-16-0.2-20.4-1.2c-7.3-1.5-10.2-3.8-10.2-3.8
        l-0.3-0.3l-0.4-0.1c-0.1,0-11.1-3.3-14.1-10.5c-1.3-3.1-0.9-6.5,1.3-10.4c0-0.1,0.1-0.1,0.1-0.2l0,0c3.5-4.3,6.8-11.6,3.4-26.3
        c-0.8-5.2-1.7-20,3-26.1c1.2-1.5,2.6-2.4,4.4-2.6c0.7-0.1,1.4-0.1,2-0.2c3.3-0.3,6.4-0.5,9.3-3.2c1.1-1,3.4-5.1,4.1-7
        c5.2-13.7,15.5-20.4,31.6-20.4c1.8,0,4-0.7,6.9-1.5c4-1.2,9.1-2.7,14.2-2.4c23.3,1.4,42,17.6,44.5,38.6c0.3,2.4,0.2,4.7,0.2,6.9
        c-0.2,6.8-0.3,13.2,9.5,23.7c1.8,1.9,3.1,3.3-5.3,12.7c-1.1,1.2-3.4,4-1.6,7.3c0,0,0,0,0,0c0.1,0.2,0.2,0.3,0.4,0.4
        c2.7,2.4,9.2,9.8,7.2,15.6c-1.4,4.1-7.7,9.3-29.5,10.7c-2.7,0.2-5.2,0.2-7.5,0.2c-0.9,1.4-1.9,2.7-2.9,4
        c3.2,0.1,6.7,0.1,10.7-0.1c10.1-0.7,18.1-2.2,23.6-4.6c5-2.2,8.2-5.1,9.4-8.8C314.6,157.7,304,147.9,303.1,147.2z"/>
      <rect x="875.3" y="45.8" class="st11" width="809.6" height="39"/>
      <path class="st12" d="M1685.5,541.7H874.6V84.1h810.9V541.7z M875.9,540.4h808.3V85.4H875.9V540.4z"/>
      <path class="st12" d="M1685.5,684.8H874.6V540.4h810.9V684.8z M875.9,683.5h808.3V541.7H875.9V683.5z"/>
      <path class="st12" d="M1685.5,758.9H874.6v-75.4h810.9V758.9z M875.9,757.6h808.3v-72.8H875.9V757.6z"/>
      <path class="st12" d="M1685.5,948.1H874.6V757.6h810.9V948.1z M875.9,946.8h808.3v-188H875.9V946.8z"/>

      <path class="st13" d="M1177.5,118.4v2.4h-5.7v2.2c0.4-0.1,1.1-0.1,1.6-0.1c1.3,0,2.7,0.3,3.6,1.1c0.9,0.7,1.5,1.7,1.5,3.1
          c0,1.3-0.5,2.2-1.2,2.9c-1.2,1.1-3.3,1.5-5.2,1.5c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1177.5z M1171.8,129.2c0.3,0.1,0.7,0.1,1.1,0.1
          c0.7,0,1.4-0.2,1.9-0.7c0.4-0.3,0.6-0.9,0.6-1.5c0-0.7-0.3-1.1-0.7-1.5c-0.5-0.4-1.2-0.6-1.9-0.6c-0.3,0-0.7,0-1,0.1V129.2z"/>
      <path class="st13" d="M1192.2,124.7c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1190,118.2,1192.2,121.2,1192.2,124.7z M1182.8,124.9c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1184,120.5,1182.8,122.4,1182.8,124.9z"/>
      <path class="st13" d="M1203.6,118.4v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
          c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1203.6z"/>
      <path class="st13" d="M1209,118.4v4.5c0.4,0,1-0.1,1.6-0.1c2.8,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8c-1.2,1.3-3.4,1.6-5.4,1.6
          c-1.4,0-2.5-0.1-3.2-0.2v-12.8H1209z M1209,129.2c0.3,0.1,0.7,0.1,1.1,0.1c1.4,0,2.6-0.7,2.6-2.1c0-1.5-1.1-2.1-2.5-2.1
          c-0.4,0-0.8,0-1.1,0.1V129.2z"/>
      <path class="st13" d="M1221.5,118.6c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
          c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
          V118.6z M1224.4,123.5h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V123.5z M1224.4,129.3c0.3,0,0.6,0,1.1,0
          c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V129.3z"/>
      <path class="st13" d="M1244.8,118.4v2.4h-4.9v10.5h-2.9v-12.9H1244.8z"/>
      <path class="st13" d="M1257.5,124.7c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1255.3,118.2,1257.5,121.2,1257.5,124.7z M1248.2,124.9c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1249.3,120.5,1248.2,122.4,1248.2,124.9z"/>
      <path class="st13" d="M1268.9,118.4v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
          c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1268.9z"/>
      <path class="st13" d="M1283.3,124.7c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1281.1,118.2,1283.3,121.2,1283.3,124.7z M1273.9,124.9c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1275.1,120.5,1273.9,122.4,1273.9,124.9z"/>
      <path class="st13" d="M1285.2,118.6c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
          c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
          V118.6z M1288.1,123.5h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V123.5z M1288.1,129.3c0.3,0,0.6,0,1.1,0
          c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V129.3z"/>
      <path class="st13" d="M1304.4,125.9h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V125.9z"/>

      <path class="st13" d="M1177.5,185.2v2.4h-5.7v2.2c0.4-0.1,1.1-0.1,1.6-0.1c1.3,0,2.7,0.3,3.6,1.1c0.9,0.7,1.5,1.7,1.5,3.1
          c0,1.3-0.5,2.2-1.2,2.9c-1.2,1.1-3.3,1.5-5.2,1.5c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1177.5z M1171.8,196c0.3,0.1,0.7,0.1,1.1,0.1
          c0.7,0,1.4-0.2,1.9-0.7c0.4-0.3,0.6-0.9,0.6-1.5c0-0.7-0.3-1.1-0.7-1.5c-0.5-0.4-1.2-0.6-1.9-0.6c-0.3,0-0.7,0-1,0.1V196z"/>
      <path class="st13" d="M1192.2,191.5c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1190,185,1192.2,187.9,1192.2,191.5z M1182.8,191.7c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1184,187.3,1182.8,189.2,1182.8,191.7z"/>
      <path class="st13" d="M1203.6,185.2v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
          c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1203.6z"/>
      <path class="st13" d="M1209,185.2v4.5c0.4,0,1-0.1,1.6-0.1c2.8,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8c-1.2,1.3-3.4,1.6-5.4,1.6
          c-1.4,0-2.5-0.1-3.2-0.2v-12.8H1209z M1209,196c0.3,0.1,0.7,0.1,1.1,0.1c1.4,0,2.6-0.7,2.6-2.1c0-1.5-1.1-2.1-2.5-2.1
          c-0.4,0-0.8,0-1.1,0.1V196z"/>
      <path class="st13" d="M1232,185.2v12.9h-2.9v-10.5h-4.6v10.5h-2.9v-12.9H1232z"/>
      <path class="st13" d="M1242.2,192.6h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V192.6z"/>
      <path class="st13" d="M1244.7,185.4c0.9-0.2,2.2-0.3,3.9-0.3c1.8,0,3.1,0.3,3.9,1c0.8,0.7,1.4,1.7,1.4,3s-0.4,2.3-1.2,3.1
          c-1,0.9-2.5,1.4-4.2,1.4c-0.4,0-0.7,0-1-0.1v4.6h-2.9V185.4z M1247.6,191.2c0.2,0.1,0.6,0.1,1,0.1c1.5,0,2.5-0.8,2.5-2.1
          c0-1.2-0.8-1.9-2.3-1.9c-0.6,0-1,0.1-1.2,0.1V191.2z"/>
      <path class="st13" d="M1263.5,192.6h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V192.6z"/>
      <path class="st13" d="M1276.5,185.2v10.6l1.3,0.1l-0.1,5.5h-2.2l-0.2-3.3h-7.5l-0.2,3.3h-2.2l-0.1-5.5l1.1-0.1
          c0.5-0.9,0.9-1.9,1.2-2.9c0.4-1.3,0.5-2.7,0.5-4.3v-3.4H1276.5z M1270.7,189.3c0,1.5-0.2,2.9-0.6,4.2c-0.2,0.8-0.6,1.6-0.9,2.2
          h4.3v-8.2h-2.8V189.3z"/>
      <path class="st13" d="M1282.3,185.2v5h4.8v-5h2.9v12.9h-2.9v-5.4h-4.8v5.4h-2.9v-12.9H1282.3z"/>
      <path class="st13" d="M1300.2,192.6h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V192.6z"/>
      <path class="st13" d="M1305.5,185.2v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7v-3.8
          c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1305.5z M1307.3,182.2c0,0.7,0.2,1.2,0.8,1.2
          c0.6,0,0.8-0.4,0.9-1.2h2c-0.1,1.6-1.2,2.4-2.9,2.4c-1.7,0-2.6-0.7-2.8-2.4H1307.3z"/>
      <path class="st13" d="M1330.3,185.2v12.9h-2.9v-10.5h-4.6v10.5h-2.9v-12.9H1330.3z"/>
      <path class="st13" d="M1344.6,191.5c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1342.4,185,1344.6,187.9,1344.6,191.5z M1335.3,191.7c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1336.4,187.3,1335.3,189.2,1335.3,191.7z"/>
      <path class="st13" d="M1356,185.2v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
          c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1356z"/>
      <path class="st13" d="M1370.4,191.5c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1368.2,185,1370.4,187.9,1370.4,191.5z M1361.1,191.7c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1362.2,187.3,1361.1,189.2,1361.1,191.7z"/>
      <path class="st13" d="M1372.3,185.4c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
          c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
          V185.4z M1375.2,190.3h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V190.3z M1375.2,196c0.3,0,0.6,0,1.1,0
          c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V196z"/>
      <path class="st13" d="M1386.5,185.2v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7v-3.8
          c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1386.5z"/>
      <path class="st13" d="M1400,185.2v5h4.8v-5h2.9v12.9h-2.9v-5.4h-4.8v5.4h-2.9v-12.9H1400z"/>
      <path class="st13" d="M1413.1,185.2v4.5c0.4-0.1,1-0.1,1.4-0.1c2.7,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8
          c-1.2,1.3-3.4,1.6-5.3,1.6c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1413.1z M1413.1,196c0.2,0.1,0.6,0.1,1,0.1c1.3,0,2.5-0.7,2.5-2.2
          c0-1.5-1.4-2.1-2.5-2.1c-0.4,0-0.7,0-1,0.1V196z M1423.8,185.2v12.9h-2.9v-12.9H1423.8z"/>
      <path class="st13" d="M1438.2,185.2v2.4h-4.9v10.5h-2.9v-12.9H1438.2z"/>
      <path class="st13" d="M1439.7,185.4c0.9-0.2,2.2-0.3,3.9-0.3c1.8,0,3.1,0.3,3.9,1c0.8,0.7,1.4,1.7,1.4,3s-0.4,2.3-1.2,3.1
          c-1,0.9-2.5,1.4-4.2,1.4c-0.4,0-0.7,0-1-0.1v4.6h-2.9V185.4z M1442.6,191.2c0.2,0.1,0.6,0.1,1,0.1c1.5,0,2.5-0.8,2.5-2.1
          c0-1.2-0.8-1.9-2.3-1.9c-0.6,0-1,0.1-1.2,0.1V191.2z"/>
      <path class="st13" d="M1452.8,185.2l1.9,4.5c0.3,0.9,0.6,1.7,0.9,2.5h0.1c0.2-0.6,0.4-1.4,0.7-2.5l1.4-4.5h3.1l-2.4,6.3
          c-0.8,2-1.6,4-2.5,5.1c-0.8,1.1-1.9,1.7-3.4,1.7c-0.7,0-1.1-0.1-1.4-0.2l0.2-2.3c0.2,0.1,0.5,0.1,1,0.1c0.9,0,1.5-0.6,1.7-1.2
          c0.1-0.2,0.1-0.4,0-0.6l-4.3-8.9H1452.8z"/>
      <path class="st13" d="M1471.1,185.2v10.6l1.3,0.1l-0.1,5.5h-2.2l-0.2-3.3h-7.5l-0.2,3.3h-2.2l-0.1-5.5l1.1-0.1
          c0.5-0.9,0.9-1.9,1.2-2.9c0.4-1.3,0.5-2.7,0.5-4.3v-3.4H1471.1z M1465.4,189.3c0,1.5-0.2,2.9-0.6,4.2c-0.2,0.8-0.6,1.6-0.9,2.2
          h4.3v-8.2h-2.8V189.3z"/>
      <path class="st13" d="M1477,185.2v5h4.8v-5h2.9v12.9h-2.9v-5.4h-4.8v5.4h-2.9v-12.9H1477z"/>
      <path class="st13" d="M1499.1,191.5c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1496.9,185,1499.1,187.9,1499.1,191.5z M1489.7,191.7c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1490.9,187.3,1489.7,189.2,1489.7,191.7z"/>
      <path class="st13" d="M1503.6,185.2v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7v-3.8
          c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1503.6z M1505.4,182.2c0,0.7,0.2,1.2,0.8,1.2
          c0.6,0,0.8-0.4,0.9-1.2h2c-0.1,1.6-1.2,2.4-2.9,2.4c-1.7,0-2.6-0.7-2.8-2.4H1505.4z"/>
      <path class="st13" d="M1520.9,185.2v5.4h0.2l3.6-5.4h3.5l-4.5,5.7c1.7,0.4,2.4,1.6,2.9,3.1c0.5,1.4,0.9,2.8,1.5,4.1h-3.1
          c-0.4-0.9-0.7-1.9-1-2.8c-0.4-1.5-1-2.6-2.6-2.6h-0.6v5.4h-2.9v-12.9H1520.9z"/>
      <path class="st13" d="M1539.9,185.2v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
          c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1539.9z"/>
      <path class="st13" d="M1550.1,192.6h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V192.6z"/>
      <path class="st13" d="M1555.1,187.6h-3.5v-2.4h9.9v2.4h-3.5v10.4h-2.9V187.6z"/>
      <path class="st13" d="M1566,185.2v5.4h0.2l3.6-5.4h3.5l-4.5,5.7c1.7,0.4,2.4,1.6,2.9,3.1c0.5,1.4,0.9,2.8,1.5,4.1h-3.1
          c-0.4-0.9-0.7-1.9-1-2.8c-0.4-1.5-1-2.6-2.6-2.6h-0.6v5.4h-2.9v-12.9H1566z"/>
      <path class="st13" d="M1577.4,185.2v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7v-3.8
          c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1577.4z"/>

      <path class="st13" d="M1177.5,252.1v2.4h-5.7v2.2c0.4-0.1,1.1-0.1,1.6-0.1c1.3,0,2.7,0.3,3.6,1.1c0.9,0.7,1.5,1.7,1.5,3.1
          c0,1.3-0.5,2.2-1.2,2.9c-1.2,1.1-3.3,1.5-5.2,1.5c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1177.5z M1171.8,262.9c0.3,0.1,0.7,0.1,1.1,0.1
          c0.7,0,1.4-0.2,1.9-0.7c0.4-0.3,0.6-0.9,0.6-1.5c0-0.7-0.3-1.1-0.7-1.5c-0.5-0.4-1.2-0.6-1.9-0.6c-0.3,0-0.7,0-1,0.1V262.9z"/>
      <path class="st13" d="M1192.2,258.4c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1190,251.9,1192.2,254.9,1192.2,258.4z M1182.8,258.6c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1184,254.2,1182.8,256.1,1182.8,258.6z"/>
      <path class="st13" d="M1203.6,252.1V265h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
          c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1203.6z"/>
      <path class="st13" d="M1209,252.1v4.5c0.4,0,1-0.1,1.6-0.1c2.8,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8c-1.2,1.3-3.4,1.6-5.4,1.6
          c-1.4,0-2.5-0.1-3.2-0.2v-12.8H1209z M1209,262.9c0.3,0.1,0.7,0.1,1.1,0.1c1.4,0,2.6-0.7,2.6-2.1c0-1.5-1.1-2.1-2.5-2.1
          c-0.4,0-0.8,0-1.1,0.1V262.9z"/>
      <path class="st13" d="M1221.5,252.3c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
          c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
          V252.3z M1224.4,257.2h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V257.2z M1224.4,262.9c0.3,0,0.6,0,1.1,0
          c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V262.9z"/>
      <path class="st13" d="M1246.4,264.6c-0.5,0.3-1.7,0.6-3.3,0.6c-4.5,0-6.8-2.8-6.8-6.4c0-4.4,3.1-6.9,7-6.9
          c1.5,0,2.7,0.3,3.2,0.6l-0.6,2.3c-0.6-0.2-1.4-0.5-2.4-0.5c-2.3,0-4.1,1.4-4.1,4.3c0,2.6,1.5,4.2,4.1,4.2c0.9,0,1.9-0.2,2.4-0.4
          L1246.4,264.6z"/>
      <path class="st13" d="M1256,259.6h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V259.6z"/>
      <path class="st13" d="M1258.5,252.3c0.9-0.2,2.2-0.3,3.9-0.3c1.8,0,3.1,0.3,3.9,1c0.8,0.7,1.4,1.7,1.4,3s-0.4,2.3-1.2,3.1
          c-1,0.9-2.5,1.4-4.2,1.4c-0.4,0-0.7,0-1-0.1v4.6h-2.9V252.3z M1261.4,258.1c0.2,0.1,0.6,0.1,1,0.1c1.5,0,2.5-0.8,2.5-2.1
          c0-1.2-0.8-1.9-2.3-1.9c-0.6,0-1,0.1-1.2,0.1V258.1z"/>
      <path class="st13" d="M1278.7,252.1v10.6l1.3,0.1l-0.1,5.5h-2.2l-0.2-3.3h-7.5l-0.2,3.3h-2.2l-0.1-5.5l1.1-0.1
          c0.5-0.9,0.9-1.9,1.2-2.9c0.4-1.3,0.5-2.7,0.5-4.3v-3.4H1278.7z M1272.9,256.2c0,1.5-0.2,2.9-0.6,4.2c-0.2,0.8-0.6,1.6-0.9,2.2
          h4.3v-8.2h-2.8V256.2z"/>
      <path class="st13" d="M1284.5,252.1v10.5h4.5v-10.5h2.9v10.6l1.3,0.1l-0.1,5.5h-2.2l-0.2-3.3h-9.1v-12.9H1284.5z"/>
      <path class="st13" d="M1302.5,259.6h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V259.6z"/>
      <path class="st13" d="M1177.5,297.9v2.4h-5.7v2.2c0.4-0.1,1.1-0.1,1.6-0.1c1.3,0,2.7,0.3,3.6,1.1c0.9,0.7,1.5,1.7,1.5,3.1
          c0,1.3-0.5,2.2-1.2,2.9c-1.2,1.1-3.3,1.5-5.2,1.5c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1177.5z M1171.8,308.7c0.3,0.1,0.7,0.1,1.1,0.1
          c0.7,0,1.4-0.2,1.9-0.7c0.4-0.3,0.6-0.9,0.6-1.5c0-0.7-0.3-1.1-0.7-1.5c-0.5-0.4-1.2-0.6-1.9-0.6c-0.3,0-0.7,0-1,0.1V308.7z"/>
      <path class="st13" d="M1192.2,304.2c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1190,297.7,1192.2,300.7,1192.2,304.2z M1182.8,304.4c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1184,300,1182.8,301.9,1182.8,304.4z"/>
      <path class="st13" d="M1203.6,297.9v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
          c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1203.6z"/>
      <path class="st13" d="M1209,297.9v4.5c0.4,0,1-0.1,1.6-0.1c2.8,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8c-1.2,1.3-3.4,1.6-5.4,1.6
          c-1.4,0-2.5-0.1-3.2-0.2v-12.8H1209z M1209,308.7c0.3,0.1,0.7,0.1,1.1,0.1c1.4,0,2.6-0.7,2.6-2.1c0-1.5-1.1-2.1-2.5-2.1
          c-0.4,0-0.8,0-1.1,0.1V308.7z"/>
      <path class="st13" d="M1232,297.9v12.9h-2.9v-10.5h-4.6v10.5h-2.9v-12.9H1232z"/>
      <path class="st13" d="M1246.3,304.2c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1244.1,297.7,1246.3,300.7,1246.3,304.2z M1237,304.4c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1238.1,300,1237,301.9,1237,304.4z"/>
      <path class="st13" d="M1258,297.9v10.6l1.3,0.1l-0.1,5.5h-2.2l-0.2-3.3h-7.5l-0.2,3.3h-2.2l-0.1-5.5l1.1-0.1
          c0.5-0.9,0.9-1.9,1.2-2.9c0.4-1.3,0.5-2.7,0.5-4.3v-3.4H1258z M1252.2,302c0,1.5-0.2,2.9-0.6,4.2c-0.2,0.8-0.6,1.6-0.9,2.2h4.3
          v-8.2h-2.8V302z"/>
      <path class="st13" d="M1274.5,297.9v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
          c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1274.5z"/>
      <path class="st13" d="M1288.9,304.2c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1286.7,297.7,1288.9,300.7,1288.9,304.2z M1279.6,304.4c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1280.7,300,1279.6,301.9,1279.6,304.4z"/>
      <path class="st13" d="M1301.2,297.9v12.9h-2.9v-10.5h-4.6v10.5h-2.9v-12.9H1301.2z"/>
      <path class="st13" d="M1306.8,307.5l-0.9,3.3h-3l3.9-12.9h3.8l4,12.9h-3.1l-1-3.3H1306.8z M1310,305.3l-0.8-2.7
          c-0.2-0.8-0.5-1.7-0.7-2.5h0c-0.2,0.8-0.4,1.7-0.6,2.5l-0.8,2.7H1310z"/>
      <path class="st13" d="M1317.5,300.3h-3.5v-2.4h9.9v2.4h-3.5v10.4h-2.9V300.3z"/>
      <path class="st13" d="M1328.4,297.9v5.4h0.2l3.6-5.4h3.5l-4.5,5.7c1.7,0.4,2.4,1.6,2.9,3.1c0.5,1.4,0.9,2.8,1.5,4.1h-3.1
          c-0.4-0.9-0.7-1.9-1-2.8c-0.4-1.5-1-2.6-2.6-2.6h-0.6v5.4h-2.9v-12.9H1328.4z"/>
      <path class="st13" d="M1348.6,304.2c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1346.4,297.7,1348.6,300.7,1348.6,304.2z M1339.3,304.4c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1340.4,300,1339.3,301.9,1339.3,304.4z"/>
      <path class="st13" d="M1353.2,297.9v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7V307
          c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1353.2z M1355,294.9c0,0.7,0.2,1.2,0.8,1.2
          c0.6,0,0.8-0.4,0.9-1.2h2c-0.1,1.6-1.2,2.4-2.9,2.4c-1.7,0-2.6-0.7-2.8-2.4H1355z"/>
      <path class="st13" d="M1377,310.4c-0.5,0.3-1.7,0.6-3.3,0.6c-4.5,0-6.8-2.8-6.8-6.4c0-4.4,3.1-6.9,7-6.9c1.5,0,2.7,0.3,3.2,0.6
          l-0.6,2.3c-0.6-0.2-1.4-0.5-2.4-0.5c-2.3,0-4.1,1.4-4.1,4.3c0,2.6,1.5,4.2,4.1,4.2c0.9,0,1.9-0.2,2.4-0.4L1377,310.4z"/>
      <path class="st13" d="M1389.1,297.9v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
          c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1389.1z"/>
      <path class="st13" d="M1399.3,305.3h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V305.3z"/>
      <path class="st13" d="M1401.9,298.1c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
          c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
          V298.1z M1404.8,303h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V303z M1404.8,308.7c0.3,0,0.6,0,1.1,0
          c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V308.7z"/>
      <path class="st13" d="M1416.4,307.5l-0.9,3.3h-3l3.9-12.9h3.8l4,12.9h-3.1l-1-3.3H1416.4z M1419.7,305.3l-0.8-2.7
          c-0.2-0.8-0.5-1.7-0.7-2.5h0c-0.2,0.8-0.4,1.7-0.6,2.5l-0.8,2.7H1419.7z"/>

      <path class="st13" d="M1177.5,363.3v2.4h-5.7v2.2c0.4-0.1,1.1-0.1,1.6-0.1c1.3,0,2.7,0.3,3.6,1.1c0.9,0.7,1.5,1.7,1.5,3.1
          c0,1.3-0.5,2.2-1.2,2.9c-1.2,1.1-3.3,1.5-5.2,1.5c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1177.5z M1171.8,374.1c0.3,0.1,0.7,0.1,1.1,0.1
          c0.7,0,1.4-0.2,1.9-0.7c0.4-0.3,0.6-0.9,0.6-1.5c0-0.7-0.3-1.1-0.7-1.5c-0.5-0.4-1.2-0.6-1.9-0.6c-0.3,0-0.7,0-1,0.1V374.1z"/>
      <path class="st13" d="M1192.2,369.6c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1190,363.1,1192.2,366.1,1192.2,369.6z M1182.8,369.8c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1184,365.4,1182.8,367.3,1182.8,369.8z"/>
      <path class="st13" d="M1203.6,363.3v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
          c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1203.6z"/>
      <path class="st13" d="M1209,363.3v4.5c0.4,0,1-0.1,1.6-0.1c2.8,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8c-1.2,1.3-3.4,1.6-5.4,1.6
          c-1.4,0-2.5-0.1-3.2-0.2v-12.8H1209z M1209,374.1c0.3,0.1,0.7,0.1,1.1,0.1c1.4,0,2.6-0.7,2.6-2.1c0-1.5-1.1-2.1-2.5-2.1
          c-0.4,0-0.8,0-1.1,0.1V374.1z"/>
      <path class="st13" d="M1221.1,364.1c0.9-0.5,2.2-1,4-1c2.8,0,4.5,1.4,4.5,3.3c0,1.6-1.1,2.7-2.6,3v0c1.6,0.2,3,1.3,3,3.1
          c0,2.9-2.9,3.9-5.3,3.9c-1.3,0-2.8-0.3-3.9-0.9l0.6-2.2c0.6,0.3,1.9,0.8,3.1,0.8c1.5,0,2.4-0.6,2.4-1.7c0-1.4-1.5-1.7-2.7-1.7
          h-1.2v-2.1h1.2c1.3,0,2.3-0.6,2.3-1.6c0-0.8-0.7-1.4-2-1.4c-1,0-2.1,0.4-2.8,0.8L1221.1,364.1z"/>
      <path class="st13" d="M1234.9,372.9l-0.9,3.3h-3l3.9-12.9h3.8l4,12.9h-3.1l-1-3.3H1234.9z M1238.2,370.7l-0.8-2.7
          c-0.2-0.8-0.5-1.7-0.7-2.5h0c-0.2,0.8-0.4,1.7-0.6,2.5l-0.8,2.7H1238.2z"/>
      <path class="st13" d="M1256.1,363.3v2.4h-4.9v10.5h-2.9v-12.9H1256.1z"/>
      <path class="st13" d="M1257.7,363.5c0.9-0.2,2.2-0.3,3.9-0.3c1.8,0,3.1,0.3,3.9,1c0.8,0.7,1.4,1.7,1.4,3s-0.4,2.3-1.2,3.1
          c-1,0.9-2.5,1.4-4.2,1.4c-0.4,0-0.7,0-1-0.1v4.6h-2.9V363.5z M1260.5,369.4c0.2,0.1,0.6,0.1,1,0.1c1.5,0,2.5-0.8,2.5-2.1
          c0-1.2-0.8-1.9-2.3-1.9c-0.6,0-1,0.1-1.2,0.1V369.4z"/>
      <path class="st13" d="M1270.7,363.3l1.9,4.5c0.3,0.9,0.6,1.7,0.9,2.5h0.1c0.2-0.6,0.4-1.4,0.7-2.5l1.4-4.5h3.1l-2.4,6.3
          c-0.8,2-1.6,4-2.5,5.1c-0.8,1.1-1.9,1.7-3.4,1.7c-0.7,0-1.1-0.1-1.4-0.2l0.2-2.3c0.2,0.1,0.5,0.1,1,0.1c0.9,0,1.5-0.6,1.7-1.2
          c0.1-0.2,0.1-0.4,0-0.6l-4.3-8.9H1270.7z"/>
      <path class="st13" d="M1289.1,363.3v10.6l1.3,0.1l-0.1,5.5h-2.2l-0.2-3.3h-7.5l-0.2,3.3h-2.2l-0.1-5.5l1.1-0.1
          c0.5-0.9,0.9-1.9,1.2-2.9c0.4-1.3,0.5-2.7,0.5-4.3v-3.4H1289.1z M1283.3,367.4c0,1.5-0.2,2.9-0.6,4.2c-0.2,0.8-0.6,1.6-0.9,2.2
          h4.3v-8.2h-2.8V367.4z"/>
      <path class="st13" d="M1294.7,363.3v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7v-3.8
          c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1294.7z"/>
      <path class="st13" d="M1308.1,363.3v5h4.8v-5h2.9v12.9h-2.9v-5.4h-4.8v5.4h-2.9v-12.9H1308.1z"/>
      <path class="st13" d="M1330.2,369.6c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1328,363.1,1330.2,366.1,1330.2,369.6z M1320.9,369.8c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1322,365.4,1320.9,367.3,1320.9,369.8z"/>
      <path class="st13" d="M1334.8,363.3v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7v-3.8
          c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1334.8z M1336.6,360.4c0,0.7,0.2,1.2,0.8,1.2
          c0.6,0,0.8-0.4,0.9-1.2h2c-0.1,1.6-1.2,2.4-2.9,2.4c-1.7,0-2.6-0.7-2.8-2.4H1336.6z"/>

      <path class="st13" d="M1177.5,429.7v2.4h-5.7v2.2c0.4-0.1,1.1-0.1,1.6-0.1c1.3,0,2.7,0.3,3.6,1.1c0.9,0.7,1.5,1.7,1.5,3.1
          c0,1.3-0.5,2.2-1.2,2.9c-1.2,1.1-3.3,1.5-5.2,1.5c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1177.5z M1171.8,440.5c0.3,0.1,0.7,0.1,1.1,0.1
          c0.7,0,1.4-0.2,1.9-0.7c0.4-0.3,0.6-0.9,0.6-1.5c0-0.7-0.3-1.1-0.7-1.5c-0.5-0.4-1.2-0.6-1.9-0.6c-0.3,0-0.7,0-1,0.1V440.5z"/>
      <path class="st13" d="M1192.2,436c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1190,429.5,1192.2,432.5,1192.2,436z M1182.8,436.2c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1184,431.8,1182.8,433.7,1182.8,436.2z"/>
      <path class="st13" d="M1203.6,429.7v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
          c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1203.6z"/>
      <path class="st13" d="M1209,429.7v4.5c0.4,0,1-0.1,1.6-0.1c2.8,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8c-1.2,1.3-3.4,1.6-5.4,1.6
          c-1.4,0-2.5-0.1-3.2-0.2v-12.8H1209z M1209,440.5c0.3,0.1,0.7,0.1,1.1,0.1c1.4,0,2.6-0.7,2.6-2.1c0-1.5-1.1-2.1-2.5-2.1
          c-0.4,0-0.8,0-1.1,0.1V440.5z"/>
      <path class="st13" d="M1221.5,429.9c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
          c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
          V429.9z M1224.4,434.8h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V434.8z M1224.4,440.5c0.3,0,0.6,0,1.1,0
          c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V440.5z"/>
      <path class="st13" d="M1239.8,429.7v10.5h3.2v-10.5h2.9v10.5h3.2v-10.5h2.9v12.9H1237v-12.9H1239.8z"/>
      <path class="st13" d="M1262.1,437.1h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V437.1z"/>
      <path class="st13" d="M1267.3,429.7v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7v-3.8
          c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1267.3z M1269.1,426.8c0,0.7,0.2,1.2,0.8,1.2
          c0.6,0,0.8-0.4,0.9-1.2h2c-0.1,1.6-1.2,2.4-2.9,2.4c-1.7,0-2.6-0.7-2.8-2.4H1269.1z"/>
      <path class="st13" d="M1280.8,429.7v5h4.8v-5h2.9v12.9h-2.9v-5.4h-4.8v5.4h-2.9v-12.9H1280.8z"/>
      <path class="st13" d="M1302.8,436c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1300.7,429.5,1302.8,432.5,1302.8,436z M1293.5,436.2c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1294.6,431.8,1293.5,433.7,1293.5,436.2z"/>
      <path class="st13" d="M1315.5,437.7c-0.1-1.6-0.1-3.4-0.1-5.3h-0.1c-0.4,1.6-0.9,3.5-1.4,5l-1.6,5h-2.3l-1.4-5
          c-0.4-1.5-0.9-3.3-1.2-5h0c-0.1,1.7-0.1,3.7-0.2,5.3l-0.2,4.9h-2.7l0.8-12.9h3.9l1.3,4.3c0.4,1.5,0.8,3.1,1.1,4.6h0.1
          c0.4-1.5,0.8-3.2,1.2-4.6l1.4-4.3h3.8l0.7,12.9h-2.9L1315.5,437.7z"/>
      <path class="st13" d="M1327.4,429.7v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7v-3.8
          c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1327.4z"/>
      <path class="st13" d="M1349.5,429.7v2.4h-4.9v10.5h-2.9v-12.9H1349.5z"/>
      <path class="st13" d="M1351.1,429.9c0.9-0.2,2.2-0.3,3.9-0.3c1.8,0,3.1,0.3,3.9,1c0.8,0.7,1.4,1.7,1.4,3s-0.4,2.3-1.2,3.1
          c-1,0.9-2.5,1.4-4.2,1.4c-0.4,0-0.7,0-1-0.1v4.6h-2.9V429.9z M1354,435.7c0.2,0.1,0.6,0.1,1,0.1c1.5,0,2.5-0.8,2.5-2.1
          c0-1.2-0.8-1.9-2.3-1.9c-0.6,0-1,0.1-1.2,0.1V435.7z"/>
      <path class="st13" d="M1364.1,429.7l1.9,4.5c0.3,0.9,0.6,1.7,0.9,2.5h0.1c0.2-0.6,0.4-1.4,0.7-2.5l1.4-4.5h3.1l-2.4,6.3
          c-0.8,2-1.6,4-2.5,5.1c-0.8,1.1-1.9,1.7-3.4,1.7c-0.7,0-1.1-0.1-1.4-0.2l0.2-2.3c0.2,0.1,0.5,0.1,1,0.1c0.9,0,1.5-0.6,1.7-1.2
          c0.1-0.2,0.1-0.4,0-0.6l-4.3-8.9H1364.1z"/>
      <path class="st13" d="M1382.5,429.7v10.6l1.3,0.1l-0.1,5.5h-2.2l-0.2-3.3h-7.5l-0.2,3.3h-2.2l-0.1-5.5l1.1-0.1
          c0.5-0.9,0.9-1.9,1.2-2.9c0.4-1.3,0.5-2.7,0.5-4.3v-3.4H1382.5z M1376.8,433.8c0,1.5-0.2,2.9-0.6,4.2c-0.2,0.8-0.6,1.6-0.9,2.2
          h4.3v-8.2h-2.8V433.8z"/>
      <path class="st13" d="M1388.4,429.7v5h4.8v-5h2.9v12.9h-2.9v-5.4h-4.8v5.4h-2.9v-12.9H1388.4z"/>
      <path class="st13" d="M1410.4,436c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1408.3,429.5,1410.4,432.5,1410.4,436z M1401.1,436.2c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1402.2,431.8,1401.1,433.7,1401.1,436.2z"/>
      <path class="st13" d="M1423.1,437.7c-0.1-1.6-0.1-3.4-0.1-5.3h-0.1c-0.4,1.6-0.9,3.5-1.4,5l-1.6,5h-2.3l-1.4-5
          c-0.4-1.5-0.9-3.3-1.2-5h0c-0.1,1.7-0.1,3.7-0.2,5.3l-0.2,4.9h-2.7l0.8-12.9h3.9l1.3,4.3c0.4,1.5,0.8,3.1,1.1,4.6h0.1
          c0.4-1.5,0.8-3.2,1.2-4.6l1.4-4.3h3.8l0.7,12.9h-2.9L1423.1,437.7z"/>
      <path class="st13" d="M1444.1,436c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1441.9,429.5,1444.1,432.5,1444.1,436z M1434.8,436.2c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1435.9,431.8,1434.8,433.7,1434.8,436.2z"/>
      <path class="st13" d="M1448.3,432.1h-3.5v-2.4h9.9v2.4h-3.5v10.4h-2.9V432.1z"/>
      <path class="st13" d="M1465.4,429.7v10.6l1.3,0.1l-0.1,5.5h-2.2l-0.2-3.3h-7.5l-0.2,3.3h-2.2l-0.1-5.5l1.1-0.1
          c0.5-0.9,0.9-1.9,1.2-2.9c0.4-1.3,0.5-2.7,0.5-4.3v-3.4H1465.4z M1459.7,433.8c0,1.5-0.2,2.9-0.6,4.2c-0.2,0.8-0.6,1.6-0.9,2.2
          h4.3v-8.2h-2.8V433.8z"/>
      <path class="st13" d="M1476,437.1h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V437.1z"/>
      <path class="st13" d="M1488.6,429.7v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
          c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1488.6z"/>
      <path class="st13" d="M1498.8,437.1h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V437.1z"/>
      <path class="st13" d="M1515.7,429.7v12.9h-2.9v-10.5h-4.6v10.5h-2.9v-12.9H1515.7z"/>
      <path class="st13" d="M1530,436c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1527.8,429.5,1530,432.5,1530,436z M1520.7,436.2c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1521.8,431.8,1520.7,433.7,1520.7,436.2z"/>
      <path class="st13" d="M1531.2,430.5c0.9-0.5,2.2-1,4-1c2.8,0,4.5,1.4,4.5,3.3c0,1.6-1.1,2.7-2.6,3v0c1.6,0.2,3,1.3,3,3.1
          c0,2.9-2.9,3.9-5.3,3.9c-1.3,0-2.8-0.3-3.9-0.9l0.6-2.2c0.6,0.3,1.9,0.8,3.1,0.8c1.5,0,2.4-0.6,2.4-1.7c0-1.4-1.5-1.7-2.7-1.7
          h-1.2v-2.1h1.2c1.3,0,2.3-0.6,2.3-1.6c0-0.8-0.7-1.4-2-1.4c-1,0-2.1,0.4-2.8,0.8L1531.2,430.5z"/>
      <path class="st13" d="M1542.1,429.9c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
          c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
          V429.9z M1545,434.8h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V434.8z M1545,440.5c0.3,0,0.6,0,1.1,0
          c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V440.5z"/>
      <path class="st13" d="M1565.4,436c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1563.2,429.5,1565.4,432.5,1565.4,436z M1556,436.2c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1557.2,431.8,1556,433.7,1556,436.2z"/>
      <path class="st13" d="M1570.2,429.7v5h4.8v-5h2.9v12.9h-2.9v-5.4h-4.8v5.4h-2.9v-12.9H1570.2z"/>
      <path class="st13" d="M1592.2,436c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1590.1,429.5,1592.2,432.5,1592.2,436z M1582.9,436.2c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1584.1,431.8,1582.9,433.7,1582.9,436.2z"/>
      <path class="st13" d="M1596.8,429.7v3.7c0,1.5,0.8,2.2,2.2,2.2c0.7,0,1.3-0.2,1.9-0.5v-5.4h2.9v12.9h-2.9v-5.3h-0.1
          c-0.9,0.5-1.8,0.7-2.9,0.7c-2.3,0-4-1.2-4-4v-4.2H1596.8z"/>
      <path class="st13" d="M1609.3,429.7v5h4.8v-5h2.9v12.9h-2.9v-5.4h-4.8v5.4h-2.9v-12.9H1609.3z"/>
      <path class="st13" d="M1622.2,429.7v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7v-3.8
          c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1622.2z"/>
      <path class="st13" d="M1635.6,429.7v5.4h0.2l3.6-5.4h3.5l-4.5,5.7c1.7,0.4,2.4,1.6,2.9,3.1c0.5,1.4,0.9,2.8,1.5,4.1h-3.1
          c-0.4-0.9-0.7-1.9-1-2.8c-0.4-1.5-1-2.6-2.6-2.6h-0.6v5.4h-2.9v-12.9H1635.6z"/>
      <path class="st13" d="M1647.5,439.3l-0.9,3.3h-3l3.9-12.9h3.8l4,12.9h-3.1l-1-3.3H1647.5z M1650.8,437.1l-0.8-2.7
          c-0.2-0.8-0.5-1.7-0.7-2.5h0c-0.2,0.8-0.4,1.7-0.6,2.5l-0.8,2.7H1650.8z"/>

      <path class="st13" d="M1177.5,495.5v2.4h-5.7v2.2c0.4-0.1,1.1-0.1,1.6-0.1c1.3,0,2.7,0.3,3.6,1.1c0.9,0.7,1.5,1.7,1.5,3.1
          c0,1.3-0.5,2.2-1.2,2.9c-1.2,1.1-3.3,1.5-5.2,1.5c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1177.5z M1171.8,506.3c0.3,0.1,0.7,0.1,1.1,0.1
          c0.7,0,1.4-0.2,1.9-0.7c0.4-0.3,0.6-0.9,0.6-1.5c0-0.7-0.3-1.1-0.7-1.5c-0.5-0.4-1.2-0.6-1.9-0.6c-0.3,0-0.7,0-1,0.1V506.3z"/>
      <path class="st13" d="M1192.2,501.8c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1190,495.3,1192.2,498.3,1192.2,501.8z M1182.8,502c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1184,497.6,1182.8,499.5,1182.8,502z"/>
      <path class="st13" d="M1203.6,495.5v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
          c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1203.6z"/>
      <path class="st13" d="M1209,495.5v4.5c0.4,0,1-0.1,1.6-0.1c2.8,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8c-1.2,1.3-3.4,1.6-5.4,1.6
          c-1.4,0-2.5-0.1-3.2-0.2v-12.8H1209z M1209,506.3c0.3,0.1,0.7,0.1,1.1,0.1c1.4,0,2.6-0.7,2.6-2.1c0-1.5-1.1-2.1-2.5-2.1
          c-0.4,0-0.8,0-1.1,0.1V506.3z"/>
      <path class="st13" d="M1231,508c-0.5,0.3-1.7,0.6-3.3,0.6c-4.5,0-6.8-2.8-6.8-6.4c0-4.4,3.1-6.9,7-6.9c1.5,0,2.7,0.3,3.2,0.6
          l-0.6,2.3c-0.6-0.2-1.4-0.5-2.4-0.5c-2.3,0-4.1,1.4-4.1,4.3c0,2.6,1.5,4.2,4.1,4.2c0.9,0,1.9-0.2,2.4-0.4L1231,508z"/>
      <path class="st13" d="M1244.2,501.8c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1242.1,495.3,1244.2,498.3,1244.2,501.8z M1234.9,502c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1236.1,497.6,1234.9,499.5,1234.9,502z"/>
      <path class="st13" d="M1259.5,508c-0.5,0.3-1.7,0.6-3.3,0.6c-4.5,0-6.8-2.8-6.8-6.4c0-4.4,3.1-6.9,7-6.9c1.5,0,2.7,0.3,3.2,0.6
          l-0.6,2.3c-0.6-0.2-1.4-0.5-2.4-0.5c-2.3,0-4.1,1.4-4.1,4.3c0,2.6,1.5,4.2,4.1,4.2c0.9,0,1.9-0.2,2.4-0.4L1259.5,508z"/>
      <path class="st13" d="M1264.2,498h-3.5v-2.4h9.9v2.4h-3.5v10.4h-2.9V498z"/>
      <path class="st13" d="M1283.4,501.8c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1281.2,495.3,1283.4,498.3,1283.4,501.8z M1274.1,502c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1275.2,497.6,1274.1,499.5,1274.1,502z"/>
      <path class="st13" d="M1285.3,495.7c0.9-0.2,2.2-0.3,3.9-0.3c1.8,0,3.1,0.3,3.9,1c0.8,0.7,1.4,1.7,1.4,3c0,1.3-0.4,2.3-1.2,3.1
          c-1,0.9-2.5,1.4-4.2,1.4c-0.4,0-0.7,0-1-0.1v4.6h-2.9V495.7z M1288.2,501.5c0.2,0.1,0.6,0.1,1,0.1c1.5,0,2.5-0.8,2.5-2.1
          c0-1.2-0.8-1.9-2.3-1.9c-0.6,0-1,0.1-1.2,0.1V501.5z"/>
      <path class="st13" d="M1308.2,501.8c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1306,495.3,1308.2,498.3,1308.2,501.8z M1298.9,502c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1300,497.6,1298.9,499.5,1298.9,502z"/>
      <path class="st13" d="M1313,495.5v5h4.8v-5h2.9v12.9h-2.9V503h-4.8v5.4h-2.9v-12.9H1313z"/>
      <path class="st13" d="M1326.2,495.5v4.5c0.4-0.1,1-0.1,1.4-0.1c2.7,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8
          c-1.2,1.3-3.4,1.6-5.3,1.6c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1326.2z M1326.2,506.3c0.2,0.1,0.6,0.1,1,0.1c1.3,0,2.5-0.7,2.5-2.2
          c0-1.5-1.4-2.1-2.5-2.1c-0.4,0-0.7,0-1,0.1V506.3z M1336.8,495.5v12.9h-2.9v-12.9H1336.8z"/>
      <path class="st13" d="M1352.9,508c-0.5,0.3-1.7,0.6-3.3,0.6c-4.5,0-6.8-2.8-6.8-6.4c0-4.4,3.1-6.9,7-6.9c1.5,0,2.7,0.3,3.2,0.6
          l-0.6,2.3c-0.6-0.2-1.4-0.5-2.4-0.5c-2.3,0-4.1,1.4-4.1,4.3c0,2.6,1.5,4.2,4.1,4.2c0.9,0,1.9-0.2,2.4-0.4L1352.9,508z"/>
      <path class="st13" d="M1365.2,495.5v12.9h-2.9v-10.5h-4.6v10.5h-2.9v-12.9H1365.2z"/>
      <path class="st13" d="M1370.4,495.5v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7v-3.8
          c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1370.4z"/>
      <path class="st13" d="M1383.9,495.5v5h4.8v-5h2.9v12.9h-2.9V503h-4.8v5.4h-2.9v-12.9H1383.9z"/>
      <path class="st13" d="M1397,495.5v4.5c0.4-0.1,1-0.1,1.4-0.1c2.7,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8
          c-1.2,1.3-3.4,1.6-5.3,1.6c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1397z M1397,506.3c0.2,0.1,0.6,0.1,1,0.1c1.3,0,2.5-0.7,2.5-2.2
          c0-1.5-1.4-2.1-2.5-2.1c-0.4,0-0.7,0-1,0.1V506.3z M1407.7,495.5v12.9h-2.9v-12.9H1407.7z"/>

      <path class="st13" d="M1177.5,575.9v2.4h-5.7v2.2c0.4-0.1,1.1-0.1,1.6-0.1c1.3,0,2.7,0.3,3.6,1.1c0.9,0.7,1.5,1.7,1.5,3.1
          c0,1.3-0.5,2.2-1.2,2.9c-1.2,1.1-3.3,1.5-5.2,1.5c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1177.5z M1171.8,586.7c0.3,0.1,0.7,0.1,1.1,0.1
          c0.7,0,1.4-0.2,1.9-0.7c0.4-0.3,0.6-0.9,0.6-1.5c0-0.7-0.3-1.1-0.7-1.5c-0.5-0.4-1.2-0.6-1.9-0.6c-0.3,0-0.7,0-1,0.1V586.7z"/>
      <path class="st13" d="M1192.2,582.3c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1190,575.7,1192.2,578.7,1192.2,582.3z M1182.8,582.4c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1184,578,1182.8,579.9,1182.8,582.4z"/>
      <path class="st13" d="M1203.6,575.9v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
          c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1203.6z"/>
      <path class="st13" d="M1209,575.9v4.5c0.4,0,1-0.1,1.6-0.1c2.8,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8c-1.2,1.3-3.4,1.6-5.4,1.6
          c-1.4,0-2.5-0.1-3.2-0.2v-12.8H1209z M1209,586.7c0.3,0.1,0.7,0.1,1.1,0.1c1.4,0,2.6-0.7,2.6-2.1c0-1.5-1.1-2.1-2.5-2.1
          c-0.4,0-0.8,0-1.1,0.1V586.7z"/>
      <path class="st13" d="M1221.5,576.1c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
          c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
          V576.1z M1224.4,581h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V581z M1224.4,586.8c0.3,0,0.6,0,1.1,0
          c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V586.8z"/>
      <path class="st13" d="M1237,576.1c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
          c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
          V576.1z M1239.8,581h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V581z M1239.8,586.8c0.3,0,0.6,0,1.1,0
          c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V586.8z"/>
      <path class="st13" d="M1256.2,583.4h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V583.4z"/>
      <path class="st13" d="M1258.7,576.1c0.9-0.2,2.2-0.3,3.9-0.3c1.8,0,3.1,0.3,3.9,1c0.8,0.7,1.4,1.7,1.4,3c0,1.3-0.4,2.3-1.2,3.1
          c-1,0.9-2.5,1.4-4.2,1.4c-0.4,0-0.7,0-1-0.1v4.6h-2.9V576.1z M1261.6,582c0.2,0.1,0.6,0.1,1,0.1c1.5,0,2.5-0.8,2.5-2.1
          c0-1.2-0.8-1.9-2.3-1.9c-0.6,0-1,0.1-1.2,0.1V582z"/>
      <path class="st13" d="M1276.1,588.8l-1.2-2.3c-0.5-0.9-0.8-1.6-1.1-2.3h0c-0.3,0.7-0.6,1.4-1,2.3l-1.1,2.3h-3.3l3.7-6.5
          l-3.6-6.4h3.3l1.1,2.4c0.4,0.8,0.7,1.4,1,2.1h0c0.3-0.8,0.6-1.4,0.9-2.1l1.1-2.4h3.3l-3.6,6.3l3.8,6.6H1276.1z"/>
      <path class="st13" d="M1284,575.9v5h4.8v-5h2.9v12.9h-2.9v-5.4h-4.8v5.4h-2.9v-12.9H1284z"/>
      <path class="st13" d="M1301.9,583.4h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V583.4z"/>
      <path class="st13" d="M1315.3,583.9c-0.1-1.5-0.1-3.4-0.1-5.3h-0.1c-0.4,1.6-0.9,3.5-1.4,5l-1.6,5h-2.3l-1.4-5
          c-0.4-1.5-0.9-3.3-1.2-5h0c-0.1,1.7-0.1,3.7-0.2,5.3l-0.2,4.9h-2.7l0.8-12.9h3.9l1.3,4.3c0.4,1.5,0.8,3.1,1.1,4.6h0.1
          c0.4-1.5,0.8-3.2,1.2-4.6l1.4-4.3h3.8l0.7,12.9h-2.9L1315.3,583.9z"/>
      <path class="st13" d="M1336.3,582.3c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1334.1,575.7,1336.3,578.7,1336.3,582.3z M1327,582.4c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1328.1,578,1327,579.9,1327,582.4z"/>
      <path class="st13" d="M1340.5,578.4h-3.5v-2.4h9.9v2.4h-3.5v10.4h-2.9V578.4z"/>
      <path class="st13" d="M1357.6,575.9v10.6l1.3,0.1l-0.1,5.5h-2.2l-0.2-3.3h-7.5l-0.2,3.3h-2.2l-0.1-5.5l1.1-0.1
          c0.5-0.9,0.9-1.9,1.2-2.9c0.4-1.3,0.5-2.7,0.5-4.3v-3.4H1357.6z M1351.9,580c0,1.5-0.2,2.9-0.6,4.2c-0.2,0.8-0.6,1.6-0.9,2.2
          h4.3v-8.2h-2.8V580z"/>
      <path class="st13" d="M1368.2,583.4h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V583.4z"/>
      <path class="st13" d="M1380.8,575.9v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
          c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1380.8z"/>
      <path class="st13" d="M1391,583.4h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V583.4z"/>
      <path class="st13" d="M1399.7,575.9l3.2,5.4h0.3v-5.4h2.8v5.4h0.3l3.2-5.4h3.4l-4.4,5.8c1.7,0.2,2.6,1.5,3.1,3.1
          c0.4,1.3,0.8,2.7,1.3,4h-2.9c-0.4-0.9-0.7-1.9-0.9-2.9c-0.4-1.4-1-2.5-2.6-2.5h-0.5v5.4h-2.8v-5.4h-0.5c-1.6,0-2.2,1.2-2.6,2.5
          c-0.3,0.9-0.5,2-0.9,2.9h-2.9c0.6-1.3,0.9-2.7,1.4-3.9c0.5-1.7,1.4-2.9,3.1-3.2l-4.4-5.8H1399.7z"/>
      <path class="st13" d="M1416.8,575.9v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7V585
          c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1416.8z"/>
      <path class="st13" d="M1427.4,576.1c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
          c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
          V576.1z M1430.2,581h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V581z M1430.2,586.8c0.3,0,0.6,0,1.1,0
          c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V586.8z"/>
      <path class="st13" d="M1450.6,582.3c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1448.5,575.7,1450.6,578.7,1450.6,582.3z M1441.3,582.4c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1442.5,578,1441.3,579.9,1441.3,582.4z"/>
      <path class="st13" d="M1454.9,578.4h-3.5v-2.4h9.9v2.4h-3.5v10.4h-2.9V578.4z"/>
      <path class="st13" d="M1464.4,585.5l-0.9,3.3h-3l3.9-12.9h3.8l4,12.9h-3.1l-1-3.3H1464.4z M1467.7,583.3l-0.8-2.7
          c-0.2-0.8-0.5-1.7-0.7-2.5h0c-0.2,0.8-0.4,1.7-0.6,2.5l-0.8,2.7H1467.7z"/>

      <path class="st13" d="M1177.5,639.2v2.4h-5.7v2.2c0.4-0.1,1.1-0.1,1.6-0.1c1.3,0,2.7,0.3,3.6,1.1c0.9,0.7,1.5,1.7,1.5,3.1
          c0,1.3-0.5,2.2-1.2,2.9c-1.2,1.1-3.3,1.5-5.2,1.5c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1177.5z M1171.8,649.9c0.3,0.1,0.7,0.1,1.1,0.1
          c0.7,0,1.4-0.2,1.9-0.7c0.4-0.3,0.6-0.9,0.6-1.5c0-0.7-0.3-1.1-0.7-1.5c-0.5-0.4-1.2-0.6-1.9-0.6c-0.3,0-0.7,0-1,0.1V649.9z"/>
      <path class="st13" d="M1192.2,645.5c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1190,639,1192.2,641.9,1192.2,645.5z M1182.8,645.7c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1184,641.3,1182.8,643.1,1182.8,645.7z"/>
      <path class="st13" d="M1203.6,639.2v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
          c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1203.6z"/>
      <path class="st13" d="M1209,639.2v4.5c0.4,0,1-0.1,1.6-0.1c2.8,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8c-1.2,1.3-3.4,1.6-5.4,1.6
          c-1.4,0-2.5-0.1-3.2-0.2v-12.8H1209z M1209,649.9c0.3,0.1,0.7,0.1,1.1,0.1c1.4,0,2.6-0.7,2.6-2.1c0-1.5-1.1-2.1-2.5-2.1
          c-0.4,0-0.8,0-1.1,0.1V649.9z"/>
      <path class="st13" d="M1221.5,639.3c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
          c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
          V639.3z M1224.4,644.3h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V644.3z M1224.4,650c0.3,0,0.6,0,1.1,0
          c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V650z"/>
      <path class="st13" d="M1239.9,639.2v5h4.8v-5h2.9v12.9h-2.9v-5.4h-4.8v5.4h-2.9v-12.9H1239.9z"/>
      <path class="st13" d="M1252.8,639.2v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7v-3.8
          c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1252.8z"/>
      <path class="st13" d="M1265.6,639.2l3.2,5.4h0.3v-5.4h2.8v5.4h0.3l3.2-5.4h3.4l-4.4,5.8c1.7,0.2,2.6,1.5,3.1,3.1
          c0.4,1.3,0.8,2.7,1.3,4h-2.9c-0.4-0.9-0.7-1.9-0.9-2.9c-0.4-1.4-1-2.5-2.6-2.5h-0.5v5.4h-2.8v-5.4h-0.5c-1.6,0-2.2,1.2-2.6,2.5
          c-0.3,0.9-0.5,2-0.9,2.9h-2.9c0.6-1.3,0.9-2.7,1.4-3.9c0.5-1.7,1.4-2.9,3.1-3.2l-4.4-5.8H1265.6z"/>
      <path class="st13" d="M1283.1,639.2v5h4.8v-5h2.9v12.9h-2.9v-5.4h-4.8v5.4h-2.9v-12.9H1283.1z"/>
      <path class="st13" d="M1301,646.6h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V646.6z"/>
      <path class="st13" d="M1314.4,647.1c-0.1-1.5-0.1-3.4-0.1-5.3h-0.1c-0.4,1.6-0.9,3.5-1.4,5l-1.6,5h-2.3l-1.4-5
          c-0.4-1.5-0.9-3.3-1.2-5h0c-0.1,1.7-0.1,3.7-0.2,5.3l-0.2,4.9h-2.7l0.8-12.9h3.9l1.3,4.3c0.4,1.5,0.8,3.1,1.1,4.6h0.1
          c0.4-1.5,0.8-3.2,1.2-4.6l1.4-4.3h3.8l0.7,12.9h-2.9L1314.4,647.1z"/>
      <path class="st13" d="M1335.4,645.5c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1333.2,639,1335.4,641.9,1335.4,645.5z M1326.1,645.7c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1327.2,641.3,1326.1,643.1,1326.1,645.7z"/>
      <path class="st13" d="M1339.6,641.6h-3.5v-2.4h9.9v2.4h-3.5v10.4h-2.9V641.6z"/>
      <path class="st13" d="M1356.7,639.2v10.6l1.3,0.1l-0.1,5.5h-2.2l-0.2-3.3h-7.5l-0.2,3.3h-2.2l-0.1-5.5l1.1-0.1
          c0.5-0.9,0.9-1.9,1.2-2.9c0.4-1.3,0.5-2.7,0.5-4.3v-3.4H1356.7z M1351,643.3c0,1.5-0.2,2.9-0.6,4.2c-0.2,0.8-0.6,1.6-0.9,2.2
          h4.3v-8.2h-2.8V643.3z"/>
      <path class="st13" d="M1367.3,646.6h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V646.6z"/>
      <path class="st13" d="M1379.9,639.2v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
          c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1379.9z"/>
      <path class="st13" d="M1390.1,646.6h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V646.6z"/>
      <path class="st13" d="M1398.8,639.2l3.2,5.4h0.3v-5.4h2.8v5.4h0.3l3.2-5.4h3.4l-4.4,5.8c1.7,0.2,2.6,1.5,3.1,3.1
          c0.4,1.3,0.8,2.7,1.3,4h-2.9c-0.4-0.9-0.7-1.9-0.9-2.9c-0.4-1.4-1-2.5-2.6-2.5h-0.5v5.4h-2.8v-5.4h-0.5c-1.6,0-2.2,1.2-2.6,2.5
          c-0.3,0.9-0.5,2-0.9,2.9h-2.9c0.6-1.3,0.9-2.7,1.4-3.9c0.5-1.7,1.4-2.9,3.1-3.2l-4.4-5.8H1398.8z"/>
      <path class="st13" d="M1416,639.2v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7v-3.8
          c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1416z"/>
      <path class="st13" d="M1426.5,639.3c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
          c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
          V639.3z M1429.4,644.3h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V644.3z M1429.4,650c0.3,0,0.6,0,1.1,0
          c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V650z"/>
      <path class="st13" d="M1449.8,645.5c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1447.6,639,1449.8,641.9,1449.8,645.5z M1440.4,645.7c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1441.6,641.3,1440.4,643.1,1440.4,645.7z"/>
      <path class="st13" d="M1454,641.6h-3.5v-2.4h9.9v2.4h-3.5v10.4h-2.9V641.6z"/>
      <path class="st13" d="M1463.5,648.8l-0.9,3.3h-3l3.9-12.9h3.8l4,12.9h-3.1l-1-3.3H1463.5z M1466.8,646.6l-0.8-2.7
          c-0.2-0.8-0.5-1.7-0.7-2.5h0c-0.2,0.8-0.4,1.7-0.6,2.5l-0.8,2.7H1466.8z"/>

      <path class="st13" d="M1177.5,714.6v2.4h-5.7v2.2c0.4-0.1,1.1-0.1,1.6-0.1c1.3,0,2.7,0.3,3.6,1.1c0.9,0.7,1.5,1.7,1.5,3.1
          c0,1.3-0.5,2.2-1.2,2.9c-1.2,1.1-3.3,1.5-5.2,1.5c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1177.5z M1171.8,725.3c0.3,0.1,0.7,0.1,1.1,0.1
          c0.7,0,1.4-0.2,1.9-0.7c0.4-0.3,0.6-0.9,0.6-1.5c0-0.7-0.3-1.1-0.7-1.5c-0.5-0.4-1.2-0.6-1.9-0.6c-0.3,0-0.7,0-1,0.1V725.3z"/>
      <path class="st13" d="M1192.2,720.9c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1190,714.3,1192.2,717.3,1192.2,720.9z M1182.8,721c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1184,716.7,1182.8,718.5,1182.8,721z"/>
      <path class="st13" d="M1203.6,714.6v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
          c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1203.6z"/>
      <path class="st13" d="M1209,714.6v4.5c0.4,0,1-0.1,1.6-0.1c2.8,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8c-1.2,1.3-3.4,1.6-5.4,1.6
          c-1.4,0-2.5-0.1-3.2-0.2v-12.8H1209z M1209,725.3c0.3,0.1,0.7,0.1,1.1,0.1c1.4,0,2.6-0.7,2.6-2.1c0-1.5-1.1-2.1-2.5-2.1
          c-0.4,0-0.8,0-1.1,0.1V725.3z"/>
      <path class="st13" d="M1221.5,714.7c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
          c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
          V714.7z M1224.4,719.7h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V719.7z M1224.4,725.4c0.3,0,0.6,0,1.1,0
          c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V725.4z"/>
      <path class="st13" d="M1247.4,714.6v12.9h-2.9v-10.5h-4.6v10.5h-2.9v-12.9H1247.4z"/>
      <path class="st13" d="M1261.7,720.9c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1259.5,714.3,1261.7,717.3,1261.7,720.9z M1252.4,721c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1253.5,716.7,1252.4,718.5,1252.4,721z"/>
      <path class="st13" d="M1262.4,727.5c0.3-0.4,0.5-0.9,0.7-1.5c0.5-1.5,0.8-3,1.8-3.9c0.3-0.3,0.7-0.5,1.1-0.7v-0.1
          c-1.4-0.3-2.7-1.3-2.7-3.1c0-1.2,0.5-2.1,1.3-2.7c1-0.8,2.6-1,4.2-1c1.4,0,2.8,0.1,3.7,0.3v12.7h-2.9v-5.1h-0.7
          c-0.6,0-1,0.2-1.4,0.5c-0.8,0.8-1.1,2.4-1.5,3.5c-0.1,0.4-0.2,0.7-0.5,1.1H1262.4z M1269.6,716.7c-0.2,0-0.6-0.1-1.2-0.1
          c-1.3,0-2.3,0.5-2.3,1.9c0,1.2,1.1,1.8,2.4,1.8c0.4,0,0.8,0,1.1-0.1V716.7z"/>
      <path class="st13" d="M1284.5,727.1c-0.5,0.3-1.7,0.6-3.3,0.6c-4.5,0-6.8-2.8-6.8-6.4c0-4.4,3.1-6.9,7-6.9
          c1.5,0,2.7,0.3,3.2,0.6l-0.6,2.3c-0.6-0.2-1.4-0.5-2.4-0.5c-2.3,0-4.1,1.4-4.1,4.3c0,2.6,1.5,4.2,4.1,4.2c0.9,0,1.9-0.2,2.4-0.4
          L1284.5,727.1z"/>
      <path class="st13" d="M1289.3,714.6v5h4.8v-5h2.9v12.9h-2.9v-5.4h-4.8v5.4h-2.9v-12.9H1289.3z"/>
      <path class="st13" d="M1302.2,714.6v3.8c0,2.1,0,4.1-0.2,5.8h0.1c0.7-1.5,1.5-3.2,2.3-4.6l2.8-5.1h3v12.9h-2.7v-3.8
          c0-2,0-3.8,0.2-5.4h-0.1c-0.6,1.5-1.4,3.2-2.2,4.6l-2.6,4.6h-3.3v-12.9H1302.2z"/>
      <path class="st13" d="M1315.7,714.6v10.5h4.5v-10.5h2.9v10.6l1.3,0.1l-0.1,5.5h-2.2l-0.2-3.3h-9.1v-12.9H1315.7z"/>
      <path class="st13" d="M1333.7,722h-4.7v3.1h5.3v2.4h-8.2v-12.9h8v2.4h-5v2.7h4.7V722z"/>
      <path class="st13" d="M1177.5,838v2.4h-5.7v2.2c0.4-0.1,1.1-0.1,1.6-0.1c1.3,0,2.7,0.3,3.6,1.1c0.9,0.7,1.5,1.7,1.5,3.1
          c0,1.3-0.5,2.2-1.2,2.9c-1.2,1.1-3.3,1.5-5.2,1.5c-1.4,0-2.5-0.1-3.1-0.2V838H1177.5z M1171.8,848.8c0.3,0.1,0.7,0.1,1.1,0.1
          c0.7,0,1.4-0.2,1.9-0.7c0.4-0.3,0.6-0.9,0.6-1.5c0-0.7-0.3-1.1-0.7-1.5c-0.5-0.4-1.2-0.6-1.9-0.6c-0.3,0-0.7,0-1,0.1V848.8z"/>
      <path class="st13" d="M1192.2,844.4c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1190,837.8,1192.2,840.8,1192.2,844.4z M1182.8,844.5c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1184,840.1,1182.8,842,1182.8,844.5z"/>
      <path class="st13" d="M1203.6,838v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
          c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4V838H1203.6z"/>
      <path class="st13" d="M1209,838v4.5c0.4,0,1-0.1,1.6-0.1c2.8,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8c-1.2,1.3-3.4,1.6-5.4,1.6
          c-1.4,0-2.5-0.1-3.2-0.2V838H1209z M1209,848.8c0.3,0.1,0.7,0.1,1.1,0.1c1.4,0,2.6-0.7,2.6-2.1c0-1.5-1.1-2.1-2.5-2.1
          c-0.4,0-0.8,0-1.1,0.1V848.8z"/>
      <path class="st13" d="M1221.5,838.2c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
          c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
          V838.2z M1224.4,843.2h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V843.2z M1224.4,848.9c0.3,0,0.6,0,1.1,0
          c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V848.9z"/>
      <path class="st13" d="M1239.8,838v5.4h0.2l3.6-5.4h3.5l-4.5,5.7c1.7,0.4,2.4,1.6,2.9,3.1c0.5,1.4,0.9,2.8,1.5,4.1h-3.1
          c-0.4-0.9-0.7-1.9-1-2.8c-0.4-1.5-1-2.6-2.6-2.6h-0.6v5.4h-2.9V838H1239.8z"/>
      <path class="st13" d="M1260,844.4c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1257.8,837.8,1260,840.8,1260,844.4z M1250.7,844.5c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1251.8,840.1,1250.7,842,1250.7,844.5z"/>
      <path class="st13" d="M1271.4,850.6c-0.5,0.3-1.7,0.6-3.3,0.6c-4.5,0-6.8-2.8-6.8-6.4c0-4.4,3.1-6.9,7-6.9
          c1.5,0,2.7,0.3,3.2,0.6l-0.6,2.3c-0.6-0.2-1.4-0.5-2.4-0.5c-2.3,0-4.1,1.4-4.1,4.3c0,2.6,1.5,4.2,4.1,4.2c0.9,0,1.9-0.2,2.4-0.4
          L1271.4,850.6z"/>
      <path class="st13" d="M1276.1,840.5h-3.5V838h9.9v2.4h-3.5v10.4h-2.9V840.5z"/>
      <path class="st13" d="M1282.8,850.9c0.3-0.4,0.5-0.9,0.7-1.5c0.5-1.5,0.8-3,1.8-3.9c0.3-0.3,0.7-0.5,1.1-0.7v-0.1
          c-1.4-0.3-2.7-1.3-2.7-3.1c0-1.2,0.5-2.1,1.3-2.7c1-0.8,2.6-1,4.2-1c1.4,0,2.8,0.1,3.7,0.3v12.7h-2.9v-5.1h-0.7
          c-0.6,0-1,0.2-1.4,0.5c-0.8,0.8-1.1,2.4-1.5,3.5c-0.1,0.4-0.2,0.7-0.5,1.1H1282.8z M1290,840.2c-0.2,0-0.6-0.1-1.2-0.1
          c-1.3,0-2.3,0.5-2.3,1.9c0,1.2,1.1,1.8,2.4,1.8c0.4,0,0.8,0,1.1-0.1V840.2z"/>
      <path class="st13" d="M1302.2,850.9l-1.2-2.3c-0.5-0.9-0.8-1.6-1.1-2.3h0c-0.3,0.7-0.6,1.4-1,2.3l-1.1,2.3h-3.3l3.7-6.5
          l-3.6-6.4h3.3l1.1,2.4c0.4,0.8,0.7,1.4,1,2.1h0c0.3-0.8,0.6-1.4,0.9-2.1l1.1-2.4h3.3l-3.6,6.3l3.8,6.6H1302.2z"/>
      <path class="st13" d="M1177.5,792.5v2.4h-5.7v2.2c0.4-0.1,1.1-0.1,1.6-0.1c1.3,0,2.7,0.3,3.6,1.1c0.9,0.7,1.5,1.7,1.5,3.1
          c0,1.3-0.5,2.2-1.2,2.9c-1.2,1.1-3.3,1.5-5.2,1.5c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1177.5z M1171.8,803.3c0.3,0.1,0.7,0.1,1.1,0.1
          c0.7,0,1.4-0.2,1.9-0.7c0.4-0.3,0.6-0.9,0.6-1.5c0-0.7-0.3-1.1-0.7-1.5c-0.5-0.4-1.2-0.6-1.9-0.6c-0.3,0-0.7,0-1,0.1V803.3z"/>
      <path class="st13" d="M1192.2,798.8c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1190,792.3,1192.2,795.2,1192.2,798.8z M1182.8,799c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1184,794.6,1182.8,796.5,1182.8,799z"/>
      <path class="st13" d="M1203.6,792.5v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
          c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1203.6z"/>
      <path class="st13" d="M1209,792.5v4.5c0.4,0,1-0.1,1.6-0.1c2.8,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8c-1.2,1.3-3.4,1.6-5.4,1.6
          c-1.4,0-2.5-0.1-3.2-0.2v-12.8H1209z M1209,803.3c0.3,0.1,0.7,0.1,1.1,0.1c1.4,0,2.6-0.7,2.6-2.1c0-1.5-1.1-2.1-2.5-2.1
          c-0.4,0-0.8,0-1.1,0.1V803.3z"/>
      <path class="st13" d="M1221.5,792.7c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
          c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
          V792.7z M1224.4,797.6h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V797.6z M1224.4,803.3c0.3,0,0.6,0,1.1,0
          c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V803.3z"/>
      <path class="st13" d="M1247.9,800.5c-0.1-1.5-0.1-3.4-0.1-5.3h-0.1c-0.4,1.6-0.9,3.5-1.4,5l-1.6,5h-2.3l-1.4-5
          c-0.4-1.5-0.9-3.3-1.2-5h0c-0.1,1.7-0.1,3.7-0.2,5.3l-0.2,4.9h-2.7l0.8-12.9h3.9l1.3,4.3c0.4,1.5,0.8,3.1,1.1,4.6h0.1
          c0.4-1.5,0.8-3.2,1.2-4.6l1.4-4.3h3.8l0.7,12.9h-2.9L1247.9,800.5z"/>
      <path class="st13" d="M1256,792.5v4.5c0.4-0.1,1-0.1,1.4-0.1c2.7,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8
          c-1.2,1.3-3.4,1.6-5.3,1.6c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1256z M1256,803.3c0.2,0.1,0.6,0.1,1,0.1c1.3,0,2.5-0.7,2.5-2.2
          c0-1.5-1.4-2.1-2.5-2.1c-0.4,0-0.7,0-1,0.1V803.3z M1266.7,792.5v12.9h-2.9v-12.9H1266.7z"/>
      <path class="st13" d="M1272.3,792.5V803h3.2v-10.5h2.9V803h3.2v-10.5h2.9v12.9h-14.9v-12.9H1272.3z"/>
      <path class="st13" d="M1289.8,792.5V803h4.5v-10.5h2.9v10.6l1.3,0.1l-0.1,5.5h-2.2l-0.2-3.3h-9.1v-12.9H1289.8z"/>
      <path class="st13" d="M1303.3,802.1l-0.9,3.3h-3l3.9-12.9h3.8l4,12.9h-3.1l-1-3.3H1303.3z M1306.6,799.9l-0.8-2.7
          c-0.2-0.8-0.5-1.7-0.7-2.5h0c-0.2,0.8-0.4,1.7-0.6,2.5l-0.8,2.7H1306.6z"/>
      <path class="st13" d="M1319.7,805.4l-1.2-2.3c-0.5-0.9-0.8-1.6-1.1-2.3h0c-0.3,0.7-0.6,1.4-1,2.3l-1.1,2.3h-3.3l3.7-6.5
          l-3.6-6.4h3.3l1.1,2.4c0.4,0.8,0.7,1.4,1,2.1h0c0.3-0.8,0.6-1.4,0.9-2.1l1.1-2.4h3.3l-3.6,6.3l3.8,6.6H1319.7z"/>

      <path class="st13" d="M1177.5,906.7v2.4h-5.7v2.2c0.4-0.1,1.1-0.1,1.6-0.1c1.3,0,2.7,0.3,3.6,1.1c0.9,0.7,1.5,1.7,1.5,3.1
          c0,1.3-0.5,2.2-1.2,2.9c-1.2,1.1-3.3,1.5-5.2,1.5c-1.4,0-2.5-0.1-3.1-0.2v-12.8H1177.5z M1171.8,917.5c0.3,0.1,0.7,0.1,1.1,0.1
          c0.7,0,1.4-0.2,1.9-0.7c0.4-0.3,0.6-0.9,0.6-1.5c0-0.7-0.3-1.1-0.7-1.5c-0.5-0.4-1.2-0.6-1.9-0.6c-0.3,0-0.7,0-1,0.1V917.5z"/>
      <path class="st13" d="M1192.2,913.1c0,4.2-2.6,6.8-6.3,6.8c-3.8,0-6.1-2.9-6.1-6.6c0-3.9,2.5-6.8,6.3-6.8
          C1190,906.5,1192.2,909.5,1192.2,913.1z M1182.8,913.2c0,2.5,1.2,4.3,3.1,4.3c2,0,3.1-1.9,3.1-4.4c0-2.3-1.1-4.3-3.1-4.3
          C1184,908.9,1182.8,910.7,1182.8,913.2z"/>
      <path class="st13" d="M1203.6,906.7v12.9h-2.9v-10.5h-2.9v3.1c0,3.3-0.4,5.9-2.4,7c-0.7,0.4-1.5,0.6-2.6,0.6l-0.4-2.4
          c0.6-0.1,1.1-0.3,1.5-0.7c0.8-0.8,1-2.4,1-4.4v-5.6H1203.6z"/>
      <path class="st13" d="M1209,906.7v4.5c0.4,0,1-0.1,1.6-0.1c2.8,0,5.1,1.3,5.1,4.2c0,1.2-0.4,2.1-1,2.8c-1.2,1.3-3.4,1.6-5.4,1.6
          c-1.4,0-2.5-0.1-3.2-0.2v-12.8H1209z M1209,917.5c0.3,0.1,0.7,0.1,1.1,0.1c1.4,0,2.6-0.7,2.6-2.1c0-1.5-1.1-2.1-2.5-2.1
          c-0.4,0-0.8,0-1.1,0.1V917.5z"/>
      <path class="st13" d="M1221.5,906.9c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
          c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
          V906.9z M1224.4,911.9h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V911.9z M1224.4,917.6c0.3,0,0.6,0,1.1,0
          c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V917.6z"/>
      <path class="st13" d="M1246.4,919.3c-0.5,0.3-1.7,0.6-3.3,0.6c-4.5,0-6.8-2.8-6.8-6.4c0-4.4,3.1-6.9,7-6.9
          c1.5,0,2.7,0.3,3.2,0.6l-0.6,2.3c-0.6-0.2-1.4-0.5-2.4-0.5c-2.3,0-4.1,1.4-4.1,4.3c0,2.6,1.5,4.2,4.1,4.2c0.9,0,1.9-0.2,2.4-0.4
          L1246.4,919.3z"/>
      <path class="st13" d="M1250.7,906.7l1.9,4.5c0.3,0.9,0.6,1.7,0.9,2.5h0.1c0.2-0.6,0.4-1.4,0.7-2.5l1.4-4.5h3.1l-2.4,6.3
          c-0.8,2-1.6,4-2.5,5.1c-0.8,1.1-1.9,1.7-3.4,1.7c-0.7,0-1.1-0.1-1.4-0.2l0.2-2.3c0.2,0.1,0.5,0.1,1,0.1c0.9,0,1.5-0.6,1.7-1.2
          c0.1-0.2,0.1-0.4,0-0.6l-4.3-8.9H1250.7z"/>
      <path class="st13" d="M1269,919.3c-0.5,0.3-1.7,0.6-3.3,0.6c-4.5,0-6.8-2.8-6.8-6.4c0-4.4,3.1-6.9,7-6.9c1.5,0,2.7,0.3,3.2,0.6
          l-0.6,2.3c-0.6-0.2-1.4-0.5-2.4-0.5c-2.3,0-4.1,1.4-4.1,4.3c0,2.6,1.5,4.2,4.1,4.2c0.9,0,1.9-0.2,2.4-0.4L1269,919.3z"/>
      <path class="st13" d="M1273.8,909.2h-3.5v-2.4h9.9v2.4h-3.5v10.4h-2.9V909.2z"/>
      <path class="st13" d="M1283.3,916.3l-0.9,3.3h-3l3.9-12.9h3.8l4,12.9h-3.1l-1-3.3H1283.3z M1286.6,914.2l-0.8-2.7
          c-0.2-0.8-0.5-1.7-0.7-2.5h0c-0.2,0.8-0.4,1.7-0.6,2.5l-0.8,2.7H1286.6z"/>
      <path class="st13" d="M1292.9,906.9c0.8-0.2,2.3-0.3,3.8-0.3c1.8,0,2.9,0.2,3.8,0.7c0.9,0.5,1.5,1.4,1.5,2.5
          c0,1.1-0.7,2.2-2.1,2.8v0c1.5,0.4,2.6,1.5,2.6,3.2c0,1.2-0.5,2.1-1.3,2.7c-0.9,0.7-2.5,1.2-5.1,1.2c-1.4,0-2.5-0.1-3.2-0.2
          V906.9z M1295.8,911.9h1c1.5,0,2.4-0.6,2.4-1.6c0-1-0.7-1.5-2.1-1.5c-0.6,0-1,0-1.2,0.1V911.9z M1295.8,917.6c0.3,0,0.6,0,1.1,0
          c1.3,0,2.5-0.5,2.5-1.9c0-1.3-1.2-1.8-2.6-1.8h-1V917.6z"/>
      <path class="st13" d="M1307.4,916.3l-0.9,3.3h-3l3.9-12.9h3.8l4,12.9h-3.1l-1-3.3H1307.4z M1310.7,914.2l-0.8-2.7
          c-0.2-0.8-0.5-1.7-0.7-2.5h0c-0.2,0.8-0.4,1.7-0.6,2.5l-0.8,2.7H1310.7z"/>
      <path class="st13" d="M1323.7,919.6l-1.2-2.3c-0.5-0.9-0.8-1.6-1.1-2.3h0c-0.3,0.7-0.6,1.4-1,2.3l-1.1,2.3h-3.3l3.7-6.5
          l-3.6-6.4h3.3l1.1,2.4c0.4,0.8,0.7,1.4,1,2.1h0c0.3-0.8,0.6-1.4,0.9-2.1l1.1-2.4h3.3l-3.6,6.3l3.8,6.6H1323.7z"/>
<!--        <path class="st14" d="M892.6,68.1l-0.7,2.6h-2.4l3.1-10h3l3.1,10h-2.4l-0.8-2.6H892.6z M895.1,66.4l-0.6-2.1-->
<!--            c-0.2-0.6-0.4-1.3-0.5-1.9h0c-0.1,0.6-0.3,1.4-0.5,1.9l-0.6,2.1H895.1z"/>-->
<!--        <path class="st14" d="M902.3,60.6v3.9h3.7v-3.9h2.3v10H906v-4.2h-3.7v4.2H900v-10H902.3z"/>-->
<!--        <path class="st14" d="M912.5,60.6v4.2h0.2l2.8-4.2h2.8l-3.5,4.4c1.3,0.3,1.9,1.2,2.3,2.4c0.4,1.1,0.7,2.2,1.2,3.2h-2.4-->
<!--            c-0.3-0.7-0.5-1.5-0.8-2.2c-0.3-1.1-0.8-2-2-2h-0.4v4.2h-2.2v-10H912.5z"/>-->
<!--        <path class="st14" d="M925.2,66.4h-3.7v2.4h4.1v1.9h-6.4v-10h6.2v1.9h-3.9v2.1h3.7V66.4z"/>-->
<!--        <path class="st14" d="M929.1,62.5h-2.7v-1.9h7.7v1.9h-2.7v8.1h-2.3V62.5z"/>-->
<!--        <path class="st14" d="M937.6,60.6v3.5c0.3,0,0.7-0.1,1.1-0.1c2.1,0,4,1,4,3.3c0,0.9-0.3,1.6-0.8,2.2c-1,1-2.6,1.3-4.1,1.3-->
<!--            c-1.1,0-1.9-0.1-2.4-0.1v-10H937.6z M937.6,69c0.2,0,0.5,0.1,0.8,0.1c1,0,1.9-0.6,1.9-1.7c0-1.1-1.1-1.6-1.9-1.6-->
<!--            c-0.3,0-0.6,0-0.8,0.1V69z M945.9,60.6v10h-2.3v-10H945.9z"/>-->
<!--        <path class="st14" d="M954,70.7V61h2.5l1.6,3.6c0.4,1,0.9,2.2,1.3,3.2l0,0c-0.1-1.2-0.2-2.6-0.2-4.1V61h1.9v9.7h-2.3l-1.7-3.7-->
<!--            c-0.4-1-0.9-2.2-1.3-3.3h0c0.1,1.2,0.1,2.6,0.1,4.3v2.8H954z M967.1,63.8c0,1.9-1.2,2.7-2.6,2.7c-1.5,0-2.6-0.9-2.6-2.6-->
<!--            c0-1.6,1.1-2.7,2.6-2.7C966.1,61.2,967.1,62.3,967.1,63.8z M962.3,68.2V67h4.4v1.1H962.3z M963.6,63.9c0,0.8,0.4,1.5,0.9,1.5-->
<!--            c0.6,0,0.9-0.6,0.9-1.5c0-0.7-0.3-1.5-0.9-1.5S963.6,63.2,963.6,63.9z"/>-->
<!--        <path class="st14" d="M1091,60.6v4.2h0.2l2.8-4.2h2.8l-3.5,4.4c1.3,0.3,1.9,1.2,2.3,2.4c0.4,1.1,0.7,2.2,1.2,3.2h-2.4-->
<!--            c-0.3-0.7-0.5-1.5-0.8-2.2c-0.3-1.1-0.8-2-2-2h-0.4v4.2h-2.2v-10H1091z"/>-->
<!--        <path class="st14" d="M1106.7,65.5c0,3.3-2,5.3-4.9,5.3c-3,0-4.7-2.2-4.7-5.1c0-3,1.9-5.3,4.9-5.3-->
<!--            C1105,60.5,1106.7,62.8,1106.7,65.5z M1099.4,65.7c0,2,0.9,3.3,2.4,3.3c1.5,0,2.4-1.5,2.4-3.4c0-1.8-0.9-3.3-2.4-3.3-->
<!--            C1100.3,62.3,1099.4,63.7,1099.4,65.7z"/>-->
<!--        <path class="st14" d="M1115.8,60.6v8.2l1,0.1l-0.1,4.3h-1.7l-0.1-2.5h-5.9l-0.1,2.5h-1.7l-0.1-4.3l0.8-0.1-->
<!--            c0.4-0.7,0.7-1.4,1-2.2c0.3-1,0.4-2.1,0.4-3.3v-2.7H1115.8z M1111.3,63.8c0,1.2-0.1,2.3-0.4,3.3c-0.2,0.6-0.4,1.2-0.7,1.7h3.3-->
<!--            v-6.3h-2.2V63.8z"/>-->
        <path class="st14" d="M1170.9,60.6v3.9h3.7v-3.9h2.3v10h-2.3v-4.2h-3.7v4.2h-2.3v-10H1170.9z"/>
        <path class="st14" d="M1181.2,68.1l-0.7,2.6h-2.4l3.1-10h3l3.1,10h-2.4l-0.8-2.6H1181.2z M1183.8,66.4l-0.6-2.1
            c-0.2-0.6-0.4-1.3-0.5-1.9h0c-0.1,0.6-0.3,1.4-0.5,1.9l-0.6,2.1H1183.8z"/>
        <path class="st14" d="M1190.7,60.6v3c0,1.6,0,3.2-0.1,4.5h0.1c0.5-1.2,1.2-2.5,1.8-3.6l2.2-3.9h2.3v10h-2.1v-3
            c0-1.5,0-2.9,0.2-4.2h0c-0.5,1.2-1.1,2.5-1.7,3.6l-2,3.6h-2.6v-10H1190.7z"/>
        <path class="st14" d="M1207.4,66.8c0-1.2-0.1-2.7-0.1-4.1h0c-0.3,1.3-0.7,2.7-1.1,3.9l-1.2,3.9h-1.8l-1.1-3.9
            c-0.3-1.2-0.7-2.6-0.9-3.9h0c-0.1,1.4-0.1,2.9-0.2,4.2l-0.2,3.8h-2.1l0.6-10h3l1,3.3c0.3,1.2,0.6,2.4,0.8,3.6h0
            c0.3-1.2,0.6-2.5,1-3.6l1.1-3.3h3l0.6,10h-2.2L1207.4,66.8z"/>
        <path class="st14" d="M1217.5,66.4h-3.7v2.4h4.1v1.9h-6.4v-10h6.2v1.9h-3.9v2.1h3.7V66.4z"/>
        <path class="st14" d="M1221.7,60.6v3.9h3.7v-3.9h2.3v10h-2.3v-4.2h-3.7v4.2h-2.3v-10H1221.7z"/>
        <path class="st14" d="M1238.9,65.5c0,3.3-2,5.3-4.9,5.3c-3,0-4.7-2.2-4.7-5.1c0-3,1.9-5.3,4.9-5.3
            C1237.2,60.5,1238.9,62.8,1238.9,65.5z M1231.6,65.7c0,2,0.9,3.3,2.4,3.3c1.5,0,2.4-1.5,2.4-3.4c0-1.8-0.9-3.3-2.4-3.3
            C1232.5,62.3,1231.6,63.7,1231.6,65.7z"/>
        <path class="st14" d="M1240.4,60.8c0.6-0.1,1.8-0.2,2.9-0.2c1.4,0,2.2,0.1,3,0.6c0.7,0.4,1.2,1.1,1.2,2c0,0.9-0.5,1.7-1.7,2.1v0
            c1.1,0.3,2,1.2,2,2.5c0,0.9-0.4,1.6-1,2.1c-0.7,0.6-1.9,0.9-3.9,0.9c-1.1,0-1.9-0.1-2.5-0.1V60.8z M1242.6,64.6h0.7
            c1.2,0,1.8-0.5,1.8-1.2c0-0.8-0.6-1.2-1.6-1.2c-0.5,0-0.8,0-1,0.1V64.6z M1242.6,69.1c0.2,0,0.5,0,0.9,0c1,0,1.9-0.4,1.9-1.4
            c0-1-0.9-1.4-2-1.4h-0.8V69.1z"/>
        <path class="st14" d="M1251.7,68.1l-0.7,2.6h-2.4l3.1-10h3l3.1,10h-2.4l-0.8-2.6H1251.7z M1254.2,66.4l-0.6-2.1
            c-0.2-0.6-0.4-1.3-0.5-1.9h0c-0.1,0.6-0.3,1.4-0.5,1.9l-0.6,2.1H1254.2z"/>
        <path class="st14" d="M1261.4,60.6v3.9h3.7v-3.9h2.3v10h-2.3v-4.2h-3.7v4.2h-2.3v-10H1261.4z"/>
        <path class="st14" d="M1271.4,60.6v3c0,1.6,0,3.2-0.1,4.5h0.1c0.5-1.2,1.2-2.5,1.8-3.6l2.2-3.9h2.3v10h-2.1v-3
            c0-1.5,0-2.9,0.2-4.2h0c-0.5,1.2-1.1,2.5-1.7,3.6l-2,3.6h-2.6v-10H1271.4z"/>
        <path class="st14" d="M1285.6,66.4h-3.7v2.4h4.1v1.9h-6.4v-10h6.2v1.9h-3.9v2.1h3.7V66.4z"/>
        <path class="st14" d="M1290.2,61.3c0.7-0.4,1.7-0.8,3.1-0.8c2.2,0,3.5,1.1,3.5,2.6c0,1.2-0.8,2.1-2,2.4v0c1.2,0.2,2.4,1,2.4,2.4
            c0,2.2-2.3,3-4.1,3c-1,0-2.2-0.2-3-0.7l0.5-1.7c0.4,0.2,1.5,0.6,2.4,0.6c1.1,0,1.8-0.5,1.8-1.3c0-1.1-1.2-1.3-2.1-1.3h-1v-1.7
            h0.9c1,0,1.8-0.5,1.8-1.3c0-0.7-0.6-1.1-1.5-1.1c-0.8,0-1.6,0.3-2.2,0.6L1290.2,61.3z"/>
        <path class="st14" d="M1307.8,65.5c0,3.3-2,5.3-4.9,5.3c-3,0-4.7-2.2-4.7-5.1c0-3,1.9-5.3,4.9-5.3
            C1306.1,60.5,1307.8,62.8,1307.8,65.5z M1300.5,65.7c0,2,0.9,3.3,2.4,3.3c1.5,0,2.4-1.5,2.4-3.4c0-1.8-0.9-3.3-2.4-3.3
            C1301.4,62.3,1300.5,63.7,1300.5,65.7z"/>
        <path class="st14" d="M1311.5,60.6v3.9h3.7v-3.9h2.3v10h-2.3v-4.2h-3.7v4.2h-2.3v-10H1311.5z"/>
        <path class="st14" d="M1321.8,60.6v3.5c0.3,0,0.7-0.1,1.1-0.1c2.1,0,4,1,4,3.3c0,0.9-0.3,1.6-0.8,2.2c-1,1-2.6,1.3-4.1,1.3
            c-1.1,0-1.9-0.1-2.4-0.1v-10H1321.8z M1321.7,69c0.2,0,0.5,0.1,0.8,0.1c1,0,1.9-0.6,1.9-1.7c0-1.1-1.1-1.6-1.9-1.6
            c-0.3,0-0.6,0-0.8,0.1V69z M1330,60.6v10h-2.3v-10H1330z"/>
        <path class="st14" d="M1009,60.6v8.2h3.5v-8.2h2.3v8.2l1,0.1l-0.1,4.3h-1.7l-0.1-2.5h-7.1v-10H1009z"/>
        <path class="st14" d="M1017,60.8c0.6-0.1,1.8-0.2,2.9-0.2c1.4,0,2.2,0.1,3,0.6c0.7,0.4,1.2,1.1,1.2,2c0,0.9-0.5,1.7-1.7,2.1v0
            c1.1,0.3,2,1.2,2,2.5c0,0.9-0.4,1.6-1,2.1c-0.7,0.6-1.9,0.9-3.9,0.9c-1.1,0-1.9-0.1-2.5-0.1V60.8z M1019.3,64.6h0.7
            c1.2,0,1.8-0.5,1.8-1.2c0-0.8-0.6-1.2-1.6-1.2c-0.5,0-0.8,0-1,0.1V64.6z M1019.3,69.1c0.2,0,0.5,0,0.9,0c1,0,1.9-0.4,1.9-1.4
            c0-1-0.9-1.4-2-1.4h-0.8V69.1z"/>
        <path class="st14" d="M1032,66.4h-3.7v2.4h4.1v1.9h-6.4v-10h6.2v1.9h-3.9v2.1h3.7V66.4z"/>
        <path class="st14" d="M1035.9,62.5h-2.7v-1.9h7.7v1.9h-2.7v8.1h-2.3V62.5z"/>
<!--        <path class="st14" d="M927.9,306.9L927.9,306.9l-4,1.9l-0.8-3.6l5.5-2.6h4v20.7h-4.7V306.9z"/>-->
<!--        <path class="st14" d="M1107.9,117.1v2.7l-7.4,15.3h-4.4l7.4-14.5v-0.1h-8.2v-3.5H1107.9z"/>-->
<!--        <path class="st14" d="M1095.3,186.9c-0.5,0-1,0-1.6,0.1c-3.7,0.3-5.4,2.2-5.9,4.3h0.1c0.9-0.9,2.1-1.4,3.8-1.4-->
<!--          c3,0,5.6,2.1,5.6,5.9c0,3.6-2.7,6.5-6.6,6.5c-4.8,0-7.1-3.6-7.1-7.8c0-3.4,1.2-6.2,3.2-8c1.8-1.6,4.1-2.5,6.9-2.7-->
<!--          c0.8-0.1,1.3-0.1,1.7,0V186.9z M1093,195.9c0-1.7-0.9-3.1-2.7-3.1c-1.1,0-2.1,0.7-2.5,1.6c-0.1,0.2-0.2,0.6-0.2,1-->
<!--          c0.1,1.9,1,3.6,2.9,3.6C1092,199,1093,197.7,1093,195.9z"/>-->
<!--        <path class="st14" d="M1099.4,199.6c0-1.5,1-2.6,2.5-2.6c1.5,0,2.5,1,2.5,2.6c0,1.5-1,2.5-2.5,2.5-->
<!--          C1100.4,202.1,1099.4,201.1,1099.4,199.6z"/>-->
<!--        <path class="st14" d="M1107.1,197.7c0.7,0.4,2.5,1.1,4.2,1.1c2.2,0,3.3-1,3.3-2.4c0-1.8-1.8-2.6-3.6-2.6h-1.7v-3h1.6-->
<!--          c1.4,0,3.2-0.6,3.2-2.1c0-1.1-0.9-1.9-2.7-1.9c-1.5,0-3,0.6-3.8,1.1l-0.9-3c1.1-0.7,3.2-1.4,5.6-1.4c3.8,0,6,2,6,4.5-->
<!--          c0,1.9-1.1,3.4-3.3,4.2v0.1c2.2,0.4,3.9,2,3.9,4.4c0,3.2-2.8,5.5-7.4,5.5c-2.3,0-4.3-0.6-5.3-1.3L1107.1,197.7z"/>-->
<!--        <path class="st14" d="M1095.3,253.8c-0.5,0-1,0-1.6,0.1c-3.7,0.3-5.4,2.2-5.9,4.3h0.1c0.9-0.9,2.1-1.4,3.8-1.4-->
<!--          c3,0,5.6,2.1,5.6,5.9c0,3.6-2.7,6.5-6.6,6.5c-4.8,0-7.1-3.6-7.1-7.8c0-3.4,1.2-6.2,3.2-8c1.8-1.6,4.1-2.5,6.9-2.7-->
<!--          c0.8-0.1,1.3-0.1,1.7,0V253.8z M1093,262.8c0-1.7-0.9-3.1-2.7-3.1c-1.1,0-2.1,0.7-2.5,1.6c-0.1,0.2-0.2,0.6-0.2,1-->
<!--          c0.1,1.9,1,3.6,2.9,3.6C1092,266,1093,264.6,1093,262.8z"/>-->
<!--        <path class="st14" d="M1099.4,266.5c0-1.5,1-2.6,2.5-2.6c1.5,0,2.5,1,2.5,2.6c0,1.5-1,2.5-2.5,2.5-->
<!--          C1100.4,269.1,1099.4,268,1099.4,266.5z"/>-->
<!--        <path class="st14" d="M1118.3,254.2h-7l-0.4,2.8c0.4-0.1,0.7-0.1,1.2-0.1c1.7,0,3.5,0.4,4.7,1.3c1.4,0.9,2.2,2.4,2.2,4.5-->
<!--          c0,3.3-2.9,6.3-7.7,6.3c-2.2,0-4-0.5-5-1l0.7-3.2c0.8,0.4,2.4,0.9,4.1,0.9c1.7,0,3.6-0.8,3.6-2.7c0-1.9-1.5-3-5.1-3-->
<!--          c-1,0-1.7,0.1-2.4,0.2l1.2-9.4h9.9V254.2z"/>-->
<!--        <path class="st14" d="M1095.3,299.6c-0.5,0-1,0-1.6,0.1c-3.7,0.3-5.4,2.2-5.9,4.3h0.1c0.9-0.9,2.1-1.4,3.8-1.4-->
<!--          c3,0,5.6,2.1,5.6,5.9c0,3.6-2.7,6.5-6.6,6.5c-4.8,0-7.1-3.6-7.1-7.8c0-3.4,1.2-6.2,3.2-8c1.8-1.6,4.1-2.5,6.9-2.7-->
<!--          c0.8-0.1,1.3-0.1,1.7,0V299.6z M1093,308.6c0-1.7-0.9-3.1-2.7-3.1c-1.1,0-2.1,0.7-2.5,1.6c-0.1,0.2-0.2,0.6-0.2,1-->
<!--          c0.1,1.9,1,3.6,2.9,3.6C1092,311.7,1093,310.4,1093,308.6z"/>-->
<!--        <path class="st14" d="M1099.4,312.3c0-1.5,1-2.6,2.5-2.6c1.5,0,2.5,1,2.5,2.6c0,1.5-1,2.5-2.5,2.5-->
<!--          C1100.4,314.8,1099.4,313.8,1099.4,312.3z"/>-->
<!--        <path class="st14" d="M1117.8,299.6c-0.5,0-1,0-1.6,0.1c-3.7,0.3-5.4,2.2-5.9,4.3h0.1c0.9-0.9,2.1-1.4,3.8-1.4-->
<!--          c3,0,5.6,2.1,5.6,5.9c0,3.6-2.7,6.5-6.6,6.5c-4.8,0-7.1-3.6-7.1-7.8c0-3.4,1.2-6.2,3.2-8c1.8-1.6,4.1-2.5,6.9-2.7-->
<!--          c0.8-0.1,1.3-0.1,1.7,0V299.6z M1115.5,308.6c0-1.7-0.9-3.1-2.7-3.1c-1.1,0-2.1,0.7-2.5,1.6c-0.1,0.2-0.2,0.6-0.2,1-->
<!--          c0.1,1.9,1,3.6,2.9,3.6C1114.6,311.7,1115.5,310.4,1115.5,308.6z"/>-->
<!--        <path class="st14" d="M1095.3,365c-0.5,0-1,0-1.6,0.1c-3.7,0.3-5.4,2.2-5.9,4.3h0.1c0.9-0.9,2.1-1.4,3.8-1.4c3,0,5.6,2.1,5.6,5.9-->
<!--          c0,3.6-2.7,6.5-6.6,6.5c-4.8,0-7.1-3.6-7.1-7.8c0-3.4,1.2-6.2,3.2-8c1.8-1.6,4.1-2.5,6.9-2.7c0.8-0.1,1.3-0.1,1.7,0V365z-->
<!--           M1093,374c0-1.7-0.9-3.1-2.7-3.1c-1.1,0-2.1,0.7-2.5,1.6c-0.1,0.2-0.2,0.6-0.2,1c0.1,1.9,1,3.6,2.9,3.6-->
<!--          C1092,377.2,1093,375.8,1093,374z"/>-->
<!--        <path class="st14" d="M1099.4,377.7c0-1.5,1-2.6,2.5-2.6c1.5,0,2.5,1,2.5,2.6c0,1.5-1,2.5-2.5,2.5-->
<!--          C1100.4,380.3,1099.4,379.2,1099.4,377.7z"/>-->
<!--        <path class="st14" d="M1106.3,380v-2.5l2.3-2.1c3.9-3.5,5.8-5.5,5.9-7.6c0-1.5-0.9-2.6-3-2.6c-1.5,0-2.9,0.8-3.8,1.5l-1.2-3-->
<!--          c1.4-1,3.5-1.9,5.9-1.9c4.1,0,6.3,2.4,6.3,5.6c0,3-2.2,5.4-4.8,7.7l-1.7,1.4v0.1h6.8v3.5H1106.3z"/>-->
<!--        <path class="st14" d="M1095.3,431.4c-0.5,0-1,0-1.6,0.1c-3.7,0.3-5.4,2.2-5.9,4.3h0.1c0.9-0.9,2.1-1.4,3.8-1.4-->
<!--          c3,0,5.6,2.1,5.6,5.9c0,3.6-2.7,6.5-6.6,6.5c-4.8,0-7.1-3.6-7.1-7.8c0-3.4,1.2-6.2,3.2-8c1.8-1.6,4.1-2.5,6.9-2.7-->
<!--          c0.8-0.1,1.3-0.1,1.7,0V431.4z M1093,440.4c0-1.7-0.9-3.1-2.7-3.1c-1.1,0-2.1,0.7-2.5,1.6c-0.1,0.2-0.2,0.6-0.2,1-->
<!--          c0.1,1.9,1,3.6,2.9,3.6C1092,443.5,1093,442.2,1093,440.4z"/>-->
<!--        <path class="st14" d="M1099.4,444.1c0-1.5,1-2.6,2.5-2.6c1.5,0,2.5,1,2.5,2.6c0,1.5-1,2.5-2.5,2.5-->
<!--          C1100.4,446.6,1099.4,445.6,1099.4,444.1z"/>-->
<!--        <path class="st14" d="M1113.8,446.3v-4.3h-8v-2.7l6.8-10.9h5.1v10.5h2.2v3.2h-2.2v4.3H1113.8z M1113.8,438.9v-4-->
<!--          c0-1.1,0.1-2.2,0.1-3.3h-0.1c-0.6,1.2-1,2.2-1.7,3.3l-2.4,3.9v0.1H1113.8z"/>-->
<!--        <path class="st14" d="M1095.3,497.2c-0.5,0-1,0-1.6,0.1c-3.7,0.3-5.4,2.2-5.9,4.3h0.1c0.9-0.9,2.1-1.4,3.8-1.4-->
<!--          c3,0,5.6,2.1,5.6,5.9c0,3.6-2.7,6.5-6.6,6.5c-4.8,0-7.1-3.6-7.1-7.8c0-3.4,1.2-6.2,3.2-8c1.8-1.6,4.1-2.5,6.9-2.7-->
<!--          c0.8-0.1,1.3-0.1,1.7,0V497.2z M1093,506.2c0-1.7-0.9-3.1-2.7-3.1c-1.1,0-2.1,0.7-2.5,1.6c-0.1,0.2-0.2,0.6-0.2,1-->
<!--          c0.1,1.9,1,3.6,2.9,3.6C1092,509.4,1093,508,1093,506.2z"/>-->
<!--        <path class="st14" d="M1099.4,509.9c0-1.5,1-2.6,2.5-2.6c1.5,0,2.5,1.1,2.5,2.6c0,1.5-1,2.5-2.5,2.5-->
<!--          C1100.4,512.5,1099.4,511.4,1099.4,509.9z"/>-->
<!--        <path class="st14" d="M1111.7,497.9L1111.7,497.9l-3.5,1.6l-0.7-3.1l4.8-2.2h3.5v18h-4.1V497.9z"/>-->
<!--        <path class="st14" d="M1083.5,579.4c0-2.2,1.2-3.6,3.2-4.5v-0.1c-1.7-0.9-2.6-2.3-2.6-3.9c0-3,2.7-5.1,6.3-5.1-->
<!--          c4.2,0,5.9,2.4,5.9,4.6c0,1.5-0.8,3.1-2.6,4v0.1c1.7,0.7,3.3,2.2,3.3,4.5c0,3.3-2.7,5.4-6.9,5.4-->
<!--          C1085.6,584.4,1083.5,581.8,1083.5,579.4z M1092.8,579.2c0-1.6-1.2-2.5-2.8-3c-1.4,0.4-2.2,1.4-2.2,2.7c0,1.3,1,2.5,2.5,2.5-->
<!--          C1091.8,581.5,1092.8,580.5,1092.8,579.2z M1088.1,570.7c0,1.2,1.1,2,2.5,2.4c1-0.3,1.8-1.2,1.8-2.3c0-1.1-0.6-2.2-2.2-2.2-->
<!--          C1088.8,568.7,1088.1,569.6,1088.1,570.7z"/>-->
<!--        <path class="st14" d="M1099.4,581.8c0-1.5,1-2.6,2.5-2.6c1.5,0,2.5,1.1,2.5,2.6c0,1.5-1,2.5-2.5,2.5-->
<!--          C1100.4,584.4,1099.4,583.3,1099.4,581.8z"/>-->
<!--        <path class="st14" d="M1111.7,569.8L1111.7,569.8l-3.5,1.6l-0.7-3.2l4.8-2.2h3.5v18h-4.1V569.8z"/>-->
<!--        <path class="st14" d="M1083.5,651.1c0-2.2,1.2-3.6,3.2-4.5v-0.1c-1.7-0.9-2.6-2.3-2.6-3.9c0-3,2.7-5.1,6.3-5.1-->
<!--          c4.2,0,5.9,2.4,5.9,4.6c0,1.5-0.8,3.1-2.6,4v0.1c1.7,0.7,3.3,2.2,3.3,4.5c0,3.3-2.7,5.4-6.9,5.4-->
<!--          C1085.6,656.1,1083.5,653.5,1083.5,651.1z M1092.8,651c0-1.6-1.2-2.5-2.8-3c-1.4,0.4-2.2,1.4-2.2,2.7c0,1.3,1,2.5,2.5,2.5-->
<!--          C1091.8,653.2,1092.8,652.2,1092.8,651z M1088.1,642.5c0,1.2,1.1,2,2.5,2.4c1-0.3,1.8-1.2,1.8-2.3c0-1.1-0.6-2.2-2.2-2.2-->
<!--          C1088.8,640.4,1088.1,641.3,1088.1,642.5z"/>-->
<!--        <path class="st14" d="M1099.4,653.6c0-1.5,1-2.6,2.5-2.6c1.5,0,2.5,1.1,2.5,2.6c0,1.5-1,2.5-2.5,2.5-->
<!--          C1100.4,656.1,1099.4,655,1099.4,653.6z"/>-->
<!--        <path class="st14" d="M1106.3,655.8v-2.5l2.3-2.1c3.9-3.5,5.8-5.5,5.9-7.6c0-1.5-0.9-2.6-3-2.6c-1.5,0-2.9,0.8-3.8,1.5l-1.2-3-->
<!--          c1.4-1,3.5-1.9,5.9-1.9c4.1,0,6.3,2.4,6.3,5.6c0,3-2.2,5.4-4.8,7.7l-1.7,1.4v0.1h6.8v3.5H1106.3z"/>-->
<!--        <path class="st14" d="M1096.4,728.2c0.5,0.1,1,0.1,1.8,0c1.3-0.1,2.6-0.4,3.5-1.1c1.2-0.8,1.9-2,2.3-3.3l-0.1,0-->
<!--          c-0.8,0.8-2,1.3-3.6,1.3c-3,0-5.6-2.1-5.6-5.6c0-3.5,2.8-6.5,6.8-6.5c4.6,0,6.8,3.5,6.8,7.7c0,3.7-1.2,6.5-3.1,8.3-->
<!--          c-1.7,1.5-4.1,2.4-6.9,2.5c-0.7,0.1-1.4,0-1.9,0V728.2z M1098.9,719.1c0,1.5,0.8,2.9,2.5,2.9c1.1,0,1.9-0.6,2.3-1.2-->
<!--          c0.1-0.2,0.2-0.5,0.2-1.1c0-1.9-0.7-3.8-2.6-3.8C1100,716,1098.9,717.3,1098.9,719.1z"/>-->
<!--        <path class="st14" d="M1081.5,794.9L1081.5,794.9l-3.5,1.6l-0.7-3.2l4.8-2.2h3.5v18h-4.1V794.9z"/>-->
<!--        <path class="st14" d="M1104.7,800.1c0,5.6-2.2,9.4-6.8,9.4c-4.6,0-6.7-4.2-6.7-9.3c0-5.2,2.2-9.3,6.9-9.3-->
<!--          C1102.9,790.9,1104.7,795.1,1104.7,800.1z M1095.4,800.2c0,4.1,1,6.1,2.6,6.1s2.5-2,2.5-6.1c0-4-0.9-6.1-2.5-6.1-->
<!--          C1096.4,794,1095.4,796,1095.4,800.2z"/>-->
<!--        <path class="st14" d="M1107,806.9c0-1.5,1-2.6,2.5-2.6c1.5,0,2.5,1.1,2.5,2.6c0,1.5-1,2.5-2.5,2.5-->
<!--          C1108.1,809.4,1107,808.4,1107,806.9z"/>-->
<!--        <path class="st14" d="M1114,809.1v-2.5l2.3-2.1c3.9-3.5,5.8-5.5,5.9-7.6c0-1.5-0.9-2.6-3-2.6c-1.5,0-2.9,0.8-3.8,1.5l-1.2-3-->
<!--          c1.4-1,3.5-1.9,5.9-1.9c4.1,0,6.3,2.4,6.3,5.6c0,3-2.2,5.4-4.8,7.7l-1.7,1.4v0.1h6.8v3.5H1114z"/>-->
<!--        <path class="st14" d="M1081.5,840.5L1081.5,840.5l-3.5,1.6l-0.7-3.2l4.8-2.2h3.5v18h-4.1V840.5z"/>-->
<!--        <path class="st14" d="M1104.7,845.6c0,5.6-2.2,9.4-6.8,9.4c-4.6,0-6.7-4.2-6.7-9.3c0-5.2,2.2-9.3,6.9-9.3-->
<!--          C1102.9,836.4,1104.7,840.7,1104.7,845.6z M1095.4,845.7c0,4.1,1,6.1,2.6,6.1s2.5-2,2.5-6.1c0-4-0.9-6.1-2.5-6.1-->
<!--          C1096.4,839.6,1095.4,841.6,1095.4,845.7z"/>-->
<!--        <path class="st14" d="M1107,852.4c0-1.5,1-2.6,2.5-2.6c1.5,0,2.5,1.1,2.5,2.6c0,1.5-1,2.5-2.5,2.5-->
<!--          C1108.1,855,1107,853.9,1107,852.4z"/>-->
<!--        <path class="st14" d="M1114.7,850.6c0.7,0.4,2.5,1.1,4.2,1.1c2.2,0,3.3-1.1,3.3-2.4c0-1.8-1.8-2.6-3.6-2.6h-1.7v-3h1.6-->
<!--          c1.4,0,3.2-0.6,3.2-2.1c0-1.1-0.9-1.9-2.7-1.9c-1.5,0-3,0.6-3.8,1.1l-0.9-3c1.1-0.7,3.2-1.4,5.6-1.4c3.8,0,6,2,6,4.5-->
<!--          c0,1.9-1.1,3.4-3.3,4.2v0.1c2.2,0.4,3.9,2,3.9,4.4c0,3.2-2.8,5.5-7.4,5.5c-2.3,0-4.3-0.6-5.3-1.3L1114.7,850.6z"/>-->
<!--        <path class="st14" d="M1081.5,909.2L1081.5,909.2l-3.5,1.6l-0.7-3.2l4.8-2.2h3.5v18h-4.1V909.2z"/>-->
<!--        <path class="st14" d="M1104.7,914.3c0,5.6-2.2,9.4-6.8,9.4c-4.6,0-6.7-4.2-6.7-9.3c0-5.2,2.2-9.3,6.9-9.3-->
<!--          C1102.9,905.1,1104.7,909.4,1104.7,914.3z M1095.4,914.4c0,4.1,1,6.1,2.6,6.1s2.5-2,2.5-6.1c0-4-0.9-6.1-2.5-6.1-->
<!--          C1096.4,908.3,1095.4,910.3,1095.4,914.4z"/>-->
<!--        <path class="st14" d="M1107,921.1c0-1.5,1-2.6,2.5-2.6c1.5,0,2.5,1.1,2.5,2.6c0,1.5-1,2.5-2.5,2.5-->
<!--          C1108.1,923.7,1107,922.6,1107,921.1z"/>-->
<!--        <path class="st14" d="M1119.4,909.2L1119.4,909.2l-3.5,1.6l-0.7-3.2l4.8-2.2h3.5v18h-4.1V909.2z"/>-->
<!--        <path class="st14" d="M921.7,618.9V616l2.7-2.4c4.5-4,6.7-6.4,6.8-8.8c0-1.7-1-3-3.4-3c-1.8,0-3.3,0.9-4.4,1.7L922,600-->
<!--            c1.6-1.2,4-2.1,6.8-2.1c4.7,0,7.3,2.7,7.3,6.5c0,3.5-2.5,6.2-5.5,8.9l-1.9,1.6v0.1h7.8v4H921.7z"/>-->
<!--        <path class="st14" d="M922.6,718.2c0.9,0.4,2.8,1.3,4.8,1.3c2.5,0,3.8-1.2,3.8-2.8c0-2-2-3-4.2-3h-2v-3.5h1.9-->
<!--            c1.6,0,3.7-0.6,3.7-2.4c0-1.2-1-2.2-3.1-2.2c-1.7,0-3.5,0.7-4.3,1.2l-1-3.5c1.2-0.8,3.7-1.6,6.4-1.6c4.4,0,6.9,2.3,6.9,5.2-->
<!--            c0,2.2-1.2,3.9-3.8,4.8v0.1c2.5,0.4,4.5,2.3,4.5,5c0,3.7-3.2,6.3-8.5,6.3c-2.7,0-4.9-0.7-6.2-1.5L922.6,718.2z"/>-->
<!--        <path class="st14" d="M930.3,852.6v-4.9h-9.2v-3.2l7.8-12.6h5.9V844h2.5v3.6h-2.5v4.9H930.3z M930.3,844v-4.6-->
<!--            c0-1.2,0.1-2.5,0.2-3.9h-0.1c-0.7,1.3-1.2,2.5-1.9,3.9l-2.8,4.5v0.1H930.3z"/>-->

      <rect x="1064" y="45.8" class="st15" width="1.3" height="38.5"/>
      <rect x="1142" y="45.8" class="st15" width="1.3" height="38.5"/>
      <rect x="1064" y="84.7" class="st12" width="1.3" height="862.8"/>
      <rect x="980.7" y="84.7" class="st12" width="1.3" height="862.8"/>
      <rect x="1142" y="84.7" class="st12" width="1.3" height="862.8"/>
      <rect x="980.7" y="45.8" class="st15" width="1.3" height="38.5"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {questionary: Object},
  name: "PatientComplaintsWoman",
  data() {
    return {
      sts: [],
      groups: []
    }
  },
  methods: {

    toogleSvgClass(event, name) {
      console.log('toogleSvgClass ' + name)
      if (name && name === 'ast7') {
        this.questionary.painHead = !this.questionary.painHead
      } else if (name && name === 'ast1') {
        this.questionary.painFrontHalfChest = !this.questionary.painFrontHalfChest
      } else if (name && name === 'ast3') {
        this.questionary.painHeart = !this.questionary.painHeart
      } else if (name && name === 'ast2') {
        this.questionary.painChest = !this.questionary.painChest
      } else if (name && name === 'ast10') {
        this.questionary.painCenterSpine = !this.questionary.painCenterSpine
      } else if (name && name === 'ast9') {
        this.questionary.painBack = !this.questionary.painBack
      } else if (name && name === 'ast4') {
        this.questionary.painUpAbdominal = !this.questionary.painUpAbdominal
      } else if (name && name === 'ast6') {
        this.questionary.painLowAbdominal = !this.questionary.painLowAbdominal
      } else if (name && name === 'ast8') {
        this.questionary.painLowBack = !this.questionary.painLowBack
      } else if (name && name === 'ast0') {
        this.questionary.painMuscle = !this.questionary.painMuscle
      } else if (name && name === 'ast5') {
        this.questionary.painJoint = !this.questionary.painJoint
      }

      this.selectGroups(this.questionary)
    },
    selectGroups(questionary) {

      if (questionary.painMuscle || questionary.painJoint) {
        if (!this.groups.includes('10'))
          this.groups.push('10')
      } else {
        if (this.groups.includes('10'))
          this.groups.splice(this.groups.indexOf('10'), 1)
      }
      if (questionary.painLowBack) {
        if (!this.groups.includes('9'))
          this.groups.push('9')
      } else {
        if (this.groups.includes('9'))
          this.groups.splice(this.groups.indexOf('9'), 1)
      }
      if (questionary.painLowAbdominal || questionary.painUpAbdominal) {
        if (!this.groups.includes('8'))
          this.groups.push('8')
      } else {
        if (this.groups.includes('8'))
          this.groups.splice(this.groups.indexOf('8'), 1)
      }
      if (questionary.painHead
          || questionary.painFrontHalfChest
          || questionary.painHeart
          || questionary.painChest
          || questionary.painCenterSpine
          || questionary.painBack) {
        if (!this.groups.includes('6'))
          this.groups.push('6')
      } else {
        if (this.groups.includes('6'))
          this.groups.splice(this.groups.indexOf('6'), 1)
      }

      this.$emit('select-group', this.groups, false)
    }
  }
}
</script>

<style scoped>
.st0{fill:#3E9344;} /* мышцы 4 groupId = 10 */
.st1{fill:#CCCCCB;} /* передняя половина грудной клетки */
.st2{fill:#6D331F;} /* грудина*/
.st3{fill:#D62D2C;} /* под лопаткой справа сердце */
.st4{fill:#F7D443;} /* верхний отдел живота 2 groupId = 8 */
.st5{fill:#EEC0B1;} /* суставы 4 groupId = 10  */
.st6{fill:#2B608A;} /* нижний отдел живота 2 groupId = 8 */
.st7{fill:#59C0DB;} /* голова */
.st8{fill:#F37951;} /* поясница 3 groupId = 9 */
.st9{fill:#72469A;} /* боль со спины */
.st10{fill:#147A70;} /* шейный и грудной отдел */
.st11{fill:#D1D3D4;}
.st12{fill:#F1F2F2;}
.st13{fill:#6D6E71;}
.st14{fill:#58595B;} /* текст заголовков в таблице и номеров*/
.st15{fill:#FFFFFF;}

.svglink{ opacity:0.1; transition: .5s opacity; cursor: pointer; }
.svglinkActive{ opacity:1; transition: .5s opacity; cursor: pointer;}
.svglink:hover {opacity: 1;}

</style>