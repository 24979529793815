<template>
  <b-table striped hover :items="reportParams" :fields="fields" class="background_FFFFFF h-100"
           :thead-class="'table-header'" :tbody-tr-class="rowClass" >
    <template v-slot:head(examinationDate)="data">
      <div class="column-head vertical-center">Дата ввода исследования</div>
    </template>
    <template v-slot:head(val)="data">
      <div class="column-head vertical-center">Результат</div>
    </template>
    <template v-slot:cell(examinationDate)="data">
      <div class="vertical-center-container"><span class="vertical-center" >{{ new Date(data.item.examinationDate).toLocaleDateString() }}</span></div>
    </template>
    <template v-slot:cell(val)="data">
      <div class="vertical-center">
          <b :class="'div-' + rowClass(data.item, 'row')">{{ data.item.propertyName }}, {{ data.item.propertyValueName }}</b>
      </div>
    </template>
  </b-table>


</template>

<script>
import {host} from "@/constants";

export default {
  props: {
    patientId: {
      type: String,
      default: '0'
    },
    paramId: {
      type: Number,
      default: 0
    }
  },
  name: "DynamicInfoReport",
  data() {
    return {
      fields: [
        {
          key: 'examinationDate',
          label: 'Дата ввода исследования',
          thClass: 'table-header h-100',
          tdClass: 'h-100'
        },
        {
          key: 'val',
          label: 'Результат',
          thClass: 'table-header h-100',
          tdClass: 'h-100'
        }
      ],
      reportParams: []
    }
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.zoneId === 6 || item.zoneId === 16 || item.zoneId === 19 || item.zoneId === 25) return 'table-row-good'
      else return 'table-row'
    },
    fetchData() {
      fetch(host + '/rest/patients/' + this.patientId + '/params/' + this.paramId + '/exams', {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.reportParams = json.reportParams

            this.loading = false
            //console.log(JSON.stringify(json))
          }).catch(error => {
        console.log(error)
        this.loading = false
      })
    }

  },
  mounted() {
    this.fetchData()
  }

}
</script>

<style scoped>

</style>