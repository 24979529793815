<template>
  <div>
    <div v-if="error" class="alert alert-danger">
      {{error}}
    </div>
    <b-form @submit.prevent="onSubmit">

      <b-form-group id="ig2" label-for="paramValue" label="Значение">
        <b-form-input id="paramValue" type="text" required placeholder="Введите значение" v-model="mu.text" autocomplete="off"></b-form-input>
      </b-form-group>

      <b-form-checkbox v-if="currentRubricator.value !=='ConditionAndSyndrome' " id="hiddenForDoctor" v-model="mu.hiddenForDoctor" switch >Скрыт для врача</b-form-checkbox>



      <b-form-group id="ig3" v-if="isHierarchical" label-for="paramValue" label="Родитель">
        <treeselect name="laboratories" id="laboratories" placeholder=""
                  searchable clearable
                  open-on-click close-on-select :flat="true"
                    :noResultsText="'Не найдено...'"
                  :options="conditionAndSyndromesOptions"
                  :max-height="200"
                  :normalizer="normalizeTreeselect"
                  v-model="mu.parentValue" style="font-size: 15px!important;"/>
      </b-form-group>

      <b-button type="submit" class="btn-info" variant="primary">Сохранить</b-button>

      <b-form-group v-if="currentRubricator.value !== 'ConditionAndSyndrome' && currentRubricator.value !== 'BiomaterialType'" id="drugPatient" label-for="firstname" label="Добавление связанного аналита">
        <treeselect name="param" id="param" placeholder=""
                    clearable searchable
                    open-on-click close-on-select disableFuzzyMatching
                    :noResultsText="'Не найдено...'"
                    :noOptionsText="'Список опций пуст'"
                    :options="paramOptions | searchQueryFilter(selectedParamId, childrenSearchQuery)"
                    :max-height="200"
                    :normalizer="normalizeParamsTreeselect"
                    v-model="selectedParamId" style="font-size: 15px!important;"  @search-change="childrenSearchChange">
        </treeselect>

        <b-button @click="saveRubricatorAnalit" variant="info">Добавить</b-button>
      </b-form-group>

      <div v-for="(item, index) in params" :key="item.paramId">
        <router-link  :to="'/analitedit/' + item.paramId">{{item.paramName}}, {{item.biomaterialTypeName}}</router-link>
        <b-link v-if="currentRubricator.value !== 'ConditionAndSyndrome' && currentRubricator.value !== 'BiomaterialType'" @click="deleteRubricatorParam(item.paramId, item.paramName + ', ' + item.biomaterialTypeName, index)">
          <b-icon variant="info" icon="x-circle" size="lg"></b-icon>
        </b-link>
      </div>


    </b-form>
  </div>
</template>

<script>
import {host} from "@/constants";
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  name: "RubricatorValueEdit",
  props: {
    currentRubricator: Object,
    currentRubricatorValue: Object,
    isHierarchical: Boolean
  },
  data() {
    return {
      error: null,
      mu: {
        value: this.currentRubricatorValue !== null ? this.currentRubricatorValue.value : null,
        text: this.currentRubricatorValue !== null ? this.currentRubricatorValue.text : null,
        hiddenForDoctor: this.currentRubricatorValue !== null ? this.currentRubricatorValue.hiddenForDoctor : null,
        parentValue: this.currentRubricatorValue !== null ? this.currentRubricatorValue.parentValue : null,
      },
      params: [],
      paramOptions: [],
      selectedParamId: null,
      normalizeTreeselect(node) {
        return {
          id: node.value,
          label: node.text,
          children: node.children ? node.children : null,
        }
      },
      normalizeParamsTreeselect(node) {
        return {
          id: node.paramId,
          label: node.paramName + (node.biomaterialTypeName ? ', ' + node.biomaterialTypeName : '')
        }
      },
      conditionAndSyndromesOptions: [],
      childrenSearchQuery: null
    }
  },
  components: {
    Treeselect
  },
  filters: {
    searchQueryFilter: function (options, selectedParamId, searchQuery) {
      let result = []
      if (searchQuery) {
        result = options.filter((el, index, []) => {
          return selectedParamId === el.paramId || ((searchQuery) && (el.paramName + el.biomaterialTypeName + ';_;_; ' + ' ' + el.searchString).toLowerCase().includes(searchQuery.toLowerCase()) )
        })
      } else {
        result = options.filter((el, index, []) => {
          return selectedParamId === el.paramId || index < 50
        })
      }
      return result
    }
  },
  methods: {
    saveRubricatorAnalit() {
      fetch(host + '/rest/rubricators/' + this.currentRubricator.value + '/' + this.currentRubricatorValue.value + '/param/' + this.selectedParamId, {
        method: 'POST',
        credentials: 'include',

        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode === 0) {
              this.$messageToast('Связь рубрикатора и аналита')
              let localParams = this.paramOptions.filter( el => (el.paramId == this.selectedParamId))
              if (localParams.length > 0) {
                this.params.push(localParams[0])
              }
              this.selectedParamId = null
            } else {
              this.$errorToast(json.resultCode + ' ' + json.message)
            }

          }).catch(error => {
        this.$errorToast(error)
      })
    },
    childrenSearchChange(searchQuery, instanceId) {
      this.childrenSearchQuery = searchQuery
    },
    deleteRubricatorParam(paramId, paramName, index) {
      if (confirm('Вы действительно хотите удалить связь с рубрикатором "' + paramName + '"?')) {
        fetch(host + '/rest/rubricators/' + this.currentRubricator.value + '/' + this.currentRubricatorValue.value + '/param/' + paramId, {
          method: 'DELETE',
          credentials: 'include',

          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode === 0) {
                this.$messageToast('Удаление связи')
                this.params.splice(index, 1)
              } else {
                this.$errorToast(json.resultCode + ' ' + json.message)
              }

            }).catch(error => {
              this.$errorToast(error)
            })
      }

    },
    onFilterSelect() {
      console.log('onFilterSelect started')
      let filterRq = {}

      if (this.mu.value) {
      // MedicalSpecialty("Клинические разделы"),
      //     MetabolismType("Виды обмена"),
      //     LabStateTissueOrgan("Лабораторные состояния систем, тканей и органов"),
      //     ConditionAndSyndrome("Патологические состояния и синдромы"),
      //     Method("Метод определения аналитов"),
      //     ComplexExamination("Комплексное исследование аналитов"),
      //     Laboratory("Лаборатории"),
      //     LaboratorySection("Лабораторный раздел"),
      //     BiomaterialType("Тип биоматериала")
      //
        switch (this.currentRubricator.value) {
          case 'MedicalSpecialty': filterRq.medicalSpecialtyId = this.mu.value; break;
          // case 'MetabolismType': filterRq.metabolismTypeId = this.mu.value; break;
          case 'LabStateTissueOrgan': filterRq.labStateTissueOrganId = this.mu.value; break;
          case 'ConditionAndSyndrome': filterRq.conditionAndSyndromeId = this.mu.value; break;
          case 'Method': filterRq.methodId = this.mu.value; break;
          case 'ComplexExamination': filterRq.complexExaminationId = this.mu.value; break;
          case 'Laboratory': filterRq.laboratoryId = this.mu.value; break;
          case 'LaboratorySection': filterRq.laboratorySectionId = this.mu.value; break;
          case 'BiomaterialType': filterRq.biomaterialType = this.mu.value; break;
        }
        let method = 'POST'

        fetch(host + '/rest/params/filter', {
          method: method,
          credentials: 'include',
          body: JSON.stringify(filterRq),
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {
              this.params = json.params
            }).catch(error => {
              this.error = error
              console.log(JSON.stringify(error))
            })
      }
    },
    onSubmit() {
      let method = 'POST'
      if (this.currentRubricatorValue !== null) {
        method = 'PUT'
      }

      console.log(JSON.stringify(this.mu))
      fetch(host + '/rest/rubricators/' + this.currentRubricator.value, {
        method: method,
        credentials: 'include',
        body: JSON.stringify(this.mu),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {
              console.log('Save Success ' + JSON.stringify(json))
              this.$emit('save-success')
            } else {
              console.log('Save Failed ' + json)
              this.error = json.resultCode + ' ' + json.message;
            }
          })
          .catch(error => {
            this.error = error
            console.log(JSON.stringify(error))
          })

    },
    fetchDictsData() {
      fetch(host + '/rest/rubricators/ConditionAndSyndrome')
          .then(response => response.json())
          .then(json => {
            this.conditionAndSyndromesOptions = json.options
            console.log(json)
          })

      fetch(host + '/rest/params', {
        credentials: "include"
      }).then(response => response.json())
          .then(json => {
            console.log(json)
            this.paramOptions = json.params

          })

    }
  },
  mounted() {
    this.fetchDictsData()
    this.onFilterSelect()
  }
}
</script>

<style scoped>

</style>