<template>
  <div>
    <div v-if="error" class="alert alert-danger">
      {{error}}
    </div>
    <b-form @submit.prevent="onSubmit">

      <b-form-group id="ig2" label-for="paramValue" label="Значение">
        <b-form-input id="paramValue" type="text" required placeholder="Введите значение" v-model="mu.text" autocomplete="off"></b-form-input>
      </b-form-group>

      <b-button type="submit" class="btn-info" variant="primary">Сохранить</b-button>

    </b-form>
  </div>
</template>

<script>
import {host} from "@/constants";
export default {
  name: "FactorValueEdit",
  props: {
    currentRubricator: Object,
    currentRubricatorValue: Object
  },
  data() {
    return {
      error: null,
      mu: {
        value: this.currentRubricatorValue !== null ? this.currentRubricatorValue.value : null,
        text: this.currentRubricatorValue !== null ? this.currentRubricatorValue.text : null
      }
    }
  },
  methods: {
    onSubmit() {
      let method = 'POST'
      if (this.currentRubricatorValue !== null) {
        method = 'PUT'
      }

      console.log(JSON.stringify(this.mu))
      fetch(host + '/rest/factors/' + this.currentRubricator.value, {
        method: method,
        credentials: 'include',
        body: JSON.stringify(this.mu),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {
              console.log('Save Success ' + JSON.stringify(json))
              this.$emit('save-success')
            } else {
              console.log('Save Failed ' + json)
              this.error = json.resultCode + ' ' + json.message;
            }
          })
          .catch(error => {
            this.error = error
            console.log(JSON.stringify(error))
          })

    }
  }
}
</script>

<style scoped>

</style>