import createNumberMask from "text-mask-addons/dist/createNumberMask";

export const host = '' //'http://localhost:8084' //
export const roles = [
    {
      id: 1,
      name: 'Пациент'
    },
    {
      id: 2,
      name: 'Врач'
    },
    {
      id: 3,
      name: 'Администратор'
    }
  ]

export const yearMask = createNumberMask({
  allowDecimal: false,
  includeThousandsSeparator: false,
  allowNegative: false,
  integerLimit: 3,
  prefix: '',
  suffix: ''
})
export const currencyMask = function(rawValue) {
  let numberMask = createNumberMask({
    allowDecimal: true,
    includeThousandsSeparator: false,
    allowNegative: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    prefix: '',
    suffix: ''
  })
  rawValue = rawValue.replace(',','.')
  let mask = numberMask(rawValue)
  return mask
}

export const pregnantWeekMask = createNumberMask({
  allowDecimal: false,
  includeThousandsSeparator: false,
  allowNegative: false,
  integerLimit: 2,
  prefix: '',
  suffix: ''
})