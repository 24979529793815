<template>
  <div class="main-flex-container">
    <CabinetHeader />
    <img src="@/assets/images/BAnner2.png" class="w-100" style="height: 37px!important;">
    <div class="main-content">



        <router-view/>

    </div>
    <footer>
      <div class="row mx-0 backgrround">
        <div class="col-md-6"><p class="foot">© 2021-2023 Каллисто. Все права защищены</p></div>
        <div class="col-md-2 text-md-center"><p class="foot"><a href="#" class="foot"> Лицензиии и сертификаты</a></p></div><div class="d-none d-md-block line_small">|</div>
        <div class="col-md-2 text-md-center"><p class="foot"><a href="#" class="foot"> Условия конфиденциальности</a></p></div><div class="d-none d-md-block line_small">|</div>
        <div class="col-md text-md-center"><p class="foot"><a href="#"  class="foot"> Контактная информация </a></p></div>
      </div>
    </footer>

  </div>
</template>

<script>
import CabinetSidebar from "@/components/CabinetSidebar";
import CabinetHeader from "@/components/CabinetHeader";
export default {
  name: "CabinetLayout",
  components: {CabinetHeader, CabinetSidebar}
}
</script>

<style>

/*@import '../assets/css/main_page.css';*/
</style>