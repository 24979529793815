<template>
  <div id="app" class="main-flex-container">
    <component :is="layout">
      <router-view></router-view>
    </component>
<!--    <Header></Header>-->
<!--    <div class="mainContent">-->
<!--      <router-view></router-view>-->
<!--    </div>-->
  </div>
</template>

<script>
  import Header from "./components/Header";
  import IndexLayout from "@/layouts/IndexLayout";
  import BootVueLayout from "@/layouts/BootVueLayout";
  import CabinetLayout from "@/layouts/CabinetLayout";
  import BaseLayout from "@/layouts/BaseLayout";
  export default {
    computed: {
      layout() {
        return (this.$route.meta.layout || 'boot-vue') + '-layout'
      }
    },
    name: 'app',
    components: {
      Header, IndexLayout, BootVueLayout, CabinetLayout, BaseLayout
    }
  }
</script>

<style>
  .mainContent {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  /*@import 'assets/css/main_page.css'*/
</style>