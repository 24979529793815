<template>
  <div>
    <b-form @submit.prevent="onSubmit">
      <div v-if="error" class="alert alert-danger">
        {{error}}
      </div>

      <b-form-group id="measurementUnitId" label-for="measurementUnitId" label="Единицы измерения">
        <b-form-select id="measurementUnitId" v-model="measurementUnitId" :options="measureOptions" :select-size="1"></b-form-select>
      </b-form-group>

      <b-form-group id="ig2" label-for="coef" label="Коэффициент">
        <b-form-input v-mask="mask" id="coef" type="text" required placeholder="Введите значение" v-model="coef" autocomplete="off"></b-form-input>
      </b-form-group>

      <b-button variant="info" type="submit" >Сохранить</b-button>
    </b-form>
  </div>
</template>

<script>
import {host} from "@/constants";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
const currencyMask = createNumberMask({
  allowDecimal: true,
  includeThousandsSeparator: false,
  allowNegative: true,
  decimalSymbol: '.',
  decimalLimit: 10,
  prefix: '',
  suffix: ''
})
export default {

  name: "AnalitMeasurementEdit",
  props: ["analitId"],
  data() {
    return {
      mask: currencyMask,
      error: null,
      measureOptions: [],
      measurementUnitId: null,
      coef: 1
    }
  },
  mounted() {
    fetch(host + '/rest/dicts/measurementunit')
      .then(response => response.json())
      .then(json => {
        this.measureOptions = json.options
      })
  },
  methods : {
    onSubmit() {
      this.error = null
      let rq = {
        paramId: this.analitId,
        coef: this.coef,
        measurementUnitId: this.measurementUnitId
      }

      fetch(host + '/rest/params/measurement', {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(rq),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {

              this.selected = null
              this.paramValue = null
              this.$emit('save-success')
            } else {

              this.error = json.resultCode + ' ' + json.message;
            }
          })
          .catch(error => {
            this.error = error

          })

    }
  }
}
</script>

<style scoped>

</style>