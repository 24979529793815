<template>
  <header>
    <!-----------------ШАПКА МОБ ВЕРСИЯ--------------------------->
    <div class="d-block d-md-none text-center row mx-0">
      <div class="col-12">
        <div class="row">
          <div class="shadow col-12 pb-1">
            <div class="row">
              <div class="col-12">
                <a href="#"><img src="@/assets/images/Logo_4P.png" class="mob_img" ></a>
              </div>
            </div>
          </div>
          <div class="row col-12 justify-content-between">
            <div class="col prof">
              <div class="ml-0 row">
                <div class="">
                  <img class="mob_avatar" src="@/assets/images/Vector.png"></img>
                </div>
                <p class="name2_mob">{{ rolename }}</p>

<!--                <a @click="exitClick" class="button my-auto ml-5"><p class="text-center buttonp">Выйти</p></a>-->
                <button @click="exitClick" class="ml-2 pb-1 btn_call_back">
                  <span class="call_back">Выйти</span>
                </button>
                <a href="#"><p class="name">{{ username }}</p></a>



              </div>

            </div>
            <div class="col">
              <div class="mt-2 ">
                <div class="mr-4">
                  <img src="@/assets/images/Vector (1).png"></img>
                  <a href="tel:+7 495 998 32 32" class="phonep"> +7 495 998 32 32</a>
                </div>
                <div class="mt-1">
                  <img src="@/assets/images/Vector (2).png"></img>
                  <a href="mailto:info@callisto-pro.ru" class="mailp"> info@callisto-pro.ru</a>
                </div>

              </div>


            </div>
          </div>
        </div>
      </div>
    </div>

    <!-----------------ШАПКА ПК ВЕРСИЯ--------------------------->
    <div class="row  d-none d-md-flex pb-2 mx-0">
      <div class="col-xl col-lg-12 col-md-12  mt-0 header__logo">
        <router-link to="/"><img  src="@/assets/images/Logo_Kalisto.svg"></router-link>



      </div>
      <div class="col-xl col-lg-12 col-md-12  mt-0 justify-content-center " v-if="isAuthorized && (role === 2 || role === 3)">
        <div class="header-menu">
          <b-link v-for="link in links" :key="link.url" v-if="role === link.role"
                  :to="link.url" :active="$route.matched.some(({ name }) => name === link.name)"
                  class="header-menu__item" active-class="header-menu__item_selected">
            {{ link.title }}
          </b-link>
        </div>

<!--        <b-dropdown v-if="isAuthorized" size="lg" variant="outline-success">-->
<!--          <template #button-content>-->
<!--            <b-icon icon="menu-down" variant="outline-success" title="Показать меню"></b-icon>-->
<!--          </template>-->
<!--          <b-dropdown-item variant="success" v-for="link in links" :key="link.url" v-if="role === link.role"-->
<!--                           :to="link.url" :active="$route.matched.some(({ name }) => name === link.name)"-->
<!--          >{{ link.title }}</b-dropdown-item>-->

          <!--          <b-dropdown-item href="#">Another item</b-dropdown-item>-->
<!--        </b-dropdown>-->



      </div>
      <div class="col-xl ml-4 mt-2 col-lg-6 col-md-6 prof d-flex justify-content-between">

        <div class="my-auto row userblock">
          <div>
            <img class="avatar" src="@/assets/images/Vector.png">
          </div>
          <div class="userinfo">
            <router-link :to="role===3 ? '/analits' : (role===2 ? '/patients' : '/patients/' + userId)"><p class="name">{{ username }}</p></router-link>
            <p class="name2">{{ rolename }}</p>
          </div>
        </div>
        <div v-if="activationDaysLeftCount && activationDaysLeftCount <= 5" class="d-flex justify-content-end">
          <div class="col-7 col-md-8 pt-2 text-md-right px-0 account_expiration_date"><span> Срок действия аккаунта: </span></div>
          <div class="col-5 col-md-4 text-right date_days" ><span > {{ activationDaysLeftCount }}
            {{activationDaysLeftCount ==5 || activationDaysLeftCount ==0 ? 'дней' : ''}}
            {{activationDaysLeftCount ==2 || activationDaysLeftCount ==3 || activationDaysLeftCount == 4 ? 'дня' : ''}}
            {{activationDaysLeftCount ==1  ? 'день' : ''}}
          </span></div>
        </div>

<!--        <a @click="exitClick" class="button my-auto">-->
<!--          <p class="text-center buttonp">Выйти</p>-->
<!--        </a>-->
        <button @click="exitClick" class="ml-2 pb-1 btn_call_back btn_call_back_exit">
          <span class="call_back">Выйти</span>
        </button>

      </div>
      <div class="col-xl col-lg-5 col-md-5 text-right mt-xl-1 mt-1 mr-xl-4">
        <div class="row mr-4 mt-1">
          <div class="px-0 col col-xl-6 col-lg-5 col-md-3 col-sm-2 text-right">
            <img src="@/assets/images/Vector1.png">&nbsp;
          </div>
          <div class="px-0 col text-left">
            <a href="tel:+7 495 998 32 32" class="tl"> +7 495 998 32 32</a>
          </div>
        </div>
        <div class="row mr-4 mt-1">
          <div class="px-0 mt-1 col col-xl-6 col-lg-5 col-md-3 col-sm-2 text-right">
            <img src="@/assets/images/Vector (2).png">&nbsp;
          </div>
          <div class="px-0 mt-1 col text-left">
            <a href="mailto:info@callisto-pro.ru" class="ml"> info@callisto-pro.ru</a>
          </div>
        </div>

<!--        <div class="mr-4 mt-1">-->
<!--          <img src="@/assets/images/Vector (1).png"><a href="tel:+7 495 998 32 32" class="phonep"> +7 495 998 32 32</a>-->
<!--        </div>-->
<!--        <div class="mt-1">-->
<!--          <img src="@/assets/images/Vector (2).png">-->
<!--          <a href="mailto:info@callisto-pro.ru" class="mailp"> info@callisto-pro.ru</a>-->
<!--        </div>-->
      </div>
    </div>

  </header>
</template>

<script>
import {host, roles} from "@/constants";

export default {
  name: "CabinetHeader",
  data() {
    return{
      links: [
        {role: 2, url: '/patients', name:'patients', title: 'Пациенты', icon: require('@/assets/images/Icon_Pacient.png')},
        {role: 2, url: '/doctorinfo', name:'doctorinfo', title: 'Личные данные', icon: require('@/assets/images/Group645.png')},
        {role: 1, url: '/patients/' + this.userId, name:'patient', title: 'Личные данные', icon1: require('@/assets/images/Icon_Pacient.png')}
      ]
    }
  },
  computed: {
    isAuthorized: {
      get() {
        return this.$store.getters.isAuthorized
      },
      set(value) {
        this.$store.commit('updateIsAuthorized', value)
      }
    },
    role: {
      get() {
        return this.$store.getters.role
      },
      set(value) {
        this.$store.commit('updateRole', value)
      }
    },
    rolename: {
      get() {
        return this.$store.getters.rolename
      },
      set(value) {
        this.$store.commit('updateRolename', value)
      }
    },
    username: {
      get() {
        return this.$store.getters.username
      },
      set(value) {
        this.$store.commit('updateUsername', value)
      }
    },
    userId: {
      get() {
        return this.$store.getters.userId
      },
      set(value) {
        this.$store.commit('updateUserId', value)
      }
    },
    activationDaysLeftCount: {
      get() {
        return this.$store.getters.activationDaysLeftCount
      },
      set(value) {
        this.$store.commit('updateActivationDaysLeftCount', value)
      }
    },
    reportDetailedBlock: {
      get() {
        return this.$store.getters.reportDetailedBlock
      },
      set(value) {
        this.$store.commit('updateReportDetailedBlock', value)
      }
    },
    reportGeneralBlock: {
      get() {
        return this.$store.getters.reportGeneralBlock
      },
      set(value) {
        this.$store.commit('updateReportGeneralBlock', value)
      }
    },
    reportConciseBlock: {
      get() {
        return this.$store.getters.reportConciseBlock
      },
      set(value) {
        this.$store.commit('updateReportConciseBlock', value)
      }
    },
    reportPatientBlock: {
      get() {
        return this.$store.getters.reportPatientBlock
      },
      set(value) {
        this.$store.commit('updateReportPatientBlock', value)
      }
    },
    reportGraphicBlock: {
      get() {
        return this.$store.getters.reportGraphicBlock
      },
      set(value) {
        this.$store.commit('updateReportGraphicBlock', value)
      }
    },
    permission: {
      get() {
        return this.$store.getters.permission
      },
      set(value) {
        this.$store.commit('updatePermission', value)
      }
    }
  },
  methods: {

    exitClick () {
      fetch(host + '/rest/session', {
        method: 'DELETE',
        credentials: 'include',
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            this.isAuthorized = false
            this.role = null
            this.activationDaysLeftCount = null
            this.$router.push('/')
            browser.cookies.remove( {
              name: 'JSESSIONID'
            })
          }).catch(error => {
        console.log(JSON.stringify(error))
      })

    }
  },
  mounted() {

    fetch(host + '/rest/session', {
      method: 'GET',
      credentials: 'include',
      headers: {'Content-Type': 'application/json; charset=utf-8'}
    }).then(response => response.json())
        .then(json => {
          if (json.resultCode == 0) {
            this.isAuthorized = true
            this.role = json.role
            this.rolename = roles.filter(t => t.id == this.role)[0].name
            this.username = json.username
            this.userId = json.userId
            this.activationDaysLeftCount = json.activationDaysLeftCount
            this.reportDetailedBlock = json.reportDetailedBlock
            this.reportGeneralBlock = json.reportGeneralBlock
            this.reportConciseBlock = json.reportConciseBlock
            this.reportPatientBlock = json.reportPatientBlock
            this.reportGraphicBlock = json.reportGraphicBlock
            this.permission = json.permission
            console.log('Role: ' + roles.filter(t => t.id == this.role)[0].name)
            //this.$router.push('/patients')
            console.log('Login Success ' + JSON.stringify(json))
          } else {

            console.log('Login Failed ' + json)
            this.isAuthorized = false
            this.role = 0
            this.$router.push('/')
          }
        }).catch(error => {
      console.log(JSON.stringify(error))
    })
  }

}
</script>

<style scoped>

</style>