<template>
  <b-container>
    <b-row class="justify-content-center"><span class="block-title">Были или есть у Ваших ближайших родственников (мать, отец) следующие заболевания?</span></b-row>
    <b-row class="justify-content-center"><span class="block-title">&nbsp;</span></b-row>
    <b-row>
      <b-form @submit.prevent="saveHeredity">
        <b-form-checkbox-group
            v-model="selected"
            :options="heredity"
            @change="saveHeredity"
            switches style="column-count: 3;"
            stacked
        ></b-form-checkbox-group>

<!--        <b-button type="submit" variant="info" >Сохранить</b-button>-->
      </b-form>
    </b-row>
  </b-container>
</template>

<script>
import {host} from "@/constants";

export default {
  props:["patientId"],
  name: "Heredity",
  data () {
    return {
      heredity: [],
      selected:[]
    }
  },
  methods: {
    saveHeredity() {
      fetch(host + '/rest/patients/' + this.patientId + '/dynamic/checklist/5', {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify(this.selected),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      })
          .then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {
              this.$messageToast('Данные о наследственных заболеваниях', 500)
            } else {
              this.$errorToast(json.resultCode + ' ' + json.message)
            }
          }).catch(error => {
            console.log(error)
            this.$errorToast('Неизвестная ошибка')
          })
    }
  },
  mounted() {
    fetch(host + '/rest/patients/' + this.patientId + '/dynamic/checklistbytype/5', {
      credentials: "include"
    })
        .then(response => response.json())
        .then(json => {
          this.heredity = json.options
          for (let el of this.heredity) {
            if (el.selected) {
              this.selected.push(el.value)
            }
          }
        })
  }
}
</script>

<style scoped>

</style>