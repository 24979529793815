<template>
  <b-container>
    <b-row>
      <b-col cols="6">
        <button class="save_btn_common w-100" @click="addAllParams()">Назначить все исследования</button>
        <button class="save_btn_common w-100" @click="addBaseParams()">Назначить базовые исследования</button>
      </b-col>
      <b-col cols="1"></b-col>
      <b-col cols="5" class="text-right">
        <button class="save_btn_common w-100" @click="getRecommendedParams(true)">Обновить список исследований</button>
        <button class="save_btn_common_orange w-100" @click="deleteRecommendedAnalits()">Очистить список исследований</button>
      </b-col>


    </b-row>

    <b-row><span style="font-size: 20px;"><strong>Базовые исследования</strong></span></b-row>
    <b-row v-for="(item, index) in mainRecommendedParams(recommendedParams)" :key="item.paramId">
      <b-col >
        <img src="@/assets/images/Icon_OMG_20x20.png" v-if="item.omsCode" >
<!--        <b-icon v-if="item.omsCode" variant="info" icon="award-fill" size="lg" ></b-icon>-->
      </b-col>
      <b-col cols="8">
        <b-icon :id="'param_q_def_' + item.paramId" icon="book"></b-icon>
        <b-tooltip  :target="'param_q_def_' + item.paramId" triggers="hover">
          <span v-html="item.paramDefinition"></span>
        </b-tooltip> &nbsp;
        <span class="priorityRed">{{ item.paramName }}, {{ item.biomaterialTypeName }}</span>
      </b-col>

      <b-col cols="2"><b-link @click="editClientAnalit(item)">Назначить</b-link></b-col>
      &nbsp;
<!--      <b-col cols="2"><b-link @click="updateParamAnalit(item, 2)">Сделать уточняющим</b-link></b-col>-->
      &nbsp;
      <b-col><b-link @click="deleteRecommendedAnalit(item)" title="Удалить"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link></b-col>
    </b-row>
    <b-row><span style="font-size: 20px;"><strong>Уточняющие исследования</strong></span></b-row>
    <b-row v-for="(item, index) in additinalRecommendedParams(recommendedParams)" :key="item.paramId">
      <b-col >
        <img src="@/assets/images/Icon_OMG_20x20.png" v-if="item.omsCode" >
<!--        <b-icon v-if="item.omsCode" variant="info" icon="award-fill" size="lg" ></b-icon>-->
      </b-col>
      <b-col cols="8">
        <b-icon :id="'param_q_def_' + item.paramId" icon="book"></b-icon>
        <b-tooltip  :target="'param_q_def_' + item.paramId" triggers="hover">
          <span v-html="item.paramDefinition"></span>
        </b-tooltip> &nbsp;
        <span class="priorityGreen">{{ item.paramName }}, {{ item.biomaterialTypeName }}</span>
      </b-col>

      <b-col cols="2"><b-link @click="editClientAnalit(item)">Назначить</b-link></b-col>
      &nbsp;
<!--      <b-col cols="2"><b-link @click="updateParamAnalit(item, 1)">Сделать базовым</b-link></b-col>-->
      &nbsp;
      <b-col><b-link @click="deleteRecommendedAnalit(item)" title="Удалить"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link></b-col>
    </b-row>

  </b-container>
</template>

<script>
import {host} from "@/constants";

export default {
  props: ["patientId"],
  name: "PatientQuestionnaireRecommendations",
  data() {
    return {
      recommendedParams: []
    }
  },
  methods: {
    async updateParamAnalit(item, checkListParamState) {
      this.loading = true
      try {
        let response = await fetch(host + '/rest/patients/' + this.patientId + '/recommendedparams/' + item.paramId + '/' + checkListParamState , {
          method: 'PUT',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        })
        let json = await response.json()
        if (json.resultCode === 0) {
          item.checkListParamState = checkListParamState
        }
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    mainRecommendedParams(recommendedParams) {
      if (recommendedParams) {
        return recommendedParams.filter(el => (el.checkListParamState === 1))
      }
      return recommendedParams
    },
    additinalRecommendedParams(recommendedParams) {
      if (recommendedParams) {
        return recommendedParams.filter(el => (el.checkListParamState === 2))
      }
      return recommendedParams
    },
    editClientAnalit(item = null) {
      this.$emit('selectParam', item)

      // this.currentAnalit = item
      // this.$bvModal.show('createClientReportParamModal')
    },
    addAllParams() {
      this.$emit('selectParams', this.recommendedParams)
    },
    addBaseParams() {
      this.$emit('selectParams', this.mainRecommendedParams(this.recommendedParams))
    },
    async deleteRecommendedAnalits() {
      this.loading = true
      try {
        let response = await fetch(host + '/rest/patients/' + this.patientId + '/recommendedparams', {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        })
        await response.json()
      } catch (e) {
        console.log(e)
      }
      this.loading = false
      this.recommendedParams.splice(0, this.recommendedParams.length)
    },
    async deleteRecommendedAnalit(item) {
      this.loading = true
      try {
        let response = await fetch(host + '/rest/patients/' + this.patientId + '/recommendedparams/' + item.paramId, {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        })
        await response.json()
      } catch (e) {
        console.log(e)
      }
      this.loading = false
      this.recommendedParams.splice(this.recommendedParams.indexOf(item), 1)
    },
    getRecommendedParams(renew = false) {
      fetch(host + '/rest/patients/' + this.patientId + '/recommendedparams' + (renew ? '/renew' : ''), {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.recommendedParams = json.params
            this.loading = false
          }).catch(error => {
        console.log(error)
        this.loading = false
      })
    }
  },
  mounted() {
    this.getRecommendedParams(false)
  }
}
</script>

<style scoped>
.priorityRed {
  color: #e17f27;
}

.priorityGreen {
  /*color: #0f6667;*/
  color: #039022;
}

.priorityBlack {
  color: #282828;
}
</style>