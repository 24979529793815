<template>
    <div>
        <div v-if="error" class="alert alert-danger">
            {{error}}
        </div>
        <b-form @submit.prevent="onSubmit">
            <b-form-group id="ig1" label-for="login" label="Логин">
                <b-form-input id="login" type="text" placeholder="Введите логин" v-model="form.login" autocomplete="off"></b-form-input>
            </b-form-group>

            <b-form-group id="ig2" label-for="password" label="Пароль">
                <b-form-input id="login" type="password" placeholder="Введите пароль" v-model="form.password" autocomplete="off"></b-form-input>
            </b-form-group>

            <b-button type="submit" class="btn-info" variant="primary">Войти</b-button>

        </b-form>
    </div>
</template>

<script>

  export default {
    props: ['error'],
    name: "LoginForm",
    data() {
      return {
        form: {
          login: '',
          password: ''
        }
      }
    },
    methods: {
      onSubmit(){
        this.$emit('submit-form', this.form)
      }
    }

  }
</script>

<style scoped>

</style>