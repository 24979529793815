<template>
  <div>
    <div v-if="error" class="alert alert-danger">
      {{error}}
    </div>
    <b-form @submit.prevent="onSubmit">

      <b-form-group id="ig2" label-for="paramValue" label="Вариант ответа">
        <b-form-input id="paramValue" type="text" required placeholder="Введите значение" v-model="mu.text" autocomplete="off"></b-form-input>
      </b-form-group>

<!--      <b-form-group id="ig2" label-for="measurementUnitId" label="Классфикация ответа">-->
<!--        <b-form-select id="measurementUnitId" required v-model="mu.propertyValueTypeId" placeholder="Выберите единицу измерения" :options="propValueTypeOptions"-->
<!--                       :select-size="1"></b-form-select>-->
<!--      </b-form-group>-->
<!--      <b-form-group id="ig2" label-for="paramValue" label="Синонимы">-->
<!--        <b-form-input id="paramValue" type="text" placeholder="Введите значение" v-model="mu.propertyValueNameSynonym"></b-form-input>-->
<!--      </b-form-group>-->
<!--      <b-form-group id="ig2" label-for="paramValue" label="Комментарий">-->
<!--        <b-form-input id="paramValue" type="text" placeholder="Введите значение" v-model="mu.reportText"></b-form-input>-->
<!--      </b-form-group>-->
      <b-form-group id="ig2" label-for="zoneId" label="Классификация ответа">
        <b-form-select id="zoneId" v-model="mu.zoneId" placeholder="" @change="zoneOptionsChanges" :options="zoneOptions"
                       :select-size="1"></b-form-select>
      </b-form-group>

      <b-form-group id="propertyValueNameSynonym" label-for="propertyValueNameSynonym" label="Шаблон синонимов для качественной шкалы">
        <b-textarea rows="5" max-rows="7" id="propertyValueNameSynonym" v-model="mu.propertyValueNameSynonym"></b-textarea>
      </b-form-group>

      <div v-if="paramType === 7">
        <b-row class="newAttr newAttrAfter">
          <b-col cols="3">
            <label for="startValue">Диапазон: От</label>
          </b-col>
          <b-col cols="4">
            <b-form-input v-mask="vMask" id="startValue" name="startValue"
                          type="text"
                          v-model="mu.startValue" autocomplete="off"></b-form-input>
          </b-col>
          <b-col cols="1">
            <label for="finishValue">До</label>
          </b-col>
          <b-col cols="4">
            <b-form-input v-mask="vMask" id="finishValue" name="finishValue" type="text"
                          v-model="mu.finishValue" autocomplete="off"></b-form-input>
          </b-col>
        </b-row>

        <b-form-group id="measurementUnitId" label-for="measurementUnitId" label="Единицы измерения">
            <b-form-select id="measurementUnitId" v-model="mu.measurementUnitId" :options="measureOptions"
                           :select-size="1"></b-form-select>
        </b-form-group>

        <b-form-group id="ig2" label-for="titres" label="Титры">
          <b-form-input id="titres" type="text" placeholder="Введите значение" v-model="mu.titres" autocomplete="off"></b-form-input>
        </b-form-group>

        <b-form-group id="plusMinus" label-for="plusMinus" label="Кресты">
          <b-form-select id="plusMinus" v-model="mu.plusMinus" :options="plusMinusOptions"
                         :select-size="1"></b-form-select>
        </b-form-group>

      </div>

      <b-button type="submit" class="btn-info" variant="primary">Сохранить</b-button>

    </b-form>
  </div>
</template>

<script>
import {host} from "@/constants";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const valueMask = createNumberMask({
  allowDecimal: true,
  includeThousandsSeparator: false,
  allowNegative: true,
  decimalSymbol: '.',
  decimalLimit: 4,
  prefix: '',
  suffix: ''
})
export default {
  name: "AnalitPropertyValueEdit",
  props: {
    analitId: String,
    currentRubricator: Object,
    currentRubricatorValue: Object,
    paramType: Number
  },
  data() {
    return {
      error: null,
      vMask: valueMask,
      propValueTypeOptions: [],
      zoneOptions: [],
      measureOptions: [],
      plusMinusOptions: [
        {value: null, text: ""},
        {value: "-", text: "-"},
        {value: "+/-", text: "+/-"},
        {value: "+", text: "+"},
        {value: "++", text: "++"},
        {value: "+++", text: "+++"}
      ],
      mu: this.currentRubricatorValue ? Object.assign({}, this.currentRubricatorValue) : {
        value: null,
        text: null,
        propertyValueTypeId: null,
        propertyValueNameSynonym: null,
        reportText: null,
        zoneId: null,
        startValue: null,
        finishValue: null,
        measurementUnitId: null,
        measurementUnitName: null,
        titres: null,
        plusMinus: null
      }
    }
  },
  methods: {
    zoneOptionsChanges(newVal) {
      if (!this.currentRubricatorValue) {
        let result = this.zoneOptions.find(item => item.value == newVal)
        console.log(result)
        this.mu.propertyValueNameSynonym = result.propertyValueNameSynonym
      }
    },
    onSubmit() {
      let method = 'POST'
      if (this.currentRubricatorValue !== null) {
        method = 'PUT'
      }

      console.log(JSON.stringify(this.mu))
      fetch(host + '/rest/params/' + this.analitId+ '/properties/' + this.currentRubricator.propId + '/values', {
        method: method,
        credentials: 'include',
        body: JSON.stringify(this.mu),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {
              console.log('Save Success ' + JSON.stringify(json))
              this.$emit('save-success')
            } else {
              console.log('Save Failed ' + json)
              this.error = json.resultCode + ' ' + json.message;
            }
          })
          .catch(error => {
            this.error = error
            console.log(JSON.stringify(error))
          })

    },
    fetchDicts() {
      fetch(host + '/rest/dicts/propertyvaluetype')
          .then(response => response.json())
          .then(json => {
            this.propValueTypeOptions = json.options
          })
      fetch(host + '/rest/dicts/zone/' + this.paramType )
          .then(response => response.json())
          .then(json => {
            this.zoneOptions = json.options
          })
      fetch(host + '/rest/dicts/measurementunit')
          .then(response => response.json())
          .then(json => {
            this.measureOptions = json.options
          })
    },
  },
  mounted() {
    this.fetchDicts()
  }
}
</script>

<style scoped>

.newAttr {
  padding-top: 1rem;
}
.newAttrAfter {
  padding-bottom: 1rem;
}

</style>
