<template>
  <b-container>
    <b-row>
      <b-col>
        <br />
        <b-form-checkbox id="localNotAllowToChangeRD" switch v-model="notAllowToChangeRD" @change="changeLocalNotAllowToChangeRD">
          Зафиксировать референтные диапазоны
        </b-form-checkbox>
        <br />
        <br />
        <b-button variant="info" @click="editParamScaleMainValue()"><b-icon icon="plus" aria-hidden="true" ></b-icon> Добавить</b-button>
        &nbsp;
        <b-button variant="info" v-if="paramType===1 || paramType===3 || paramType===10" @click="calculateRD()">Пересчет РД</b-button>
        <br /><br />
        <b-table striped hover :items="items" :fields="fields">

          <template v-slot:cell(ageFrom)="data">
            <span :inner-html.prop="data.item | ageText(data.item.startYear,  data.item.startMonth, data.item.startDay)"></span>
          </template>

          <template v-slot:cell(ageTo)="data">
            <span :inner-html.prop="data.item | ageText(data.item.finishYear,  data.item.finishMonth, data.item.finishDay)"></span>
          </template>

          <template v-slot:cell(valueFrom1)="data">
            <span :inner-html.prop="data.item.startValue | valueText1(data.item.measurementUnitName, data.item.finishValue)"></span>
          </template>

          <template v-slot:cell(womanHealth)="data">
            <span>
              {{data.item.menstrualCyclePhaseName ? 'Фаза цикла: ' + data.item.menstrualCyclePhaseName : ''}}<br v-if="data.item.menstrualCyclePhaseId">
              {{data.item.postpartumWoman ? 'Родильница' : ''}}<br v-if="data.item.postpartumWoman">
              {{data.item.postmenopause ? 'Постменопауза' : ''}}<br v-if="data.item.postmenopause">
              {{data.item.pregnant ? 'Неделя беременности: ' + (data.item.startPregnancyWeek ? 'от ' + data.item.startPregnancyWeek : '') + (data.item.finishPregnancyWeek ? ' до ' + data.item.finishPregnancyWeek : '')  : ''}}
            </span>
          </template>

          <template v-slot:cell(buttons)="data">
            <h3 class="buttons">
              <nobr>
                <b-link @click="showDetails(data)" ><b-icon variant="info" icon="file-text" size="lg" ></b-icon></b-link>

                <b-link @click="editParamScaleMainValue(data.item)" ><b-icon variant="info" icon="pencil" size="lg" ></b-icon></b-link>
                &nbsp;
                <b-link @click="deleteRubricatorMainValue(data.item.paramScaleId)"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link>
              </nobr>
            </h3>
          </template>

          <template #row-details="data">
            <b-overlay spinner-variant="info" :opacity="0.5" :show="overlayShow">
            <b-card>
              <b-container v-if="!overlayShow || data.item.details">
                <b-row><strong>Детальная информация: <span v-if="!data.item.details || data.item.details.length === 0"> Отсутствует</span></strong></b-row>
                <b-row class="newAttr" v-for="(d) in data.item.details" :key="d.paramScaleDetailId">
                  <b-col><strong>{{d.startValue}}</strong> - <strong>{{d.finishValue}}</strong> {{d.measurementUnitName}}</b-col>
                  <b-col>{{d.zoneName}}</b-col>
                </b-row>

              </b-container>
            </b-card>
            </b-overlay>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-modal id="paramScaleMainValueModal" hide-footer size="lg" :title="'Параметры'">
      <ParamScaleEdit @save-success="afterSaveMain" v-bind:analit-id="this.analitId" v-bind:param-scale="currentParamScale" v-bind:scale-id="mainScaleId"></ParamScaleEdit>
    </b-modal>
  </b-container>
</template>

<script>
import {host} from "@/constants";
import ParamScaleEdit from "@/components/admin/scales/ParamScaleEdit";

export default {
  components: {ParamScaleEdit},
  props: ["analitId", "paramType", "notAllowToChangeRD"],
  name: "ParamScaleMainList",
  data() {
    return {
      items:[],
      mainScaleId: 1,
      overlayShow: true,
      fields: [
        // {
        //   key: 'scaleName',
        //   label: 'Шкала',
        //   sortable: false
        // },
        {
          key: 'sexName',
          label: 'Пол',
          sortable: false
        },
        {
          key: 'ageFrom',
          label: 'Возраст От',
          sortable: false
        },
        {
          key: 'ageTo',
          label: 'Возраст До',
          sortable: false
        },
        {
          key: 'valueFrom1',
          label: 'Референтный диапазон',
          sortable: false
        },
        {
          key: 'womanHealth',
          label: 'Состояние женского здоровья',
          sortable: false
        },
        // {
        //   key: 'valueFrom',
        //   label: 'С',
        //   sortable: false
        // },
        // {
        //   key: 'valueTo',
        //   label: 'По',
        //   sortable: false
        // },
        // {
        //   key: 'reportText',
        //   label: '',
        //   sortable: false
        // },
        // {
        //   key: 'zoneName',
        //   label: 'Интервал',
        //   sortable: false
        // },
        {
          key: 'buttons',
          label: ' ',
          sortable: false
        }
      ],
      currentParamScale: null
    }
  },
  methods: {
    changeLocalNotAllowToChangeRD(checked) {
      this.$emit('notAllowToChangeRDChanged', checked)
    },
    convertAgeToText(year, month, day) {

    },
    showDetails(data) {
      this.overlayShow = true
      data.toggleDetails()

      if (!data.item.details) {
        fetch(host + '/rest/params/' + this.analitId + '/scales/1/' + data.item.paramScaleId + '/details', {
          credentials: "include"
        })
            .then(response => response.json())
            .then(json => {
              data.item.details = json.paramScaleDetails
              console.log(json)
              this.overlayShow = false
            }).catch(error => {
              this.overlayShow = false
            })
      } else {
        this.overlayShow = false
      }
    },
    calculateRD() {
      fetch(host + '/rest/params/calc/' + this.analitId, {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {

            this.$bvToast.toast(json.resultCode + ' ' + json.message, {
              title: (json.resultCode === 0? 'Результат' : 'Ошибка'),
              toaster: 'b-toaster-top-center',
              solid: true,
              variant: (json.resultCode === 0? 'info' : 'danger'),
              noAutoHide: true,
              appendToast: true
            })
            this.$emit('after-recalc')
          }).catch(error => {
            this.error = error
            this.$bvToast.toast(error, {
              title: 'Ошибка',
              toaster: 'b-toaster-top-center',
              solid: true,
              variant: 'danger',
              noAutoHide: true,
              appendToast: true
            })
      })
    },

    fetchData() {
      fetch(host + '/rest/params/' + this.analitId + '/scales/1', {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.items = json.paramScales
            this.loading = false
            console.log(json)
          })
    },
    afterSaveMain() {
      this.$bvModal.hide('paramScaleMainValueModal')
      this.loading = true
      this.fetchData()
    },
    editParamScaleMainValue(item = null) {
      if (item) {
        this.currentParamScale = Object.assign({}, item)
      } else {
        this.currentParamScale = null
      }
      this.$bvModal.show('paramScaleMainValueModal')
    },
    deleteRubricatorMainValue(paramId) {
      this.error = null;
      if (confirm('Вы уверены, что хотите удалить интервальную шкалу?')) {
        this.loading = true;
        fetch(host + '/rest/params/' + this.analitId + '/scales/' + paramId, {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {
                this.items = this.items.filter(item => item.paramScaleId !== paramId)

                console.log('Delete Success ' + json)

              } else {

                console.log('Delete Failed ' + json)
                this.error = json.message;
              }
              this.loading = false

            }).catch(error => {
          this.error = error
          console.log(JSON.stringify(error))
          this.loading = false
        })
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  filters: {
    valueText1: function (value, unitName, finishValue) {
      if (value && finishValue) {
        return '<b>' + value + '</b> - <b>' + finishValue + '</b> ' + unitName
      } else if (value) {
        return 'выше <b>' + value + '</b> ' + unitName
      } else if (finishValue) {
        return 'до <b>' + finishValue + '</b> ' + unitName
      }
      return '-'
    },
    valueText: function (value, unitName) {
      if (value) {
        return '<b>' + value + '</b> ' + unitName
      }
      return '-'
    },
    ageText: function (item, year, month, day) {
      let resultText = ''
      if (year && year > 0) {
        resultText += '<b>' + year + '</b>'
        if (year >=5 && year<=20) {
          resultText += ' лет '
        } else {
          const modyear = year % 10
          switch (modyear) {
            case 1:
              resultText += ' год '
              break
            case 2:
            case 3:
            case 4:
              resultText += ' года '
              break
            default:
              resultText += ' лет '
          }
        }
      }
      if (month && month > 0) {
        resultText += '<b>' + month + '</b>'
        if (month >= 5 && month <= 20) {
          resultText += ' месяцев '
        } else {
          const modyear = month % 10
          switch (modyear) {
            case 1:
              resultText += ' месяц '
              break
            case 2:
            case 3:
            case 4:
              resultText += ' месяца '
              break
            default:
              resultText += ' месяцев '
          }
        }
      }
      if (day && day > 0) {
          resultText += '<b>' + day + '</b>'
          if (day >=5 && day<=20) {
            resultText += ' дней '
          } else {
            const modyear = day % 10
            switch (modyear) {
              case 1:
                resultText += ' день '
                break
              case 2:
              case 3:
              case 4:
                resultText += ' дня '
                break
              default:
                resultText += ' дней '
            }
          }
      }

      return resultText
    }
  }
}
</script>

<style scoped>
h3.buttons {
  text-align: -webkit-right!important;
  text-align: -moz-right!important;
  text-align: right!important;
}
.newAttr {
  padding-top: 1rem;
}
</style>