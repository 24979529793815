<template>
  <div class="container-fluid">
    <b-row>
      <h3>Настройка отчётов</h3>
    </b-row>
    <b-tabs>
      <b-tab title="Настройка обобщенных отчетов">
        <ReportSettings report-type="2" />
      </b-tab>
      <b-tab title="Настройка сокращенных отчетов">
        <ReportSettings report-type="3" />
      </b-tab>
      <b-tab title="Настройка графических отчетов">
        <ReportSettings report-type="5" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import ReportSettings from "./ReportSettings";
export default {
  name: "ReportSettingsContainer",
  components: {ReportSettings}
}
</script>

<style scoped>

</style>