<template>
  <div>
    <div v-if="error" class="alert alert-danger">
      {{error}}
    </div>
    <b-form @submit.prevent="onSubmit">

      <b-form-group id="formulaNameLabel" label-for="formulaName" label="Название формулы">
        <b-form-input id="formulaName" type="text" required placeholder="Введите значение" v-model="mu.formulaName" autocomplete="off"></b-form-input>
      </b-form-group>

      <b-form-group id="formulaTextLabel" label-for="formulaText" label="Формула">
        <ckeditor v-model="mu.formulaText"
                  :editor="editor" tag-name="textarea"></ckeditor>
      </b-form-group>

      <b-button type="submit" class="btn-info" variant="primary">Сохранить</b-button>

    </b-form>
  </div>
</template>

<script>
import {host} from "@/constants";

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


export default {
  name: "RuleEdit",
  props: {
    currentMeasurementUnit: Object
  },
  data() {
    return {
      editor: ClassicEditor,
      error: null,
      mu: this.currentMeasurementUnit !== null ? this.currentMeasurementUnit : {
        formulaId: 0,
        formulaName: null,
        formulaText: null
      }
    }
  },
  methods: {
    onSubmit() {
      let method = 'POST'
      if (this.currentMeasurementUnit !== null) {
        method = 'PUT'
      }


      fetch(host + '/rest/warehouse/formulas', {
        method: method,
        credentials: 'include',
        body: JSON.stringify(this.mu),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {

              this.$emit('save-success')
            } else {

              this.error = json.resultCode + ' ' + json.message;
            }
          })
          .catch(error => {
            this.error = error

          })

    }
  }
}
</script>

<style scoped>

</style>