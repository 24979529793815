<template>
  <b-container>
    <b-row>
      <span class="badgeSelected" v-for="(item, index) in patientDrugs" :key="item.drugPatientId">
        <h4>
          <b-badge variant="success" size="lg">{{ item.drugPatientName }} &nbsp;
            <b-icon style="cursor: pointer;" size="md" icon="x-circle" aria-hidden="true" @click="deleteFromSelected(index, item.drugPatientId, item.drugPatientName)" title="Удалить"></b-icon>
          </b-badge>
        </h4>
      </span>
    </b-row>
    <b-row>
      <treeselect
                  name="param" id="param" placeholder=""
                  clearable searchable
                  open-on-click close-on-select disableFuzzyMatching
                  :noResultsText="'Не найдено...'"
                  :noOptionsText="'Список опций пуст'"
                  :load-options="loadOptions"
                  :async="true"
                  :max-height="200"
                  :normalizer="normalizeTreeselect"
                  v-model="selected" style="font-size: 15px!important;" @select="selectDrug" >
        <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName">
          {{node.label}}
        </label>
        <label slot="value-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName">
          {{node.label}}
        </label>
      </treeselect>

    </b-row>
<!--    <b-form-group id="drugPatient" label-for="firstname">-->


<!--      <b-input-group>-->
<!--        <b-form-input id="drugPatient" list="drugPatientDataList" type="text" v-model="patientDrugName" placeholder="Начните вводить название лекарства" @keypress="selectDrugKey"></b-form-input>-->
<!--        <b-input-group-append>-->
<!--          <b-button @click="selectDrug" variant="info">Добавить</b-button>-->
<!--        </b-input-group-append>-->
<!--      </b-input-group>-->

<!--      <datalist id="drugPatientDataList" @click="selectDrug">-->
<!--        <option v-for="item in drugsOptions" > {{ item.text }}</option>-->
<!--      </datalist>-->
<!--    </b-form-group>-->

  </b-container>
</template>

<script>
import {host} from "@/constants";
import Treeselect from "@riophae/vue-treeselect";
import { ASYNC_SEARCH } from '@riophae/vue-treeselect'

export default {
  props:["patientId"],
  name: "PatientDrugs",
  components: {
    Treeselect
  },
  data() {
    return {
      selected: null,
      patientDrugs:[],
      drugsOptions: [],
      drugsOptionsFiltered: [],
      patientDrugName: null,
      childrenSearchQuery: null,
      normalizeTreeselect(node) {
        return {
          id: node.value,
          label: node.text
        }
      },
    }
  },
  filters: {
    nodeLabelFilter(value) {
      if (value) {
        return value.substring(0, value.indexOf(';;_;;'))
      }
    }
  },
  methods: {
    loadOptions({ action, searchQuery, callback }) {
      if (action === ASYNC_SEARCH) {
        if (searchQuery && searchQuery.length > 2) {
          let options = this.drugsOptions.filter((el, index, []) => {
            return ((searchQuery) && (el.text).toLowerCase().startsWith(searchQuery.toLowerCase()) )
          })
          options = options.concat(this.drugsOptions.filter((el, index, []) => {
            return ((searchQuery)
                && (el.text).toLowerCase().includes(searchQuery.toLowerCase())
                && !(el.text).toLowerCase().startsWith(searchQuery.toLowerCase())
            )
          }))
          options = options.concat(this.drugsOptions.filter((el, index, []) => {
            return ((searchQuery)
                && (el.searchString).toLowerCase().includes(searchQuery.toLowerCase())
                && !(el.text).toLowerCase().includes(searchQuery.toLowerCase())
            )
          }))
          callback(null, options)
        } else {
          callback(null, [])
        }
      }
    },
    selectDrug(event) {
      console.log('event = ' + JSON.stringify(event))

      let newDrug = {drugPatientName: event.text, drugId: event.value }

      fetch(host + '/rest/patients/' + this.patientId + '/drugs', {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(newDrug),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      })
          .then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {
              this.$messageToast('Заболевание сохранено', 500)
              newDrug.drugPatientId = json.entityId
              this.patientDrugs.push(newDrug)
              this.selected = null
            } else {
              this.$errorToast(json.resultCode + ' ' + json.message)
            }
          }).catch(error => {
        console.log(error)
        this.$errorToast('Неизвестная ошибка')
      })

      this.patientDrugName = null
    },
    fetchPatientDrugs() {
      fetch(host + '/rest/patients/' + this.patientId + '/drugs', {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.patientDrugs = json.patientDrugs

          })
    },
    fetchDrugs() {
      fetch(host + '/rest/dicts/drug', {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.drugsOptions = json.options

          })
    },
    deleteFromSelected(index, id, name) {
      if (confirm('Вы уверены, что хотите удалить "' + name + '"?')) {

        fetch(host + '/rest/patients/' + this.patientId + '/drugs/' + id, {
          method: 'DELETE',
          credentials: 'include'
        })
            .then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {
                this.$messageToast('Удаление заболевания', 500)
                this.patientDrugs.splice(index, 1)
              } else {
                this.$errorToast(json.resultCode + ' ' + json.message)
              }
            }).catch(error => {
              console.log(error)
              this.$errorToast('Неизвестная ошибка')
            })
      }
    }
  },
  mounted() {
    this.fetchDrugs()
    this.fetchPatientDrugs()
  }
}
</script>

<style scoped>

</style>