<template>
  <b-container fluid>

    <b-tabs

            content-class="mt-3 " nav-class=" color_606D6D font-weight-bold" style="width: 100%;"
            active-nav-item-class="background_E9F5EA_page_7 tab-link__active"
            nav-item-class="color_606D6D"
             justified>
      <b-tab title="Назначения" title-link-class="tab-link"  class="p-0 m-0">
        <PrescribedBlanksTable v-bind:patient-id="patientId"></PrescribedBlanksTable>
      </b-tab>
      <b-tab title="Исследования" title-link-class="tab-link"  v-if="role === 2">
        <b-table v-if="role === 2" striped hover :items="reportParams" :fields="fields" :tbody-tr-class="rowClass" class="mt-4 ml-0 mr-0 h-100">
          <template v-slot:head(paramFullName)="data">
            <div class="column-head vertical-center">Наименование аналита</div>
          </template>
          <template v-slot:head(examinationDate)="data">
            <div class="column-head vertical-center">Дата ввода исследования</div>
          </template>
          <template v-slot:head(val)="data">
            <div class="column-head vertical-center">Результат</div>
          </template>
          <template v-slot:head(paramReport)="data">
            <div class="column-head vertical-center">Интерпретация</div>
          </template>

          <!-- A custom formatted column -->
          <template v-slot:cell(examinationDate)="data">
            <div class="vertical-center-container"><span class="vertical-center" >{{ new Date(data.item.examinationDate).toLocaleDateString() }}</span></div>
          </template>
          <template v-slot:cell(paramFullName)="data">
            <div class="vertical-center">{{data.item.paramName}}{{data.item.biomaterialTypeName ? ', ' + data.item.biomaterialTypeName : '' }}</div>
          </template>
          <template v-slot:cell(val)="data">
              <span class="vertical-center" v-if="data.item.paramType === 2 || (data.item.paramType === 7 && data.item.propertyName)">
                <b :class="'div-' + rowClass(data.item, 'row')">{{ data.item.propertyName }}, {{ data.item.propertyValueName }}</b>
              </span>
              <span class="vertical-center" v-else>
                <b :class="'div-' + rowClass(data.item, 'row')">{{ data.item.paramValue }} {{ data.item.measurementUnitName }}</b>
              </span>
          </template>
          <template v-slot:cell(paramReport)="data">
            <div class="vertical-center"><span v-html="data.item.paramReport" ></span></div>
          </template>
          <template v-slot:cell(buttons)="data">
            <div class="vertical-center-container">
              <nobr>

<!--                <b-link v-if="data.item.examinationFileId" target="_blank" :to="'/rest/recognition/' + patientId + '/pdf/' + data.item.examinationFileId"><b-icon variant="info" icon="box-arrow-in-down" size="lg" ></b-icon></b-link>-->
                              <b-link v-if="(role == 2 || role == 3) && !permission.reportPreBlock" @click="openAnalitPdfReport(data.item)" title="Показать отчет"><b-icon variant="danger" icon="file-earmark-medical" size="lg" ></b-icon></b-link>
<!--                <b-link @click="editClientAnalit(data.item)" ><b-icon variant="info" icon="pencil" size="lg" ></b-icon></b-link>-->
<!--                <b-link @click="deleteClientAnalit(data.item.paramClientId)"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link>-->
              </nobr>
            </div>
          </template>
        </b-table>
      </b-tab>
      <b-tab title="Файлы исследований" title-link-class="tab-link"  title-item-class="mr-0">
        <b-container fluid>
          <b-row v-if="role === 1">
            <b-col cols="3">Выберите файл с исследованием в формате PDF</b-col>
            <b-col cols="6">
              <b-form-file
                  v-model="fileToRecognize"
                  :state="Boolean(fileToRecognize)"
                  placeholder="Выберите файл на диске или перетащите..."
                  drop-placeholder="Перетащите файлы..."
                  browse-text="Обзор"
              ></b-form-file>
            </b-col>
            <!--      <b-col cols="3">-->
            <!--        <b-button class="btn-info" :disabled="!Boolean(fileToRecognize)" @click="sendFileToRecognize()">Загрузить исследование<b-spinner v-if="saving" variant="light" small></b-spinner></b-button>-->
            <!--      </b-col>-->

          </b-row>
          <b-row v-if="role === 1">
            <b-col cols="3">Бланк назначений</b-col>
            <b-col cols="6">
              <b-form-select id="biomaterialType" v-model="selectedBlank" :options="blankOptions" value-field="prescribedBlankId" text-field="prescribedBlankName"
                             :select-size="1"></b-form-select>
            </b-col>
            <b-col cols="3">
              <button class="save_btn_common" :disabled="!Boolean(fileToRecognize)" @click="sendFileToRecognize()">Загрузить исследование<b-spinner v-if="saving" variant="light" small></b-spinner></button>
            </b-col>

          </b-row>
          <b-row>
            <div v-if="modelResult && role === 2">
              <div><strong>{{modelResult.research_name}}</strong></div>
              <div v-for="(item, index) in modelResult.probes" :key="index">
                {{item.name}} : {{item.value}} {{item.unit}}
              </div>
            </div>
          </b-row>
          <b-row>
            <hr>
          </b-row>
          <b-row>
            <b-table striped hover :items="files" :fields="fileFields" :tbody-tr-class="'table-row'">
              <template v-slot:cell(createDate)="data">
                {{ new Date(data.item.createDate).toLocaleDateString() }}
              </template>
              <template v-slot:cell(examinationFileName)="data">
                <b-link target="_blank" :to="'/rest/recognition/' + patientId + '/pdf/' + data.item.examinationFileId">{{data.item.examinationFileName}}</b-link>
              </template>
              <template v-slot:cell(buttons)="data">
                <h3>
                  <nobr>

                    <!--              <b-link @click="openAnalitPdfReport(data.item)" ><b-icon variant="danger" icon="file-earmark-medical" size="lg" ></b-icon></b-link>-->
                    <!--              <b-link @click="editClientAnalit(data.item)" ><b-icon variant="info" icon="pencil" size="lg" ></b-icon></b-link>-->
                    <b-link @click="deleteClientAnalit(data.item.examinationFileId)" title="Удалить"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link>
                  </nobr>
                </h3>
              </template>
            </b-table>
          </b-row>
        </b-container>
      </b-tab>
    </b-tabs>


  </b-container>
</template>

<script>
import {host} from "@/constants";
import PrescribedBlanksTable from "@/components/patients/PrescribedBlanksTable";

export default {
  components: {PrescribedBlanksTable},
  props: ["patientId"],
  name: "ExaminationFiles",
  computed: {
    role: {
      get() {
        return this.$store.getters.role
      }
    },
    permission: {
      get() {
        return this.$store.getters.permission
      }
    }
  },
  data() {
    return {
      fileToRecognize: null,
      selectedBlank: null,
      blankOptions: [],
      modelResult: null,
      saving: false,
      files: [],
      reportParams: [],
      fileFields: [
        {
          key: 'createDate',
          label: 'Дата создания'
        },
        {
          key: 'examinationFileName',
          label: 'Имя файла'
        },
        {
          key: 'prescribedBlankName',
          label: 'Бланк назначений'
        },
        {
          key: 'buttons',
          label: ''
        }

      ],
      fields: [
        {
          key: 'examinationDate',
          label: 'Дата ввода исследования',
          thClass: 'table-header h-100',
          tdClass: 'h-100'
        },
        {
          key: 'paramFullName',
          label: 'Наименование аналита',
          thClass: 'table-header h-100',
          tdClass: 'h-100'
        },
        {
          key: 'val',
          label: 'Результат',
          thClass: 'table-header h-100',
          tdClass: 'h-100'
        },
        {
          key: 'paramReport',
          label: 'Интерпретация',
          thClass: 'table-header h-100',
          tdClass: 'h-100'
        },
        {
          key: 'buttons',
          label: '',
          thClass: 'table-header h-100',
          tdClass: 'h-100'
        }
      ]
    }
  },
  methods: {
    rowClass(item, type = 'row') {
      if (!item || type !== 'row') return
      if (item.zoneId === 6 || item.zoneId === 16 || item.zoneId === 19 || item.zoneId === 25) return 'table-row-good'
      else return 'table-row'
    },
    openAnalitPdfReport(item = null) {
      let reportType = 'AnalitDoctor'
      if (item.paramType === 2) {
        reportType = 'QualAnalitDoctor'
      } else if (item.paramType === 7) {
        reportType = "SemiQuanAnalitDoctor"
      }
      window.open(host + "/rest/reports/" + reportType + "/patient/" + this.patientId + "/analit/" + item.paramClientId , "_blank");

    },
    deleteClientAnalit(examinationFileId) {
      this.error = null;
      if (confirm('Вы уверены, что хотите удалить файл?')) {

        fetch(host + '/rest/recognition/' + this.patientId + '/pdf/' + examinationFileId, {
          method: 'DELETE',
          credentials: 'include'
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {
                this.files = this.files.filter(item => item.examinationFileId !== examinationFileId)

              } else {

                this.$errorToast(json.message);
              }

            }).catch(error => {
              this.$errorToast(error)
              console.log(JSON.stringify(error))

            })
      }

    },
    sendFileToRecognize() {
      this.saving = true
      console.log((this.fileToRecognize))

      if (this.fileToRecognize && this.fileToRecognize.type === 'application/pdf') {

        var reader  = new FileReader();
        var curThis = this

        reader.onloadend = function () {

          console.log(reader.result)
          let rq = {blankId: curThis.selectedBlank, name: curThis.fileToRecognize.name, type:curThis.fileToRecognize.type, content: reader.result.substring(reader.result.indexOf('base64,') + 7 )}
          console.log(JSON.stringify(rq))

          fetch(host + '/rest/recognition/' + curThis.patientId, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(rq),
            headers: {'Content-Type': 'application/json; charset=utf-8'}
          }).then(response => response.json())
              .then(json => {
                console.log('Response ' + JSON.stringify(json))
                curThis.modelResult = json.modelResult
                curThis.saving = false
                curThis.fetchData()
              }).catch(error => {

            console.log(error)
            curThis.saving = false
          })

        }

        reader.readAsDataURL(this.fileToRecognize);

      }
    },
    fetchData() {
      fetch(host + '/rest/recognition/' + this.patientId, {
        method: 'GET',
        credentials: 'include'
      }).then(response => response.json())
          .then(json => {
            console.log('Response ' + JSON.stringify(json))
            this.files = json.files
          }).catch(error => {
            console.log(error)
          })

      fetch(host + '/rest/params/clientreport/' + this.patientId, {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.reportParams = json.reportParams
            this.loading = false
            console.log(JSON.stringify(json))
          }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    fetchPrescribedBlanks() {
      fetch(host + '/rest/patients/' + this.patientId + '/prescribes', {
        credentials: "include",
        method: 'GET'
      })
          .then(response => response.json())
          .then(json => {


            if (json.resultCode === 0) {
              //this.prescribedBlanks = json.blanks

              this.blankOptions = [{prescribedBlankId: null, prescribedBlankName: ''}]
              this.blankOptions = this.blankOptions.concat(json.blanks)
              if (json.blanks.length > 0) {
                this.selectedBlank = json.blanks[0].prescribedBlankId
              }

            } else {
              this.$errorToast(json.resultCode + ' ' + json.message)
            }

          }).catch(error => {
        console.log(error)
        this.saving = false
      })
    }
  },
  mounted() {
    console.log("ExamFiles mounted")
    this.fetchData()
    this.fetchPrescribedBlanks()
  }
}
</script>

<style scoped>

</style>