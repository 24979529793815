<template>
  <b-container fluid>
    <b-row class="pt-1 pl-3" style=" background-color: #FFFFFF;">
      <b-form-checkbox v-model="questionary.noPain" switch @change="tooglePain">Боли отсутствуют</b-form-checkbox>

    </b-row>
    <b-row class="justify-content-center" style="background-color: #FFFFFF;text-transform: uppercase; font-family: Myriad Pro; font-style: normal; font-weight: 600; font-size: 18px;line-height: 22px;">

      <span class="block-title">Если чувствуете боль, выберете зону, к которой она относится</span>
    </b-row>
    <b-row style=" background-color: #FFFFFF;">
      <b-container>
        <PatientComplaintsWoman v-show="gender === 2" @select-group="selectGroup" ref="patientComplaintsWoman" v-bind:questionary="questionary"/>
        <PatientComplaintsMan v-show="gender === 1" @select-group="selectGroup" ref="patientComplaintsMan" v-bind:questionary="questionary" />
      </b-container>
      <hr />
    </b-row>
    <b-row class="justify-content-center" style=" padding: 1rem 0rem; text-transform: uppercase; font-family: Myriad Pro; font-style: normal; font-weight: 600; font-size: 18px;line-height: 22px;">

      <span class="block-title">Если обнаружили у себя какой-либо симптом, пожалуйста, отметьте его</span>
    </b-row>
    <b-row style="padding-bottom: 1rem">
      <b-container>
        <b-form @submit.prevent="saveComplaints">
          <b-form-checkbox-group
              v-model="groupsSelect"
              class=""
              :options="groupsOptions"
              @change="saveComplaints"
              switches
              stacked style="column-count: 2;"
              ></b-form-checkbox-group>
  <!--        <b-button type="submit" variant="info" :disabled="saving">-->
  <!--          Сохранить-->
  <!--          <b-spinner v-if="saving" variant="light" small></b-spinner>-->
  <!--        </b-button>-->
        </b-form>
      </b-container>
    </b-row>



  </b-container>
</template>

<script>
import PatientComplaintsWoman from "@/components/patients/PatientComplaintsWoman";
import PatientComplaintsMan from "@/components/patients/PatientComplaintsMan";
import {host} from "@/constants";
export default {
  props: ["patientId", "gender"],
  name: "PatientComplaints",
  data() {
    return {
      saving: false,
      groupsOptions: [],
      groupsSelect: [],
      group6Options: [],
      group8Options: [],
      group9Options: [],
      group10Options: [],
      groupId: null,
      questionary: {
        questionaryId: null,
        clientId: this.patientId,
        noPain: false,
        painHead: false,
        painFrontHalfChest: false,
        painHeart: false,
        painUnderLeftScapula: false,
        painChest: false,
        painCenterSpine: false,
        painBack: false,
        painUpAbdominal: false,
        painLowAbdominal: false,
        painLowBack: false,
        painMuscle: false,
        painBorn: false,
        painJoint: false,
      }
    }
  },
  components: {PatientComplaintsWoman, PatientComplaintsMan},
  methods: {
    tooglePain(checked) {
      console.log('Toogle pain ' + checked)
      if (checked) {
        this.selectGroup(['6'], checked)

        this.questionary.painHead = false
        this.questionary.painFrontHalfChest = false
        this.questionary.painHeart = false
        this.questionary.painUnderLeftScapula = false
        this.questionary.painChest = false
        this.questionary.painCenterSpine = false
        this.questionary.painBack = false
        this.questionary.painUpAbdominal = false
        this.questionary.painLowAbdominal = false
        this.questionary.painLowBack = false
        this.questionary.painMuscle = false
        this.questionary.painBorn = false
        this.questionary.painJoint = false

        //this.groupsSelect = []
      }
    },
    async saveComplaints() {
      this.saving = true
      try {
        let rqSelect = []

        for (let el of this.groupsSelect) {

          if (this.groupsOptions.includes(this.groupsOptions.find(elem => (elem.value == el )))) {
            rqSelect.push(el)
          }
        }
        let response = await fetch(host + '/rest/patients/' + this.patientId + '/dynamic/checklistbygroup/' + this.groupId, {
          method: 'PUT',
          credentials: 'include',
          body: JSON.stringify(rqSelect),
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        })
        let json = await response.json()

        if (json.resultCode == 0) {
          this.$messageToast('Жалобы', 500)
        } else {
          this.$errorToast(json.resultCode + ' ' + json.message)
        }

        await this.saveQuestionary()
      } catch(error)  {
        console.log(error)
        this.$errorToast('Неизвестная ошибка')
      }
      this.saving = false
    },
    async saveQuestionary() {
      this.saving = true
      try {

        let response = await fetch(host + '/rest/patients/' + this.patientId + '/questionary', {
          method: 'PUT',
          credentials: 'include',
          body: JSON.stringify(this.questionary),
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        })
        let json = await response.json()

        if (json.resultCode !== 0) {
          this.$errorToast(json.resultCode + ' ' + json.message)
        }
      } catch(error)  {
        console.log(error)
        this.$errorToast('Неизвестная ошибка')
      }
      this.saving = false
    },
    async selectGroup(groups, isNotPain) {
      console.log('select groups ' + groups)
      if (isNotPain) {
        this.questionary.noPain = isNotPain
      } else {
        this.questionary.noPain = false
      }

      this.groupsOptions = []
      //for (let groupId of groups) {
      if (groups && groups.length > 0) {
        // if (this.groupId != groups[0]) {
        //   this.groupsSelect = []
        // }
        this.groupId = groups[0]
        if (this.groupId === '6') {
          this.group6Options.forEach(value => {
            if (!this.groupsOptions.includes(value)) {
              this.groupsOptions.push(value)
            }
          })
        } else if (this.groupId === '8') {
          this.group8Options.forEach(value => {
            if (!this.groupsOptions.includes(value)) {
              this.groupsOptions.push(value)
            }
          })
        }
        else if (this.groupId === '9') {
          this.group9Options.forEach(value => {
            if (!this.groupsOptions.includes(value)) {
              this.groupsOptions.push(value)
            }
          })
        }
        else if (this.groupId === '10') {
          this.group10Options.forEach(value => {
            if (!this.groupsOptions.includes(value)) {
              this.groupsOptions.push(value)
            }
          })
        }
      }

      await this.saveQuestionary()
    },
    async fetchData() {
      this.groupsSelect = []
      let response = await fetch(host + '/rest/patients/' + this.patientId + '/dynamic/checklistbygroup/6', {
        credentials: "include"
      })
      let json  = await response.json()
      this.group6Options = json.options
      for (let el of this.group6Options) {
        if (el.selected) {
          this.groupsSelect.push(el.value)
        }
      }

      response = await fetch(host + '/rest/patients/' + this.patientId + '/dynamic/checklistbygroup/8', {
        credentials: "include"
      })
      json  = await response.json()
      this.group8Options = json.options
      for (let el of this.group8Options) {
        if (el.selected && !this.groupsSelect.includes(el.value)) {
          this.groupsSelect.push(el.value)
        }
      }

      response = await fetch(host + '/rest/patients/' + this.patientId + '/dynamic/checklistbygroup/9', {
        credentials: "include"
      })
      json  = await response.json()
      this.group9Options = json.options
      for (let el of this.group9Options) {
        if (el.selected  && !this.groupsSelect.includes(el.value) ) {
          this.groupsSelect.push(el.value)
        }
      }


      response = await fetch(host + '/rest/patients/' + this.patientId + '/dynamic/checklistbygroup/10', {
        credentials: "include"
      })
      json  = await response.json()
      this.group10Options = json.options
      for (let el of this.group10Options) {
        if (el.selected  && !this.groupsSelect.includes(el.value)) {
          this.groupsSelect.push(el.value)
        }
      }

      response = await fetch(host + '/rest/patients/' + this.patientId + '/questionary', {
        credentials: "include"
      })
      json  = await response.json()
      if (json.resultCode == 0 && json.zones) {
        this.questionary = json.zones
      }


      if (this.gender === 2){
        this.$refs.patientComplaintsWoman.selectGroups(this.questionary)
      } else {
        this.$refs.patientComplaintsMan.selectGroups(this.questionary)
      }
    }
  },
  async mounted() {
    await this.fetchData()


  }
}
</script>

<style scoped>

</style>