<template>
  <div>
    <b-button class="btn-info" v-b-modal.createParamMeasurementUnitModal >Добавить единицу измерения</b-button>
    <br /><br />
    <b-table striped hover :items="measurementParams" :fields="fields">
      <template v-slot:cell(buttons)="data">
        <a><b-icon variant="info" icon="x-circle" size="lg" @click="deleteParamMeasurement(analitId, data.item.value, data.item.text)"></b-icon></a>
      </template>
    </b-table>

    <b-modal id="createParamMeasurementUnitModal" hide-footer size="lg" title="Единица измерения">
      <AnalitMeasurementEdit @save-success="afterSave" v-bind:analitId="this.analitId"></AnalitMeasurementEdit>
    </b-modal>
  </div>
</template>

<script>
import {host} from "@/constants";
import AnalitMeasurementEdit from "@/components/AnalitMeasurementEdit";

export default {
  name: "AnalitMeasurements",
  props: ["analitId"],
  data() {
    return {
      fields: [
        {
          key: 'text',
          label: 'Альтернативные единицы измерения'
        },
        {
          key: 'coef',
          label: 'Коэффициент'
        },
        {
          key: 'buttons',
          label: ' ',
          sortable: false
        }
      ],
      measurementParams: [],
      error: "",
      mode: "create"
    }
  },
  mounted: function () {

    fetch(host + '/rest/dicts/parammeasurementunit/' + this.analitId, {
      credentials: "include"
    })
        .then(response => response.json())
        .then(json => {
          this.measurementParams = []
          let arr = json.options
          for (let m of arr) {
            console.log(m.coef)
            if (m.coef !== 1) {
              this.measurementParams.push(m)
            }
          }
          console.log(JSON.stringify(json))
        })
  },
  components: {
    AnalitMeasurementEdit
  },
  methods: {

    afterSave() {
      this.$bvModal.hide('createParamMeasurementUnitModal')
      fetch(host + '/rest/dicts/parammeasurementunit/' + this.analitId, {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.measurementParams = []
            let arr = json.options
            for (let m of arr) {
              console.log(m.coef)
              if (m.coef !== 1) {
                this.measurementParams.push(m)
              }
            }
            console.log(JSON.stringify(json))
          })
    },
    deleteParamMeasurement(paramId, measurementUnitId, paramName) {
      if (confirm('Вы уверены, что хотите удалить единицу измерения "' + paramName + '" у данного аналита?')) {
        this.loading = true;
        fetch(host + '/rest/params/' + paramId + '/measurement/' + measurementUnitId, {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {

                console.log('Exit Success ' + json)

              } else {

                console.log('Exit Failed ' + json)
                this.error = json.message;
              }
              this.afterSave()
              this.loading = false
            })

      }
    }
  }

}
</script>

<style scoped>

</style>