<template>
  <div>
    <b-list-group horizontal>
      <b-list-group-item :variant="currentRubr !== null && r.value === currentRubr.value ? 'info' : ''"
                         v-for="r in rubricatorList" :key="r.value" button @click="setRname(r)">{{ r.text }}
      </b-list-group-item>
    </b-list-group>
    <div class="container-fluid">
<!--      <FactorValueList v-if="currentRubr!==null && currentRubr.value === 'Drug'" v-bind:rubr="currentRubr"></FactorValueList>-->
      <DiseaseValueList v-bind:rubr="currentRubr"></DiseaseValueList>

    </div>

  </div>
</template>

<script>
import {host} from "@/constants";
import FactorValueList from "@/components/admin/factors/FactorValueList";
import DiseaseValueList from "@/components/admin/factors/DiseaseValueList";

export default {
  name: "FactorList",
  data() {
    return {
      rubricatorList: [
        {value: "Disease", text: "Патологические состояния и причины отклонений"},
        {value: "Drug", text: "Лекарственные препараты"},
        {value: "RiskFactor", text: "Факторы внешней среды и особые состояния организма"},
        {value: "LaboratoryError", text: "Лабораторные ошибки"}
      ],
      currentRubr: null,
      isSetRname: false
    }
  },
  components: {
    DiseaseValueList,
    FactorValueList
  },
  mounted() {
    // this.fetchData()
  },
  methods: {
    // fetchData() {
    //   fetch(host + '/rest/factors')
    //       .then(response => response.json())
    //       .then(json => {
    //         this.rubricatorList = json.options
    //         //this.loading = false
    //         console.log(json)
    //       })
    // },
    setRname(rubr = null) {
      this.currentRubr = rubr
    }
  }
}
</script>

<style scoped>

</style>