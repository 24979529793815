<template>
  <div>
    <div v-if="error" class="alert alert-danger">
      {{error}}
    </div>
    <b-form @submit.prevent="onSubmit">
      <div class="row mx-0 mt-4 py-3  px-2 background_border_page_7 row_margin">

        <div class="col-lg-1 col-12 m-auto px-0 text-center text-lg-left"><span class="attribute_search_page_7"></span></div>
        <treeselect class="custom-treeselect-control col-12  padding_0 "
                    name="param" id="param" placeholder=""
                    clearable searchable :disabled="currentAnalit !== null"
                    open-on-click close-on-select disableFuzzyMatching
                    :noResultsText="'Не найдено...'"
                    :noOptionsText="'Список опций пуст'"
                    :options="selectOptions | searchQueryFilter(selected, childrenSearchQuery, rubricators)"
                    :max-height="200"
                    :normalizer="normalizeTreeselect"
                    v-model="selected.paramId" style="font-size: 15px!important;" @select="onParamSelect1" @search-change="childrenSearchChange">
          <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName + 'customOptionLabel'">
            <span :title="getNodeLabel(node.label)">{{node.label | nodeLabelFilter}}</span>
          </label>
          <div slot="value-label" slot-scope="{ node}" class="vue-treeselect__multi-value ">

                <span class="" :title="getNodeLabel(node.label)">{{node.label | nodeLabelFilter}}</span>

          </div>
        </treeselect>


        <div class="col col-sm-1 col-xl-3 d-flex justify-content-around m-auto">
<!--          <span class="attribute_search_page_7 pl-4">Поиск по рубрикам</span>-->
<!--          <button class="border-0 outline_none_background"><img src="@/assets/images/Group 143.png"></button>-->
        </div>

      </div>


<!--      <span v-if="currentAnalit === null">-->
<!--        <b>Фильтры:</b>-->
<!--        <b-form-group v-for="r in rubricators" id="r.value" label-for="" :key="r.rubricatorId" label-cols="6">-->
<!--          <template slot="label">{{ r.text }}</template>-->
<!--          <b-form-select id="" v-model="r.selectedValue" :options="r.options" :select-size="1" @change="onFilterSelect"></b-form-select>-->
<!--        </b-form-group>-->
<!--      </span>-->


      <div v-if="!(selected.paramType === 4 || selected.paramType === 5 || selected.paramType === 6 || selected.paramType === 8 || selected.paramType === 9)"   class="analit-simple">

        <div class="row mx-0 row_padding"  v-if="!(selected.paramType == 4 || selected.paramType === 5 || selected.paramType === 6 || selected.paramType == 8 || selected.paramType == 9)">
          <div :class="(selected.paramType == 1 || selected.paramType === 3 || selected.paramType === 10) ? 'col-lg-9 col-sm-12' : 'col-lg-12 col-sm-12' ">
            <div class="row text-center mb-3 mb-lg-0 h-100"  >
              <div class="col-md-6 col-6 px-0" v-if="(selected.paramType === 1 || selected.paramType === 3 || selected.paramType === 10 || (selected.paramType === 7 && (isTitres || isInterval)))">
                <div class="px-0"><span class="attribute_center_page_7">Значение</span></div>
                <div class="px-0 row_margin">
                  <b-input-group class="border-0 w-100 row_padding "  >
                    <div v-if="(selected.paramType === 7 && titreOptions && titreOptions.length > 0)" class="border-0 analit-input-prepend-titr" is-text>1:</div>
<!--                    <b-form-input v-mask="mask" id="paramValue" type="text" placeholder="Введите значение" v-model="paramValue" autocomplete="off" @change="paramValueSelect"></b-form-input>-->
                    <input :class="(selected.paramType === 7 && titreOptions && titreOptions.length > 0) ? 'border-0 w-70 analit-input-titr text-left' : 'border-0 w-100 analit-input'" v-mask="mask" id="paramValue" type="text" placeholder="Введите значение" v-model="paramValue" autocomplete="off" @change="paramValueSelect">
                  </b-input-group>
<!--                  <input  class="border-0 w-75 h_30_border_radius" v-mask="mask" id="paramValue" type="text" placeholder="Введите значение" v-model="paramValue" autocomplete="off" @change="paramValueSelect" ref="paramValue">-->
                  <!--              <input type="text" >

                  -->

                </div>
              </div>
              <div  class="col-md-6 col-6 px-0" v-if="(selected.paramType === 1 || selected.paramType === 3 || selected.paramType === 10 || (selected.paramType === 7 && (isTitres || isInterval)))">
                <div class="px-0"><span class="attribute_center_page_7">Единицы измерения</span></div>
                <div class="px-0 row_margin">
                  <!--              <b-form-select id="measure" v-model="item.measurementUnitId" :options="item.measurementOptions" :select-size="1" size="1"-->
                  <!--                             class="custom-select border-0 pt-0 w-75 inside_the_field h_30"></b-form-select>-->

                  <select v-model="measurementUnitId" @change="onMeasurementUnitSelect" class="custom-select border-0 analit-input w-100 ">
                    <option v-for="option in measurementOptions" v-bind:value="option.value">
                      {{ option.text }}
                    </option>
                  </select>

                </div>
              </div>

              <div class="col-md-12 col-12 px-0" v-if="(selected.paramType === 2 || (selected.paramType === 7 && propertyValueOptions && propertyValueOptions.length > 0))">
                <div class="px-0">
<!--                  <span class="attribute_center_page_7">Выбор значения</span>-->
                </div>
                <div class="px-0 row_margin py-3">
                  <b-form-select id="propertyValue" v-model="propertyValue" :options="propertyValueOptions" :select-size="1" @change="propertyValueSelect"
                                 class="custom-select border-0  w-100 analit-input h_30"></b-form-select>
                  <br>
<!--                  {{propertyValueOptions.find(el => (el.value == propertyValue)) ? propertyValueOptions.find(el => (el.value == propertyValue)).text : ''}}-->

                </div>
              </div>

              <div class="col-md-6 col-6 px-0" v-if="(selected.paramType === 7 && titreOptions && titreOptions.length > 0)">
                <div class="px-0"><span class="attribute_center_page_7">Титры</span></div>
                <div class="px-0 row_margin">
                  <b-form-select id="titres" v-model="titres" :options="titreOptions" :select-size="1" @change="titresSelect"
                                 class="custom-select border-0 w-10 analit-input h_30"></b-form-select>
                </div>
              </div>

              <div class="col-md-6 col-6 px-0" v-if="(selected.paramType === 7 && plusMinusOptions && plusMinusOptions.length > 0)">
                <div class="px-0"><span class="attribute_center_page_7">Кресты</span></div>
                <div class="px-0 row_margin">
                  <b-form-select id="measure" v-model="plusMinus" :options="plusMinusOptions" :select-size="1" @change="plusMinusSelect"
                                 class="custom-select border-0 w-100  analit-input h_30"></b-form-select>
                </div>
              </div>
            </div>
          </div>

          <div v-if="selected.paramType == 1 || selected.paramType === 3 || selected.paramType === 10" class="col-lg-3 col-sm-12 text-center " >
            <div class="row h-100">
              <div class="col-12 border_right_4px_pg_7">
                <div>
									<span class="attribute_center_page_7">Референтный диапазон
									</span>
                </div>
                <div class="d-flex justify-content-center mb-3 mb-lg-0">
                  <div><input id="startValueUser" autocomplete="off" type="text" v-mask="mask" v-model="startValueUser" :disabled="localNotAllowToChangeRD" @change="setUserScale()" class="border-0 w-100 analit-input"></div>
                  <div class="px-1"> - </div>
                  <div><input id="finishValueUser" autocomplete="off" type="text" v-mask="mask" v-model="finishValueUser" :disabled="localNotAllowToChangeRD" @change="setUserScale()" class="border-0 w-100 analit-input"></div>
                  <div class="px-1">
                    <b-link title="Установить первоначальные значения" @click="resetUserScale"><b-icon  icon="x-circle" variant="success"  size="lg" ></b-icon></b-link>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>



      </div>

      <div v-if="selected.paramType === 4 || selected.paramType === 5 || selected.paramType === 6 || selected.paramType === 8 || selected.paramType === 9 || selected.paramType === 10" class="">
        <ClientReportComplexParamForm v-bind:param="selected" v-bind:patient-id="patientId" v-bind:current-analit="currentAnalit" ref="clientComplexParamParent"></ClientReportComplexParamForm>

        <!--        <b-form-group id="examinationDate" label-for="examinationDate" label="Дата исследования">-->
        <!--          <b-form-input id="examinationDate" type="date" placeholder="" v-model="examinationDate" autocomplete="off"></b-form-input>-->
        <!--        </b-form-group>-->
      </div>

      <div  class="background_E9F5EA_page_7">
        <div class="row mx-0 py-4 border_top_2px_pg_7">

          <div class="col-12  col-lg-4 d-flex">
            <div class="position-relative">
<!--              <input v-model="examinationDate" autocomplete="off" type="date" class="border-0 width_border_page_7 date1_page_7" min="1900-01-01">-->
            </div>
            <div class="px-3">
              <span class="date_research_form">&nbsp;</span>
            </div>
          </div>
          <div class="col-12 col-lg-4 text-lg-center d-flex pt-4 pt-lg-0">

            <button class="border-0 save_btn_page_5" type="submit" :disabled="saving">
                    <span class="text_save_page_5">Сохранить
                    </span>
              <b-spinner v-if="saving" variant="light" small></b-spinner>
            </button>
            <button class="border-0 save_btn_page_5 ml-2" type="button" @click="onCancel">
                    <span class="text_save_page_5">Отменить
                    </span>
            </button>
          </div>
          <div class="col-12 col-lg-4 d-flex justify-content-lg-end pt-4 pt-lg-0">

          </div>


        </div>
      </div>

    </b-form>
  </div>
</template>

<script>
  import {host, currencyMask} from '@/constants'
  import Treeselect from "@riophae/vue-treeselect";
  import ClientReportComplexParamForm from "@/components/patients/ClientReportComplexParamForm";
  import createNumberMask from "text-mask-addons/dist/createNumberMask";

  const valueMask = createNumberMask({
    allowDecimal: true,
    includeThousandsSeparator: false,
    allowNegative: true,
    decimalSymbol: '.',
    decimalLimit: 10,
    prefix: '',
    suffix: ''
  })


  export default {
    props: {
      error: String,
      patientId: String,
      currentAnalit: Object
    },
    name: "ClientReportParamForm",
    data() {
      return{
        saving: false,
        vMask: valueMask,
        mask: currencyMask,
        childrenSearchQuery:'',
        params: [],
        propertyValueOptions: [ {value: null, text: ''}],
        titreOptions: [],
        plusMinusOptions: [],
        propertyValue: {},
        titres: null,
        plusMinus: null,
        selectOptions: [],
        measurementOptions: [],
        selected: {paramId:null},
        isTitres: false,
        scaleSelect: {},
        isUserScale: false,
        isInterval: false,
        localNotAllowToChangeRD: this.currentAnalit !== null ? this.currentAnalit.notAllowToChangeRD : false,
        startValueUser: this.currentAnalit !== null ? this.currentAnalit.startValueUser : null,
        finishValueUser: this.currentAnalit !== null ? this.currentAnalit.finishValueUser : null,
        measurementUnitId: this.currentAnalit !== null ? this.currentAnalit.measurementUnitId : null,
        paramValue: this.currentAnalit !== null ? this.currentAnalit.paramValue : null,
        examinationDate: this.currentAnalit !== null ? this.currentAnalit.examinationDate : '' + new Date().getFullYear() + '-' + (new Date().getMonth() < 9 ? '0' + (new Date().getMonth()+1) : (new Date().getMonth()+1)) + '-' + (new Date().getDate() < 10 ? '0' + (new Date().getDate()) : (new Date().getDate())),
        rubricators:[],
        normalizeTreeselect(node) {
          return {
            id: node.value.paramId,
            label: node.value.paramName + (node.value.biomaterialTypeName ? ', ' + node.value.biomaterialTypeName : '') + ';_;_; ' + node.value.paramSynonym + ' ' + node.value.searchString
          }
        },
      }
    },
    components: {
      ClientReportComplexParamForm,
      Treeselect
    },
    filters: {
      activeParams: function (options) {
        let result = []
        for (let o of options) {
          if (o.value.workInPractice && !o.value.hiddenForSearch) {
            result.push(o)
          }
        }

        return result
      },
      searchQueryFilter: function (options, diseases, searchQuery, rubricators) {

        let isRubricator = false
        for (let r of rubricators) {
          if (r.selectedValue) {
            isRubricator = true
          }
        }

        let result = []
        if (isRubricator && !searchQuery) {
          result = options
        } else {
          result = options.filter((el, index, []) => {
            return diseases.paramId === el.value.paramId || ((searchQuery) && (el.value.paramName + el.value.biomaterialTypeName + ';_;_; ' + el.value.paramSynonym + ' ' + el.value.searchString).toLowerCase().includes(searchQuery.toLowerCase()) )
          })
        }


        return result
      },

      paramsWithEmpty: function (options) {
        if (options) {
          return options.splice(0, 0, {value: null, text: null})
        } else {
          return options
        }

      },
      nodeLabelFilter(value) {
        if (value) {
          return value.substring(0, value.indexOf(';_;_;'))
        }

      }
    },
    mounted() {
      console.log('date ' + new Date().getFullYear() + '-' + (new Date().getMonth() < 9 ? '0' + (new Date().getMonth()+1) : (new Date().getMonth()+1)) + '-' + new Date().getDate())

      fetch(host + '/rest/params' + (this.currentAnalit ? '' : '/fordoctor'), {
        credentials: "include"
      })
        .then(response => response.json())
        .then(json => {
          this.prepareAnalitsFromResponse(json.params)
        })

      fetch(host + '/rest/rubricators/forDoctor', {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.rubricators = json.options
            for (let p of this.rubricators) {
              p.options = []
              p.selectedValue = null
              fetch(host + '/rest/rubricators/' + p.value, {
                credentials: "include"
              })
                  .then(response => response.json())
                  .then(json => {
                    p.options = json.options
                    p.options.splice(0,0,{value: null, text: ''})
                    this.$forceUpdate()
                  })
            }
            console.log('fetching end')
            if (this.currentAnalit !== null) {
              console.log('Current analit is ' + JSON.stringify(this.currentAnalit))
              this.measurementUnitId = this.currentAnalit.measurementUnitId

            }
          })
    },
    methods: {
      getNodeLabel(value) {
        if (value) {
          return value.substring(0, value.indexOf(';_;_;'))
        }

      },
      childrenSearchChange(searchQuery, instanceId) {
        this.childrenSearchQuery = searchQuery
      },
      plusMinusSelect(newVal) {
        this.titres = null
        this.propertyValue = {value:null, propertyId: this.propertyValue.propertyId}
        this.paramValue = ''
        // this.$refs.paramValue ? this.$refs.paramValue.value = ""
        // this.$refs.paramValue1.value = ""
        this.$forceUpdate()
      },
      titresSelect(newVal) {
        console.log('titresSelect 1 ', this.paramValue)
        this.plusMinus = null
        this.propertyValue = {value:null, propertyId: this.propertyValue.propertyId}
        this.paramValue = ''
        // this.$refs.paramValue.value = ""
        // this.$refs.paramValue1.value = ""
        console.log('titresSelect 2 ', this.paramValue)
        this.$forceUpdate()
      },
      propertyValueSelect(newVal) {
        console.log('propertyValueSelect 1 ', this.paramValue)
        this.plusMinus = null
        this.titres = null
        this.paramValue = ''
        // this.$refs.paramValue.value = ""
        // this.$refs.paramValue1.value = ""
        console.log('propertyValueSelect 2 ', this.paramValue)
        this.$forceUpdate()
      },
      paramValueSelect(newVal) {
        console.log('paramValueSelect', newVal)
        this.plusMinus = null
        this.titres = null
        this.propertyValue = {value:null, propertyId: this.propertyValue.propertyId}
        this.$forceUpdate()
      },

      prepareAnalitsFromResponse(params) {
        this.selectOptions = []
        this.params = params
        let selectParent = false
        for (let p of this.params) {

          let option = {
            value: p,
            text: p.paramName
          }
          this.selectOptions.push(option)
          if (this.currentAnalit && this.currentAnalit.parentParamId == p.paramId && this.currentAnalit.onlyOneSelect) {
            this.selected = p
            this.selected.onlyOneSelect = this.currentAnalit.onlyOneSelect
            selectParent = true
            this.onParamSelect()
          } else if (this.currentAnalit && !selectParent && this.currentAnalit.paramId == p.paramId) {
            this.selected = p
            this.onParamSelect()
          }
        }
        console.log('fetching end')
        if (this.currentAnalit !== null) {
          console.log('Current analit is ' + JSON.stringify(this.currentAnalit))
          this.measurementUnitId = this.currentAnalit.measurementUnitId

        }
        this.$forceUpdate()
      },
      onParamSelect() {
        this.measurementUnitId = this.selected.measurementUnitId
        fetch(host + '/rest/dicts/parammeasurementunit/' + this.selected.paramId, {
          credentials: "include"
        })
            .then(response => response.json())
            .then(json => {
              this.measurementOptions = json.options

            })

        fetch (host + '/rest/params/' + this.selected.paramId + '/properties/values/dict', {
          credentials: "include"
        })
            .then(response => response.json())
            .then(json => {
              this.propertyValueOptions = [ {value: null, text: ''}]
              this.titreOptions = []
              this.plusMinusOptions = []
              this.isInterval = false
              this.isTitres = false
              for (let p of json.options) {
                if (p.text) {
                  this.propertyValueOptions.push(
                      {value: p, text: p.propertyName + ', ' + p.text }
                  )
                  if (this.currentAnalit && this.currentAnalit.propertyValueId === p.value) {
                    this.propertyValue = p
                  }
                }
                if (p.titres) {
                  this.titreOptions.push(
                      {value: p.titres, text: p.titres }
                  )
                }
                if (p.plusMinus) {
                  this.plusMinusOptions.push(
                      {value: p.plusMinus, text: p.plusMinus }
                  )
                }
                if (p.startValue || p.finishValue) {
                  this.isInterval = true
                }
                if (p.titres) {
                  this.isTitres = true
                }
              }



            })

        if (this.selected.paramType == 1 || this.selected.paramType == 3 || this.selected.paramType == 10) {
          this.onMeasurementUnitSelect(null)
        }

      },
      onMeasurementUnitSelect(event) {
        fetch(host + '/rest/params/' + this.selected.paramId + '/patient/' + this.patientId + '/scale/' + this.measurementUnitId, {
          credentials: "include"
        })
            .then(response => response.json())
            .then(json => {
              this.scaleSelect = json.scaleSelect
              console.log('startValueUser ' + this.startValueUser + ' finishValueUser ' + this.finishValueUser)
              if (event==null) {
                if (this.startValueUser || this.finishValueUser) {
                  this.isUserScale = true
                } else {
                  this.startValueUser = this.scaleSelect.startValue
                  this.finishValueUser = this.scaleSelect.finishValue
                  this.isUserScale = false
                }
              } else {
                this.startValueUser = this.scaleSelect.startValue
                this.finishValueUser = this.scaleSelect.finishValue
                this.isUserScale = false
              }

            })
      },
      onParamSelect1(node, instanceId) {

        this.selected = node.value
        this.localNotAllowToChangeRD = this.selected.notAllowToChangeRD
        this.onParamSelect()

      },
      setUserScale() {
        this.isUserScale = true
      },
      resetUserScale() {
        this.startValueUser = this.scaleSelect.startValue
        this.finishValueUser = this.scaleSelect.finishValue
        this.isUserScale = false
      },
      onFilterSelect() {
        console.log('onFilterSelect started')
        let filterRq = {}
        for (let r of this.rubricators) {
          switch (r.rubricatorId) {
            case 1: filterRq.medicalSpecialtyId = r.selectedValue; break;
            case 2: filterRq.metabolismTypeId = r.selectedValue; break;
            case 3: filterRq.labStateTissueOrganId = r.selectedValue; break;
            case 4: filterRq.conditionAndSyndromeId = r.selectedValue; break;
            case 5: filterRq.methodId = r.selectedValue; break;
            case 6: filterRq.complexExaminationId = r.selectedValue; break;
            case 7: filterRq.laboratoryId = r.selectedValue; break;
          }
        }
        let method = 'POST'

        fetch(host + '/rest/params/filter', {
          method: method,
          credentials: 'include',
          body: JSON.stringify(filterRq),
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {
              this.prepareAnalitsFromResponse(json.params)
            }).catch(error => {
              this.error = error
              console.log(JSON.stringify(error))
            })
      },
      async onCancel() {
        if (confirm('Введенные данные будут потеряны. Вы уверены что хотите закрыть форму без сохранения данных?')) {
          this.$emit('save-cancel')
        }
      },
      async onSubmit() {
        this.saving = true
        console.log('exam date' + this.examinationDate)
        if (this.selected.paramType === 4 || this.selected.paramType === 5 || this.selected.paramType === 6 || this.selected.paramType === 8 || this.selected.paramType === 9 || this.selected.paramType === 10) {
          await this.$refs.clientComplexParamParent.onSubmit(this.examinationDate)
          this.$emit('save-success')
        }
        if (this.selected.paramType === 1 || this.selected.paramType === 2 || this.selected.paramType === 3 || this.selected.paramType === 7 || (this.selected.paramType === 10 && this.paramValue)) {
          let rq = null
          let method = 'POST'
          if (this.currentAnalit !== null && this.currentAnalit.paramClientId) {
            rq = {
              param: {
                paramClientId: this.currentAnalit.paramClientId,
                paramValue: this.paramValue,
                measurementUnitId: this.measurementUnitId,
                examinationDate: this.examinationDate,
                propertyId: this.propertyValue.value ? this.propertyValue.propertyId : null,
                propertyValueId: this.propertyValue.value,
                plusMinus: this.plusMinus,
                titres: this.titres,
                startValueUser: this.isUserScale ? this.startValueUser : null,
                finishValueUser: this.isUserScale ? this.finishValueUser : null
              }
            }
            method = 'PUT'
          } else {
            rq = {
              clientId: this.patientId,
              param: {
                paramId: this.selected.paramId,
                paramValue: this.paramValue,
                measurementUnitId: this.measurementUnitId,
                examinationDate: this.examinationDate,
                propertyId: this.propertyValue.value ? this.propertyValue.propertyId : null,
                propertyValueId: this.propertyValue.value,
                plusMinus: this.plusMinus,
                titres: this.titres,
                startValueUser: this.isUserScale ? this.startValueUser : null,
                finishValueUser: this.isUserScale ? this.finishValueUser : null
              }
            }
            method = 'POST'

          }
          try {
            console.log(JSON.stringify(rq))
            let response = await fetch(host + '/rest/params/clientreport', {
              method: method,
              credentials: 'include',
              body: JSON.stringify(rq),
              headers: {'Content-Type': 'application/json; charset=utf-8'}
            })
            let json = await response.json()

            if (json.resultCode == 0) {
              console.log('Save Success ' + JSON.stringify(json))
              this.selected = {paramId: null}
              this.paramValue = null
              this.$emit('save-success')
            } else {
              console.log('Login Failed ' + json)
              this.error = json.resultCode + ' ' + json.message;
            }

          } catch(error) {
            this.error = error
            console.log(JSON.stringify(error))
          }

        }
        this.saving = false

      }
    }
  }
</script>

<style scoped>

.customOptionLabel {
  display: flex!important;
  overflow: visible!important;
}

.custom-treeselect-control {
  display: flex!important;
  overflow: visible!important;
}
.vue-treeselect__multi-value {
  display: flex!important;
  overflow: visible!important;
}
.vue-treeselect__control {
  display: flex!important;
  overflow: visible!important;

}
.vue-treeselect__single-value {
  display: flex!important;
  overflow: visible!important;

}
.vue-treeselect__value-container {
  display: flex!important;
  overflow: visible!important;
}

</style>