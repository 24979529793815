<template>
  <b-container fluid>
    <b-tabs v-model="tabIndex" content-class="mt-3" nav-class="font-weight-bold">
      <b-tab title="Единицы измерения">
        <MeasurementUnitList/>
      </b-tab>
      <b-tab title="Формулы">
        <FormulaList />
      </b-tab>
      <b-tab title="Правила">
        <RuleList />
      </b-tab>

      <!--      <b-tab title="Патологии графических отчетов" >-->
      <!--        <ZoneDiseaseReportPhrases />-->
      <!--      </b-tab>-->
    </b-tabs>
  </b-container>
</template>

<script>
import MeasurementUnitList from "@/components/admin/measurementunits/MeasurementUnitList"
import FormulaList from "@/components/admin/rules/FormulaList"
import RuleList from "@/components/admin/rules/RuleList"

export default {
  name: "RulesAndFormulas",
  components: {
    MeasurementUnitList, FormulaList, RuleList
  },
  data() {
    return {
      tabIndex: 0
    }
  }
}
</script>

<style scoped>

</style>