<template>
  <b-container fluid>
    <b-row>
      <b-button variant="info" @click="editParamScaleValue(2)">
        <b-icon icon="plus" aria-hidden="true"></b-icon>
        Добавить
      </b-button><br/><br/>
    </b-row>
    <b-row v-if="isDataReady && so.items && so.items.length > 0" v-for="(so, index) in scaleOptions" :key="so.value">
      <b-col>
        <h3>
          {{ so.text }}
          <b-link @click="editScaleName(so)">
            <b-icon variant="info" icon="pencil" size="lg"></b-icon>
          </b-link>
        </h3>
        <b-table striped hover :items="so.items" :fields="fields">
          <template v-slot:cell(ageFrom)="data">
            <span
                :inner-html.prop="data.item | ageText(data.item.startYear,  data.item.startMonth, data.item.startDay)"></span>
          </template>

          <template v-slot:cell(ageTo)="data">
            <span
                :inner-html.prop="data.item | ageText(data.item.finishYear,  data.item.finishMonth, data.item.finishDay)"></span>
          </template>

          <template v-slot:cell(valueFrom1)="data">
            <span
                :inner-html.prop="data.item.startValue | valueText1(data.item.measurementUnitName, data.item.finishValue)"></span>
          </template>

          <template v-slot:cell(zoneName)="data">
            <span v-html="data.item.reportText"></span>
          </template>

          <template v-slot:cell(buttons)="data">
            <h3 class="buttons">
              <nobr>
                <b-link @click="editParamScaleValue(so.value, data.item)">
                  <b-icon variant="info" icon="pencil" size="lg"></b-icon>
                </b-link>
                &nbsp;
                <b-link @click="deleteRubricatorValue(data.item.paramScaleId)">
                  <b-icon variant="info" icon="x-circle" size="lg"></b-icon>
                </b-link>
              </nobr>
            </h3>
          </template>


        </b-table>
      </b-col>
    </b-row>
    <b-modal id="paramScaleValueModal" hide-footer size="xl" :title="'Параметры'">
      <ParamScaleEdit @save-success="afterSave" v-bind:analit-id="this.analitId" v-bind:param-scale="currentParamScale"
                      v-bind:scale-id="currentScaleId"></ParamScaleEdit>
    </b-modal>
    <b-modal id="scaleNameModal" hide-footer size="lg" title="Шкала">
      <ScaleValueEdit @save-success="afterSaveScaleName" v-bind:error="error" v-bind:current-measurement-unit="currentScaleName"></ScaleValueEdit>
    </b-modal>
  </b-container>
</template>

<script>
import {host} from "@/constants";
import ParamScaleEdit from "@/components/admin/scales/ParamScaleEdit";
import ScaleValueEdit from "@/components/admin/scales/ScaleValueEdit";

export default {
  components: {ParamScaleEdit, ScaleValueEdit},
  props: ["analitId"],
  name: "ParamScaleList",
  data() {
    return {
      error: null,
      currentScaleId: 2,
      isDataReady: false,
      scaleOptions: [],
      fields: [
        // {
        //   key: 'scaleName',
        //   label: 'Шкала',
        //   sortable: false
        // },
        {
          key: 'sexName',
          label: 'Пол',
          sortable: false
        },
        {
          key: 'ageFrom',
          label: 'Возраст От',
          sortable: false
        },
        {
          key: 'ageTo',
          label: 'Возраст До',
          sortable: false
        },
        {
          key: 'valueFrom1',
          label: 'Значение',
          sortable: false
        },
        // {
        //   key: 'valueFrom',
        //   label: 'С',
        //   sortable: false
        // },
        // {
        //   key: 'valueTo',
        //   label: 'По',
        //   sortable: false
        // },
        // {
        //   key: 'reportText',
        //   label: '',
        //   sortable: false
        // },
        {
          key: 'zoneName',
          label: 'Заключение',
          sortable: false
        },
        {
          key: 'buttons',
          label: ' ',
          sortable: false
        }
      ],
      currentParamScale: null,
      currentScaleName: null
    }
  },
  methods: {
    async fetchDictsData() {
      let response = await fetch(host + '/rest/scales', {
        credentials: "include"
      })
      let json = await response.json()
      this.scaleOptions = json.options.filter(item => item.value !== 1)
    },
    async fetchData() {
      let response = await fetch(host + '/rest/params/' + this.analitId + '/scales', {
        credentials: "include"
      })
      let json = await response.json()
      for (let so of this.scaleOptions) {
        so.items = json.paramScales.filter(item => item.scaleId === so.value)
      }
    },
    fetchSyncData() {
      fetch(host + '/rest/params/' + this.analitId + '/scales', {
        credentials: "include"
      }).then(response => response.json())
          .then(json => {
            for (let so of this.scaleOptions) {
              so.items = json.paramScales.filter(item => item.scaleId === so.value)
              console.log ('Items size ' +  so.items.length)
            }
            console.log ('ScaleOptions Full')
            console.log (this.scaleOptions)
            this.isDataReady = true
            this.$forceUpdate()
          })
    },
    fetchSyncDictsData() {
      fetch(host + '/rest/scales', {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.scaleOptions = json.options.filter(item => item.value !== 1)
            console.log ('ScaleOptions')
            console.log (JSON.stringify(this.scaleOptions))
            this.fetchSyncData()
          })
    },
    async afterSave() {

      this.$bvModal.hide('paramScaleValueModal')
      //console.log('Start afterSave')
      this.fetchSyncDictsData()
      //await this.fetchData()
      //this.$forceUpdate()
    },
    async afterSaveScaleName() {

      this.fetchSyncDictsData()
      //await this.fetchData()
      this.$bvModal.hide('scaleNameModal')
      //this.$forceUpdate()
    },
    editScaleName(item) {
      this.currentScaleName = item
      this.$bvModal.show('scaleNameModal')
    },
    editParamScaleValue(selectedScaleId, item = null) {
      if (item) {
        this.currentParamScale = Object.assign({}, item)
      } else {
        this.currentParamScale = null
      }

      this.currentScaleId = selectedScaleId
      this.$bvModal.show('paramScaleValueModal')
    },
    deleteRubricatorValue(paramId) {
      this.error = null;
      if (confirm('Вы уверены, что хотите удалить интервальную шкалу?')) {
        this.loading = true;
        fetch(host + '/rest/params/' + this.analitId + '/scales/' + paramId, {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {
                for (let so of this.scaleOptions) {
                  so.items = so.items.filter(item => item.paramScaleId !== paramId)
                }
                //this.items = this.items.filter(item => item.paramScaleId !== paramId)

                console.log('Delete Success ' + json)

              } else {

                console.log('Delete Failed ' + json)
                this.error = json.message;
              }
              this.loading = false
              this.isDataReady = true
            }).catch(error => {
          this.error = error
          console.log(JSON.stringify(error))
          this.loading = false
        })
      }
    }
  },
  async mounted() {
    await this.fetchDictsData()
    await this.fetchData()
    this.isDataReady = true
  },
  filters: {
    valueText1: function (value, unitName, finishValue) {
      if (value && finishValue) {
        return '<b>' + value + '</b> - <b>' + finishValue + '</b> ' + unitName
      } else if (value) {
        return 'выше <b>' + value + '</b> ' + unitName
      } else if (finishValue) {
        return 'до <b>' + finishValue + '</b> ' + unitName
      }
      return '-'
    },
    valueText: function (value, unitName) {
      if (value) {
        return '<b>' + value + '</b> ' + unitName
      }
      return '-'
    },
    ageText: function (item, year, month, day) {
      let resultText = ''
      if (year && year > 0) {
        resultText += '<b>' + year + '</b>'
        if (year >= 5 && year <= 20) {
          resultText += ' лет '
        } else {
          const modyear = year % 10
          switch (modyear) {
            case 1:
              resultText += ' год '
              break
            case 2:
            case 3:
            case 4:
              resultText += ' года '
              break
            default:
              resultText += ' лет '
          }
        }
      }
      if (month && month > 0) {
        resultText += '<b>' + month + '</b>'
        if (month >= 5 && month <= 20) {
          resultText += ' месяцев '
        } else {
          const modyear = month % 10
          switch (modyear) {
            case 1:
              resultText += ' месяц '
              break
            case 2:
            case 3:
            case 4:
              resultText += ' месяца '
              break
            default:
              resultText += ' месяцев '
          }
        }
      }
      if (day && day > 0) {
        resultText += '<b>' + day + '</b>'
        if (day >= 5 && day <= 20) {
          resultText += ' дней '
        } else {
          const modyear = day % 10
          switch (modyear) {
            case 1:
              resultText += ' день '
              break
            case 2:
            case 3:
            case 4:
              resultText += ' дня '
              break
            default:
              resultText += ' дней '
          }
        }
      }

      return resultText
    }
  }
}
</script>

<style scoped>
h3.buttons {
  text-align: -webkit-right !important;
  text-align: -moz-right !important;
  text-align: right !important;
}
</style>