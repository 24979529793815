<template>
  <b-container fluid class="p-0 m-0">
    <b-row class="ml-0 mr-0">
      <b-table striped hover :items="prescribedBlanks | prescribedBlanksFilter(isFiltered)" :fields="prescribedBlanksFields" ref="prescribedBlanks"
               :tbody-tr-class="'table-row'"
      >

        <template v-slot:cell(createDate)="data">
          {{ new Date(data.item.createDate).toLocaleDateString() }}
        </template>
        <template v-slot:cell(buttons)="data">
          <div class="justify-content-end">
            <nobr>
              <b-link @click="showDetails(data)" title="Показать назначенные исследования" class="ml-3" ><img src="@/assets/images/patients/icon_details.png"></b-link>
              <b-link @click="openAnalitPdfReport(data.item)" title="Распечатать назначенные исследования" class="ml-3"><img src="@/assets/images/patients/icon_pdf.png"></b-link>

              <b-link @click="showModalBlankName(data.item)" title="Редактировать" class="ml-3"><img src="@/assets/images/patients/icon_edit.png"></b-link>
              <!--              &nbsp;-->
              <b-link v-if="role===2" @click="deleteMeasurementUnit(data.item.prescribedBlankId, data.item.prescribedBlankName)" title="Удалить" class="ml-3"><img src="@/assets/images/patients/icon_delete.png"></b-link>
            </nobr>
          </div>
        </template>

        <template #row-details="data">
          <b-overlay spinner-variant="info" :opacity="0.5" :show="overlayShow">
            <b-card>
              <b-container v-if="!overlayShow || data.item.details">
                <b-row><strong>Назначенные исследования: <span v-if="!data.item.details || data.item.details.length === 0"> Отсутствуют</span></strong></b-row>
                <b-row class="newAttr" v-for="(d) in data.item.details" :key="d.paramId">
                  <b-col>
                    <img src="@/assets/images/Icon_OMG_20x20.png" v-if="d.omsCode" >
                  </b-col>
                  <b-col cols="11">
                    <b-icon :id="'param_def_' + d.paramId" icon="book"></b-icon>
                    <b-tooltip  :target="'param_def_' + d.paramId" triggers="hover">
                      <span v-html="d.paramDefinition"></span>
                    </b-tooltip> &nbsp;
                    <strong>{{d.paramName}}</strong>, <strong>{{d.biomaterialTypeName}}</strong>
                  </b-col>
                </b-row>

              </b-container>
            </b-card>
          </b-overlay>
        </template>
      </b-table>

    </b-row>
    <b-row v-if="role === 2">
      <b-modal id="prescribedBlankModalEdit" hide-footer size="lg" title="Название бланка назначений">
        <form @submit.prevent="sendToPatient">
          <b-form-group label="Название бланка назначений" label-cols="3">
            <b-form-input type="text" required size="255" v-model="blankName"></b-form-input>
          </b-form-group>
          <b-form-group label-cols="3" label="Комментарий к бланку назначений" >
            <b-form-input type="text" size="255" v-model="blankComment"></b-form-input>
          </b-form-group>
          <b-button type="submit" class="btn-info" :disabled="saving">
            {{ blankId ? 'Сохранить' : 'Отправить бланк назначений пациенту'}}<b-spinner v-if="saving" variant="light" small></b-spinner>
          </b-button>
        </form>
      </b-modal>
    </b-row>
  </b-container>
</template>

<script>
import {host} from "@/constants";

export default {
  props: ["patientId", "isFiltered"],
  computed: {
    role: {
      get() {
        return this.$store.getters.role
      }
    },
  },
  name: "PrescribedBlanksTable",
  filters: {
    prescribedBlanksFilter: function (options, isFiltered) {
      if (isFiltered) {
        return options.filter((el, index) => { return index < 3})
      } else {
        return options
      }


    }
  },
  data() {
    return {
      saving:false,
      blankId:0,
      blankName: 'Назначения от ' + new Intl.DateTimeFormat('ru-RU').format(new Date()),
      blankComment: '',
      prescribedBlanks:[],
      overlayShow: true,
      prescribedBlanksFields:[
        {
          key: 'createDate',
          label: 'Дата создания',
          sortable: true,
          thClass: 'border-top-none'
        },
        {
          key: 'prescribedBlankName',
          label: 'Название',
          sortable: false,
          thClass: 'border-top-none'
        },
        {
          key: 'prescribedBlankComment',
          label: 'Комментарий к бланку',
          sortable: false,
          thClass: 'border-top-none'
        },
        {
          key: 'buttons',
          label: ' ',
          sortable: false,
          class: 'text-right',
          thClass: 'border-top-none'
        }
      ]
    }
  },
  methods: {
    sendToPatient() {
      this.saving = true

      let method = 'POST'
      let request = { prescribedBlankName: this.blankName, prescribedBlankComment: this.blankComment }
      if (this.blankId) {
        method = 'PUT'
        request = { prescribedBlankName: this.blankName, prescribedBlankComment: this.blankComment, prescribedBlankId: this.blankId }
      }
      fetch(host + '/rest/patients/' + this.patientId + '/prescribes', {
        credentials: "include",
        method: method,
        body: JSON.stringify(request),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      })
          .then(response => response.json())
          .then(json => {

            this.saving = false
            if (json.resultCode === 0) {
              this.$messageToast('Бланк назначений')
              this.$bvModal.hide('prescribedBlankModalEdit')
              this.fetchPrescribedBlanks()
            } else {
              this.$errorToast(json.resultCode + ' ' + json.message)
            }

          }).catch(error => {
        console.log(error)
        this.saving = false
      })
    },
    showModalBlankName(item = null) {
      if (item) {
        this.blankId = item.prescribedBlankId
        this.blankName = item.prescribedBlankName
        this.blankComment = item.prescribedBlankComment
      } else {
        this.blankId = null
        this.blankName = 'Назначение от ' + new Intl.DateTimeFormat('ru-RU').format(new Date())
        this.blankComment = null
      }
      this.$bvModal.show('prescribedBlankModalEdit')
    },
    showDetails(data) {
      this.overlayShow = true
      data.toggleDetails()

      if (!data.item.details) {
        fetch(host + '/rest/patients/' + this.patientId + '/prescribes/' + data.item.prescribedBlankId + '/params', {
          credentials: "include"
        })
            .then(response => response.json())
            .then(json => {
              data.item.details = json.params
              console.log(json)
              this.overlayShow = false
            }).catch(error => {
          this.overlayShow = false
        })
      } else {
        this.overlayShow = false
      }
    },
    openAnalitPdfReport(item = null) {
      window.open(host + "/rest/reports/PrescribedBlank/patient/" + this.patientId + "/prescribed/" + item.prescribedBlankId , "_blank");

    },
    deleteMeasurementUnit(paramId, paramName) {
      this.error = null;
      if (confirm('Вы уверены, что хотите удалить бланк назначений "' + paramName + '"?')) {
        fetch(host + '/rest/patients/' + this.patientId + '/prescribes/' + paramId, {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {
                this.prescribedBlanks = this.prescribedBlanks.filter(item => item.prescribedBlankId !== paramId)

              } else {

                this.error = json.message;
              }

            }).catch(error => {

          console.log(JSON.stringify(error))
        })
      }
    },
    fetchPrescribedBlanks() {
      fetch(host + '/rest/patients/' + this.patientId + '/prescribes', {
        credentials: "include",
        method: 'GET'
      })
          .then(response => response.json())
          .then(json => {

            this.saving = false
            if (json.resultCode === 0) {
              this.prescribedBlanks = json.blanks
            } else {
              this.$errorToast(json.resultCode + ' ' + json.message)
            }

          }).catch(error => {
        console.log(error)
        this.saving = false
      })
    },
  },
  mounted() {
    this.fetchPrescribedBlanks()
  }
}
</script>

<style scoped>

</style>