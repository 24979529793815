<template>
  <div>
    <b-row>
      <b-col>
        <h4>Альтернативные единицы измерения
        <b-button class="btn-info" @click="addNew()">
          <b-icon icon="plus" aria-hidden="true"></b-icon>
          Добавить
        </b-button></h4>
      </b-col>
    </b-row>
    <b-row v-for="(measure, index) in measurementParams" :key="measure.value" class="row1remBottom">
      <b-col cols="5">
        <b-form-select id="measurementUnitId" required v-model="measure.value" placeholder="Выберите единицу измерения" :options="measureOptions"
                       :select-size="1"></b-form-select>
      </b-col>
      <b-col cols="1">&nbsp;</b-col>
      <b-col cols="2">Коэффициент</b-col>
      <b-col cols="3">
        <b-form-input v-mask="mask" id="coef" type="text" required placeholder="Введите значение"
                      v-model="measure.coef"></b-form-input>
      </b-col>
      <b-col cols="1">
        <b-button class="btn-info" @click="deleteParamMeasurement(analitId, measure, index)">
          <b-icon icon="x-circle" aria-hidden="true"></b-icon>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import {host} from "@/constants";

const currencyMask = createNumberMask({
  allowDecimal: true,
  includeThousandsSeparator: false,
  allowNegative: true,
  decimalSymbol: '.',
  decimalLimit: 10,
  prefix: '',
  suffix: ''
})
export default {
  name: "AnalitAlternativeMeasurements",
  props: ["analitId"],
  data() {
    return {
      measurementParams: [],
      mask: currencyMask,
      error: null,
      measureOptions: [],
    }
  },
  mounted: function () {
    this.fetchData()
    this.fetchDicts()
  },
  methods: {
    addNew() {
      this.measurementParams.push({value: null, text: '', coef: null})
    },
    fetchDicts() {
      fetch(host + '/rest/dicts/measurementunit')
          .then(response => response.json())
          .then(json => {
            this.measureOptions = json.options
            console.log(json)
          })
    },
    fetchData() {
      fetch(host + '/rest/dicts/parammeasurementunit/' + this.analitId, {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.measurementParams = []
            let arr = json.options
            for (let m of arr) {
              console.log(m.coef)
              if (!m.isMain) {
                m.fromServer = true
                this.measurementParams.push(m)
              }
            }
            console.log(JSON.stringify(json))
          })
    },
    async fetchDelete(paramId, value) {
      let response = await fetch(host + '/rest/params/' + paramId + '/measurement/' + value, {
        method: 'DELETE',
        credentials: 'include',
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      })
      let json = await response.json()
      if (json.resultCode != 0) {
        console.log('Delete Failed ' + json)
        this.$errorToast(json.resultCode + ' ' + json.message)
        this.error = json.message;
      }
    },
    async onSubmitMeasurementParams() {
      for (var m of this.measurementParams) {
        console.log(JSON.stringify(m))
        if (m.fromServer) {
          await this.fetchDelete(this.analitId, m.value)
        }
        let rq = {
          paramId: this.analitId,
          coef: m.coef,
          measurementUnitId: m.value
        }

        console.log(JSON.stringify(rq))
        let response = await fetch(host + '/rest/params/measurement', {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify(rq),
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        })
        let json = await response.json()
        if (json.resultCode != 0) {
          this.$errorToast(json.resultCode + ' ' + json.message)
        }
            // .catch(error => {
            //   this.error = error
            //   console.log(JSON.stringify(error))
            // })
      }
    },
    async deleteParamMeasurement(paramId, measure, index) {
      if (confirm('Вы уверены, что хотите удалить единицу измерения "' + measure.text + '" у данного аналита?')) {
        if (measure.fromServer) {
          await this.fetchDelete(paramId, measure.value)
        }
        this.measurementParams.splice(index, 1)
      }
    }
  }
}
</script>

<style scoped>
.row1remBottom {
  padding-bottom: 1rem;
}

</style>