<template>
  <b-container fluid>
    <b-row>
<!--      <b>Фильтры:</b>-->
<!--      <b-form-select id="" v-model="selectedValue" :options="options" :select-size="1" @change="onFilterSelect"></b-form-select>-->
      <b-col cols="5" class="" >
        <div class="mt-2" v-if="rubr.value =='MedicalSpecialty'">
          Выбор клинического раздела
        </div>
        <div class="mt-2" v-if="rubr.value =='LabStateTissueOrgan'">
          Выбор органов или видов обмена
        </div>
      </b-col>
      <b-col cols="7" >
        <treeselect class="custom-select_2 border-0 w-100 col-12  parametrs padding_0"
                    name="param" id="param" placeholder=""
                    clearable searchable :multiple="true"
                    open-on-click close-on-select disableFuzzyMatching
                    :noResultsText="'Не найдено...'"
                    :noOptionsText="'Список опций пуст'"
                    :options="options"
                    :max-height="200"
                    :normalizer="normalizeTreeselect"
                    v-model="selectedValues" style="font-size: 15px!important;" @select="onFilterSelect" @deselect="onFilterDeselect">

        </treeselect>
      </b-col>
    </b-row>
<!--      <b-form-group v-for="r in rubricators" id="r.value" label-for="" :key="r.rubricatorId" label-cols="6">-->
<!--        <template slot="label">{{ r.text }}</template>-->
<!--        <b-form-select id="" v-model="r.selectedValue" :options="r.options" :select-size="1" @change="onFilterSelect"></b-form-select>-->
<!--      </b-form-group>-->

    <hr width="100%" color="gray">
    <b-row>
      <b-col>
        <button class="save_btn_common" @click="addAllParams()">Назначить все исследования</button>
      </b-col>
      <b-col class="text-right">
        <button class="save_btn_common_orange" @click="clearRecommendedAnalits()">Очистить список исследований</button>
      </b-col>
    </b-row>
    <b-row v-for="(item, index) in recommendedParams" :key="item.paramId">
      <b-col >
        <img src="@/assets/images/Icon_OMG_20x20.png" v-if="item.omsCode" >
<!--        <b-icon v-if="item.omsCode" variant="info" icon="award-fill" size="lg" ></b-icon>-->
      </b-col>
      <b-col cols="8">
        <b-icon :id="'param_def_' + item.paramId" icon="book"></b-icon>
        <b-tooltip  :target="'param_def_' + item.paramId" triggers="hover">
          <span v-html="item.paramDefinition"></span>
        </b-tooltip> &nbsp;
        {{ item.paramName }}, {{ item.biomaterialTypeName }}
      </b-col>
      <b-col cols="2">
        <b-link @click="editClientAnalit(item)">Назначить</b-link>
      </b-col>
      <b-col><b-link @click="deleteRecommendedAnalit(index)" title="Удалить"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link></b-col>
    </b-row>
  </b-container>
</template>

<script>
import {host} from "@/constants";
import Treeselect from "@riophae/vue-treeselect";

export default {
  props: {
    rubr: Object
  },
  components: {
    Treeselect
  },
  name: "PatientRubricatorRecommendations",
  data() {
    return {
      rubricators: [],
      recommendedParams: [],
      selectedValue: null,
      selectedValues: [],
      options: [],
      normalizeTreeselect(node) {
        return {
          id: node.value,
          label: node.text
        }
      }
    }
  },
  mounted() {

    fetch(host + '/rest/rubricators/' + this.rubr.value, {
      credentials: "include"
    })
        .then(response => response.json())
        .then(json => {
          this.options = json.options
          //this.options.splice(0,0,{value: null, text: ''})
          this.$forceUpdate()
        })

    // fetch(host + '/rest/rubricators/forDoctor', {
    //   credentials: "include"
    // })
    //     .then(response => response.json())
    //     .then(json => {
    //       this.rubricators = json.options
    //       for (let p of this.rubricators) {
    //         p.options = []
    //         p.selectedValue = null
    //
    //       }
    //     })
  },
  methods: {
    deleteRecommendedAnalit(index) {
      this.recommendedParams.splice(index, 1)
    },
    clearRecommendedAnalits() {
      this.recommendedParams = []
    },
    editClientAnalit(item = null) {
      this.$emit('selectParam', item)
    },
    addAllParams() {
      this.$emit('selectParams', this.recommendedParams)
    },
    async onFilterSelect(node) {

      let selectedVals = [].concat(this.selectedValues)
      selectedVals.push(node.value)
      await this.callData(selectedVals)
    },
    async onFilterDeselect(node) {

      let selectedVals = this.selectedValues.filter(el =>(el !== node.value))
      await this.callData(selectedVals)
    },
    async callData(selectedVals) {
      console.log('onFilterSelect started ' + selectedVals)

      this.recommendedParams = []
      for (let r of selectedVals) {
        let filterRq = {}
        switch (this.rubr.rubricatorId) {
          case 1: filterRq.medicalSpecialtyId = r; break;
          case 2: filterRq.metabolismTypeId = r; break;
          case 3: filterRq.labStateTissueOrganId = r; break;
          case 4: filterRq.conditionAndSyndromeId = r; break;
          case 5: filterRq.methodId = r; break;
          case 6: filterRq.complexExaminationId = r; break;
          case 7: filterRq.laboratoryId = r; break;
        }
        let method = 'POST'

        let response = await fetch(host + '/rest/params/filter', {
          method: method,
          credentials: 'include',
          body: JSON.stringify(filterRq),
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        })
        let json = await response.json()

        let newParams = json.params.filter(el => (!this.recommendedParams.find(el1 => (el1.paramId === el.paramId))))
        this.recommendedParams = this.recommendedParams.concat(newParams)
        // for (let p of json.params) {
        //   if (!this.recommendedParams.find(el => (el.paramId === p.paramId))) {
        //     this.recommendedParams.push(p)
        //   }
        // }

      }


    }
  }
}
</script>

<style scoped>

</style>