<template>
  <b-container fluid>
    <b-row class="newStr">
      <strong>Рубрикатор "{{currentRubricator.text}}"</strong>
    </b-row>
    <b-row class="newStr">
      <b-col>
        <b-form-checkbox v-model="currentRubricator.enableForDoctor" name="enable-for-doctor-check-button" switch @change="saveEnableData()">
          Доступно для врача
        </b-form-checkbox>
      </b-col>
      <b-col>
        <b-form-checkbox v-model="currentRubricator.enableForAdmin" name="enable-for-admin-check-button" switch @change="saveEnableData()">
          Доступно для администратора
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row class="newStr">
      <b-button variant="info" @click="editRubricatorValue()">Создать значение рубрикатора</b-button>
    </b-row>
    <b-row class="newStr">
      <div v-if="error" class="alert alert-danger">
        {{error}}
      </div>
    </b-row>
    <b-row  v-if="currentRubricator.value === 'ConditionAndSyndrome' && !loading">
      <b-tree-view :data="items" :nodeKeyProp="'value'" :nodeLabelProp="'text'" :renameNodeOnDblClick="false" :contextMenuItems="contextMenuItems" @contextMenuItemSelect="contextMenuSelected" />
    </b-row>
    <b-row v-else-if="currentRubricator.value !== 'ConditionAndSyndrome' && !loading">
      <b-table striped hover :items="items" :fields="fields" ref="measurementUnitTable">
        <!-- A custom formatted column -->
        <template v-slot:cell(value)="data">
          <b>{{ data.item.value }}</b>
        </template>
        <template v-slot:cell(text)="data">
          <span v-html="data.item.text"></span>
        </template>
        <template v-slot:cell(buttons)="data">
          <h3>
            <nobr>
              <b-link @click="editRubricatorValue(data.item)" ><b-icon variant="info" icon="pencil" size="lg" ></b-icon></b-link>
              &nbsp;
              <b-link @click="deleteRubricatorValue(data.item.value, data.item.text)"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link>
            </nobr>
          </h3>
        </template>
      </b-table>
    </b-row>
    <b-row>
      <b-modal id="measurementUnitModal" hide-footer size="lg" :title="'Значение рубрикатора ' + this.currentRubricator.text">
        <RubricatorValueEdit @save-success="afterSave" v-bind:error="error" v-bind:current-rubricator-value="currentMeasurementUnit" v-bind:current-rubricator="currentRubricator" v-bind:is-hierarchical="isHierarchical"></RubricatorValueEdit>
      </b-modal>
      <Loader v-bind:showLoader="loading" />
    </b-row>
  </b-container>
</template>

<script>
import {host} from "@/constants"
import RubricatorValueEdit from "@/components/admin/rubricators/RubricatorValueEdit"
import Loader from "@/components/Loader";
import { bTreeView } from 'bootstrap-vue-treeview'
export default {
  props:{
    rubr: Object
  },
  name: "RubricatorValueList",
  components: {
    Loader, RubricatorValueEdit, bTreeView
  },
  data() {
    return {
      fields: [
        {
          key: 'value',
          label: 'Id',
          sortable: true
        },
        {
          key: 'text',
          label: 'Наименование',
          sortable: true
        },
        {
          key: 'buttons',
          label: ' ',
          sortable: false
        }
      ],
      items: [],
      loading: true,
      error: null,
      currentRubricator: this.rubr,
      currentMeasurementUnit: null,
      isHierarchical: false,
      contextMenuItems: [ { code: 'RENAME', label: 'Изменить' }, { code: 'DELETE', label: 'Удалить' }  ]
    }
  },
  methods: {
    contextMenuSelected(contextMenuItem, dictItem){
      console.log('contextMenuItem ' + JSON.stringify(contextMenuItem))
      console.log('dictItem ' + JSON.stringify(dictItem.data))
      if (contextMenuItem.code === 'DELETE') {
        this.deleteRubricatorValue(dictItem.data.value, dictItem.data.text)
      }
      if (contextMenuItem.code === 'RENAME') {
        console.log('Renaming ')
        this.editRubricatorValue(dictItem.data)
      }
    },
    deleteRubricatorValue(paramId, paramName) {
      this.error = null;
      if (confirm('Вы уверены, что хотите удалить значение рубрикатора "' + paramName + '"')) {
        this.loading = true;
        fetch(host + '/rest/rubricators/' + this.currentRubricator.value + '/' + paramId, {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {
                this.loading = true
                this.fetchData()

                console.log('Delete Success ' + json)

              } else {

                console.log('Delete Failed ' + json)
                this.error = json.message;
              }
              this.loading = false

            }).catch(error => {
          this.error = error
          console.log(JSON.stringify(error))
          this.loading = false
        })
      }
    },
    async saveEnableData() {
      await this.$nextTick()
      fetch(host + '/rest/rubricators', {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify(this.currentRubricator),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode !== 0) {

              this.error = json.message;
            }
            this.loading = false

          }).catch(error => {
            this.error = error
            console.log(JSON.stringify(error))
            this.loading = false
          })
    },
    editRubricatorValue(item = null) {
      console.log('edit  ' + JSON.stringify(item))
      this.currentMeasurementUnit = item
      this.isHierarchical = (this.currentRubricator.value === 'ConditionAndSyndrome')
      this.$bvModal.show('measurementUnitModal')
    },
    fetchData() {
      fetch(host + '/rest/rubricators/' + this.currentRubricator.value, {
        credentials: 'include'
      })
          .then(response => response.json())
          .then(json => {
            this.items = json.options
            this.loading = false
            console.log(json)
          })
    },
    afterSave() {
      this.$bvModal.hide('measurementUnitModal')
      this.loading = true
      this.fetchData()
    }
  },
  mounted() {
    this.fetchData()
  },
  watch: {
    rubr: function (val) {
      this.loading = true
      this.currentRubricator = val
      this.fetchData()
    },
  }
}
</script>

<style scoped>
h3 {
  text-align: -webkit-right!important;
  text-align: -moz-right!important;
  text-align: right!important;
}
.newStr {
  padding-top: 0.8rem;
}

</style>