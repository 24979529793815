<template>
  <div>
    <b-container v-for="(f, index) in factors" :key="f.value">
      <b-row>
        <h4>{{ f.text }}</h4>
      </b-row>
      <b-row>
        <b-button variant="info" @click="editParamFactorBandValue(f)">
          <b-icon icon="plus" aria-hidden="true"></b-icon>
          Добавить
        </b-button>
      </b-row>
      <b-row>
        <b-table striped hover :items="f.factorBands" :fields="fields">
          <template v-slot:head(zoneOrProperty)="data">
            {{ (paramType === 2 || paramType === 7) ? 'Вариант': 'Интервал'}}
          </template>
          <template v-slot:cell(zoneOrProperty)="data">
            {{ (paramType === 2 || paramType === 7) ? ((data.item.propertyName ? data.item.propertyName :'')  + (data.item.propertyValueName? (', ' + data.item.propertyValueName) : '' )) : data.item.zoneName}}
          </template>
          <template v-slot:cell(buttons)="data">
            <h5 class="buttons">
              <nobr>
                <b-link @click="editParamFactorBandValue(f, data.item)">
                  <b-icon variant="info" icon="pencil" size="lg"></b-icon>
                </b-link>
                &nbsp;
                <b-link @click="deleteRubricatorValue(f, data.item.paramFactorBandId)">
                  <b-icon variant="info" icon="x-circle" size="lg"></b-icon>
                </b-link>
              </nobr>
            </h5>
          </template>
        </b-table>


      </b-row>
      <b-row>&nbsp;</b-row>

    </b-container>
    <b-modal :id="'paramFactorBandModal' + factors[0].value" hide-footer size="lg" :title="currentFactorText">
      <FactorBandEdit @save-success="afterSave" v-bind:analit-id="this.analitId" v-bind:factor-band="currentFactorBand"
                      v-bind:factor-value="currentFactorValue" v-bind:factor-text="currentFactorText" v-bind:param-type="this.paramType"></FactorBandEdit>
    </b-modal>


  </div>
</template>

<script>
import {host} from "@/constants";
import FactorBandEdit from "@/components/admin/analits/FactorBandEdit";

export default {
  components: {FactorBandEdit},
  props: ["analitId", "paramType", "factorName"],
  name: "FactorBandList",
  data() {
    return {
      fields: [
        {
          key: 'factorName',
          label: 'Наименование'
        },
        {
          key: 'sexName',
          label: 'Пол'
        },
        {
          key: 'zoneOrProperty',
          label: this.paramType === 7 || this.paramType === 2 ? 'Вариант': 'Интервал',
          sortable: false
        },
        {
          key: 'priorityReport',
          label: 'Приоритет',
          sortable: true
        },
        {
          key: 'buttons',
          label: ' ',
          sortable: false
        }
      ],
      error: "",
      loading: true,
      factors: [
        {value: "Disease", text: "Патологические состояния и причины отклонений", factorBands: []},
        {value: "Drug", text: "Лекарственные препараты", factorBands: []},
        {value: "RiskFactor", text: "Факторы внешней среды и особые состояния организма", factorBands: []},
        {value: "LaboratoryError", text: "Лабораторные ошибки", factorBands: []}
      ].filter(el => (this.factorName == el.value)),
      currentFactorBand: null,
      currentFactorValue: null,
      currentFactorText: ''
    }
  },
  methods: {
    afterSave() {
      this.$bvModal.hide('paramFactorBandModal' + this.factors[0].value)
      this.loading = true
      this.fetchFactorsList()
    },
    editParamFactorBandValue(factor, item = null) {
      this.currentFactorBand = item
      this.currentFactorValue = factor.value
      this.currentFactorText = factor.text
      this.$bvModal.show('paramFactorBandModal' + this.factors[0].value)
    },
    deleteRubricatorValue(factor, paramId) {
      this.error = null;
      if (confirm('Вы уверены, что хотите удалить параметр?')) {
        this.loading = true;
        fetch(host + '/rest/params/' + this.analitId + '/factors/' + factor.value + '/' + paramId, {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {


                console.log('Delete Success ' + json)

              } else {

                console.log('Delete Failed ' + json)
                this.error = json.message;
              }
              this.loading = false
              this.fetchFactorList(factor)
            }).catch(error => {
          this.error = error
          console.log(JSON.stringify(error))
          this.loading = false
        })
      }
    },
    fetchFactorsList() {
      for (let f of this.factors) {
        this.fetchFactorList(f)
      }
    },
    fetchFactorList(factor) {
      fetch(host + '/rest/params/' + this.analitId + '/factors/' + factor.value, {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            factor.factorBands = json.factorBands
            console.log(JSON.stringify(json))
          })
    }
  },
  mounted() {
    this.fetchFactorsList()

    console.log('factorName = ' + this.factorName)
  }
}
</script>

<style scoped>
.buttons {
  text-align: -webkit-right!important;
  text-align: -moz-right!important;
  text-align: right!important;
}
</style>