<template>
  <div>
    <b-form @submit.prevent="onSubmit">

      <!--      MedicalSpecialty("Клинические разделы"),-->
      <!--      MetabolismType("Виды обмена"),-->
      <!--      LabStateTissueOrgan("Лабораторные состояния систем, тканей и органов"),-->
      <!--      ConditionAndSyndrome("Патологические состояния и синдромы")-->
      <!--      Method("Метод определения аналитов"),-->
      <!--      ComplexExamination("Комплексное исследование аналитов"),-->
      <!--      Laboratory("Лаборатории")-->

      <!--      <b-form-group id="methods" label-for="methods" label="Метод определения аналитов" >-->
      <label  v-if="checkRubricator('Method')"  class="editLabel" for="methods">Метод определения аналитов</label><br>
      <b-button  v-if="checkRubricator('Method')"  variant="info" @click="editRubricatorValue(rubricators[4])"><b-icon icon="plus" aria-hidden="true"></b-icon>
        Добавить
      </b-button>
      <treeselect  v-if="checkRubricator('Method')"  name="methods" id="methods" placeholder=""
                  multiple clearable searchable
                  open-on-click close-on-select :flat="true"
                  :noResultsText="'Не найдено...'"
                  :options="methodsOptions"
                  :max-height="200"
                  :normalizer="normalizeTreeselect"
                  v-model="methods" />
      <!--      <h5>-->
      <!--        <span class="badgeSelected" v-for="(item, index) in methods" :key="item">-->
      <!--          <b-badge variant="info" >{{ item | filterValueText(methodsOptions) }} &nbsp;-->
      <!--            <b-icon style="cursor: pointer;" size="sm" icon="x-circle" aria-hidden="true" @click="deleteFromSelected(index, methods)"></b-icon>-->
      <!--          </b-badge>-->
      <!--        </span>-->
      <!--      </h5>-->
      <!--      <b-form-select id="methods" v-model="methods" multiple :options="methodsOptions" :select-size="5"></b-form-select>-->
      <!--      </b-form-group>-->
      <!--      <b-form-group id="complexExaminations" label-for="complexExaminations" label="Комплексное исследование аналитов" >-->
      <label v-if="checkRubricator('ComplexExamination')" class="editLabel" for="complexExaminations">Комплексное исследование аналитов</label><br>
      <b-button v-if="checkRubricator('ComplexExamination')" variant="info" @click="editRubricatorValue(rubricators[5])"><b-icon icon="plus" aria-hidden="true"></b-icon>
        Добавить
      </b-button>
      <treeselect v-if="checkRubricator('ComplexExamination')" name="complexExaminations" id="complexExaminations" placeholder=""
                  multiple clearable searchable
                  open-on-click close-on-select :flat="true"
                  :noResultsText="'Не найдено...'"
                  :options="complexExaminationsOptions"
                  :max-height="200"
                  :normalizer="normalizeTreeselect"
                  v-model="complexExaminations" />
      <!--      <h5>-->
      <!--        <span class="badgeSelected" v-for="(item, index) in complexExaminations" :key="item">-->
      <!--          <b-badge variant="info" >{{ item | filterValueText(complexExaminationsOptions) }} &nbsp;-->
      <!--            <b-icon style="cursor: pointer;" size="sm" icon="x-circle" aria-hidden="true" @click="deleteFromSelected(index, complexExaminations)"></b-icon>-->
      <!--          </b-badge>-->
      <!--        </span>-->
      <!--      </h5>-->
      <!--      <b-form-select id="complexExaminations" v-model="complexExaminations" multiple-->
      <!--                     :options="complexExaminationsOptions" :select-size="5"></b-form-select>-->
      <!--      </b-form-group>-->

      <!--      <b-form-group id="medicalSpecialties" label-for="medicalSpecialties" label="Клинические разделы" >-->
      <label v-if="checkRubricator('MedicalSpecialty')" class="editLabel" for="medicalSpecialties">Клинические разделы</label><br>
<!--      <b-button variant="info" @click="editRubricatorValue(rubricators[0])"><b-icon icon="plus" aria-hidden="true"></b-icon>-->
<!--        Добавить-->
<!--      </b-button>-->
      <treeselect v-if="checkRubricator('MedicalSpecialty')" name="medicalSpecialties" id="medicalSpecialties" placeholder=""
                  multiple clearable searchable
                  open-on-click close-on-select :flat="true"
                  :noResultsText="'Не найдено...'"
                  :options="medicalSpecialtiesOptions"
                  :max-height="200"
                  :normalizer="normalizeTreeselect"
                  v-model="medicalSpecialties" />
<!--      <h5>-->
<!--        <span class="badgeSelected" v-for="(item, index) in medicalSpecialties" :key="item">-->
<!--          <b-badge variant="info" >{{ item | filterValueText(medicalSpecialtiesOptions) }} &nbsp;-->
<!--            <b-icon style="cursor: pointer;" size="sm" icon="x-circle" aria-hidden="true" @click="deleteFromSelected(index, medicalSpecialties)"></b-icon>-->
<!--          </b-badge>-->
<!--        </span>-->
<!--      </h5>-->
<!--      <b-form-select id="medicalSpecialties" v-model="medicalSpecialties" multiple :options="medicalSpecialtiesOptions"-->
<!--                     :select-size="5"></b-form-select>-->
      <!--      </b-form-group>-->
      <!--      <b-form-group id="metabolismTypes" label-for="metabolismTypes" label="Виды обмена" >-->
<!--      <label class="editLabel" for="metabolismTypes">Виды обмена</label><br>-->
<!--      <b-button variant="info" @click="editRubricatorValue(rubricators[1])"><b-icon icon="plus" aria-hidden="true"></b-icon>-->
<!--        Добавить-->
<!--      </b-button>-->
<!--      <treeselect name="metabolismTypes" id="metabolismTypes" placeholder=""-->
<!--                  multiple clearable searchable-->
<!--                  open-on-click close-on-select :flat="true"-->
<!--                  :noResultsText="'Не найдено...'"-->
<!--                  :options="metabolismTypesOptions"-->
<!--                  :max-height="200"-->
<!--                  :normalizer="normalizeTreeselect"-->
<!--                  v-model="metabolismTypes" />-->
<!--      <h5>-->
<!--        <span class="badgeSelected" v-for="(item, index) in metabolismTypes" :key="item">-->
<!--          <b-badge variant="info" >{{ item | filterValueText(metabolismTypesOptions) }} &nbsp;-->
<!--            <b-icon style="cursor: pointer;" size="sm" icon="x-circle" aria-hidden="true" @click="deleteFromSelected(index, metabolismTypes)"></b-icon>-->
<!--          </b-badge>-->
<!--        </span>-->
<!--      </h5>-->
<!--      <b-form-select id="metabolismTypes" v-model="metabolismTypes" multiple :options="metabolismTypesOptions"-->
<!--                     :select-size="5"></b-form-select>-->
      <!--      </b-form-group>-->
      <!--      <b-form-group id="labStateTissueOrgans" label-for="labStateTissueOrgans" label="Лабораторные состояния систем, тканей и органов" >-->
      <label v-if="checkRubricator('LabStateTissueOrgan')" class="editLabel" for="labStateTissueOrgans">Лабораторные состояния систем, тканей и органов</label><br>
<!--      <b-button variant="info" @click="editRubricatorValue(rubricators[2])"><b-icon icon="plus" aria-hidden="true"></b-icon>-->
<!--        Добавить-->
<!--      </b-button>-->
      <treeselect v-if="checkRubricator('LabStateTissueOrgan')" name="labStateTissueOrgans" id="labStateTissueOrgans" placeholder=""
                  multiple clearable searchable
                  open-on-click close-on-select :flat="true"
                  :noResultsText="'Не найдено...'"
                  :options="labStateTissueOrgansOptions"
                  :max-height="200"
                  :normalizer="normalizeTreeselect"
                  v-model="labStateTissueOrgans" />
<!--      <h5>-->
<!--        <span class="badgeSelected" v-for="(item, index) in labStateTissueOrgans" :key="item">-->
<!--          <b-badge variant="info" >{{ item | filterValueText(labStateTissueOrgansOptions) }} &nbsp;-->
<!--            <b-icon style="cursor: pointer;" size="sm" icon="x-circle" aria-hidden="true" @click="deleteFromSelected(index, labStateTissueOrgans)"></b-icon>-->
<!--          </b-badge>-->
<!--        </span>-->
<!--      </h5>-->
<!--      <b-form-select id="labStateTissueOrgans" v-model="labStateTissueOrgans" multiple-->
<!--                     :options="labStateTissueOrgansOptions" :select-size="5"></b-form-select>-->
      <!--      </b-form-group>-->
      <!--      <b-form-group id="conditionAndSyndromes" label-for="conditionAndSyndromes" label="Патологические состояния и синдромы" >-->
      <label v-if="checkRubricator('ConditionAndSyndrome')" class="editLabel" for="conditionAndSyndromes">Патологические состояния и синдромы</label><br>
<!--      <b-button variant="info" @click="editRubricatorValue(rubricators[3])"><b-icon icon="plus" aria-hidden="true"></b-icon>-->
<!--        Добавить-->
<!--      </b-button>-->
<!--      <h5>-->
<!--        <span class="badgeSelected" v-for="(item, index) in conditionAndSyndromes" :key="item">-->
<!--          <b-badge variant="info" >{{ item | filterValueText(conditionAndSyndromesOptions) }} &nbsp;-->
<!--            <b-icon style="cursor: pointer;" size="sm" icon="x-circle" aria-hidden="true" @click="deleteFromSelected(index, conditionAndSyndromes)"></b-icon>-->
<!--          </b-badge>-->
<!--        </span>-->
<!--      </h5>-->
      <treeselect v-if="checkRubricator('ConditionAndSyndrome')" name="conditionAndSyndromes" id="conditionAndSyndromes" placeholder=""
                  multiple clearable searchable
                  open-on-click close-on-select :flat="true"
                  :noResultsText="'Не найдено...'"
                  :options="conditionAndSyndromesOptions"
                  :max-height="200"
                  :normalizer="normalizeTreeselect"
                  v-model="conditionAndSyndromes" />
<!--      <b-form-select id="conditionAndSyndromes" v-model="conditionAndSyndromes" multiple-->
<!--                     :options="conditionAndSyndromesOptions" :select-size="5"></b-form-select>-->
      <!--      </b-form-group>-->

      <!--      <b-form-group id="laboratories" label-for="laboratories" label="Лаборатории" >-->
      <label v-if="checkRubricator('Laboratory')" class="editLabel" for="laboratories">Лаборатории</label><br>
<!--      <b-button variant="info" @click="editRubricatorValue(rubricators[6])"><b-icon icon="plus" aria-hidden="true"></b-icon>-->
<!--        Добавить-->
<!--      </b-button>-->
      <treeselect v-if="checkRubricator('Laboratory')" name="laboratories" id="laboratories" placeholder=""
                  multiple clearable searchable
                  open-on-click close-on-select :flat="true"
                  :noResultsText="'Не найдено...'"
                  :options="laboratoriesOptions"
                  :max-height="200"
                  :normalizer="normalizeTreeselect"
                  v-model="laboratories" style="font-size: 15px!important;"/>
<!--      <h5>-->
<!--        <span class="badgeSelected" v-for="(item, index) in laboratories" :key="item">-->
<!--          <b-badge variant="info" >{{ item | filterValueText(laboratoriesOptions) }} &nbsp;-->
<!--            <b-icon style="cursor: pointer;" size="sm" icon="x-circle" aria-hidden="true" @click="deleteFromSelected(index, laboratories)"></b-icon>-->
<!--          </b-badge>-->
<!--        </span>-->
<!--      </h5>-->
<!--      <b-form-select id="laboratories" v-model="laboratories" multiple :options="laboratoriesOptions"-->
<!--                     :select-size="5"></b-form-select>-->
      <!--      </b-form-group>-->

      <!--      <b-form-group id="laboratorySections" label-for="laboratorySections" label="Лабораторные разделы" >-->
      <label v-if="checkRubricator('LaboratorySection')" class="editLabel" for="laboratories">Лабораторные разделы</label><br>
      <!--      <b-button variant="info" @click="editRubricatorValue(rubricators[7])"><b-icon icon="plus" aria-hidden="true"></b-icon>-->
      <!--        Добавить-->
      <!--      </b-button>-->
      <treeselect v-if="checkRubricator('LaboratorySection')" name="laboratorySections" id="laboratorySections" placeholder=""
                  multiple clearable searchable
                  open-on-click close-on-select :flat="true"
                  :noResultsText="'Не найдено...'"
                  :options="laboratorySectionsOptions"
                  :max-height="200"
                  :normalizer="normalizeTreeselect"
                  v-model="laboratorySections" style="font-size: 15px!important;"/>
      <!--      <h5>-->
      <!--        <span class="badgeSelected" v-for="(item, index) in laboratorySections" :key="item">-->
      <!--          <b-badge variant="info" >{{ item | filterValueText(laboratorySectionsOptions) }} &nbsp;-->
      <!--            <b-icon style="cursor: pointer;" size="sm" icon="x-circle" aria-hidden="true" @click="deleteFromSelected(index, laboratories)"></b-icon>-->
      <!--          </b-badge>-->
      <!--        </span>-->
      <!--      </h5>-->
      <!--      <b-form-select id="laboratorySections" v-model="laboratorySections" multiple :options="laboratorySectionsOptions"-->
      <!--                     :select-size="5"></b-form-select>-->
      <!--      </b-form-group>-->
    </b-form>
    <b-modal id="addingRubricatorModal" hide-footer size="lg" :title="'Значение рубрикатора '">
      <RubricatorValueEdit @save-success="afterSave" v-bind:error="error" v-bind:current-rubricator-value="curRubVal"
                           v-bind:current-rubricator="currentRubricator" v-bind:is-hierarchical="isHierarchical"></RubricatorValueEdit>
    </b-modal>

  </div>
</template>

<script>
import {host} from "@/constants";
import RubricatorValueEdit from "@/components/admin/rubricators/RubricatorValueEdit";
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  props: ["analitId"],
  name: "AnalitRubricators",
  data() {
    return {
      error: null,
      medicalSpecialties: [],
      medicalSpecialtiesOptions: [],
      metabolismTypes: [],
      metabolismTypesOptions: [],
      labStateTissueOrgans: [],
      labStateTissueOrgansOptions: [],
      conditionAndSyndromes: [],
      conditionAndSyndromesOptions: [],
      methods: [],
      methodsOptions: [],
      complexExaminations: [],
      complexExaminationsOptions: [],
      laboratories: [],
      laboratoriesOptions: [],
      laboratorySections: [],
      laboratorySectionsOptions: [],
      curRubVal: null,
      rubricators: [
        {value: "MedicalSpecialty", text: "Клинические разделы"},
        {value: "MetabolismType", text: "Виды обмена"},
        {value: "LabStateTissueOrgan", text: "Лабораторные состояния систем, тканей и органов"},
        {value: "ConditionAndSyndrome", text: "Патологические состояния и синдромы"},
        {value: "Method", text: "Метод определения аналитов"},
        {value: "ComplexExamination", text: "Комплексное исследование аналитов"},
        {value: "Laboratory", text: "Лаборатории"},
        {value: "LaboratorySection", text: "Лабораторный раздел"}
      ],
      currentRubricator: null,
      normalizeTreeselect(node) {
        return node.children ? {
          id: node.value,
          label: node.text,
          children: node.children
        } : {
          id: node.value,
          label: node.text
        }
      },
      isHierarchical: false
    }
  },
  filters: {
    filterValueText: function (value, options) {
      return options.filter(item => item.value === value)[0].text
    }
  },
  components: {
    RubricatorValueEdit, Treeselect
  },
  methods: {
    checkRubricator(name) {
      //console.log('checkRubr ' + this.rubricators.find(el => (el.value === name)).enableForAdmin)
      if (this.rubricators.find(el => (el.value === name)) && this.rubricators.find(el => (el.value === name)).enableForAdmin) {
        return true
      } else {
        return false
      }
    },
    deleteFromSelected(index, list) {
      list.splice(index, 1)
    },
    clickBackButton() {
      this.error = null
      this.$router.go(-1)
    },
    editRubricatorValue(item = null) {
      this.currentRubricator = item
      this.isHierarchical = (this.currentRubricator.value === 'ConditionAndSyndrome')
      this.$bvModal.show('addingRubricatorModal')
    },
    afterSave() {
      this.$bvModal.hide('addingRubricatorModal')
      this.fetchDictsData()
    },
    onSubmit() {
      this.error = null
      console.log("Параметры " + JSON.stringify(this.conditionAndSyndromes))
      console.log(JSON.stringify(this.analitId))
      let method = 'PUT'

      let rq = {
        analitId: this.analitId,
        paramMedicalSpecialties: this.medicalSpecialties,
        paramMetabolismTypes: this.metabolismTypes,
        paramLabStateTissueOrgans: this.labStateTissueOrgans,
        paramConditionAndSyndromes: this.conditionAndSyndromes,
        paramMethods: this.methods,
        paramComplexExaminations: this.complexExaminations,
        paramLaboratories: this.laboratories,
        paramLaboratorySections: this.laboratorySections
      }


      fetch(host + '/rest/params/rubricators', {
        method: method,
        credentials: 'include',
        body: JSON.stringify(rq),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {
              console.log('Save Success ' + JSON.stringify(json))

            } else {
              console.log('Save Failed ' + json)
              this.error = json.resultCode + ' ' + json.message;
              this.$bvToast.toast(json.resultCode + ' ' + json.message, {
                title: `Ошибка`,
                toaster: 'b-toaster-top-center',
                solid: true,
                variant: 'danger',
                noAutoHide: true,
                appendToast: true
              })
            }
          }).catch(error => {
        this.error = error
        this.$bvToast.toast(error, {
          title: `Ошибка`,
          toaster: 'b-toaster-top-center',
          solid: true,
          variant: 'danger',
          noAutoHide: true,
          appendToast: true
        })
        console.log(JSON.stringify(error))
      })


    },
    fetchData() {
      fetch(host + '/rest/rubricators', {
        credentials:'include'
      })
          .then(response => response.json())
          .then(json => {
            this.rubricators = json.options
            //this.loading = false
            console.log(json)
          })
    },
    fetchDictsData() {
      fetch(host + '/rest/rubricators/MedicalSpecialty', {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.medicalSpecialtiesOptions = json.options
            console.log(json)
          })
      fetch(host + '/rest/rubricators/MetabolismType', {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.metabolismTypesOptions = json.options
            console.log(json)
          })
      fetch(host + '/rest/rubricators/LabStateTissueOrgan', {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.labStateTissueOrgansOptions = json.options
            console.log(json)
          })
      fetch(host + '/rest/rubricators/ConditionAndSyndrome', {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.conditionAndSyndromesOptions = json.options
            this.$forceUpdate()
            console.log(json)
          })
      fetch(host + '/rest/rubricators/Method', {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.methodsOptions = json.options
            console.log(json)
          })
      fetch(host + '/rest/rubricators/ComplexExamination', {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.complexExaminationsOptions = json.options
            console.log(json)
          })
      fetch(host + '/rest/rubricators/Laboratory', {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.laboratoriesOptions = json.options
            console.log(json)
          })
      fetch(host + '/rest/rubricators/LaboratorySection', {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.laboratorySectionsOptions = json.options
            console.log(json)
          })
    },
    fetchAnalitRubricatorValues() {
      fetch(host + '/rest/params/rubricators/' + this.analitId, {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.medicalSpecialties = json.paramMedicalSpecialties
            this.metabolismTypes = json.paramMetabolismTypes
            this.labStateTissueOrgans = json.paramLabStateTissueOrgans
            this.conditionAndSyndromes = json.paramConditionAndSyndromes
            this.methods = json.paramMethods
            this.complexExaminations = json.paramComplexExaminations
            this.laboratories = json.paramLaboratories
            this.laboratorySections = json.paramLaboratorySections
            console.log(json)
          })
    }
  },
  mounted() {
    this.fetchData()
    this.fetchDictsData()
    this.fetchAnalitRubricatorValues()
  }

}
</script>

<style scoped>
.editLabel {
  padding-top: 0.8rem;
}
.badgeSelected {
  padding: 0.2rem;
}

.treeselect-font {
  font-size: 15px!important;
}

</style>