<template>
  <div class="main-flex-container">
    <!-----------------ШАПКА МОБ ВЕРСИЯ--------------------------->
    <header>
      <div class="d-block d-lg-none text-center">
        <div class="row mx-0">
          <div class="col-12">
            <div class="row">
              <div class="shadow col-12 pb-1">
                <div class="row">
                  <div class="col-2 ml-md-n4 mt-2">
                    <button class="btn_menu">
                      <i class="fa fa-bars fa-4x px-2"></i>
                    </button>
                  </div>
                  <div class="col-9 mt-1">
                    <img src="../assets/images/Logo_4P.png" class="mob_img" >
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="d-flex justify-content-center">
                  <img src="../assets/images/Icon_car 1.png">
                  <div class="ml-3 mt-3">
                    <div class="ml-2 h6 support">Поддержка</div>
                    <div>
                      <button class="ml-2 pb-1 btn_call_back">
                        <span class="call_back">Перезвонить</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="mt-3">
                  <div>
                    <img src="src/assets/images/Vector1.png ">
                    <a href="tel:+7 495 998 32 32" class="tl"> +7 495 998 32 32</a>
                  </div>
                  <div class="mt-1">
                    <img src="../assets/images/Vector (2).png">
                    <a href="mailto:info@callisto-pro.ru" class="ml"> info@callisto-pro.ru</a>
                  </div>
                </div>
              </div>
            </div>
            <hr class="mt-0 d-lg-none line">
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-6 ">
                <div><button class="btn_proposal_to_the_doctor mob_btn">
                  <span class="proposal_to_the_doctor mob_text_btn"> Предложение для врача </span>
                </button></div>
              </div>

              <div class="col-6">
                <div><button class="btn_sign_up_for_training mob_btn">
                  <span class="sign_up_for_training mob_text_btn"> Записаться на обучение </span>
                </button></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-----------------ШАПКА КОМП ВЕРСИЯ--------------------------->
      <div class="row d-none d-lg-flex mx-0">
        <div class="col-12">
          <div class="row">
            <div class="col-lg-6 col-xl-6 mt-2 col-md-12">
              <img src="../assets/images/Logo_4P.png" >
            </div>
            <div class="col-lg d-flex col-md-6 justify-content-center mr-3">
              <img src="../assets/images/Icon_car 1.png" >
              <div class="ml-3 mt-3">
                <div class="ml-2 h6 support"> Поддержка </div>
                <div>
                  <button class="ml-2 pb-1 btn_call_back">
                    <span class="call_back">Перезвонить</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-auto mt-3 d-none d-md-block col-md-6 mr-1">
              <div>
                <img src="@/assets/images/Vector1.png"><a href="tel:+7 495 998 32 32" class="tl"> +7 495 998 32 32</a>
              </div>
              <div class="mt-1">
                <img src="@/assets/images/Vector (2).png">
                <a href="mailto:info@callisto-pro.ru" class="ml"> info@callisto-pro.ru</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="mt-lg-0 mb-0 mb-lg-3 line_2">
    </header>

    <!-------------------------------------------------->

    <div class="main-content">
      <router-view />
    </div>

    <footer>
      <div class="row mx-0 oblast_foot">
        <div class="col-md-6 mt-1"><p class="foot">© 2021-2023 Каллисто. Все права защищены</p></div>
        <div class="col-md-2 text-md-center"><p class="foot"><a href="#" class="foot"> Лицензиии и сертификаты</a></p></div><div class="d-none d-md-block line_small">|</div>
        <div class="col-md-2 text-md-center"><p class="foot"><a href="#" class="foot"> Условия конфиденциальности</a></p></div><div class="d-none d-md-block line_small">|</div>
        <div class="col-md text-md-center"><p class="foot"><a href="#"  class="foot"> Контактная информация </a></p></div>
      </div>
    </footer>
  </div>

</template>

<script>
export default {
  name: "BaseLayout"
}
</script>

<style >
@import '../assets/css/main_page.css';
</style>