<template>
  <div>
    <div>
      <b-container fluid>
        <b-row class="newAttr">
          <b-button variant="info" to="/doctoredit">Создать врача</b-button>
        </b-row>
        <b-row class="newAttr">
          <div v-if="error" class="alert alert-danger">
            {{error}}
          </div>
        </b-row>
        <b-row class="newAttr">
          <b-input-group>
            <b-input-group-prepend is-text>
              <b-icon variant="info" icon="search" size="lg"></b-icon>
            </b-input-group-prepend>
            <b-form-input type="text" v-model="searchDoctorRequest" autocomplete="off"></b-form-input>
          </b-input-group>
        </b-row>
        <b-row class="newAttr">
          <b-table striped hover :items="doctors | filterItems(searchDoctorRequest)"
                   :fields="fields" :tbody-tr-class="rowClass">

            <!-- A custom formatted column -->
            <template v-slot:cell(fullname)="data">
              <span v-if="data.item.isBlocked" >{{ data.item.firstname }} {{ data.item.lastname }}</span>
              <span v-else><b>{{ data.item.firstname }}</b> <b class="text-info">{{ data.item.lastname }}</b></span>
            </template>
            <template v-slot:cell(phoneNumber)="data">
              <span>{{ data.item.phoneNumber | VMask('+ # (###) ###-##-##') }}</span>
            </template>
            <template v-slot:cell(buttons)="data">
              <h3 class="buttons">
                <nobr>
                  <b-link @click="rowClickHandler(data.item)">
                    <b-icon variant="info" icon="pencil" size="lg"></b-icon>
                  </b-link>
                  &nbsp;
                  <b-link @click="blockUnblockDoctor(data.item.doctorId, data.item.isBlocked)">
                    <b-icon variant="info" :icon="data.item.isBlocked ? 'toggle-off' : 'toggle-on'" size="lg"></b-icon>
                  </b-link>

                  <b-link @click="deleteDoctor(data.item)">
                    <b-icon variant="info" icon="x-circle" size="lg"></b-icon>
                  </b-link>

                </nobr>
              </h3>
            </template>

          </b-table>
        </b-row>
      </b-container>
      <Loader v-bind:showLoader="loading" />
      <!--    <Loader v-if="loading" />-->
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader'
import {host} from '@/constants'
import {mapGetters, mapActions} from 'vuex'
const phoneMask = '+ # (###) ###-##-##'

export default {
  name: "DoctorList",
  computed: {
    searchDoctorRequest: {
      get() {
        return this.$store.getters.searchDoctorRequest
      },
      set(value) {
        this.$store.commit('updateSearchDoctorRequest', value)
      }
    },
    doctors: {
      get() {
        return this.$store.getters.doctors
      }
    }
  },
  data () {
    return {
      vMask: phoneMask,
      loading: true,
      error: null,
      fields: [
        {
          key: 'doctorId',
          label: 'ID',
          sortable: true
        },
        {
          key: 'fullname',
          label: 'Имя'
        },
        {
          key: 'clientCount',
          label: 'Пациенты',
          sortable: true
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true
        },
        {
          key: 'phoneNumber',
          label: 'Телефон',
          sortable: true
        },
        {
          key: 'activationDate',
          label: 'Дата активации',
          sortable: true
        },
        {
          key: 'buttons',
          label: ' ',
          sortable: false
        }
      ]
    }
  },
  filters: {
    filterItems: function (items, searchRequest) {
      if (searchRequest !== null && searchRequest !== "" ) {
        return items.filter((el) => (el.searchString.toLowerCase()).includes(searchRequest.toLowerCase()))
      }
      return items
    }
  },
  components: {
    Loader
  },
  methods: {
    ...mapActions(['fetchDoctors']),
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.isBlocked) return 'table-secondary'
    },
    rowClickHandler(item) {
      this.$router.push('/doctoredit/' + item.doctorId)
    },
    async blockUnblockDoctor(doctorId, isBlocked) {

      this.loading = true;
      let response = await fetch(host + '/rest/doctors/' + doctorId + '/block/' + !isBlocked, {
        method: 'GET',
        credentials: 'include',
      })
      let json = await response.json()
      try {
        if (json.resultCode == 0) {
          await this.fetchDoctors()
        } else {
          this.error = json.message;
        }
      } catch(error) {
        this.error = error
        console.log(JSON.stringify(error))
      }
      this.loading = false
    },
    async deleteDoctor(doctor) {

      if (confirm('Вы действительно хотите удалить врача?'))
      this.loading = true;
      let response = await fetch(host + '/rest/doctors/' + doctor.doctorId , {
        method: 'DELETE',
        credentials: 'include',
      })
      let json = await response.json()
      try {
        if (json.resultCode == 0) {
          this.$messageToast('Удаление врача')
          await this.fetchDoctors()

        } else {
          this.$errorToast(json.resultCode + ' ' + json.message)
        }
      } catch(error) {
        this.$errorToast(error)
      }
      this.loading = false
    }



  },
  async mounted() {
    await this.fetchDoctors()
    this.loading = false;
  }
}
</script>

<style scoped>

.newAttr {
  padding-top: 1rem;
}

h3.buttons {
  text-align: -webkit-right !important;
  text-align: -moz-right !important;
  text-align: right !important;
}
</style>