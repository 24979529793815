<template>
  <div>
    <b-form @submit.prevent="onSubmit">
      <div v-if="error" class="alert alert-danger">
        {{error}}
      </div>
      <b-overlay spinner-variant="info" :opacity="0.5" :show="loading">
      <b-container>
        <b-row class="newAttr">
          <b-col><label for="factorId">{{ factorText }}</label></b-col>
          <b-col>
            <b-form-select v-if="factorBand" id="factorId" v-model="localParamFactorBand.factorId" :options="factorOptions" required :select-size="1"></b-form-select>
            <treeselect v-else name="param" id="param" placeholder=""
                        multiple clearable searchable required
                        open-on-click close-on-select :flat="true"
                        :noResultsText="'Не найдено...'"
                        :noOptionsText="'Список опций пуст'"
                        :options="factorOptions | searchQueryFilter(localParamFactorBand.factors, childrenSearchQuery)"
                        :max-height="200"
                        :normalizer="normalizeTreeselect"
                        v-model="localParamFactorBand.factors" style="font-size: 15px!important;" @search-change="childrenSearchChange">
            </treeselect>
          </b-col>
        </b-row>
        <b-row class="newAttr">
          <b-col><label for="sexId">Пол</label></b-col>
          <b-col><b-form-select id="sexId" v-model="localParamFactorBand.sexId" :options="genderOptions" required :select-size="1"></b-form-select></b-col>
        </b-row>
        <b-row v-if="!factorBand && paramType !== 2 && paramType !== 7" class="newAttr">
          <b-col><label for="zoneGroupId">Группа интервалов</label></b-col>
          <b-col>
          <!--<b-form-checkbox v-for="(item, i) in zoneGroupOptions" :key="item.value" switches @change="toogleZoneGroup" :labe></b-form-checkbox>-->
            <b-form-checkbox-group @change="toogleZoneGroup"
                                   v-model="zoneGroups"
                                   :options="zoneGroupOptions"
                                   id="zoneGroupId"
                                   switches></b-form-checkbox-group>

          </b-col>
        </b-row>
        <b-row v-if="paramType !== 2 && paramType !== 7" class="newAttr">
          <b-col><label for="zoneId">Интервал</label></b-col>
          <b-col>
            <b-form-select v-if="factorBand" id="zoneId" v-model="localParamFactorBand.zoneId" :options="zoneOptions" text-field="zoneName" value-field="zoneId" required :select-size="1"></b-form-select>
            <b-form-checkbox-group v-else
                                   v-model="localParamFactorBand.zones"
                                   :options="zoneOptions"
                                   text-field="zoneName"
                                   value-field="zoneId"
                                   id="zoneId"
                                   stacked>
            </b-form-checkbox-group>
          </b-col>
        </b-row>
        <b-row v-if="paramType === 2 || paramType === 7" class="newAttr">
          <b-col><label for="zoneId">Варианты ответа</label></b-col>
          <b-col>
            <b-form-select v-if="factorBand" id="zoneId" v-model="complexPropValue" :options="propertyValueOptions" required :select-size="1"></b-form-select>
            <b-form-checkbox-group v-else
                                   v-model="complexPropValues"
                                   :options="propertyValueOptions"
                                   text-field="text"
                                   value-field="value"
                                   id="zoneId"
                                   stacked>
            </b-form-checkbox-group>
          </b-col>
        </b-row>
<!--        <b-row v-if="factorValue === 'ParamRecommended'" class="newAttr">-->
<!--          <b-col><label for="recommendationTypeId">Критичность рекомендации</label></b-col>-->
<!--          <b-col>-->
<!--            <b-form-select id="recommendationTypeId" v-model="localParamFactorBand.recommendationTypeId" :options="recommendationOptions" required :select-size="1"></b-form-select>-->
<!--          </b-col>-->
<!--        </b-row>-->
        <b-row class="newAttr">
          <b-col><label for="priorityReport">Приоритет</label></b-col>
          <b-col><b-form-input id="priorityReport" type="number" required  v-model="localParamFactorBand.priorityReport" autocomplete="off"></b-form-input></b-col>
        </b-row>
        <b-row class="newAttr">
          <b-col>
            <b-button variant="info" type="submit" >Сохранить</b-button>
          </b-col>
        </b-row>
      </b-container>
      </b-overlay>
    </b-form>
  </div>
</template>

<script>
import {host} from "@/constants";
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  props: {
    analitId: String,
    factorValue: String,
    factorText: String,
    factorBand: Object,
    paramType: String
  },
  name: "FactorBandEdit",
  filters: {

    searchQueryFilter: function (options, selected, searchQuery) {
      console.log('searchQueryFilter' + searchQuery)
      if (!searchQuery) {
        return []
      }

      let result = []

      result = options.filter((el, index, []) => {
        return (selected.find(sel => (sel == el.value)) || ((searchQuery) && (el.text).toLowerCase().includes(searchQuery.toLowerCase()) ))
      })

      return result
    }
  },
  data () {
    return {
      childrenSearchQuery: null,
      loading: false,
      factorOptions: [],
      genderOptions: [{value:0,text:'мужской и женский'},{value:1,text:'мужской'},{value:2,text:'женский'}],
      zoneOptions: [],
      zoneGroupOptions: [],
      zoneGroups: [],
      propertyValueOptions: [],
      //recommendationOptions: [],
      error: null,
      complexPropValue: this.factorBand ? this.factorBand.propertyId + ',' + this.factorBand.propertyValueId : null,
      complexPropValues: [],
      localParamFactorBand: this.factorBand ? this.factorBand : {
        paramFactorBandId: 1,
        sexId: 0,
        factorId: null,
        factors: [],
        priorityReport: 0,
        zoneId: null,
        zones: [],
        recommendationTypeId: null,
        propertyValueId: null,
        propertyId: null,
        propertyValues: []
      },
      normalizeTreeselect(node) {
        return {
          id: node.value,
          label: node.text
        }
      },
    }
  },
  methods: {
    childrenSearchChange(searchQuery, instanceId) {
      console.log('childrenSearchChange' + searchQuery)
      this.childrenSearchQuery = searchQuery
    },
    fetchDictsData() {
      fetch(host + '/rest/zones/' + this.paramType)
          .then(response => response.json())
          .then(json => {
            this.zoneOptions = json.options
          })
      fetch(host + '/rest/dicts/zone/group')
          .then(response => response.json())
          .then(json => {
            this.zoneGroupOptions = json.options
          })
      // fetch(host + '/rest/dicts/recommendationtype')
      //     .then(response => response.json())
      //     .then(json => {
      //       this.recommendationOptions = json.options
      //     })
      fetch(host + '/rest/factors/' + this.factorValue, {
        credentials: 'include'
      })
          .then(response => response.json())
          .then(json => {
            this.factorOptions = json.options
          })
      fetch(host + '/rest/params/' + this.analitId + '/properties/values/dict', {
        credentials: 'include'
      })
          .then(response => response.json())
          .then(json => {
            this.propertyValueOptions = []
            for (let opt of json.options) {
              if (opt.value && opt.propertyId && opt.propertyId > 0) {
                this.propertyValueOptions.push( {
                  value: opt.propertyId + ',' + opt.value,
                  text: opt.propertyName + ', ' + opt.text
                })
              }
            }
          })
    },
    onSubmit() {
      this.loading = true
      let method = 'POST'
      if (this.factorBand) {
        method = 'PUT'
        this.localParamFactorBand.zones = null
        this.localParamFactorBand.factors = null
        this.localParamFactorBand.propertyValues = null
        if (this.paramType === 2 || this.paramType === 7) {
          const propIds = this.complexPropValue.split(',')
          this.localParamFactorBand.propertyId = propIds[0]
          this.localParamFactorBand.propertyValueId = propIds[1]
          //this.localParamFactorBand.zoneId = null
        } else {
          this.localParamFactorBand.propertyId = null
          this.localParamFactorBand.propertyValueId = null
        }

      } else {
        if (this.paramType === 2 || this.paramType === 7) {
          this.localParamFactorBand.propertyValues = []
          for (let a of this.complexPropValues) {
            const propIds = a.split(',')
            this.localParamFactorBand.propertyValues.push({value: propIds[1], propertyId: propIds[0]})
          }
        }
      }


      console.log(JSON.stringify(this.mu))
      fetch(host + '/rest/params/' + this.analitId + '/factors/' + this.factorValue, {
        method: method,
        credentials: 'include',
        body: JSON.stringify(this.localParamFactorBand),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            this.loading = false
            if (json.resultCode == 0) {
              this.$emit('save-success')
            } else {
              this.error = json.resultCode + ' ' + json.message;
            }
          })
          .catch(error => {
            this.error = error
            this.loading = false
          })

    },
    toogleZoneGroup(checked) {
      this.localParamFactorBand.zones = []
      checked.forEach(el => {
        this.zoneOptions.forEach(zone => {
          if (el === zone.zoneGroupId) {
            this.localParamFactorBand.zones.push(zone.zoneId)
          }
        })
      })
    }
  },
  components: {
    Treeselect
  },
  mounted() {
    this.fetchDictsData()
  }
}
</script>

<style scoped>

.newAttr {
  padding-top: 1rem;
}

</style>