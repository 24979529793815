import { render, staticRenderFns } from "./PatientDrugs.vue?vue&type=template&id=4fdd4c3c&scoped=true"
import script from "./PatientDrugs.vue?vue&type=script&lang=js"
export * from "./PatientDrugs.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fdd4c3c",
  null
  
)

export default component.exports