<template>
  <b-container>
    <b-row>
      <b-col cols="3">Создание синонима</b-col>
      <b-col cols="7"><b-form-input id="text" type="text" required placeholder="Введите значение" v-model="newDrugSynonym" autocomplete="off"></b-form-input></b-col>
      <b-col cols="2"><b-button type="button" :disabled="saving" class="btn-info" variant="primary" @click="addSynonym">Добавить <b-spinner v-if="saving" variant="light" small></b-spinner></b-button></b-col>
    </b-row>
    <b-row>
      <b-table striped hover :items="drugSynonyms" :fields="fields">
        <!-- A custom formatted column -->
        <template v-slot:cell(buttons)="data">
          <h3>
            <nobr>
              <b-link @click="deleteRubricatorValue(data.item.drugSynonymId, data.item.drugSynonymName)"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link>
            </nobr>
          </h3>
        </template>
      </b-table>
    </b-row>
  </b-container>
</template>

<script>
import {host} from "@/constants";

export default {
  props: ["drugId"],
  name: "DrugSynonyms",
  data() {
    return {
      saving: false,
      drugSynonyms:[],
      newDrugSynonym: '',
      fields: [
        {
          key: 'drugSynonymName',
          label: 'Наименование',
          sortable: true
        },
        {
          key: 'buttons',
          label: ' ',
          sortable: false


        }
      ],
    }
  },
  methods : {
    deleteRubricatorValue(paramId, drugSynonymName) {
      if (confirm('Вы уверены, что хотите удалить синоним "' + drugSynonymName + '"?')) {
        fetch(host + '/rest/factors/Drug/' + this.drugId + '/synonyms/' + paramId, {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {
                this.fetchData()

              } else {

                console.log('Delete Failed ' + json)
                this.$errorToast(json.message)
              }
              this.loading = false

            }).catch(error => {
          this.$errorToast(error)
        })
      }
    },
    async addSynonym() {
      this.saving = true
      let method = 'POST'


      console.log(JSON.stringify(this.mu))
      try {
        let response = await fetch(host + '/rest/factors/Drug/' + this.drugId + '/synonyms', {
          method: method,
          credentials: 'include',
          body: JSON.stringify({drugSynonymName: this.newDrugSynonym, drugId: this.drugId}),
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        })
        let json = await response.json()

        if (json.resultCode == 0) {
          this.$messageToast('Синоним')
          await this.fetchData()
          this.$emit('save-success')
        } else {
          console.log('Save Failed ' + json)
          this.$errorToast( json.resultCode + ' ' + json.message)
        }

      } catch(error ) {
        this.$errorToast( json.resultCode + ' ' + json.message)
        console.log(JSON.stringify(error))
      }
      this.saving = false
    },
    async fetchData() {
      let response = await fetch(host + '/rest/factors/Drug/' + this.drugId + '/synonyms', {
        credentials: "include"
      })
      let json = await response.json()
      this.drugSynonyms = json.synonyms
    }
  },
  async mounted() {
    await this.fetchData()
  }
}
</script>

<style scoped>

</style>