<template>
  <div class="row">
    <div class="col-lg-12">
      <br /><br />
      <h4>{{currentRubricator.text}}</h4>
      <br />
      <b-button variant="info" @click="editRubricatorValue()"><b-icon icon="plus" aria-hidden="true" ></b-icon> Добавить</b-button>
      <br /><br />
      <div v-if="error" class="alert alert-danger">
        {{error}}
      </div>
      <b-table striped hover :items="items" :fields="fields" ref="measurementUnitTable">
        <!-- A custom formatted column -->
        <template v-slot:cell(value)="data">
          <b>{{ data.item.value }}</b>
        </template>
        <template v-slot:cell(text)="data">
          <span v-html="data.item.text"></span>
        </template>
        <template v-slot:cell(buttons)="data">
          <h3>
            <nobr>
              <b-link @click="editRubricatorValue(data.item)" ><b-icon variant="info" icon="pencil" size="lg" ></b-icon></b-link>
              &nbsp;
              <b-link @click="deleteRubricatorValue(data.item.value, data.item.text)"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link>
            </nobr>
          </h3>
        </template>
      </b-table>
      <b-modal id="measurementUnitModal" hide-footer size="lg" :title="'Значение ' + this.currentRubricator.text">
        <FactorValueEdit @save-success="afterSave" v-bind:error="error" v-bind:current-rubricator-value="currentMeasurementUnit" v-bind:current-rubricator="currentRubricator"></FactorValueEdit>
      </b-modal>
      <Loader v-bind:showLoader="loading" />
    </div>
  </div>
</template>

<script>
import {host} from "@/constants"
import Loader from "@/components/Loader";
import FactorValueEdit from "@/components/admin/factors/FactorValueEdit";
export default {
  props:{
    rubr: Object
  },
  name: "FactorValueList",
  components: {
    Loader, FactorValueEdit
  },
  data() {
    return {
      fields: [
        {
          key: 'value',
          label: 'Id',
          sortable: true
        },
        {
          key: 'text',
          label: 'Наименование',
          sortable: true
        },
        {
          key: 'buttons',
          label: ' ',
          sortable: false
        }
      ],
      items: [],
      loading: true,
      error: null,
      currentRubricator: this.rubr,
      currentMeasurementUnit: null
    }
  },
  methods: {
    deleteRubricatorValue(paramId, paramName) {
      this.error = null;
      if (confirm('Вы уверены, что хотите удалить значение рубрикатора "' + paramName + '"')) {
        this.loading = true;
        fetch(host + '/rest/factors/' + this.currentRubricator.value + '/' + paramId, {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {
                this.items = this.items.filter(item => item.value !== paramId)

                console.log('Delete Success ' + json)

              } else {

                console.log('Delete Failed ' + json)
                this.error = json.message;
              }
              this.loading = false

            }).catch(error => {
          this.error = error
          console.log(JSON.stringify(error))
          this.loading = false
        })
      }
    },
    editRubricatorValue(item = null) {
      this.currentMeasurementUnit = item
      this.$bvModal.show('measurementUnitModal')
    },
    fetchData() {
      fetch(host + '/rest/factors/' + this.currentRubricator.value, {
        credentials: 'include'
      })
          .then(response => response.json())
          .then(json => {
            this.items = json.options
            this.loading = false
            console.log(json)
          })
    },
    afterSave() {
      this.$bvModal.hide('measurementUnitModal')
      this.loading = true
      this.fetchData()
    }
  },
  mounted() {
    this.fetchData()
  },
  watch: {
    rubr: function (val) {
      this.loading = true
      this.currentRubricator = val
      this.fetchData()
    },
  }
}
</script>

<style scoped>
h3 {
  text-align: -webkit-right!important;
  text-align: -moz-right!important;
  text-align: right!important;
}

</style>