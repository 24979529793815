<template>
  <b-container>
    <b-row class="justify-content-center"><span class="block-title">Укажите хронические заболевания</span></b-row>
    <b-row class="justify-content-center"><span class="block-title">&nbsp;</span></b-row>
    <b-row>
      <b-form @submit.prevent="saveChronicDiseases">
        <b-form-checkbox-group
            v-model="selected"
            :options="chronisDiseases"
            @change="saveChronicDiseases"
            switches style="column-count: 3; font-family: Myriad Pro; font-style: normal;font-weight: 600;font-size: 17px;line-height: 20px;color: #606D6D;"
            stacked></b-form-checkbox-group>
<!--        <b-button type="submit" variant="info" >Сохранить</b-button>-->
      </b-form>
    </b-row>
  </b-container>
</template>

<script>
import {host} from "@/constants";

export default {
  props:["patientId"],
  name: "ChronicDiseases",
  data () {
    return {
      chronisDiseases: [],
      selected:[]
    }
  },
  methods: {
    saveChronicDiseases() {
      fetch(host + '/rest/patients/' + this.patientId + '/dynamic/checklist/3', {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify(this.selected),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      })
          .then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {
              this.$messageToast('Данные о хронических заболеваниях', 500)
            } else {
              this.$errorToast(json.resultCode + ' ' + json.message)
            }
          }).catch(error => {
        console.log(error)
        this.$errorToast('Неизвестная ошибка')
      })
    }
  },
  mounted() {
    fetch(host + '/rest/patients/' + this.patientId + '/dynamic/checklistbytype/3', {
      credentials: "include"
    })
        .then(response => response.json())
        .then(json => {
          this.chronisDiseases = json.options
          for (let el of this.chronisDiseases) {
            if (el.selected) {
              this.selected.push(el.value)
            }
          }
        })
  }

}
</script>

<style scoped>

</style>